import module from "module";

const templateUrl = require('./deposit-account-list.template.html');

module.component('depositAccountList', {
  templateUrl,
  controller: function (depositAccountService, interestBoardService) {

    const that = this;

    const s1 = depositAccountService.toObservable().subscribe(data => {
      that.depositAccounts = data ? data : [];
      if (that.depositAccounts.length > 0) {
        _.forEach(that.depositAccounts, a => {
          a.interestRange = interestBoardService.formatInterestRateRangeForRateType(a.interestBoards);
        })
      }
    });

    that.$onDestroy = () => {
      s1.unsubscribe();
    }
  }
});
