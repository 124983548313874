import module from 'module';
import BigNumber from 'bignumber.js';
import templateUrl from './check-transfer-to-cashier.template.html';

// Deprecated - Marked for removal
class CheckTransferToCashier {
  constructor(authentication, actionCommand, userCache, ledgerActionTemplateCache, miscTransactionsService, commandAccessChecker) {
    
    this.branchId = authentication.context.branchId;
    this.userId = authentication.context.id;
    this.userCache = userCache;
    this.ledgerActionTemplateCache = ledgerActionTemplateCache;
    this.miscTransactionsService = miscTransactionsService;
    this.actionCommand = actionCommand;
    this.commandAccessChecker = commandAccessChecker;

    this.transaction = {
      amount: 0,
      userId: this.userId,
      branchId: this.branchId,
      approvalUserId: null,
      checkIds: []
    };

    this.approvalUsers = [];
  }

  $onInit() {
    this.getApprovalUsers()
      .then(approvalUsers => this.approvalUsers = approvalUsers);
  }

  async getApprovalUsers() {
    const usersCache = this.userCache.toPromise();
    const templateCache = this.ledgerActionTemplateCache.toPromise();
    const canApproveCommandChecker = this.commandAccessChecker.canApproveCommandPromise();

    const [users, templates, canApproveCommand] = await Promise.all([usersCache, templateCache, canApproveCommandChecker]);
    const template = templates.find(temp => temp.type === 'BATCH_CHECK_TRANSFER_TO_CASHIER');

    return this.miscTransactionsService.filterApprovalUsers(this.branchId, template, users, canApproveCommand);
  }

  onCheckChange(checks) {
    this.updateTransaction(checks);
  }

  updateTransaction(checks) {
    let amount = new BigNumber(0);
    const checkIds = [];

    checks.forEach(check => {
      amount = amount.plus(check.amount);
      checkIds.push(check.id);
    });
    
    this.transaction.amount = amount.toNumber();
    this.transaction.checkIds = checkIds;
  }

  cancelChanges() {
    this.actionCommand.cancelChanges();
  };

  save() {
    this.actionCommand.execute('BATCH_CHECK_TRANSFER_TO_CASHIER', this.transaction);
  }
}

module.component('checkTransferToCashier', {
  templateUrl,
  controller: CheckTransferToCashier
});
