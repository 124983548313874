export const multipleAccountOwnershipsEnabled = (profile, product, pdicCasaTypes) => {
  return profile.customerType === 'INDIVIDUAL' &&
    pdicCasaTypes.find(t => t.id === product.pdicTypeId).multipleOwnershipAllowed;
};

export const isCorporate = (profile, product, pdicCasaTypes) => {
  return profile.customerType === 'CORPORATE' &&
    pdicCasaTypes.find(t => t.id === product.pdicTypeId).code === 'CORPORATE';
};

export const enrichOwnersWithRelatives = (product, relatives) => {
  if (product && relatives) {
    product.ownership.forEach(o => {
      if (o.ownershipType === 'SIGNATORY') {
        o.relative = relatives.find(r => r.id === o.relativeId);
      }
    })
  }
};

export const mapSignatoryToOwnership = (sig) => {
  return {
    birthDate: sig.birthDate,
    branchId: sig.branchId,
    customerNumber: sig.customerNumber,
    customerId: sig.relativeCustomerId,
    effectiveName: sig.effectiveName,
    ownershipType: 'SIGNATORY',
    relativeId: sig.id,
    productAccess: false
  };
};