import module from "module";
import _ from 'lodash';
import BigNumber from "bignumber.js";
import {sum} from "../../../../shared/common/MathUtils";

const templateUrl = require('./print-disclosure-statement.template.html');

module.component('printDisclosureStatement', {
  templateUrl: templateUrl,
  bindings: {
    loan: '<'
  },
  controller: function ($route, $location, customerCache, loanCache,
                        organizationCache, branchService, loanProductsCache, breadcrumbs, postingDateService) {
    const that = this;

    that.total = {};
    that.charges = [];
    that.conditionalCharges = [];

    postingDateService.getCurrentUserBranchPostingDate().then(date => that.date = date.clone().format('YYYY-MM-DD'));

    const createChargeObject = (loan) => {
      let tempCharges = [];
      tempCharges.push({charge: "Doc Stamp Tax", amount: loan.docStamp});
      tempCharges.push({charge: "Notarial Fee", amount: loan.notarialFee});
      tempCharges.push({charge: "Application Fee", amount: loan.applicationFee});
      tempCharges.push({charge: "Credit Investigation Fee", amount: loan.creditInvestigationFee});
      tempCharges.push({charge: "Extra Bank Fee", amount: loan.extraBankFee});
      tempCharges.push({charge: "Service Charge", amount: loan.serviceCharge});
      tempCharges.push({charge: "Pre-termination Fee", amount: loan.preterminationFee});
      tempCharges.push({charge: "Membership Fee", amount: loan.membershipFee});
      tempCharges.push({charge: "ID Fee", amount: loan.idFee});
      tempCharges.push({charge: "Insurance Fee", amount: loan.insuranceFee});
      tempCharges.push({charge: "Insurance Service Fee", amount: loan.insuranceServiceFee});
      tempCharges.push({charge: "Insurance Processing Fee", amount: loan.insuranceProcessingFee});

      _.forEach(loan.fees, (fee) => {
        tempCharges.push({charge: fee.feeName, amount: fee.amount});
      });

      return tempCharges;
    };

    const createConditionalCharges = (loan) => {
      if (loan.loanType.pastDueInterestCharge && loan.loanType.pastDueInterestCharge.rate > 0) {
        const charge = loan.loanType.pastDueInterestCharge;
        Object.assign(charge, {title: 'Past due'})
        that.conditionalCharges.push(charge);
      }

      if (loan.loanType.penalty && loan.loanType.penalty.rate > 0) {
        const charge = loan.loanType.penalty;
        Object.assign(charge, {title: 'Penalty'});
        that.conditionalCharges.push(charge);
      }
    };

    const createAddress = async (loan) => {
      const profile = await customerCache.profile(loan.customerId).toPromise();
      const customerProfile = _.cloneDeep(profile);
      const address = customerProfile.addresses[0];
      const addressDetails = [address.street, address.barangay, address.city, address.province, address.postalCode];

      loan.customer = {};
      loan.customer.profile = profile;
      loan.customer.profile.customerAddress = addressDetails.filter(a => a).join(' ');
    };

    const getOrganization = async (loan) => {
      const organization = await organizationCache.toPromise();
      Object.assign(loan, {
        organization: organization[0]
      });
    };

    const prepareLoanData = () => {
      if (that.loan && (that.loan.status === 'PENDING' || that.loan.status === 'ACTIVE')) {
        const tempCharges = createChargeObject(that.loan);

        that.totalCharge = 0;

        that.totalCharge = sum(_.map(tempCharges, (charge) => {
          return charge.amount;
        }));

        that.charges = tempCharges.filter(charge => {
          return charge.amount > 0;
        });

        createAddress(that.loan);
        getOrganization(that.loan);

        createConditionalCharges(that.loan);

        that.netProceeds = new BigNumber(that.loan.principalAmount).sub(that.totalCharge);
      }
    };

    that.$onChanges = () => {
      prepareLoanData();
    }
  }
});