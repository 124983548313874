import module from 'module';
import './area-details.style.less';
import templateUrl from './area-details.template.html';
import {flatten} from '../tree.utils';

class AreaDetails {
  constructor($route, $location, http, branchService, command) {
    this.$route = $route;
    this.$location = $location;
    this.http = http;
    this.branchService = branchService;
    this.command = command;
  }

  async $onInit() {
    const [branches, areaTree] = await Promise.all([this.branchService.toPromise(), this.http.get('/management/areas').toPromise()]);
    this.branches = branches;
    const flatAreas = flatten(areaTree);

    if (this.areaId) {
      const currentArea = flatAreas.find(area => area.id === this.areaId);
      this.prepareBranches(currentArea);
      this.area = {...currentArea, parent: flatAreas.find(area => area.id === currentArea.parentId)};

      this.availableAreas = this.findAvailableAreas(flatAreas);
    } else {
      this.availableAreas = flatAreas.filter(area => !area.parentId);

      this.area = {
        name: null,
        description: null,
        parent: {},
      };
    }
  }

  prepareBranches(area) {
    this.branches.forEach(branch => {
      if (area.branchIds && area.branchIds.includes(branch.id)) {
        branch.selected = true;
      }
      if (area.officeIds && area.officeIds.includes(branch.id)) {
        branch.areaOffice = true;
      }
    });
  }

  findAvailableAreas(flatAreas) {
    if (!this.areaId) {
      return;
    }

    const excludedAreasIds = new Set([this.areaId]);
    let a = this.area;
    while (a.parentId) {
      excludedAreasIds.add(a.parentId);
      a = flatAreas.find(area => area.id === a.parentId);
    }

    const currentAreaChildrenIds = this.area.children ? this.area.children.map(currentChild => currentChild.id) : [];

    return flatAreas.filter(
      // areas that are not assigned to any parent and are not on excluded list
      // or areas that are children of current area
      area => (!area.parentId && !excludedAreasIds.has(area.id)) || currentAreaChildrenIds.includes(area.id)
    ).map(
      area => {
        area.selected = currentAreaChildrenIds.includes(area.id);
        return area;
      }
    );
  }

  onBranchChange(branch) {
    if (!branch.selected && branch.areaOffice) {
      branch.areaOffice = false;
    }
  }

  onAreaOfficeChange(branch) {
    if (branch.areaOffice && !branch.selected) {
      branch.selected = true;
    }
  }

  getBranches() {
    return this.branches
      .filter(branch => branch.selected)
      .map(branch => branch.id);
  }

  getOffices() {
    return this.branches.filter(branch => branch.areaOffice).map(branch => branch.id);
  }

  getAreas() {
    return this.availableAreas.filter(area => area.selected).map(area => area.id);
  }

  goBack() {
    this.$location.path('/admin/organization');
  }

  async save() {
    const input = {
      id: this.area.id,
      name: this.area.name,
      description: this.area.description,
      branchIds: this.getBranches(),
      officeIds: this.getOffices(),
      areaIds: this.getAreas()
    };

    await this.command.execute(this.areaId ? 'UpdateArea' : 'CreateArea', input).toPromise();
    this.goBack();
  }

}

module.component('areaDetails', {
  templateUrl,
  controller: AreaDetails,
  bindings: {
    areaId: '<'
  }
});