import module from 'module';
import _ from 'lodash';

const templateUrl = require('./cashier-check-encashment.template.html');
module.component('cashierCheckEncashment', {
  templateUrl: templateUrl,
  controller: function (http, authentication, actionCommand, customerCache, loanCache, notification) {

    // JS 'magic'
    let self = this;
    // Current branch ID
    self.branchId = authentication.context.branchId;
    // List of pending cashier checks for branch
    self.pendingChecks = [];
    // Selected check number (from list)
    self.selectedNumber = null;
    // Selected pending check
    self.selectedCheck = null;
    // Ledger action data
    self.transaction = {};
    // Pending check select config
    this.selectConfig = {
      placeholder: 'Select check number',
      searchField: ['number'],
      valueField: 'number',
      labelField: 'number',
      maxItems: 1
    };

    // Read issued cashier's checks for current branch
    const checkQueryUrl = `/checks/cashiers?targetBranchId=${self.branchId}&interbranchChecks=FALSE&status=ISSUED&pageSize=50`;
    http.get(checkQueryUrl, {}, {nxLoaderText: 'Loading cashier checks'})
      .success((page) => {
        if (page && page.resultCount > 0) {
          self.pendingChecks = page.result;
        }
      })
      .error(() => notification.show("Failed to load registered tasks"));

    self.refreshSelectedCheck = () => {
      if (self.selectedNumber) {
        self.selectedCheck = _.find(self.pendingChecks, {number: self.selectedNumber});
        self.transaction.amount = self.selectedCheck.amount;
        self.transaction.number = self.selectedCheck.number;
        self.transaction.payee = self.selectedCheck.payee;
        self.transaction.purpose = self.selectedCheck.purpose;
        self.transaction.hookType = self.selectedCheck.hookType;
        self.transaction.hookId = self.selectedCheck.hookId;
      }
    };

    self.readCheckPurposeLabel = () => {
      if (!self.selectedCheck) return '';
      return {
        'REGULAR_WITHDRAWAL': 'Withdrawal',
        'INTEREST_WITHDRAWAL': 'Interest withdrawal',
        'PRINCIPAL_WITHDRAWAL': 'Principal withdrawal',
        'LOAN_RELEASE': 'Loan release'
      }[self.selectedCheck.purpose]
    };

    this.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    this.save = () => {
      actionCommand.execute('CASHIER_CHECK_ENCASHMENT', self.transaction)
        .success(() => {
          customerCache.refetch();
          loanCache.refetch();
        });
    }
  }
});
