import './lookup-helper.service'
import $ from 'jquery';

import module from 'module';
import _ from 'lodash';

const templateUrl = require('./lookup-customer.template.html');
module.component('lookupCustomer', {
  templateUrl: templateUrl,
  controller: function (http, $timeout, $location, $filter, authentication, popup,
                        branchService, branchWorkingDayCache, systemPropertyCache, requestFormatter,
                        lookupHelper) {
    let that = this;

    // Organization type
    that.organizationType = null;
    that.defaultUrl = '/customer/{}/accounts';
    that.productNumberMask = null;
    that.productNumberMaskSaved = null;
    that.showProductMask = false;

    that.branchNames = {};

    that.searchParams = {
      customerId: null,
      documentNumber: null,
      name: null,
      birthDate: null,
      productNumber: null,
      checkNumber: null
    };
    that.searchResults = null;
    that.overflow = false;
    that.searchPerformed = false;
    that.createCustomerEnabled = false;
    that.contextBranchId = authentication.context.branchId;

    that.allDefaultUrls = [
      '/customer/{}/loans',
      '/customer/{}/term-deposits',
      '/customer/{}/accounts',
      '/customer/{}/pawns'
    ];


    const branchSub = branchService.toObservable().subscribe(branches => {
      return branches.forEach(b => that.branchNames[b.id] = b.name);
    });

    const systemPropertiesSub = systemPropertyCache.toObservable().subscribe(properties => {
      // Load organization type
      const organizationType = _.find(properties, {code: 'ORGANIZATION_TYPE'});
      that.organizationType = organizationType === null ? 'BANK' : organizationType.value;
      const defaultUrl = _.find(properties, {code: 'DEFAULT_URL_AFTER_CST_LOOKUP'});
      if (defaultUrl && defaultUrl.value) {
        that.defaultUrl = defaultUrl.value;
      }
      // Load product number mask (if present it should be applied to UI)
      const productNumberMask = _.find(properties, {code: 'PRODUCT_NUMBER_MASK'});
      if (productNumberMask && productNumberMask.value) {
        that.productNumberMask = productNumberMask.value;
        that.productNumberMaskSaved = that.productNumberMask;
        that.showProductMask = true;
      }

    });

    const workingDaySub = branchWorkingDayCache.withParam(that.contextBranchId).toObservable().subscribe(workingDay => {
      that.workingDay = workingDay;
    });

    /**
     * Request validation is organization type dependent
     */
    that.validSearchRequest = () => {
      if (!that.organizationType) {
        return false;
      }
      switch (that.organizationType) {
        case 'PAWNSHOP':
          return that.searchParams.productNumber
            || (that.searchParams.name && (that.searchParams.birthDate || that.searchParams.isCorporate));
        case 'BANK':
          return that.searchParams.customerNumber
            || that.searchParams.documentNumber
            || that.searchParams.name
            || that.searchParams.productNumber
            || that.searchParams.checkNumber;
        default:
          return false;
      }
    };

    that.emptyResults = function () {
      return that.searchResults !== null && that.searchResults.length === 0;
    };

    that.showResults = function () {
      return that.searchPerformed && !that.emptyResults();
    };

    that.nullifyParams = function (searchParams) {
      _.each(searchParams, function (value, key) {
        if (value != null && $.trim(value) == '') {
          searchParams[key] = null;
        }
      });
    };

    /**
     * Customer creation is available if:
     *
     * 1. User has appropriate permission
     * 2. Working day is STARTED
     * 3. Customer lookup was already performed
     * 4. Search was performed using customer name
     *
     * @return true if new customer can be created
     */
    const setupCustomerCreation = () => {
      const hasPermission = authentication.permissions['NEW_CUSTOMER_WRITE'];
      const workingDayStatus = that.workingDay && that.workingDay.status === 'STARTED';

      that.createCustomerEnabled = hasPermission
        && workingDayStatus
        && that.searchPerformed === true
        && that.searchParams.name;
    };

    that.search = function () {
      that.nullifyParams(that.searchParams);

      let queryParams = requestFormatter.convertToQueryParams(that.searchParams);
      http.get(`/customers${queryParams}`, {nxLoaderText: 'Searching for customer...'})
        .success(function (data) {
          that.searchPerformed = true;
          that.searchResults = _.filter(data, (c) => c.customerStatus === 'ACTIVE' || c.customerStatus === 'PROSPECT');
          // If search result contains exactly one active customer -> open it
          if (that.searchResults.length === 1 && that.searchResults[0].customerStatus === 'ACTIVE') {
            if (that.searchResults[0].productGroup) {
              that.openCustomerProduct(that.buildRedirectUrl(that.searchResults[0]));
            } else {
              that.openCustomer(that.searchResults[0].customerId);
            }
          } else {
            setupCustomerCreation();
          }
        });
    };

    that.buildRedirectUrl = (data) => {
      if (data.productGroup === 'ACCOUNT' && authentication.permissions['CST_DEPOSIT_ACCOUNT_READ']) {
        return `/customer/${data.customerId}/accounts/${data.productId}`;
      } else if (data.productGroup === 'DEPOSIT' && authentication.permissions['CST_TERM_DEPOSIT_READ']) {
        return `/customer/${data.customerId}/term-deposits/${data.productId}`;
      } else if (data.productGroup === 'LOAN' && authentication.permissions['CST_LOANS_READ']) {
        return `/customer/${data.customerId}/loans/${data.productId}`;
      } else if (data.productGroup === 'PAWN' && authentication.permissions['CST_PAWNS_READ']) {
        return `/customer/${data.customerId}/pawns/${data.productId}`;
      } else if (data.productGroup === 'LAYAWAY' && authentication.permissions['CST_LAYAWAYS_READ']) {
        return `/customer/${data.customerId}/layaways/${data.productId}`;
      } else {
        return null;
      }
    };

    that.switchProductMask = () => {
      if(that.showProductMask) {
        that.productNumberMask = that.productNumberMaskSaved;
      } else {
        if(that.searchParams.productNumber) {
          that.searchParams.productNumber = lookupHelper.removeSpecialCharsFromMask(that.searchParams.productNumber, that.productNumberMaskSaved);
        }
        that.productNumberMask = null;
      }
    };

    that.openCustomerProduct = (redirectionUrl) => {
      if (redirectionUrl) {
        $location.path(redirectionUrl);
      }
    };

    that.openCustomer = (customerId) => {
      let currentUrl = that.defaultUrl;

      let i = 0;
      while(currentUrl && !that.hasPermissionsForRedirection(currentUrl)) {
        currentUrl = that.allDefaultUrls[i];
        i++;
      }

      //When 'i' is greater than allDefaultUrls length, currentUrl is set undef
      if(!currentUrl) {
        popup({text: "Insufficient permissions to open customer profile", callback: () => {}});
      }

      let redirectionUrl = currentUrl.replace('{}', customerId);
      $location.path(redirectionUrl);
    };

    that.hasPermissionsForRedirection = (defaultRedirectionUrl) => {
      if ('/customer/{}/loans' === defaultRedirectionUrl) {
        return authentication.permissions['CST_LOANS_READ'];
      } else if ('/customer/{}/pawns' === defaultRedirectionUrl) {
        return authentication.permissions['CST_PAWNS_READ'];
      } else if ('/customer/{}/layaways' === defaultRedirectionUrl) {
        return authentication.permissions['CST_LAYAWAYS_READ'];
      } else if ('/customer/{}/term-deposits' === defaultRedirectionUrl) {
        return authentication.permissions['CST_TERM_DEPOSIT_READ'];
      } else if ('/customer/{}/accounts' === defaultRedirectionUrl) {
        return authentication.permissions['CST_DEPOSIT_ACCOUNT_READ'];
      } else {
        return true;
      }
    };

    that.createCustomer = () => {
      $location.path('dashboard/new-customer');
    };

    that.setCorporate = () => {
      if (that.searchParams.isCorporate) {
        that.searchParams.customerType = 'CORPORATE';
      } else {
        that.searchParams.customerType = null;
      }
    };

    that.$onDestroy = () => {
      branchSub.unsubscribe();
      systemPropertiesSub.unsubscribe();
      workingDaySub.unsubscribe();
    };
  }
});
