import module from 'module';

const templateUrl = require('./sub-header.template.html');
module.component('subHeader', {
  templateUrl,
  transclude: true,
  bindings: {
    label: '<',
    title: '=',
    tab: '<'
  }
});