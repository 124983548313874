import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('actionCategoryCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/ledger/actions/categories'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.actionCategory,
    cacheName: 'actionCategory'
  })
);
