import module from 'module';

const templateUrl = require('./pawn-stones-predicate.template.html');
module.component('pawnStonesPredicate', {
  templateUrl,
  bindings: {
    predicates: '=',
    type: '<'
  },

  controller: function ($scope) {
    const that = this;
    $scope.$watchCollection('$ctrl.predicates', () => {
      if (!that.predicates) {
        that.value = null;
      } else {
        that.value = that.predicates[that.type];
      }
    });
    that.valueChanged = () => {
      that.predicates[that.type] = that.value;
    };
  }
});
