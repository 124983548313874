import module from "module";
import templateUrl from './modal.template.html';


class ModalApi {
  constructor($modal) {
    this.$modal = $modal;

    // handle clicks on backdrop
    this.$modal.on('hidden', () => {
      if(this.resolve) {
        this.resolve({accepted: false});
        this.resolve = null;
      }
    })
  }

  /**
   * Used to display modal. Will be resolved with value {accepted: true|false} depending on user selection.
   */
  show() {
    return new Promise((resolve, ) => {
      this.resolve = resolve;
      this.$modal.modal('show');
    })
  }

  /**
   * Provided by the api for someone who would like to override default buttons and still
   * benefit from show().
   */
  onOk() {
    this.resolve({accepted: true});
    this.resolve = null;
    this.$modal.modal('hide');
  }

  /**
   * Provided by the api for someone who would like to override default buttons and still
   * benefit from show().
   */
  onCancel() {
    this.resolve({accepted: false});
    this.resolve = null;
    this.$modal.modal('hide');
  }
}


class Modal {
  constructor($element) {
    this.$element = $element;
  }

  $postLink() {
    this.api = new ModalApi(this.$element.find('.modal'));
    this.onApiReady({api: this.api});
  }

  onOk() {
    this.api.onOk();
  }

  onCancel() {
    this.api.onCancel();
  }
}


module.component('modal', {
  templateUrl,
  transclude: {
    'header': 'modalHeader',
    'body': 'modalBody',
    'footer': '?modalFooter'
  },
  bindings: {
    onApiReady: '<', // function receiving {api} as a parameter
    form: '<' // optional form used to enable/disable ok validation
  },
  controller: Modal
});