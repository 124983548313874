import module from 'module';

const templateUrl = require('./group-data.template.html');
module.component('groupData', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '=',
    'form': '='
  },
  controller: function ($scope, $timeout, dict) {
    let that = this;

    that.dict = dict;
  }
});
