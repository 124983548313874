import module from 'module';

import templateUrl from './password.template.html';

class Password {
  constructor(systemPropertyCache) {
    this.passwordMinLength = 8;

    this.allValidationRules = {
      ONE_NUMBER: /\p{Nd}/u,
      ONE_LOWERCASE_LETTER: /\p{Ll}/u,
      ONE_UPPERCASE_LETTER: /\p{Lu}/u,
      ONE_SPECIAL_CHARACTER: / |[!-/]|[:-@]|[\^-`]|[{-~]/ ,
    };

    this.validationRules = {};

    systemPropertyCache.toObservable()
      .first()
      .subscribe(properties => {
        const complexity = properties.find(prop => prop.code === 'PASSWORD_COMPLEXITY');
        const minLength = properties.find(prop => prop.code === 'PASSWORD_MIN_LENGTH');

        if(minLength) {
          this.passwordMinLength = parseInt(minLength.value || '0');
        }

        if(!complexity) {
          return;
        }

        this.validationRules = {};
        const validations = complexity.value.split(',');
        validations.filter(val => val) // remove empty
          .forEach(validationRule => {
            this.validationRules[validationRule] = this.allValidationRules[validationRule];
          })
      });
  }

  errorText() {
    const validationText = [
      this.form.password.$error.minlength && `${this.passwordMinLength} characters`,
      this.form.password.$error.oneNumber && 'one number',
      this.form.password.$error.oneLowercaseLetter && 'one lowercase letter',
      this.form.password.$error.oneUppercaseLetter && 'one uppercase letter',
      this.form.password.$error.oneSpecialCharacter && 'one special character',
    ].filter(val => val);

    return validationText.join(", ");
  }

  validate(value, type) {
    const validation = this.validationRules[type];
    if(validation && value) {
      return validation.test(value);
    }

    return true;
  }
}


module.component('password', {
  templateUrl,
  require: {
    'form': '^^form',
  },
  bindings: {
    model: '=',
    ngDisabled: '<',
    ngRequired: '<'
  },
  controller: Password
});
