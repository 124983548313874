import module from 'module';

const templateUrl = require('./pawn-cycle-counter.template.html');
module.component('pawnCycleCounter', {
  templateUrl,
  bindings: {
    pawnType: '=',
    form: '='
  },
  controller: function ($scope) {
    $scope.$watch('$ctrl.pawnType.cycleCounting', () => {
      if (this.pawnType.cycleCounting && (!this.pawnType.cycles || this.pawnType.cycles.length === 0)) {
        this.pawnType.cycles = [0];
      }
    });

    this.add = () => {
      if (!this.pawnType.cycles) this.pawnType.cycles = [];
      this.pawnType.cycles.push(0);
      this.form.$setDirty();
    };

    this.remove = () => {
      this.pawnType.cycles.splice(this.pawnType.cycles.length - 1, 1);
      this.form.$setDirty();
    };

    this.range = function(min, max) {
      let input = [];
      for (let i = min; i <= max; i += 1) {
        input.push(i);
      }
      return input;
    };
  }
});
