import module from 'module';
import { NgTableParams } from 'ng-table/ng-table.js';
import templateUrl from './check-list.template.html';
import './check-list.less';

class CheckList {
  constructor(http, authentication, requestFormatter) {
    this.http = http;
    this.requestFormatter = requestFormatter;
    this.branchId = authentication.context.branchId;
    this.userId = authentication.context.realUserId;

    // Table configuration
    this.recordsPerPage = 50;
    this.currentPage = 0;
    
    this.total = 0;
    this.selected = [];
    this.checksOnCurrentPage = [];

    this.StatusTransfer = {
      TRANSFERRED_TO_CASHIER: 'TRANSFERRED_TO_CASHIER',
      TRANSFERRED_TO_TELLER: 'TRANSFERRED_TO_TELLER'
    };
  }

  $onInit() {
    this.tableConfig = new NgTableParams({
      page: 1,
      count: this.recordsPerPage,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: (params) => {
        this.currentPage = params._params.page - 1;
        
        return this.getChecksOnCurrentPage()
          .then(res => {
            params.total(res.totalCount);
            this.total = res.totalCount;
            this.checksOnCurrentPage = res.result;
            
            return res.result;
          });
      }
    });
  }

  getChecksOnCurrentPage() {
    const url = '/checks/incoming';
    const params = this.getQueryParams();
    return this.http.get(url, { params }).toPromise();
  }

  getQueryParams() {
    let params = {
      transferStatus: this.transferStatus,
      pageSize: this.recordsPerPage,
      pageNo: this.currentPage.toString()
    };
    
    switch(this.transferStatus) {
      case this.StatusTransfer.TRANSFERRED_TO_CASHIER:
        params['branchId'] = this.branchId;
        break;
      case this.StatusTransfer.TRANSFERRED_TO_TELLER:
        params['heldBy'] = this.userId;
        break;
      default:
        console.error('No transfer status provided!');
    }
    
    return params;
  }

  isAllChecksMarked() {
    return this.checksOnCurrentPage.every(check => this.isCheckMarked(check));
  }

  isCheckMarked(selectedCheck) {
    return this.selected.find(check => check.id === selectedCheck.id) ? true : false;
  }

  toggleAllChecks(e) {
    const isChecked = e.target.checked;
    if (isChecked) {
      this.markAllChecks();
    } else {
      this.checksOnCurrentPage.forEach(check => {
        this.removeSingleCheck(check);
      })
    }

    this.onCheckChange({checks: this.selected});
  }

  markAllChecks() {
    let uncheckedChecks = [];
    
    if (this.selected.length > 0) {
      uncheckedChecks = this.checksOnCurrentPage
        .filter(checkOnPage => !this.selected.some(check => checkOnPage.id === check.id));
    } else {
      uncheckedChecks = this.checksOnCurrentPage;
    }

    uncheckedChecks.forEach(check => {
      this.addSingleCheck(check);
    });
  }

  toggleSingleCheck(e, check) {
    const isChecked = e.target.checked;
    if (isChecked) {
      this.addSingleCheck(check);
    } else {
      this.removeSingleCheck(check);
    }
    this.onCheckChange({checks: this.selected});
  }

  addSingleCheck(check) {
    this.selected.push(check);
  }

  removeSingleCheck(selectedCheck) {
    const checkIndex = this.selected.findIndex(check => check.id === selectedCheck.id);
    this.selected.splice(checkIndex, 1);
  }
}

module.component('checkList', {
  bindings: {
    transferStatus: '<',
    onCheckChange: '&'
  },
  templateUrl,
  controller: CheckList
});