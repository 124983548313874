import module from 'module';

export const Statuses = {
  ALL: 'All',
  PENDING: 'Pending',
  PROCESSED: 'Posted',
  REJECTED: 'Revoked',
};

class GlTransactionService {
  constructor(http, command) {
    this.http = http;
    this.command = command;
  }

  fetchTransactions({
    ledgerId,
    postingDateFrom,
    postingDateTo,
    status,
    pageNo,
    pageSize,
  }) {

    if(status === 'ALL') {
      status = null;
    }

    return this.http.get(`/ledger/${ledgerId}/transactions/paged`, {
      params: {
        ledgerId,
        postingDateFrom,
        postingDateTo,
        status,
        pageNo,
        pageSize,
      }
    });
  }

  createTransaction({ledgerId, backdated, backdatedPostingDate,remarks, contingent, transactionType, transactionUnits}) {
    return this.command.execute(backdated ? 'CreateBackdatedLedgerTransaction' : 'CreateManualLedgerTransaction', {
      ledgerId,
      backdated,
      backdatedPostingDate,
      remarks,
      contingent,
      transactionType,
      transactionUnits,
    }, {nxLoaderText: 'Creating Transaction...'});
  }

  revokeTransaction({ledgerId, transactionId}) {
    return this.http.doDelete(`/ledger/${ledgerId}/transactions/${transactionId}`, {
      nxLoaderText: 'Revoking Transaction...'
    })
  }
}

module.service('glTransactionService', GlTransactionService);