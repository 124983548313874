import module from "module";

import './sss-online-employer-payment.component';
import './sss-online-individual-payment.component';

// Deprecated - Marked for removal
const templateUrl = require('./sss-online-payment.template.html');
module.component('sssOnlinePayment', {
  templateUrl: templateUrl,
  controller: function ($location, actionCommand, modalPrintPreviewService) {

    this.payorKind = 'INDIVIDUAL';

    this.onCancelChanges = actionCommand.cancelChanges;
    this.onSaved = ({response,}) => {
      modalPrintPreviewService.show('SSS_MISC_TRANSACTION_VALIDATION_SLIP', {
        paymentId: response.output.id,
        paymentMode: 'ONLINE'
      }, () => {
        $location.path("/dashboard/miscellaneous-transactions");
      });
    }
  }
});
