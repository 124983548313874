import module from 'module';

import templateUrl from './watchlist.template.html';
import './watchlist.style.less';

class WatchlistView {
  constructor(http) {
    this.http = http;
  }

  async $onInit() {
    this.watchlist = await this.http.get('/management/watchlist').toPromise();
  }
}

module.component('watchlistView', {
  templateUrl,
  controller: WatchlistView
});
