import module from 'module';
import _ from 'lodash';
import $ from 'jquery'

const templateUrl = require('./multiselect.template.html');
class Multiselect {
  constructor($scope, $element) {
    this.$scope = $scope;
    this.$element = $element;
    this.showDropdown = false;
    this.defaultPageSize = 100;

    this.hideDropdownListener = (e) => {
      const $clickedItem = $(e.target);
      if($clickedItem.closest(this.$element).length > 0){
        return;
      }
      this.$scope.$apply(() => this.showDropdown = false);
    }
  }

  $onInit() {
    $(document).on("click", this.hideDropdownListener);

    this.ngModelCtrl.$isEmpty = (value) => {
      return _.isEmpty(value);
    };

    this.totalDisplayedCount = this.getDisplaySize();
  }

  $onChanges(changes){
    if(changes.hasOwnProperty("items")){
      this.deepCopyItems = this.items ? _.cloneDeep(this.items) : [];
      if(this.deepCopyItems.length < this.totalDisplayedCount){
        this.totalDisplayedCount = this.getDisplaySize();
      }
    }

    if(changes.hasOwnProperty("ngModel") && changes['ngModel'] != null){
      const isNull = (this.isNullSelectAll() && this.ngModel && this.ngModel.length === 1 && this.ngModel[0] === null);
      const isSameLength = this.items && this.items.length > 0 && this.ngModel && this.ngModel.length === this.items.length;
      this.selectAllSelected = isNull || isSameLength;
    }
    this.updateItems();
  }

  $onDestroy() {
    $(document).off("click", this.hideDropdownListener);
  }

  isSelectAllOption() {
    return !!this.selectAllOption;
  }

  isNullSelectAll(){
    return !!this.nullSelectAll;
  }

  getUnfolded() {
    return !!this.unfolded;
  }

  getLabelProperty() {
    return this.labelProperty || 'label';
  }

  getLabelPropertyValue(item) {
    if (!item) {
      return '';
    }

    const property = this.getLabelProperty().split('.');

    let propertyValue;
    property.forEach(prop => {
      if (prop != null) {
        propertyValue = propertyValue ? propertyValue[prop] : item[prop];
      }
    });

    return propertyValue;
  }

  getValueProperty() {
    return this.valueProperty || 'value';
  }

  getDisplaySize() {
    return this.displaySize  || this.defaultPageSize;
  }

  getSelectedItems(){
    return this.deepCopyItems ? this.deepCopyItems.filter(item => item.checked).map(item => item[this.getValueProperty()]) : [];
  }

  updateNgModel() {
    if(this.isNullSelectAll() && this.selectAllSelected){
      this.ngModelCtrl.$setViewValue([null]);
    } else {
      this.ngModelCtrl.$setViewValue(this.getSelectedItems());
    }
  }

  updateItems() {
    if(!this.deepCopyItems || !this.ngModel) return;
    for(let item of this.deepCopyItems){
      item.checked = this.selectAllSelected || _.includes(this.ngModel, item[this.getValueProperty()]);
    }
  }

  isAllDisplayed(){
    return this.totalDisplayedCount >= this.deepCopyItems.length;
  }

  selectAllChange() {
    this.deepCopyItems.forEach(el => el.checked = this.selectAllSelected);
    this.updateNgModel();
  }

  onCheckboxChange(){
    this.selectAllSelected = this.getSelectedItems().length === this.items.length;
    this.updateNgModel();
  }

  onLoadMoreClick(){
    const notDisplayedCount = this.deepCopyItems.length - this.totalDisplayedCount;
    if(notDisplayedCount >= this.getDisplaySize()){
      this.totalDisplayedCount += this.getDisplaySize();
    } else {
      this.totalDisplayedCount += notDisplayedCount;
    }
  }

  renderLabel() {
    if(this.selectAllSelected){
      return "All items selected";
    }

    const selectedItems = this.getSelectedItems();
    const length = selectedItems.length;
    if(length === 1){
      const value = selectedItems[0];
      const item = _.find(this.deepCopyItems, i => i[this.getValueProperty()] === value);
      return this.getLabelPropertyValue(item);
    } else {
      return `${length} items selected`;
    }
  }
}

module.component('multiselect', {
  templateUrl,
  require: {
    ngModelCtrl: 'ngModel'
  },
  bindings: {
    items: '<',
    ngModel: '<',
    inactive: '<',
    ngDisabled: '<',
    ngRequired: '<',
    labelProperty: '<',
    valueProperty: '<',
    unfolded: '<',
    selectAllOption: '<',
    nullSelectAll: '<',
    validate: '<',
    displaySize: '<'
  },
  controller: Multiselect
});
