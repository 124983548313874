import _ from 'lodash'
import BigNumber from 'bignumber.js';

import module from 'module';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/first';

class PawnMetalDescriptionService {
  constructor($filter, pawnMetalRateSingleCache) {
    this.$filter = $filter;
    this.pawnMetalRateSingleCache = pawnMetalRateSingleCache;
  }


  pushDetail(details, label, value, include = true, nullLabel = '-') {
    if (include) {
      details.push({
        label: label,
        value: value !== null ? value : nullLabel
      });
    }
  }


  pushMetalDetails(details, weights) {
    let tmp = [];
    let totalGold = 0;
    let totalPlatinum = 0;

    for (let [fineness, obj] of Object.entries(weights)) {
      let metalType = obj.type;
      tmp.push(obj);

      if (metalType === 'gold') {
        totalGold = totalGold + obj.weight;
      } else if (metalType === "platinum") {
        totalPlatinum = totalPlatinum + obj.weight;
      }
    }

    tmp = tmp.sort(it => parseInt( it.fineness.replace('k','')));

    for (let obj of tmp) {
      let txt = "Total " + obj.type + " weight " + obj.fineness;

      details.push({
        label: txt,
        value: (new BigNumber(obj.weight).round(2).toNumber() || 0) + ' gr'
      })
    }
    if (totalGold != 0) {
      details.push({
        label: 'Total gold weight',
        value: new BigNumber(totalGold).round(2).toNumber() + ' gr'
      })
    }
    if (totalPlatinum != 0) {
      details.push({
        label: 'Total platinum weight',
        value: new BigNumber(totalPlatinum).round(2).toNumber() + ' gr'
      })
    }
  }


  calculateMetalWeight(result, metal, pawnMetalRates) {
    let rate = _.find(pawnMetalRates, {id: parseInt(metal.metalRateId)});

    if (!result[rate.fineness]) {
      result[rate.fineness] = {};
      let metalType = rate.name.toLowerCase();
      result[rate.fineness].type = metalType;
      result[rate.fineness].fineness = rate.fineness;

    }

    let metalResult = result[rate.fineness].weight || 0.;
    result[rate.fineness].weight = metalResult + parseFloat(metal.weight);
  }


  calculateMetalWeights(items, metalRatesObservables, details) {
    const result = {};

    Observable.forkJoin(...metalRatesObservables).subscribe(pawnMetalRates => {
      pawnMetalRates = _.flatten(pawnMetalRates);
      for (let item of items) {
        if (item.metal) { //Laptop have null in metal field
          if (!item.metal.components || item.metal.components.length === 0) {
            this.calculateMetalWeight(result, item.metal, pawnMetalRates);
          } else { //Tri/Bi Component
            for (let component of item.metal.components) {
              this.calculateMetalWeight(result, component, pawnMetalRates);
            }
          }
        }
      }
      this.pushMetalDetails(details, result);
    });

  }


  buildPawnMetalRatesObservables(items) {
    let observables = [];
    let queredIds = [];
    for (let item of items) {
      if(item.metal) { //Laptop have null in metal field
        if (!item.metal.components || item.metal.components.length === 0) {
          if (!queredIds.includes(item.metal.metalRateId)) {
            queredIds.push(item.metal.metalRateId);
            observables.push(
              this.pawnMetalRateSingleCache.withParam(item.metal.metalRateId).toObservable().first()
            )
          }
        } else {
          for (let component of item.metal.components) {
            if (!queredIds.includes(component.metalRateId)) {
              queredIds.push(component.metalRateId);
              observables.push(
                this.pawnMetalRateSingleCache.withParam(component.metalRateId).toObservable().first()
              );
            }
          }
        }
      }
    }

    return observables;
  }


  buildSelectedItemsInfo(branchName, auction, items) {
    let details = [];

    this.pushDetail(details, 'Branch name', branchName, true, 'All');
    this.pushDetail(details, 'Pawn auction', auction, true, 'All');
    this.pushDetail(details, 'Total items', items.length);
    let metalRatesObservables = this.buildPawnMetalRatesObservables(items);
    this.calculateMetalWeights(items, metalRatesObservables, details);

    return details;
  }
}

module.factory('pawnMetalDescriptionService', ($filter, pawnMetalRateSingleCache) =>
  new PawnMetalDescriptionService($filter, pawnMetalRateSingleCache)
);
