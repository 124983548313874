import module from 'module';
import 'rxjs/add/operator/concat';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import LocalCache from 'shared/util/localCache';
import MemoryFactory from 'shared/util/localCacheMemoryFactory'
import AccessType from 'constants/customerAccessType';


module.factory('customerCache', function (http, customerService, activeCustomerService, productCacheService) {
  let that = this;
  let service = {};

  service.loadedCustomerId = null;
  service.loadedCustomerType = null;
  service.hasInvalidData = null;
  service.hasExpiredData = null;
  that.cacheMap = {};

  service.profile = (customerId) =>
    retrieveCacheObject(customerId, 'profile', {
      provider: () => http.get(`/customers/${customerId}`)
        .success(profile => {
          if (profile.customerType === 'GROUP')
            console.error(`ERROR: loaded group customer. customerId: ${customerId}`);
          customerService.extractPhones(profile);
          service.loadedCustomerType = profile.customerType;
          service.hasInvalidData = profile.hasInvalidData;
          service.hasExpiredData = profile.hasExpiredData;
        })
    });

  /**
   * fullAccess equals false means that stream of data from cache will contain
   * only products that current user has access to.
   */
  service.depositAccounts = (customerId, fullAccess=true) => {
    const cache = retrieveCacheObject(customerId, 'customerDepositAccounts', {
      provider: () => http.get(`/products/accounts?customerId=${customerId}&includeOwnership=true`)
    });

    if(fullAccess) {
      return cache;
    }

    return productCacheService.withRestrictedAccess(cache, customerId, AccessType.NONGROUP);
  };

  /**
   * fullAccess equals false means that stream of data from cache will contain
   * only products that current user has access to.
   */
  service.loans = (customerId, fullAccess=true) => {
    const cache = retrieveCacheObject(customerId, 'customerLoans', {
      provider: () => http.get(`/products/loans?customerId=${customerId}&includeAmortization=true`)
    });

    if(fullAccess) {
      return cache;
    }

    return productCacheService.withRestrictedAccess(cache, customerId, AccessType.NONGROUP);
  };

  service.pawns = (customerId) =>
    retrieveCacheObject(customerId, 'customerPawns', {
      provider: () => http.get(`/products/pawns?customerId=${customerId}&includeAmortization=true&includeItems=true&includeUidLedger=true`)
    });

  service.layaways = (customerId) =>
    retrieveCacheObject(customerId, 'customerLayaways', {
      provider: () => http.get(`/products/layaways?customerId=${customerId}&includeItems=true`)
    });

  /**
   * fullAccess equals false means that stream of data from cache will contain
   * only products that current user has access to.
   */
  service.termDeposits = (customerId, fullAccess=true) => {
    const cache = retrieveCacheObject(customerId, 'customerTermDeposits', {
      provider: () => http.get(`/products/deposits?customerId=${customerId}&includeOwnership=true`)
    });

    if(fullAccess) {
      return cache;
    }

    return productCacheService.withRestrictedAccess(cache, customerId, AccessType.NONGROUP);
  };

  service.customerProductFees = (customerId) =>
    retrieveCacheObject(customerId, 'customerProductFees', {
      provider: () => http.get(`/products/fees?customerId=${customerId}`)
    });

  // --------- technical functions -------------
  function retrieveCacheObject(customerId, cacheName, cacheOptions) {
    verifyCustomerId(customerId);

    // adds default params
    cacheOptions['cacheFactory'] = MemoryFactory;
    cacheOptions['cacheName'] = cacheName;

    if (!that.cacheMap[cacheName]) {
      that.cacheMap[cacheName] = new LocalCache(cacheOptions);
    }
    return that.cacheMap[cacheName];
  }

  service.refetch = () => {
    let keys = Object.keys(that.cacheMap);
    for (let key of keys) {
      console.log('Refetching customer data: ' + key);
      that.cacheMap[key].refetch();
    }
  };

  service.evict = () => {
    service.loadedCustomerId = null;
    service.loadedCustomerType = null;
    service.hasInvalidData = null;
    service.hasExpiredData = null;
    activeCustomerService.ofType(AccessType.NONGROUP).updateCustomerId(null);
    that.cacheMap = {};
  };

  service.evictWithoutCustomerId = () => {
    that.cacheMap = {};
  };

  service.unloadCustomer = () => {
    service.loadedCustomerId = null;
    service.loadedCustomerType = null;
    service.hasInvalidData = null;
    service.hasExpiredData = null;
    activeCustomerService.ofType(AccessType.NONGROUP).updateCustomerId(null);
    service.evict();
  };

  function verifyCustomerId(newCustomerId) {
    if (service.loadedCustomerId && String(service.loadedCustomerId) !== String(newCustomerId)) {
      that.cacheMap = {};
    }
    service.loadedCustomerId = newCustomerId;
    activeCustomerService.ofType(AccessType.NONGROUP).updateCustomerId(newCustomerId);
  }

  return service;
});
