import module from 'module';
import _ from 'lodash';

import templateUrl from './cash-transfer-to-cashier.template.html';

class CashTransferToCashierView {

  constructor(authentication, actionCommand, userCache, ledgerActionTemplateCache, systemPropertyCache,
              miscTransactionsService, commandAccessChecker, branchCurrencyCache, http, denominationSerialNumbersService) {
    this.authentication = authentication;
    this.actionCommand = actionCommand;
    this.userCache = userCache;
    this.ledgerActionTemplateCache = ledgerActionTemplateCache;
    this.systemPropertyCache = systemPropertyCache;
    this.miscTransactionsService = miscTransactionsService;
    this.commandAccessChecker = commandAccessChecker;
    this.branchCurrencyCache = branchCurrencyCache;
    this.http = http;
    this.denominationSerialNumbersService = denominationSerialNumbersService
  }

  async $onInit() {
    const branchId = this.authentication.context.branchId;

    this.currencies = await this.branchCurrencyCache.withParam(Number(branchId)).toPromise();
    const mainCurrencyIdx = _.findIndex(this.currencies, it => it.isoCode === 'PHP');
    if (mainCurrencyIdx > -1) {
      this.mainCurrency = this.currencies.splice(mainCurrencyIdx, 1)[0];
    }

    if (!this.forex) {
      this.currency = this.mainCurrency;
    }

    const [
      executorAssetCounters,
      users,
      templates,
      canApproveCommand,
      properties
    ] = await Promise.all([
      this.http.get(`/management/users/${this.authentication.context.id}/counter/all`).toPromise(),
      this.userCache.toPromise(),
      this.ledgerActionTemplateCache.toPromise(),
      this.commandAccessChecker.canApproveCommandPromise(),
      this.systemPropertyCache.toPromise()
    ]);

    this.canSaveNoteSerialNumber = _.find(properties, {code: 'FOREIGN_DENOMINATION_SERIAL_NUMBERS_ENABLED'}).value === 'TRUE';
    this.executorAssetCounters = executorAssetCounters;
    this.transaction = {};
    this.refreshTxAmountAndMaxAmount();

    const template = _.find(templates, {type: this.actionType});
    template.commandName = this.actionCommand.actionTypeToCommand(template.type);
    this.approvalUsers = this.miscTransactionsService.filterApprovalUsers(branchId, template, users, canApproveCommand)
      .filter(approvalUser => approvalUser.roles.some(au => au.cashierCounter));
  }

  getTitle() {
    return _.capitalize((this.forex ? 'forex ' : '') + 'cash transfer to cashier');
  }

  refreshAmountCurrency() {
    this.currency = this.currencies.find(c => c.id === this.transaction.currencyId);
    this.refreshTxAmountAndMaxAmount();
  }

  refreshTxAmountAndMaxAmount() {
    const currencyId = this.forex ? this.transaction.currencyId : this.mainCurrency.id;
    this.maxAmount = (this.executorAssetCounters.find(ac => ac.currencyId === currencyId) || {}).totalCash;
    this.transaction.amount = this.maxAmount;
  }

  isDenominationReady() {
    if (!this.transaction) {
      return false;
    }

    if (this.forex) {
      return this.transaction.currencyId != null;
    }

    return true;
  }

  cancelChanges() {
    this.actionCommand.cancelChanges();
  }

  save() {
    if (this.forex) {
      this.denominationSerialNumbersService.appendSerialNumbers(this.currency, this.transaction);
    }
    
    this.actionCommand.executeAndShowValidation(this.actionType, this.transaction);
  }
}

module.component('cashTransferToCashier', {
  templateUrl: templateUrl,
  bindings: {
    actionType: '@',
    forex: '<'
  },
  controller: CashTransferToCashierView
});
