import module from 'module';
import ParameterizedLocalCache from 'shared/util/parameterized-local-cache';

module.factory('pawnItemCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (params) => http.get(`/products/pawns/items/${params}`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.pawnItem,
    cacheName: 'pawnItemCache'
  })
);
