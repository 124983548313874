import module from 'module';
import moment from 'moment';
import $ from 'jquery'
import _ from 'lodash';

module.factory('requestFormatter', ($filter) => {
  return {
    formatDate: (input) => {
      const m = moment(input);
      return m.format('YYYY-MM-DD');
    },

    nullifyParams: (criteria) => {
      const criteriaCopy = angular.copy(criteria);
      _.each(criteriaCopy, function (value, key) {
        if (value != null && $.trim(value) == '') criteria[key] = null;
      });
      return criteriaCopy;
    },

    /**
     * Converts an object to request params
     */
    convertToQueryParams: (params) => {
      if (!params) {
        return "";
      }
      let queryString = "?";
      for (let key in params) {
        if (!params.hasOwnProperty(key)) {
          continue;
        }
        const rawParam = params[key];
        if (rawParam) {
          let value = "";
          if (rawParam instanceof Date) {
            value = $filter('nxDate')(rawParam)
          } else {
            value = escape(rawParam);
          }
          queryString += `${key}=${value}&`;
        }
      }

      return queryString.substring(0, queryString.length - 1);
    }

  }
});