import module from 'module';
import _ from 'lodash';

const templateUrl = require('./cash-withdraw.template.html');
module.component('customerAccountsCashWithdraw', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, http, customerCache, productDefinitionService,
                        accountOperationService) {
    let that = this;
    that.depositAccount = {};

    let customerId = $route.current.params['customerId'];
    let productId = $route.current.params['accountId'];

    customerCache.depositAccounts(customerId).toObservable()
      .combineLatest(productDefinitionService.toObservable(), (depositAccounts, products) =>
        depositAccounts.map(da => {
          const p = _.find(products, {id: da.definitionId});
          return Object.assign(da, {
            productName: p ? p.productName : '<Unknown product>',
          });
        })
      ).first()
      // productId is a string
      .subscribe(accounts => that.depositAccount = _.find(accounts, (a) => String(a.id) === productId));


    that.amountSelected = (amount) => {
      that.selectedAmount = amount;
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${productId}`);

    that.withdraw = () => {
      accountOperationService.cashWithdraw({
        customerId: customerId,
        productId: productId,
        amount: that.selectedAmount
      }, that.redirectBack);
    }
  }
});
