import module from 'module';

import templateUrl from './customer-stock-items.template.html';

import './customer-stock-items.style.less';

class CustomerStockItems {
  constructor($route) {
    this.$route = $route;
  }

  $onInit() {
    this.customerId = this.$route.current.params['customerId'];
    this.checkedMode = [];
    this.checkedItems = [];
  }
}

module.component('customerStockItems', {
  templateUrl: templateUrl,
  controller: CustomerStockItems
});
