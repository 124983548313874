import module from 'module';
import templateUrl from './gl-payment.template.html';
import {flattenSubaccounts} from "../../../general-ledger/common/gl.utils";

class GlPayment {
  constructor(glAccountService, glLedgerService) {
    this.glAccountService = glAccountService;
    this.glLedgerService = glLedgerService;

    this.loadOptions();
  }

  async loadOptions() {
    const ledgers = await this.glLedgerService.toPromise();
    const ledgersInLoanBranch = ledgers.filter(ledger => ledger.branchId === this.branch.id);
    const accounts = _.flatten(await Promise.all(ledgersInLoanBranch
      .map(ledger => this.glAccountService.fetchAccounts(ledger.id, {
        leafOnly: true
      }))
      .map(httpRes => httpRes.toPromise())
    ));

    this.accounts = flattenSubaccounts(accounts);
  }
}


module.component('glPayment', {
  templateUrl,
  bindings: {
    operation: '=',
    remarks: '=',
    branch: '<',
  },
  controller: GlPayment
});
