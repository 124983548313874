import module from 'module';
import _ from 'lodash';

import templateUrl from './stones.template.html';

class Stones {
  constructor(http, branchService, notification, confirmation, authentication, pawnItemStoneTypeCache, pawnItemStoneRateCache, command, $route) {
    this.http = http;
    this.branchService = branchService;
    this.notification = notification;
    this.confirmation = confirmation;
    this.authentication = authentication;
    this.pawnItemStoneTypeCache = pawnItemStoneTypeCache;
    this.pawnItemStoneRateCache = pawnItemStoneRateCache;
    this.command = command;
    this.$route = $route;
  }

  async $onInit() {
    const [types, rates] = await Promise.all([this.pawnItemStoneTypeCache.toPromise(), this.pawnItemStoneRateCache.toPromise()]);

    this.ratesByTypeMap = _.groupBy(rates, 'stoneTypeId');
    this.newRates = {};
    this.typeIdToType = types.reduce(
      (collector, type) => {
        const value = type['id'];
        collector[value] = type;
        return collector;
      }, {}
    )
  }

  resolveStoneTypeName(stoneTypeId) {
    return (this.typeIdToType[stoneTypeId] || {}).name;
  }

  isRateUpdateDisabled(stoneTypeId) {
    return (this.typeIdToType[stoneTypeId] || {}).evaluationFormula === 'RAPPAPORT_VALUE_AND_DOLLAR_RATE';
  }

  isSizeAlreadyDefined(stoneTypeId, size) {
    return this.ratesByTypeMap[stoneTypeId]
      .some(r => Number(r.name) === Number(size));
  }

  addNewRow(stoneTypeId) {
    const newEntry = {stoneTypeId: Number(stoneTypeId)};
    if (!this.newRates.hasOwnProperty(stoneTypeId)) {
      this.newRates[stoneTypeId] = [newEntry]
      return;
    }
    this.newRates[stoneTypeId].push(newEntry);
  }

  deleteRow(stoneTypeId, index) {
    this.newRates[stoneTypeId].splice(index, 1);
  }

  async save() {
    const confirmed = await this.confirmation('Do you want to update stone rates?');
    if (!confirmed) {
      return;
    }

    const flattenedRates = Object.values(this.ratesByTypeMap).flat()
      .concat(Object.values(this.newRates).flat());

    await this.command.execute('UpsertPawnItemStoneRates', {stoneRates: flattenedRates}).toPromise();
    this.pawnItemStoneRateCache.evict()
    this.$route.reload();
  }
}

module.component('stones', {
  templateUrl,
  controller: Stones
});
