class LocalCacheMemoryStorage {

  constructor() {
    this.data = {};
  }

  get(key) {
    return this.data[key] ? this.data[key] : null;
  }

  put(key, data) {
    this.data[key] = data;
  }

  remove(key) {
    delete this.data[key];
  }
}


export default {
  createCache: (cacheName) => new LocalCacheMemoryStorage(),
  get: (cacheName) => undefined
}
