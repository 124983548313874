import module from 'module';
import ParameterizedLocalCache from 'shared/util/parameterized-local-cache';

module.factory('certificateCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (productId) => http.get(`/products/${productId}/certificate`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.certificate,
    cacheName: 'certificateCache'
  })
);