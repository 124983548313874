import module from 'module';
import LocalCache from 'shared/util/localCache';
import ParameterizedLocalCache from 'shared/util/parameterized-local-cache';

module.factory('workingDaysCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/management/working-days', {nxLoaderSkip: true}),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.workingDays,
    cacheName: 'workingDays'
  })
);

module.factory('branchWorkingDayCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (branchId) => http.get(`/management/working-days?branchId=${branchId}`, {nxLoaderSkip: true}),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.branchWorkingDay,
    cacheName: 'branchWorkingDay'
  })
);
