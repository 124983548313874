import module from 'module';
import templateUrl from './transacting-state.template.html';
import _ from 'lodash';
import '../forex-panel.style.less';

class TransactingState {
  constructor(http, branchService, currencyCache, authentication, command, confirmation) {
    this.http = http;
    this.branchService = branchService;
    this.currencyCache = currencyCache;
    this.authentication = authentication;
    this.command = command;
    this.confirmation = confirmation;

    this.options = [
      {
        id: false,
        label: 'Disabled'
      },
      {
        id: true,
        label: 'Enabled'
      }
    ];
  }

  async $onInit() {
    this.selectedBranch = this.authentication.context.branchId;
    await this.refresh();
    this.changeOrganizationTransactingState();
  }

  async refresh() {
    await this.refetchData();
    this.refreshGroupedStates();
  }

  async refetchData() {
    const currencyPromise = this.currencyCache.toPromise();
    const branchesPromise = this.branchService.toPromise();
    const transactingStatesPromise = this.http.get('/forex/transacting-state').toPromise();

    [this.currencies, this.branches, this.transactingStates] = await Promise.all([currencyPromise,
      branchesPromise, transactingStatesPromise]);
  }

  refreshGroupedStates() {
    const statesByBranchId = _.groupBy(this.transactingStates, 'branchId');

    this.groupedStates = this.branches
      .map(br => ({
        branchId: br.id,
        branchName: br.name,
        transactingStates: (statesByBranchId[br.id] || [])
          .map(state => ({
            currency: this.currencies.find(c => Number(c.id) === Number(state.currencyId)),
            ...state
          }))
      }))
      .reduce((acc, val) => {
        acc[val.branchId] = val;
        return acc;
      }, {});
  }

  organizationTransactingStateChanged(transactingEnabled) {
    if (this.selectedBranch) {
      this.changeTransactingStates(this.groupedStates[this.selectedBranch].transactingStates, transactingEnabled);
    } else {
      Object.entries(this.groupedStates).forEach(([_, groupedState]) => {
        this.changeTransactingStates(groupedState.transactingStates, transactingEnabled);
      });
    }
  }

  changeTransactingStates(transactingStates, transactingEnabled) {
    transactingStates.forEach(state => {
      state.transactingEnabled = transactingEnabled;
      state.changed = true;
    });
    this.changeOrganizationTransactingState();
  }

  async resumeForexTransacting() {
    const message = this.selectedBranch ? 'Are you sure to resume transacting for all forex currencies?' :
      'Are you sure to resume transacting for all forex currencies in all branches?';
    const confirmed = await this.confirmation(message);
    if (confirmed) {
      this.organizationTransactingStateChanged(true);
      await this.save();
    }
  }

  async stopForexTransacting() {
    const message = this.selectedBranch ? 'Are you sure to stop transacting for all forex currencies?' :
      'Are you sure to stop transacting for all forex currencies in all branches?';
    const confirmed = await this.confirmation(message);
    if (confirmed) {
      this.organizationTransactingStateChanged(false);
      await this.save();
    }
  }

  async save() {
    this.changedStates = Object.values(this.groupedStates)
      .flatMap(groupedState => groupedState.transactingStates);

    // remove currency from transacting state - otherwise request payload may be too large
    this.changedStates.forEach(transactingState => transactingState.currency = null);

    await this.command.execute('UpdateTransactingState', {
      transactingStates: this.changedStates
    }).toPromise();

    this.refresh();
  }

  changeOrganizationTransactingState() {
    this.organizationTransactingState = this.selectedBranch ? this.groupedStates[this.selectedBranch].transactingStates.some(t => t.transactingEnabled) :
      Object.entries(this.groupedStates).some(([_, groupedState]) => groupedState.transactingStates.some(t => t.transactingEnabled));
  }
}

module.component('transactingState', {
  templateUrl,
  controller: TransactingState
});
