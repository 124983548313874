import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import {NgTableParams} from 'ng-table/ng-table.js';
import BigNumber from 'bignumber.js';

import module from 'module';
import './transaction-details-builder.service'
import './operation-table-builder.service'
import operationStatusToLabel from './operationStatusToLabel';

const templateUrl = require('./transaction-history.template.html');
module.component('transactionHistory', {
  templateUrl: templateUrl,
  transclude: true,
  bindings: {
    product: '<',
    groups: '<',
    status: '<',
    item: '=',
    productType: '<',
    hideAvailableBalanceAfter: '<',
    hideTotalBalanceAfter: '<'
  },
  controller: function (operationTableBuilder, reportModal, postingDateService, branchWorkingDayCache, $filter) {
    const that = this;
    that.transactionHistoryConfig = undefined;
    that.selectedTransactionId = undefined;
    that.statusToLabel = operationStatusToLabel;

    // Set default date range parameters to display current month
    if (that.productType === 'ACCOUNT') {
      postingDateService.getCurrentUserBranchPostingDate()
        .then(date => {
          that.dateTo = date.clone().format('YYYY-MM-DD');
          that.dateFrom = date.clone().subtract(1, "month").format('YYYY-MM-DD');
        });
    }

    that.setItem = (item) => {
      that.item = item;
    };

    that.hideInlinePanel = () => {
      that.selectedTransactionId = null;
    };

    /** This method adds branchWorkingDay data to the transaction for later use in revert-command.service */
    that.transactionClicked =  async (transaction, $event) => {
      transaction.branchWorkingDay = await branchWorkingDayCache.withParam(transaction.registrationBranchId).toPromise();
      $event.stopPropagation();
      that.selectedTransactionId = transaction.id;
    };

    that.abs = (number) => {
      var bigNumber = new BigNumber(number);
      bigNumber = bigNumber.abs();
      return parseFloat(bigNumber);
    };

    that.getOperationCode = (operation) => {
      if (that.product && that.product.id) {
        return operation.target.id === that.product.id ? operation.targetCode : operation.sourceCode;
      }
    };

    that.translate = operation => {
      const customFeeOperationCodePrefix = 'APPLY_FEE_CUSTOM';
      const code = that.getOperationCode(operation);
      if(code.startsWith(customFeeOperationCodePrefix)) {
        return code.substring(customFeeOperationCodePrefix.length + 1);
      }

      return $filter('translate')(code, 'OPERATION_NAME');
    };

    that.translate = operation => {
      const customFeeOperationCodePrefix = 'APPLY_FEE_CUSTOM';
      const code = that.getOperationCode(operation);
      if(code.startsWith(customFeeOperationCodePrefix)) {
        return code.substring(customFeeOperationCodePrefix.length + 1);
      }

      return $filter('translate')(code, 'OPERATION_NAME');
    };

    that.resetTransactions = () => {
      if (that.product && that.product.id) {
        that.transactionHistoryConfig = operationTableBuilder.buildNgTable({
          productId: that.product.id,
          status: that.status,
          groups: that.groups,
          passbookOnly: false,
          dateFrom: that.dateFrom,
          dateTo: that.dateTo
        });
      }
    };

    that.printTransactions = () => {
      let params = {
        productId: that.product.id,
        status: that.status,
        groups: that.groups,
        dateFrom: that.dateFrom,
        dateTo: that.dateTo
      };
      let reportName = 'TransactionHistoryReport';
      reportModal.display({
        params: params,
        reportCode: reportName,
        hideXls: true
      });
    };

    that.$onChanges = function (changes) {
      if (changes.hasOwnProperty('product')) that.resetTransactions();
    };
  }
});
