import EventEmitter from 'wolfy87-eventemitter';
import observableService from './observableService';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/first';

export const storagePrefix = 'nx__';

export default class LocalCache {

  constructor({cacheFactory, cacheName, cacheTime, provider, postProcessor}) {
    this.provider = provider;
    this.emitter = new EventEmitter();
    this.currentlyFetching = false;
    this.postProcessor = postProcessor;

    this.cacheKey = 'key';

    this.cache = cacheFactory.get(cacheName);
    if (!this.cache) {
      // cache was not loaded into memory (but it could exist in localStorage)
      this.cache = cacheFactory.createCache(cacheName, {
        maxAge: cacheTime,
        deleteOnExpire: 'aggressive',
        storageMode: 'localStorage',
        storagePrefix: storagePrefix,
      });
    }
  };

  subscribe(listener) {
    this.emitter.addListener(this.cacheKey, listener);
    const lastValue = this.cache.get(this.cacheKey);
    if (lastValue) {
      listener(undefined, lastValue);
    } else {
      this.refetch();
    }
  };

  refetch() {
    if (this.fetching()) {
      return;
    }

    this.cache.remove(this.cacheKey);

    this.provider()
      .success(response => {
        if (this.postProcessor) response = this.postProcessor(response);
        this.cache.put(this.cacheKey, response);
        this.emitter.emit(this.cacheKey, undefined, response);
      })
      .error(error => this.emitter.emit(this.cacheKey, error))
      .always(() => {
        this.fetching(false);
      });

    this.fetching(true);
  };

  evict() {
    this.cache.remove(this.cacheKey);
  }

  fetching(value) {
    if (value !== undefined) {
      this.currentlyFetching = value;
    }

    return !!this.currentlyFetching;
  };

  unsubscribe(listener) {
    this.emitter.removeListener(this.cacheKey, listener);
  };

  toObservable() {
    return observableService(this);
  }

  toPromise() {
    return observableService(this)
      .first()
      .toPromise();
  }
}
