import module from "module";

const templateUrl = require('./loan-product-list.template.html');
module.component('loanProductList', {
  templateUrl,
  controller: function (loanProductsCache) {

    this.loanProducts = [];

    loanProductsCache.toObservable().subscribe(data => this.loanProducts = data ? data : []);


  }
});
