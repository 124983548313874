import module from 'module';
import _ from 'lodash';

module.factory('productLockService', function ($filter, confirmationTemplate, command, productLockCache) {
  const service = this;

  const execCallback = (callback) => {
    if (callback && typeof callback === 'function') callback();
  };

  service.createLock = (product, data, callback) => {
    confirmationTemplate({
      question: 'Do you want to lock the product?',
      details: [
        {label: 'Product number', description: product.productNumber},
        {label: 'Lock type', description: $filter('prettyEnum')(data.type)}
      ],
      yesCallback: () => command.execute('CreateProductLock', data).success(() => execCallback(callback))
    });
  };

  service.releaseProductLocks = (product, remarks, callback) => {
    confirmationTemplate({
      question: 'Do you want to release all of the product locks?',
      details: [
        {label: 'Product number', description: product.productNumber}
      ],
      yesCallback: () => {
        productLockCache.withParam(product.id).toObservable().first().subscribe(locks => {
          if (!_.isEmpty(locks) && locks.some(lock => !lock.released)) {
            const input = locks.filter(l => !l.released).map(l => {
              return {lockId: l.id, releaseRemarks: remarks};
            });
            command.execute('BatchReleaseProductLock', {locks: input}).success(() => execCallback(callback));
          }
        });
      }
    });
  };

  return service;
});
