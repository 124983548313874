import module from "module";
import $ from "jquery";
import {NgTableParams} from 'ng-table/ng-table.js';

const templateUrl = require('./product-holds.template.html');
module.component('productHolds', {
  templateUrl: templateUrl,
  bindings: {
    product: '<'
  },
  controller: function ($filter, http, confirmationTemplate, command) {

    const that = this;
    that.holdsTable = undefined;

    const convertFilterToQueryString = (params) => {
      return $.param({
        pageNo: params.page() - 1,
        pageSize: params.count(),
        productId: that.product.id
      });
    };

    const createSearchQueryPromise = (params) => {
      const queryParams = convertFilterToQueryString(params);
      return http.get(`/products/holds?${queryParams}`, {nxLoaderText: 'Loading holds'}).success(page => {
        that.holdsTable.data = page.result;
        that.holdsTable.total(page.totalCount);
      });
    };

    that.resetHolds = () => {
      if (that.product && that.product.id) {
        that.holdsTable = new NgTableParams({
          count: 20,
        }, {
          counts: [],
          paginationMaxBlocks: 5,
          paginationMinBlocks: 5,
          getData: params => createSearchQueryPromise(params)
        });
      }
    };

    that.onHoldClick = (hold, $event) => {
      $event.stopPropagation();
      that.selectedHoldId = hold.id;
    };

    that.releaseHold = (hold) => {
      confirmationTemplate({
        question: `Do you want to release hold of ${hold.amount} PHP?`,
        details: [
          {label: 'Type', description: $filter('prettyEnum')(hold.type)},
          {label: 'Created on', description: $filter('prettyDate')(hold.createdOn)},
          {label: 'Creation remarks', description: hold.creationRemarks}
        ],
        warning: 'This action cannot be reverted',
        yesCallback: () => {
          command.execute('ReleaseManualHold', {holdId: hold.id}).success(() => that.resetHolds());
        }
      });
    };

    that.$onInit = () => {
      that.resetHolds();
    };

    that.$onChanges = (changes) => {
      if (changes.hasOwnProperty('product')) that.resetHolds();
    };
  }
});
