import module from 'module';
import _ from 'lodash';
import LocalCache from 'shared/util/localCache';

module.factory('userCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/management/users', {nxLoaderSkip: true}),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.users,
    cacheName: 'users',
    postProcessor: users =>
      users.map(u => {
        const middle = (u.middleName ? u.middleName + ' ' : '');
        u.effectiveName = `${u.username} (${u.firstName} ${middle}${u.lastName})`;
        u.fullName = `${u.firstName} ${middle}${u.lastName}`;

        // Create user full name with roles, e.g. 'Joe H. Doe (teller, collector)'
        u.fullNameWithRoles = u.fullName;
        if (u.roles && u.roles.length > 0) {
          const userRoles = `(${_.map(u.roles, 'name').join(', ')})`;
          u.fullNameWithRoles = u.fullName + ' ' + userRoles;
        }
        return u;
      })
  }));
