import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('pawnItemStoneCutGradeCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get(`/products/pawns/items/stone-cut-grades`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.pawnItemStoneCutGradeConfig,
    cacheName: `pawnItemStoneCutGradeConfig`
  })
);
