import module from "module";
import BigNumber from 'bignumber.js';
import _ from 'lodash';

import {addMaskToRegex, ssNumberPattern, validateStartEndRegex,} from './sss-offline-payment.utils';
import {confirmationMessage} from "../sss/sss.utils";

// Deprecated - Marked for removal
const templateUrl = require('./sss-offline-payment.template.html');
module.component('sssOfflinePayment', {
  templateUrl: templateUrl,
  controller: function ($location, http, actionCommand, command, translationCache,
                        modalPrintPreviewService, postingDateService, confirmation,
                        $filter) {
    this.payment = {};

    this.paymentTypes = [
      'MEMBER_LOAN',
      'MISC_SOCIAL_SECURITY_CARD',
      'PESO_FUND',
      'REAL_ESTATE_LOAN',
      'CALAMITY_LOAN',
      'EDUCATIONAL_LOAN',
      'LOAN_RESTRUCTURING'
    ];

    this.allPayorTypes = [
      'EMPLOYER',
      'FARMER_FISHERMAN',
      'HOUSEHOLD_EMPLOYER',
      'INDIVIDUALS',
      'NON_WORKING_SPOUSE',
      'OVERSEAS_WORKER',
      'SELF_EMPLOYED_MEMBER',
      'VOLUNTARY_MEMBER',
    ];

    const onlyLoanPaymentTypes = [
      'REAL_ESTATE_LOAN',
      'CALAMITY_LOAN',
      'EDUCATIONAL_LOAN',
      'LOAN_RESTRUCTURING'
    ];

    postingDateService.getCurrentUserBranchPostingDate().then(date => this.postingDate = date);

    this.availablePayorTypes = () => {
      if (!this.payment.paymentType) {
        return [...this.allPayorTypes];
      }

      if ('MISC_SOCIAL_SECURITY_CARD' === this.payment.paymentType) {
        return this.allPayorTypes.filter(payor => payor !== 'EMPLOYER' && payor !== 'HOUSEHOLD_EMPLOYER');
      }

      if ('CONTRIBUTION' === this.payment.paymentType) {
        return this.allPayorTypes.filter(payor => payor !== 'INDIVIDUALS');
      }

      if (['MEMBER_LOAN', 'REAL_ESTATE_LOAN', 'CALAMITY_LOAN', 'EDUCATIONAL_LOAN', 'LOAN_RESTRUCTURING'].includes(this.payment.paymentType)) {
        return ['INDIVIDUALS', 'EMPLOYER'];
      }

      if ('PESO_FUND' === this.payment.paymentType) {
        return this.allPayorTypes.filter(payor => !['NON_WORKING_SPOUSE', 'EMPLOYER', 'HOUSEHOLD_EMPLOYER'].includes(payor));
      }

      return [...this.allPayorTypes];
    };

    this.onPaymentTypeChange = () => {
      if (!this.availablePayorTypes().includes(this.payment.payorType)) {
        this.payment.payorType = null;
      }

      this.payment.loanAccountNumber = null;
      this.payment.socialSecurityNumber = null;
    };

    this.onPayorTypeChange = () => {
      this.payment.loanAccountNumber = null;
      this.payment.socialSecurityNumber = null;
      this.payment.crn = null;
    };

    this.showLoanAccountNumber = () => {
      if (!this.payment.paymentType) {
        return true;
      }

      if (onlyLoanPaymentTypes.includes(this.payment.paymentType)) {
        return true;
      }

      return (this.payment.paymentType === 'MEMBER_LOAN' && this.payment.payorType === 'INDIVIDUALS');
    };

    this.allFields = () => {
      const forms = {
        ssn: _.get(this, 'commandForm.socialSecurityNumber.$viewValue'),
        loan: _.get(this, 'commandForm.loanAccountNumber.$viewValue'),
      };

      const shows = {
        ssn: this.showSocialSecurityNumber(),
        loan: this.showLoanAccountNumber()
      };

      return {forms, shows};
    };

    this.isFieldRequired = currentType => {
      const { forms, shows } = this.allFields();

      return Object.keys(forms)
        .filter(type => type !== currentType)
        .filter(type => shows[type])
        .every(type => !forms[type]) && shows[currentType];
    };

    this.isFieldDisabled = currentType => {
      const { forms, shows } = this.allFields();
      return Object.keys(forms)
        .filter(type => type !== currentType)
        .some(type => forms[type]);
    };

    this.showCrn = () => {
      return false;
    };

    this.showSocialSecurityNumber = () => {
      return !this.payment.paymentType || !onlyLoanPaymentTypes.includes(this.payment.paymentType);
    };

    this.ssLoanMask = () => {
      return '99-9999999-9';
    };

    this.ssNumberPattern = () => {
      return ssNumberPattern(this.payment.paymentType);
    };

    this.validateSSLoanNumberChecksum = value => {
      if (!value) {
        return true;
      }

      const digitPositions = [0, 1, 2, 3, 4, 5, 6, 7, 8];
      const coefficient = [1, 3, 7, 1, 3, 7, 1, 3, 7];
      if (!value || value.length !== 10) {
        return false;
      }

      let sum = 0;
      for (let i = 0; i < digitPositions.length; i++) {
        sum += Number(value[digitPositions[i]]) * coefficient[i];
      }

      const tenthDigit = (10 - sum % 10) % 10;
      return Number(value[9]) === tenthDigit;
    };

    this.loanAccountNumberPattern = () => {
      return validateStartEndRegex(
        addMaskToRegex(
          '(0[1-9]|1[0-9]|20|8[0-9]|90)',
          '\\d{7}',
          '\\d'
        )
      );
    };

    this.onStartingPeriodChange = () => {
      if (!this.payment.endingPeriod) {
        this.payment.endingPeriod = this.payment.startingPeriod.clone();
      }
    };

    this.validateSSAmount = ssAmount => {
      if (this.payment.paymentType === "CONTRIBUTION") {
        if (["EMPLOYER", "HOUSEHOLD_EMPLOYER"].includes(this.payment.payorType)) {
          return true;
        }

        if (["FARMER_FISHERMAN", "NON_WORKING_SPOUSE",
            "OVERSEAS_WORKER", "SELF_EMPLOYED_MEMBER",
            "VOLUNTARY_MEMBER"].includes(this.payment.payorType)) {
          return ssAmount >= 200;
        }

        return true;
      }

      if (["MEMBER_LOAN", "REAL_ESTATE_LOAN"].includes(this.payment.paymentType)) {
        return ssAmount > 0;
      }

      if (this.payment.paymentType === "PESO_FUND") {
        return ssAmount >= 1000 &&
          ssAmount <= 500 * (10 ** 3) &&
          ssAmount % 100 === 0;
      }

      if (this.payment.paymentType && this.payment.paymentType.startsWith('MISC_')) {
        return ssAmount > 0;
      }

      return true;
    };

    this.getFixedDate = () => {
      const fixedDatePaymentTypes = ['MEMBER_LOAN', 'PESO_FUND', 'REAL_ESTATE_LOAN', 'CALAMITY_LOAN',
        'EDUCATIONAL_LOAN', 'LOAN_RESTRUCTURING', 'MISC_SOCIAL_SECURITY_CARD'];
      const fixedDatePayorTypes = ['FARMER_FISHERMAN', 'HOUSEHOLD_EMPLOYER', 'INDIVIDUALS', 'NON_WORKING_SPOUSE',
        'OVERSEAS_WORKER', 'SELF_EMPLOYED_MEMBER', 'VOLUNTARY_MEMBER'];
      if (fixedDatePaymentTypes.includes(this.payment.paymentType)) {
        if (fixedDatePayorTypes.includes(this.payment.payorType)) {
          return this.postingDate;
        }
      }
    };

    this.isDateRequired = () => {
      const requiredDateForEmployerPaymentTypes = ['MEMBER_LOAN', 'PESO_FUND', 'REAL_ESTATE_LOAN', 'CALAMITY_LOAN', 'EDUCATIONAL_LOAN', 'LOAN_RESTRUCTURING'];
      const requiredPayorTypes = ['EMPLOYER', 'HOUSEHOLD_EMPLOYER'];
      return !requiredDateForEmployerPaymentTypes.includes(this.payment.paymentType) || !requiredPayorTypes.includes(this.payment.payorType);
    };

    this.getFixedSSAmount = () => {
      if (!this.payment.paymentType) {
        return false;
      }

      if (this.payment.paymentType === 'MISC_SOCIAL_SECURITY_CARD') {
        return 200;
      }

      return false;
    };

    this.showECAmount = () => {
      if(!this.payment.paymentType) {
        return true;
      }

      return this.payment.paymentType === 'CONTRIBUTION' &&
        (!this.payment.payorType ||
          ['EMPLOYER', 'HOUSEHOLD_EMPLOYER'].includes(this.payment.payorType));
    };

    this.hideStartingEndingPeriod = () => {
      if(!this.payment.paymentType) {
        return false;
      }

      return ['MISC_SOCIAL_SECURITY_CARD', 'EDUCATIONAL_LOAN'].includes(this.payment.paymentType);
    };

    this.validateECAmount = ecAmount => {
      return ecAmount !== null && ecAmount !== undefined;
    };

    this.cancelChanges = actionCommand.cancelChanges;

    this.save = async () => {
      const previousPaymentMonth = () => this.postingDate.clone().subtract(1, 'months');

      let startingPeriod = (this.getFixedDate() || this.payment.startingPeriod);
      if (!startingPeriod && !this.isDateRequired()) {
        startingPeriod = previousPaymentMonth();
      }

      let endingPeriod = (this.getFixedDate() || this.payment.endingPeriod);
      if (!endingPeriod && !this.isDateRequired()) {
        endingPeriod = previousPaymentMonth();
      }

      const displayedFixedDateFormat = 'YYYY-MM-DD';
      const monthYearDateFormat = 'YYYY-MM';

      const displayedStartingPeriod = this.getFixedDate() ?
          this.getFixedDate().format(displayedFixedDateFormat) :
          startingPeriod.format(monthYearDateFormat);

      const displayedEndingPeriod = this.getFixedDate() ?
          this.getFixedDate().format(displayedFixedDateFormat) :
          endingPeriod.format(monthYearDateFormat);

      const ssAmount = this.getFixedSSAmount() || this.payment.ssAmount;
      const ecAmount = this.showECAmount() ? this.payment.ecAmount : null;
      const amount = new BigNumber(ssAmount || 0).plus(ecAmount || 0).toNumber();
      const message = confirmationMessage({
        amount,
        startingPeriod: displayedStartingPeriod,
        endingPeriod: displayedEndingPeriod,
        payorType: this.payment.payorType,
        moneyFormatter: $filter('php'),
        calculateFlexiFund: false
      });

      const proceed = await confirmation(message, true);
      if (proceed) {
        command.execute('PayOfflineSSS', {
          "paymentType": this.payment.paymentType,
          "payorType": this.payment.payorType,
          "payorName": this.payment.payorName,
          "loanAccountNumber": this.showLoanAccountNumber() && this.payment.loanAccountNumber ? this.payment.loanAccountNumber : null,
          "ssNumber": this.showSocialSecurityNumber() && this.payment.socialSecurityNumber ? this.payment.socialSecurityNumber : null,
          "startingPeriod": startingPeriod.format(monthYearDateFormat),
          "endingPeriod": endingPeriod.format(monthYearDateFormat),
          "ssAmount": ssAmount,
          "ecAmount": ecAmount,
          "paymentDate": this.postingDate.format('YYYY-MM-DDTHH:mm:ss'),
          "crNumber": this.showCrn() ? this.payment.crn : null,
        }).success(res => {
          modalPrintPreviewService.show('SSS_MISC_TRANSACTION_VALIDATION_SLIP', {
            paymentId: res.output.id,
            paymentMode: 'OFFLINE'
          }, () => {
            $location.path("/dashboard/miscellaneous-transactions");
          });
        });
      }
    };
  }
});
