import module from 'module';
import $ from "jquery";

const templateUrl = require('./modal-info.template.html');

module.component('modalInfoComponent', {
  templateUrl,
  bindings: {
    /** Example:
    *   [{label: 'Title', value:'1value'}]
    */
    rows: '<',
    info: '<',
    title: '<',
    /** Example:
     *  [{text:'Button', action:'()=>cb()', bclass:'btn btn-success'}]
     */
    buttons: '<',
    onClose: '&'
  },
  controller: function () {
    const that = this;

    that.close = () => {
      $('#modalInfo').modal('hide');
      if (that.onClose) that.onClose();
    };

  }
});


module.factory('modalInfo', function ($rootScope, $compile, $timeout) {
  let that = this;

  that.closeModal = function () {
    $('#modalInfoComponent').remove();
  };

  that.closeModalWithBackdrop = function () {
    that.closeModal();
    $('.modal-backdrop').remove();
  };

  function showModal(content, title, info, buttons, onClose) {
    let scope = $rootScope.$new();
    scope.rows = content;
    scope.buttons = buttons;
    scope.closeModal = () => {
      that.closeModal();
      if (onClose) onClose();
    };
    scope.title = title;
    scope.info = info;

    $('body').append($compile('<modal-info-component id="modalInfoComponent" rows="rows" info="info" buttons="buttons" title="title" on-close="closeModal()"/>')(scope));

    $timeout(() =>
      $('#modalInfo').modal({
        keyboard: false,
        backdrop: 'static'
      }));
  }

  return {
    display: showModal,
    closeModalWithBackdrop : that.closeModalWithBackdrop
  }
});