import module from 'module';

class CasaProductPrintService {
  constructor(http, modalPrintPreviewService, passbookCache, customerCache, depositAccountService, termDepositsService) {
    this.http = http;
    this.modalPrintPreviewService = modalPrintPreviewService;
    this.passbookCache = passbookCache;
    this.customerCache = customerCache;
    this.depositAccountService = depositAccountService;
    this.termDepositsService = termDepositsService;

    this.casaProductTypes = {
      TERM_DEPOSIT: {
        code: "TERM_DEPOSIT",
        productsCache: this.customerCache.termDeposits,
        productTypesCache: this.termDepositsService,
        validationPrint: "TERM_DEPOSIT_VALIDATION_SLIP",
        passbookPrint: "TERM_DEPOSIT_PASSBOOK"
      },
      DEPOSIT_ACCOUNT: {
        code: "DEPOSIT_ACCOUNT",
        productsCache: this.customerCache.depositAccounts,
        productTypesCache: this.depositAccountService,
        validationPrint: "DEPOSIT_ACCOUNT_VALIDATION_SLIP",
        passbookPrint: "DEPOSIT_ACCOUNT_PASSBOOK"
      }
    }
  }

  async getCasaProductType(customerId, casaType, productId) {
    const productsPromise = casaType.productsCache(customerId).toPromise();
    const productTypesPromise = casaType.productTypesCache.toPromise();
    const [products, productTypes] = await Promise.all([productsPromise, productTypesPromise]);

    const product = products.find(acc => Number(acc.id) === Number(productId));
    return productTypes.find(prdType => Number(prdType.id) === Number(product.typeId));
  }

  async markPassbookOperationAsPrinted(productId, operationId, lineNo) {
    await this.http.put(`/products/${productId}/passbook`, [{
      operationId: operationId,
      line: lineNo,
    }]).toPromise();

    this.passbookCache.withParam(productId).evict();
  }

  async printFor(casaProductType, customerId, productId, operationId) {
    const casaType = this.casaProductTypes[casaProductType];

    await this.modalPrintPreviewService.showAsync(casaType.validationPrint, {operationId});
    const productType = await this.getCasaProductType(customerId, casaType, productId);

    if (productType.printPassbookAfterValidationSlip) {
      const passbook = await this.passbookCache.withParam(productId).toPromise();
      const startLine = passbook.lastUsedLine + 1;
      await this.modalPrintPreviewService.showAsync(casaType.passbookPrint, {
        productId,
        operationIds: [operationId],
        lineNo: startLine
      });
      await this.markPassbookOperationAsPrinted(productId, operationId, startLine)
    }
  }
}

module.service('casaProductPrintService', CasaProductPrintService);
