import module from 'module';
import ParameterizedLocalCache from 'shared/util/parameterized-local-cache';

module.factory('checkbookCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (productId) => http.get(`/products/accounts/checkbooks`,
      {params: {
        productId: productId,
        pageNo: 0,
        pageSize: 20
      }}),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.checkbook,
    cacheName: 'checkbookCache'
  })
);
