import module from 'module';
import $ from 'jquery';
import moment from 'moment';

const templateUrl = require('./menu.template.html');

module.component('menu', {
  templateUrl: templateUrl,
  controller: function ($scope, $location, $interval, $timeout, $element, $compile, nav, confirmation, customerCache, groupCustomerCache,
                        authentication, availableTasksCache, config, breadcrumbs, systemPropertyCache, cashTransferCheckService, command,
                        $rootScope, appVersionService, singleUserCache) {
    const that = this;

    const cashTransferSub = cashTransferCheckService.toObservable()
      .subscribe(shouldBlock => {
        this.blockActions = shouldBlock;
      });

    const updateServerTimeByOneSecond = () => {
      that.serverTime = that.serverTime ? moment(that.serverTime)
        .add(1, 'seconds')
        .format('YYYY-MM-DDTHH:mm:ss') : null
    };

    let updateServerTimeInterval = null;
    appVersionService.backend()
      .then(backendVersionObject => {
        const frontendVersion = extractVersionNumber(appVersionService.currentFrontend().appVersion);
        const backendVersion = extractVersionNumber(backendVersionObject.appVersion);

        that.versionInfo = `F:${frontendVersion}/B:${backendVersion}`;
        that.serverTime = backendVersionObject.webServerTime;

        updateServerTimeInterval = $interval(updateServerTimeByOneSecond, 1000);
      });

    function extractVersionNumber(fullVersionCode) {
      const versionRegex = /\d+(\.\d+)+(-S)?/;
      const match = versionRegex.exec(fullVersionCode);
      return match ? match[0] : 'unknown';
    }

    $scope.permission = authentication.permissions;
    $scope.customerCache = customerCache;
    $scope.groupCustomerCache = groupCustomerCache;
    let selectedTab = $location.path();

    $scope.$on('$routeChangeSuccess', () => {
      selectedTab = replaceCustomerId($location.path());
      $timeout(() => {
        // avoid race condition with breadcrumbs
        const crumbs = breadcrumbs.get();
        let label = '<null>';
        let idx = crumbs.length - 1;
        while (idx >= 0 && label === '<null>') label = crumbs[idx--].label;
        let title = label !== '<null>' ? 'nextbank - ' + label.toLowerCase() : 'nextbank';
        $('title').text(title);
      });
    });

    let taskPollInterval = null;
    if (authentication.permissions['ACTIONS_READ']) {

      availableTasksCache.toObservable().subscribe(data => {
        this.availableTasks = data;
      });

      // If actions are shown and task poll is enabled -> refetch pending actions count
      systemPropertyCache.toObservable().subscribe(properties => {
        // Disable task counter polling by default
        taskPollInterval = null;
        const taskPollEnabled = properties.find(p => p && p.code === 'TASK_COUNTER_POLL_ENABLED');
        if (taskPollEnabled && 'TRUE' === taskPollEnabled.value) {
          taskPollInterval = $interval(() => {
            availableTasksCache.refetch();
          }, config.actionCounterFetchInterval);
        }
      });
    }

    const isSystemPropertyTrue = (systemProperty) => {
      return systemProperty != null && (/true/i).test(systemProperty.value);
    };

    const systemPropertiesSub = systemPropertyCache.toObservable().subscribe(properties => {
      const organizationType = _.find(properties, {code: 'ORGANIZATION_TYPE'});
      that.organizationType = organizationType === null ? 'BANK' : organizationType.value;

      const isLedgerSupported = _.find(properties, {code: 'LEDGER_SUPPORT'});
      that.isLedgerSupported = isSystemPropertyTrue(isLedgerSupported);

      const isLogAttendancesEnabled = _.find(properties, {code: 'LOG_ATTENDANCES_ENABLED'});
      that.isLogAttendancesEnabled = isSystemPropertyTrue(isLogAttendancesEnabled);

      const isForexTransactionsEnabled = _.find(properties, {code: 'FOREX_TRANSACTIONS_ENABLED'});
      that.isForexTransactionsEnabled = isSystemPropertyTrue(isForexTransactionsEnabled);

      const isStockInventoryEnabled = _.find(properties, {code: 'STOCK_INVENTORY_ENABLED'});
      that.isStockInventoryEnabled = isSystemPropertyTrue(isStockInventoryEnabled);
    });

    $scope.select = function (tab, groupCustomerId) {
      let path = groupCustomerId ? replaceGroupCustomerId(tab) : replaceCustomerId(tab);
      $location.path(path)
    };

    $scope.isSelected = function (tab) {
      const regexpString = `.*${tab}.*`;
      let reg = new RegExp(regexpString);
      return reg.test(selectedTab);
    };

    $scope.refreshCustomer = function ($event, cache) {
      confirmation('Are you sure you want to refresh the context?', function () {
        cache.refetch();
      });
      $event.stopPropagation();
    };

    $scope.closeCustomer = function ($event, cache) {
      confirmation('Are you sure you want to close the context?', function () {
        cache.unloadCustomer();
        $location.path('/dashboard/lookup-customer');
      });
      $event.stopPropagation();
    };

    function replaceCustomerId(tab) {
      if (!tab || !customerCache.loadedCustomerId) return tab;
      return tab.indexOf(':customerId') !== -1 ? tab.replace(':customerId', customerCache.loadedCustomerId) : tab;
    }

    function replaceGroupCustomerId(tab) {
      if (!tab || !groupCustomerCache.loadedCustomerId) return tab;
      return tab.indexOf(':customerId') !== -1 ? tab.replace(':customerId', groupCustomerCache.loadedCustomerId) : tab;
    }

    /*
     * Hides all empty section entries.
     *
     * Note: It may remove an element permanently before 'command-access' is resolved.
     * However, if there is also a 'ng-if' it does help to preserve the element and show it when all conditions are met.
     */
    $timeout(() => $('.sidenav-section-entries:not(:has(*))').parent().remove());

    $(document.body).click('.sidenav-section-header', event => {
      $(event.target).closest('.sidenav-section-header').siblings('.sidenav-section-entries').collapse('toggle');
    });

    that.canLogAttendance = () => {
      return that.isLogAttendancesEnabled && that.user && that.user.shiftId !== null;
    }

    that.$onInit = async () => {
      that.user = await singleUserCache.user(authentication.context.id).toPromise();
    }

    that.$onDestroy = () => {
      systemPropertiesSub.unsubscribe();
      if (taskPollInterval) $interval.cancel(taskPollInterval);
      if (updateServerTimeInterval) $interval.cancel(updateServerTimeInterval)
      cashTransferSub.unsubscribe();
    };
  }
});
