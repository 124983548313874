import module from 'module';
import './system-health-check.component.less';
import templateUrl from './system-health-check.template.html';

import { healthCheckPhase } from 'constants/healthCheckPhase';

class SystemHealthCheck {
  constructor(http, authentication) {
    this.checks = null;
    this.hideOk = true;
    this.healthCheckPhase = healthCheckPhase.HEALTH_CHECK;

    this.http = http;
    this.authentication = authentication;
  }

  refetchHealthChecks() {
    this.http.get(`/system/health-details?includeStatement=true&phases=${this.healthCheckPhase}&branchId=${this.authentication.context.branchId}`)
      .success(data => this.checks = data);
  }

  $onInit() {
    this.refetchHealthChecks();
  }
}

module.component('systemHealthCheck', {
  templateUrl,
  controller: SystemHealthCheck
});