import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('ledgerTagCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/ledger/tags'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.ledgerTag,
    cacheName: 'ledgerTag'
  })
);
