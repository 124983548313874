import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('pawnItemStoneTypeCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get(`/products/pawns/items/stone-types`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.pawnItemStoneTypeConfig,
    cacheName: `pawnItemStoneTypeConfig`
  })
);
