import module from 'module';
import angular from 'angular';
import _ from 'lodash';
import { isCorporate, multipleAccountOwnershipsEnabled, mapSignatoryToOwnership } from '../../common/casa-product-util';

import CASE_CLASS_CODE_VALUES from '../../common/types/casa-class-code-labels.js'

const templateUrl = require('./new-account-details.template.html');

module.component('customerAccountCreateDetails', {
  templateUrl: templateUrl,
  controller: function ($timeout, $route, $location, $filter, http, casaTypesCache, customerCache,
                        productDefinitionService, depositAccountService, confirmation, notification,
                        commandMetadataCache, command, branchService, dict) {
    const that = this;

    const customerId = $route.current.params['customerId'];
    const accountTypeId = $route.current.params['accountTypeId'];

    that.account = {};
    that.accountType = {};
    that.depositAccounts = [];
    /**
     * Multiple account ownership enabled when:
     * 1. Customer is INDIVIDUAL
     * 2. Casa PDIC casa types allows this feature.
     */
    that.multipleAccountOwnershipEnabled = false;

    that.subtypes = [{
      label: 'Savings',
      value: 'SAVINGS'
    }, {
      label: 'Checking',
      value: 'CHECKING'
    }];

    const defaultCasaClassCodeValue = 'INDIVIDUALS';
    that.casaClassCodeValues = CASE_CLASS_CODE_VALUES;

    that.selectAccountConfig = {
      placeholder: 'Select account',
      searchField: ['productNumber'],
      valueField: 'id',
      labelField: 'productNumber',
      maxItems: 1
    };

    const s1 = depositAccountService.toObservable().subscribe(data => {
      that.accountType = _.find(data, {id: ~~accountTypeId});
      that.checking = that.accountType.accountSubtype === 'CHECKING';
      that.account.ata = angular.copy(that.accountType.automaticTransferAgreement);
      that.account.updateAta = false;
      that.account.taxExempted = that.accountType.taxExempted;
      that.account.casaClassCode = defaultCasaClassCodeValue;
      that.account.pdicTypeId = that.accountType.defaultPdicTypeId;
    });

    // Fetch product definitions
    const s2 = productDefinitionService.toObservable()
      .subscribe(productTypes => {
        that.productTypes = _.filter(productTypes, {productGroup: 'ACCOUNT'});
        that.relationDefinitionsIds = _.map(that.productTypes, t => {
          return {label: t.productName, value: t.id}
        })
      });

    // Fetch ATA trigger & credit commands
    const s3 = commandMetadataCache.toObservable()
      .subscribe(commands => {
        that.triggerCommands = {};
        that.creditCommands = {};
        if (commands && commands.length > 0) {
          // Function to filter commands by tag and convert results to label/value form
          // label -> human readable command name, value -> command alias
          const extract = (tag) => {
            return _.map(_.filter(commands, c => c.alias && _.includes(c.tags, tag)), c => {
              return {label: $filter('startCase')(c.alias), value: c.alias}
            })
          };
          that.triggerCommands = extract('ATA_TRIGGER');
          that.creditCommands = extract('ATA_CREDIT');
        }
      });

    // This method will remove all invalid relations from [account.ataRelationsIds array]
    that.resetRelations = () => {
      // Exclude relations not matching allowed definitions
      const definitions = that.account.ata.relationDefinitionsIds;
      let relations = _.filter(that.allRelations, r => _.includes(definitions, r.definitionId));
      // Exclude accounts of related people if they are not allowed
      const allowExternal = that.account.ata.allowExternalRelations;
      if (!allowExternal) relations = _.filter(relations, {customerId: Number(customerId)});
      // Remove invalid relations from multiselect
      that.account.ataRelationsIds = _.intersection(that.account.ataRelationsIds, _.map(relations, 'id'));
      // Convert relations to label/value list (for multiselect)
      that.multiselectRelations = _.map(relations, r => {
        return {
          value: r.id,
          label: function () {
            const account = _.find(that.allRelations, {id: r.id});
            return account ? account.productNumber : 'Invalid product: ' + r.id;
          }()
        };
      });
    };

    that.findSignatoryNames = () => {
      let signatoryTypeId = _.find(dict.RELATIVE_TYPE, {code: 'SIGNATORY'}).id;
      that.signatoryRelatives = _.filter(that.profile.relatives, {typeId: signatoryTypeId});
    };

    // Fetch all accounts belonging to customer (along with relatives)
    // All accounts are fetched to avoid refetching of accounts on each
    // change of [allowExternalRelations] change
    const s4 = customerCache.profile(customerId).toObservable()
      .combineLatest(branchService.toObservable(), (profile, branches) => {
        const branch = branches.find(b => b.id = profile.branchId);
        const owner = {
          birthDate: profile.individualData ? profile.individualData.birthDate : null,
          branchId: profile.branchId,
          branchName: branch.name,
          customerId: customerId,
          customerNumber: profile.customerNumber,
          effectiveName: profile.effectiveName,
          ownershipType: 'OWNER'
        };
        that.profile = profile;

        dict.onLoadingComplete(() => {
          that.findSignatoryNames();
        });

        that.account.ownership = [owner];
        that.allRelations = [];
        // Create array containing id of current customer
        // and ids of all of relatives (if exists)
        let customerIds = [customerId];
        if (profile && profile.relatives && profile.relatives.length > 0) {
          customerIds = customerIds.concat(_.map(profile.relatives, 'id'));
        }
        // Fetch active & dormant accounts for [customerIds]
        const criteria = {customerId: customerIds, productStatus: ['ACTIVE', 'INACTIVE']};
        http.post('/products/accounts/search', criteria, {nxLoaderSkip: true}).success(data => {
          if (data && data.result && data.result.length > 0) that.allRelations = data.result;
          that.resetRelations();
        });
      }).subscribe();

    const s5 = casaTypesCache.toObservable()
      .subscribe(casaTypes => {
        that.casaTypes = casaTypes;
        that.pdicCasaTypes = _.filter(that.casaTypes, {'regulatorType': 'PDIC'});
      });

    const s6 = customerCache.depositAccounts(customerId).toObservable()
      .subscribe(data => {
        that.depositAccounts = _.filter(data, a => a.status === 'ACTIVE');
      });

    that.pdicCasaTypesChanged = () => {
      that.multipleAccountOwnershipEnabled = multipleAccountOwnershipsEnabled(that.profile, that.account, that.pdicCasaTypes);
      that.isCorporate = isCorporate(that.profile, that.account, that.pdicCasaTypes);
      if (!that.isCorporate) {
        that.account.signatoryIds = [];
      }
    };

    //highlight required fields
    $timeout(() => that.form.$setSubmitted());

    that.accountLabel = (id) => {
      const account = _.find(that.allRelations, {id: id});
      return account ? account.productNumber : 'Invalid account';
    };

    that.open = () => {
      that.account.customerId = customerId;
      that.account.accountTypeId = that.accountType.id;

      const accountClone = angular.copy(that.account);
      accountClone.signatoryIds = accountClone.signatoryIds || [];
      let signatories = that.signatoryRelatives
        .filter(s => accountClone.signatoryIds.includes(s.id))
        .map(sig => mapSignatoryToOwnership(sig));
      accountClone.ownership = accountClone.ownership.concat(signatories);
      let message = `Do you want to open a "${that.accountType.productDefinition.productName}" account?`;
      http.post('/products/accounts/validate-prospect-account-type', accountClone).success((response) => {
        if (response.hasTypeConflict && that.accountType.typeConflictStrategy === 'SHOW_WARNING') {
          message = `This client already has at least one "${that.accountType.productDefinition.productName}" deposit 
          account. Do you want to proceed?`;
        } else if (response.hasSubtypeConflict && that.accountType.subtypeConflictStrategy === 'SHOW_WARNING') {
          message = `This client already has at least one other ${that.accountType.accountSubtype.toLowerCase()} account. 
          Do you want to proceed?`;
        }
        confirmation(message, () => {
          command.execute('CreateAccount', accountClone).success((response) => {
            customerCache.depositAccounts(customerId).refetch();
            that.redirect(response.output.id);
          });
        });
      })

    };

    that.redirect = (id) => {
      let path = `/customer/${customerId}/accounts`;
      if (id) {
        //redirect to newly created account
        path = path + '/' + id;
      } else {
        //redirect back
        path = path + '/create';
      }
      $location.path(path);
    };

    that.redirectBack = () => {
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => that.redirect());
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
      s2.unsubscribe();
      s3.unsubscribe();
      s4.unsubscribe();
      s5.unsubscribe();
      s6.unsubscribe();
    };
  }
});
