import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('pawnAuctionCache', (http, CacheFactory, config) => {

  let self = {};
  let branchCache = {};

  let pawnBranchAuctionCache = (branchId) => {
    const cache = new LocalCache({
      provider: () => http.get(`/products/pawns/auctions?branchId=${branchId}`),
      cacheFactory: CacheFactory,
      cacheTime: config.cache.pawnAuctionConfig,
      cacheName: `pawnAuctionConfig${branchId}`
    });
    branchCache[branchId] = cache;
    return cache;
  };

  self.systemAuctions = () => {
    return new LocalCache({
      provider: () => http.get('/products/pawns/auctions'),
      cacheFactory: CacheFactory,
      cacheTime: config.cache.pawnAuctionConfig,
      cacheName: 'pawnAuctionConfig'
    });
  };

  self.branchAuctions = (branchId) => {
    if (!branchCache[branchId]) pawnBranchAuctionCache(branchId);
    return branchCache[branchId];
  };

  return self;
});
