import module from 'module';

module.factory('customerMergeStorageService', function () {
  const STORAGE_PREFIX = 'customer-merge-';
  const service = {};

  function retrieveFromStorage(customerId) {
    const customersToMerge = sessionStorage.getItem(STORAGE_PREFIX + customerId);
    if (customersToMerge) return JSON.parse(customersToMerge);
    else return [];
  }

  function storeInStorage(customerId, customersToMerge) {
    sessionStorage.setItem(STORAGE_PREFIX + customerId, JSON.stringify(customersToMerge));
  }

  service.clearAll = () => {
    sessionStorage.clear();
  };

  service.clear = (customerId) => {
    if (!customerId) {
      console.log('No customerId present. Removing stopped...');
      return;
    }

    storeInStorage(customerId, []);
    return [];
  };

  service.add = (customerId, customers) => {
    if (!customerId) {
      console.log('No customerId present. Adding stopped...');
      return;
    }

    if (!customers) {
      console.log('No customers present. Adding stopped...');
      return;
    }

    storeInStorage(customerId, customers);
  };

  service.get = (customerId) => {
    if (!customerId) {
      console.log('No customerId present. Returning empty list..');
    }

    return retrieveFromStorage(customerId);
  };

  return service;
});
