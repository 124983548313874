import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import module from 'module';
import $ from "jquery";
import _ from 'lodash';

const templateUrl = require('./group-loans-disbursment.template.html');
module.component('groupLoansDisbursment', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $scope, http, notification, groupCustomerCache, userCache, authentication) {
    let that = this;

    that.groupLoan = {};
    that.reportContent = undefined;
    that.batchId = $route.current.params['groupLoanId'];
    that.customerId = $route.current.params['customerId'];
    that.releaser = undefined;
    that.releaseDate = undefined;
    that.users = undefined;
    that.allUsers = undefined;
    that.signatures = {};
    that.hasData = true;

    const params = $.param({
      groupLoanId: that.batchId
    });
    const reportCode = "group-loan-disbursment";
    let batchSub;

    const usersSub = userCache.toObservable().subscribe(users => {
      that.allUsers = users.map(user => {
        Object.assign(user, {fullName: `${user.lastName}, ${user.firstName} ${user.middleName ? user.middleName : ''}`});
        return user;
      });
      that.users = _.filter(that.allUsers, {'branchId': authentication.context.branchId});

      let currentUser = _.find(that.allUsers, {id: authentication.context.id});
      that.signatures.preparedByName = currentUser.fullName;

      batchSub = groupCustomerCache.batch(that.customerId)
        .toObservable()
        .subscribe(batches => {
          const batch = batches.find(batch => String(batch.id) === that.batchId);
          if (!batch) {
            console.warn("Couldn't find batch!, id: ", that.batchId);
            return;
          }

          that.releaser = _.find(that.allUsers, {id: batch.releasedById});
          if (that.releaser && !that.signatures.releasedByName) {
            that.signatures.releasedByName = that.releaser.fullName;
            that.releaseDate = batch.releaseDate;
          }
        });
    });

    that.preview = () => {
      http.http({
        url: `/reports/${reportCode}/json`,
        method: 'POST',
        responseType: 'json',
        data: params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      })
        .success(reportContent => {
          that.reportContent = reportContent;
          that.hasData = that.checkIfHasData(that.reportContent);
          if(that.hasData) {
            //Assign values to report header
            that.reportContent[0].reportDescription["Approved by:"] = that.signatures.approvedByName;
            that.reportContent[0].reportDescription["Checked by:"] = that.signatures.checkedByName;
          }

        });
    };

    that.checkIfHasData = (reports) => {
      return _(reports).map((r) => r.rows).flatten().value().length > 0
    };


    that.downloadXls = () => {
      http.http({
        url: `/reports/${reportCode}/xls`,
        method: 'POST',
        responseType: 'blob',
        data: params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        nxLoaderText: 'Downloading report XLS'
      }).success(response => {
        const xlsxFileUrl = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = xlsxFileUrl;
        a.download = `${that.reportContent[0].reportName}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(xlsxFileUrl);
      });
    };

    that.close = () => {
      $location.path(`/customer/${that.customerId}/group-loans/${that.batchId}`);
    };


    that.$onDestroy = () => {
      usersSub.unsubscribe();
      batchSub.unsubscribe();
    };
  }
});

