import module from 'module';
import {productAvailability} from 'constants/productDefinition';

class ProductService {
  constructor(authentication) {
    this.authentication = authentication
  }

  availableForSale(product) {
    const availableForAllBranches =
      product.productDefinition.productAvailability === productAvailability.allBranches.value;

    const availableOnlyForSelectedBranches =
      product.productDefinition.productAvailability === productAvailability.selectedBranches.value;

    const availableForCurrentBranch = !!product.productDefinition.availableInBranchIds
      && product.productDefinition.availableInBranchIds.includes(this.authentication.context.branchId);

    return availableForAllBranches
      || (availableOnlyForSelectedBranches
        && availableForCurrentBranch);
  }
}

module.service('productService', ProductService);