import module from 'module';
import ParameterizedLocalCache from 'shared/util/parameterized-local-cache';

module.factory('pawnMetalRateSingleCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (metalRateId) => http.get(`/products/pawns/metal-rates/${metalRateId}`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.pawnMetalRates,
    cacheName: 'pawnMetalRateSingleCache'
  })
);
