import module from 'module';
import './forex-panel.style.less';
import templateUrl from './forex-panel.template.html';

class ForexPanel {
  constructor($location) {
    this.$location = $location;
  }
  isActive(path){
    return path === this.$location.path();
  }
}

module.component('forexPanel', {
  templateUrl,
  controller: ForexPanel
});
