import module from 'module';

import templateUrl from './branch-filter.template.html';

class BranchFilter {
  constructor(http) {
    this.areasPromise = http.get('/management/areas').toPromise();
  }

  $onInit() {
    this.ngModel.$render = () => {
      this.values = this.modelValue();
    };

    this.nodeLabel = (node) => node.name;
  }

  modelValue() {
    return this.ngModel.$modelValue || [];
  }

  updateModel() {
    this.ngModel.$setTouched();
    this.ngModel.$setViewValue(this.values);
  }

  uniqueSelectedBranchIds() {
    const sanitizedValues = (this.values || []).filter(value => value && value > 0); // keep only branches - they have positive ids
    return new Set(sanitizedValues);
  }

  $onChanges(changes) {
    this.rebuildForest();
  }

  buildTree(area) {
    const areaChildren = (area.children || []).map(area => this.buildTree(area));
    const branchChildren = (area.branchIds || []).map((branchId) => {
      const foundBranch = this.options.find(branch => branch.id === branchId);
      if (!foundBranch) {
        return null;
      }

      return {
        id: foundBranch.id,
        name: foundBranch.code,
        type: 'branch',
        children: []
      };
    }).filter(item => item);

    const children = [...areaChildren, ...branchChildren];
    if (children.length === 0 || (children.length === 1 && children[0] === null)) {
      return null;
    }

    return {
      id: area.id * -10, // avoid overlapping area and branch ids
      name: area.name,
      type: 'area',
      children: children
    };
  }

  getBranchesRec(forest, branches) {
    for (const node of forest) {
      if (!node) {
        continue;
      }

      if (node.type === 'branch') {
        branches.add(node.id);
      }

      this.getBranchesRec(node.children, branches);
    }
  }

  getBranches(forest) {
    const branches = new Set();
    this.getBranchesRec(forest, branches);
    return branches;
  }

  async rebuildForest() {
    const areas = await this.areasPromise;
    const forest = areas.map(area => this.buildTree(area))
      .filter(item => item);
    const assignedBranches = this.getBranches(forest);
    const unassignedBranches = this.options.filter(option => !assignedBranches.has(option.id));

    for (const unassignedBranch of unassignedBranches) {
      forest.push({
        id: unassignedBranch.id,
        name: unassignedBranch.code,
        type: 'branch',
        children: []
      });
    }

    if (forest.length === 0) {
      this.treeData = [];
      return;
    }

    this.treeData = [{
      id: -1,
      name: 'All branches',
      type: 'area',
      children: forest
    }];
  }
}

module.component('branchFilter', {
  templateUrl,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    options: '<',
    ngRequired: '<'
  },
  controller: BranchFilter
});
