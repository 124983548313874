import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('accessRuleCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/access/rules'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.depositoryAccount,
    cacheName: 'accessRuleCommands'
  })
);
