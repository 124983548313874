import module from 'module';
import moment from 'moment';
import {isCorporate, mapSignatoryToOwnership, multipleAccountOwnershipsEnabled} from '../../common/casa-product-util';
import _ from 'lodash';
import BigNumber from "bignumber.js";

const templateUrl = require('./edit-term-deposit.template.html');
module.component('customerTermDepositEdit', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $location, $filter, customerCache, casaTypesCache, confirmation,
                        branchService, termDepositsService, customerDepositService, command, dict) {
    const that = this;

    const customerId = $route.current.params['customerId'];
    const depositId = $route.current.params['depositId'];
    const preterminationFirstHalfMultipler = new BigNumber(0.25).toNumber();
    const preterminationSecondHalfMultipler = new BigNumber(0.50).toNumber();

    that.termDeposit = {};
    that.pdicCasaTypes = [];
    that.accounts = [];
    that.input = null;
    that.selectedRelatedAccount = {};

    that.cycleTypes = [{
      label: 'Days',
      value: 'DAY'
    }, {
      label: 'Weeks',
      value: 'WEEK'
    }, {
      label: 'Months',
      value: 'MONTH'
    }, {
      label: 'Quarters',
      value: 'QUARTER'
    }, {
      label: 'Years',
      value: 'YEAR'
    }];

    that.accountSelectConfig = {
      placeholder: 'Select account',
      searchField: ['productNumber'],
      valueField: 'id',
      labelField: 'label',
      maxItems: 1,
      allowEmptyOption: true
    };

    that.pdicCasaTypesChanged = () => {
      that.multipleAccountOwnershipEnabled = multipleAccountOwnershipsEnabled(that.profile, that.input, that.pdicCasaTypes);
      that.isCorporate = isCorporate(that.profile, that.input, that.pdicCasaTypes);
      if (!that.isCorporate) {
        that.input.signatoryIds = [];
      }
    };

    $scope.$watchCollection('$ctrl.input.ownership', () => {
      if (that.input) {
        searchAccounts(that.input.ownership, that.profile.relatives, that.termDepositProduct.interCustomerTransferAllowed);
      }
    });

    $scope.$watch('$ctrl.input.depositInterest.interestRate', () => {
      if (that.input && that.input.depositInterest.specialInterestRate && that.input.depositInterest.interestRate) {
        const interestRate = new BigNumber(that.input.depositInterest.interestRate);
        that.input.depositInterest.preterminationFirstHalfInterestRate = interestRate.mul(preterminationFirstHalfMultipler).toNumber();
        that.input.depositInterest.preterminationSecondHalfInterestRate = interestRate.mul(preterminationSecondHalfMultipler).toNumber();
      }
    });

    that.getCycleTypeLabel = (type) => {
      const cycleType = _.find(that.cycleTypes, {value: type});
      return cycleType ? cycleType.label : '-';
    };

    const searchAccounts = (ownership, relatives, fetchRelatedCustomerAccounts, cb) => {
      let customerIds = ownership.map(o => Number(o.customerId));
      if (relatives && relatives.length > 0) {
        customerIds = customerIds.concat(relatives.map(r => Number(r.relativeCustomerId)));
      }

      customerDepositService.searchDeposits(
        // term deposit owner, co-owner, and relatives Ids
        customerIds,
        // valid deposit account statuses
        ['ACTIVE', 'INACTIVE'],
        // if true -> fetch deposit accounts of related customers,
        fetchRelatedCustomerAccounts,
        // search success callback
        deposits => {
          that.accounts = deposits;
          if (cb) cb(that.accounts);
        }
      );
    };

    that.findSignatoryRelatives = () => {
      let signatoryTypeId = _.find(dict.RELATIVE_TYPE, {code: 'SIGNATORY'}).id;
      that.signatoryRelatives = _.filter(that.profile.relatives, {typeId: signatoryTypeId});
    };

    const s1 = customerCache.termDeposits(customerId).toObservable()
      .combineLatest(casaTypesCache.toObservable(), (deposits, casaTypes) => {
        that.pdicCasaTypes = _.filter(casaTypes, {'regulatorType': 'PDIC'});
        let termDeposit = _.find(deposits, {id: Number(depositId)});
        if (termDeposit && termDeposit.pdicTypeId) {
          termDeposit.pdicTypeName = _.find(that.pdicCasaTypes, {id: termDeposit.pdicTypeId}).name;
        }
        return termDeposit;
      })
      .combineLatest(termDepositsService.toObservable(), (termDeposit, products) => {
        let termDepositProduct = _.find(products, {id: termDeposit.typeId});
        termDepositProduct = termDepositsService.enrichWithStrategiesDescription(termDepositProduct);
        that.termDepositProduct = termDepositProduct;
        that.maturityStrategiesValidValues = termDepositProduct.maturityStrategies.map(v => v.value);
        that.creditingStrategiesValidValues = termDepositProduct.creditingStrategies.map(v => v.value);
        termDeposit.maturityStrategyLabel = termDepositsService.getMaturityStrategyLabel(termDeposit.maturityStrategy);
        termDeposit.creditingStrategyLabel = termDepositsService.getCreditingStrategyLabel(termDeposit.creditingStrategy);

        return termDeposit;
      })
      .combineLatest(customerCache.profile(customerId).toObservable(), (termDeposit, profile) => {
        that.profile = profile;

        searchAccounts(termDeposit.ownership, that.profile.relatives, that.termDepositProduct.interCustomerTransferAllowed, (deposits) => {
          that.termDeposit.selectedRelatedAccount = _.find(that.accounts, {id: termDeposit.relatedAccountId});
          that.selectedRelatedAccount = termDeposit.selectedRelatedAccount;
        });

        dict.onLoadingComplete(() => {
          that.findSignatoryRelatives();
        });

        that.signatoryNames = termDeposit.ownership
          .filter(o => o.ownershipType === 'SIGNATORY')
          .map(o => o.effectiveName);

        return termDeposit;
      })
      .combineLatest(branchService.toObservable(), (termDeposit, branches) => {
        const branch = _.find(branches, {id: termDeposit.branchId});
        if (branch.postingDate) {
          that.branchPostingDate = moment(branch.postingDate).toDate();
        } else {
          that.branchPostingDate = Date();
        }
        return termDeposit;
      })
      .first()
      .subscribe(termDeposit => {
        that.termDeposit = termDeposit;
        that.input = {
          productId: termDeposit.id,
          typeId: that.termDepositProduct.id,
          pdicTypeId: termDeposit.pdicTypeId,
          depositTerm: termDeposit.depositTerm,
          maturityStrategy: termDeposit.maturityStrategy,
          creditingStrategy: termDeposit.creditingStrategy,
          relatedAccountId: termDeposit.relatedAccountId,
          taxExempted: termDeposit.taxExempted,
          casaClassCode: termDeposit.casaClassCode,
          creditingInterval: termDeposit.creditingScheduler.interval,
          creditingCycleType: termDeposit.creditingScheduler.cycleType,
          lastCreditingDate: termDeposit.creditingScheduler.lastExecution,
          accruedInterest: termDeposit.accruedInterest,
          lastAccrualDate: termDeposit.lastAccrualDate,
          ownership: termDeposit.ownership,
          signatoryIds: termDeposit.ownership.filter(o => o.ownershipType === 'SIGNATORY').map(o => o.relativeId),
          depositInterest: termDeposit.depositInterest
        };
        that.pdicCasaTypesChanged();
      });

    that.resetInterestRate = () => {
      if (that.input && !that.input.depositInterest.specialInterestRate) {
        that.input.depositInterest.interestRate = that.termDeposit.depositInterest.interestRate;
      }
    };

    that.redirect = () => {
      $location.path(`/customer/${customerId}/term-deposits/${depositId}`);
    };

    that.redirectBack = () => {
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => {
        that.redirect();
      });
    };

    that.update = () => {
      that.resetInterestRate();
      confirmation(`Do you want to update the "${that.termDeposit.productNumber}" term deposit?`, () => {
        let relatedAccount = that.selectedRelatedAccount;
        that.input.relatedAccountId = relatedAccount ? relatedAccount.id : null;
        removeTimeFromDate(that.input);

        let signatories = that.signatoryRelatives
          .filter(s => that.input.signatoryIds.includes(s.id))
          .map(sig => mapSignatoryToOwnership(sig));

        that.input.ownership = that.termDeposit.ownership.filter(o => o.ownershipType !== 'SIGNATORY').concat(signatories);

        if (!(that.multipleAccountOwnershipEnabled || that.isCorporate)) {
          //remove everyone but owner
          that.input.ownership = that.input.ownership.filter(o => o.ownershipType === 'OWNER');
        }
        command.execute('UpdateDeposit', that.input).success(() => {
          customerCache.termDeposits(customerId).refetch();
          that.redirect();
        });
      });
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
    };

    //private methods

    const removeTimeFromDate = (object) => {
      for (let key in object) {
        if (object.hasOwnProperty(key)) {
          let value = object[key];
          if (value instanceof Date) {
            object[key] = $filter('nxDate')(value);
          }
        }
      }
    };
  }
});
