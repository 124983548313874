import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('pawnItemStoneRateCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get(`/products/pawns/items/stone-rates`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.pawnItemStoneRateConfig,
    cacheName: `pawnItemStoneRateConfig`
  })
);
