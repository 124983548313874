import module from "module";
import CompositeRequest from 'shared/common/compositeRequest';
import _ from 'lodash';
import {productAvailability} from 'constants/productDefinition';

import templateUrl from './layaway-product-details.template.html';

class LayawayProductDetailsComponent {
  constructor(layawayProductsCache, $route, $location, http, notification, confirmation, breadcrumbs, branchService,
              command) {
    this.layawayProductsCache = layawayProductsCache;
    this.$route = $route;
    this.$location = $location;
    this.http = http;
    this.notification = notification;
    this.confirmation = confirmation;
    this.breadcrumbs = breadcrumbs;
    this.branchService = branchService;
    this.command = command;
  }

  async $onInit() {
    this.parentUrl = '/admin/layaway-products';
    this.branches = await this.branchService.toPromise();

    this.layawayProduct = {
      productDefinition: {
        productAvailability: productAvailability.allBranches.value,
        availableInBranchIds: [],
        expired: false,
        daysInYear: 360,
        productGroup: "LAYAWAY"
      },
      minimalDownPaymentPercentage: null,
      installmentInterval: null,
      installmentCount: null,
      advancePaymentEnabled: null
    };

    const productId = this.$route.current.params['productId'];
    this.createMode = !productId;

    if (!this.createMode) {
      const layawayProducts = await this.layawayProductsCache.toPromise();
      this.layawayProduct = _.find(layawayProducts, (d) => d.id == productId);

      _.set(this.breadcrumbs, 'options.layaway-product-label', this.layawayProduct.productDefinition.productName);
    }
  }

  cancel() {
    this.confirmation('Do you want to cancel? Canceling will discard all changes.', () => this.$location.path(this.parentUrl));
  }

  async confirmChanges() {
    if (this.createMode) {
      await this.create();
    } else {
      this.update();
    }
  }

  async create() {
    const response = await this.command.execute('CreateLayawayType', this.layawayProduct).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.layawayProductsCache.evict();
    this.$location.path(this.parentUrl);
  }

  update() {
    const updateLayawayTypeRequest = !this.hasAnyFormChanged() ? null : this.command.execute('UpdateLayawayType', this.layawayProduct);
    const updateGlMappingRequest = this.glMappingComponent.save();

    if (updateLayawayTypeRequest || updateGlMappingRequest) {
      CompositeRequest.create()
        .add(updateLayawayTypeRequest)
        .add(updateGlMappingRequest)
        .success(() => {
          this.layawayProductsCache.refetch();
          this.$location.path('/admin/layaway-products');
        });
    } else {
      this.notification.show("Information", "No changes have been made");
    }
  }

  hasAnyFormChanged() {
    return !this.generalForm.$pristine;
  }

  setGlMappingComponent(instance) {
    this.glMappingComponent = instance;
  }
}

module.component('layawayProductDetails', {
  templateUrl,
  controller: LayawayProductDetailsComponent
});
