import module from 'module';
import _ from 'lodash';

const templateUrl = require('./amortization-inline-panel.template.html');
module.component('amortizationInlinePanel', {
    templateUrl: templateUrl,
    bindings: {
      'item' : '=',
      'selectedLoan' : '<',
      'onlyData' : '<'
    },
    controller: function ($route, $location, $scope, http, breadcrumbs, customerCache, notification,
                          productDefinitionService, userCache, glMappingsService,
                          loanProductsCache, $filter) {
      let that = this;
      let customerId = $route.current.params['customerId'];


      that.keyValueDetails = _.memoize(amortizationLineItem => {
        return [
          {
            label: 'Outstanding principal',
            value: `${$filter('php')(amortizationLineItem.principalBalance)} / ${$filter('php')(amortizationLineItem.principalAmount)}`,
          }, {
            label: 'Outstanding interests',
            value: `${$filter('php')(amortizationLineItem.interestBalance)} / ${$filter('php')(amortizationLineItem.interestAmount)}`,
          }, {
            label: 'Outstanding CBU',
            value: `${$filter('php')(amortizationLineItem.cbuChargeBalance)} / ${$filter('php')(amortizationLineItem.cbuChargeAmount)}`,
          }, {
            label: 'Outstanding PF',
            value: `${$filter('php')(amortizationLineItem.pfChargeBalance)} / ${$filter('php')(amortizationLineItem.pfChargeAmount)}`,
          }, {
            label: 'Outstanding TP',
            value: `${$filter('php')(amortizationLineItem.tpChargeBalance)} / ${$filter('php')(amortizationLineItem.tpChargeAmount)}`,
          }, {
            label: 'Outstanding custom fees',
            value: `${$filter('php')(amortizationLineItem.customFeesBalance)} / ${$filter('php')(amortizationLineItem.customFeesAmount)}`,
          }, {
            label: 'Outstanding penalties',
            value: `${$filter('php')(amortizationLineItem.penaltyBalance)} / ${$filter('php')(amortizationLineItem.penaltyAmount)}`,
          }, {
            label: 'Outstanding past-due Interest',
            value: `${$filter('php')(amortizationLineItem.pastDueInterestBalance)} / ${$filter('php')(amortizationLineItem.pastDueInterestAmount)}`,
          }, {
            label: 'Temporary receipt',
            value: amortizationLineItem.temporaryReceipt,
          }, {
            label: 'Total outstanding amount',
            value: `${$filter('php')(amortizationLineItem.amortizationBalance)} / ${$filter('php')(amortizationLineItem.amortizationAmount)}`,
          }
        ];
      });

      that.keyValueDetails.cache = new WeakMap();

      that.hideInlinePanel = () => {
        that.hidden = true;
      };

      that.waiveDisabled = (amortizationId) => {
        let amortization = _.find(that.selectedLoan.amortizationSchedule.list, (a) => a.id == amortizationId);
        return amortization.amortizationBalance === 0;
      };

      that.sendReminder = (amortizationId) => console.log('sending reminder: ' + amortizationId);
      that.waive = (amortizationId) => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/amortization/${amortizationId}/waive`);
    }
  }
);