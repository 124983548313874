import module from 'module';
import _ from 'lodash';
import moment from 'moment';

const templateUrl = require('./end-the-day.template.html');
module.component('endTheDay', {
  templateUrl: templateUrl,
  controller: function ($interval, $location, $filter, http, config, authentication, notification, productDefinitionService,
                        systemPropertyCache, branchService, confirmationTemplate, actionCommand) {

    // JS 'magic'
    let that = this;
    // Read product definitions
    that.productDefinitions = null;
    // Product mapping configuration (required to determine if end of day process is allowed)
    that.mappingStatus = null;
    // Non empty product mappings (non empty = at least 1 product instance exists)
    that.blockingProductMappings = null;
    // Batch step status
    that.stepStatuses = null;
    // Switch to enable step refetching
    that.refetchEnabled = false;

    // clear branch cache
    branchService.evict();

    /**
     * Read product definitions.
     * Definitions are required to map ledger status with human-readable product name.
     */
    const productDefinitionSub = productDefinitionService.toObservable().subscribe(definition => {
      that.productDefinitions = definition;
    });

    const systemPropertiesSub = systemPropertyCache.toObservable().subscribe(properties => {
      const ignoreHealthCheck = _.find(properties, {code: 'ALLOW_EOD_DESPITE_HEALTH_CHECK_FAIL'});
      that.ignoreHealthCheck = ignoreHealthCheck && ignoreHealthCheck.value === 'TRUE';
      const ls = _.find(properties, {code: 'LEDGER_SUPPORT'});
      that.ledgerSupported = ls && ls.value === 'TRUE';
      if (that.ledgerSupported) {
        // TODO = fetch GL mappings status
      } else {
        that.mappingStatus = {};
      }

      if (!that.ignoreHealthCheck) http.get(`/system/health`)
        .success(() => that.systemHealth = true)
        .error(() => that.systemHealth = false);
    });

    const branchSub = branchService.toObservable().subscribe(branches => {
      const authBranch = _.find(branches, {id: authentication.context.branchId});
      if (authBranch) {
        that.postingDate = moment(authBranch.postingDate).toDate();
        that.branchName = authBranch.name;
      }
    });


    /**
     * Translates product definition ID into it's name
     */
    that.getProductDefinition = (id) => {
      return that.productDefinitions && _.find(that.productDefinitions, {id: id});
    };

    /**
     * Mappings status are valid if all product definitions having at least
     * one product instance are configured
     */
    that.validateMappingsStatus = () => {
      // TODO = implement mappings validation for new GL mappings model
      return true;
    };

    /**
     * Refetch should be stopped if:
     * - If at least 1 step is STOPPED/FAILED/ABANDONED/UNKNOWN
     * - All steps are COMPLETED or PENDING (null)
     */
    that.updateRefetch = (steps) => {
      let stopStep = _.some(steps, (step) => ['STOPPED', 'FAILED', 'ABANDONED', 'UNKNOWN'].includes(step.status));
      that.refetchEnabled = !(stopStep || _.every(steps, (step) => [null, 'COMPLETED'].includes(step.status)));
    };

    that.fetchStepStatuses = () => {
      // Read batch process step statuses from server
      http.get(`/batch-jobs/status`, {nxLoaderSkip: true})
        .success((stepStatuses) => {
          that.stepStatuses = stepStatuses;
          that.updateRefetch(that.stepStatuses);
          if (!that.refetchEnabled) {
            // EOD done
            branchService.refetch();
          }
        })
        .error(() => notification.show("Error", "Failed to fetch batch process status"));
    };

    // Perform initial status fetch
    that.fetchStepStatuses();

    // Refetch steps statuses every [batchProcessStatusFetchInterval] ms
    $interval(() => {
      if (that.refetchEnabled) {
        that.fetchStepStatuses()
      }
    }, config.batchProcessStatusFetchInterval);

    /**
     * End process should be available if:
     * - Product GL mappings are valid
     * - All steps are null (PENDING)
     */
    that.endOfDayEnabled = () => {
      const branchDateFetched = !!that.postingDate;
      const health = that.systemHealth || that.ignoreHealthCheck;
      return that.validateMappingsStatus() && health && !that.refetchEnabled && _.every(that.stepStatuses, {status: null}) && branchDateFetched;
    };

    that.cancelChanges = () => {
      that.refetchEnabled = false;
      $location.path(`/dashboard/miscellaneous-transactions`);
    };

    that.doSave = () => {
      const {id, branchId} = authentication.context;
      actionCommand.execute('END_DAY', {userId: id, branchId: branchId}, () => {
          notification.show('Success', 'Day end process started');
          that.refetchEnabled = true;
        });
    };

    that.save = () => {
      confirmationTemplate({
        question: 'Do you want to end the day?',
        details: [
          {label: 'Branch', description: that.branchName},
          {label: 'Date', description: $filter('prettyDate')(that.postingDate)}
        ],
        warning: 'This operation cannot be reverted safely.<br>Please make sure that the day can be closed and you are closing the correct day.',
        yesCallback: that.doSave
      });
    };

    that.$onDestroy = () => {
      productDefinitionSub.unsubscribe();
      systemPropertiesSub.unsubscribe();
      branchSub.unsubscribe();
    }
  }
});
