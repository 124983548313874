import module from 'module';
import ParameterizedLocalCache from 'shared/util/parameterized-local-cache';

module.factory('printBackgroundCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (parameters) => http.get(`/print/${parameters.printCode}/background-image`, {
      params: parameters.params
    }),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.printBackgroundCache,
    cacheName: 'printBackgroundFilesCache'
  })
);
