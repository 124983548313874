import module from 'module';
import {NOTIFICATION_TEMPLATE, NOTIFICATION_TEMPLATES} from 'constants/apiUrl';

import templateUrl from './notification-templates.template.html';

class NotificationTemplates {
  constructor(http, popup, systemPropertyCache, command, $location, confirmation, $route) {
    this.http = http;
    this.popup = popup;
    this.systemPropertyCache = systemPropertyCache;
    this.command = command;
    this.$location = $location;
    this.confirmation = confirmation;
    this.$route = $route;
  }

  async $onInit() {
    const templates = await this.http.get(NOTIFICATION_TEMPLATES).toPromise();
    this.templates = templates.filter(t => !t.deleted);
  }

  createTemplate() {
    this.$location.path(NOTIFICATION_TEMPLATE);
  }

  editTemplate(templateId) {
    this.$location.path(`${NOTIFICATION_TEMPLATE}/${templateId}`);
  }

  async removeTemplate(templateId) {
    const templateToRemove = this.templates.filter(t => t.id === templateId)[0];
    const confirmed = await this.confirmation(`Are you sure you want to delete '${templateToRemove.name}'?`);
    if (!confirmed) {
      return;
    }

    const response = await this.command.execute('RemoveNotificationTemplate', {id: templateId}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.$route.reload();
  };
}

module.component('notificationTemplates', {
  templateUrl,
  controller: NotificationTemplates
});
