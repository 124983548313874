import module from 'module';
import moment from 'moment';

class CustomReport {
  constructor($scope, http, customReportSaved, customReportHistory, reportService, schemaCache, NgTableParams, popup) {
    this.query = '';
    this.populateQuery = this.query;
    this.reportName = '';
    this.customReportSavedList = [];
    this.customReportHistoryList = [];
    this.hints = null;
    this.infiniteScrollSelector = 'custom-report .report-view-holder';

    this.$scope = $scope;
    this.http = http;
    this.reportService = reportService;
    this.popup = popup;
    
    this.savedCache = customReportSaved;
    this.historyCache = customReportHistory;
    this.schemaCache = schemaCache;

    this.title = "Custom reports editor";

    this.savedTable = new NgTableParams({}, {
      counts: [],
      getData: () => this.customReportSavedList
    });

    this.historyTable = new NgTableParams({}, {
      counts: [],
      getData: () => this.customReportHistoryList
    });

    this.onQueryUpdate = (payload) => {
      this.query = payload.sql;
    }
    
    this.updateHistory();
    this.updateSaved();
    this.getHints();
  }

  async getHints() {
    const hints = await this.schemaCache.toPromise();
    let hintsFormatted =  {};

    hints.forEach(val => hintsFormatted[`${val.schemaName}.${val.tableName}`] = val.columnNames);
    this.hints = hintsFormatted;
  }

  runQuery() {
    if (!this.query) {
      this.popup({header: 'Error', text: 'There is no query to save!', renderHtml: true});
      return null;
    }

    const params = {
      reportName: this.reportName,
      query: this.query
    }
    
    this.http
      .http({
        url: '/reports/CustomReport/json',
        method: 'POST',
        responseType: 'json',
        data: $.param(params),
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      })
      .success(reportContent => {
        this.reportContent = reportContent;
        this.addQueryToHistory();
      })
      .error(data => this.reportService.handleResponseError(data));
  }

  downloadXLS() {
    if (!this.query) {
      this.popup({header: 'Error', text: 'There is no query to save!', renderHtml: true});
      return null;
    }

    const params = {
      reportName: this.reportName,
      query: this.query
    };

    this.http
      .http({
        url: `/reports/CustomReport/xls`,
        method: 'POST',
        responseType: 'blob',
        data: $.param(params),
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        nxLoaderText: 'Downloading report XLS'
      })
      .success(response => {
        const xlsxFileUrl = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = xlsxFileUrl;
        a.download = this.reportName === '' ? `custom_report_${moment().valueOf()}.xlsx` : `${this.reportName}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(xlsxFileUrl);
      })
      .error(data => this.reportService.handleResponseError(data));
  }
  
  updateHistory() {
    this.customReportHistoryList = this.historyCache.values().reverse();
    this.historyTable.reload();
  }

  updateSaved() {
    this.customReportSavedList = this.savedCache.values();
    this.savedTable.reload();
  }

  addQueryToHistory() {
    this.historyCache.put(moment().valueOf(), { name: this.reportName, date: moment().format('YYYY-MM-DD, HH:mm'), sql: this.query});
    this.updateHistory();
  }

  saveQuery() {
    if (!this.query) {
      this.popup({header: 'Error', text: 'There is no query to save!', renderHtml: true});
      return null;
    }
    
    this.savedCache.put(this.reportName, { name: this.reportName, date: moment().format('YYYY-MM-DD'), sql: this.query});
    this.updateSaved();
  }

  editQuery(item) {
    this.query = item.sql;
    this.populateQuery = this.query;
    this.reportName = item.name;
  }

  deleteSaved(item) {
    this.savedCache.remove(item.name);
    this.updateSaved();
  }
}

import templateUrl from './custom-report.template.html';
import './custom-report.component.less';
module.component('customReport', {
  templateUrl,
  controller: CustomReport
});