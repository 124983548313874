import module from 'module';
import _ from 'lodash';

const templateUrl = require('./term-deposit-details.template.html');

module.component('termDepositDetails', {
  templateUrl,
  controller: function (termDepositsService, $route, $location, breadcrumbs, notification, http, loader, confirmation, command, feeDefinitionsCache) {

    const that = this;

    that.sectionType = 'general';
    that.currentProductId = $route.current.params.productId;
    that.termDeposit = undefined;
    that.productDefinition = undefined;

    const s1 = termDepositsService.toObservable().subscribe(data => {

      const deposit = _.find(data, d => Number(d.id) === Number(that.currentProductId));

      if (deposit) {

        _.set(breadcrumbs, 'options', {'term-deposit-label': deposit.productDefinition.productName});

        // Keep product definition to update GL mappings
        that.productDefinition = deposit.productDefinition;

        // Copy properties with 1:1 mapping
        that.termDeposit = _.pick(deposit, [
          'defaultPdicTypeId',
          'depositGroup',
          'minimalBalance',
          'maximumBalance',
          'minimalTerm',
          'maximumTerm',
          'docStamp',
          'withholdingTax',
          'interestBoards',
          'maturityStrategies',
          'creditingStrategies',
          'placementStrategy',
          'taxExemptionThreshold',
          'interCustomerTransferAllowed',
          'partialWithdrawalAllowed',
          'partialInitialPlacementAllowed',
          'passbookConfig',
          'printPassbookAfterValidationSlip'
        ]);

        // Copy nested/complex properties
        Object.assign(that.termDeposit, {
          id: deposit.id,
          expired: deposit.productDefinition.expired,
          productName: deposit.productDefinition.productName,
          productTypeCode: deposit.productDefinition.productTypeCode,
          daysInYear: deposit.productDefinition.daysInYear,
          description: deposit.productDefinition.description,
          availableForSale: deposit.productDefinition.availableForSale,
          productDefinitionId: deposit.productDefinition.id,
          accrualEnabled: deposit.accrualScheduler.enabled,
          accrualInterval: deposit.accrualScheduler.interval,
          accrualCycleType: deposit.accrualScheduler.cycleType,
          feeDefinitions: deposit.feeDefinitions
        });

      } else {

        _.set(breadcrumbs, 'options', {'term-deposit-label': 'Create New'});

        const createInterestBoard = (rateType) => {
          return {
            rateType: rateType,
            defaultRate: 0,
            cells: [{
              rate: 0,
              minBalance: 0,
              maxBalance: null,
              minTerm: 0,
              maxTerm: null
            }]
          };
        };

        that.termDeposit = {
          daysInYear: 365,
          availableForSale: false,
          expired: false,
          docStamp: 0.5,
          withholdingTax: 20.0,
          taxExemptionThreshold: 1825,
          maturityStrategies: [],
          creditingStrategies: ['NOT_ALLOWED'],
          placementStrategy: 'NONE',
          interCustomerTransferAllowed: false,
          partialWithdrawalAllowed: false,
          partialInitialPlacementDisabled: false,
          accrualEnabled: true,
          accrualInterval: 1,
          accrualCycleType: 'MONTH_END',
          interestBoards: [
            createInterestBoard('BASIC'),
            createInterestBoard('PRETERMINATION_ON_FIRST_HALF'),
            createInterestBoard('PRETERMINATION_ON_SECOND_HALF'),
            createInterestBoard('OVERMATURE')
          ],
          feeDefinitions: [],
          passbookConfig: 'PASSBOOK_NOT_SUPPORTED'
        };
      }
    });

    that.saveDeposit = () => {
      if (that.termDeposit.id) {
        command.execute('UpdateDepositType', that.termDeposit).success(() => {
          termDepositsService.refetch();
          feeDefinitionsCache.evict();
          that.glMappingComponent.save().success(() => {
            $location.path("/admin/term-deposits");
          });
        }).approval(() => {
          that.glMappingComponent.save();
        });
      } else {
        command.execute('CreateDepositType', that.termDeposit).success(() => {
          termDepositsService.refetch();
          feeDefinitionsCache.evict();
          $location.path("/admin/term-deposits");
        });
      }
    };

    that.cancelChanges = () => {
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => $location.path('/admin/term-deposits'));
    };

    that.setGlMappingComponent = (instance) => {
      that.glMappingComponent = instance;
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
    };
  }
});
