import module from 'module';

const templateUrl = require('./group-officers.template.html');
module.component('groupOfficers', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '=',
    'form': '=',
    'officers': '<'
  },
  controller: function ($scope, $timeout, authentication, dict, userCache) {
    let that = this;
    that.dict = dict;

    that.userRoles = [
      'ACCOUNT_DEVELOPER',
      'AREA_MANAGER',
      'COLLECTOR'
    ];

    that.memberRoles = [
      'GROUP_CHIEF_BORROWER',
      'GROUP_TREASURER',
      'GROUP_SECRETARY'
    ];


    that.role = 'COLLECTOR'; //Default role for first row
    that.user;
    that.users;

    that.showMembers = false;
    that.showUsers = true; //Default for first row
    //Only for editing Collector
    that.showCollectorEdit = false;
    that.collectorEdit;

    that.roles = [...that.userRoles, ...that.memberRoles];
    that.roles.sort();

    const profileBranch = that.editMode ? that.profile.branchId : authentication.context.branchId;
    const userSubscr = userCache.toObservable().subscribe(users => {
      let usersFiltered = users
        .filter(u => u.branchId === profileBranch)
        .filter(u =>
          u.roles.find(
            role => (role.name == 'LOAN_OFFICER' || role.name == 'COLLECTOR')
          )
      ).map(u => Object.assign(u, {name:u.fullName}));
      that.users = usersFiltered;
    });

    that.$onChanges = (changes) => {
      //Remove currently added roles
      for(let o of changes.officers.currentValue) {
        let index = that.roles.findIndex(el => el === o.role);
        if(index > -1) {
          that.roles.splice(index,1);
        }
      }
    };

    that.isUserRole = () => {
      return that.userRoles.includes(that.role);
    };

    that.isMemberRole = () => {
      return that.memberRoles.includes(that.role);
    };

    that.onRoleSelected = () => {
      if (that.isUserRole()) {
        that.showUsers = true;
        that.showMembers = false;
      } else if(that.isMemberRole()) {
        that.showUsers = false;
        that.showMembers = true;
      }
    };

    that.onRoleAdd = () => {

      that.officers.push({
        id: that.isUserRole() ? that.user.id : that.user.customerId,
        role: that.role,
        name: that.user.name,
        isUserRole: that.isUserRole()
      });

      if(that.isUserRole()) {
        that.profile.loanOfficers.push({
          role: that.role,
          userId: that.user.id
        });
      } else if(that.isMemberRole()) {
        that.profile.groupLeaders.push({
          role: that.role,
          customerId: that.user.customerId
        });
      }

      const roleIndex = that.roles.findIndex((el) => el === that.role);
      that.roles.splice(roleIndex, 1);

      that.role = null;
      that.user = null;

      that.showMembers = false;
      that.showUsers = false;
    };

    that.onRoleRemove = (idx) => {
      const officer = that.officers[idx];
      that.roles.push(officer.role);
      that.roles.sort();
      if (officer.isUserRole) {
        let i = that.profile.loanOfficers.findIndex(el => el.userId === officer.id);
        that.profile.loanOfficers.splice(i, 1);
      } else {
        let i = that.profile.groupLeaders.findIndex(el => el.customerId === officer.id);
        that.profile.groupLeaders.splice(i, 1);
      }
      that.officers.splice(idx, 1);
    };

    that.onCollectorEdit = () => {
      that.showCollectorEdit = true;
    };

    that.onCollectorSave = () => {
      that.showCollectorEdit = false;

      let collectorUserId = that.officers[0].id;
      let collector = that.profile.loanOfficers.find(el => el.userId === collectorUserId && el.role === "COLLECTOR");
      collector.userId = that.collectorEdit.id;
      collector.name = that.collectorEdit.name;
      collector.id = null;

      that.officers[0].dbId = null;
      that.officers[0].id = that.collectorEdit.id;
      that.officers[0].name = that.collectorEdit.name;
    };

    that.$onDestroy = () => {
      userSubscr.unsubscribe();
    };
  }
});
