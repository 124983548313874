import module from 'module';
import _ from 'lodash';

const templateUrl = require('./create-account-hold.template.html');
module.component('createAccountHold', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, http, customerCache, branchService, authentication, confirmation, command) {
    const that = this;

    const customerId = $route.current.params['customerId'];
    const accountId = $route.current.params['accountId'];

    that.systemDate = null;
    that.account = {};
    that.hold = {autoRelease: false};

    const s1 = customerCache.depositAccounts(customerId).toObservable().subscribe((accounts) => {
      that.account = _.find(accounts, (a) => Number(a.id) === Number(accountId))
    });

    const s2 = branchService.toObservable().subscribe(branches => {
      const branch = _.find(branches, (b) => Number(b.id) === Number(authentication.context.branchId));
      that.systemDate = branch.postingDate;
    });

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${accountId}`);

    that.create = () => {
      confirmation(`Do you want to create new hold for ${that.hold.amount} PHP?`, () => {
        const input = Object.assign({}, {
          productId: accountId,
          amount: that.hold.amount,
          autoReleaseOn: that.hold.autoReleaseOn ? $filter('nxDate')(that.hold.autoReleaseOn) : null,
          creationRemarks: that.hold.creationRemarks
        });
        command.execute('CreateManualHold', input, {nxLoaderText: 'Creating hold'})
          .success(() => {
            customerCache.depositAccounts(customerId).refetch();
            that.redirectBack();
          })
      });
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
      s2.unsubscribe();
    }
  }
});
