import _ from 'lodash';

import module from 'module';

module.factory('http', function ($http, notification, authentication, internetCheckService, config, $q) {
  const API_DOMAIN_LOCAL_STORAGE_KEY = 'nx_apiOriginDomain';

  let errorNotificationTime = 5000;
  let notificationIssued = false;
  let firstRequest = true;
  let workingHoursShown = false;

  let cloudfrontCheckCompleted = false;
  let apiEndpointUrl = config.apiPrefix;

  let Request = {
    successCallback: [],
    errorCallback: [],
    alwaysCallback: [],

    defaultErrorCallback: function (data, status) {
      if (status === 403) {
        notification.show('Error', 'Access denied.');
      }
      if (data) {
        let errorCode = data.errorCode;
        let message = data.errorMessage;
        if (!errorCode || (errorCode && !message)) {
          message = "An unknown error occurred.";
        }
        notification.show("Error", message, errorNotificationTime);
        console.log(`REST Error:  [${status}] ${errorCode} (${message})`);
      } else {
        notification.show("Error", "An error occurred while contacting the server. Please try again later.", errorNotificationTime)
      }
    },

    logout: function () {
      const hash = encodeURI(window.location.hash);
      const href = hash ? `/${hash}` : undefined;
      authentication.logout(href);
    },

    loginOutsideOfWorkingHours: function (data, status) {
      let that = this;
      if (status === 401 && data.errorMessage === 'Authentication Failed: ACCESS_BLOCKED_DUE_TO_WORKING_HOURS') {
        if (!workingHoursShown) {
          alert("Access blocked due to working hours");
          workingHoursShown = true;
        }
        that.logout();
        console.log("Logged out, due to working hours");
        return true;
      }
      return false;
    },

    /**
     * On the first HTTP request, we check if the response was routed through Cloudfront.
     * If it was routed through Cloudfront and there is an api domain exposed that we can use,
     * all the following API requests should be routed through the api domain.
     *
     * This check is needed because some environments are accessed in both ways:
     * - Via CloudFront on public networks
     * - Directly to ElasticBeanstalk (nginx) on internal bank VPN network
     */
    switchToApiDomain: (response) => {
      if (cloudfrontCheckCompleted) return;

      /**
       * We can determine if the response was sent by Cloudfront using the Via header.
       * From https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/RequestAndResponseBehaviorCustomOrigin.html
       * Via – CloudFront sets the value to the following in the response to the viewer:
       *
       * Via: http-version alphanumeric-string.cloudfront.net (CloudFront)
       * For example, if the client makes a request over HTTP/1.1, the value is something like the following:
       * Via: 1.1 1026589cc7887e7a0dc7827b4example.cloudfront.net (CloudFront)
       */
      const via = response.headers('Via');
      if (via && via.includes('CloudFront')) {
        // verify if there is an API endpoint we can switch to
        const apiDomain = localStorage.getItem(API_DOMAIN_LOCAL_STORAGE_KEY);
        if (apiDomain && apiDomain.trim()) {
          // switching to external api endpoint...
          apiEndpointUrl = `${apiDomain}/api/v2`;
          console.log(`Switching to API endpoint: ${apiEndpointUrl}`);
        }
      }
      cloudfrontCheckCompleted = true;
    },

    attachCallbacks: function (httpRequest) {
      let that = this;
      httpRequest
        .then(response => {
          that.switchToApiDomain(response);

          const {data, status, headers} = response;
          that.processCallbacks(that.successCallback, data, status, headers);
          that.processCallbacks(that.alwaysCallback, data, status, headers);
          internetCheckService.reportSuccess();
          firstRequest = false;
        })
        .catch(ex => {
          const {data, status} = ex;
          let loggedOutDueToWorkingHours = that.loginOutsideOfWorkingHours(data, status);
          if (!loggedOutDueToWorkingHours) {
            if (status === 401 && !notificationIssued) {
              notificationIssued = true;
              if (!firstRequest) console.log('Session expired.');
              that.logout();
              firstRequest = false;
            }
          }

          if (that.errorCallback.length > 0) {
            that.processCallbacks(that.errorCallback, data);
          } else {
            that.defaultErrorCallback(data, status);
          }


          if (data && data.errorCode == 'NOT_AVAILABLE_OFFLINE') {
            console.log("Offline Error - The operation is not available in offline mode.");
          }

          that.processCallbacks(that.alwaysCallback, data);

          if (status === 0) {
            // probably connectivity problems. Better check it out...
            internetCheckService.retry();
          }
        });
    },

    processCallbacks: function (callbacks, ...args) {
      _.each(callbacks, function (cb) {
        cb(...args);
      })
    },
    /**
     *  @deprecated prefer toPromise()
     */
    success: function (callback) {
      this.successCallback.push(callback);
      return this;
    },
    /**
     *  @deprecated prefer toPromise()
     */
    error: function (callback) {
      this.errorCallback.push(callback);
      return this;
    },
    /**
     *  @deprecated prefer toPromise()
     */
    always: function (callback) {
      this.alwaysCallback.push(callback);
      return this;
    },
    toPromise: function () {
      const deferred = $q.defer();
      this.success(response => deferred.resolve(response))
          .error(error => deferred.reject(error));
      return deferred.promise;
    }
  };

  function createRequest(method, args) {
    let req = Object.create(Request);
    req.successCallback = [];
    req.alwaysCallback = [];
    req.errorCallback = [];

    const methodHandle = method ? $http[method] : $http;
    let httpRequest = methodHandle.apply($http, args);
    req.attachCallbacks(httpRequest);

    return req;
  }

  return {
    head: function () {
      arguments[0] = apiEndpointUrl + arguments[0];
      return createRequest('head', arguments);
    },
    get: function () {
      arguments[0] = apiEndpointUrl + arguments[0];
      return createRequest('get', arguments);
    },
    post: function () {
      if (arguments[0].url) arguments[0].url = apiEndpointUrl + arguments[0].url;
      else arguments[0] = apiEndpointUrl + arguments[0];
      return createRequest('post', arguments);
    },
    put: function () {
      arguments[0] = apiEndpointUrl + arguments[0];
      return createRequest('put', arguments);
    },
    doDelete: function () {
      arguments[0] = apiEndpointUrl + arguments[0];
      return createRequest('delete', arguments);
    },
    http: function () {
      if (arguments[0].url) arguments[0].url = apiEndpointUrl + arguments[0].url;
      else arguments[0] = apiEndpointUrl + arguments[0];
      return createRequest(undefined, arguments);
    },
  };

});
