import module from 'module';
import $ from 'jquery';
import _ from 'lodash';
import {NgTableParams} from 'ng-table/ng-table.js';

const templateUrl = require('./active-stop-order-list.template.html');
module.component('activeStopOrderList', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $location, $filter, $timeout, http, authentication, confirmation, branchService, userCache, command) {
    const that = this;

    // Stop order search criteria
    that.filter = {branchId: null};

    const convertFilterToQueryString = (params) => {
      return $.param({
        pageNo: params.page() - 1,
        pageSize: params.count(),
        branchId: that.filter.branchId,
        onlyActive: true
      });
    };

    const createSearchQueryPromise = (params) => {
      const queryParams = convertFilterToQueryString(params);
      return http.get(`/checks/stop-orders?${queryParams}`, {nxLoaderText: 'Loading stop orders'}).success(page => {
        that.tableConfig.data = page.result;
        that.tableConfig.total(page.totalCount);
        that.stopOrders = page.result;
        if (that.stopOrders && that.stopOrders.length > 0) {
          _.forEach(that.stopOrders, (stopOrder) => {
            const branch = _.find(that.branches, {id: stopOrder.branchId});
            stopOrder.branchName = branch ? branch.name : '-';
            const user = _.find(that.users, {id: stopOrder.createdBy});
            stopOrder.createdByFullName = user ? user.fullName : '-';
          });
        }
      });
    };

    that.itemClicked = (stopOrder, $event) => {
      $event.stopPropagation();
      that.selectedCheck = stopOrder;
      that.selectedCheckId = stopOrder.id;
    };

    that.tableConfig = new NgTableParams({
      count: 20,
    }, {
      counts: [],
      paginationMaxBlocks: 5,
      paginationMinBlocks: 5,
      getData: params => createSearchQueryPromise(params)
    });

    that.reloadList = () => {
      that.tableConfig.reload();
    };

    that.remove = (id) => {
      confirmation(`Do you want to remove stop order?`, () => {
        command.execute('RemoveCheckStopOrder', {id: id})
          .success(() => {
            that.reloadList();
          });
      });
    };

    // Read users to map createdBy field to user full name
    const usersSub = userCache.toObservable().subscribe(users => that.users = users);

    // Read branches available for user
    const branchesSub = branchService.toObservable().subscribe(branches => {

      // Keep complete list of branches to translate stop order branch id to name
      that.branches = branches;

      // Initialize list of branches available to user
      const branchIds = authentication.context.branchIds;
      that.availableBranches = _.filter(branches, (b) => _.includes(branchIds, b.id));

      // Prepare default filter settings
      const defaultBranch = _.find(that.availableBranches, {id: authentication.context.branchId});
      const postingDate = Date.parse(defaultBranch.postingDate);
      that.filter.branchId = defaultBranch.id;
      that.filter.dateRange = {from: postingDate, to: postingDate};

      // Load initial stop orders page
      that.tableConfig.page(1);
      that.tableConfig.reload();
    });

    that.$onDestroy = () => {
      usersSub.unsubscribe();
      branchesSub.unsubscribe();
    };
  }
});
