import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('forexDocumentRateCache', (http, CacheFactory, config) =>
    new LocalCache({
        provider: () => http.get('/forex/document-rates'),
        cacheFactory: CacheFactory,
        cacheTime: config.cache.forexDocumentRates,
        cacheName: 'forexDocumentRateCache'
    })
);
