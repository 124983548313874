import {Observable} from 'rxjs/Observable'
import {NgTableParams} from 'ng-table/ng-table.js';
import _ from 'lodash';


import module from 'module';


module.factory('operationTableBuilder', function (http, glMappingsService, userCache) {
  let that = this;

  let service = {};

  const attrByName = (operation, type, defaultValue = '-') => {
    const attribute = _.find(operation.attributes, attr => attr.type === type);
    return _.get(attribute, 'value', defaultValue);
  };


  service.buildNgTable = (config) => {
    let productId = config.productId;
    let status = config.status;
    let groups = config.groups;
    let passbookOnly = config.isPassbook;
    let dateFrom = config.dateFrom;
    let dateTo = config.dateTo;

    return new NgTableParams({
      count: 15,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: params => {
        if (productId) {
          const operationsObs = Observable.fromPromise(
            http.get('/products/operations', {
              params: {
                pageNo: params.page() - 1,
                pageSize: params.count(),
                productId: productId,
                fetchAttributes: true,
                fetchBalanceSnapshots: true,
                fetchPassbookOperationsOnly: passbookOnly,
                status: status,
                group: groups,
                dateFrom: dateFrom,
                dateTo: dateTo
              }
            }).toPromise()
          );

          let promise = Observable.of(operationsObs, userCache.toObservable(), glMappingsService.feesDefinitions.toObservable())
            .combineAll((transactions, users, fees) => {
              params.total(transactions.totalCount);
              return transactions.result
                .map(transaction => {
                  const registrationUser = _.find(users, user => user.id === transaction.registrationUserId);

                  let feeName = undefined;
                  if (_.get(transaction, 'operationGroup') === 'APPLY_FEE') {
                    const fee = _.find(fees, fee => fee.id === _.get(transaction, 'feeDefinitionId'));
                    feeName = _.get(fee, 'feeName');
                  }

                  if(passbookOnly) {
                    transaction.printed = attrByName(transaction, 'PASSBOOK_LINE', null) ? true : false;
                  }

                  // Add [balanceAfter] & [holdsAfter] properties (extract it from balance snapshots)
                  const snapshots = transaction.balanceSnapshots;
                  if (snapshots && snapshots.length > 0) {
                    // Find balance snapshots for [productId]
                    const targetSnapshots = _.filter(snapshots, {productId: parseInt(config.productId)});
                    // First try to find 'AFTER' snapshot
                    let snapshot = _.find(targetSnapshots, {phase: 'AFTER'});
                    // If AFTER snapshot does not exist -> get 'BEFORE'
                    if (!snapshot) {
                      snapshot = _.find(targetSnapshots, {phase: 'BEFORE'});
                    }
                    // If snapshot was found -> assign it's values to operation
                    if (snapshot) {
                      Object.assign(transaction, {
                        balanceAfter: snapshot.balance,
                        holdsAfter: snapshot.holds
                      });
                    }
                  }

                  return Object.assign(transaction, {
                    registrationUser,
                    feeName,
                  });
                })})
            .take(1)
            .toPromise();
          if(config.postData) {
            promise.then(config.postData);
          }
          return promise;
        } else {
          return Promise.resolve([]);
        }
      }});


  };

  return service;
});
