import module from 'module';
import $ from 'jquery';

import templateUrl from './layaway-receipt-view.template.html';
import './layaway-receipt-view.style.less';

class LayawayReceiptViewComponent {
  constructor($route, $location, http, nav, printService, $timeout) {
    this.$route = $route;
    this.$location = $location;
    this.http = http;
    this.nav = nav;
    this.printService = printService;
    this.$timeout = $timeout;
  }

  $onInit() {
    const layawayId = this.$route.current.params['layawayId'];
    this.$timeout(() => {
      this.http.post(`/print/LAYAWAY_RECEIPT/json`, {layawayId: layawayId}).success(doc => {
        this.doc = doc;

        return this.printService.printToContainer(doc, {
          autoPrint: false,
          backgroundEnabled: true,
          container: $('.iframe-preview')
        });
      });
    });
  }

  redirectBack() {
    this.nav.back()
  }

  print() {
    this.printService.printToContainer(this.doc, {
      autoPrint: true,
      backgroundEnabled: false,
      container: $('.iframe-invisible')
    });
  }
}

module.component('layawayReceiptView', {
  templateUrl: templateUrl,
  controller: LayawayReceiptViewComponent
});