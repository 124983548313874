import module from 'module';
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import {
  addAccountLabels
} from 'components/general-ledger/common/gl.utils';

const templateUrl = require('./memo.template.html');
module.component('customerAccountsMemo', {
  templateUrl: templateUrl,
  controller: function($route, $location, $filter,  http, customerCache, branchService, productDefinitionService, glMappingsService,
                        accountOperationService) {
    let that = this;

    that.selectConfig = {
      placeholder: 'Select Account',
      searchField: 'label',
      valueField: 'fullCode',
      labelField: 'label',
      maxItems: 1
    };

    that.depositAccount = {};

    // form input
    that.remarks = null;
    that.amount = null;
    that.selectedAccount = null;
    that.accounts = [{}];

    const bigSumBy = (collection, itemProperty) => {
      return collection.reduce((sum, item) => sum.add(item[itemProperty] || 0), new BigNumber(0));
    };

    that.calculateAmount = () => {
      return bigSumBy(that.accounts, 'amount').toNumber();
    };

    that.addNewAccount = () => {
      that.accounts.push({});
    };

    that.removeAccount = (account) => {
      that.accounts.splice(_.findIndex(that.accounts, account), 1);
    };

    let customerId = $route.current.params['customerId'];
    let accountId = $route.current.params['accountId'];

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${accountId}`);

    that.isCredit = $location.path().endsWith('credit-memo');
    that.isDebit = $location.path().endsWith('debit-memo');
    if (!that.isCredit && !that.isDebit) that.redirectBack();


    customerCache.depositAccounts(customerId).toObservable()
      .combineLatest(productDefinitionService.toObservable(), (depositAccounts, products) =>
        depositAccounts.map(d => {
          let definition = _.find(products, {id: d.definitionId});
          return Object.assign(d, {productDefinition: definition});
        })
      )
      // Extend product instance with branch name
      .combineLatest(branchService.toObservable(), (depositAccounts, branches) =>
        depositAccounts.map(d => {
          return Object.assign(d, {branch: _.find(branches, {id: d.branchId})});
        })
      )
      // accountId is a string
      .first()
      .subscribe(accounts => {
        // fetch product mappings for account product type id
        that.depositAccount = _.find(accounts, (a) => String(a.id) === accountId);
        return that.depositAccount;
      });

    /**
     * Credit & debit memo operations should use accounts from:
     * ASSET/LIABILITY/INCOME/EXPENSE groups.
     *
     * TODO = In future this list should be filtered to include MEMO accounts only.
     */
    glMappingsService.accounts.toObservable().subscribe(glAccounts => {
      that.glAccounts = addAccountLabels(
        _.filter(glAccounts, function (glAccount) {
          return ['ASSET', 'LIABILITY', 'INCOME', 'EXPENSE'].includes(glAccount.accountGroup);
        })
      );
    });

    that.amountSelected = (amount) => {
      that.selectedAmount = amount;
    };

    that.doOperation = () => {
      let req = {
        customerId: customerId,
        productId: accountId,
        amount: that.calculateAmount(),
        units: that.accounts,
        remarks: that.remarks
      }
      if (that.isCredit) {
        accountOperationService.memoDeposit(req, that.redirectBack);
      } else {
        accountOperationService.memoWithdraw(req, that.redirectBack);
      }
    };
  }
});
