import module from "module";

const templateUrl = require('./pawn-product-list.template.html');
module.component('pawnProductList', {
  templateUrl,
  controller: function (pawnProductsCache) {
    const that = this;
    this.pawnProducts = [];

    const s = pawnProductsCache.toObservable().subscribe(data => this.pawnProducts = data ? data : []);

    that.$onDestroy = () => {
      s.unsubscribe();
    };

  }
});
