import _ from 'lodash';
import module from 'module';
import $ from 'jquery';

import {cicContractTypes, loanCreationTypes} from 'constants/loan';

import './group-loans-create-form.style.less';
import templateUrl from './group-loans-create-form.template.html';

class GroupLoansCreateForm {
  constructor(groupLoansCreateService, $element,) {
    this.groupLoansCreateService = groupLoansCreateService;
    this.$element = $element;
  }

  $onInit() {
    this.creationTypes = [...loanCreationTypes];
    this.cicContractTypes = [...cicContractTypes];

    this.defaultMemberId = -1;
    this.defaultMember = {
      id: this.defaultMemberId ,
      effectiveName: 'Default Configuration',
      default: true
    };

    this.membersWithDefault = [this.defaultMember, ...this.members];
    this.newMemberData = {};
    this.membersWithDefault.forEach(member =>
      Object.assign(this.newMemberData, {[member.id]: {
          loan: {
            loanTypeId: this.product.id,
            customerId: Number.parseInt(member.id),
            creationType: this.product.defaultCreationType,
            automaticTransferAgreement: {
              transferStrategy: 'NONE'
            }
          },
        }})
    );
  }

  removeMember(memberToRemove) {
    this.membersWithDefault = this.membersWithDefault.filter(member => member.id !== memberToRemove.id);
    delete this.newMemberData[member.id];
  }

  formsPropsCopy(memberData) {
    return {loan: memberData.loan};
  }

  copyDefaultConfigurationToAll() {
    for(let [key, memberData] of Object.entries(this.newMemberData)) {
      if(key === String(this.defaultMemberId)) {
        continue;
      }

      this.copyDefaultConfigurationToHere(memberData);
    }
  }

  copyDefaultConfigurationToHere(memberData) {
    const formModel = this.formsPropsCopy(this.newMemberData[this.defaultMemberId]);
    const clonedFormModel = _.clone(formModel);
    _.merge(memberData, _.omit(clonedFormModel, ['loan.customerId', 'loan.creationType', 'loan.principalAmount']));
  }

  formInvalid() {
    return Object.entries(this.newMemberData)
      .filter(([id, _]) => id !== String(this.defaultMemberId))
      .map(([_, value]) => value)
      .some(newMemberData => {
        return !newMemberData.createParametersForm ||
          newMemberData.createParametersForm.$invalid ||
          !newMemberData.createLaiDetailsForm ||
          newMemberData.createLaiDetailsForm.$invalid ||
          !newMemberData.overrideForm ||
          newMemberData.overrideForm.$invalid ||
          newMemberData.headerForm.$invalid;
      })
  }

  formReady() {
    this.membersWithDefault.filter(member => member.id !== this.defaultMemberId)
      .forEach(member => {
        const originalMember = this.members.find(originalMember => originalMember.id === member.id);
        const memberData = this.newMemberData[member.id];
        originalMember.newLoan = memberData;
        originalMember.newProduct = this.product;

        // I'm not unsubscribing from this observable on navigating away from this component - I need loan calculation in group-loans-create view
        this.groupLoansCreateService.calculateLoan({
          customerGroupId: this.groupProfile.id,
          loan: memberData.loan,
        })
          .subscribe(loanCalculation => {
            originalMember.newLoanCalculation = loanCalculation;
          });
      });

    this.members.forEach(member => member.applying = false);

    this.formSubmitAction();
  }


  $postLink() {
    const $formEl = this.$element.find('.group-loans-create-form');
    // all clicks on accordion header (marked with data-toggle="collapse") will cause accordion to expand and collapse
    // due to the presence of inputs, buttons and selects within header, clicking on them will cause the same expanding behaviour,
    // which is clearly non intentional, so when such events are triggered, we cancel them
    $formEl.on('click', event => {
      // we need to cancel bubbling to prevent expanding of accordion
      const shouldPreventPropagation = $(event.target)
        .closest('glue-container, button, select, input').length;

      if (shouldPreventPropagation) {
        event.stopPropagation();
      }

    });
  }
}

module.component('groupLoansCreateForm', {
  templateUrl,
  bindings: {
    'members': '<',
    'product': '<',
    'customerId': '<',
    'groupProfile': '<',
    'formSubmitAction': '<'
  },
  controller: GroupLoansCreateForm
});
