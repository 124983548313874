import module from 'module';


module.factory('loanPreTerminateService', function($filter) {
  let service = {};

  service.getMessage = (totalInfo) => {
      let msg = `Do you want to pay ${$filter('php')(totalInfo.total)} ? `;
      let waiveVal = totalInfo.totalWaive;

      let waiveMsg = waiveVal === 0 ? '' : `The amount ${$filter('php')(waiveVal)} will be waived.`;

      msg = msg + waiveMsg;

      return `${msg}`;
  };

  return service;
});