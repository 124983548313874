import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('misGroupsCache', (http, CacheFactory, config) =>
  new LocalCache({
      provider: () => http.get(`/products/loans/mis-groups`),
      cacheFactory: CacheFactory,
      cacheTime: config.cache.misGroups,
      cacheName: `misGroups`
    }
  )
);
