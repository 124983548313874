import module from 'module';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

module.factory('accountOperationService', function ($filter, http, notification, confirmation, customerCache, casaProductPrintService, command, depositAccountService) {
  const that = this;

  const cashOperationMsg = (isCredit, amount, belowMaintaining = false) => {
    const typeToString = () => isCredit ? 'deposit' : 'withdraw';
    const typeToLoaderString = () => isCredit ? 'Depositing' : 'Withdrawing';
    const typeToPastString = () => isCredit ? 'deposited' : 'withdrawn';

    let message = `Do you want to ${typeToString()} ${$filter('php')(amount)}?`;
    if (belowMaintaining) {
      message = `${message} The balance after this transaction will be lower than the maintaining balance.`
    }

    return {
      confirmMsg: message,
      loaderMsg: `${typeToLoaderString()} funds...`,
      successMsg: `The selected amount has been successfully ${typeToPastString()}.`
    }
  };

  const formatDebitMessage = (customerId, accountId, amount, callback) => {
    customerCache.depositAccounts(customerId)
      .toObservable()
      .map(accounts => _.find(accounts, a => Number(a.id) === Number(accountId)))
      .combineLatest(depositAccountService.toObservable(), (account, types) => {
        account.type = _.find(types, t => Number(t.id) === account.typeId);
        return account;
      })
      .first()
      .subscribe(account => {
        const balanceAfter = new BigNumber(account.balance).minus(amount).toFixed(2);
        const maintainingBalance = new BigNumber(account.type.maintainingBalance);
        const belowMaintaining = maintainingBalance.greaterThan(balanceAfter);
        callback(cashOperationMsg(false, amount, belowMaintaining));
      })
  };

  const executeOperation = (cmd, customerId, productId, amount, request,
        {confirmMsg, loaderMsg, successMsg}, callback = () => {}) => {
    confirmation(confirmMsg, () => {
      command.execute(cmd, request, {nxLoaderText: loaderMsg})
        .success((res) => {
          const operationId = res.output.operationId;
          notification.show(successMsg);
          customerCache.depositAccounts(customerId).refetch();
          casaProductPrintService('DEPOSIT_ACCOUNT', customerId, productId, operationId).finally(() => callback());
        }, true);
    });
  };

  that.cashWithdraw = ({customerId, productId, amount}, callback) => {
    formatDebitMessage(customerId, productId, amount, (message) => {
      executeOperation('WithdrawAccountFundsByCash', customerId, productId, amount, {
        productId: productId,
        amount: amount,
        entryType: 'DEBIT'
      }, message, callback);
    });
  };

  that.checkWithdraw = ({customerId, productId, amount, validFrom, depositoryAccountId, micrNumber, checkNumber, payee, remarks}, callback) => {
    formatDebitMessage(customerId, productId, amount, (message) => {
      executeOperation('WithdrawAccountFundsByCheck', customerId, productId, amount, {
        productId: productId,
        amount: amount,
        entryType: 'DEBIT',
        validFrom: validFrom,
        depositoryAccountId: depositoryAccountId,
        micrNumber: micrNumber,
        checkNumber: checkNumber,
        payee: payee,
        remarks: remarks
      }, message, callback)
    });
  };

  that.encashOnUsCheck = ({customerId, productId, amount, validFrom, micrNumber, payee, remarks}, callback) => {
    formatDebitMessage(customerId, productId, amount, (message) => {
      executeOperation('EncashOnUsCheck', customerId, productId, amount, {
        amount: amount,
        validFrom: validFrom,
        micrNumber: micrNumber,
        payee: payee,
        remarks: remarks,
      }, message, callback)
    });
  };

  that.memoWithdraw = ({customerId, productId, amount, units, remarks}, callback) => {
    formatDebitMessage(customerId, productId, amount, (message) => {
      executeOperation('WithdrawAccountFundsByMemo', customerId, productId, amount, {
        productId: productId,
        amount: amount,
        entryType: 'DEBIT',
        units: units,
        remarks: remarks
      }, message, callback);
    });
  };

  that.memoDeposit = ({customerId, productId, amount, units, remarks}, callback) => {
    executeOperation('DepositMemoToAccount', customerId, productId, amount, {
      productId: productId,
      amount: amount,
      entryType: 'CREDIT',
      units: units,
      remarks: remarks
    }, cashOperationMsg(true, amount), callback);
  };

  const closeMessage = {
    confirmMsg: 'Are you sure you want to close account?',
    loaderMsg: 'Closing account',
    successMsg: 'Deposit account has been successfully closed'
  };

  that.closeWithCash = ({customerId, productId, amount}, callback) => {
    executeOperation('CloseAccountWithCash', customerId, productId, amount, {
      productId: productId,
      entryType: 'DEBIT',
      amount: amount
    }, closeMessage, callback);
  };

  that.closeWithCheck = ({customerId, productId, amount, validFrom, depositoryAccountId, micrNumber, checkNumber, payee, remarks}, callback) => {
    executeOperation('CloseAccountWithCheck', customerId, productId, amount, {
      productId: productId,
      entryType: 'DEBIT',
      amount: amount,
      validFrom: validFrom,
      depositoryAccountId: depositoryAccountId,
      micrNumber : micrNumber,
      checkNumber: checkNumber,
      payee: payee,
      remarks: remarks
    }, closeMessage, callback);
  };

  return that;
});
