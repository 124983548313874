import 'rxjs/add/operator/concat';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import {Observable} from 'rxjs/Observable';
import module from "module";


const defaultResponseFilterer = (response, branchId) => response.filter(product => product.branchId === branchId);

class ProductCacheService {
  constructor(customerAccessService, authentication) {
    this.customerAccessService = customerAccessService;
    this.authentication = authentication;
  }

  /**
   * Restricts access to data from LocalCache based on currently active user.
   * Internal implementation relies heavily on proxies. Once method toObservable is invoked
   * on returned LocalCache, stream of products will be filtered only to contain products, user has access
   * to. Any changes in access will result in new values emitted.
   */
  withRestrictedAccess(cache, customerId, accessType, responseFilter = defaultResponseFilterer) {
    return new Proxy(cache, {
        get: (target, name) => {
          if (name !== 'toObservable') {
            return target[name];
          }

          const customerAccessStream = this.customerAccessService
            .ofType(accessType)
            .onAccessChanged()
            // when customer changes, deny access to cache for a previous one
            // this situation may happen when you cache with restricted access (fullAccess=false)
            // without context of a customer (this should never happen)
            .takeWhile(value => String(value.customerId) === String(customerId))
            .map(value => value.access)
            .concat(Observable.of(false));

          return () => target.toObservable()
            .combineLatest(customerAccessStream, (products, access) => {
              if (access) {
                return products;
              }

              return responseFilter(products, this.authentication.context.branchId);
            });
        }
      }
    )
  }
}

module.service('productCacheService', ProductCacheService);