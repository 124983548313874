import module from 'module';
import {NgTableParams} from 'ng-table/ng-table.js';
import {updateRefreshTime} from '../../common/refresh-button/refresh-button.component';

import templateUrl from './forex-dashboard.template.html'
import './forex-dashboard.style.less';

const FIVE_MINUTES = 1000 * 60 * 5;
const FOREX_BUYING_STATS_URL = '/forex/buying-stats';

class ForexDashboard {

  constructor($scope, http, $interval) {
    this.$scope = $scope;
    this.http = http;
    this.$interval = $interval;
  }

  $onInit() {
    this.tableConfig = new NgTableParams({
        count: 10
      }, {
        counts: [],
        paginationMinBlocks: 3,
        paginationMaxBlocks: 8,
        getData: params => this.stats.entries.filter(this.selectPage(params))
      }
    )
    this.$interval(() => this.refresh(), FIVE_MINUTES);
  }

  selectPage(params) {
    return (_, i) =>
      i >= (params.page() - 1) * params.count() &&
      i < params.page() * params.count();
  }

  async refresh() {
    this.stats = await this.http.get(FOREX_BUYING_STATS_URL).toPromise();
    this.tableConfig.reload();
    this.tableConfig.total(this.stats.entries.length);
    updateRefreshTime(this.$scope);
  }

  hasEntries() {
    return this.stats && this.stats.entries && this.stats.entries.length > 0;
  }
}

module.component('forexDashboard', {
  templateUrl,
  controller: ForexDashboard
});
