import module from 'module';
import './customer-loans-edit-automatic-transfer.style.less';
import templateUrl from './customer-loans-edit-automatic-transfer.template.html'

class EditAutomaticTransfer {
  constructor($location, confirmation, customerCache, command) {
    this.$location = $location;
    this.confirmation = confirmation;
    this.customerCache = customerCache;
    this.command = command;
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loan.id}`)
  };

  async save() {
    const actionApproved = await this.confirmation('Do you want to update the automatic transfer?');
    if(!actionApproved) {
      return;
    }

    await this.command.execute('EditLoanAutomaticTransferAgreement', {
      productId: this.loan.id,
      customerId: this.customerId,
      automaticTransferAgreement: this.loan.automaticTransferAgreement
    }).toPromise();

    this.customerCache.loans(this.customerId).refetch();
    this.redirectBack();
  };

  $onChanges(changes) {
    if(changes.loan) {
      this.loan = {
        ...changes.loan.currentValue,
        automaticTransferAgreement: {
          transferStrategy: 'NONE',
          ...changes.loan.currentValue.automaticTransferAgreement
        }
      }
    }
  }
}

module.component('customerLoansEditAutomaticTransfer', {
  templateUrl,
  bindings: {
    customerId: '<',
    loan: '<'
  },
  controller: EditAutomaticTransfer
});