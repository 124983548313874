import module from 'module';

import './layaway-payment-view.style.less';

import templateUrl from './layaway-payment-view.template.html';

class LayawayPaymentView {
  constructor ($routeParams, $location, pawnItemCache, pawnItemTypeCache, authentication, notification, command,
               confirmationTemplate, actionCategoryCache, systemPropertyCache, glMappingsService, branchService,
               $filter, customerCache, confirmation, http) {
    this.$routeParams = $routeParams;
    this.$location = $location;
    this.pawnItemCache = pawnItemCache;
    this.pawnItemTypeCache = pawnItemTypeCache;
    this.authentication = authentication;
    this.notification = notification;
    this.command = command;
    this.confirmationTemplate = confirmationTemplate;
    this.actionCategoryCache = actionCategoryCache;
    this.systemPropertyCache = systemPropertyCache;
    this.glMappingsService = glMappingsService;
    this.branchService = branchService;
    this.$filter = $filter;
    this.customerCache = customerCache;
    this.confirmation = confirmation;
    this.http = http;
  }

  async $onInit() {
    this.item = {};

    this.amount = 0;

    this.customerId = parseInt(this.$routeParams.customerId);
    const layawayId = parseInt(this.$routeParams.layawayId);
    this.request = {
      denominationBundle: null,
      remarks: null,
      customerId: null
    };

    this.layaway = await this.http.get(`/products/layaways/${layawayId}`).toPromise();
  }

  goBack() {
    this.$location.path(`/customer/${this.customerId}/layaways/${this.layaway.id}`);
  }

  async pay() {
    const confirmed = await this.confirmationTemplate({
      question: `Do you want to proceed?`,
      details: [
        {label: 'Amount', description: this.$filter('php')(this.request.amount)}
      ]
    });

    if (!confirmed) {
      return;
    }

    const request = {
      ...this.request,
      productId: this.layaway.id,
      customerId: this.layaway.customerId,
      branchId: this.layaway.branchId
    };

    const response = await this.command.execute('PayLayaway', request).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.customerCache.layaways(this.layaway.customerId).evict();
    this.$location.path(`/customer/${this.layaway.customerId}/layaways/`);
  }
}

module.component('layawayPaymentView', {
  templateUrl: templateUrl,
  controller: LayawayPaymentView
});
