import module from 'module';
import $ from 'jquery';

module.directive('fileReader', function() {
  return {
    scope: {
      fileReader:"="
    },
    link: function(scope, element) {
      const that = this;
      $(element).on('change', function(changeEvent) {
        var files = changeEvent.target.files;
        if (files.length) {
          var r = new FileReader();
          r.onload = function(e) {
            var contents = e.target.result;
            scope.$apply(function () {
              //force model update for file with same content as previously loaded
              scope.fileReader = undefined;
            });
            scope.$apply(function () {
              scope.fileReader = contents;
            });
          };

          r.readAsText(files[0]);
          element.val("");
        }
      });
    }
  };
});
