import "rxjs/add/observable/fromPromise";
import "rxjs/add/observable/of";
import "rxjs/add/operator/combineAll";
import {Subscription} from "rxjs/Subscription";
import _ from "lodash";

import module from "module";

const templateUrl = require('./customer-pawn-amortization.template.html');
module.component('customerPawnAmortization', {
  templateUrl: templateUrl,
  bindings: {
    selectedPawn: '<'
  },
  controller: function () {
    let that = this;
  }
});