import module from 'module';
import _ from 'lodash';

const templateUrl = require('./credit-on-us-check.template.html');
module.component('customerAccountsCreditOnUsCheck', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, customerCache, confirmation, command) {

    const that = this;
    const customerId = $route.current.params['customerId'];
    const accountId = $route.current.params['accountId'];

    that.account = {};
    that.check = {};

    customerCache.depositAccounts(customerId).toObservable().first().subscribe(accounts => {
      that.account = _.find(accounts, {id: accountId});
    });

    that.credit = () => {
      confirmation(`Do you want to credit on-us check with ${$filter('php')(that.check.amount)}?`, () => {
        command.execute('CreditOnUsCheck', {
          productId: accountId,
          amount: that.check.amount,
          validFrom: $filter('nxDate')(that.check.validFrom),
          micrNumber: that.check.micrNumber,
          payee: that.check.payee,
          remarks: that.check.remarks
        }, {nxLoaderText: 'Crediting check'}).success(() => {
          customerCache.depositAccounts(customerId).refetch();
          that.redirectBack();
        });
      });
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${accountId}`);
  }
});
