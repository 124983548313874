import module from 'module';

import templateUrl from './layaway-product-list.template.html';

class LayawayProductListComponent {
  constructor(layawayProductsCache) {
    this.layawayProductsCache = layawayProductsCache;
  }

  async $onInit() {
    this.layawayProducts = await this.layawayProductsCache.toPromise();
  }
}

module.component('layawayProductList', {
  templateUrl,
  controller: LayawayProductListComponent
});
