import module from 'module';
import _ from 'lodash';

module.factory('miscTransactionsService', function (actionCommand) {

  /**
   * Approval user must meet following criteria:
   * 1. Is not phantom
   * 2. Is from the same branch as action user
   * 3. Has appropriate role (as of action template)
   */
  const canApproveAction = (branchId, actionTemplate, user, canApproveCommand) => {
    actionTemplate.commandName = actionCommand.actionTypeToCommand(actionTemplate.type);
    // Check if user is non phantom
    if (user.phantom) return false;
    // Check if user is from the same branch as action user
    if (user.branchId !== branchId) return false;
    // If template defines approval roles -> compare it with user roles
      // check if the user has the roles to approve a given template

    if (!user.roles || user.roles.length === 0) return false;

    let userRoleIds = user.roles.map(r => r.id);

    // finally check if a role can approve a command
    return canApproveCommand(actionTemplate.commandName, userRoleIds);
  };

  return {
    filterApprovalUsers: (branchId, actionTemplate, users, canApproveCommand) => {
      return _.filter(users, u => canApproveAction(branchId, actionTemplate, u, canApproveCommand));
    }
  };
});
