import ImageCompressor from '@xkeshi/image-compressor';
import fileIcon from './file-icon.png';

import module from 'module';

const imageCompressor = new ImageCompressor();
const compressionOptions = {
  quality: '0.8',
};

const isImage = blob => /image\/.*/.test(blob.type);


// supports readonly attribute
/**
 * fileIds - ids of uploaded files, for no uploaded files pass empty array
 * fileAdditionalMetadata - js object will be sent in request body
 * validate - js object with validation https://github.com/danialfarid/ng-file-upload (ngf-validate property)
 * showWebCamHandler - use handler when you want to use webcam functionality only.
 */
const templateUrl = require('./file-upload.template.html');
module.component('fileUpload', {
  templateUrl,
  require: {
    ngModel: '^ngModel',
  },
  bindings: {
    'fileAdditionalMetadata': '<',
    'validate': '<',
    'maxFiles': '<',
    'maxSize': '<',
    'showFileName': '<',
    'showRemarks': '<',
    'ngDisabled': '<',
    'enableWebcam': '<',
    'allowDuplicates': '<',
    'enableDropFile': '<',
    'enableSelectFile': '<',
    'showThumbnail': '<',
    'showWebCamHandler': '&'
  },
  controller: function ($scope, $attrs, notification, fileService, $element) {
    const that = this;

    $element.click(evt => that.ngModel.$setTouched());

    that.validate = that.validate || {
      size: {
        max: that.maxSize || '1MB',
      }
    };

    const modelValue = () => that.ngModel.$modelValue || [];

    const triggerNgModelUpdate = (newValue) => {
      that.ngModel.$setTouched();
      that.ngModel.$setViewValue(newValue);
    };

    that.$onInit = () => {
      that.maxFiles = that.maxFiles || Number.MAX_SAFE_INTEGER;
      that.ngModel.$isEmpty = value => !value || value.length === 0;
      that.showRemarks = that.showRemarks === undefined ? true : that.showRemarks;
      that.showFileName = that.showFileName === undefined ? true : that.showFileName;
      that.enableWebcam = that.enableWebcam === undefined ? true : that.enableWebcam;
      that.enableDropFile = that.enableDropFile === undefined ? true : that.enableDropFile;
      that.enableSelectFile = that.enableSelectFile === undefined ? true : that.enableSelectFile;
      that.showThumbnail = that.showThumbnail === undefined ? true : that.showThumbnail;

      that.ngModel.$render = () => {
        modelValue().forEach(file => {
          fileService.downloadFile(file.id, true, false)
            .success(data => {
              const fileReader = new FileReader();
              fileReader.readAsDataURL(data);
              fileReader.onload = () => {
                const base64Image = fileReader.result;
                Object.assign(file, { // here we update file in place
                  file: data,
                  thumbnail: base64Image
                });

                triggerNgModelUpdate([...modelValue()]); // create new copy of an array - needed for model to discover update
              };
            }).error(e => {
            console.error(e);
            notification.show('Error', 'Error downloading file');
          });

          fileService.getMetadata(file.id)
            .success(data => {
              Object.assign(file, { // here we update file in place
                remarks: data.remarks,
                fileName: data.fileName,
              });

              triggerNgModelUpdate([...modelValue()]);
            }).error(e => {
            console.error(e);
            notification.show('Error', 'Error downloading file metadata');
          });
        });
      };
    };

    $attrs.$observe('readonly', value => {
      that.readonly = value;
    });

    const uploadFileToStorage = (file, blob) => {
      fileService.uploadFile(blob, {allowDuplicates: that.allowDuplicates !== false}, {
        uploadEventHandlers: {
          progress: function (e) {
            file.progress = ~~(100 * e.loaded / e.total);
          }
        },
        nxLoaderSkip: true,
        additionalData: that.fileAdditionalMetadata,
      }).success(data => {
        file.uploaded = true;
        const {id,} = data;
        file.id = id;
        notification.show('Success', 'File uploaded');
        triggerNgModelUpdate([...modelValue()]);
      }).error(e => {
        console.error(e);
        file.uploaded = false;
        file.progress = 0;
        notification.show('Error', e.errorMessage || 'Couldn\'t upload file');
        triggerNgModelUpdate([...modelValue().filter(f => f !== file)]);
      });
    };

    that.addFiles = blobs => {
      const postprocessedBlobs = blobs.map(blob => {
        if (isImage(blob)) {
          return imageCompressor.compress(blob, compressionOptions)
        }

        return blob;
      });

      const addFileToModel = (file, blob) => {
        triggerNgModelUpdate([...modelValue(), file]);
        uploadFileToStorage(file, blob);
      };

      Promise.all(postprocessedBlobs)
        .then(files => {
          files.forEach(blob => {
            if (isImage(blob)) {
              const fileReader = new FileReader();
              fileReader.readAsDataURL(blob);
              fileReader.onload = () => {
                const base64Image = fileReader.result;
                const image = {
                  uploaded: false,
                  thumbnail: base64Image,
                  fileName: blob.name,
                };

                addFileToModel(image, blob);
              };

              return;
            }

            const file = {
              uploaded: false,
              thumbnail: fileIcon,
              fileName: blob.name,
            };

            addFileToModel(file, blob);
          });
        })
        .catch(e => {
          console.error(e);
          notification.show('Error', 'Cannot compress image');
        })
    };

    that.deleteImage = (e, image) => {
      // prevent form from being dirty
      e.stopPropagation();
      e.preventDefault();

      // uploaded flags is only used for files uploaded by this control, check prevents file used from deletion
      if (image.uploaded && image.id) {
        fileService.deleteFile(image.id)
          .success(data => {
            notification.show('Success', 'File deleted');
            triggerNgModelUpdate(modelValue().filter(file => image !== file));
          }).error(e => {
          console.error(e);
          notification.show('Error', 'Error deleting file');
        });

        return;
      }

      triggerNgModelUpdate(modelValue().filter(file => image !== file));
    };

    that.updateRemark = (e, item) => {
      fileService.updateRemarks(item.id, item.remarks, {nxLoaderSkip: true})
        .error(e => {
          notification.show('Error', 'Error updating file');
        });
    };

    that.displayFileName = item => {
      return that.showFileName &&
        //TODO: 'cameraPicture' is constant defined by file-upload-new component.
        item.fileName !== 'blob' && item.fileName !== 'cameraPicture';
    };

    that.registerShowCameraHandler = (handler) => {
      if (!that.showWebCamHandler) {
        return;
      }

      that.showWebCamHandler({
        handler: handler
      });
    };
  }
});
