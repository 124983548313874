import module from 'module';

import templateUrl from './customer-forex.template.html';

class Forex {
}

module.component('customerForex', {
  templateUrl: templateUrl,
  bindings: {
    customerId: '<',
    mainCurrencyIso: '<'
  },
  controller: Forex
});
