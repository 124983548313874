import 'rxjs/add/operator/combineAll';
import 'rxjs/add/operator/toArray';
import module from 'module';
import StateMachine from 'javascript-state-machine';

import './group-loans-create.service';
import './group-loans-create-members.component';
import './group-loans-create-form.component';
import './group-loans-create.style.less';
import templateUrl from './group-loans-create.template.html';

class GroupLoansCreate {
  constructor($route, $location, groupLoansCreateService, groupCustomerCache) {
    this.customerId = $route.current.params['customerId'];
    this.groupLoansCreateService = groupLoansCreateService;
    this.groupCustomerCache = groupCustomerCache;
    this.$location = $location;
  }

  $onInit() {
    this.members = [];
    this.membersSub = this.groupLoansCreateService.getMemberLoans(this.customerId)
      .subscribe(members => {
        this.members = members;
      });

    this.groupCustomerCache.profile(this.customerId)
      .toObservable()
      .subscribe(profile => this.groupProfile = profile);

    this.fsm = new StateMachine({
      init: 'membersSelection',
      transitions: [
        {name: 'membersSelected', from: 'membersSelection', to: 'productSelection',},
        {name: 'productSelected', from: 'productSelection', to: 'membersProductConfiguration',},
        {name: 'membersProductConfigured', from: 'membersProductConfiguration', to: 'membersSelection',}
      ]
    });

    this.loanType = 'group';
    this.productId = null;

    this.openAction = ({product, }) => {
      this.product = product;
      this.fsm.productSelected();
    };

    this.memberConfigurationFinishedAction = () => {
      this.fsm.membersProductConfigured();
    };

    this.prepareLoan = () => {
      this.fsm.membersSelected();
      this.applyingMembers = this.members.filter(member => member.applying);
    };

    this.saveApplication = ({loan, }) => {
      const loanId = loan.id;
      this.groupCustomerCache.refetch();
      this.$location.path(`/customer/${this.customerId}/group-loans/${loanId}`);
    }
  }

  $onDestroy() {
    this.membersSub.unsubscribe();
  }
}

module.component('groupLoansCreate', {
  templateUrl,
  controller: GroupLoansCreate
});
