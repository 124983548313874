import _ from 'lodash';

import module from 'module';
import reportTypeMapping from './reportTypeMapping';
const templateUrl = require('./report-list.template.html');

module.component('reportList', {
  templateUrl,
  controller: function ($scope, $routeParams, $location, breadcrumbs, branchService, http, notification) {

    const that = this;
    const reportType = _.get($routeParams, 'reportType');
    const reportTypeName = reportType.replace(/-/g, ' ');

    that.title = `List of all ${reportTypeName} reports`;

    _.set(breadcrumbs, 'options', {
      'report-list': reportTypeName,
    });

    http.get('/reports/descriptors')
      .success(reports => {
        const filteredReports = reports.filter(report => report.type === reportTypeMapping[reportType] && report.visible);
        that.reports = _.sortBy(filteredReports, report => report.name);
      })
      .error(() => {
        notification.show('Error', 'Failed to load report list');
      });

    that.openLink = report => {
      return `#!/report/${reportType}/reports/${report.code}`;
    };
  }
});
