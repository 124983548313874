import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('pawnMetalTypesCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get(`/products/pawns/metal-types`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.pawnMetalTypesConfig,
    cacheName: `pawnMetalTypesConfig`
  })
);
