import module from 'module';
import BigNumber from 'bignumber.js';

const templateUrl = require('./loan-pre-terminate.template.html');
module.component('loanPreTerminate', {
  templateUrl: templateUrl,
  bindings: {
    maxValues: '<',
    priorityAmortizationIds: '<',
    paymentRequest: '=',
    waiveRequest: '='
  },
  controller: function ($scope, $route) {
    let that = this;
    that.loanId = $route.current.params['loanId'];
    that.amortization = null;
    that.total = 0;
    that.totalWaive = 0;
    that.amortizationIds = null;
    that.totalOutstandingBalance = null;

    that.finalValues = {
      principal: 0,
      interest: 0,
      cbu: 0,
      pf: 0,
      tp: 0,
      pastDueInterest: 0,
      penalty: 0
    };

    that.waived = {
      principal: 0,
      interest: 0,
      cbu: 0,
      pf: 0,
      tp: 0,
      pastDueInterest: 0,
      penalty: 0
    };

    let bn = (number) => {
      return number ? new BigNumber(number) : new BigNumber(0)
    };

    that.buildWaiveParts = () => {
      let waiveRequests = [];
      if (that.finalValues.interest < that.maxValues.interest) {
        waiveRequests.push({
          waiveType: 'INTEREST',
          waiveAmount: that.waived.interest,
        });
      }

      if (that.finalValues.penalty < that.maxValues.penalty) {
        waiveRequests.push({
          waiveType: 'PENALTY',
          waiveAmount: that.waived.penalty,
        });
      }

      if (that.finalValues.pastDueInterest < that.maxValues.pastDueInterest) {
        waiveRequests.push({
          waiveType: 'PAST_DUE_INTEREST',
          waiveAmount: that.waived.pastDueInterest
        });
      }

      if (that.finalValues.cbu < that.maxValues.cbuCharge) {
        waiveRequests.push({
          waiveType: 'CBU',
          waiveAmount: that.waived.cbu
        });
      }

      if (that.finalValues.pf < that.maxValues.pfCharge) {
        waiveRequests.push({
          waiveType: 'PF',
          waiveAmount: that.waived.pf
        });
      }

      if (that.finalValues.tp < that.maxValues.tpCharge) {
        waiveRequests.push({
          waiveType: 'TP',
          waiveAmount: that.waived.tp
        });
      }

      return waiveRequests;
    };

    that.updateWaiveRequest = () => {
      that.waiveRequest = {
        productId: that.loanId,
        waiveParts: that.buildWaiveParts(),
        priorityAmortizationIds: that.priorityAmortizationIds
      };
    };

    that.updatePaymentRequest = () => {
      const fv = that.finalValues;

      that.paymentRequest = {
        productId: that.loanId,
        amount: that.total,

        principalAmount: fv.principal !== 0 ? fv.principal : undefined,
        interestAmount: fv.interest !== 0 ? fv.interest : undefined,
        cbuChargeAmount: fv.cbu !== 0 ? fv.cbu : undefined,
        pfChargeAmount: fv.pf !== 0 ? fv.pf : undefined,
        tpChargeAmount: fv.tp !== 0 ? fv.tp : undefined,
        pastDueInterestAmount: fv.pastDueInterest !== 0 ? fv.pastDueInterest : undefined,
        penaltyAmount: fv.penalty !== 0 ? fv.penalty : undefined,
        commandPurpose: 'LOAN_WAVE'
      };

      // update total
      if (that.maxValues) {
        that.total = parseFloat(bn(that.maxValues.principal)
          .plus(bn(fv.interest))
          .plus(bn(fv.cbu))
          .plus(bn(fv.pf))
          .plus(bn(fv.tp))
          .plus(bn(fv.pastDueInterest))
          .plus(bn(fv.penalty))
          .toFixed(2));
        that.paymentRequest.amount = that.total;
      }
    };

    that.updateWaive = () => {
      if (that.maxValues) {
        that.waived.interest = parseFloat(bn(that.maxValues.interest).minus(bn(that.finalValues.interest)).toFixed(2));
        that.waived.cbu = parseFloat(bn(that.maxValues.cbuCharge).minus(bn(that.finalValues.cbu)).toFixed(2));
        that.waived.pf = parseFloat(bn(that.maxValues.pfCharge).minus(bn(that.finalValues.pf)).toFixed(2));
        that.waived.tp = parseFloat(bn(that.maxValues.tpCharge).minus(bn(that.finalValues.tp)).toFixed(2));
        that.waived.pastDueInterest = parseFloat(bn(that.maxValues.pastDueInterest).minus(bn(that.finalValues.pastDueInterest)).toFixed(2));
        that.waived.penalty = parseFloat(bn(that.maxValues.penalty).minus(bn(that.finalValues.penalty)).toFixed(2));
        that.totalWaive = parseFloat(bn(that.waived.interest)
          .plus(bn(that.waived.cbu))
          .plus(bn(that.waived.pf))
          .plus(bn(that.waived.tp))
          .plus(bn(that.waived.pastDueInterest))
          .plus(bn(that.waived.penalty))
          .toFixed(2));

        that.updateWaiveRequest();
      }
    };

    that.$onChanges = change => {
      if (change.maxValues && change.maxValues.currentValue) {
        that.finalValues.principal = that.maxValues.principal;
        that.finalValues.interest = that.maxValues.interest;
        that.finalValues.cbu = that.maxValues.cbuCharge;
        that.finalValues.pf = that.maxValues.pfCharge;
        that.finalValues.tp = that.maxValues.tpCharge;
        that.finalValues.pastDueInterest = that.maxValues.pastDueInterest;
        that.finalValues.penalty = that.maxValues.penalty;

        that.totalOutstandingBalance = parseFloat(bn(that.maxValues.principal)
          .plus(bn(that.maxValues.interest))
          .plus(bn(that.maxValues.cbuCharge))
          .plus(bn(that.maxValues.pfCharge))
          .plus(bn(that.maxValues.tpCharge))
          .plus(bn(that.maxValues.pastDueInterest))
          .plus(bn(that.maxValues.penalty))
          .toFixed(2));
      }

      that.updatePaymentRequest();
    };

    $scope.$watch('$ctrl.finalValues.interest', () => {
      that.updatePaymentRequest();
      that.updateWaive();
    });
    $scope.$watch('$ctrl.finalValues.cbu', () => {
      that.updatePaymentRequest();
      that.updateWaive();
    });
    $scope.$watch('$ctrl.finalValues.pf', () => {
      that.updatePaymentRequest();
      that.updateWaive();
    });
    $scope.$watch('$ctrl.finalValues.tp', () => {
      that.updatePaymentRequest();
      that.updateWaive();
    });
    $scope.$watch('$ctrl.finalValues.pastDueInterest', () => {
      that.updatePaymentRequest();
      that.updateWaive();
    });
    $scope.$watch('$ctrl.finalValues.penalty', () => {
      that.updatePaymentRequest();
      that.updateWaive();
    });
  }
});
