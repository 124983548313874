import module from 'module';

module.factory('confirmationTemplate', function (confirmation) {
  return function ({question,
                     details,
                     warning,
                     /** @deprecated function returns promise, use it */ yesCallback,
                     /** @deprecated function returns promise, use it */ noCallback}) {
    let detailsHTML = '';
    if (details) {
      detailsHTML = '<br><br>';
      for (let d of details) {
        detailsHTML += `<strong>${d.label}</strong>: ${d.description}<br>`
      }
    }

    let warningHTML = warning ?
      `<br>
       <span class="red">
         <strong>WARNING</strong>: ${warning}
       </span>` : '';

    return confirmation(
      `<strong>${question}</strong>
       ${detailsHTML}
       ${warningHTML}`,
      yesCallback,
      noCallback,
      true);
  }
});
