import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import _ from 'lodash';
import module from 'module';

import './report-approvals-filters.less';

const templateUrl = require('./report-approvals-filters.template.html');
class ReportApprovalsFilters {
  constructor($route, $location, postingDateService, userCache, reportModal, authentication) {
    this.loanId = $route.current.params['loanId'];
    this.customerId = $route.current.params['customerId'];
    this.users = undefined;
    this.allUsers = undefined;
    this.$location = $location;
    this.postingDateService = postingDateService;
    this.userCache = userCache;
    this.authentication = authentication;
  }

  $onInit() {
    this.postingDateService.getCurrentUserBranchPostingDate().then(date => this.date = date.clone().format('YYYY-MM-DD'));

    this.usersSub = this.userCache.toObservable().subscribe(users => {
      this.allUsers = users.map(user => {
        Object.assign(user, {fullName: `${user.lastName}, ${user.firstName} ${user.middleName ? user.middleName : ''}`});
        return user;
      });
      this.users = _.filter(this.allUsers, {'branchId': this.authentication.context.branchId});
    });
  }

  close(){
    this.$location.path(`/customer/${this.customerId}/loans/${this.loanId}`);
  };

  $onDestroy () {
    this.usersSub.unsubscribe();
  };
}

module.component('reportApprovalsFilters', {
  templateUrl: templateUrl,
  bindings: {
    'hideDate': '<',
    'approvedByName': '=',
    'checkedByName': '=',
    'displayReport': '<'
  },
  controller: ReportApprovalsFilters
});
