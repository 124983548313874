import module from 'module';
import templateUrl from './user-attendance.template.html';

class UserAttendanceComponent {
  constructor($scope, $routeParams, $location, $filter, $route, http, reportService, fileService, popup, notification, authentication) {
    this.http = http;
    this.reportService = reportService;
    this.fileService = fileService;
    this.popup = popup;
    this.notification = notification;
    this.authentication = authentication;

    this.params = {};
    this.selectedColumns = [];
    this.attendances=[];

    this.reportCode = 'UserAttendanceReport';

    this.filterConfig = {
      reportCode: this.reportCode,
      buttons: {
        filter: {
          isVisible: true,
          isDisabled: false,
          action: () => this.view(),
          text: 'View'
        }, download: {
          isVisible: true,
          isDisabled: false,
          action: () => this.downloadXls(),
          text: 'Download xls'
        }, print: {
          isVisible: true,
          isDisabled: false,
          action: () => window.print(),
          text: 'Print'
        }
      }
    };
  }

  async view() {
    let selectedBranchIds = this.params.branchId.filter(item => item);
    if(selectedBranchIds.length === 0){
      selectedBranchIds = this.authentication.context.branchIds;
    }

    const criteria = {
      dateFrom: this.params.date_range.from,
      dateTo: this.params.date_range.to,
      branchIds: selectedBranchIds,
      userIds: this.params.userId.filter(item => item),
      attendanceStatuses: this.params.attendanceStatus.filter(item => item)
    };

    //remove null array entry when the filter value is select all
    this.selectedColumns = this.params["columns_array[]"].filter(item => item);

    try {
      this.attendances = await this.http.post(`/attendance`, criteria, {nxLoaderText: 'Loading user attendances'}).toPromise();
    } catch (error) {
      this.popup({
        header: 'Error',
        text: error.errorMessage
      });
    }
  }

  downloadXls() {
    this.reportService.downloadXls({
      reportCode: this.reportCode,
      params: this.params,
      reportName: 'User_Attendance_Report.xlsx'
    });
  }

  async showFilePreview(fileId) {
    try {
      const file = await this.fileService.downloadFile(fileId, false, false).toPromise();
      const fileMetadata = await this.fileService.getMetadata(fileId).toPromise();
      this.filePreviewRemarks = fileMetadata.remarks;
      this.showPopup = true;
      this.filePreview = window.URL.createObjectURL(file);
    } catch (error) {
      this.notification.show("Error", "Could not load attendance file");
      this.showPopup = false;
      console.error(error);
    }
  }

  $onDestroy(){
    if (this.filePreview) {
      window.URL.revokeObjectURL(this.filePreview);
    }
  }
}

module.component("userAttendance", {
  templateUrl,
  controller: UserAttendanceComponent
})
