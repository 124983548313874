import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('currencyCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/currencies'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.currency,
    cacheName: 'currency'
  })
);
