import module from 'module';

import templateUrl from './stock-items-view.template.html';

class StockItemsView {
  // Empty
}

module.component('stockItemsView', {
  templateUrl: templateUrl,
  bindings: {
    checkedMode: '=',
    checkedItems: '='
  },
  controller: StockItemsView
});
