import module from "module";
import _ from "lodash";

const templateUrl = require('./pos-cash-withdrawal.template.html');
module.component('posCashWithdrawal', {
  templateUrl: templateUrl,
  controller: function ($filter, $routeParams, $timeout, authentication, actionCommand, branchService) {

    const that = this;
    that.branch = null;
    that.transaction = {};

    const sub = branchService.toObservable().subscribe(branches => {
      that.branch = _.find(branches, {id: authentication.context.branchId});
    });

    that.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    that.denominationIsValid = () => {
      return !that.useDenomination || that.transaction.denominationValid || that.transaction.denominationValid == null;
    };

    that.save = () => {
      actionCommand.execute('POS_CASH_WITHDRAWAL', that.transaction);
    };

    that.$onDestroy = () => sub.unsubscribe();
  }
});


