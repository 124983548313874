import module from 'module';

module.directive('validateDate', function () {
  return {
    restrict: 'A',
    require: '?ngModel',
    link: function (scope, elem, attr, ngModel) {
      if (!ngModel) return;

      scope.$watch(attr.ngModel, function () {
        validate();
      });

      attr.$observe('validateDate', function (val) {
        validate();
      });

      let validate = function () {

        let valid = false;

        // If selected date is null -> consider input as valid
        // 'Required' should be added to input to perform not null check
        let selectedDate = ngModel.$viewValue;
        if (!selectedDate) {
          valid = true;
        } else {
          const currentDate = new Date();
          const pastDate = new Date(selectedDate);

          let expectedDate = attr.validateDate;

          if (expectedDate === 'past-date-valid') {
            valid = currentDate > pastDate;
          } else if (expectedDate === 'future-date-valid') {
            valid = currentDate < pastDate;
          } else if (expectedDate === 'future-or-now-date-without-time') {
            currentDate.setHours(0, 0, 0, 0);
            pastDate.setHours(0, 0, 0, 0);
            valid = currentDate <= pastDate;
          }
        }

        ngModel.$setValidity('validateDate', valid);
      };
    }
  }
});
