import module from "module";
import {addAccountLabels} from "../../general-ledger/common/gl.utils";

const templateUrl = require('./batch-sss-pension-upload.template.html');
module.component('batchSssPensionUpload', {
  templateUrl: templateUrl,
  bindings: {
    'fileId': '=',
    'onSubmit': '<'
  },
  controller: function (glMappingsService, command) {
    const that = this;

    // GL accounts select config
    that.selectConfig = {
      placeholder: 'Select account',
      searchField: 'label',
      valueField: 'fullCode',
      labelField: 'label',
      maxItems: 1
    };

    that.files = null;
    that.command = {
      fileId: null,
      accountCode: null,
      createAnniversaryHold: true,
      stopOnError: false
    };

    const s1 = glMappingsService.accounts.toObservable().subscribe(glAccounts => {
      that.glAccounts = addAccountLabels(
        _.filter(glAccounts, function (glAccount) {
          return ['ASSET', 'LIABILITY'].includes(glAccount.accountGroup);
        })
      );
    });

    that.submit = () => {
      that.command.fileId = (!that.files || that.files.length < 1) ? null : that.files[0].id;
      command.execute('SSSPensionFileUpload', that.command).success((res) => {
        that.fileId = res.output.file.id;
        that.onSubmit(that.fileId);
      });
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
    }
  }
});
