import InspireTree from 'inspire-tree';
import InspireTreeDOM from 'inspire-tree-dom';
import 'inspire-tree-dom/dist/inspire-tree-light.min.css';

import module from 'module';
import _ from 'lodash';

import './ent.style.less';
import templateUrl from './ent.template.html';

class Ent {
  constructor($element, $scope) {
    this.tree = new InspireTree({
      selection: {
        disableDirectDeselection: true,
      },
    });

    this.$element = $element;
    this.treeVisible = false;
    this.selectedNode = null; // has node, not value of ngModel
    this.treeVisible = false;
    this.$scope = $scope;
  }

  normalizedTreeModel() {
    const prepareTreeNode = sourceNode => ({
      id: sourceNode.id,
      text: this.nodeLabel ? this.nodeLabel(sourceNode) : sourceNode[this.colDefs[0].field],
      children: (sourceNode.children || []).map(prepareTreeNode),
      originalData: sourceNode,
      itree: {
        state: {
          selectable: this.selectOnlyLeaves ? (sourceNode.children || []).length === 0 : true,
        }
      }
    });

    return Object.values(this.treeData || []).map(prepareTreeNode);
  }

  changeValue() {
    this.treeVisible = !this.treeVisible;
    if(this.treeVisible) {
      this.selectModelValue();
    }
  }

  onSearchChanged() {
    if(this.searchInput) {
      this.tree.search(this.searchInput);
      return;
    }

    this.tree.clearSearch();
  }

  modelValue() {
    return this.ngModel.$modelValue;
  }

  selectModelValue() {
    const externalNode = this.modelValue();
    if(externalNode) {
      const node = this.tree.node(externalNode.id);
      const selectionEvents = ['node.selected', 'node.deselected'];
      this.tree.mute(selectionEvents);

      node.expandParents();
      node.select();

      this.selectedNode = node;

      this.tree.mute([]);
    }
  }

  triggerNgModelUpdate(newNode) {
    this.ngModel.$setTouched();
    this.ngModel.$setViewValue(newNode ? newNode.originalData : null);
    this.selectedNode = newNode;
  };

  $onChanges(changes) {
    const previousTreeData = _.get(changes, 'treeData.previousValue', {});
    const currentTreeData = _.get(changes, 'treeData.currentValue', {});
    // here I'm trying to defer initialization of  tree as it's rendering engine is asynchronous
    if(changes.treeData.isFirstChange() && !_.isEmpty(currentTreeData)) {
      return;
    }

    // I'm explicitly checking for emptiness as two instances of empty objects
    // using different constructors may not be equal
    if(_.isEmpty(previousTreeData) && _.isEmpty(currentTreeData)) {
      return true;
    }

    if(!_.isEqual(previousTreeData, currentTreeData)) {
      this.tree.load(this.normalizedTreeModel());
    }
  }

  $postLink() {
    this.$element.click(_evt => this.ngModel.$setTouched());

    this.ngModel.$render = () => {
      const externalValue = this.modelValue();
      if(externalValue) {
        this.selectedNode = this.tree.node(externalValue.id);
        return;
      }

      this.selectedNode = null;
    };

    const root = this.$element.find('.ent__tree-element');
    new InspireTreeDOM(this.tree, {
      target: root.get(0),
    });

    this.tree.on('node.selected', (node, _isLoadEvent) => {
      this.$scope.$apply(() => {
        this.triggerNgModelUpdate(node);
        this.treeVisible = false;
        this.searchInput = "";
        this.tree.clearSearch();
      });
    });

    this.tree.on('model.loaded', _nodes => {
      this.selectModelValue();
    });

    if(this.treeData) {
      this.tree.load(this.normalizedTreeModel());
    }
  }
}


module.component('ent', {
  templateUrl,
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    treeData: '<', // [{id: 'a', children: []}, {id: 'b', children: []}]
    nodeLabel: '<', // to specify label for a tree node, pass there a function. It will receive node from treeData as an argument.
    selectOnlyLeaves: '<',
    search: '<', // enables search, by default disabled
  },
  controller: Ent
});
