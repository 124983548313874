import module from 'module';

import templateUrl from './layaway-installments.template.html';

class LayawayInstallmentsComponent {}

module.component('layawayInstallments', {
  templateUrl: templateUrl,
  bindings: {
    layaway: '<'
  },
  controller: LayawayInstallmentsComponent
});