import module from 'module';

import templateUrl from './stock-items-inventory-view.template.html';

export const MODE = {
  SEND_FOR_DISPLAY: {
    name: 'Send for display',
    command: 'SendPawnStockItemForDisplay',
    generatePrompt: (items) => `Do you want to send ${items.length} items for display?`,
    generateInput: (items, branchId, headOfficeId) => ({
      branchId: branchId,
      itemIds: items.map(item => item.id)
    })
  },
  SEND_TO_HO: {
    name: 'Send to head office',
    command: 'SendPawnStockItemToHeadOffice',
    generatePrompt: (items) => `Do you want to send ${items.length} items to Head Office?`,
    generateInput: (items, branchId, headOfficeId) => ({
      targetBranchId: headOfficeId,
      itemIds: items.map(item => item.id)
    })
  },
  SELL: {
    name: 'Sell stock items',
    command: 'SellStockItems'
    /* functions ignored, go to proper view */
  },
  LAYAWAY: {
    name: 'Create layaway',
    command: 'CreateLayaway'
    /* functions ignored, go to proper view */
  },
  REPRICE_STOCKED_ITEMS: {
    name: 'Reprice stock items',
    command: 'RepriceStockedItems',
    generatePrompt: (items) => `Do you want to reprice ${items.length} items?`
  }
};

class StockItemsInventoryView {
  constructor() {
    this.MODE = MODE;
    this.checkedMode = null;
    this.checkedItems = [];
  }

  isModeSelected(modes) {
    return this.checkedMode && modes.some(m => this.checkedMode.name === m.name);
  }
}

module.component('stockItemsInventoryView', {
  templateUrl: templateUrl,
  controller: StockItemsInventoryView
});
