import module from 'module';
import LocalCache from 'shared/util/localCache';
import MemoryFactory from 'shared/util/localCacheMemoryFactory'
import AccessType from "constants/customerAccessType";

module.factory('groupCustomerCache', function (http, activeCustomerService, productCacheService) {
  let that = this;
  let service = {};

  service.loadedCustomerId = null;

  that.cacheMap = {};

  service.profile = (customerId) =>
    retrieveCacheObject(customerId, 'profile', {
      provider: () => http.get(`/customers/${customerId}`)
        .success(profile => {
          if (profile.customerType !== 'GROUP')
            console.error(`ERROR: loaded non-group customer. customerId: ${customerId}`);
        })
    });

  service.individualLoans = (customerId) =>
    retrieveCacheObject(customerId, 'customerIndividualLoan', {
      provider: () => http.get(`/products/loans/group-customer/${customerId}`)
    });

  service.groupLoans = (customerId) =>
    retrieveCacheObject(customerId, 'customerGroupLoan', {
      provider: () => http.get(`/products/loans/group-loan/customer/${customerId}`)
    });

  /**
   * fullAccess equals false means that stream of data from cache will contain
   * only products that current user has access to.
   */
  service.batch = (customerId, includeAmortization, fullAccess=true) => {
    let url = `/products/loans/batch/${customerId}`;
    url = includeAmortization ? url + `?includeAmortization=true` : url;
    const cache = retrieveCacheObject(customerId, 'customerBatch', {
      provider: () => http.get(url)
    });

    if(fullAccess) {
      return cache;
    }

    return productCacheService.withRestrictedAccess(cache, customerId, AccessType.GROUP,
      (response, branchId) => {
        return response.filter(batch => batch.loans.every(loan => loan.branchId === branchId))
      });
  };


  // --------- technical functions -------------
  function retrieveCacheObject(customerId, cacheName, cacheOptions) {
    verifyCustomerId(customerId);

    // adds default params
    cacheOptions['cacheFactory'] = MemoryFactory;
    cacheOptions['cacheName'] = cacheName;

    if (!that.cacheMap[cacheName]) {
      that.cacheMap[cacheName] = new LocalCache(cacheOptions);
    }
    return that.cacheMap[cacheName];
  }

  service.refetch = () => {
    let keys = Object.keys(that.cacheMap);
    for (let key of keys) {
      console.log('Refetching group customer data: ' + key);
      that.cacheMap[key].refetch();
    }
  };

  service.evict = () => {
    service.loadedCustomerId = null;
    activeCustomerService.ofType(AccessType.GROUP).updateCustomerId(null);
    that.cacheMap = {};
  };

  service.evictWithoutCustomerId = () => {
    that.cacheMap = {};
  };

  service.unloadCustomer = () => {
    service.loadedCustomerId = null;
    activeCustomerService.ofType(AccessType.GROUP).updateCustomerId(null);
    service.evict();
  };

  function verifyCustomerId(newCustomerId) {
    if (service.loadedCustomerId && String(service.loadedCustomerId) !== String(newCustomerId)) {
      that.cacheMap = {};
    }
    service.loadedCustomerId = newCustomerId;
    activeCustomerService.ofType(AccessType.GROUP).updateCustomerId(newCustomerId);
  }

  return service;
});
