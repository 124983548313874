import module from 'module';
import _ from 'lodash';

const templateUrl = require('./new-term-deposit-list.template.html');
module.component('customerTermDepositCreateList', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, customerCache, termDepositsService, interestBoardService) {

    const that = this;
    const customerId = $route.current.params['customerId'];

    const s1 = termDepositsService.toObservable().subscribe(data => {
      that.termDeposits = data ? data : [];
      if (that.termDeposits.length > 0) {
        _.forEach(that.termDeposits, d => {
          d.interestRange = interestBoardService.formatInterestRateRangeForRateType(d.interestBoards);
        })
      }
    });

    that.open = (termDepositProduct) => {
      $location.path(`/customer/${customerId}/term-deposits/create/${termDepositProduct.id}`);
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
    }
  }
});