import module from "../../module";

module.factory('httpLoadingInterceptor', (loader, $q) => {
  return {
    'request': (config) => {
      if (config.nxLoaderSkip) {
        return config;
      }

      const loaderId = loader.show(config.nxLoaderText);
      config.nxLoaderId = loaderId;
      return config;
    },
    'responseError': error => {
      if (error.config && !error.config.nxLoaderSkip) {
        loader.dismiss(error.config.nxLoaderId);
      }

      return $q.reject(error);
    },
    'response': response => {
      if (!response.config.nxLoaderSkip) {
        loader.dismiss(response.config.nxLoaderId);
      }

      return response;
    }
  }
});
