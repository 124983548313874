import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('pawnTagCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/pawn-tags'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.pawnTag,
    cacheName: 'pawnTags'
  })
);
