import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('branchService', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/management/branches', {nxLoaderSkip: true}),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.branches,
    cacheName: 'branches'
  }));
