import module from 'module';
import ParameterizedLocalCache from 'shared/util/parameterized-local-cache';

module.factory('ataCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (accountId) => http.get(`/products/accounts/${accountId}/ata`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.ata,
    cacheName: 'ataCache'
  })
);
