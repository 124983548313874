import module from 'module';

const templateUrl = require('./form-section.template.html');

module.component('formSection', {
	templateUrl,
	transclude: true,
	bindings: {
	  formTitle: '<'
	}
});
