import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('forexDocumentTypeCache', (http, CacheFactory, config) =>
    new LocalCache({
        provider: () => http.get('/forex/document-types'),
        cacheFactory: CacheFactory,
        cacheTime: config.cache.forexDocumentTypes,
        cacheName: 'forexDocumentTypeCache'
    })
);
