import _ from 'lodash';
import $ from 'jquery';

import module from 'module';

const templateUrl = require('./view-profile.template.html');
module.component('dashboardActionViewProfile', {
  templateUrl: templateUrl,
  controller: function ($scope, $routeParams, http, $route, dict, customerCache, customerService,
                        profileUpdateService, dashboardActionService, availableTasksCache,
                        $location, breadcrumbs, systemPropertyCache, watchlistService) {
    const that = this;

    that.singlePage = null;
    that.corporateCustomerEnabled = null;

    const systemPropertiesSub = systemPropertyCache.toObservable().subscribe(properties => {
      const singlePageProp = _.find(properties, {code: 'CIF_SINGLE_PAGE'});
      that.singlePage = singlePageProp && singlePageProp.value === 'TRUE';
      const corpoProp = _.find(properties, {code: 'CIF_CORPORATE_SUPPORT'});
      that.corporateCustomerEnabled = corpoProp && corpoProp.value === 'TRUE';
    });

    that.displayMode = $routeParams.mode;
    that.objectId = $routeParams.object;
    that.taskId = $routeParams.action;
    $scope.profile = {};
    $scope.dict = dict;
    $scope.editMode = false;
    that.watchlistVerification = watchlistService.emptyVerification();

    http.get(`/tasks/${that.taskId}`).success((task => that.task = task));

    $scope.clonedCustomerProfileModel = null;
    http.get(`/customers/${that.objectId}?ignoreInactive=false`)
      .success(profile => $scope.profile = customerService.extractPhones(profile));

    $scope.enableEditMode = function () {
      $scope.editMode = true;
      $scope.clonedCustomerProfileModel = $.extend(true, {}, $scope.profile);
    };

    $scope.disableEditMode = function () {
      $scope.editMode = false;
    };

    $scope.cancelEdit = function () {
      $scope.profile = $scope.clonedCustomerProfileModel;
      $scope.disableEditMode();
    };

    function clearFormDirty() {
      [that.individualDataForm, that.corporateDataForm, that.documentsForm, that.addressesForm, that.incomeForm]
        .forEach(form => {
          if (form) form.$setPristine();
        })
    }

    that.resetWatchlistVerification = () => {
      that.watchlistVerification = {
        completed: false
      }
    }

    that.verifyWatchlist = async (input) => {
      that.watchlistVerification = await watchlistService.verify(input);
    }

    $scope.confirmChanges = function () {
      const command = profileUpdateService.update($scope.profile, $scope.clonedCustomerProfileModel, that.watchlistVerification);
      if (command) {
        command.success(() => {
          customerCache.refetch();
          $scope.disableEditMode();
        });
      } else {
        // no changes were made
        $scope.disableEditMode();
      }
    };

    let constructor = () => {
      $scope.disableEditMode();
    };

    constructor();

    const onUpdatedAction = () => {
      availableTasksCache.refetch();
      const parentPath = _.nth(breadcrumbs.get(), -2).path;
      $location.path(parentPath);
    };

    that.revoke = () => dashboardActionService.cancel({id: that.taskId}, null, onUpdatedAction);

    that.pinEntered = (task, pin, user) => dashboardActionService.pinEntered({id: task.id}, pin, user, onUpdatedAction);

    that.localOverride = () => {
      that.overrideTask = that.task;
    };

    that.reject = () => dashboardActionService.reject({id: that.taskId}, null, onUpdatedAction);

    that.approve = () => dashboardActionService.approve({id: that.taskId}, null, onUpdatedAction);

    that.$onDestroy = () => {
      systemPropertiesSub.unsubscribe();
      availableTasksCache.unsubscribe();
    }
  }
});
