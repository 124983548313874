import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import module from 'module';

const templateUrl = require('./customer-loans-assign-group.template.html');
module.component('customerLoansAssignGroup', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, customerCache, groupCustomerCache, command, confirmation) {
    let that = this;
    let customerId = $route.current.params['customerId'];
    let loanId = $route.current.params['loanId'];

    that.request = {loanId};

    http.get(`/products/loans/group-customer/matching?customerIds=${customerId}`)
      .success(groupCustomers => that.groupCustomers = groupCustomers);

    that.redirectBack = () => $location.path(`/customer/${customerId}/loans/${loanId}`);

    that.assign = () => confirmation('Do you want to assign the loan to the new group?', () =>
      command.execute('AssignLoanToGroup', that.request)
        .success(() => {
          groupCustomerCache.evict();
          customerCache.loans(customerId).refetch();
          that.redirectBack();
        })
    );
  }
});