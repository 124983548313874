import module from 'module';

const templateUrl = require('./loan-product-advanced.template.html');
module.component('loanProductAdvancedForm', {
  templateUrl,
  bindings: {
    loanProduct: '=',
    form: '='
  },
  controller: function ($scope, $timeout, dict) {
    let that = this;
    that.dict = dict;

    dict.onLoadingComplete(() => that.loanSecurityClean = _.find(that.dict['LOAN_SECURITY'], {code: 'CLN'}));

    $scope.$watch('$ctrl.loanProduct.withCollateral', () => {
      that.loanSecurityOptions = that.dict['LOAN_SECURITY'];
      if (that.loanProduct.withCollateral === false) {
        that.loanSecurityOptions = that.dict['LOAN_SECURITY'].filter(loanSec => loanSec.code === 'CLN');
      } else if (that.loanProduct.withCollateral === true) {
        that.loanSecurityOptions = that.dict['LOAN_SECURITY'].filter(loanSec => loanSec.code !== 'CLN');
      }
    });

    that.creationTypes = [{
      label: 'New Loan',
      value: 'NEW_LOAN'
    }, {
      label: 'Reloan',
      value: 'RELOAN'
    }, {
      label: 'Restructured',
      value: 'RESTRUCTURED'
    }];

  }
});