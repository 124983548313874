import BigNumber from 'bignumber.js';
import module from 'module';

const templateUrl = require('./edit-loan-amortization.template.html');

module.component('customerEditLoanAmortization', {
  templateUrl: templateUrl,
  bindings: {
    formAmortization: '=',
    sumsValid: '=',
    loan: '='
  },
  controller: function ($scope) {
    let that = this;

    //status options for amortization
    that.statusOptions = ['PAID', 'UNPAID', 'DUE', 'OVERDUE'];

    $scope.$watch('$ctrl.loan', () => {
      if (that.loan && that.loan.amortizationSchedule) {
        that.formAmortization.$setSubmitted();
        that.calculatePrincipalTotal();
      }
    });

    that.calculateAmount = (item) => {
      item.amortizationAmount = bn(item.principalAmount)
        .plus(bn(item.interestAmount))
        .plus(bn(item.penaltyAmount))
        .plus(bn(item.pastDueInterestAmount))
        .plus(bn(item.cbuChargeAmount))
        .plus(bn(item.pfChargeAmount))
        .plus(bn(item.tpChargeAmount))
        .toNumber();
    };

    that.calculateBalance = (item) => {
      item.amortizationBalance = bn(item.principalBalance)
        .plus(bn(item.interestBalance))
        .plus(bn(item.penaltyBalance))
        .plus(bn(item.pastDueInterestBalance))
        .plus(bn(item.cbuChargeBalance))
        .plus(bn(item.pfChargeBalance))
        .plus(bn(item.tpChargeBalance))
        .toNumber();
    };

    that.calculatePrincipalTotal = () => {
      let balance = new BigNumber(0);
      let amount = new BigNumber(0);
      for (let a of that.loan.amortizationSchedule.list) {
        balance = balance.plus(a.principalBalance);
        amount = amount.plus(a.principalAmount);
      }

      that.totalPrincipalAmount = amount.toNumber();
      that.totalPrincipalBalance = balance.toNumber();
    };


    function bn(number) {
      return number ? new BigNumber(number) : new BigNumber(0)
    }
  }
});
