import module from 'module';
import _ from 'lodash';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import 'rxjs/add/operator/combineLatest';

import './check-termination.style.less'
const templateUrl = require('./check-termination.template.html');
module.component('customerTermDepositCheckTermination', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, command, http, customerCache, productDefinitionService, confirmation,
                        notification, depositoryAccountCache, branchService, authentication, modalPrintPreviewService,
                        feeDefinitionsCache, casaProductPrintService) {
    let that = this;
    that.deposit = {};
    that.banks = {};

    // Checking account config
    that.selectConfig = {
      placeholder: 'Select account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };

    // Form params
    that.checkNo = '';
    that.micrNumber = '';
    that.depositoryAccountId = null;
    that.validFrom = null;
    that.payee = null;
    that.remarks = null;

    let customerId = $route.current.params['customerId'];
    let depositId = ~~$route.current.params['depositId'];

    // Interest forecast
    that.interestForecast = {};
    that.terminationAmount = null;

    that.onMicrNumberChange = (micrNumber) => {
      if (micrNumber && micrNumber.length === 31) {
        that.checkNo = micrNumber.substr(0, 10);
      } else {
        that.checkNo = null;
      }
    };

    const branchSub = branchService.toObservable().subscribe(branches => {
      const branchId = authentication.context.branchId;
      const authBranch = _.find(branches, {id: branchId});
      if (authBranch) {
        const systemDate = moment(authBranch.postingDate).toDate();
        that.systemDate = systemDate;
        that.validFrom = systemDate;
      }
    });

    const depositoryAccountSub = depositoryAccountCache.toObservable().subscribe(depositoryAccounts => {
      that.checkingAccounts = _.filter(depositoryAccounts, {accountType: 'CHECKING'});
    });

    customerCache.termDeposits(customerId).toObservable()
      .map(deposits => _.find(deposits, {id: depositId}))
      .combineLatest(productDefinitionService.toObservable(), (deposit, products) => {
        return Object.assign(deposit, {
          "productDefinition": _.find(products, {id: deposit.definitionId})
        });
      })
      .combineLatest(customerCache.profile(customerId).toObservable(), (depositAccounts, customer) => {
        that.payee = customer.effectiveName;
        return depositAccounts;
      })
      .subscribe(deposit => {
        that.termDeposit = deposit;
        that.interestForecast = that.readInterestForecast(deposit.id);
        that.remarks = 'Termination of deposit no. ' + that.termDeposit.productNumber;
      });

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${depositId}`);

    // Fetch term deposit interest forecast
    that.readInterestForecast = (depositId) => {
      Promise.all([http.post(`/products/deposits/${depositId}/interest`, {mode: 'TERMINATION'}).toPromise(),
                  feeDefinitionsCache.toPromise()])
        .then(([forecast, feesDef]) => {
          //decorate fee with name
          forecast.terminationFees.forEach(f => {
            let fd = feesDef.find(fd => fd.id === f.feeDefinitionId);
            if (fd) f.name = fd.feeName;
          });

          that.interestForecast = forecast;
          let terminationAmount = new BigNumber(0)
            .plus(that.termDeposit.balance)
            .plus(forecast.netInterest);
          //subtract termination fee
          forecast.terminationFees.forEach(f => {
            terminationAmount = terminationAmount.sub(f.amount);
          });

          that.terminationAmount = terminationAmount.round(2).toNumber();
        })
        .catch(() => {notification.show("Error", "Failed to load interest forecast.")});
    };

    that.toggleTerminationFeeForEdit = (fee) => {
      if (fee.override) {
        fee.newAmount = fee.amount;
      }
    };

    that.calculateTerminationAmount = () => {
      let terminationAmount = new BigNumber(0)
        .plus(that.termDeposit.balance)
        .plus(that.interestForecast.netInterest);
      //subtract termination fee
      that.interestForecast.terminationFees.forEach(f => {
        const amount = f.override ? f.newAmount : f.amount;
        terminationAmount = terminationAmount.sub(amount);
      });

      return terminationAmount.round(2).toNumber();
    }

    that.recalculateForecast = () => {
      that.terminationAmount = that.calculateTerminationAmount();
    };

    that.terminationFeeChanged = () => {
      that.terminationAmount = that.calculateTerminationAmount();
    };

    that.terminate = () => {
      const bankId = _.find(that.checkingAccounts, {id: Number(that.depositoryAccountId)}).bankId;
      confirmation(`Do you want to withdraw ${$filter('php') (that.terminationAmount)} and terminate deposit?`, () => {
        const terminationFees = that.interestForecast.terminationFees.map(f => {
          let fee = Object.assign({}, f);
          if (fee.override) {
            fee.amount = fee.newAmount;
          }
          fee.newAmount = null;
          fee.override = null;
          return fee;
        });
        command.execute('CheckDepositPretermination', {
          productId: that.termDeposit.id,
          amount: that.terminationAmount,
          entryType: "DEBIT",
          validFrom: that.validFrom,
          bankId: bankId,
          depositoryAccountId: that.depositoryAccountId,
          micrNumber: that.micrNumber,
          checkNumber: that.checkNo,
          payee: that.payee,
          terminationFeesOverride: terminationFees,
          remarks: that.remarks
        }, {nxLoaderText: 'Withdrawing funds...'}).success((res) => {
          casaProductPrintService.printFor('TERM_DEPOSIT', customerId, that.termDeposit.id, res.output.id).finally(() => {
            customerCache.termDeposits(customerId).refetch();
            that.redirectBack();
          });
        });
      });
    };

    that.$onDestroy = () => {
      depositoryAccountSub.unsubscribe();
      branchSub.unsubscribe();
    };
  }
});
