import module from 'module'
import {productAvailability} from 'constants/productDefinition';

import templateUrl from './product-availability.template.html'

class ProductAvailability {
  constructor() {
    this.productAvailabilityModes = productAvailability;

    this.selectizeConfig = {
      maxItems: null,
      valueField: 'id',
      labelField: 'name',
      searchField: ['name'],
      sortField: 'name',
      plugins: ['remove_button']
    };
  }
}

module.component('productAvailability', {
  templateUrl,
  bindings: {
    branchesToSelect: '<',
    productAvailability: '=',
    availableInBranchIds: '='
  },
  controller: ProductAvailability
});