import module from 'module';

import templateUrl from './force-profile-update.template.html';
import './force-profile-update.style.less';

class ForceProfileUpdateView {
  constructor(http, nav, confirmation, dict, command, riskAssessmentEntryCache) {
    this.http = http;
    this.nav = nav;
    this.confirmation = confirmation;
    this.dict = dict;
    this.command = command;
    this.riskAssessmentEntryCache = riskAssessmentEntryCache
  }

  async $onInit() {
    this.entries = await this.riskAssessmentEntryCache.toPromise();
  }

  getLabel(entry) {
    return `Force customer profile update for ${this.dict.getDescription('CUSTOMER_RISK_LEVEL', entry.riskDictionaryEntryId)} risk customers every:`;
  }

  goBack() {
    this.nav.back();
  }

  async save() {
    const confirmation = await this.confirmation('Do you want to update it?');
    if (!confirmation) {
      return;
    }

    await this.command.execute('UpdateRiskAssessments', {entries: this.entries}).toPromise();
    this.goBack();
  }
}

module.component('forceProfileUpdateView', {
  templateUrl,
  controller: ForceProfileUpdateView
});
