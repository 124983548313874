import module from 'module';

class GlAccountService {
  constructor(http) {
    this.http = http;
  }

  fetchAccounts(id, params = {leafOnly: false}) {
    return this.http.get(`/ledger/${id}/accounts`, {
      params,
      nxLoaderText: 'Loading accounts'
    });
  }
}

module.factory('glAccountService', (http) =>
  new GlAccountService(http)
);