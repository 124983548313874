import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('ledgerActionTemplateCache', (http, CacheFactory, config) =>
  new LocalCache({
      provider: () => http.get(`/ledger/actions/templates`),
      cacheFactory: CacheFactory,
      cacheTime: config.cache.ledgerActionTemplate,
      cacheName: `ledgerActionTemplate`
    }
  )
);
