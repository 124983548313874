import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import {Subscription} from 'rxjs/Subscription';
import _ from 'lodash';
import {enrichOwnersWithRelatives, isCorporate} from '../common/casa-product-util';


import module from 'module';
import './cash-amount-picker/cash-amount-picker.component'
import './cash-deposit/cash-deposit.component'
import './cash-withdraw/cash-withdraw.component'
import './check-deposit/check-deposit.component'
import './check-withdrawal/check-withdrawal.component'
import './activate-account/activate-account.component'
import './close-account/close-account-cash/close-account-cash.component'
import './close-account/close-account-check/close-account-check.component'
import './memo/memo.component'
import './new-account/new-account-list.component'
import './new-account/new-account-details.component'
import './stop-order/stop-order.component'
import './credit-on-us-check/credit-on-us-check.component'
import './edit-account/edit-account.component'
import './edit-ata/edit-account-ata.component'
import './issue-checkbook/issue-checkbook.component'
import './common-operations/account-operation-service'
import './encash/encash-on-us-check.component'
import './create-hold/create-account-hold.component'
import '../common/create-lock/create-lock.component'

const templateUrl = require('./accounts.template.html');
module.component('customerAccounts', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $scope, http, customerCache, productLockCache, productLockService,
                        productDefinitionService, depositAccountService, branchService, command, $routeParams,
                        modalPrintPreviewService, passbookCache, ataCache, userCounterService, transactionDetailsBuilder,
                        operationTableBuilder, authentication, confirmation, fileService, casaTypesCache,
                        revertCommandService, queryParamsRemover, customerService) {
    const that = this;

    that.profile = {};
    that.depositAccounts = [];
    that.visibleAccounts = [];
    that.showClosed = $routeParams.showClosed === "true";
    that.depositAccountsTransactionHistory = [];
    that.selectedTransactionId = undefined;
    that.permissions = authentication.permissions;
    that.keyValueDetails = _.memoize(currentTransaction => {
      let baseValueDetails = transactionDetailsBuilder.build(currentTransaction);
      baseValueDetails.push(transactionDetailsBuilder.buildPhpIfNotZero('Available Balance after', (currentTransaction.balanceAfter)));

      baseValueDetails = transactionDetailsBuilder.clean(baseValueDetails);

      return baseValueDetails;
    });

    that.keyValueDetails.cache = new WeakMap();

    that.selectedAccount = null;
    that.isValidationSlipActive = false;
    that.isPassbookPrintActive = false;
    that.activePassbook = null;
    that.isCheckbookAccount = false;

    const customerId = $route.current.params['customerId'];
    that.accountId = $route.current.params['accountId'];

    that.onlyClosedDeposits = () => {
      return that.depositAccounts && that.depositAccounts.filter(deposit => deposit.status !== 'CLOSED').length === 0;
    };

    queryParamsRemover.removeQueryOnRedirect($scope, ['showClosed']);

    modalPrintPreviewService.canReprint('DEPOSIT_ACCOUNT_VALIDATION_SLIP', (active) => {
      that.isValidationSlipActive = active;
    });
    modalPrintPreviewService.canReprint('DEPOSIT_ACCOUNT_PASSBOOK', (active) => {
      that.isPassbookPrintActive = active;
    });

    const accountIsLocked = () => {
      return !_.isEmpty(that.nonReleasedLocks)
    };

    that.showPrint = (transaction) => {
      modalPrintPreviewService.show('DEPOSIT_ACCOUNT_VALIDATION_SLIP', {operationId: transaction.id});
    };

    that.isValidationSlipSupported = (transaction) => {
      return ['WITHDRAW_CASH',
        'WITHDRAW_CHECK',
        'DEPOSIT_CASH',
        'DEPOSIT_CUTOFF_CHECK',
        'DEPOSIT_CHECK',
        'CREDIT_MEMO',
        'DEBIT_MEMO'].includes(transaction.operationGroup);
    };

    that.toggleClosedAccounts = () => {
      if (that.showClosed) {
        that.visibleAccounts = that.depositAccounts;
      } else {
        that.visibleAccounts = that.depositAccounts.filter(deposit => deposit.status !== 'CLOSED');
      }

      if (that.selectedAccount) {
        $location
          .path(`/customer/${customerId}/accounts/${that.selectedAccount.id}`)
          .search('showClosed', that.showClosed.toString());
      }
    };

    that.switchContext = (owner) => {
      let redirectionUrl = `/customer/${owner.customerId}/accounts`;
      $location.path(redirectionUrl);
    };

    that.showSignature = (owner) => {
      const signatureFileId = owner.signatureFileId;
      that.signature = null;
      if (signatureFileId && !that.signature) {
        fileService.downloadFile(signatureFileId, false)
          .success(file => {
            that.signature = window.URL.createObjectURL(file);
          })
          .error(error => {
            notification.show("Error", "Could not show signature");
            that.showPopup = false;
            console.error(error);
          });
      }
      that.showPopup = true;
    };

    that.hideSignature = () => {
      that.showPopup = false;
      that.signature = null;
    };

    that.revertAllowed = (operation) => {
      return revertCommandService.revertAllowed(operation);
    };

    const revert = (operation, input, question, revertCommandName) => {
      confirmation(question, () =>
        command.execute(revertCommandName, input).success(() => {
          customerCache.depositAccounts(customerId).refetch();
          userCounterService.refetch();
        }));
    };

    that.revertCashDeposit = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the cash deposit of ${operation.amount} PHP?`,
      'DepositCashToAccountRevert'
    );

    that.revertCashDeposit = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the cash deposit of ${operation.amount} PHP?`,
      'DepositCashToAccountRevert'
    );

    that.revertCheckDeposit = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the check deposit of ${operation.amount} PHP?`,
      'DepositCheckToAccountRevert'
    );

    that.revertCashWithdrawal = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the cash withdrawal of ${operation.amount} PHP?`,
      'WithdrawAccountFundsByCashRevert'
    );

    that.revertCheckWithdrawal = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the check withdrawal of ${operation.amount} PHP?`,
      'WithdrawAccountFundsByCheckRevert'
    );

    that.revertCreditMemo = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert credit memo of ${operation.amount} PHP?`,
      'DepositMemoToAccountRevert'
    );

    that.revertDebitMemo = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert debit memo of ${operation.amount} PHP?`,
      'WithdrawAccountFundsByMemoRevert'
    );

    that.revertCreditOnUsCheckEnabled = (operation) => {
      return operation
        && operation.commandId
        && operation.checkId
        && operation.status !== 'REVERTED'
        && operation.operationGroup === 'TRANSFER_FUNDS'
        && operation.operationSubgroup
        && _.includes(['CREDIT_CHECK_ON_US', 'OFFSET_CREDIT_CHECK_ON_US'], operation.operationSubgroup);
    };

    that.revertCreditOnUsCheck = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the credit on-us check of ${operation.amount} PHP?`,
      'CreditOnUsCheckRevert'
    );

    that.revertEncashOnUsCheck = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the encash on-us check of ${operation.amount} PHP?`,
      'EncashOnUsCheckRevert'
    );

    that.returnCheck = (operation) => {
      const input = {'id': operation.checkId};
      command.execute('ReturnAccountCheck', input).success(() => {
        customerCache.depositAccounts(customerId).refetch();
      });
    };

    that.selectAccount = (account) => {
      that.selectedAccount = account;
      $location.path(`/customer/${customerId}/accounts/${account.id}`)
    };

    /**
     * Account can be closed if and only if:
     * 1. Holds balance is equal to 0 (no pending operations)
     * 2. Closing fee is equal to account balance
     * 3. Product is: ACTIVE/INACTIVE
     */
    that.closingAvailable = () => {
      let account = that.selectedAccount;
      // If holds balance is > 0 -> leave immediately
      if (account.holdsBalance !== 0) return false;
      // Otherwise check balance against fee
      return !accountIsLocked() && ['ACTIVE', 'INACTIVE'].includes(that.selectedAccount.status);
    };

    that.editAvailable = () => that.selectedAccount.status !== 'CLOSED'
      && !accountIsLocked();

    const ataCreditCommandAllowed = (command) => !that.ata
      || !that.ata.enabled
      || (that.ata.creditCommands && _.includes(that.ata.creditCommands, command));

    const passbookSupported = (accountType) => accountType ? accountType.passbookConfig !== 'PASSBOOK_NOT_SUPPORTED' : false;

    /**
     * Cash deposit can be performed when:
     * 1. Product is: ACTIVE
     * 2. ATA is disabled OR ATA allows cash deposit
     */
    that.cashDepositAvailable = () => that.selectedAccount.status === 'ACTIVE'
      && !accountIsLocked()
      && ataCreditCommandAllowed('DepositCashToAccount');

    /**
     * Cash deposit can be performed when:
     * 1. Product is: ACTIVE
     * 2. ATA is disabled OR ATA allows check deposit
     */
    that.checkDepositAvailable = () => that.selectedAccount.status === 'ACTIVE'
      && !accountIsLocked()
      && ataCreditCommandAllowed('DepositCheckToAccount');

    /**
     * Credit memo operation can be performed when:
     * 1. Product is: ACTIVE/INACTIVE
     * 2. ATA is disabled OR ATA allows check deposit
     */
    that.creditMemoAvailable = () => ['ACTIVE', 'INACTIVE'].includes(that.selectedAccount.status)
      && !accountIsLocked()
      && ataCreditCommandAllowed('DepositMemoToAccount');

    /**
     * Cash withdrawal can be performed when:
     * 1. Product balance is > 0
     * 2. Product is ACTIVE
     * 3. Account subtype != CHECKING
     */
    that.cashWithdrawalAvailable = () => that.selectedAccount.status === 'ACTIVE'
      && !accountIsLocked()
      && that.selectedAccount.balance > 0
      && that.subtype !== 'CHECKING';

    /**
     * Check withdrawal can be performed when:
     * 1. Product balance is > 0
     * 2. Product is ACTIVE
     * 3. Account subtype != CHECKING
     */
    that.checkWithdrawalAvailable = () => that.selectedAccount.status === 'ACTIVE'
      && !accountIsLocked()
      && that.selectedAccount.balance > 0
      && that.subtype !== 'CHECKING';

    /**
     * On-us check encashment can be performed when:
     * 1. Product is ACTIVE
     * 2. Account subtype != CHECKING
     */
    that.encashOnUsCheckAvailable = () => that.selectedAccount.status === 'ACTIVE'
      && !accountIsLocked()
      && that.subtype !== 'CHECKING';

    /**
     * Debit memo operation can be performed when:
     * 1. Product balance is > 0
     * 2. Product is: ACTIVE
     */
    that.debitMemoAvailable = () => that.selectedAccount.status === 'ACTIVE'
      && !accountIsLocked()
      && that.selectedAccount.balance > 0;

    /**
     * Create hold operation can be performed when:
     * 1. Product balance is > 0
     * 2. Product is: ACTIVE
     */
    that.createHoldAvailable = () => that.selectedAccount.status === 'ACTIVE'
      && !accountIsLocked()
      && that.selectedAccount.balance > 0;

    /**
     * Activation should be available for INACTIVE
     * products only
     */
    that.activationAvailable = () => that.selectedAccount.status === 'INACTIVE'
      && !accountIsLocked();

    /**
     * Credit on-us check can be performed when:
     * 1. Product is ACTIVE
     */
    that.creditOnUsCheckAvailable = () => that.selectedAccount.status === 'ACTIVE'
      && !accountIsLocked();

    /**
     * Activation should be available for CHECKING
     * accounts only
     */
    that.createStopOrderAvailable = () => that.isCheckbookAccount
      && !accountIsLocked()
      && !['CLOSED'].includes(that.selectedAccount.status);

    /**
     * Checkbook issue action is available only for ACTIVE checking accounts
     */
    that.checkbookIssueAvailable = () => that.isCheckbookAccount
      && ['ACTIVE'].includes(that.selectedAccount.status)
      && !accountIsLocked();

    /**
     * Pasbbok issue action is available only for ACTIVE accounts with passbook enabled
     */
    that.passbookIssueAvailable = () => passbookSupported(that.selectedAccount)
      && ['ACTIVE'].includes(that.selectedAccount.status)
      && !accountIsLocked();

    /**
     * Passbook issue action is available only for ACTIVE accounts with passbook enabled
     */
    that.hasActivePassbook = () => {
      return passbookSupported(that.selectedAccount) && that.activePassbook;
    };

    const confirmationIfPassbookRequired = (action) => {
      if (that.selectedAccount.passbookConfig === 'PASSBOOK_REQUIRED') {
        confirmation('Is passbook available?', action);
      } else {
        action();
      }
    };

    that.createLockAvailable = () => {
      return !accountIsLocked() && that.selectedAccount.status !== 'CLOSED';
    };

    that.releaseLockAvailable = () => {
      return accountIsLocked() && that.selectedAccount.status !== 'CLOSED';
    };

    that.releaseLock = () => {
      productLockService.releaseProductLocks(that.selectedAccount, null, () => {
        customerCache.depositAccounts(customerId).refetch()
        $route.reload();
      })
    };

    that.cashDeposit = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/cash-deposit`);
    that.checkDeposit = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/check-deposit`);
    that.creditMemo = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/credit-memo`);
    that.internalTransfer = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/internal-transfer`);
    that.cashWithdraw = () => confirmationIfPassbookRequired(() => {
      $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/cash-withdraw`);
    });
    that.checkWithdrawal = () => confirmationIfPassbookRequired(() => {
      $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/check-withdrawal`);
    });
    that.encashCheck = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/encash-check`);
    that.debitMemo = () => confirmationIfPassbookRequired(() => {
      $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/debit-memo`);
    });
    that.createHold = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/create-hold`);
    that.createLock = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/create-lock`);
    that.activateAccount = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/activate-account`);
    that.closeAccountCash = () => confirmationIfPassbookRequired(() => {
      $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/close/cash`);
    });
    that.closeAccountCheck = () => confirmationIfPassbookRequired(() => {
      $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/close/check`);
    });
    that.createNew = () => $location.path(`/customer/${customerId}/accounts/create`);
    that.createStopOrder = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/stop-order`);
    that.creditOnUsCheck = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/credit-on-us-check`);
    that.edit = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/edit`);
    that.editAta = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/edit-ata`);
    that.issueCheckbook = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/issue-checkbook`);
    that.issuePassbook = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/issue-passbook`);

    that.s1 = customerCache.profile(customerId).toObservable().subscribe(profile => {
      customerService.redirectWhenProfileIsInvalid(profile);
      that.profile = profile
    });
    that.s2 = customerCache.depositAccounts(customerId, false).toObservable()
      .combineLatest(productDefinitionService.toObservable(), (depositAccounts, products) =>
        depositAccounts.map(da => {
          const p = _.find(products, {id: da.definitionId});
          return Object.assign(da, {
            productName: p ? p.productName : '<Unknown product>',
            closingFee: p ? p.closingFee : null
          });
        })
      )
      .combineLatest(depositAccountService.toObservable(), (depositAccounts, accountTypes) =>
        depositAccounts.map(a => {
          const p = _.find(accountTypes, {id: a.typeId});
          return Object.assign(a, {
            closingFee: p ? p.closingFee : null,
            accountSubtype: p ? p.accountSubtype : null,
            passbookConfig: p ? p.passbookConfig : undefined,
            subtype: p ? p.accountSubtype : 'SAVINGS',
          });
        })
      )
      // enrich with branch name from branch service
      .combineLatest(branchService.toObservable(), (depositAccounts, branches) =>
        depositAccounts.map(da => {
          const branch = _.find(branches, {
            id: da.branchId
          });
          return Object.assign(da, {
            branchName: branch ? branch.name : '<Unknown>'
          });
        })
      )
      // enrich with pdic casa types
      .combineLatest(casaTypesCache.toObservable(), (depositAccounts, casaTypes) => {
        that.pdicCasaTypes = _.filter(casaTypes, {'regulatorType': 'PDIC'});
        return depositAccounts.map(da => {
          const pdicType = _.find(that.pdicCasaTypes, { id: da.pdicTypeId });
          const name = pdicType != null ? pdicType.name : null;
          return Object.assign(da, {
            pdicTypeName: name != null ? name.substring(name.indexOf('/') + 1) : null
          });
        })
      })
      .subscribe(accounts => {
          // select first account when none selected
          if (!that.accountId && accounts.length > 0) $location.path(`/customer/${customerId}/accounts/${accounts[0].id}`);

          if (that.accountId) {
            const account = _.find(accounts, (a) => a.id == that.accountId);
            if (account) {
              that.selectedAccount = account;
              that.isCheckbookAccount = account.accountSubtype === 'CHECKING';
              that.isCorporate = isCorporate(that.profile, that.selectedAccount, that.pdicCasaTypes);
              if (that.isCorporate) {
                enrichOwnersWithRelatives(that.selectedAccount, that.profile.relatives);
              }
            } else {
              $location.path(`/customer/${customerId}/accounts`);
            }
          }

          that.depositAccounts = accounts;

          if (that.showClosed) {
            that.visibleAccounts = accounts;
          } else {
            that.visibleAccounts = accounts.filter(account => account.status !== 'CLOSED');

            if (that.selectedAccount && that.selectedAccount.status === 'CLOSED' && that.selectAccount && that.visibleAccounts[0]) {
              that.selectAccount(that.visibleAccounts[0]);
            } else if (that.selectedAccount && that.selectedAccount.status === 'CLOSED' && that.onlyClosedDeposits()) {
              that.showClosed = true;
              that.toggleClosedAccounts();
            }
          }

          return accounts;
        }
      );

    that.subscription = new Subscription();
    that.subscription.add(that.s1);
    that.subscription.add(that.s2);

    if (that.accountId) {
      // Add passbook subscription
      that.subscription.add(passbookCache.withParam(that.accountId).toObservable().subscribe(data => {
        that.activePassbook = data;
        that.activePassbook.nextLine = data.lastUsedLine + 1;
      }));
      // Add ATA subscription
      that.subscription.add(ataCache.withParam(that.accountId).toObservable().subscribe(ata => that.ata = ata));
      // Add product locks subscription
      that.subscription.add(productLockCache.withParam(that.accountId).toObservable().subscribe(locks => {
        that.locks = locks;
        that.nonReleasedLocks = _.filter(locks || [], {released: false});
      }));
    }

    that.$onDestroy = () => that.subscription.unsubscribe();
  }
});
