import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('permissionCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get(`/management/permissions`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.permissionCache,
    cacheName: 'permissionCache'
  })
);
