import module from 'module';
import $ from 'jquery';

class PrintCertificateService {
  constructor($rootScope, $compile) {
    this.$rootScope = $rootScope;
    this.$compile = $compile;
  }

  printFor(productId) {
    return new Promise((resolve) => {
      const scope = this.$rootScope.$new();
      scope.productId = productId;
      scope.closePreview = () => {
        $('#modalPrintCertificate').remove();
        resolve();
      };
      $('body').append(this.$compile('<print-certificate id="modalPrintCertificate" product-id="productId" on-close="closePreview()"/>')(scope));
    });
  }
}

module.service('printCertificateService', PrintCertificateService);