import module from 'module';

import templateUrl from './notification-sms-configuration.template.html';

class NotificationSmsConfiguration {
  constructor($location, authentication) {
    this.$location = $location;
    this.authentication = authentication;
  }

  $onInit() {
    this.permission = this.authentication.permissions;
  }
}

module.component('notificationSmsConfiguration', {
  templateUrl,
  controller: NotificationSmsConfiguration
});
