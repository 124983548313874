import module from 'module';
import './lookup-customer-modal.component';
import $ from "jquery";

class LookupCustomerModalService {
  constructor($rootScope, $compile) {
    this.$rootScope = $rootScope;
    this.$compile = $compile;
  }

  selectCustomer() {
    return new Promise((resolve) => {
      const scope = this.$rootScope.$new();
      scope.close = () => {
        $('#lookup-customer-modal').remove();
        resolve();
      };

      scope.select = (customer) => {
        $('#lookup-customer-modal').remove();
        resolve(customer);
      };

      $('body').append(this.$compile(
        `<lookup-customer-modal 
          id="lookup-customer-modal"
          on-selected="select" 
          on-closed="close"/>
        `)(scope));
    });
  }
}

module.service('lookupCustomerModalService', LookupCustomerModalService);
