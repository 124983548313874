import module from 'module';

const templateUrl = require('./amount-predicate.template.html');
module.component('amountPredicate', {
  templateUrl,
  bindings: {
    predicates: '=',
    /**
     * One of: GREATER_THAN_AMOUNT, GREATER_THAN_OR_EQUAL_AMOUNT, LESS_THAN_AMOUNT, LESS_THAN_OR_EQUAL_AMOUNT
     */
    type: '<'
  },
  controller: function ($scope) {
    const that = this;

    $scope.$watchCollection('$ctrl.predicates', () => {
      if (!that.predicates) {
        that.value = null;
      } else {
        that.value = that.predicates[that.type];
      }

    });

    that.valueChanged = () => {
      that.predicates[that.type] = that.value;
    };

  }
});
