import module from 'module';
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import {
  addAccountLabels
} from 'components/general-ledger/common/gl.utils';

const templateUrl = require('./deposit-memo.template.html');
module.component('depositMemo', {
  templateUrl: templateUrl,
  controller: function($route, $location, command, customerCache, branchService, productDefinitionService, glMappingsService, confirmation, notification) {
    let that = this;
    that.termDeposit = {};

    // form input
    that.remarks = null;
    that.amount = null;
    that.selectedAccount = null;

    that.accounts = [{}];

    const bigSumBy = (collection, itemProperty) => {
      return collection.reduce((sum, item) => sum.add(item[itemProperty] || 0), new BigNumber(0));
    };

    that.calculateAmount = () => {
      return bigSumBy(that.accounts, 'amount');
    };

    that.addNewAccount = () => {
      that.accounts.push({});
    };

    that.removeAccount = (account) => {
      that.accounts.splice(_.findIndex(that.accounts, account), 1);
    };

    that.amountSelected = (amount) => {
      that.selectedAmount = amount;
    };

    let customerId = $route.current.params['customerId'];
    let accountId = $route.current.params['accountId'];

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${accountId}`);

    that.isCredit = $location.path().endsWith('credit-memo');
    that.isDebit = $location.path().endsWith('debit-memo');

    if (!that.isCredit && !that.isDebit) that.redirectBack();

    customerCache.termDeposits(customerId).toObservable()
      // Extend product instance with product name
      .combineLatest(productDefinitionService.toObservable(), (termDeposits, products) =>
        termDeposits.map(d => {
          let definition = _.find(products, {id: d.definitionId});
          return Object.assign(d, {productDefinition: definition});
        })
      )
      // Extend product instance with branch name
      .combineLatest(branchService.toObservable(), (termDeposits, branches) =>
        termDeposits.map(d => {
          return Object.assign(d, {branch: _.find(branches, {id: d.branchId})});
        })
      )
      .subscribe(termDeposits => {
        // fetch product mappings for account product type id
        that.termDeposit = _.find(termDeposits, (a) => String(a.id) === accountId);
        return that.termDeposit;
      });

    /**
     * Credit & debit memo operations should use accounts from:
     * ASSET/LIABILITY/INCOME/EXPENSE groups.
     *
     * TODO = In future this list should be filtered to include MEMO accounts only.
     */
    glMappingsService.accounts.toObservable().subscribe(glAccounts => {
      that.glAccounts = addAccountLabels(
        _.filter(glAccounts, function(glAccount){
          return ['ASSET', 'LIABILITY', 'INCOME', 'EXPENSE'].includes(glAccount.accountGroup);
        })
      );
    });

    that.doOperation = () => {
      confirmation(`Do you want to ${that.isCredit ? 'credit' : 'debit'} ${that.calculateAmount()} PHP?`, () => {
        let commandName = that.isCredit ? 'DepositTermDepositFundsByMemo' : 'WithdrawTermDepositFundsByMemo';

        command.execute(commandName, {
          productId: accountId,
          amount: that.calculateAmount(),
          entryType: that.isCredit ? 'CREDIT' : 'DEBIT',
          units: that.accounts,
          remarks: that.remarks
        }, {
          nxLoaderText: 'Processing operation...'
        }).success(() => {
          notification.show("The selected amount has been successfully " + (that.isCredit ? 'credited.' : 'debited.'));
          customerCache.termDeposits(customerId).refetch();
          that.redirectBack();
        }, true);
      });
    };

    that.selectConfig = {
      placeholder: 'Select Account',
      searchField: 'label',
      valueField: 'fullCode',
      labelField: 'label',
      maxItems: 1
    };
  }
});
