import module from 'module';

import templateUrl from './product-items.template.html';

class ProductItemsComponent {
  constructor(pawnItemTypeCache, $scope, http, productItemsLastSnapshotCache) {
    this.pawnItemTypeCache = pawnItemTypeCache;
    this.$scope = $scope;
    this.http = http;
    this.productItemsLastSnapshotCache = productItemsLastSnapshotCache;
  }

  async $onInit() {
    const types = await this.pawnItemTypeCache.toPromise();

    this.typeMap = {};
    for (let t of types) {
      this.typeMap[t.id] = t.name;
    }

    this.$scope.$watch('$ctrl.product', async () => {
      await this.loadItems();
    })
  }

  async loadItems() {
    if (!this.product) {
      return;
    }

    this.items = this.product.items;
    if (this.items && this.items.length) {
      return;
    }

    this.items = await this.productItemsLastSnapshotCache.withParam(this.product.id).toPromise();
  }

  itemClicked(item, $event) {
    $event.stopPropagation();
    this.selectedItemId = item.id;
  }

  hideInlinePanel() {
    this.selectedItemId = null;
  }
}

module.component('productItems', {
  templateUrl: templateUrl,
  bindings: {
    product: '=',
    editItem: '=',
    removeItem: '='
  },
  controller: ProductItemsComponent
});
