import module from 'module';
import _ from 'lodash';

const templateUrl = require('./loan-cash-payment.template.html');
module.component('customerLoansCashPayment', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $location, http, dict, customerCache, productDefinitionService, confirmation, notification, loanProductsCache, command,
  modalPrintPreviewService) {
    let that = this;

    that.loan = null;
    that.paymentRequest = {};

    let customerId = $route.current.params['customerId'];
    let loanId = $route.current.params['loanId'];

    customerCache.loans(customerId).toObservable()
      .combineLatest(loanProductsCache.toObservable(), (loans, loanTypes) => {
        if (!loans) that.redirectBack();
        return loans.map(loan => {
          // add loanProduct to loan object
          const type = _.find(loanTypes, {id: loan.typeId});
          return Object.assign(loan, {
            loanType: type
          });
        });
      })
      .subscribe(loans => {
        that.loan = _.find(loans, (l) => l.id === Number(loanId));
      });


    that.redirectBack = () => $location.path(`/customer/${customerId}/loans/${loanId}`);

    that.pay = () => {
      confirmation(`Do you want to pay ${that.paymentRequest.amount} PHP?`, () => {
        command.execute(`PayLoanByCash`, {
          productId: loanId,
          ...that.paymentRequest
        }, {nxLoaderText: 'Performing operation...'})
          .success((validationResponse) => {
          let message = "Cash payment operation performed successfully.";
          let msgType = "Success";
          let operationId = validationResponse.output.operationId;
          if (validationResponse.valid === false) {
            msgType = "Error";
            message = message + "\nOutstanding balance is not zero. Loan marked as INVALID.";
          }
          notification.show(msgType, message);
          customerCache.loans(customerId).refetch();
          modalPrintPreviewService.show('LOAN_PAYMENT_RECEIPT', {operationId: operationId},
            () => that.redirectBack());
        }, true)
          .offline(() => {
            customerCache.loans(customerId).refetch();
            that.redirectBack();
          })
      });
    };

    that.print = () => {
      window.print();
    };

  }
});
