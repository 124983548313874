import module from 'module';

const templateUrl = require('./term-deposit-check-placement.template.html');
module.component('customerTermDepositCheckPlacement', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, command, dict, customerCache, productDefinitionService, confirmation,
                        customerDepositService, modalPrintPreviewService, casaProductPrintService) {
    let that = this;
    that.termDeposit = {};
    that.banks = [];

    // form parameters
    that.checkNo = null;
    that.issuingBank = null;
    that.amount = null;
    that.validFrom = null;
    that.payee = null;
    that.remarks = null;

    let customerId = $route.current.params['customerId'];
    let depositId = $route.current.params['depositId'];

    that.selectConfig = {
      placeholder: 'Select bank',
      searchField: ['description'],
      valueField: 'id',
      labelField: 'description',
      maxItems: 1
    };

    customerDepositService.getDeposit(customerId, depositId, deposit => that.termDeposit = deposit);
    dict.onLoadingComplete(() => that.banks = dict['BANK']);

    that.amountSelected = (amount) => {
      that.selectedAmount = amount;
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${depositId}`);

    that.deposit = () => {
      confirmation(`Do you want to deposit ${$filter('php')(that.amount)}?`, () => {
        command.execute('DepositCheckPlacement', {
          productId: depositId,
          amount: that.amount,
          entryType: "CREDIT",
          validFrom: that.validFrom,
          bankId: that.issuingBank,
          micrNumber: that.micrNumber,
          checkNumber: that.micrNumber.substr(0, 10),
          payee: that.payee,
          remarks: that.remarks
        }, {nxLoaderText: 'Performing check placement'}).success((res) => {
          casaProductPrintService.printFor('TERM_DEPOSIT', customerId, depositId, res.output.id).finally(() => {
            customerCache.termDeposits(customerId).refetch();
            customerDepositService.checkIfCertificated(that.termDeposit, (activated) => {
              if (activated) {
                modalPrintPreviewService.show('CERTIFICATE_OF_TERM_DEPOSIT', {depositId: depositId}, () => that.redirectBack());
              } else {
                that.redirectBack();
              }
            });
          });
        });
      });
    };
  }
});
