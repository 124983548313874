import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import module from 'module';


import './promissory-note-print.style.less';
import templateUrl from './promissory-note-print.template.html';

class PromissoryNotePrint {
  constructor($location, userCache, modalPrintPreviewService) {
    this.$location = $location;
    this.modalPrintPreviewService = modalPrintPreviewService;
    this.filters = {};

    userCache.toPromise()
        .then(users => this.users = users);
  }

  close() {
    this.$location.path(`/customer/${this.customer.id}/loans/${this.loan.id}`);
  }

  displayPrint() {
    const print = {
      code: 'LOAN_PROMISSORY_NOTE',
      parameters: {
        'PRODUCT_DEFINITION': this.loan.definitionId,
        'CUSTOMER_TYPE': this.customer.customerType
      }
    };

    this.modalPrintPreviewService.show(print, {
      loanId: this.loan.id,
      firstWitnessId: this.firstWitness.customerId,
      sndWitnessId: this.sndWitness.customerId
    });
  }
}

module.component('promissoryNotePrint', {
  templateUrl,
  bindings: {
    loan: '<',
    customer: '<'
  },
  controller: PromissoryNotePrint
});
