import module from 'module';

module.factory('hierarchyBuilder', () => {
  let that = this;
  let service = {};
  service.buildHierarchy = (groups, idMap, root) => {
    let codeMap = {};
    // first pass - populate code map
    for (let group of groups) {
      codeMap[group.code] = group;
      idMap[group.id] = group;
      group.children = [];
    }

    // second pass - build hierarchy based on map
    for (let group of groups) {
      if (group.parentCode) {
        const parent = codeMap[group.parentCode];
        parent.children.push(group);
      } else {
        root.push(group);
      }
    }
  };

  service.getMisGroupLeafs = (groups) => {
    service.buildHierarchy(groups, {}, []);
    return _.filter(groups, (group) => group.children.length === 0);
  };
  return service;
});

