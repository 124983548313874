import _ from 'lodash';
import module from 'module';

const templateUrl = require('./group-loans-individual.template.html');
module.component('groupLoansIndividual', {
  templateUrl: templateUrl,
  bindings: {
    batches: '<',
  },
  controller: function ($route, $location) {
    let that = this;
    that.loans = [];

    that.openLoan = (loan) => {
      $location.path(`/customer/${loan.customerId}/loans/${loan.id}`)
    };

    that.$onChanges = changes => {
      const currentBatches = changes.batches.currentValue;
      if (currentBatches) {
        let loans = _.flatten(currentBatches.map(batch => batch.loans));
        // sort so that CLOSED is at the end of the list
        that.loans = _.sortBy(loans, (l) => l.status === 'CLOSED' ? 1 : 0)
      } else {
        that.loans = [];
      }
    }
  }
});