import module from 'module';
import _ from 'lodash';

const templateUrl = require('./activate-account.template.html');
module.component('customerAccountsActivateAccount', {
  templateUrl: templateUrl,
  controller: function ($route, $location, customerCache, productDefinitionService, confirmation, command) {
    let that = this;
    that.depositAccount = {};

    let customerId = $route.current.params['customerId'];
    let productId = $route.current.params['accountId'];

    customerCache.depositAccounts(customerId).toObservable()
      .combineLatest(productDefinitionService.toObservable(), (depositAccounts, products) =>
          depositAccounts.map(da => {
            const p = _.find(products, {id: da.definitionId});
            return Object.assign(da, {
              productName: p ? p.productName : '<Unknown product>'
            });
          })
        // productId is a string
      ).subscribe(accounts => that.depositAccount = _.find(accounts, (a) => String(a.id) === productId));

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${productId}`);

    that.activate = () => {
      confirmation(`Are you sure you want to proceed with account activation?`, () => {
        command.execute('ActivateAccount', {
          "productId": productId
        }, {nxLoaderText: 'Activating account...'}).success(() => {
          customerCache.depositAccounts(customerId).refetch();
          that.redirectBack();
        });
      });
    }
  }
});
