export const loanCreationTypes = Object.freeze([
  {
    label: 'New Loan',
    value: 'NEW_LOAN'
  }, {
    label: 'Reloan',
    value: 'RELOAN'
  }, {
    label: 'Restructured',
    value: 'RESTRUCTURED'
  }
]);

export const loanInformationTypes = Object.freeze({
  LOAN_CLASS: 'LOAN_CLASS',
  LOAN_ECONOMIC_ACTIVITY: 'LOAN_ECONOMIC_ACTIVITY',
  LOAN_PURPOSE: 'LOAN_PURPOSE',
  LOAN_SECURITY: 'LOAN_SECURITY',
  LOAN_RATE_TYPE: 'LOAN_RATE_TYPE',
  LOAN_MICROFINANCE_CLASSIFICATION: 'LOAN_MICROFINANCE_CLASSIFICATION'
});

export const loanCalculationMethods = Object.freeze([
  'PERCENTAGE_BASED_ON_YEAR_TERM',
  'PERCENTAGE_OF_ORIGINAL_INTERESTS',
  'PERCENTAGE_OF_ORIGINAL_PRINCIPAL',
  'PERCENTAGE_OF_ORIGINAL_PRINCIPAL_WITH_CUSTOM_DIVISOR',
  'PERCENTAGE_OF_ORIGINAL_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS',
  'PERCENTAGE_OF_OUTSTANDING_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS_PRINCIPAL_AND_PENALTIES',
  'PERCENTAGE_OF_INSURANCE_FEE',
  'PERCENTAGE_OF_INSTALLMENT_INTERESTS',
  'PERCENTAGE_OF_INSTALLMENT_PRINCIPAL',
  'PERCENTAGE_OF_INSTALLMENT_INTERESTS_AND_PRINCIPAL'
]);

export const cicContractTypes = Object.freeze([
  {label: 'Agricultural Loan', value: 'AGRICULTURAL_LOAN'},
  {label: 'Loan Line', value: 'LOAN_LINE'},
  {label: 'Personal Loan', value: 'PERSONAL_LOAN'},
  {label: 'Mortgage Real Estate', value: 'MORTGAGE_REAL_ESTATE'},
  {label: 'Syndicated Loan', value: 'SYNDICATED_LOAN'},
  {label: 'Term Loan', value: 'TERM_LOAN'},
  {label: 'Short Term Loan', value: 'SHORT_TERM_LOAN'},
  {label: 'Vehicle Loan', value: 'VEHICLE_LOAN'},
  {label: 'Unsecured Loan', value: 'UNSECURED_LOAN'},
  {label: 'Home Equity Loan', value: 'HOME_EQUITY_LOAN'},
  {label: 'Salary Loan', value: 'SALARY_LOAN'},
  {label: 'Provident Loan', value: 'PROVIDENT_LOAN'},
  {label: 'Business Loan', value: 'BUSINESS_LOAN'},
  {label: 'Vehicle Leasing', value: 'VEHICLE_LEASING'},
  {label: 'Real Estate Leasing', value: 'REAL_ESTATE_LEASING'},
  {label: 'Equipment Leasing', value: 'EQUIPMENT_LEASING'},
  {label: 'Apex Loan', value: 'APEX_LOAN'},
  {label: 'Trust Loan', value: 'TRUST_LOAN'},
  {label: 'Benefit Loan', value: 'BENEFIT_LOAN'},
  {label: 'Time Loan', value: 'TIME_LOAN'},
  {label: 'Student Loan', value: 'STUDENT_LOAN'},
  ]
);

export const automaticTransferStrategies = Object.freeze([
  {label: 'Always pay full amortizations', value: 'PAY_FULLY'},
  {label: 'Pay as much as possible', value: 'PAY_PARTIALLY'},
  {label: 'None', value: 'NONE'},
]);

export const allPercentageChargeTypes = Object.freeze([
  'PERCENTAGE_OF_ORIGINAL_INTERESTS',
  'PERCENTAGE_OF_ORIGINAL_PRINCIPAL',
  'PERCENTAGE_OF_ORIGINAL_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS',
  'PERCENTAGE_OF_OUTSTANDING_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS_PRINCIPAL_AND_PENALTIES',
  'PERCENTAGE_OF_INSTALLMENT_INTERESTS',
  'PERCENTAGE_OF_INSTALLMENT_PRINCIPAL',
  'PERCENTAGE_OF_INSTALLMENT_INTERESTS_AND_PRINCIPAL',
]);

export const pastDueInterestChargeTypes = Object.freeze([
  'PERCENTAGE_OF_ORIGINAL_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_PRINCIPAL',
  'PERCENTAGE_OF_OUTSTANDING_INTERESTS_AND_PRINCIPAL',
  'PERCENTAGE_OF_INSTALLMENT_PRINCIPAL',
  'PERCENTAGE_OF_INSTALLMENT_INTERESTS_AND_PRINCIPAL'
]);
