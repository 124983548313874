import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('pawnItemStoneColourCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get(`/products/pawns/items/stone-colours`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.pawnItemStoneColourConfig,
    cacheName: `pawnItemStoneColourConfig`
  })
);
