import module from 'module';
import _ from 'lodash';
import templateUrl from './forex-interbranch-cash-transfer.template.html';
import "./forex-interbranch-cash-transfer.less";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";

class ForexInterBranchCashTransfer {
  constructor(branchServiceHelper, authentication, branchCurrencyCache, systemPropertyCache, actionCommand, confirmation,
              $filter, http, denominationSerialNumbersService, userCache, popup, $window,  $timeout) {
    this.branchServiceHelper = branchServiceHelper;
    this.authentication = authentication;
    this.branchCurrencyCache = branchCurrencyCache;
    this.systemPropertyCache = systemPropertyCache;
    this.actionCommand = actionCommand;
    this.confirmation = confirmation;
    this.$filter = $filter;
    this.http = http;
    this.denominationSerialNumbersService = denominationSerialNumbersService;
    this.userCache = userCache;

    this.transaction = {};
    this.window = $window;
    this.singlePage = true;
    this.popup = popup;
    this.showCurrencyBreakdownTable = false;
    this.$timeout = $timeout;
    this.reportData = [
      {
        rows: [],
        key: "perSystem",
      },
      {
        rows: [],
        key: "actualCount",
      },
      {
        rows: [],
        key: "variance",
      },
    ];
  }

  async $onInit() {
    const [branches,
      currencies,
      systemProperties,
      systemUsers
    ] = await Promise.all([
      this.branchServiceHelper.getOperatingBranches(),
      this.branchCurrencyCache.withParam(Number(this.authentication.context.branchId)).toPromise(),
      this.systemPropertyCache.toPromise(),
      this.userCache.toPromise()
    ]);

    this.systemUsers = systemUsers;
    this.branches = _.sortBy(branches, 'name');
    this.sourceBranch = this.branches.find(b => b.id === this.authentication.context.branchId);
    this.transaction.sourceBranchId = this.sourceBranch.id;

    const mainCurrencyIso = systemProperties.find(prop => prop.code === 'MAIN_CURRENCY_ISO_CODE');
    this.foreignCurrencies = currencies.filter(c => c.isoCode !== mainCurrencyIso.value)

    this.canSaveNoteSerialNumber = _.find(systemProperties, {code: 'FOREIGN_DENOMINATION_SERIAL_NUMBERS_ENABLED'}).value === 'TRUE';
  }

  onTargetBranchChange(targetBranchId) {
    this.transaction.approvalUserId = null;
    this.users = _.filter(this.systemUsers, {branchId: targetBranchId, phantom: false});
    // If there is only 1 user -> select it
    if (this.users && this.users.length === 1) {
      this.transaction.approvalUserId = _.head(this.users).id;
    }
  }

  onCurrencyChange() {
    if (this.currency) {
      this.denominationSerialNumbersService.resetSerialNumbers(this.currency);
    }
    
    this.currency = this.foreignCurrencies.find(cur => cur.id === this.transaction.currencyId);
    this.transaction.amount = null;
  }

  getTargetBranchName() {
    const maybeBranch = this.branches.find(b => b.id === this.transaction.targetBranchId);
    return maybeBranch ? maybeBranch.name : '';
  }

  denominationIsValid() {
    return this.transaction.denominationBundle != null && this.transaction.denominationValid;
  };

  async save() {
    this.denominationSerialNumbersService.appendSerialNumbers(this.currency, this.transaction);

    if (await this.confirmation(`Do you want to transfer ${this.$filter('php')(this.transaction.amount, {currency: this.currency.isoCode})} 
                                 to branch ${this.getTargetBranchName()}?`)) {
      await this.actionCommand.execute('FOREX_INTERBRANCH_CASH_TRANSFER', this.transaction).toPromise();
    }
  }

  async currencyBreakdownHandler() {
    try {
      let currencyBreakdown = await this.http.post(`/forex/interbranch-cash-transfer-variance-breakdown-report`, this.transaction.denominationBundle).toPromise();
      this.reportData = this.reportData.map((tableData) => {
        let rows = currencyBreakdown[tableData.key];
        let grandTotal = rows.reduce((sum, row) => sum + row.total, 0);

        return {
          ...tableData,
          rows: rows,
          grandTotal: grandTotal
        };
      });

      this.showCurrencyBreakdownTable = true;
      this.branchName = this.getTargetBranchName();
    } catch (error) {
      this.popup({
        header: "Error",
        text: error.errorMessage,
      });
    }
  }

  // To do, create a service to handle config
  async preview() {

    const printableArea = document.getElementById("reportContent");

    const pdf = new JsPDF("l", "pt", "letter");

    await html2canvas(printableArea).then((canvas) => {

      const pageHeight = 980;

      for (let i = 0; i <= printableArea.clientHeight / pageHeight; i++) {
        const srcImg = canvas;
        const sX = 0;
        const sY = pageHeight * i; // start 1 pageHeight down for every new page
        const sWidth = printableArea.clientWidth;
        const sHeight = pageHeight;
        const dX = 0;
        const dY = 0;
        const dWidth = printableArea.clientWidth;
        const dHeight = pageHeight;

        window.onePageCanvas = document.createElement("canvas");
        window.onePageCanvas.setAttribute("width", printableArea.clientWidth);
        window.onePageCanvas.setAttribute("height", pageHeight);
        window.onePageCanvas.style.border = 'none';
        window.onePageCanvas.style.outline = 'none';

        let ctx = window.onePageCanvas.getContext("2d");

        ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

        const canvasDataURL = window.onePageCanvas.toDataURL("image/jpg", 1.0);
        const width = window.onePageCanvas.width;
        const height = window.onePageCanvas.clientHeight;

        if (i > 0)
          // if we're on anything other than the first page, add another page
          pdf.addPage(612, 791); // 8.5" x 11" in pts (inches*72)

        pdf.setPage(i + 1);

        pdf.addImage(canvasDataURL, "JPG", 20, 20, width * 0.46, height * 0.46); // add content to the page

      }
    });

    this.$timeout(() => {

      let pdfURL = pdf.output("bloburl");

      const iframe = $(".iframe-preview")[0];

      iframe.onload = function() {
        // Print the content of the iframe
        iframe.contentWindow.focus(); // Required for some browsers
        iframe.contentWindow.print();
      };

      iframe.setAttribute("src", pdfURL);
      iframe.style.display = "none"; // Hides the iframe
    });
  }
}
 
module.component('forexInterbranchCashTransfer', {
  templateUrl,
  controller: ForexInterBranchCashTransfer
});
