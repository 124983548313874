import moment from 'moment';
import _ from 'lodash';
import {Observable} from 'rxjs/Observable'
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/toPromise';
import {NgTableParams} from 'ng-table/ng-table.js';

import module from 'module';
import {Statuses} from '../common/gl-transaction.service';
import {defaultHeaderLabels, mergedLedgerPromise} from '../common/gl.utils';
import {updateRefreshTime} from 'components/common/refresh-button/refresh-button.component';

import templateUrl from './gl-transaction-list.template.html';

class GlTransactionList {
  constructor($scope, $routeParams, glLedgerService, branchService,
              breadcrumbs, glTransactionService, userCache, confirmation,
              notification) {
    this.$scope = $scope;
    this.$routeParams = $routeParams;
    this.glLedgerService = glLedgerService;
    this.branchService = branchService;
    this.breadcrumbs = breadcrumbs;
    this.glTransactionService = glTransactionService;
    this.userCache = userCache;
    this.confirmation = confirmation;
    this.notification = notification;
  }

  async $onInit() {
    this.ledgerId = parseInt(this.$routeParams.accountId);
    this.header = [];
    this.statuses = Object.keys(Statuses)
      .map(statusName => ({
        value: statusName,
        label: Statuses[statusName]
      }));

    const ledger = await mergedLedgerPromise({
      glLedgerService: this.glLedgerService,
      branchService: this.branchService,
      accountId: this.ledgerId
    });
    this.initializeFilter(ledger);

    _.set(this.breadcrumbs, 'options', {
      'gl-transaction-list-label': ledger.template.name,
    });

    this.header = defaultHeaderLabels(ledger);
    this.initializeConfig();

    this.$scope.$watch('$ctrl.filter',
      () => {
        if (!this.config) {
          return;
        }

        this.selectedTransaction = null;
        this.config.page(1);
        this.config.reload();
      },
      true
    );
  }

  initializeConfig() {
    this.config = new NgTableParams({
      count: 15,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: params => {
        const transactionsPromise = this.glTransactionService.fetchTransactions({
          ledgerId: this.ledgerId,
          postingDateFrom: this.filter.date.from,
          postingDateTo: this.filter.date.to,
          status: this.filter.status.value,
          pageNo: params.page() - 1,
          pageSize: params.count(),
        }).toPromise();

        const transactionsObservable = Observable.fromPromise(transactionsPromise);
        return transactionsObservable.combineLatest(this.userCache.toObservable().take(1), (transactions, users) => {
          this.config.total(transactions.totalCount);
          updateRefreshTime(this.$scope);

          return transactions.result.map((transaction, index) => {
            const user = _.find(users, user => user.id === transaction.userId);
            const rejectionUser = transaction.rejectionUserId ? _.find(users, user => user.id === transaction.rejectionUserId) : null;
            const formatFullDateTime = date => date ? moment(date).format('DD/MM/YYYY hh:mm:ss a') : '-';
            const formatUser = (user) => user ? `${user.firstName} ${user.lastName}` : '-';
            return {
              id: transaction.id,
              index: index + 1,
              backdated: transaction.backdated,
              contingent: transaction.contingent,
              objectIds: transaction.objectIds,
              transactionDate: formatFullDateTime(transaction.registrationTime),
              status: transaction.status,
              systemDate: moment(transaction.postingDate).format('DD/MM/YYYY'),
              postingDate: transaction.status === 'PENDING' && !transaction.backdated ? '-' : moment(transaction.effectivePostingDate).format('DD/MM/YYYY'),
              transactionType: transaction.transactionType,
              user: formatUser(user),
              amount: transaction.amount,
              remarks: transaction.remarks,
              revokeAction: transaction.status === 'PENDING' && transaction.transactionType === 'MANUAL',
              transactionUnits: transaction.transactionUnits,
              rejectionTime: formatFullDateTime(transaction.rejectionTime),
              rejectionUser: formatUser(rejectionUser),
            };
          });
        }).toPromise();
      }
    });
  }

  initializeFilter(ledger) {
    const postingDate = ledger.branch.postingDate;
    const formattedDate = moment(postingDate).format('YYYY-MM-DD');
    this.filter = {
      status: this.statuses[0],
      date: {
        from: formattedDate,
        to: formattedDate
      }
    };
  }

  mapTransactionType(type) {
    if (type === 'OPERATION') {
      return 'Automatic';
    } else if (type === 'MANUAL') {
      return 'Manual';
    } else if (type === 'LEDGER_ACTION') {
      return 'Miscellaneous';
    } else {
      return type;
    }
  }

  refresh() {
    this.config.reload();
  }

  resolveLabel(statusKey) {
    return Statuses[statusKey];
  }

  revokeAction(item, $event = null) {
    if ($event) {
      $event.stopPropagation();
    }

    const transactionId = item.id;
    this.confirmation('Do you want to revoke the transaction?', () => {
      this.glTransactionService.revokeTransaction({
        ledgerId: this.ledgerId,
        transactionId,
      }).toPromise()
        .then(revoked => {
          this.notification.show('Transaction successfully revoked');
          this.refresh()
            .subscribe();
        });
    });
  }

  hideDetailsPanel() {
    this.selectedTransaction = null;
  }

  rowClicked(row, $event) {
    $event.stopPropagation();
    this.selectedTransaction = row.id;
  }
}

module.component('glTransactionList', {
  templateUrl,
  controller: GlTransactionList
});
