import module from 'module';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

const templateUrl = require('./stocked-items-reprice-summary.template.html');
import './stocked-items-reprice-summary.style.less';

class StockedItemsRepriceSummary {
  constructor(http, command, notification, pawnItemStoneTypeCache, pawnItemStoneRateCache, systemPropertyCache) {
    this.http = http;
    this.command = command;
    this.notification = notification;
    this.pawnItemStoneTypeCache = pawnItemStoneTypeCache;
    this.pawnItemStoneRateCache = pawnItemStoneRateCache;
    this.systemPropertyCache = systemPropertyCache
  }

  async $onInit() {
    const request = this.items.map(item => {
      return {
        id: item.id,
        stoneMarkup: 0
      }
    });

    const [result, pawnItemStoneTypes, pawnItemStoneRates, systemProperties] = await Promise.all([
      this.http.post(`/products/pawns/calculate-item-reprice`, request, { nxLoaderText: 'Calculating new prices' }).toPromise(),
      this.pawnItemStoneTypeCache.toPromise(),
      this.pawnItemStoneRateCache.toPromise(),
      this.systemPropertyCache.toPromise(),
    ]);

    this.stockedItemMarkup = Number(_.find(systemProperties, { code: 'STOCKED_ITEM_REPRICING_MARKUP_PERCENTAGE' }).value);

    result.stockedItems.forEach(item => {
      const i = _.find(this.items, { id: item.id });

      // Build table data.
      if (i) {
        item.autoDescription = i.autoDescription;
        item.categoryLabel = i.categoryLabel;
        item.typeLabel = i.typeLabel;
        item.subtypeLabel = i.subtypeLabel;


        if (item.metal.metalRateId == null) {
          item.karatLabel = "";
          item.previousGoldRateLabel = "";
          item.newGoldRateLabel = "";

          item.metal.components.forEach(metalComponent => {
            if (item.karatLabel.length) {
              item.karatLabel += ", ";
            }

            if (item.previousGoldRateLabel.length) {
              item.previousGoldRateLabel += ", ";
            }

            if (item.newGoldRateLabel.length) {
              item.newGoldRateLabel += ", "
            }

            item.karatLabel += metalComponent.rate.fineness;
            item.previousGoldRateLabel += metalComponent.previousMetalRate.pricePerGram.toLocaleString() + ' PHP';
            item.newGoldRateLabel += metalComponent.rate.pricePerGram.toLocaleString() + ' PHP';
          });
        } else {
          item.karatLabel = item.metal.rate.fineness;
          item.previousGoldRateLabel = item.metal.previousMetalRate.pricePerGram.toLocaleString() + ' PHP';
          item.newGoldRateLabel = item.metal.rate.pricePerGram.toLocaleString() + ' PHP';
        }

        let stoneTypes = "";
        let stoneSizes = "";
        item.stones.forEach(stone => {
          if (stoneTypes.length) {
            stoneTypes += ", ";
          }

          if (stoneSizes.length) {
            stoneSizes += ", ";
          }

          stoneTypes += _.find(pawnItemStoneTypes, { id: stone.stoneTypeId }).name;
          stoneSizes += _.find(pawnItemStoneRates, { id: stone.stoneRateId }).name;
        });

        item.stoneTypes = stoneTypes;
        item.stoneSizes = stoneSizes;
      }
    });

    this.itemList = result.stockedItems;
  }

  calculateNewSellingPrice(item) {
    const stoneMarkup = item.stoneMarkup;

    item.newStoneValue = new BigNumber(item.totalPreviousStonesValuation).plus((stoneMarkup / 100) * item.totalPreviousStonesValuation)
      .toNumber();

    item.newItemValue = new BigNumber(item.newStoneValue).plus(item.newMetalValue)
      .toNumber();

    item.newSellingPrice = new BigNumber(item.newItemValue)
      .plus((this.stockedItemMarkup / 100) * item.newItemValue)
      .round(2).toNumber();
  }

  async reprice() {
    await this.command.execute('RepriceStockedItems', { stockedItems: this.itemList })
      .success(() => {
        this.cancel();
      });
  }

  cancel() {
    this.mode = null;
  }
}

module.component('stockedItemsRepriceSummary', {
  templateUrl,
  bindings: {
    mode: '=',
    items: '<'
  },
  controller: StockedItemsRepriceSummary
});