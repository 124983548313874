import module from 'module';
import moment from 'moment';
import _ from 'lodash';
import templateUrl from './clock-in-out.template.html'
import './attendance.style.less'

const YEAR_MONTH_DAY_PATTERN = 'YYYY-MM-DD';
const YEAR_MONTH_PATTERN = 'YYYY-MM';

class ClockInOut {
  constructor(attendanceService, authentication, postingDateService, confirmation, $timeout, $route, NgTableParams) {
    this.attendanceService = attendanceService;
    this.authentication = authentication;
    this.postingDateService = postingDateService;
    this.confirmation = confirmation;
    this.$timeout = $timeout;
    this.$route = $route;
    this.NgTableParams = NgTableParams;
  }

  $onInit() {
    this.filter = {
      month: moment().toDate()
    };
    this.initializeTableConfig();
    this.postingDateService.getCurrentUserBranchPostingDate().then((p) => this.systemDate = p);
    this.action = 'TIME_IN' || 'TIME_OUT';
    this.doFilter();
  }

  initializeTableConfig() {
    this.tableConfig = new this.NgTableParams({
      filter: this.filter
    }, {
      counts: [],
      getData: async (params) => {
        const selectedDate = params.filter().month;
        const rawLogs = await this.fetchLogs(selectedDate);
        const timeInAndOutPairs = this.collectTimeInAndOutPairs(rawLogs);
        const logs = this.combinePairsIntoLogs(timeInAndOutPairs);

        if (!this.isCurrentYearMonth(selectedDate)) {
          return;
        }


        if (logs.length === 0 // new month has come
          || !this.isCurrentDay(logs[0].clockIn) // new day has come, for backwards compatibility last cycle isn't required to be completed
          || logs[0].clockIn && logs[0].clockOut // last clock-in/out cycle has been completed
        ) {
          logs.unshift({
            systemDate: this.systemDate,
            currentDate: moment().format(YEAR_MONTH_DAY_PATTERN)
          })
        }

        return logs;
      }
    });
  }

  isLatestEntry(item) {
    return this.tableConfig.data[0] === item;
  }

  async fetchLogs(date) {
    return this.attendanceService.getLog(
      this.authentication.context.id,
      moment(date).format(YEAR_MONTH_DAY_PATTERN)
    );
  }

  collectTimeInAndOutPairs(rawLogs) {
    const timeInAndOutPairs = [];
    let timeInAndOutPair = [];

    // Find pairs of TIME_IN and proceeding TIME_OUT
    for (const log of rawLogs) {
      if (log.action === 'TIME_IN') {
        if (timeInAndOutPair.length > 0) {
          timeInAndOutPairs.push(timeInAndOutPair);
        }
        timeInAndOutPair = [];
      }
      timeInAndOutPair.push(log);
    }

    if (timeInAndOutPair.length > 0) {
      // Add ongoing log
      timeInAndOutPairs.push(timeInAndOutPair);
    }

    return timeInAndOutPairs;
  }

  combinePairsIntoLogs(timeInAndOutPairs) {
    return timeInAndOutPairs.map(pair => {
      return {
        systemDate: pair[0].systemDate,
        currentDate: pair[0].logTime,
        clockIn: pair[0].logTime,
        clockOut: pair.length > 1 ? pair[1].logTime : null,
      }
    }).sort((a,b) => moment(b.clockIn).toDate().getTime() - moment(a.clockIn).toDate().getTime()); // reverse, latest at the top
  }

  isCurrentYearMonth(date) {
    return moment(date).format(YEAR_MONTH_PATTERN) === moment().format(YEAR_MONTH_PATTERN);
  }

  isCurrentDay(date) {
    return moment(date).format(YEAR_MONTH_DAY_PATTERN) === moment().format(YEAR_MONTH_DAY_PATTERN);
  }

  async log(photo) {
    if (!_.first(photo) || !_.first(photo).id) {
      return;
    }

    const result = await this.attendanceService.log(_.first(photo).id, this.action);
    if (!result) {
      return;
    }

    if (this.action === 'TIME_OUT') {
      this.authentication.logout();
    }
    this.$route.reload();
  }

  async clockIn() {
    const confirm = await this.confirmation('Confirm clock in?');
    if (!confirm) {
      return;
    }
    this.action = 'TIME_IN';
    this.showCamera();
  }

  async clockOut() {
    const confirm = await this.confirmation('System will be logged out. Confirm clock out?');
    if (!confirm) {
      return;
    }
    this.action = 'TIME_OUT';
    this.showCamera();
  }

  showCamera() {
    if (this.showWebCam) {
      this.showWebCam();
    }
  }

  doFilter() {
    this.tableConfig.parameters().reload();
  }

  registerShowCameraHandler(handler) {
    this.showWebCam = handler;
  }

  onFileUpload() {
    if (!this.attendancePhoto) {
      return;
    }
    this.log(this.attendancePhoto);
  }
}

module.component('clockInOut', {
  templateUrl,
  controller: ClockInOut
});
