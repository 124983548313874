import module from 'module';
import {BigNumber} from "bignumber.js";

import './stock-item-create-layaway-view.style.less';

import templateUrl from './stock-item-create-layaway-view.template.html';

class StockItemCreateLayawayView {
  constructor ($routeParams, $location, pawnItemCache, pawnItemTypeCache, authentication, notification, command,
               confirmationTemplate, actionCategoryCache, systemPropertyCache, glMappingsService, branchService,
               $filter, customerCache, confirmation, http, layawayProductsCache, popup, productService) {
    this.$routeParams = $routeParams;
    this.$location = $location;
    this.pawnItemCache = pawnItemCache;
    this.pawnItemTypeCache = pawnItemTypeCache;
    this.authentication = authentication;
    this.notification = notification;
    this.command = command;
    this.confirmationTemplate = confirmationTemplate;
    this.actionCategoryCache = actionCategoryCache;
    this.systemPropertyCache = systemPropertyCache;
    this.glMappingsService = glMappingsService;
    this.branchService = branchService;
    this.$filter = $filter;
    this.customerCache = customerCache;
    this.confirmation = confirmation;
    this.http = http;
    this.layawayProductsCache = layawayProductsCache;
    this.popup = popup;
    this.productService = productService;
  }

  async $onInit() {
    this.sellingPrice = this.sumTotalPrice(this.items, 'sellingPrice');

    this.request = {
      itemIds: this.items.map(i => i.id),
      branchId: this.items[0].branchId,
      customerId: null,
      layawayTypeId: null,
      downPaymentAmount: null,
      denominationBundle: null,
    };

    this.resetCalculation();

    const layawayProducts = await this.layawayProductsCache.toPromise();
    this.availableLayawayProducts = layawayProducts.filter(pp => this.productService.availableForSale(pp));
  }

  resetCalculation() {
    this.calculation = null;
  }

  onInputChange() {
    this.resetCalculation();
  }

  async calculate() {
    try {
      this.calculation = await this.http.post('/products/layaways/calculate', {
        ...this.request,
        customerId: this.customer.customerId,
        branchId: this.items[0].branchId,
        itemIds: this.items.map(i => i.id),
      }).toPromise();
    } catch (e) {
      this.popup({
        header: 'Error',
        text: e.errorMessage
      });
    }
  }

  setCustomer(customer) {
    this.customer = customer;
  }

  goBack() {
    this.mode = null;
  }

  async create() {
    const confirmed = await this.confirmationTemplate({
      question: `Do you want to create following layaway?`,
      details: [
        {label: 'Customer', description: `${this.customer.name} (${this.customer.customerNumber})`},
        {label: 'Layaway type', description: this.availableLayawayProducts.find(l => l.id === this.calculation.typeId).productDefinition.productName},
        {label: 'Down payment', description: this.$filter('php')(this.calculation.downPaymentAmount)},
        {label: 'Date of agreement', description: this.$filter('prettyDate')(this.calculation.agreedOn)},
        {label: 'Last installment Date', description: this.$filter('prettyDate')(this.calculation.lastInstallmentDate)},
        {label: 'Installment amount', description: this.$filter('php')(this.calculation.installmentAmount)}
      ]
    });

    if (!confirmed) {
      return;
    }

    const request = {
      ...this.request,
      customerId: this.customer.customerId
    };

    const response = await this.command.execute('CreateLayaway', request).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.customerCache.layaways(this.customer.customerId).evict();
    this.pawnItemCache.evict();
    this.$location.path(`/customer/${this.customer.customerId}/layaways/`);
  }

  sumTotalPrice(items, property) {
    return items.map(entry => new BigNumber(entry[property]))
      .reduce((a, b) => a.plus(b), new BigNumber(0));
  }
}

module.component('stockItemCreateLayawayView', {
  templateUrl: templateUrl,
  bindings: {
    mode: '=',
    items: '<'
  },
  controller: StockItemCreateLayawayView
});
