import module from 'module';
import _ from 'lodash';

module.factory('newPawnService', function (customerCache, http) {
  const STORAGE_PREFIX = 'pawn-process-appraisals-';
  const service = {};

  function retrieveFromStorage(customerId) {
    const item = sessionStorage.getItem(STORAGE_PREFIX + customerId);
    if (item) return JSON.parse(item);
    else return [];
  }

  function storeInStorage(customerId, appraisals) {
    sessionStorage.setItem(STORAGE_PREFIX + customerId, JSON.stringify(appraisals));
  }

  service.removeAppraisal = (appraisal) => {
    if (!customerCache.loadedCustomerId) {
      console.log('No customer present. Not removing appraisal...');
      return;
    }

    const appraisals = retrieveFromStorage(customerCache.loadedCustomerId);
    _.remove(appraisals, {id: appraisal.id});
    storeInStorage(customerCache.loadedCustomerId, appraisals);
    return appraisals;
  };

  service.removeAll = () => {
    if (!customerCache.loadedCustomerId) {
      console.log('No customer present. Not removing appraisals...');
      return;
    }

    storeInStorage(customerCache.loadedCustomerId, []);
    return [];
  };

  service.removeIds = (ids) => {
    if (_.isEmpty(ids)) {
      console.log('No pawn ids. Not removing appraisals...');
      return;
    }

    if (!customerCache.loadedCustomerId) {
      console.log('No customer present. Not removing appraisals...');
      return;
    }

    const appraisals = retrieveFromStorage(customerCache.loadedCustomerId);
    const filtered = appraisals.filter(i => !ids.includes(i.id));
    storeInStorage(customerCache.loadedCustomerId, filtered);
  };

  service.addAppraisal = (appraisal, customerId) => {
    if (!appraisal) {
      console.log('No appraisal given...');
      return;
    }

    if (!customerId) {
      console.log('WARNING: No customerId provided. Not adding appraisal to the customer..');
      return;
    }

    const storedAppraisals = retrieveFromStorage(customerCache.loadedCustomerId);
    storedAppraisals.push(appraisal);
    storeInStorage(customerCache.loadedCustomerId, storedAppraisals);
  };

  service.getAppraisals = () => {
    if (!customerCache.loadedCustomerId) {
      console.log('No customer present. Returning empty list..');
    }

    return retrieveFromStorage(customerCache.loadedCustomerId);
  };

  /**
   * Performs simple filtering of provided tags based on given refTagId.
   * Assumes reference tag points to acceptable starting point and any proceeding tags (by level means) are supported to user.
   *
   * Category and total amount conditions aren't included in the process.
   *
   * @param refTagId
   * @param tags
   * @returns {*|*[]}
   */
  service.getSupportedTagIds = (refTagId, tags) => {
    const refTag = tags.find(t => t.id === refTagId);
    return refTag.levelLeader ?
      // Level based tags, either leaders or non-leaders
      tags.filter(t => t.level >= refTag.level)
        .filter(t => t.levelLeader === refTag.levelLeader)
        .map(t => t.id) :
      // No other possible than given ref tag - includes Sakto/Sagad (Series AX and Series BX)
      // or current tag that doesn't have anything to tag-levels
      [refTag.id];
  }

  service.calculateAppraisalAmount = (items) => {
    return _.sumBy(items, (item) => item.overloadedValuation ? item.overloadedValuation : item.valuation);
  };

  service.roundDownToNearestHundreds = (amount) => {
    return Math.floor(amount / 100) * 100;
  }

  service.createPawnRequest = (pawnProduct, pawn, items) => {
    return {
      pawnTypeId: pawnProduct.id,
      fafNo: pawn.fafNo,
      tagId: pawn.tagId,
      origin: 'NEW',
      appraisalUserId: pawn.appraisalUserId,
      customerId: pawn.customerId,
      maxAppraisalAmount: pawn.maxAppraisalAmount,
      principalAmount: pawn.amount,
      interestRate: pawn.interestRate,
      itemIds: items.map(i => i.id),
      comments: pawn.comments,
      eirRate: pawn.eirRate
    };
  }

  service.calculatePawnPromise = async (request, pawnProduct) => {
    const calculatedPawn = await http.post(`/products/pawns/calculate`, request).toPromise();
    if (!pawnProduct.interestPaidInAdvance) {
      calculatedPawn.interestAmount = 0;
    }
    return calculatedPawn;
  }

  return service;
});
