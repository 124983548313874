import module from 'module';

import templateUrl from './customer-pawn-uid-ledger.template.html';

class PawnUidLedger {
  // empty
}

module.component('pawnUidLedger', {
  templateUrl: templateUrl,
  bindings: {
    uidLedger: '<',
    showStatus: '<'
  },
  controller: PawnUidLedger
});