import module from "../../../../module";
import templateUrl from "./shift-list.template.html";
import './shift-list.style.less'
import moment from "moment";

class ShiftList {
  constructor(http, confirmation, command) {
    this.http = http;
    this.confirmation = confirmation;
    this.command = command;

    this.daysOfWeek = [{
      label: 'Monday',
      value: 'MONDAY'
    }, {
      label: 'Tuesday',
      value: 'TUESDAY'
    }, {
      label: 'Wednesday',
      value: 'WEDNESDAY'
    }, {
      label: 'Thursday',
      value: 'THURSDAY'
    }, {
      label: 'Friday',
      value: 'FRIDAY'
    }, {
      label: 'Saturday',
      value: 'SATURDAY'
    }, {
      label: 'Sunday',
      value: 'SUNDAY'
    }];
  }

  async $onInit() {
    this.shifts = await this.http.get("/management/shifts").toPromise();
    this.shifts.forEach(s => {
      s.startTime = this.parseTime(s.startTime);
      s.endTime = this.parseTime(s.endTime);
    })
  }

  hasRecords() {
    return this.shifts && this.shifts.length > 0;
  }

  add() {
    const initShift = {
      days: {},
      inputMode: true
    }
    this.shifts.push(initShift);
  }

  edit(index) {
    this.shiftsCopy = angular.copy(this.shifts);
    this.shifts[index].inputMode = true;
  }

  cancel(index) {
    if (this.shifts[index].id) {
      this.shifts[index] = this.shiftsCopy[index];
      this.shifts[index].inputMode = false;
    } else {
      this.shifts.pop();
    }
  }

  getForm(index) {
    this.synchronizedForm();
    return this[`form_${index}`];
  }

  isSaveDisabled(index) {
    return this.getForm(index).$pristine || this.getForm(index).$invalid;
  }

  formatTime(time) {
    return moment(time).format('HH:mm:ss');
  }

  parseTime(time) {
    return moment(time, 'HH:mm:ss').toDate();
  }

  getRequest(shift) {
    const request = {
      command: 'CreateShift',
      params: {
        name: shift.name,
        description: shift.description,
        days: shift.days,
        startTime: this.formatTime(shift.startTime),
        endTime: this.formatTime(shift.endTime)
      }
    };

    if (shift.id) {
      request.params.id = shift.id;
      request.command = 'UpdateShift'
    }
    return request;
  }

  async save(index) {
    if (this.getForm(index).$valid) {
      const confirmation = await this.confirmation('Do you want to save shift?');
      if (!confirmation) {
        return;
      }

      const request = this.getRequest(this.shifts[index]);
      const result = await this.command.execute(request.command, request.params).toPromise();
      if (!result) {
        return;
      }

      this.shifts[index].id = result.output.id;
      this.shifts[index].inputMode = false;
      this.getForm(index).$setPristine();
    }
  }

  async delete(index) {
    if (!this.getForm(index).$valid) {
      return;
    }
    const confirmation = await this.confirmation('Do you want to delete shift?');
    if (!confirmation) {
      return;
    }
    const shift = this.shifts[index];
    const result = await this.command.execute('DeleteShift', {id: shift.id}).toPromise();
    if (!result) {
      return;
    }
    this.shifts.splice(index, 1);
  }

  synchronizedForm() {
    let hasUndefined = false;
    for(let i = 0; i < this.shifts.length; i++) {
      if (!this[`form_${i}`] || hasUndefined) {
        hasUndefined = true;
        this[`form_${i}`] = this[`form_${i + 1}`];
      }
    }
  }
}


module.component("shiftList", {
  templateUrl,
  controller: ShiftList
})
