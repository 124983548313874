import module from 'module';
import _ from 'lodash';
import moment from 'moment';

import {addAccountLabels} from 'components/general-ledger/common/gl.utils';

const templateUrl = require('./branch-details.template.html');

module.component('branchDetails', {
  templateUrl,
  controller: function ($route, $location, http, $timeout, dict, authentication, organizationCache, glMappingsService,
                        branchService, confirmation, notification, depositoryAccountCache, systemPropertyCache, currencyCache,
                        branchCurrencyCache, $filter, command, forexCache, pawnTagCache, confirmationTemplate, $scope, pawnTagService) {

    const that = this;

    const defaultWorkingDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];

    that.workingDays = [{
      label: 'Monday',
      value: 'MONDAY'
    }, {
      label: 'Tuesday',
      value: 'TUESDAY'
    }, {
      label: 'Wednesday',
      value: 'WEDNESDAY'
    }, {
      label: 'Thursday',
      value: 'THURSDAY'
    }, {
      label: 'Friday',
      value: 'FRIDAY'
    }, {
      label: 'Saturday',
      value: 'SATURDAY'
    }, {
      label: 'Sunday',
      value: 'SUNDAY'
    }];

    that.ledgerAccountSelectConfig = {
      placeholder: 'Select ledger account',
      searchField: 'label',
      valueField: 'fullCode',
      labelField: 'label',
      maxItems: 1
    };

    that.dict = dict;
    that.branchId = $route.current.params.branchId;
    that.rootOrganization = null;
    that.organizations = [];
    that.selectableOrganizations = [];
    that.branches = [];
    that.otherBranches = [];
    that.branch = {};
    that.depositoryAccounts = [];
    that.isBranchAlreadyClosed = false;

    // On init set form as submitted to highlight invalid fields
    $timeout(() => {
      that.branchForm.$setSubmitted();
    });

    that.$onInit = async () => {
      [that.hasCompletePawnTagReadPermission, that.tags, that.properties] = await Promise.all([
        pawnTagService.hasCompletePawnTagReadPermission(),
        pawnTagCache.toPromise(),
        systemPropertyCache.toPromise()
      ]);

      const ledgerSupportProperty = _.find(that.properties, {code: 'LEDGER_SUPPORT'});
      that.supportsLedger = ledgerSupportProperty && ledgerSupportProperty.value === 'TRUE';

      const organizationType = _.find(that.properties, {code: 'ORGANIZATION_TYPE'});
      that.organizationType = organizationType === null ? 'BANK' : organizationType.value;
    }

    that.getDefaultOrganization = () => {
      return that.organizations && that.organizations.length === 1 ? _.head(that.organizations) : null;
    };

    that.createTime = (hour) => {
      if (hour >= 0 && hour <= 24) {
        let cutoff = new Date();
        cutoff.setHours(hour);
        cutoff.setMinutes(0);
        cutoff.setSeconds(0);
        cutoff.setMilliseconds(0);
        return cutoff;
      } else {
        return null;
      }
    };

    that.onPosSupportChange = (posSupport) => {
      if (!posSupport) {
        that.branch.posLicenseBranchId = null;
        that.branch.posDepositoryAccountId = null;
      }
    };

    const depositoryAccountsSub = depositoryAccountCache.toObservable().subscribe(depositoryAccounts => {
      that.depositoryAccounts = depositoryAccounts;
    });

    that.fetchCurrencies = async () => {
      that.currencies = await currencyCache.toPromise();
    };

    const branchSub = branchService.toObservable().subscribe(async branches => {
      that.branches = branches;
      that.otherBranches = branches;
      that.fetchCurrencies();
      // For existing branch -> fetch it's details
      if (that.branchId) {
        // Find data of branch identified by [branchId]
        that.branch = _.find(branches, (branch) => branch.id == that.branchId);
        // Create new separated-variable to remember initial value
        that.isBranchAlreadyClosed = Boolean(that.branch.closed);
        // Exclude branch from [otherBranches] list
        that.otherBranches = _.filter(that.otherBranches, (b) => b.id != that.branchId);
        // Parse check cutoff time
        const parseTime = (time) => time ? moment(time, 'HH:mm:ss').toDate() : null;
        that.branch.checkCutoffTime = parseTime(that.branch.checkCutoffTime);
        that.branch.automaticEndOfDayTime = parseTime(that.branch.automaticEndOfDayTime);

        that.workingHours = await http.get(`/management/branches/${that.branchId}/working-hours`).toPromise();
        that.workingHours.forEach(workingHours => {
          workingHours.from = parseTime(workingHours.from);
          workingHours.to = parseTime(workingHours.to);
        });
        that.synchronizeWorkingHours();
      }
    });

    const organizationSub = organizationCache.toObservable().subscribe(organizations => {
      that.organizations = organizations;
      // Select root organization
      that.rootOrganization = _.find(organizations, {root: true});
      if (that.rootOrganization && that.rootOrganization.type === 'PAWNSHOP') {
        // to avoid constraint validation error in the back end
        that.branch.bspBranchName = 'N/A';
        that.branch.bspBranchAddress = 'N/A';
      }

      // Filter selectable organizations (only non-root) can be picked by user
      that.selectableOrganizations = _.filter(organizations, {root: false});
      // There is only 1 organization -> set it as selectable
      if (organizations.length === 1) {
        that.selectableOrganizations = [];
        that.selectableOrganizations.push(organizations[0]);
      }
      // If branch organization is not set and there is only one organization -> set it
      if (that.branch && !that.branch.organizationId) {
        let defaultOrganization = that.getDefaultOrganization();
        Object.assign(that.branch, {organizationId: defaultOrganization ? defaultOrganization.id : null});
      }
    });

    const ledgerAccountsSub = glMappingsService.accounts.toObservable().subscribe(glAccounts => {
      that.ledgerAccounts = addAccountLabels(
        _.filter(glAccounts, function (glAccount) {
          return ['ASSET', 'LIABILITY'].includes(glAccount.accountGroup);
        })
      );
    });

    that.initializeWorkingHours = ({dayOfWeek, dayType = 'WORKING_DAY'}) => ({
      dayType,
      dayOfWeek,
      from: null,
      to: null
    });

    that.synchronizeWorkingHours = () => {
      // remove working hours for removed working days
      that.workingHours = that.workingHours
        .filter(workingHours => workingHours.dayType !== 'WORKING_DAY' || that.branch.workingDays.includes(workingHours.dayOfWeek));

      // add working hours for added working days
      that.workingHours = that.workingHours.concat(
        that.branch.workingDays
        // filter working days without working hours defined
          .filter(workingDay => !that.workingHours.find(wh => wh.dayOfWeek === workingDay))
          // map them to new working hours object
          .map(dayOfWeek => that.initializeWorkingHours({dayOfWeek}))
      );

      // sort them
      const sortedWorkingHours = that.workingDays
        .map(nextWorkingDay => that.workingHours.find(wd => wd.dayOfWeek === nextWorkingDay.value))
        .filter(workingHours => workingHours !== undefined);

      // add holiday working hours at the end of working hours list
      if (that.workingHours.find(wh => wh.dayType === 'HOLIDAY')) {
        sortedWorkingHours.push(that.workingHours.find(wh => wh.dayType === 'HOLIDAY'));
      } else {
        sortedWorkingHours.push(that.initializeWorkingHours({dayType: 'HOLIDAY'}))
      }

      that.workingHours = sortedWorkingHours;
    };

    (function initializeNewBranch() {
      if (!that.branchId) {
        const defaultOrganization = that.getDefaultOrganization();
        const defaultCountry = _.find(that.dict['COUNTRY'], {code: 'PH'});
        that.branch = {
          organizationId: defaultOrganization ? defaultOrganization.id : null,
          workingDays: defaultWorkingDays,
          startedOn: new Date(),
          ipAddressRestricted: true,
          postingDate: null,
          forexCurrencies: null,
          effectivePostingDate: null,
          checkCutoffTime: that.createTime(12),
          checkCounterThreshold: 3,
          posSupport: false,
          posLicensedBranchId: null,
          posDepositoryAccountId: null,
          address: {
            primary: true,
            orderNo: 0,
            countryId: defaultCountry ? defaultCountry.id : null
          },
          supportedPawnTagIds: [],
          closed: false
        };

        that.workingHours = [];
        that.synchronizeWorkingHours();
      }
    })();

    const findUniqueCode = (base) => {
      let unique = false;
      let counter = 1;
      let codeCandidate = base.substring(0, 5).toUpperCase();
      // Map list of branches to branch codes (include only branches wih NN code)
      const branchCodes = _.map(_.filter(that.branches, b => b.code !== null), (b) => b.code.toUpperCase());
      // While code candidate is not unique -> increment
      while (!unique) {
        codeCandidate = codeCandidate + (counter > 1 ? counter : '');
        unique = !_.includes(branchCodes, codeCandidate);
        counter++;
      }
      return codeCandidate;
    };

    /**
     * DEPRECATED
     *
     * Due to Villarica request code is generated on backend (numeric only).
     * This method should can be used in future.
     *
     * @param name branch name
     * @return branch code candidate
     */
    const generateCode = (name) => {
      // If branch name is null or empty -> return [null]
      if (!name) return null;
      // Remove all non-ascii characters from name
      let code = name.toUpperCase().replace(/[^0-9A-Z]/g, '');
      // If name after removal of non-ascii characters is empty -> return [null]
      if (!code) return null;
      // Check if code is unique (compare with other branches
      return findUniqueCode(code);
    };

    that.onNameChange = () => {
      that.branch.code = generateCode(that.branch.name);
    };

    that.formatWorkingHoursLabel = (workingHours) =>
      $filter('prettyEnum')(workingHours.dayType === 'WORKING_DAY' ? workingHours.dayOfWeek : workingHours.dayType) + ":";

    const formatTime = (time) => time ? moment(time).format('HH:mm:ss') : null;

    that.formatCommand = () => {
      const input = {
        branch: {
          id: that.branch.id,
          organizationId: that.branch.organizationId,
          name: that.branch.name,
          code: that.branch.code,
          amlaInstitutionCode: that.branch.amlaInstitutionCode,
          initialCash: that.branch.initialCash,
          address: that.branch.address,
          workingDays: that.branch.workingDays,
          tinNumber: that.branch.tinNumber,
          startedOn: that.branch.startedOn,
          phoneNumber: that.branch.phoneNumber,
          faxNumber: that.branch.faxNumber,
          ipAddressRestricted: that.branch.ipAddressRestricted,
          ipAddress: that.branch.ipAddress,
          postingDate: that.branch.postingDate,
          effectivePostingDate: that.branch.effectivePostingDate,
          brstn: that.branch.brstn,
          sssCode: that.branch.sssCode || null,
          checkCutoffTime: formatTime(that.branch.checkCutoffTime),
          checkCounterThreshold: that.branch.checkCounterThreshold,
          automaticEndOfDayTime: formatTime(that.branch.automaticEndOfDayTime),
          posSupport: that.branch.posSupport,
          posLicenseBranchId: that.branch.posLicenseBranchId,
          posDepositoryAccountId: that.branch.posDepositoryAccountId,
          dueFromAccountCode: that.branch.dueFromAccountCode,
          dueToAccountCode: that.branch.dueToAccountCode,
          forexTransacting: that.branch.forexTransacting || false,
          bspBranchName: that.branch.bspBranchName,
          bspBranchAddress: that.branch.bspBranchAddress,
          bspBranchCode: that.branch.bspBranchCode,
          forexCurrencies: that.branch.forexCurrencies,
          supportedPawnTagIds: that.branch.supportedPawnTagIds,
          closed: that.branch.closed
        },
      };

      // fill required fields
      input.branch.address.primary = true;
      input.branch.address.type = 'PERMANENT';

      input.workingHours = _.cloneDeep(that.workingHours)
        .map(workingHours => ({
          ...workingHours,
          from: formatTime(workingHours.from),
          to: formatTime(workingHours.to)
        }));

      return input;
    };

    that.save = () => {
      if (that.branch.id) {
        that.update();
      } else {
        that.create();
      }
    };

    that.create = async () => {
      await command.execute('CreateBranch', that.formatCommand()).toPromise();

      const updateBranchListAndRedirectToBranches = () => {
        branchService.refetch();
        branchCurrencyCache.withParam(that.branch.id).refetch();
        forexCache.withParam(that.branch.id).refetch();
        $location.path("/admin/organization/branches");
      };

      authentication.refresh(updateBranchListAndRedirectToBranches, updateBranchListAndRedirectToBranches);
    };

    that.update = async () => {
      if (that.branch.closed) {
        const confirmed = await confirmationTemplate({
          question: `Do you want to close the branch?`,
          warning: 'This operation cannot be reverted.'
        });
        if (!confirmed) {
          return;
        }
      }

      // Update branch first and then close it
      await command.execute('UpdateBranch', that.formatCommand()).toPromise();

      notification.show("Success", "Branch updated successfully");
      branchService.refetch();
      branchCurrencyCache.withParam(that.branch.id).refetch();
      forexCache.withParam(that.branch.id).refetch();
      $location.path("/admin/organization/branches");
    };

    that.cancel = () => {
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => $location.path("/#!/admin/branches"));
    };

    that.$onDestroy = () => {
      depositoryAccountsSub.unsubscribe();
      branchSub.unsubscribe();
      organizationSub.unsubscribe();
      ledgerAccountsSub.unsubscribe();
    };
  }
});
