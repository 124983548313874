import module from "module";
import BigNumber from 'bignumber.js';
import moment from 'moment';

import {ssNumberPattern} from "../sss-offline-payment/sss-offline-payment.utils";
const templateUrl = require('./sss-change-individual-prn.template.html');

// Deprecated - Marked for removal
class SssChangeIndividualPrn {
  constructor($location, $filter, http, actionCommand, command, popup, modalPrintPreviewService) {
    this.command = command;
    this.popup = popup;
    this.$filter = $filter;
    this.$location = $location;
    this.modalPrintPreviewService = modalPrintPreviewService;

    this.availablePayorTypes = [
      'NON_WORKING_SPOUSE',
      'OVERSEAS_WORKER',
      'SELF_EMPLOYED_MEMBER',
      'VOLUNTARY_MEMBER',
    ];

    this.ssPattern = ssNumberPattern('CONTRIBUTION');
    this.monthlyFlexiFundAmount = 0;

    this.onPayorTypeChange = () => {
      this.monthlyFlexiFundAmount = 0;
    };

    this.getAvailableContributions = () => {
      if(!this.payorType) {
        return this.contributions;
      }

      if(this.payorType === 'OVERSEAS_WORKER') {
        return this.contributions.filter(cont => cont.value > 500);
      }

      return this.contributions;
    };

    this.contributions = [];

    http.get('/sss/online-payment/payor-kind/INDIVIDUAL/contributions')
      .success(contributions => {
        this.contributions = contributions.map(amount => ({
          value: amount,
          label: $filter('php')(amount)
        }));
      });

    this.cancelChanges = () => {
      actionCommand.cancelChanges();
    };
  }

  onStartingPeriodChange() {
    if (!this.endingPeriod) {
      this.endingPeriod = this.startingPeriod.clone();
    }
  };

  calculateMonthSpan() {
    if(!this.startingPeriod || !this.endingPeriod) {
      return null;
    }

    return moment(this.endingPeriod).diff(this.startingPeriod, 'months') + 1; // diff between jan-jan should be one
  }

  calculateTotalContributionAmount() {
    return this.multiplyByMonthSpan(this.monthlyAmount);
  }

  multiplyByMonthSpan(amount) {
    const monthDiff = this.calculateMonthSpan();
    if(!monthDiff || (amount === null || amount === undefined)) {
      return null;
    }

    return new BigNumber(amount)
      .times(monthDiff)
      .toNumber();
  }

  calculateTotalAmount() {
    const amount = this.calculateTotalContributionAmount();
    if(amount === null || amount === undefined) {
      return 0;
    }

    return new BigNumber(amount)
      .plus(new BigNumber(this.flexiFundAmount || 0))
      .toNumber();
  }

  calculateTotalAmountText() {
    const amount = this.calculateTotalContributionAmount();
    if(amount === null || amount === undefined) {
      return "";
    }

    return this.$filter('php')(this.calculateTotalAmount());
  }

  changePRN() {
    this.command.execute('ChangeIndividualPRNSSS', {
      "payorType": this.payorType,
      "ssNumber": this.ssNumber,
      "startingPeriod": this.startingPeriod.format('YYYY-MM'),
      "endingPeriod": this.endingPeriod.format('YYYY-MM'),
      "amount": this.calculateTotalAmount(),
      "flexiFundAmount": this.flexiFundAmount || 0,
    }).success(res => {
      const {commandId, output: {prn}} = res;
      this.popup({
        text: `New PRN: ${prn}`,
        callback: () => {
          this.modalPrintPreviewService.show('SSS_GENERATE_PRN_VALIDATION_SLIP', {
            commandId: commandId,
          }, () => {
            this.$location.path("/dashboard/miscellaneous-transactions")
          });
        }
      });
    });
  }
}

module.component('sssChangeIndividualPrn', {
  templateUrl,
  controller: SssChangeIndividualPrn
});
