import module from 'module';
import _ from 'lodash';
import {addressTypes} from 'constants/address';

import './addresses.style.less';
const templateUrl = require('./addresses.template.html');
module.component('customerProfileAddresses', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '='
  },
  controller: function (dict, propertyConfigService, systemPropertyCache) {
    let that = this;

    that.dict = dict;
    that.cfg = propertyConfigService;
    that.addressTypes = addressTypes;

    that.$onInit = () => {
      that.profile.addresses = that.profile.addresses || [];
    };

    that.hasOnePrimaryAddress = () => {
      const primaryAddressCount = that.profile.addresses.filter(a => a.primary).length;
      return primaryAddressCount === 1;
    };

    systemPropertyCache.toObservable().first().subscribe(properties => {
      const minAddr = _.find(properties, {code: 'CIF_MIN_ADDRESS_SECTIONS'});
      that.minAddressSections = minAddr ? Number(minAddr.value) : 0;
    });


    that.addAddress = function () {
      that.profile.addresses.push({
        countryId: that.readDefaultCountry(),
        primary: false
      });
    };

    that.removeAddress = function (addr) {
      let array = that.profile.addresses;
      let index = array.indexOf(addr);
      array.splice(index, 1);
    };

    that.readDefaultCountry = () => {
      let defaultCountryCode = that.cfg.defaultValue('customer', 'address', 'country');
      if (defaultCountryCode) {
        let country = _.find(that.dict['COUNTRY'], {code: defaultCountryCode});
        return country && country.id ? country.id : null;
      }
      return null;
    };

    that.primaryChanged = (addr, idx) => {
      //make sure only one address is selected as primary
      if (addr.primary) {
        that.profile.addresses.forEach( (a, i) => {
          if (idx !== i) {
            a.primary = false;
          }
        });
      }
    }
  }
});
