import module from 'module';
import _ from 'lodash';

const templateUrl = require('./loan-check-release.template.html');
module.component('customerLoanCheckRelease', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, confirmation, notification, customerCache, modalPrintPreviewService) {
    const that = this;

    let customerId = $route.current.params['customerId'];
    let loanId = $route.current.params['loanId'];

    customerCache.loans(customerId).toObservable().subscribe(loans =>
      that.loan = _.find(loans, (l) => l.id == loanId));

    that.redirectBack = () => {
      $location.path(`/customer/${customerId}/loans/${loanId}`);
    }
    that.cancelChanges = () => {
      that.redirectBack();
    };

    that.save = () => {
      http.post(`/products/loans/${loanId}/release/cashiers-check`, {
        cashiersCheckNumber: that.checkNumber,
        remarks: that.remarks,
      }).success((res) => {
        const operationId = res.id;
        notification.show('Cashier\'s check successfully created.');
        customerCache.loans(customerId).refetch();
        modalPrintPreviewService.show('CREATE_CASHIER_CHECK_FOR_LOAN_RELEASE', {operationId: operationId},
          () => that.redirectBack());
      })
    }
  }
});
