import module from '../../../module';
import _ from 'lodash';
import templateUrl from './pawn-tag-select.template.html';

class PawnTagSelect {

  constructor(permissionCache, rolesCache, userCache, authentication, $scope, pawnTagCache) {
    this.permissionCache = permissionCache;
    this.rolesCache = rolesCache;
    this.userCache = userCache;
    this.roleId = authentication.context.roleIds;
    this.$scope = $scope;
    this.pawnTagCache = pawnTagCache;
  }

  async $onInit() {
    const [roles, allTags]  = await Promise.all([this.rolesCache.toPromise(), this.pawnTagCache.toPromise()]);

    const permissions = _.filter(roles, r => this.roleId.includes(r.id))
      .map(r => r.permissions)
      .reduce((_, val) => val);

    const supportedTagsPermission = _.filter(permissions, p => p.name === 'PWN_TAG_READ')
      .map(p => ({
        name: p.name,
        predicates: p.predicates
      }))
      .reduce((_, val) => val, {});

    if (!supportedTagsPermission.predicates) {
      return;
    }

    this.$scope.$watch('$ctrl.supportedTagIds', () => {
      if (this.supportedTagIds) {
        const tagIds = this.supportedTagIds.filter(id => supportedTagsPermission.predicates['AVAILABLE_TAGS'].includes(id));
        this.tags = _.filter(allTags, t => tagIds.includes(t.id));
        this.refreshModelTagId();
      }
    });

    this.$scope.$watch('$ctrl.initialTagId', () => {
      this.refreshModelTagId();
    });
  }

  refreshModelTagId() {
    if (!this.pawn) {
      return;
    }

    if (this.initialTagId && this.tags && this.tags.some(t => t.id === this.initialTagId)) {
      this.pawn.tagId = this.initialTagId;
    } else {
      this.pawn.tagId = null
    }
  }
}

module.component('pawnTagSelect', {
  templateUrl: templateUrl,
  bindings: {
    /**
     * Model used to store selected tag
     */
    pawn: '=',
    /**
     * [optional] Tag of first choice, in case not contained in {@code supportedTagIds} it will be ignored
     */
    initialTagId: '<',
    /**
     * List of tags ready to be selected, make sure all of them are allowed when {@code manualSelection} is set to {@code true}
     *
     * Note {@code PWN_TAG_READ} permission and corresponding predicates are handled by the component
     */
    supportedTagIds: '<',
    /**
     * By default, components blocks tag selection, this property changes that behaviour
     */
    manualSelection: '<'
  },
  controller: PawnTagSelect
});
