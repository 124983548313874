import module from "module";
import {addAccountLabels} from "../../general-ledger/common/gl.utils";

const templateUrl = require('./batch-sss-pension-correction.template.html');
module.component('batchSssPensionCorrection', {
  templateUrl: templateUrl,
  bindings: {
    'fileId': '<',
    'onSubmit': '<'
  },
  controller: function (sssPensionService, glMappingsService, command) {
    const that = this;

    // GL accounts select config
    that.selectConfig = {
      placeholder: 'Select account',
      searchField: 'label',
      valueField: 'fullCode',
      labelField: 'label',
      maxItems: 1
    };

    const s1 = sssPensionService.getPensionsByFileId(that.fileId).subscribe(p => {
      that.pensions = p.filter(p => p.status === 'ERROR');
      that.pensions.forEach(p => p.setAccount = (result) => {
        p.account = result || null;
      });
    });

    const s2 = glMappingsService.accounts.toObservable().subscribe(glAccounts => {
      that.glAccounts = addAccountLabels(
        _.filter(glAccounts, (glAccount) => {
          return ['ASSET', 'LIABILITY'].includes(glAccount.accountGroup);
        })
      );
    });

    that.submit = () => {
      const input = {pensionFileId: that.fileId};
      input.redoList = that.pensions.map(pension => {
        return Object.assign({}, {
          pensionId: pension.id,
          productId: pension.account ? pension.account.id : null,
          status: pension.targetStatus || 'ERROR'
        });
      });

      command.execute('SSSPensionBatchRedo', input).success(() => {
        that.onSubmit(that.fileId);
      });
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
      s2.unsubscribe();
    }
  }
});
