import module from 'module';
import _ from 'lodash';

const templateUrl = require('./loan-check-payment.template.html');
module.component('customerLoansCheckPayment', {
  templateUrl: templateUrl,
  controller: function ($route, $scope, $location, $filter, http, command, dict, customerCache, productDefinitionService, loanProductsCache, confirmation,
                        modalPrintPreviewService) {
    let that = this;
    that.banks = [];
    that.loan = null;
    that.paymentRequest = {};
    that.checkModel = {};

    let customerId = $route.current.params['customerId'];
    that.loanId = $route.current.params['loanId'];

    customerCache.loans(customerId).toObservable()
      .combineLatest(loanProductsCache.toObservable(), (loans, loanTypes) => {
        if (!loans) that.redirectBack();
        return loans.map(loan => {
          // add loanProduct to loan object
          const type = _.find(loanTypes, {id: loan.typeId});
          return Object.assign(loan, {
            loanType: type
          });
        });
      })
      .subscribe(loans => {
        that.loan = _.find(loans, (l) => l.id === Number(that.loanId));
      });

    that.redirectBack = () => $location.path(`/customer/${customerId}/loans/${that.loanId}`);

    that.pay = () => {

      let commandCode = that.checkModel.onUsCheck ? 'PayLoanByOnUsCheck' : 'PayLoanByCheck';
      confirmation(`Do you want to pay ${$filter('php')(that.checkModel.amount)}?`, () => {
        command.execute(commandCode, {
          productId: that.loanId,
          ...that.paymentRequest,
          checkOperation: that.checkModel
        }, {nxLoaderText: 'Performing operation...'})
          .success((validationResponse) => {
            let operationId = validationResponse.output.operationId;
            customerCache.loans(customerId).refetch();
            modalPrintPreviewService.show('LOAN_PAYMENT_RECEIPT', {operationId: operationId},
              () => that.redirectBack());
          }).offline(() => {
          customerCache.loans(customerId).refetch();
          that.redirectBack();
        })
      });
    };

    that.print = () => {
      window.print();
    };

    $scope.$watch('$ctrl.paymentRequest', () => {
      that.checkModel.amount = that.paymentRequest.amount;
    }, true);

  }
});
