import module from 'module';

module.factory('cmcHeaderInterceptor',
    (cmcService) => ({
      request: (config) => {
        const cmc = cmcService.getCmcToken();

        if (cmc)
          config.headers['NX-CMC'] = cmc;

        return config;
      }
    })
);