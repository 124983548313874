import module from "../../../module";
import templateUrl from './attendance.template.html'
import './attendance.style.less'
class Attendance {
  constructor() {
    this.sectionType = 'inOut'
  }

}

module.component('attendance', {
  templateUrl,
  controller: Attendance
});
