import module from 'module';

const templateUrl = require('./customer-breadcrumbs.template.html');
module.component('customerBreadcrumbs', {
    templateUrl: templateUrl,
    bindings: {
      /**
       * Either 'CUSTOMER' or 'GROUP'
       * If null, the default of CUSTOMER is chosen.
       */
      mode: '<'
    },
    controller: function ($route, breadcrumbs, customerCache, groupCustomerCache) {

      function setBreadcrumbName(profile) {
        _.set(breadcrumbs, 'options.customer', profile.effectiveName);
      }

      const cache = this.mode === 'GROUP' ? groupCustomerCache : customerCache;

      let customerId = $route.current.params['customerId'];
      if(customerId) {
        cache.profile(customerId).toObservable().subscribe(profile => {
          setBreadcrumbName(profile)
        });
      }
    }
  }
);