import module from 'module';
import _ from 'lodash';

const templateUrl = require('./income.template.html');
module.component('customerProfileIncome', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '=',
    'multipleIncomeSources': '<'
  },
  controller: function ($scope, $timeout, dict, propertyConfigService, systemPropertyCache) {
    let that = this;
    that.cfg = propertyConfigService;

    that.profile.incomeSources = that.profile.incomeSources && that.profile.incomeSources.length ? that.profile.incomeSources : [{}];

    dict.onLoadingComplete(() => {
      that.dict = dict;
      that.types = dict['INCOME_SOURCE_TYPE'];
      that.otherTypeId = _.find(dict['INCOME_SOURCE_TYPE'], {code: 'OTHERS'}).id;
      that.natureOfWorks = dict['NATURE_OF_WORK'];
    });

    that.minSections = 0;    

    const s1 = systemPropertyCache.toObservable().first().subscribe(properties => {
      const minSections = _.find(properties, {code: 'CIF_MIN_INCOME_SOURCE_SECTIONS'});
      that.minSections = minSections && minSections.value ? Number(minSections.value) : 0;
    });

    that.onChangeIncomeSource = (incomeSource) => {
      incomeSource.other = null;
    }

    that.addIncomeSource = function () {
      that.profile.incomeSources.push({
        primary: false
      });
    };

    that.removeIncomeSource = function (soi) {
      let array = that.profile.incomeSources;
      let index = array.indexOf(soi);
      array.splice(index, 1);
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
    };
  }
});
