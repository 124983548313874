import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import {Subscription} from 'rxjs/Subscription';
import _ from 'lodash';
import moment from 'moment';

import './common/amortization-payment.component';
import './new-loan/new-loan-list.component';
import './new-loan/new-loan-list-table.component';
import './new-loan/new-loan-details.component';
import './new-loan/new-loan-parameters.component';
import './new-loan/new-loan-lai.component';
import './new-loan/new-loan-charges-override.component';
import './assign-group/customer-loans-assign-group.component';
import './check-payment/loan-check-payment.component';
import './cash-payment/loan-cash-payment.component';
import './transfer-payment/loan-transfer-payment.component';
import './check-release/loan-check-release.component';
import './check-release/group-loan-check-release.component';
import './gl-payment';
import './waive-penalties/waive-amortization.component';
import './common/loan-pre-terminate.component';
import './pre-terminate/loan-pre-terminate-by-cash.component';
import './pre-terminate/loan-pre-terminate-by-transfer.component';
import './pre-terminate/loan-pre-terminate.service'
import './amortization/amortization-inline-panel.component';
import './common/uid-ledger.component';
import './loan-account-information/loan-account-information.component';


import BigNumber from 'bignumber.js';


import './preview/loan-preview.component';

import module from 'module';

const templateUrl = require('./customer-loans.template.html');
module.component('customerLoans', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $scope, customerCache, notification, postingDateService, userCache, 
                        modalPrintPreviewService, command, reportModal, loanProductsCache, branchService, 
                        transactionDetailsBuilder, confirmation, authentication, revertCommandService, $routeParams,
                        feeDefinitionsCache, queryParamsRemover, NgTableParams, $filter, customerService) {
    let that = this;
    that.loans = [];
    that.visibleLoans = [];
    that.showClosed = $routeParams.showClosed === "true";
    that.selectedLoan = undefined;
    that.item = undefined;
    that.selectedTransactionId = undefined;
    $scope.permission = authentication.permissions;
    that.commandAccess = command.access;
    that.isLoanPaymentReceiptActive = false;
    that.depositAccounts = {};
    that.branchPostingDate = null;
    that.$filter = $filter;

    that.loanTableData = [];
    that.columnConfiguration = null;
    that.amortizationTable = new NgTableParams({
      count: that.loanTableData.length
    }, {
      counts: [],
      getData: () => that.loanTableData
    });

    queryParamsRemover.removeQueryOnRedirect($scope, ['showClosed']);

    let customerId = $route.current.params['customerId'];
    that.loanId = $route.current.params['loanId'];

    that.groups = ["DEPOSIT_CASH", "DEPOSIT_CHECK", "TRANSFER_FUNDS", "CREATE_PRODUCT", "UPDATE_STATUS", "APPLY_FEE", "CREATE_PRODUCT", "CREDIT_MEMO"];

    const s = customerCache.profile(customerId).toObservable().subscribe((profile) => {
      if (profile.customerType === 'GROUP') {
        // redirect to group loans
        $location.path(`/customer/${customerId}/group-loans`);
      }

      customerService.redirectWhenProfileIsInvalid(profile);

    });
    const subscription = new Subscription();
    subscription.add(s);

    that.loanIsReleasedButNotClosed = (loan) => {
      return loan && !['PENDING', 'INACTIVE', 'CLOSED'].includes(loan.status);
    };

    customerCache.depositAccounts(customerId).toObservable().first()
      .subscribe(data => {
        that.depositAccounts = _.filter(data, a => a.status === 'ACTIVE');
      });

    modalPrintPreviewService.canReprint('LOAN_PAYMENT_RECEIPT', (active) => {
      that.isLoanPaymentReceiptActive = active;
    });

    that.toggleClosedLoans = () => {
      if (that.showClosed) {
        that.visibleLoans = that.loans;
      } else {
        that.visibleLoans = that.loans.filter(loan => loan.status !== 'CLOSED');
      }

      if (that.selectedLoan) {
        $location
          .path(`/customer/${customerId}/loans/${that.selectedLoan.id}`)
          .search('showClosed', that.showClosed.toString());
      }
    };

    that.transactionClicked = (transaction, $event) => {
      $event.stopPropagation();
      that.selectedTransactionId = transaction.id;
    };

    that.hasZeroOutstandingBalance = loan => {
      if(!loan) {
        return false;
      }

      const totalBalance = _.get(loan, 'amortizationSchedule.totalBalance', {});
      return Object.values(totalBalance).every(balanceValue => balanceValue === 0);
    };

    that.setCellColor = (row, col) => {
      if (col.isStatus) {
        return {
          'red': row.status === 'DUE' || row.status === 'OVERDUE',
          'green': row.status === 'PAID',
          'orange': row.status === 'PARTIALLY_PAID'
        }
      } else if (col.isLate) {
        return { 'red': row.daysLate > 0 && row.status !== 'PAID' }
      } else if (row['No'] === 'Outstanding:' && col.totalCheck) {
        return {
          'text-alert': that.selectedLoan.amortizationSchedule.totalBalance.interest !== that.selectedLoan.interestBalance
        }
      } else if (row['No'] === 'Outstanding:' && col.principalCheck) {
        return {
          'text-alert': that.selectedLoan.amortizationSchedule.totalBalance.principal !== that.selectedLoan.principalBalance
        }
      }
      return null;
    };

    that.addTotal = (config, totalObject, title) => {
      let total = {};

      config.forEach(option => {
        const field = option.field.replace('Amount', '');

        if (typeof totalObject[field] === 'number') {
          total[option.field] = totalObject[field];
        } else if (option.field === 'amortizationAmount') {
          total[option.field] = totalObject['total'];
        } else {
          total[option.field] = null;
        }
      });

      total['No'] = title;
      return total;
    };

    that.prepareAmortizationTable = (schedule) => {
      let scheduleList = schedule.list;

      const showAmount = field => {
        const acceptableFields = ['cbuChargeAmount', 'pfChargeAmount', 'tpChargeAmount'];
        const isFieldIncluded = acceptableFields.includes(field);

        if (!isFieldIncluded) {
          return true;
        }

        return scheduleList
          .map(item => item[field])
          .some(val => val !== 0);
      };

      that.columnConfiguration = [
        { field: "No",                     title: "No",            show: true },
        { field: "dueDate",                title: "Due Date",      show: true, filterName: 'prettyDate' },
        { field: "status",                 title: "Status",        show: true, isStatus: true },
        { field: "datePaid",               title: "Date paid",     show: true, filterName: 'prettyDate' },
        { field: "daysLate",               title: "Days late",     show: true, isLate: true },
        { field: "principalAmount",        title: "Principal",     show: true, filterName: 'php', principalCheck: true },
        { field: "interestAmount",         title: "Interests",     show: true, filterName: 'php', interestCheck: true },
        { field: "cbuChargeAmount",        title: "CBU",           show: true, filterName: 'php' },
        { field: "pfChargeAmount",         title: "PF",            show: true, filterName: 'php' },
        { field: "tpChargeAmount",         title: "TP",            show: true, filterName: 'php' },
        { field: "customFeesAmount",       title: "Custom Fees",   show: true, filterName: 'php' },
        { field: "amortizationAmount",     title: "Total amount",  show: true, filterName: 'php' },
        { field: "loanOutstandingBalance", title: "Balance after", show: true, filterName: 'php' }
      ].map(option => ({...option, show: option.filterName === 'php' ? showAmount(option.field) : true}));

      scheduleList = scheduleList.map((val, index) => ({ ...val, No: index + 1 }))

      scheduleList.push(that.addTotal(that.columnConfiguration, schedule.totalAmount, 'Total:'));
      scheduleList.push(that.addTotal(that.columnConfiguration, schedule.totalBalance, 'Outstanding:'));

      return scheduleList;
    };

    that.selectLoan = (loan) => {
      loan.cbuAccount = _.find(that.depositAccounts, {id: loan.cbuSavingsAccountId});
      loan.pfAccount = _.find(that.depositAccounts, {id: loan.pfSavingsAccountId});
      loan.tpAccount = _.find(that.depositAccounts, {id: loan.tpSavingsAccountId});

      that.selectedLoan = loan;

      $location.path(`/customer/${customerId}/loans/${loan.id}`)
    };

    that.isGrantDateValid = () => {
      return that.branchPostingDate.isSameOrAfter(that.selectedLoan.grantDate);
    };

    that.preTerminateByCash = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/pre-terminate/cash`);
    that.preTerminateByTransfer = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/pre-terminate/transfer`);
    that.createNew = () => $location.path(`/customer/${customerId}/loans/create`);
    that.release = () => $location.path(`/dashboard/miscellaneous-transactions/cashiers-check-creation`);
    that.payInCash = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/cash-payment`);
    that.payByTransfer = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/transfer-payment`);
    that.payInCheck = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/check-payment`);
    that.payByGeneralLedger = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/gl-payment`);

    const revertCommands = {
      'DEPOSIT_CASH': "PayLoanByCashRevert",
      'DEPOSIT_CHECK': "PayLoanByCheckRevert",
      'CREDIT_MEMO': "PayLoanByGLRevert"
    };

    that.revertCommandName = (operation) => {
      // Special cases where operation group is not enough
      if (operation.operationGroup === 'TRANSFER_FUNDS') {
        if (operation.targetCode.includes("CREDIT_CHECK_ON_US")) {
          return "PayLoanByOnUsCheckRevert";
        } else {
          return "PayLoanByTransferRevert";
        }
      }
      return revertCommands[operation.operationGroup]
    };

    that.revertAllowed = (operation) => {
      const commandName = that.revertCommandName(operation);
      return commandName &&
        operation.target.id.toString() === that.loanId &&
        ['PENDING', 'PROCESSED'].includes(operation.status) &&
        revertCommandService.revertAllowed(operation, commandName);
    };

    that.revertPayment = (operation) => confirmation(`Do you want to revert the payment of ${operation.amount} PHP?`, () => {
      let revertCommand = that.revertCommandName(operation);

      command.execute(revertCommand, {operationId: operation.id, commandId: operation.commandId})
        .success(() => {
          notification.show('Successfully reverted payment');
          customerCache.loans(customerId).refetch();
          $route.reload();
        });
    });

    that.paymentReport = () => {
      $location.path(`/customer/${customerId}/loans/${that.loanId}/payment-report`);
    };

    that.amortizationScheduleReport = () => {
      $location.path(`/customer/${customerId}/loans/${that.loanId}/schedule-report`);
    };

    that.statementOfAccount = () => {
      $location.path(`/customer/${customerId}/loans/${that.loanId}/statement`);
    };

    that.promissoryNote = () => {
      $location.path(`/customer/${customerId}/loans/${that.loanId}/promissory-note`)
    };

    that.disclosureStatement = () => {
      window.print();
    };

    that.showPaymentReceipt = (transaction) => {
      modalPrintPreviewService.show('LOAN_PAYMENT_RECEIPT', {operationId: transaction.id});
    };

    that.isPrintReceiptAvailable = (transaction) => {
      if (that.isLoanPaymentReceiptActive && transaction.status === 'PROCESSED') {
        if (['DEPOSIT_CASH', 'DEPOSIT_CHECK'].includes(transaction.operationGroup)) {
          return true;
        } else if (transaction.operationGroup === 'TRANSFER_FUNDS') {
          return transaction.target && transaction.target.id == that.loanId;
        } else if (transaction.operationGroup === 'CREDIT_MEMO') {
          return transaction.target && transaction.target.id == that.loanId;
        }
      };

      return false;
    };

    that.returnCheck = (transaction) => {
      const input = {'id': transaction.checkId};
      command.execute('ReturnLoanCheck', input).success(() => {
        customerCache.loans(customerId).refetch();
      });
    };

    that.showGrantDateErrorNotification = () => {
      notification.show("Error", "Unable to perform release because date granted is in the future.");
    };

    that.releaseByCC = () => {
      if (that.isGrantDateValid()) {
        $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/release/check`);
      } else {
        that.showGrantDateErrorNotification();
      }
    };

    that.deleteLoan = () =>
      confirmation('Do you want to delete the selected loan?', () =>
        command.execute('DeleteLoan', {productId: that.selectedLoan.id}).success(that.refresh));

    that.closeLoan = () =>
      confirmation('Do you want to close the selected loan?', () =>
        command.execute('CloseLoan', {productId: that.selectedLoan.id}).success(that.refresh));

    that.reopenLoan = () =>
      confirmation('Do you want to reopen selected loan?', () =>
        command.execute('ReopenLoan', {productId: that.selectedLoan.id}).success(that.refresh));

    that.refresh = () => {
      customerCache.loans(customerId).refetch();
      $route.reload();
    };

    that.isPartiallyPaid = amortization => {
      if(amortization.status === 'PAID') {
        return false;
      }

      // as earned loan after creation has shadow interests - the specific amount of interests paid depend
      // one the time loan was paid
      // for this case we set interest balance to 0, which results in a discrepancy between amortization balance and
      // amortization amount (the later includes also shadow interests)
      if(!that.selectedLoan.asEarnedInterestCalculation) {
        return amortization.amortizationAmount !== amortization.amortizationBalance;
      }

      const baseBalance = new BigNumber(amortization.amortizationAmount)
        .sub(amortization.interestAmount)
        .sub(amortization.amortizationBalance)
        .plus(amortization.interestBalance);

      if(!baseBalance.isNegative() && !baseBalance.isZero()) {
        return true;
      }

      return amortization.asEarnedInterestAmount !== amortization.asEarnedInterestBalance;
    };

    that.editInformation = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/edit-information`);
    that.editMetadata = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/edit-metadata`);
    that.editAmortization = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/edit-amortization`);
    that.editAutomaticTransferAgreement = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/edit-automatic-transfer`);

    that.waiveLoan = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/waive`);

    that.assignLoanToGroup = () => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/assign-group`);

    that.keyValueDetails = _.memoize(currentTransaction => {

      let baseValueDetails = transactionDetailsBuilder.build(currentTransaction);

      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('Line numbers', currentTransaction.attributes['AMORTIZATION_LINE_NUMBERS']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('Interest amount', currentTransaction.attributes['INTEREST_PAYMENT']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('Principal amount', currentTransaction.attributes['PRINCIPAL_PAYMENT']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('Penalty amount', currentTransaction.attributes['PENALTY_PAYMENT']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('PastDueInterest amount', currentTransaction.attributes['PAST_DUE_INTEREST_PAYMENT']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('CBU amount', currentTransaction.attributes['CBU_PAYMENT']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('TP amount', currentTransaction.attributes['TP_PAYMENT']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('PF amount', currentTransaction.attributes['PF_PAYMENT']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('UID line Number', currentTransaction.attributes['UID_LINE_NUMBER']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('Official receipt', currentTransaction.attributes['OFFICIAL_RECEIPT']));
      baseValueDetails.push(transactionDetailsBuilder.buildIfNotEmpty('Temporary receipt', currentTransaction.attributes['TEMPORARY_RECEIPT']));
      baseValueDetails.push(transactionDetailsBuilder.buildPhpIfNotZero('Outstanding principal balance after', currentTransaction.attributes['OUTSTANDING_PRINCIPAL_BALANCE']));

      baseValueDetails = transactionDetailsBuilder.clean(baseValueDetails);

      return baseValueDetails;
    });

    that.fetchLoans = () => {
      return customerCache.loans(customerId, false).toObservable()
        .combineLatest(loanProductsCache.toObservable(), (loans, loanTypes) =>
          loans.map(loan => {
            // add loanProduct to loan object
            const type = _.find(loanTypes, {id: loan.typeId});
            return Object.assign(loan, {
              loanType: type
            });
          })
        ).combineLatest(branchService.toObservable(), (loans, branches) => {
            loans.map(loan => {
              if (loan.id == that.loanId) {
                let loanBranch = _.find(branches, {id: loan.branchId});
                that.branchPostingDate = postingDateService.getPostingDate(loanBranch);
              }
            });
            return loans;
          }
        ).combineLatest(branchService.toObservable(), (loans, branches) =>
          loans.map(loan => Object.assign(loan, {branch: _.find(branches, {id: loan.branchId})}))
        )
        .combineLatest(userCache.toObservable(), (loans, users) =>
          loans.map(loan => Object.assign(loan, {user: _.find(users, {id: loan.userId})}))
        ).combineLatest(customerCache.customerProductFees(customerId).toObservable(), (loans, customerProductFees) => {
          _.forEach(loans, (loan) => {
            loan.fees = [];
            _.forEach(customerProductFees, (fee) => {
              if (fee.productId === loan.id) {
                loan.fees.push(fee);
              }
            });
          });
          return loans;
        }).combineLatest(feeDefinitionsCache.toObservable(), (loans, feeDefinitions) => {
          _.forEach(loans, (loan) => {
            _.forEach(loan.fees, (fee) => {
              const feeDef = _.find(feeDefinitions, {id: fee.feeDefinitionId});
              fee.feeName = feeDef.feeName;
            })
          });
          return loans;
        }).subscribe(loans => {
            // select first account when none selected
            if (!that.loanId && loans.length > 0) {
              $location.path(`/customer/${customerId}/loans/${loans[0].id}`);
            }

            if (that.loanId) {
              const loan = _.find(loans, (a) => String(a.id) === that.loanId);
              if (loan) {
                that.selectedLoan = loan;

                // mark first unpaid transaction
                for (let a of that.selectedLoan.amortizationSchedule.list) {
                  if (a.status !== 'PAID') {
                    a.currentAmortization = true;
                    break;
                  }
                }
              } else {
                $location.path(`/customer/${customerId}/loans`);
              }
            }

            that.loans = loans;

            if (that.showClosed) {
              that.visibleLoans = loans;
            } else {
              that.visibleLoans = loans.filter(loan => loan.status !== 'CLOSED');

              if (that.selectedLoan && that.selectedLoan.status === 'CLOSED' && that.selectLoan && that.visibleLoans[0]) {
                that.selectLoan(that.visibleLoans[0]);
              } else if (that.selectedLoan && that.selectedLoan.status === 'CLOSED' && that.onlyClosedLoans()) {
                that.showClosed = true;
                that.toggleClosedLoans();
              }
            }

            if (that.selectedLoan && that.selectedLoan.amortizationSchedule.list) {
              that.loanTableData = that.prepareAmortizationTable(that.selectedLoan.amortizationSchedule);
              that.amortizationTable.reload();
            }

            return loans;
          }
        );
    };

    that.onlyClosedLoans = () => {
      return that.loans && that.loans.filter(loan => loan.status !== 'CLOSED').length === 0;
    };

    subscription.add(that.fetchLoans());

    that.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }
});
