import module from 'module';

import templateUrl from './forex-document-type-list.template.html';

class ForexDocumentTypeList {
  constructor(forexDocumentTypeCache, $location) {
    this.forexDocumentTypeCache = forexDocumentTypeCache;
    this.$location = $location;
  }

  async $onInit() {
    this.documentTypes = await this.forexDocumentTypeCache.toPromise();
    this.hasRecords = this.documentTypes && this.documentTypes.length > 0;
  }

  addDocumentType() {
    this.$location.path('/admin/forex/document-types/create');
  }

  updateDocumentType(id) {
    this.$location.path('/admin/forex/document-types/'+id);
  }
}

module.component('forexDocumentTypeList', {
  templateUrl,
  controller: ForexDocumentTypeList
});