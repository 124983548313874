import module from 'module';
import {NOTIFICATION_TEMPLATE_PREVIEW, NOTIFICATION_TEMPLATES} from 'constants/apiUrl';

import templateUrl from './notification-template.template.html';
import './notification-template.style.less'

class NotificationTemplate {
  constructor(http, popup, systemPropertyCache, command, $location) {
    this.$location = $location;
    this.http = http;
    this.popup = popup;
    this.systemPropertyCache = systemPropertyCache;
    this.command = command;
    this.$location = $location;
  }

  async $onInit() {
    const templates = await this.http.get(NOTIFICATION_TEMPLATES).toPromise();
    this.backLink = '/notification/sms-configuration';

    if (this.templateId) {
      this.template = templates.filter(t => !t.isDeleted)
        .find(t => t.id === Number(this.templateId));
    }
  }

  preview() {
    this.http.post(
      NOTIFICATION_TEMPLATE_PREVIEW,
      this.template
    ).success(response => {
      this.popup({
        header: 'Message Preview',
        text: `"${response}"<br>`,
        renderHtml: true
      });
    }).error(response => {
      this.popup({
        header: 'Error generating preview',
        text: `<strong>Details:&nbsp;</strong>${response.errorMessage ? response.errorMessage : "An unknown error occurred."}`,
        renderHtml: true
      });
    });
  }

  upsertLabel() {
    return this.templateId ? 'Update' : 'Create';
  }

  async upsert() {
    await this.command.execute('UpsertNotificationTemplate', this.template).toPromise();
    this.$location.path('/notification/sms-configuration');
  }
}

module.component('notificationTemplate', {
  templateUrl,
  bindings: {
    templateId: '<'
  },
  controller: NotificationTemplate
});
