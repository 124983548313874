import module from 'module';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/toPromise';

module.factory('command', function (http, popup, notification, $location, $filter, commandRoleMapCache, authentication, $q) {
  let service = {};

  /**
   * Executes the command specified as parameter.
   *
   * By default the following callbacks are attached:
   * - on successful command execution -> shows OK notification
   * - on error -> shows error notification
   * - when offline or received timeout -> shows information about offline status
   * - when command needs approval -> notification + redirect to actions/sent-by-me
   *
   * When additional callbacks are attached, the default callbacks are still executed
   * *unless* overrideDefault = true is passed in as 2nd parameter.
   */
  service.execute = (command, request, arg) => {
    let successCallbacks = [];
    let approvalCallbacks = [];
    let errorCallbacks = [];
    let alwaysCallbacks = [];
    let offlineCallbacks = [];

    let commandName = $filter('startCase')(command);

    let defaultSuccessCallback = () => {
      notification.show(`Successfully executed: ${commandName}`);
    };
    let defaultApprovalCallback = () => {
      popup({
        text: `Operation '${commandName}' requires approval. Once the task is approved, the operation will be processed automatically.`,
        callback: () => $location.path('/dashboard/actions/sent-by-me')
      });
    };
    let defaultErrorCallback = (data) => {
      let details = data.errorMessage ? data.errorMessage : "An unknown error occurred.";
      let message = `<strong>Error executing:&nbsp;</strong>'${commandName}'.`;

      if (details) message += `<br><br><strong>Details:&nbsp;</strong>${details}`;
      let finePrint;
      if (data.commandId || data.requestUuid) {
        finePrint = 'Error code: ';
        if (data.commandId) finePrint += data.commandId;
        if (data.commandId && data.requestUuid) finePrint += '/';
        if (data.requestUuid) finePrint += `${data.requestUuid.substring(data.requestUuid.length - 8)}`;
      }
      popup({text: message, renderHtml: true, finePrint: finePrint});
    };

    let defaultOfflineCallback = () => {
      let details = 'Connectivity error. Please check your internet connection.';
      let message = `<strong>Error executing:&nbsp;</strong>${commandName}.`;
      message += `<br><br><strong>Details:&nbsp;</strong>${details}`;

      popup({text: message, renderHtml: true});
    };

    http.post(`/command/${command}`, request, arg)
      .success(response => {
        if (response.approvalRequired) {
          if (defaultApprovalCallback) defaultApprovalCallback();
          processCallbacks(approvalCallbacks, response);
        } else {
          if (defaultSuccessCallback) defaultSuccessCallback();
          processCallbacks(successCallbacks, response);
        }
      }).error(response => {
      if (!response) {
        // offline
        if (defaultOfflineCallback) defaultOfflineCallback(response);
        processCallbacks(offlineCallbacks, response);
      } else {
        // plain old error
        if (defaultErrorCallback) defaultErrorCallback(response);
        processCallbacks(errorCallbacks, response);
      }
    }).always(response => processCallbacks(alwaysCallbacks, response));

    return {
      /**
       *  @deprecated prefer toPromise()
       */
      success: function (callback, overrideDefault = false) {
        if (overrideDefault) defaultSuccessCallback = null;
        successCallbacks.push(callback);
        return this;
      },
      /**
       *  @deprecated prefer toPromise()
       */
      approval: function (callback, overrideDefault = false) {
        if (overrideDefault) defaultApprovalCallback = null;
        approvalCallbacks.push(callback);
        return this;
      },
      /**
       *  @deprecated prefer toPromise()
       */
      error: function (callback, overrideDefault = false) {
        if (overrideDefault) defaultErrorCallback = null;
        errorCallbacks.push(callback);
        return this;
      },
      /**
       *  @deprecated prefer toPromise()
       */
      always: function (callback) {
        alwaysCallbacks.push(callback);
        return this;
      },
      /**
       *  @deprecated prefer toPromise()
       */
      offline: function (callback, overrideDefault = false) {
        if (overrideDefault) defaultOfflineCallback = null;
        offlineCallbacks.push(callback);
        return this;
      },
      toPromise: function (preventDefaults = false) {
        return new $q((resolve, reject) => {

          if (preventDefaults) {
            defaultSuccessCallback = null;
            defaultOfflineCallback = null;
            defaultApprovalCallback = null;
          }

          successCallbacks.push((response) => resolve(response));
          approvalCallbacks.push((response) => resolve(response));
          errorCallbacks.push((response) => reject(response));
          offlineCallbacks.push((response) => reject(response));
        });
      }
    }
  };

  return service;
});


module.factory('commandAccessChecker', (commandRoleMapCache, authentication) => {
  let service = {};

  /**
   * Checks if roles has access to either execute or approve a certain command
   *
   * @param roleMap  - JSON Object containg the commands and allowed users to approve and execute
   * @param accessType   - access type of command to check : 'execute' or 'approve'
   * @param command  - the command to check if a role has an access to
   * @param roleIds - array of roles id
   *
   * @return boolean
   */
  const hasCommandAccess = (roleMap, accessType, command, roleIds) => {

    if (typeof roleIds === 'number' || typeof roleIds === 'string') {
      roleIds = [parseInt(roleIds)];
    }

    if(!roleMap[command]) return false;
    const allowedRoles = roleMap[command][accessType];
    const match = _.intersection(allowedRoles, roleIds);
    return match && match.length > 0;
  };

  /**
   * Function to check if a certain command can be executed by the current user that is logged on.
   * */
  service.canExecuteCommandObservable = () => {
    return commandRoleMapCache.toObservable().map(roleMap => {
      return (command, roleIds = authentication.context.roleIds) => {
        return hasCommandAccess(roleMap, "execute", command, roleIds);
      };
    });
  };

  service.canExecuteCommandPromise = () => {
    return service.canExecuteCommandObservable()
      .first()
      .toPromise();
  };

  /**
   * Function to check if a certain command can be approved by the current user that is logged on.
   * */
  service.canApproveCommandObservable = () => {
    return commandRoleMapCache.toObservable().map(roleMap => {
      return (command, roleIds = authentication.context.roleIds) => {
        return hasCommandAccess(roleMap, "approve", command, roleIds);
      };
    });
  };

  service.canApproveCommandPromise = () => {
    return service.canApproveCommandObservable()
      .first()
      .toPromise();
  };

  return service;

});

/**
 * NOTE: this directive will not work with ng-show on the same element and an error will be thrown when we
 *       attempt to use command-access and ng-show on the same element. ng-if, however, works fine.
 *
 * NOTE2: it does override an effect coming from ng-class, however, it can be fixed with addition of ng-if
 */
module.directive('commandAccess', function (commandRoleMapCache, authentication) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      if (attrs.ngShow) {
        throw `The directive 'command-access' cannot be used together with 'ng-show'. Use 'ng-if' instead.`;
      }

      const commands = attrs.commandAccess;
      if (!commands) {
        throw `The directive 'command-access' is empty.`;
      }

      const commandArray = commands.split(',').map(c => c.trim());

      const roleMapSubscription = commandRoleMapCache.toObservable().subscribe((roleMap => {
        //return the list of allowed users to execute a certain commands
        let canExecuteRoleIds = _.flatten(commandArray.map(command => roleMap[command])
          .filter(commandAccessRules => commandAccessRules && commandAccessRules.execute && commandAccessRules.execute.length > 0)
          .map(commandAccessRules => commandAccessRules.execute));

        const match = _.intersection(canExecuteRoleIds, authentication.context.roleIds);
        let showElement = match && match.length > 0;

        if (showElement) element.show();
        else element.hide();
      }));

      scope.$on('$destroy', () => {
        roleMapSubscription.unsubscribe();
      })

    }
  };
});

function processCallbacks(callbacks, arg) {
  _.each(callbacks, function (cb) {
    cb(arg);
  })
}
