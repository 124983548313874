const templateUrl = require('./loan-pre-terminate-by-cash.template.html');
import BigNumber from 'bignumber.js';
import module from 'module';
import _ from 'lodash';

module.component('loanPreTerminateByCash', {
  templateUrl: templateUrl,
  controller: function ($scope, $location, $route, $filter, customerCache, notification, command, confirmation, loanPreTerminateService) {
    let that = this;

    that.customerId = $route.current.params['customerId'];
    that.loanId = $route.current.params['loanId'];

    that.paymentRequest = {};
    that.waiveRequest = {};
    that.amortizationIds = [];
    that.maxValues;
    that.loan;
    that.title = $filter('uppercase')($filter('translate')('pre-terminate-by-cash', 'LOAN'));

    let subscribe = customerCache.loans(that.customerId).toObservable().subscribe(loans => {
      that.loan = _.find(loans, (l) => l.id == that.loanId);
      that.amortizationIds = _.map(that.loan.amortizationSchedule.list, a => a.id);
      that.maxValues = {...that.loan.amortizationSchedule.totalBalance};
    });

    that.preTerminate = () => {
      var info = {
        total: that.paymentRequest.amount,
        totalWaive: that.waiveRequest.waiveParts.reduce((sum, item) => sum.add(item['waiveAmount'] || 0), new BigNumber(0)).toNumber()
      };

      var message = loanPreTerminateService.getMessage(info);
      confirmation(message, () => {
        command.execute('PreterminateLoanByCash', {
          waiveRequest: that.waiveRequest,
          loanPaymentRequest: that.paymentRequest
        }, {
          nxLoaderText: 'Performing operation...'
        }).success(() => {
          customerCache.loans(that.customerId).refetch();
          that.redirectBack();
        }).offline(() => {
          customerCache.loans(that.customerId).refetch();
          that.redirectBack();
        })
      });
    };

    that.redirectBack = () => $location.path(`/customer/${that.customerId}/loans/${that.loanId}`);

    that.$onDestroy = () => {
      subscribe.unsubscribe();
    };
  }
});