import _ from 'lodash';

export const determineAllowedGoldFinenessValues = (metalRates, allowedGoldFinenessValuesProperty) => {
    let allowedGoldFinenessValues = allowedGoldFinenessValuesProperty.value.split(",");

    // Remove all the golds from the master list.
    let goldList = _.remove(metalRates, (k) => {return k.name === "Gold"});

    // Add the missing Gold karat values.
    allowedGoldFinenessValues.forEach(karat => {
      const doesKaratExist = _.find(goldList, {fineness: karat});
      if (!doesKaratExist) {
        const missingKaratObject = {
          fineness: karat,
          metalTypeIds: goldList[0].metalTypeIds,
          name: "Gold",
          pricePerGram: 0,
          orderNo: null,
          overridden: false,
          type: goldList[0].type,
          systemDate: goldList[0].systemDate
        };

        goldList.push(missingKaratObject);
      }
    });

    // Order by fineness.
    goldList = _.sortBy(goldList, (f) => parseInt(f.fineness.replace('k', '')));

    // Recalculate the order number.
    let count = 10000;
    goldList.forEach(f => {
      f.orderNo = count;
      count += 1000;
    });

    // Combine the two lists and group by name.
    return metalRates = _.concat(goldList, metalRates);
}