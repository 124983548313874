import module from 'module';

class ExchangeRateService {
  constructor(exchangeRateFormulaCache, forexCache, authentication) {
    this.exchangeRateFormulaCache = exchangeRateFormulaCache;
    this.forexCache = forexCache;
    this.authentication = authentication;

    this.refetch();
  }

  async refetch() {
    this.allExchangeRates = await this.forexCache.withParam(this.authentication.context.branchId).toPromise();
    this.rawFormulas = await this.exchangeRateFormulaCache.toPromise();
  }

  getFormulas(category, currencyId) {
    return this.rawFormulas
      .filter(formula => formula.forexTransactionCategory === category)
      .filter(formula => Number(formula.currencyUnit.currencyId) === Number(currencyId))
  }

  currentBranchExchangeRates(currencyId) {
    return this.allExchangeRates.exchangeRatesInBranches[this.authentication.context.branchId]
      .find(exchangeRate => Number(exchangeRate.currency.id) === Number(currencyId));
  }

  /**
   * Calculates exchangeRate depending on denomination bundle
   */
  getExchangeRate(currencyId, foreignDenomination, transactionCategory) {
    this.refetch();

    return transactionCategory === 'SELL' ? this.currentBranchExchangeRates(currencyId).sellRate
      : this.currentBranchExchangeRates(currencyId).buyRate;
  }
}

module.service('exchangeRateService', ExchangeRateService);