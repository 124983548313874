import module from 'module';
import $ from 'jquery';

import templateUrl from './pawn-ticket.template.html';
import './pawn-ticket.style.less';

module.component('customerPawnTicket', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, nav, printService, $timeout) {
    let that = this;

    let customerId = $route.current.params['customerId'];
    let pawnId = $route.current.params['pawnId'];

    that.redirectBack = () => nav.back();

    $timeout(() => {
      http.post(`/print/PAWN_TICKET/json`, {pawnId: pawnId}).success(doc => {
        that.doc = doc;

        return printService.printToContainer(doc, {
          autoPrint: false,
          backgroundEnabled: true,
          container: $('.iframe-preview')
        });
      });
    });

    that.print = () => printService.printToContainer(that.doc, {
      autoPrint: true,
      backgroundEnabled: false,
      container: $('.iframe-invisible')
    });

  }
});