import module from 'module';
import _ from 'lodash';
import $ from 'jquery';

const templateUrl = require('./lookup-customer-group.template.html');
module.component('lookupCustomerGroup', {
  templateUrl: templateUrl,
  controller: function (http, $timeout, $location, $filter, authentication,
                        branchService, branchWorkingDayCache, requestFormatter, systemPropertyCache, lookupHelper) {
    let that = this;

    // only fetch n results
    that.resultLimit = 50;

    that.defaultUrl = '/customer/{}/group-loans';

    that.branchNames = {};

    that.searchParams = {
      customerNumber: null,
      customerName: null,
      groupName: null,
      productNumber: null,
      resultLimit: that.resultLimit
    };
    that.searchResults = null;
    that.overflow = false;
    that.searchPerformed = false;
    that.createCustomerEnabled = false;
    that.contextBranchId = authentication.context.branchId;

    that.showProductMask = false;

    const systemPropertiesSub = systemPropertyCache.toObservable().subscribe(properties => {
      // Load product number mask (if present it should be applied to UI)
      const productNumberMask = _.find(properties, {code: 'PRODUCT_NUMBER_MASK'});
      if (productNumberMask && productNumberMask.value) {
        that.productNumberMask = productNumberMask.value;
        that.productNumberMaskSaved = that.productNumberMask;
        that.showProductMask = true;
      }
    });

    that.switchProductMask = () => {
      if (that.showProductMask) {
        that.productNumberMask = that.productNumberMaskSaved;
      } else {
        if (that.searchParams.productNumber) {
          that.searchParams.productNumber = lookupHelper.removeSpecialCharsFromMask(that.searchParams.productNumber, that.productNumberMaskSaved);
        }
        that.productNumberMask = null;
      }
    };

    const branchSub = branchService.toObservable().subscribe(branches => {
      return branches.forEach(b => that.branchNames[b.id] = b.name);
    });

    const workingDaySub = branchWorkingDayCache.withParam(that.contextBranchId).toObservable().subscribe(workingDay => {
      that.workingDay = workingDay;
    });

    /**
     * Request validation is organization type dependent
     */
    that.validSearchRequest = () => {
      return that.searchParams.customerNumber
        || that.searchParams.customerName
        || that.searchParams.groupName
        || that.searchParams.productNumber;
    };

    that.emptyResults = function () {
      return that.searchResults !== null && that.searchResults.length === 0;
    };

    that.showResults = function () {
      return that.searchPerformed && !that.emptyResults();
    };

    that.nullifyParams = function (searchParams) {
      _.each(searchParams, function (value, key) {
        if (value != null && $.trim(value) == '') {
          searchParams[key] = null;
        }
      });
    };

    /**
     * Customer creation is available if:
     *
     * 1. User has appropriate permission
     * 2. Working day is STARTED
     * 3. Customer lookup was already performed
     * 4. Search was performed using customer name
     *
     * @return true if new customer can be created
     */
    const setupCustomerCreation = () => {
      const hasPermission = authentication.permissions['NEW_CUSTOMER_WRITE'];

      that.createCustomerEnabled = hasPermission && that.searchPerformed === true;
    };

    const countMembersWithActiveLoans = (results) => {
      for (let r of results) {
        r.membersWithActiveLoans = _.filter(r.members, m => m.activeLoanCount > 0).length;
      }
    };

    that.search = function () {
      that.nullifyParams(that.searchParams);

      let queryParams = requestFormatter.convertToQueryParams(that.searchParams);
      http.get(`/customers/group${queryParams}`, {nxLoaderText: 'Searching for customer...'})
        .success(function (data) {
          that.searchPerformed = true;
          that.searchResults = data;
          setupCustomerCreation();
          countMembersWithActiveLoans(data);
        });
    };

    that.openCustomerProduct = (redirectionUrl) => {
      if (redirectionUrl) {
        $location.path(redirectionUrl);
      }
    };

    that.openCustomer = (customerId) => {
      let redirectionUrl = that.defaultUrl.replace('{}', customerId);
      $location.path(redirectionUrl);
    };

    /** Redirects to new group loan */
    that.createGroup = () => {
      $location.path('/customer/group-loans/create');
    };

    that.showInlinePanel = (result, $event) => {
      $event.stopPropagation();
      that.selectedGroupCustomerId = result.customerId;
    };

    that.withActiveLoans = (members) => _.filter(members, m => m.activeLoanCount > 0);
    that.withoutActiveLoans = (members) => _.filter(members, m => m.activeLoanCount === 0);

    that.$onDestroy = () => {
      branchSub.unsubscribe();
      workingDaySub.unsubscribe();
      systemPropertiesSub.unsubscribe();
    };
  }
});
