import module from 'module';
import _ from 'lodash';
const templateUrl = require('./undo-close-counters.template.html');

module.component('undoCloseCounters', {
  templateUrl,
  controller: function (branchService, confirmation, $filter, $route, popup, command) {
    const that = this;

    that.command = {};

    const branchSub = branchService.toObservable().subscribe(branches => {
      that.branches = branches;
    });

    that.execute = () => {
      const branchId = that.command.branchId;
      const branch = _.find(that.branches, b => b.id === branchId);
      const closedCounterDate = $filter('apiDate')(that.command.closedCounterDate);
      confirmation(`Do you want to undo CLOSE COUNTERS?<br><br>
          <strong>Branch</strong>: ${branch.name}<br>
          <strong>Date</strong>: ${$filter('prettyDate')(that.command.closedCounterDate)}
          <br><br>
          <strong class="red">WARNING: This action cannot be reverted.</strong>
      `, () => {
        command.execute('UndoCloseCounterCommand', {branchId, closedCounterDate})
          .success(count => popup({
            text: `Undo CLOSE COUNTERS performed successfully.`,
            callback: () => $route.reload()}
          ), true);
      }, () => {}, true);
    };

    that.$onDestroy = () => {
      branchSub.unsubscribe();
    };

  }
});
