import module from "module";
import $ from "jquery";

const templateUrl = require('./report-modal.template.html');

module.component('reportModalComponent', {
  templateUrl,
  bindings: {
    reportContent: '<',
    reportCode: '<',
    params: '<',
    onClose: '&',
    hideXls: '<'
  },
  controller: function (http, reportService) {
    const that = this;

    that.close = () => {
      $('#reportModal').modal('hide');
      if (that.onClose) that.onClose();
    };

    that.print = () => {
      window.print();
    };

    that.downloadXls = () => {
      http.http({
        url: `/reports/${that.reportCode}/xls`,
        method: 'POST',
        responseType: 'blob',
        data: $.param(that.params),
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        nxLoaderText: 'Downloading report XLS'
      })
        .success(response => {
          const xlsxFileUrl = window.URL.createObjectURL(response);
          const a = document.createElement('a');
          a.href = xlsxFileUrl;
          a.download = `${that.reportContent[0].reportName}.xlsx`;
          a.click();
          window.URL.revokeObjectURL(xlsxFileUrl);
        }).error(data => reportService.handleResponseError(data))
    };

  }
});


module.factory('reportModal', function ($rootScope, $compile, $timeout, http) {

  const fetchReportAndDisplayModal = ({reportCode, params, onClose, hideXls}) => {
    http.http({
      url: `/reports/${reportCode}/json`,
      method: 'POST',
      responseType: 'json',
      data: $.param(params),
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    }).success(reportContent => {
      showModal(reportContent, reportCode, params, onClose, hideXls);
    });

  };

  function showModal(reportContent, reportCode, params, onClose, hideXls) {
    let scope = $rootScope.$new();
    scope.reportContent = reportContent;
    scope.params = params;
    scope.reportCode = reportCode;
    scope.hideXls = hideXls;
    scope.closeModal = () => {
      $('#reportModalComponent').remove();
      if (onClose) onClose();
    };
    $('body').append($compile('<report-modal-component id="reportModalComponent" report-code="reportCode" hide-xls="hideXls" report-content="reportContent" params="params" on-close="closeModal()"/>')(scope));
    $timeout(() =>
      $('#reportModal').modal({
        keyboard: false,
        backdrop: 'static'
      }));
  }

  return {
    display: fetchReportAndDisplayModal
  }

});