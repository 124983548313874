import module from 'module';


module.component('tokenValidityCheck', {
  controller: function ($timeout, http) {
    let lastTimeout = null;
    const checkTokenValidity = () => {
      // internet can be slow, so schedule next token checking shortly after receiving previous response
      http.head('/auth-tokens/check-validity', {nxLoaderSkip: true,})
        .always(() => {
          lastTimeout = $timeout(checkTokenValidity, 5 * 1000);
        });
    };

    checkTokenValidity();

    this.$onDestroy = () => {
      if (lastTimeout) {
        $timeout.cancel(lastTimeout);
      }
    }

  }
});
