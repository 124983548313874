import module from "module";
import _ from "lodash";
import angular from "angular";
import BigNumber from 'bignumber.js';

const templateUrl = require('./pawn-partial-redemption.template.html');
module.component('customerPawnPartialRedemption', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, customerCache, pawnItemTypeCache, confirmation, command) {
    this.banks = [];
    this.changed = true;
    this.selectedItem = {};

    // form parameters
    this.remainingAmount = null;
    // additional partial principal to be paid
    this.partialPaymentAmount = 0;
    // new pawn owner
    this.newOwner = null;
    // partial redemption command input object
    this.commandInput = {
      changeOwner: false,
      newOwnerId: null,
      productId: null,
      itemIds: null,
      partialPaymentAmount: null,
      amount: null,
      denominationBundle: null
    };
    this.itemsValue = 0;
    const customerId = $route.current.params['customerId'];
    const pawnId = Number($route.current.params['pawnId']);

    customerCache.pawns(customerId).toObservable().subscribe((pawns) => {
      this.pawn = angular.copy(_.find(pawns, (l) => l.id === pawnId));
      this.items = _.filter(this.pawn.items, i => (i.status === 'PAWNED_ACTIVE' || i.status === 'PAWNED_EXPIRED'));
      // Prepare command input object
      this.commandInput.productId = this.pawn.id;
    });

    const s = pawnItemTypeCache.toObservable().subscribe((types) => {
      this.typeMap = {};
      _.forEach(types, (type) => {
        this.typeMap[type.id] = type.name;
      });
    });

    this.getSelectedItems = () => {
      return _.filter(this.items, i => i.selected);
    };

    this.refreshSummary = async () => {
      this.calculation = null;

      const selectedItems = this.getSelectedItems();
      this.itemsValue = selectedItems.map(it => it.valuation)
        .reduce((acc, curVal) => new BigNumber(acc).add(curVal).toNumber(), 0);
      this.partialPaymentAmount = this.itemsValue;

      await this.calculate();
    };

    this.inputValid = () => {
      if (!this.pawn) {
        return false;
      }

      const selectedCount = _.filter(this.items, i => i.selected).length;
      const hasSelected = selectedCount > 0 && selectedCount < this.items.length;
      const validForPartialPayment =
        this.partialPaymentAmount >= this.itemsValue && this.partialPaymentAmount <= this.pawn.principalBalance;

      return hasSelected && validForPartialPayment;
    };

    this.setNewOwner = (customer) => {
      if (customer) {
        this.newOwner = customer;
        this.commandInput.newOwnerId = customer.customerId;
      }
    };

    this.removeNewOwner = () => {
      this.newOwner = null;
      this.commandInput.newOwnerId = null;
    };

    this.redirectBack = () => $location.path(`/customer/${customerId}/pawns/${pawnId}`);

    this.paymentChanged = () => {
      this.principalBalance = '-';
      this.changed = true;
    };

    this.calculate = async () => {
      const itemsIds = this.getSelectedItems().map(i => i.id);
      if (_.isEmpty(itemsIds)) {
        this.calculation = null;
        return;
      }

      const calculationRequest = {
        itemIds: itemsIds,
        partialPaymentAmount: this.partialPaymentAmount
      };

      this.calculation = await http.post(`/products/pawns/${pawnId}/partial-redemption/calculate`, calculationRequest).toPromise();
      this.principalBalance = this.calculation.principalBalance;
      this.commandInput.amount = this.calculation.total;
      this.commandInput.itemIds = itemsIds;
      this.commandInput.partialPaymentAmount = this.partialPaymentAmount;

      this.changed = false;
    };

    this.showAppraisalAmountComparison = () => {
      return this.calculation && this.calculation.currentAppraisalAmount !== this.selectedItem.effectiveValuation;
    }

    this.redeem = async () => {
      const confirmed = await confirmation(`Do you want to perform partial redemption (${this.calculation.total} PHP)?`);
      if (!confirmed) {
        return;
      }

      const response = await command.execute('PartialRedeemPawn', this.commandInput).toPromise();
      if (response.approvalRequired) {
        return;
      }

      customerCache.pawns(customerId).refetch();
      $location.path(`/customer/${customerId}/pawns/${response.id}`);
    };

    this.$onDestroy = () => {
      s.unsubscribe();
    };
  }
});