import module from 'module';
import _ from 'lodash';

const templateUrl = require('./loan-product-general.template.html');
module.component('loanProductGeneralForm', {
  templateUrl,
  bindings: {
    loanProduct: '=',
    form: '='
  },
  controller: function (depositAccountService) {
    const that = this;

    that.assignmentStrategyRequired = false;

    depositAccountService.toObservable().subscribe(data => {
      let cbuTypes = _.filter(data, {purpose: 'CONTRACTUAL_SAVINGS_CBU'});
      let pfTypes = _.filter(data, {purpose: 'CONTRACTUAL_SAVINGS_PF'});
      let tpTypes = _.filter(data, {purpose: 'CONTRACTUAL_SAVINGS_TP'});

      that.cbuTypes = cbuTypes.map(accountTypeMapper);
      that.pfTypes = pfTypes.map(accountTypeMapper);
      that.tpTypes = tpTypes.map(accountTypeMapper);
    });

    that.assignmentStrategies = [
      {
        value: 'ALWAYS_CREATE_NEW',
        label: 'CS_ALWAYS_CREATE_NEW'
      }, {
        value: 'ASSIGN_OR_CREATE',
        label: 'CS_ASSIGN_OR_CREATE'
      }, {
        value: 'ERROR_WHEN_MISSING',
        label: 'CS_ERROR_WHEN_MISSING'
      }
    ];

    that.selectContractualSavings = () => {
      that.assignmentStrategyRequired = that.loanProduct.cbuAccountTypeId || that.loanProduct.pfAccountTypeId || that.loanProduct.tpAccountTypeId;
    };

    function accountTypeMapper(account) {
      return {label: account.productDefinition.productName, value: account.id}
    }

  }
});
