import module from 'module';

const templateUrl = require('./charge.template.html');
module.component('charge', {
  templateUrl,
  bindings: {
    charge: '=',
    types: '<',
    disabled: '<'
  },
  controller: function () {

  }
});
