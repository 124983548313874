import module from 'module';

module.directive('validValues', function() {
  return {
    scope: {
      validValues: '<'
    },
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attributes, ngModel) {
      scope.$watch('validValues', () => {
        if (scope.validValues) {
          var values = angular.isArray(scope.validValues)
            ? scope.validValues
            : Object.keys(scope.validValues);
          ngModel.$validators.validValues = function (modelValue) {
            return values.indexOf(modelValue) !== -1;
          }
          //force validation
          ngModel.$validate();
        }
      });
    }
  }
});
