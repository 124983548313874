import module from 'module';

const templateUrl = require('./push-failed-eod.template.html');

module.component('pushFailedEod', {
  templateUrl,
  controller: function (confirmationTemplate, popup, http, $filter, $route, rolesCache, branchService) {
    const that = this;

    branchService.toObservable()
      .first()
      .subscribe(branches => that.branches = branches);

    that.execute = () => {
      confirmationTemplate({
        question: `Do you want to push failed end of day for branch ${this.branch.name}?`,
        yesCallback: () => http.post(`/batch-jobs/push-eod/${this.branch.id}`).success((ret) => {
          popup({text:`Pushed failed branch eod`, callback: () => $route.reload()});
        })
      })
    };

  }
});
