import module from 'module';
import _ from 'lodash';
import BigNumber from "bignumber.js";

// Deprecated - Marked for removal
const templateUrl = require('./create-interbranch-cashier-check.template.html');
module.component('createInterbranchCashierCheck', {
  templateUrl: templateUrl,
  controller: function ($filter, authentication, branchService, userCache, actionCommand, actionCategoryCache, breadcrumbs) {

    const that = this;

    that.transaction = {
      units: [{}]
    };

    // Complete list of system users
    that.systemUsers = [];
    // List of branches excluding branch of logged user
    that.branches = [];
    // List of users from target branch
    that.users = [];
    // Config for transaction-units component to selectize categories
    that.selectConfig = {
      placeholder: 'Select category',
      searchField: ['name', 'ledgerAccountFullCode'],
      valueField: 'ledgerAccountFullCode',
      labelField: 'name',
      maxItems: 1
    };


    that.onTargetBranchChange = (targetBranchId) => {
      that.receiver = null;
      that.users = _.filter(that.systemUsers, {branchId: targetBranchId, phantom: false});
      // If there is only 1 user -> select it
      if (that.users && that.users.length === 1) {
        that.receiver = _.head(that.users).id;
      }
    };

    that.transactionName = $filter('translate')('CREATE INTERBRANCH CASHIER CHECK', 'MISC_TRANSACTION');

    _.set(breadcrumbs, 'options', {
      'Create interbranch cashier check': that.transactionName,
    });

    const categoriesSub = actionCategoryCache.toObservable().subscribe(categories => {
      that.categories = _.filter(categories, {'actionType': 'CREATE_INTERBRANCH_CASHIER_CHECK_IN'});
      // It there is only 1 category -> select it
      if (that.categories && that.categories.length === 1) {
        that.category = _.head(that.categories);
      }
    });

    const branchSub = branchService.toObservable()
      .combineLatest(userCache.toObservable(), (branches, users) => {
        that.systemUsers = users;
        return branches;
      }).subscribe(branches => {
        const authBranchId = authentication.context.branchId;
        that.transaction.sourceBranchId = authBranchId;
        that.sourceBranch = _.find(branches, {id: authBranchId});
        that.branches = _.filter(branches, (b) => Number(b.id) !== authBranchId);
        // If there is only 1 branch -> select it
        if (that.branches && that.branches.length === 1) {
          const targetBranchId = _.head(that.branches).id;
          that.transaction.targetBranchId = targetBranchId;
          that.onTargetBranchChange(targetBranchId);
        }
      });

    const bigSumBy = (collection, itemProperty) => {
      return collection.reduce((sum, item) => sum.add(item[itemProperty] || 0), new BigNumber(0));
    };

    that.calculateDebitSum = () => {
      return bigSumBy(that.transaction.units, 'amount');
    };

    that.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    that.save = () => {
      let clonedTransaction = angular.copy(that.transaction);

      clonedTransaction.units.forEach(u => u.entryType = 'DEBIT');
      clonedTransaction.purpose = 'REGULAR_WITHDRAWAL';
      clonedTransaction.payee = that.receiver.fullName;
      clonedTransaction.amount = Number(this.calculateDebitSum());
      clonedTransaction.receiverId = that.receiver.id;

      actionCommand.execute('CREATE_INTERBRANCH_CASHIER_CHECK', clonedTransaction);
    };

    that.$onDestroy = () => {
      categoriesSub.unsubscribe();
      branchSub.unsubscribe();
    }
  }
});
