import module from 'module';

const templateUrl = require('./cashier-cash-overage.template.html');
module.component('cashierCashOverage', {
  templateUrl: templateUrl,
  controller: function (actionCommand) {
    this.transaction = {};

    this.denominationIsValid = () => {
      return this.transaction.denominationBundle != null && this.transaction.denominationValid;
    };

    this.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    this.save = () => {
      actionCommand.execute('CASHIER_CASH_OVERAGE', this.transaction);
    }
  }
});
