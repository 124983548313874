import module from 'module';
import _ from 'lodash';

const templateUrl = require('./post-outgoing-check.template.html');

module.component('postOutgoingCheck', {
  templateUrl: templateUrl,
  controller: function ($filter, $location, command, confirmationTemplate, depositoryAccountCache) {

    const that = this;
    that.accounts = [];
    that.depositoryAccountId = undefined;
    that.input = {};

    that.accountSelectConfig = {
      placeholder: 'Select depository account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };

    const s1 = depositoryAccountCache.toObservable().subscribe(data => {
      that.accounts = [];
      if (data && data.length > 0) {
        that.accounts = _.filter(data, d => d.clearing)
      }
    });

    that.onAccountChange = () => {
      // Reset account-related input properties
      that.input.brstn = null;
      that.input.accountNumber = null;
      // Find depository account & setup properties
      const account = _.find(that.accounts, a => Number(a.id) === Number(that.depositoryAccountId));
      if (account) {
        that.input.brstn = account.brstn;
        that.input.accountNumber = account.accountNumber;
      }
    };

    that.redirectBack = () => $location.path('/inventory/checks');

    that.postCheck = () => {
      confirmationTemplate({
        question: `Do you want to post the manual inward check?`,
        details: [
          {label: 'Check number', description: that.input.checkNumber},
          {label: 'BRSTN', description: that.input.brstn},
          {label: 'Account number', description: that.input.accountNumber},
          {label: 'Amount', description: $filter('php')(that.input.amount)}
        ],
        yesCallback: () => {
          command.execute('RegisterInwardCheck', that.input).success(() => {
            that.redirectBack()
          })
        }
      });
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
    };
  }
});