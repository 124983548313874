import module from 'module';
import _ from 'lodash';
import {determineAllowedGoldFinenessValues} from './metals.utils';

const templateUrl = require('./stocked-item-metal-rates.template.html');

class StockedItemMetalRates {
  constructor(http, branchService, stockedItemMetalRateCache, notification, confirmation, authentication, systemPropertyCache, command) {
    this.http = http;
    this.branchService = branchService;
    this.stockedItemMetalRateCache = stockedItemMetalRateCache;
    this.notification = notification;
    this.confirmation = confirmation;
    this.authentication = authentication;
    this.systemPropertyCache = systemPropertyCache;
    this.command = command;
  }

  async $onInit() {
    this.readOnly = !this.authentication.permissions['PWN_METAL_RATE_WRITE'];

    const [
      branches,
      stockedItemMetalRates,
      systemProperties
    ] = await Promise.all([
      this.branchService.toPromise(),
      this.stockedItemMetalRateCache.toPromise(),
      this.systemPropertyCache.toPromise()
    ]);

    const allowedGoldFinenessValuesProperty = _.find(systemProperties, {code: 'ALLOWED_GOLD_FINENESS_VALUES'});

    stockedItemMetalRates = determineAllowedGoldFinenessValues(stockedItemMetalRates, allowedGoldFinenessValuesProperty);
    this.metalTypeMap = _.groupBy(stockedItemMetalRates, 'name');
    this.userBranch = _.find(branches, {id: this.authentication.context.branchId});
  }

  save() {
    this.confirmation('Do you want to update stocked item metal prices?', async () => {
      // Convert pawn metal rate map into flat array.
      let flatRates = [];
      for(let group of Object.keys(this.metalTypeMap)) {
        if (this.metalTypeMap.hasOwnProperty(group)) {
          flatRates = _.union(flatRates, this.metalTypeMap[group]);
        }
      }

      // Set metal rate [systemDate] to current branch date.
      _.forEach(flatRates, (rate) => {
        rate.systemDate = this.userBranch.postingDate;
      });

      // Update stocked item pawn metal rates.
      this.http.put(`/products/pawns/metal-rates`, flatRates, {nxLoaderText: 'Updating stocked item metal prices.'})
      .success(() => {
        this.notification.show("Success", "Stocked item metal prices updated successfully.");
        this.stockedItemMetalRateCache.refetch();
      })
      .error(() => {
        this.notification.show("Error", "Failed to update metal prices.");
        this.stockedItemMetalRateCache.refetch();
      });
    });
  }
}

module.component('stockedItemMetalRates', {
  templateUrl,
  controller: StockedItemMetalRates
});