import module from 'module';

module.factory('interestBoardService', function () {

  const service = {};

  const filterBoardsByRateType = (boards, rateType) => {
    if (!boards || boards.length < 1 || !rateType) return null;
    return _.filter(boards, b => b.rateType === rateType);
  };

  const createRateRange = (rateType, minRate, maxRate) => {
    return {
      rateType: rateType,
      minRate: minRate || 0,
      maxRate: maxRate || 0
    };
  };

  /**
   * Returns string containing formatted interest board rate range.
   * For example if min rate = 0.6 max rate = 5.25 this method will
   * return: '0.6% - 5.25%'. If min rate = max rate there will be
   * single percentage value returned like '2.25%'
   *
   * For invalid interest boards method returns '-'
   *
   * @param board input interest board
   */
  service.formatInterestRateRange = (board) => {

    if (!board || !board.cells || board.cells.length < 1) return '-';
    const rates = _.map(_.filter(board.cells, c => c.rate !== null), 'rate');
    if (!rates || rates.length < 1) return '-';

    const minRate = _.min(rates);
    const maxRate = _.max(rates);

    return minRate === maxRate ? `${minRate}%` : `${minRate}% - ${maxRate}%`;
  };

  service.formatInterestRateRangeForRateType = (boards, rateType = 'BASIC') => {

    const filtered = filterBoardsByRateType(boards, rateType);
    if (!filtered || filtered.length === 0) return '-';

    return service.formatInterestRateRange(filtered[0]);
  };

  /**
   * Calculates interest rate range for given term. Since the product
   * balance is not given service cannot tell the precise interest rate.
   * This method is used during term deposit creation when only the term
   * is known and the amount depends on the placements performed later on.
   *
   * @param boards interest rate boards array
   * @param term product term
   * @param rateType interest rate type
   */
  service.calculateInterestRateRange = (boards, term, rateType = 'BASIC') => {

    const filtered = filterBoardsByRateType(boards, rateType);
    if (!filtered || filtered.length === 0) return createRateRange(rateType);

    const cells = _.filter(filtered[0].cells, c => (c.minTerm || 0) <= term && (!c.maxTerm || c.maxTerm < term));
    const rates = _.map(cells, 'rate');
    if (!rates || rates.length < 1) return createRateRange(rateType);

    return createRateRange(rateType, _.min(rates), _.max(rates));
  };

  /**
   * Searchs boards to match given criteria then returns interest rate.
   * If boards doesnt met criteria default interest rate is returned.
   *
   * @param boards interest rate boards array
   * @param term product term
   * @param balance total balance
   * @param rateType interest rate type
   * @returns interestRate
   */
  service.filterInterestRate = (boards, term, balance, rateType = 'BASIC') => {
    
    const board = filterBoardsByRateType(boards, rateType);
    if (!board && board.length === 0) return null;
    
    const cells = board[0].cells
      // If minTerm || 0 is smaller or maxTerm is greater or maxTerm doesnt exists
      .filter(c => (c.minTerm || 0) <= term && (!c.maxTerm || c.maxTerm > term))
      // If minBalance is smaller/equal or maxBalance is greater or doesnt exists
      .filter(c => (c.minBalance || 0) <= balance && (!c.maxBalance || c.maxBalance > balance));

    return cells.length === 1 ? cells[0].rate : board[0].defaultRate;
  }

  return service;
});
