import module from 'module';
import {pastDueInterestChargeTypes} from "constants/loan";

const templateUrl = require('./loan-product-amortization.template.html');
module.component('loanProductAmortizationForm', {
  templateUrl,
  bindings: {
    loanProduct: '=',
    form: '='
  },
  controller: function ($scope, $timeout, $filter) {
    const that = this;

    this.intervalTypes = [{
      label: 'Daily',
      value: 'DAILY'
    }, {
      label: 'Weekly',
      value: 'WEEKLY'
    }, {
      label: 'Fortnightly',
      value: 'FORTNIGHTLY'
    }, {
      label: 'Every 30 days',
      value: 'EVERY_30_DAYS'
    }, {
      label: 'Monthly',
      value: 'MONTHLY'
    }, {
      label: 'Semimonthly (1.X & 16.X)',
      value: 'SEMIMONTHLY_1_16'
    }, {
      label: 'Semimonthly (15.X & 30.X)',
      value: 'SEMIMONTHLY_15_30'
    }, {
      label: 'Quarterly',
      value: 'QUARTERLY'
    }, {
      label: 'Semiannual',
      value: 'SEMIANNUAL'
    }, {
      label: 'Yearly',
      value: 'YEARLY'
    }, {
      label: 'Single Payment',
      value: 'SINGLE_PAYMENT'
    }];

    this.interestCalculationMethods = [{
      label: 'Simple',
      value: 'SIMPLE'
    }, {
      label: 'Compound',
      value: 'COMPOUND'
    }, {
      label: 'Advance',
      value: 'ADVANCE'
    }];

    this.simpleAmortizationTypes = [{
      label: 'Straight',
      value: 'STRAIGHT'
    }, {
      label: 'Balloon',
      value: 'BALLOON'
    }, {
      label: 'Balloon (cyclic)',
      value: 'BALLOON_CYCLIC'
    }, {
      label: 'Balloon (spread)',
      value: 'BALLOON_SPREAD'
    }];

    this.advanceAmortizationTypes = [{
      label: 'UID Ledger',
      value: 'UID_LEDGER'
    }];

    this.roundingModeTypes = [{
      label: 'System default',
      value: null
    }, {
      label: 'Centavo',
      value: 'CENTAVO'
    }, {
      label: 'Half Peso',
      value: 'HALF_PESO'
    }, {
      label: 'One Peso',
      value: 'ONE_PESO'
    }];

    this.straightAmortizationTypes = [{
      label: 'Straight',
      value: 'STRAIGHT'
    }];

    this.compoundAmortizationTypes = [{
      label: 'Balloon (cyclic)',
      value: 'BALLOON_CYCLIC'
    }, {
      label: 'Balloon (spread)',
      value: 'BALLOON_SPREAD'
    }, {
      label: 'Diminishing w/ equal amortization',
      value: 'DIMINISHING_EQUAL_AMORTIZATION'
    }, {
      label: 'Diminishing w/ equal amortization exact days',
      value: 'DIMINISHING_EQUAL_AMORTIZATION_EXACT_DAYS'
    }, {
      label: 'Diminishing w/ equal principal',
      value: 'DIMINISHING_EQUAL_PRINCIPAL'
    }];

    this.paymentDirections = [{
      label: 'Horizontal',
      value: 'HORIZONTAL'
    }, {
      label: 'Vertical',
      value: 'VERTICAL'
    }];

    this.amortizationDivisors = [{
      label: '100',
      value: 100
    }, {
      label: '357',
      value: 357
    }, {
      label: '360',
      value: 360
    }, {
      label: '364',
      value: 364
    }, {
      label: '365',
      value: 365
    }];

    this.accrualPeriod = [{
      label: 'Daily',
      value: 'DAILY'
    }, {
      label: 'Monthly',
      value: 'MONTHLY'
    }];

    // charge types available for past due interest
    this.pastDueInterestChargeTypes = pastDueInterestChargeTypes.map(value => ({
      label: $filter('prettyEnum')(value),
      value,
    }));

    this.setDefaultInterestCalculationMethod = () => {
      const method = this.loanProduct.interestCalculationMethod;
      this.loanProduct.amortizationType = ['ADVANCE'].includes(method)
        ? this.advanceAmortizationTypes[0].value
        : null;

      this.loanProduct.defaultInterestCalculationParameter = null;
      this.loanProduct.asEarnedInterestCalculation = false;
    };

    function addEvents(bag) {
      $scope.$on(bag + '.drag', function (e, el) {
        el.removeClass('ex-moved');
      });

      $scope.$on(bag + '.drop', function (e, el) {
        that.form.$setDirty();
        el.addClass('ex-moved');
      });

      $scope.$on(bag + '.over', function (e, el, container) {
        container.addClass('ex-over');
      });

      $scope.$on(bag + '.out', function (e, el, container) {
        container.removeClass('ex-over');
      });
    }

    $timeout(() => {
      addEvents('dueHierarchy');
      addEvents('advanceHierarchy');
    })
  }
});
