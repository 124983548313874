import module from 'module';
import _ from 'lodash';

import './new-pawn-details.style.less';
const templateUrl = require('./new-pawn-details.template.html');

const PAWN_COMMENTS_MAX_LENGTH = 4096;

class CustomerPawnCreateDetails {
  constructor($route, $location, http, customerCache, pawnProductsCache, $filter, confirmationTemplate,
              notification, newPawnService, command, branchService, authentication, $scope, popup) {
    this.$route = $route;
    this.$location = $location;
    this.http = http;
    this.customerCache = customerCache;
    this.pawnProductsCache = pawnProductsCache;
    this.$filter = $filter;
    this.confirmationTemplate = confirmationTemplate;
    this.notification = notification;
    this.newPawnService = newPawnService;
    this.command = command;
    this.branchService = branchService;
    this.authentication = authentication;
    this.$scope = $scope;
    this.popup = popup;
  }

  async $onInit() {
    this.customerId = Number(this.$route.current.params['customerId']);
    this.pawnTypeId = Number(this.$route.current.params['pawnTypeId']);

    this.customerPawnsBaseUrl = `/customer/${this.customerId}/pawns`;

    const [pawnProducts, branches] = await Promise.all([this.pawnProductsCache.toPromise(), this.branchService.toPromise()]);
    this.pawnProduct = _.find(pawnProducts, p => p.id === this.pawnTypeId);
    this.branch = branches.find(br => br.id === this.authentication.context.branchId);

    this.$scope.$watch('$ctrl.items', async () => {
      if (!this.items || !this.pawnProduct || !this.pawn) {
        return;
      }

      this.updatePawnDescription();
      this.validateAppraisals(this.items);
      await this.fetchCalculatedPawn();
    });

    this.$scope.$watch('$ctrl.calculatedPawn', async () => {
      if (!this.calculatedPawn) {
        this.calculatedMaxAmountLowerInterestRate = null;
        this.pawn.tagId = null;
        this.pawn.interestRate = this.pawnProduct.defaultInterestRate;
        this.pawn.eirRate = this.pawnProduct.defaultEirRate;
        return;
      }

      this.pawn.interestRate = this.calculatedPawn.interestRate;
      this.pawn.eirRate = this.calculatedPawn.eirRate;
    });

    this.fetchItems();
    this.initializePawn();
  }

  fetchItems() {
    if (!this.customerCache.loadedCustomerId) {
      this.customerCache.pawns(this.customerId);
    }
    this.items = this.newPawnService.getAppraisals();
  }

  initializePawn() {
    this.pawn = {
      customerId: this.customerId,
      pawnTypeId: this.pawnProduct.id,
      interestRate: this.pawnProduct.defaultInterestRate,
      eirRate: this.pawnProduct.defaultEirRate
    };

    // If pawn type supports tagging & default tag is set -> copy it to pawn
    if (this.pawnProduct.defaultInterest && this.pawnProduct.supportedPawnTagIds && this.pawnProduct.defaultTag) {
      this.pawn.tag = this.pawnProduct.defaultTag;
    }
  }

  /**
   * Check if valuation of at lest one stone is higher than system valuation.
   * If stone valuation was altered -> display warning
   */
  validateAppraisals(items) {
    this.stoneValueExceeded = false;
    // Filter pawn items with stones
    if (items && items.length > 0) {
      const itemsWithStones = _.filter(items, (i) => i.stones && i.stones.length > 0);
      // Map items to collection of stones
      const stones = _.flatten(_.map(itemsWithStones, 'stones'));
      // Filter only stones with valuation
      const valuableStones = _.filter(stones, s => s.valuation > 0 && s.systemValuation > 0);
      // Check if at least one of stones is valued higher than system computation
      if (_.some(valuableStones, s => s.valuation > s.systemValuation)) {
        this.stoneValueExceeded = true;
      }
    }
  }

  updatePawnDescription() {
    this.pawn.comments = '';
    if (this.items && this.items.length > 0) {
      const descriptions = _.map(this.items, (i) => i.autoDescription);
      for (let i = 0; i < descriptions.length; i++) {
        this.pawn.comments = this.pawn.comments + "(" + (i + 1) + ") " + descriptions[i] + "\n";
      }
      this.pawn.comments = this.pawn.comments.substr(0, PAWN_COMMENTS_MAX_LENGTH - 1);
    }
  }

  async fetchCalculatedPawn() {
    const request = {
      ...this.newPawnService.createPawnRequest(this.pawnProduct, this.pawn, this.items),
      principalAmount: this.newPawnService.calculateAppraisalAmount(this.items)
    };

    if (!request.itemIds || !request.principalAmount) {
      this.calculatedPawn = null;
      return;
    }

    try {
      this.calculatedPawn = await this.newPawnService.calculatePawnPromise(request, this.pawnProduct);
      this.blockParametersTab = false;
    } catch (error) {
      this.calculatedPawn = null;
      this.blockParametersTab = true;
      this.popup({
        header: 'Error',
        text: error.errorMessage
      });
    }
  }

  redirectBack() {
    this.$location.path(`${this.customerPawnsBaseUrl}/create`);
  }

  openAddAppraisalForm() {
    this.$location.path(`${this.customerPawnsBaseUrl}/create/${this.pawnTypeId}/new-appraisal`)
  }

  async open() {
    if (this.calculationNeedsUpdate) {
      this.notification.show("Error", "Please update pawn computation.");
      return;
    }

    const request = this.newPawnService.createPawnRequest(this.pawnProduct, this.pawn, this.items);

    const confirmed = await this.confirmationTemplate({
      question: `Do you want to create the pawn?`,
      details: [
        {label: 'Loan amount', description: this.$filter('php')(request.principalAmount)},
        {label: 'Interest', description: this.$filter('percent')(request.interestRate)},
        {label: 'Net cash amount', description: this.$filter('php')(this.calculatedPawn.releaseAmount)}
      ]
    });

    if (!confirmed) {
      return;
    }

    this.command.execute('CreatePawn', request)
      .success((pawnResponse) => {
        const pawnId = pawnResponse.output.id;
        this.customerCache.pawns(this.customerId).refetch();
        this.newPawnService.removeAll();
        this.$location.path(`${this.customerPawnsBaseUrl}/${pawnId}`);
      })
      .approval(async () => {
        this.newPawnService.removeAll();
      })
      .error(async () => {
        // Check if any pawn items made it through (i.e. has productId)
        // If so, we remove them on the list
        const itemIds = request.itemIds.join(',');
        const pawnItems = await this.http.get(`/products/pawns/items?ids=${itemIds}`).toPromise();
        const boundedItemIds = pawnItems.filter(i => i.productId).map(i => i.id);
        this.newPawnService.removeIds(boundedItemIds);

        this.customerCache.pawns(this.customerId).refetch();
        if (!_.isEmpty(boundedItemIds)) {
          this.$route.reload();
        }
      });
  }
}

module.component('customerPawnCreateDetails', {
  templateUrl: templateUrl,
  controller: CustomerPawnCreateDetails
});
