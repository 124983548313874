import module from 'module';
import _ from 'lodash';

const templateUrl = require('./check-deposit.template.html');
module.component('customerAccountsCheckDeposit', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, dict, customerCache, productDefinitionService, confirmation,
                        casaProductPrintService, command) {
    let that = this;

    that.checkModel = {};

    let customerId = $route.current.params['customerId'];
    that.productId = $route.current.params['accountId'];

    customerCache.depositAccounts(customerId).toObservable()
      .combineLatest(productDefinitionService.toObservable(), (depositAccounts, products) =>
          depositAccounts.map(da => {
            const p = _.find(products, {id: da.definitionId});
            return Object.assign(da, {
              productName: p ? p.productName : '<Unknown product>'
            });
          })
        // that.productId is a string
      ).subscribe(accounts => that.depositAccount = _.find(accounts, (a) => String(a.id) === that.productId));


    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${that.productId}`);

    that.deposit = () => {
      confirmation(`Do you want to deposit ${$filter('php') (that.checkModel.amount)}?`, () => {
        command.execute('DepositCheckToAccount', that.checkModel,
          {nxLoaderText: 'Depositing funds...'}).success((res) => {
            const operationId = res.output.operationId;
            customerCache.depositAccounts(customerId).refetch();
            casaProductPrintService.printFor('DEPOSIT_ACCOUNT', customerId, that.productId, operationId).finally(() => that.redirectBack());
          });
      });
    };
  }
});
