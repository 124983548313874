import module from 'module';
import moment from 'moment';
import _ from 'lodash';
import {NgTableParams} from 'ng-table/ng-table.js';
import {NOTIFICATION_SCHEDULES, NOTIFICATION_TEMPLATES} from 'constants/apiUrl';

import templateUrl from './notification-schedules.template.html';
import './notification-schedules.style.less';

class NotificationSchedules {
  constructor(confirmation, popup, command, $filter, http, branchServiceHelper) {
    this.confirmation = confirmation;
    this.popup = popup;
    this.command = command;
    this.$filter = $filter;
    this.http = http;
    this.branchHelperService = branchServiceHelper;
    this.allTemplates = [];
  }

  async $onInit() {
    this.initializeTableConfig();
    [this.branches, this.allTemplates] = await Promise.all([
      this.branchHelperService.getOperatingBranches(),
      this.http.get(NOTIFICATION_TEMPLATES).toPromise()
    ]);

    this.enabledTemplates = this.allTemplates.filter(t => !t.deleted);

    this.minSendOnDate = moment().toDate();
    this.pawnStatuses = [
      {
        id: 'ACTIVE',
        label: 'Active'
      },
      {
        id: 'PAST_DUE_PERFORMING',
        label: 'Past due'
      },
      {
        id: 'PAST_DUE_NON_PERFORMING',
        label: 'Expired'
      }
    ];
  }

  initializeTableConfig() {
    this.tableConfig = new NgTableParams({
      count: 20
    }, {
      counts: [],
      paginationMinBlocks: 3,
      paginationMaxBlocks: 8,
      getData: params => this.fetchSchedule(params)
    })
  }

  async fetchSchedule(params) {
    //retrieve list
    const queryParams = {
      pageNo: params.page() - 1,
      pageSize: params.count()
    }
    const response = await this.http.get(`${NOTIFICATION_SCHEDULES}?${$.param(queryParams, true)}`).toPromise();
    this.schedules = response.result.map(s => ({
        ...s,
        branchIds: this.convertBranchIdsForUI(s.branchIds)
    }));
    this.tableConfig.total(response.totalCount);
    return this.schedules;
  }

  convertBranchIdsForDB(uiBranchIds) {
    if (uiBranchIds && uiBranchIds[0] === null) {
      return null;
    }
    return uiBranchIds;
  }

  convertBranchIdsForUI(dbBranchIds) {
    if (dbBranchIds === null) {
      return [null];
    }
    return dbBranchIds;
  }

  isTemplateDeleted(templateId) {
    const template = this.allTemplates.find(t => t.id === templateId);
    return (template || {}).deleted;
  }

  async add() {
    this.tableConfig.page(1);
    await this.tableConfig.reload();
    const initSchedule = {
      sendOn: this.minSendOnDate,
      branchIds: null,
      pawnReleaseFrom: null,
      pawnReleaseTo: null,
      pawnStatus: 'PAST_DUE_PERFORMING',
      processedOn: undefined,
      inputMode: true
    }
    this.schedules.unshift(initSchedule);
  }

  edit(index) {
    this.schedulesCopy = angular.copy(this.schedules);
    this.schedules[index].inputMode = true;
  }

  cancel(index) {
    if (this.schedules[index].id) {
      this.schedules[index] = this.schedulesCopy[index];
      this.schedules[index].inputMode = false;
    } else {
      this.schedules.shift();
    }
  }

  getForm(index) {
    this.synchronizedForm();
    return this[`form_${index}`];
  }

  isSaveDisabled(index) {
    return this.getForm(index).$invalid;
  }

  synchronizedForm() {
    let hasUndefined = false;
    for (let i = 0; i < this.schedules.length; i++) {
      if (!this[`form_${i}`] || hasUndefined) {
        hasUndefined = true;
        this[`form_${i}`] = this[`form_${i + 1}`];
      }
    }
  }

  getRequest(schedule) {
    const request = {
      command: 'CreateNotificationSchedule',
      params: {
        sendOn: this.$filter('nxDate')(schedule.sendOn),
        templateId: schedule.templateId,
        branchIds: this.convertBranchIdsForDB(schedule.branchIds),
        pawnReleaseFrom: this.$filter('nxDate')(schedule.pawnReleaseFrom),
        pawnReleaseTo: this.$filter('nxDate')(schedule.pawnReleaseTo),
        pawnStatus: schedule.pawnStatus
      }
    };

    if (schedule.id) {
      request.params.id = schedule.id;
      request.params.status = schedule.status;
      request.command = 'UpdateNotificationSchedule';
    }

    return request;
  }

  async save(index) {
    if (!this.getForm(index).$valid) {
      return;
    }

    const request = this.getRequest(this.schedules[index]);
    const holidays = await this.http.post('/management/holidays/search', {
      branchIds: request.params.branchIds,
      types: ['HOLIDAY'],
      date: request.params.sendOn
    }).toPromise();

    if (!_.isEmpty(holidays)) {
      this.popup({
        header: 'Error',
        text: `At least one selected branch has a holiday on ${request.params.sendOn}. Please try another date.`
      });
      return;
    }

    const confirmation = await this.confirmation('Do you want to save schedule?');
    if (!confirmation) {
      return;
    }

    const result = await this.command.execute(request.command, request.params).toPromise();
    if (!result) {
      return;
    }
    this.tableConfig.reload();
  }

  async delete(index) {
    if (!this.getForm(index).$valid) {
      return;
    }
    const confirmation = await this.confirmation('Do you want to delete schedule?');
    if (!confirmation) {
      return;
    }
    const schedule = this.schedules[index];
    const result = await this.command.execute('DeleteNotificationSchedule', {id: schedule.id}).toPromise();
    if (!result) {
      return;
    }

    this.tableConfig.page(1);
    this.tableConfig.reload();
  }

  hasRecords() {
    return this.schedules && this.schedules.length > 0;
  }
}

module.component('notificationSchedules', {
  templateUrl,
  controller: NotificationSchedules
})
