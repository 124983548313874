import module from 'module';
import _ from 'lodash';

const templateUrl = require('./term-deposit-list.template.html');
module.component('termDepositList', {
  templateUrl,
  controller: function (termDepositsService, interestBoardService) {

    const that = this;

    const s1 = termDepositsService.toObservable().subscribe(data => {
      that.termDeposits = data ? data : [];
      if (that.termDeposits.length > 0) {
        _.forEach(that.termDeposits, d => {
          d.interestRange = interestBoardService.formatInterestRateRangeForRateType(d.interestBoards);
        })
      }
    });

    that.$onDestroy = () => {
      s1.unsubscribe();
    }
  }
});
