import module from 'module';

class AttendanceService {
  constructor(http, command) {
    this.http = http;
    this.command = command;
  }

  log(photoId, action) {
    return this.command.execute('LogAttendance', {photoId: photoId, action: action}).toPromise();
  }

  getLog(userId, date) {
    return this.http.get(`/attendance/logs?userId=${userId}&logDate=${date}`).toPromise();
  }
}

module.service('attendanceService', AttendanceService);
