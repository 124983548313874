import _ from 'lodash';
import 'rxjs/add/operator/map';

import module from 'module';
import { mergedLedger, defaultHeaderLabels, flattenSubaccounts } from '../common/gl.utils';
import { updateRefreshTime } from 'components/common/refresh-button/refresh-button.component';
const templateUrl = require('./gl-account-details.template.html');


module.component('glAccountDetails', {
  templateUrl,
  controller: function($scope, $routeParams, $interval,
                       glLedgerService, branchService, glAccountService, breadcrumbs, notification) {
    const self = this;
    self.ledgerId = parseInt($routeParams.accountId);
    self.header = [];

    self.refetchAccounts = () => {
      glAccountService.fetchAccounts(self.ledgerId)
        .success(data => {
          self.accounts = flattenSubaccounts(data)
            .map(account => Object.assign(account, {
              hasSubaccounts: !!account.subaccounts,
              collapsed: !!account.subaccounts,
              visible: account.accountDepth === 1,
              accountGroupShort: account.accountGroup.substring(0,2)
            }));
          notification.show("Accounts loaded successfully");
          updateRefreshTime($scope);
        })
        .error(err => {
          notification.show("Error", "An error occurred during loading of accounts.");
        });
    };

    const dataUpdates =  mergedLedger({
      glLedgerService,
      branchService,
      accountId: self.ledgerId
    })
      .subscribe(
        ledger => {
          _.set(breadcrumbs, 'options', {
            'gl-account-details-label': ledger.template.name,
          });

          self.header = defaultHeaderLabels(ledger);
        }
      );

    self.$onDestroy = () => {
      dataUpdates.unsubscribe();
    };

    const collapseSubtree = account => {
      account.collapsed = true;
      (account.subaccounts || []).forEach(a => {
        a.visible = false;
        collapseSubtree(a);
      });
    };

    self.toggleCollapsed = (account) => {
      if(!account.collapsed) {
        collapseSubtree(account);
      } else {
        account.collapsed = false;
        (account.subaccounts || []).forEach(a => a.visible = true);
      }
    };
  }
});
