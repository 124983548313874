import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('exchangeRateFormulaCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/forex/exchange-rate-formulas'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.forex,
    cacheName: 'exchangeRateFormula'
  })
);
