import module from 'module';
import _ from 'lodash';
import moment from "moment";

const templateUrl = require('./pawn-renewal.template.html');
module.component('customerPawnRenewal', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, customerCache, confirmation, notification, branchService,
                        authentication, workingDaysCache, pawnTagCache, popup, newPawnService, command, pawnProductsCache) {
    const that = this;
    that.banks = [];
    that.currentBranch = authentication.context.branchId;

    // form parameters
    that.remainingAmount = null;
    // renewal command object
    that.commandInput = {
      productId: null,
      pawnTypeId: null,
      amount: null,
      denominationBundle: null,
      targetBranchId: null
    };

    const customerId = $route.current.params['customerId'];
    const pawnId = $route.current.params['pawnId'];

    that.$onInit = async () => {
      const [pawns, tags, workingDays, branches, pawnProducts] = await Promise.all([
        customerCache.pawns(customerId).toPromise(),
        pawnTagCache.toPromise(),
        workingDaysCache.toPromise(),
        branchService.toPromise(),
        pawnProductsCache.toPromise()
      ]);

      that.tags = tags;
      that.pawn = _.find(pawns, l => l.id == pawnId);
      that.commandInput.pawnTypeId = that.pawn.typeId;

      await that.calculate();
      that.commandInput.productId = that.pawn.id;
      that.interbranch = that.currentBranch !== that.pawn.branchId;

      that.currentBranchWorkingDay = _.find(workingDays, {branchId: that.currentBranch});
      that.pawnBranchWorkingDay = _.find(workingDays, {branchId: that.pawn.branchId});
      that.currentBranchName = _.find(branches, {id: that.currentBranchWorkingDay.branchId}).name;
      that.pawnBranchName = _.find(branches, {id: that.pawnBranchWorkingDay.branchId}).name;
      that.hasDifferentPostingDates = !moment(that.currentBranchWorkingDay.postingDate).isSame(moment(that.pawnBranchWorkingDay.postingDate));

      const originalPawnType = _.find(pawnProducts, pawnProduct => pawnProduct.id === that.pawn.typeId);
      that.pawnProducts = _.filter(pawnProducts, pawnProduct => _.find(originalPawnType.supportedRenewalPawnTypeIds, renewalId => pawnProduct.id == renewalId));
    }

    that.calculate = async () => {
      try {
        that.calculation = await http.post(`/products/pawns/${pawnId}/renewal/${that.commandInput.pawnTypeId}/calculate`).toPromise();
        that.commandInput.amount = that.calculation.total;
        that.supportedPawnTagIds = newPawnService.getSupportedTagIds(that.calculation.tagId, that.tags);
      } catch (error) {
        popup({
          header: 'Error',
          text: error.errorMessage
        });
      }
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/pawns/${pawnId}`);

    that.renew = async () => {
      if (that.interbranch) {
        that.commandInput.targetBranchId = that.currentBranch;
      }

      const proceed = await confirmation(`Do you want to renew the pawn by cash (${that.calculation.total} PHP)?`);
      if (!proceed) {
        return;
      }

      const isPawnRenewedOnTheSameDay = moment(that.pawn.createdOn).isSame(moment(that.pawnBranchWorkingDay.postingDate));
      if (isPawnRenewedOnTheSameDay) {
        const proceedSameDayRenewal = await confirmation(`You are renewing a pawn that was just created (${that.pawn.createdOn}). Do you want to continue?`);
        if (!proceedSameDayRenewal) {
          return;
        }
      }

      const response = await command.execute('RenewPawn', that.commandInput).toPromise();
      if (response.approvalRequired) {
        return;
      }

      customerCache.pawns(customerId).refetch();
      newPawnService.removeAll();
      $location.path(`/customer/${customerId}/pawns/${response.output.id}`);
    };
  }
});
