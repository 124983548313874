import module from 'module';
import _ from 'lodash';

class RefreshService {
  constructor(customerCache, groupCustomerCache) {
    this.customerCache = customerCache;
    this.groupCustomerCache = groupCustomerCache;
  }

  clearCache() {
    const whitelist = ['token', 'cmc', 'nx__nonvolatile__'];

    Object.keys(localStorage).forEach((key) => {
      const whiteWordsFound = whitelist.filter(value => key.indexOf(value) >= 0);
      const isRemovable = _.isEmpty(whiteWordsFound);
      if (isRemovable) {
        localStorage.removeItem(key);
      }
    });

    this.customerCache.evictWithoutCustomerId();
    this.groupCustomerCache.evictWithoutCustomerId();
  }

  refreshPage({clearCache = true, clearToken = false}) {
    if (clearCache) {
      this.clearCache();
    }

    if (clearToken) {
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');
    }

    window.location.reload(true);
  }
}

module.service('refreshService', RefreshService);