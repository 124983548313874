import module from 'module';
import $ from 'jquery';

import './amortization-preview.component';

const templateUrl = require('./loan-preview.template.html');
module.component('loanPreview', {
  templateUrl: templateUrl,
  bindings: {
    loan: '<'
  },
  controller: function ($scope, $timeout) {

    $scope.$watch('$ctrl.loan', () => {
      if (!this.loan) return;

      $timeout(() => {
        let loanPreview = $('loan-preview');
        $('html,body').animate({scrollTop: loanPreview.offset().top});
      });
    });

  }
});