import module from "module";
import './tag.style.less'

import templateUrl from './pawn-tag-list.template.html';

class PawnTagList {
  constructor(pawnTagCache, confirmation, command, $route, pawnTagService) {
    this.pawnTagCache = pawnTagCache;
    this.confirmation = confirmation;
    this.command = command;
    this.$route = $route;
    this.pawnTagService = pawnTagService;
  }

  async $onInit() {
    const [tags, permittedTagIds] = await Promise.all([this.pawnTagCache.toPromise(), this.pawnTagService.getPermittedTagIds()]);
    this.tags = tags.filter(t => permittedTagIds.includes(t.id));
  }

  add() {
    this.tags.push({inputMode: true});
  }

  cancel() {
    this.tags.pop();
  }

  getForm(index) {
    return this[`form_${index}`];
  }

  isSaveDisabled(index) {
    return this.getForm(index).$invalid;
  }

  async save(index) {
    this.getForm(index).$setSubmitted();
    if(this.getForm(index).$valid) {
      const confirmation = await this.confirmation('Do you want to save pawn tag?');
      if (!confirmation) {
        return;
      }
      const input = this.tags[index];
      const result = await this.command.execute("CreatePawnTag", {
        name: input.name,
        description: input.description
      }).toPromise();
      if (!result) {
        return;
      }
      this.pawnTagCache.refetch();
      this.tags[index].inputMode = false;
    }
  }
}

module.component('pawnTagList', {
  templateUrl,
  controller: PawnTagList
});
