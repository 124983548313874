import angular from 'angular';
import ngAnimate from 'angular-animate';
import _ from 'lodash';
import ngRoute from 'angular-route';
import ngSanitize from 'angular-sanitize'
import ngCurrency from 'ng-currency';
import moment from 'moment';
import 'angular-selectize2';
import { ngTableModule } from 'ng-table/ng-table.js';
import routesInit from './app.routes.js'
import ngBreadcrumbs from 'angular-breadcrumbs';
import angularCache from 'angular-cache';
import angularMoment from 'angular-moment';
import ngFileUpload from 'ng-file-upload';
import uiMask from 'angular-ui-mask';
import dragula from 'angularjs-dragula';
import 'angular-moment-picker';
import uiValidate from 'angular-ui-validate';
import ngInfiniteScroll from 'ng-infinite-scroll';
import checklistModel from 'checklist-model';
import angularGoogleAnalytics from 'angular-google-analytics';

import 'angular-moment-picker/dist/angular-moment-picker.css';

/**
 * This file defines the module and configures it at a "pre-construct" stage.
 * All post-construct initialization should happen in the components (preferred) or app.js (if there's no other way)
 */
// TODO: Extract the routes into app.routes.js as in: http://stackoverflow.com/questions/34913594/angularjs-app-module-vs-app-route
// TODO: switch to angular-ui-router
export default angular.module('next-bank', [
  ngRoute, ngSanitize, 'selectize', 'angular-table', ngCurrency, ngTableModule.name,
  ngBreadcrumbs, angularCache, angularMoment, uiMask, dragula(angular), ngFileUpload,
  'moment-picker', uiValidate, ngAnimate, ngInfiniteScroll, checklistModel, angularGoogleAnalytics
])
  .config(($controllerProvider, $locationProvider, $routeProvider, $httpProvider) => {
    // TODO allow global controller variables. Eventually this should be changed:
    // TODO https://docs.angularjs.org/guide/migration#migrating-from-1-2-to-1-3
    $controllerProvider.allowGlobals();

    // routing
    $locationProvider.hashPrefix('!');
    routesInit($routeProvider);
    configureHttpDateFormatter($httpProvider);
    $httpProvider.interceptors.push('httpLoadingInterceptor');
    $httpProvider.interceptors.push('cmcHeaderInterceptor');
  }).config(momentPickerProvider => {
      momentPickerProvider.options({
          format:        'LL',
          minView:       'year',
          maxView:       'day',
          startView:     'day',
          autoclose:     true,
          today:         false,
          keyboard:      true,
          leftArrow:     '&larr;',
          rightArrow:    '&rarr;',
          yearsFormat:   'YYYY',
          monthsFormat:  'MMM',
          daysFormat:    'D',
      });
  }).config($animateProvider => {
    $animateProvider.classNameFilter(/angular-animate/);
  }).config($compileProvider => {
    $compileProvider.preAssignBindingsEnabled(true);
    if(process.env.NODE_ENV === 'production') {
      $compileProvider.debugInfoEnabled(false);
    }
  })
  .config((AnalyticsProvider, config) => {
    AnalyticsProvider.delayScriptTag(true);
    AnalyticsProvider.trackPages(true);
    AnalyticsProvider.setAccount({
      tracker: config.googleAnalyticsAccount,
      fields: {
        siteSpeedSampleRate: 100
      }
    });
  })
  .run($q => {
    // use angularjs promises to get change detection
    window.Promise = $q;
  });

/**
 * HTTP interceptor: Goes through all http requests that are being sent and converts dates to their local timezones.
 * Example: 2017-03-20T23:00:00.000Z -> 2017-03-21T00:00:00+01:00
 *
 * Both formats are correct, but the backend would parse the first one as 2017-03-20.
 * Therefore the 2nd format must be used.
 */
function configureHttpDateFormatter($httpProvider) {
  $httpProvider.interceptors.push([
    () => {
      return {
        request: function (request) {
          var convertDates = function (obj) {
            var key, results, type, value;
            results = [];
            for (key in obj) {
              value = obj[key];
              type = typeof value;
              if (value instanceof Date && _.get(Object.getOwnPropertyDescriptor(obj, key), 'writable')) {
                const momentDate = moment(value.getTime());
                results.push(obj[key] = momentDate.format());
              } else if (type === 'object') {
                results.push(convertDates(value));
              } else {
                results.push(void 0);
              }
            }
            return results;
          };
          convertDates(request.data);
          return request;
        }
      };
    }
  ])
}
