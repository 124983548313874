import module from "module";
import _ from 'lodash';
import "rxjs/add/observable/of";
import "rxjs/add/operator/combineAll";
import {updateRefreshTime} from 'components/common/refresh-button/refresh-button.component';

const templateUrl = require('./pawn-mass-withdrawal.template.html');
module.component('pawnMassWithdrawal', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $location, http, nav, confirmation, notification, pawnStatusMapper) {
    let that = this;
    that.pawnStatusMapper = pawnStatusMapper;

    that.redirectBack = () => nav.back();

    that.refresh = () => {
      let branchId = 1;
      http.get(`/products/pawns/past-auction-date?branchId=${branchId}&includeAmortization=false&includeItems=true`)
        .success(data => {
          that.pawns = data
          that.pawns.forEach(p => p.selected = true);
          updateRefreshTime($scope);
        });

    };

    that.refresh();

    that.withdraw = () => confirmation('Do you want to withdraw the selected pawns?', () => {

        let pawnIds = _
          .filter(that.pawns, p => p.selected)
          .map(p => p.id);

        http.post(`/products/pawns/mass-withdrawal`, pawnIds).success(() => {
          notification.show('Pawns withdrawn successfully.');
          that.refresh();
        });
      }
    )
  }
});
