import _ from 'lodash';

export const mergedLedgers = ({glLedgerService, branchService}) =>
  glLedgerService.toObservable()
    .combineLatest(branchService.toObservable(), (ledgers, branches) =>
      ledgers.map(ledger => Object.assign(ledger, {
          branch: _.find(branches, branch => branch.id === ledger.branchId)
        })
      ));


export const mergedLedger = ({glLedgerService, branchService, accountId}) =>
  mergedLedgers({glLedgerService, branchService})
    .map(ledgers =>
      _.find(ledgers, ledger => ledger.id === accountId)
    );

export const mergedLedgerPromise = async ({glLedgerService, branchService, accountId}) => {
  const ledgers = await mergedLedgersPromise({glLedgerService, branchService});
  return _.find(ledgers, ledger => ledger.id === accountId);
};

export const mergedLedgersPromise = async ({glLedgerService, branchService}) => {
  const [ledgers, branches] = await Promise.all([glLedgerService.toPromise(), branchService.toPromise()]);
  return ledgers.map(ledger => Object.assign(ledger, {
    branch: _.find(branches, branch => branch.id === ledger.branchId)
  }));
}

export const flattenSubaccounts = (roots) =>
  _.flatten(roots.map(root => [root, ...(flattenSubaccounts(root.subaccounts || []))]));


export const defaultHeaderLabels = ledger => [{
    label: 'General ledger name',
    text: ledger.template.name,
  },{
    label: 'Branch name',
    text: ledger.branch.name,
  },{
    label: 'Branch code',
    text: ledger.branch.code,
  }];


/**
 * Enriches GL accounts with selectLabel in format: [AG] name (fullCode)
 */
export const addAccountLabels = accounts => accounts.map((a) => {
  let s = '';
  if (a.accountGroup) s += `[${a.accountGroup.substring(0, 2)}] `;
  s += `${a.name} (${a.fullCode})`;
  a.label = s;
  return a;
});
