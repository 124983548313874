import module from 'module';
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import 'rxjs/add/operator/combineLatest';

import './cash-termination.style.less';

const templateUrl = require('./cash-termination.template.html');
module.component('customerTermDepositCashTermination', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, command, http, customerCache, productDefinitionService, confirmation,
                        notification, modalPrintPreviewService, feeDefinitionsCache, casaProductPrintService) {
    let that = this;
    that.termDeposit = {};

    let customerId = $route.current.params['customerId'];
    let depositId = ~~$route.current.params['depositId'];

    // Interest forecast
    that.recalculationRequired = false;
    that.interestForecast = {};
    that.terminationAmount = null;

    customerCache.termDeposits(customerId).toObservable()
      .map(deposits => _.find(deposits, {id: depositId}))
      .combineLatest(productDefinitionService.toObservable(), (deposit, products) => {
        return Object.assign(deposit, {
          "productDefinition": _.find(products, {id: deposit.definitionId})
        });
      }).subscribe(deposit => {
        that.termDeposit = deposit;
        that.readInterestForecast(deposit.id);
      });

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${depositId}`);

    // Fetch term deposit interest forecast
    that.readInterestForecast = (depositId) => {
      const input = {mode: 'TERMINATION'};

      Promise.all([http.post(`/products/deposits/${depositId}/interest`, input).toPromise(),
                  feeDefinitionsCache.toPromise()])
        .then(([forecast, feesDef]) => {
          //decorate fee with name
          forecast.terminationFees.forEach(f => {
            let fd = feesDef.find(fd => fd.id === f.feeDefinitionId);
            if (fd) f.name = fd.feeName;
          });

          that.interestForecast = forecast;

          that.terminationAmount = that.calculateTerminationAmount();
        })
        .catch(() => {notification.show("Error", "Failed to load interest forecast.")});
    };

    that.toggleTerminationFeeForEdit = (fee) => {
      if (fee.override) {
        fee.newAmount = fee.amount;
      }
    };

    that.calculateTerminationAmount = () => {
      let terminationAmount = new BigNumber(0)
        .plus(that.termDeposit.balance)
        .plus(that.interestForecast.netInterest);
      //subtract termination fee
      that.interestForecast.terminationFees.forEach(f => {
        const amount = f.override ? f.newAmount : f.amount;
        terminationAmount = terminationAmount.sub(amount);
      });

      return terminationAmount.round(2).toNumber();
    }

    that.recalculateForecast = () => {
      that.terminationAmount = that.calculateTerminationAmount();
    };

    that.terminationFeeChanged = () => {
      that.terminationAmount = that.calculateTerminationAmount();
    };

    that.terminate = () => {
      confirmation(`Do you want to withdraw ${$filter('php') (that.terminationAmount)} and terminate deposit?`, () => {
        const terminationFees = that.interestForecast.terminationFees.map(f => {
          let fee = Object.assign({}, f);
          if (fee.override) {
            fee.amount = fee.newAmount;
          }
          fee.newAmount = null;
          fee.override = null;
          return fee;
        });
        command.execute('CashDepositPretermination', {
          amount: that.terminationAmount,
          entryType: "DEBIT",
          productId: depositId,
          terminationFeesOverride: terminationFees
        }, {nxLoaderText: 'Withdrawing funds...'}).success((res) => {
          casaProductPrintService.printFor('TERM_DEPOSIT', customerId, depositId, res.output.id).finally(() => {
            customerCache.termDeposits(customerId).refetch();
            that.redirectBack();
          });
        });
      });
    }
  }
});
