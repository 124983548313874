import module from 'module';
import _ from 'lodash';

const templateUrl = require('./file-image.template.html');
module.component('fileImage', {
  templateUrl: templateUrl,
  bindings: {
    'fileId': '<',
    'useCache': '<',
    'imgWidth': '<',
    'imgHeight': '<'
  },
  controller: function ($scope, fileService, notification) {
    const that = this;

    const onFileLoad = (file) => that.image = window.URL.createObjectURL(file);
    const onError = (error) => {
      notification.show("Error", "Could not load image");
      console.error(error);
    };

    $scope.$watch('fileId', () => {
      fileService.downloadFile(that.fileId, false, that.useCache)
        .success(file => onFileLoad(file))
        .error(error => onError(error));
    })

  }
});
