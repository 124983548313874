import module from 'module';
import $ from 'jquery';

module.factory('popup', function () {
  return function ({
    header = "Information",
    text,
    finePrint = null,
    callback,
    renderHtml = false
  }) {
    if (!$('#popupModal')[0]) {
      $('body').append(`
				<div id="popupModal" class="modal hide fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
						<h3 class="header-text"></h3>
					</div>
					<div class="modal-body">
						<p class="text"></p>
					</div>
					<div class="modal-footer">
					  <span class="fine-print"></span>
						<button class="btn btn-primary modalButton" data-dismiss="modal" aria-hidden="true">OK</button>
					</div>
				</div>`);
    } else {
      $('#popupModal .modalButton').off();
    }

    $('#popupModal .header-text').text(header);
    if (renderHtml) {
      $('#popupModal .text').html(text);
    } else {
      $('#popupModal .text').text(text);
    }
    $('#popupModal .fine-print').text(finePrint);
    $('#popupModal').modal();
    $('#popupModal .modalButton').click(function () {
      disableButtons();
      if (callback) callback();
    });

    function disableButtons() {
      $('#popupModal .modalButton').off();
    }
  }
});
