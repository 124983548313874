import module from 'module';
import './customer-picker-paginated.less';

import templateUrl from './customer-picker-paginated.template.html';

/**
 * Allows to search customers via their name, cif number, and birthdate.
 */
class CustomerPickerPaginated {
  constructor(lookupCustomerModalService) {
    this.lookupCustomerModalService = lookupCustomerModalService;
  }

  async selectCustomer() {
    this.customer = await this.lookupCustomerModalService.selectCustomer();
    this.onSelect({customer: this.customer});
  }

  removeCustomer() {
    this.customer = null;
    this.onRemove();
  }
}

module.component('customerPickerPaginated', {
  templateUrl,
  bindings: {
    /**
     * When 'True' disables already set up customer, otherwise it's still possible to change selection
     */
    'changeDisabled': '<',
    /**
     * One-way binging for preloaded customer
     */
    'customer': '<',
    /**
     * Function called on select, when not provided 'Select' button is hidden
     */
    'onSelect': '&',
    /**
     * Function called on remove, when not provided 'Remove' button is hidden
     */
    'onRemove': '&',
    /**
     * When 'True' hides input field, otherwise it's shown and covers more space
     */
    'hideEmptyInput': '<'
  },
  controller: CustomerPickerPaginated
});