import module from 'module';

import templateUrl from "./loan-gl-payment.template.html";

class CustomerLoansGLPayment {
  constructor($location, confirmation, command, branchService, customerCache, modalPrintPreviewService) {
    this.paymentRequest = {};
    this.$location = $location;
    this.confirmation = confirmation;
    this.command = command;
    this.branchService = branchService;
    this.customerCache = customerCache;
    this.modalPrintPreviewService = modalPrintPreviewService;

    this.operation = {};
    this.remarks = null;

    this.loadBranch();
  }

  async loadBranch() {
    const branches = await this.branchService.toPromise();
    this.branch = branches.find(fetchedBranch => fetchedBranch.id === this.loan.branchId);
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loan.loanId}`);
  }

  print() {
    window.print();
  };

  pay() {
    this.confirmation(`Do you want to pay ${this.paymentRequest.amount} PHP?`, () => {
      this.command.execute('PayLoanByGL', {
        productId: this.loan.id,
        remarks: this.remarks,
        accountCode: this.operation.account.fullCode,
        ...this.paymentRequest,
      }, {nxLoaderText: 'Performing operation...'})
        .success((validationResponse) => {
          const operationId = validationResponse.output.operationId;
          this.customerCache.loans(this.customerId).refetch();
          this.modalPrintPreviewService.show('LOAN_PAYMENT_RECEIPT', {operationId,},
            () => this.redirectBack());
        }).offline(() => {
          this.customerCache.loans(this.customerId).refetch();
          this.redirectBack();
      });
    });
  };
}

module.component('customerLoansGlPayment', {
  templateUrl,
  bindings: {
    customerId: '<',
    loan: '<',
  },
  controller: CustomerLoansGLPayment
});
