import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('depositoryAccountCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/ledger/depository-accounts'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.depositoryAccount,
    cacheName: 'depositoryAccount'
  })
);
