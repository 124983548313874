import module from 'module';

const templateUrl = require('./glue-container.template.html');
module.component('glueContainer', {
  templateUrl: templateUrl,
  transclude: true,
  bindings: {
    'prepend': '<',
    'append': '<'
  }
});