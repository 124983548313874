import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('accessRuleDefinitionCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/access/commands'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.depositoryAccount,
    cacheName: 'accessRuleDefinition'
  })
);
