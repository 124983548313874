
export default {
  finishedRequests: 0,
  successfulRequests: 0,
  requests: [],
  callbacks: {
    // defined in constructor
  },

  add: function (request) {
    if (!request) return this;

    var that = this;
    this.requests.push(request);
    request.success(function () {
      that.successfulRequests++;
    });
    request.always(function () {
      that.checkIfAllOperationsFinished();
    });
//        console.log("adding request. size: " + this.requests.length);
    return this;
  },

  checkIfAllOperationsFinished: function () {
    this.finishedRequests++;
//        console.log("check operations finished: " + this.finishedRequests + ", requests: " + this.requests.length);
    if (this.finishedRequests == this.requests.length) {

      // for each success or error callback
      var callbacks = this.callbacks.success;
      if (this.requests.length !== this.successfulRequests) {
        callbacks = this.callbacks.error;
      }
      callbacks.map(function (callback) {
        callback();
      });

      // for each callback do
      this.callbacks.always.map(function (callback) {
        callback();
      });

    }
  },

  create: function () {
    var obj = Object.create(this);
    obj.requests = [];
    obj.callbacks = {
      success: [],
      error: [],
      always: []
    };
    return obj;
  },

  isEmpty: function () {
    return this.requests.length === 0;
  },
  
  success: function (callback) {
    this.callbacks.success.push(callback);
    return this;
  },
  error: function (callback) {
    this.callbacks.error.push(callback);
    return this;
  },
  always: function (callback) {
    this.callbacks.always.push(callback);
    return this;
  }
};