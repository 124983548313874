import module from 'module';
import templateUrl from './pawn-appraisal-list.template.html';

module.component('pawnAppraisalList', {
  templateUrl: templateUrl,
  bindings: {
    items: '=',
    editItem: '=',
    removeItem: '='
  },
  controller: function (confirmation, pawnItemTypeCache, pawnItemDefectTypeCache) {
    this.$onInit = async () => {
      const types = await pawnItemTypeCache.toPromise();
      this.type = types.reduce((map, t) => {
        map[t.id] = t.name;
        return map;
      }, {});
      const defects = await pawnItemDefectTypeCache.toPromise();

      this.defect = defects.reduce((map, d) => {
        map[d.id] = d.name;
        return map;
      }, {});

      this.getItemDefects = (item) => {
        if (!item || !item.defectIds) {
          return '-'
        }
        const defects = item.defectIds.map((id) => this.defect[id]);
        return defects.join(', ');
      };

      this.edit = (item) => {
        this.editItem && this.editItem(item);
      };

      this.remove = (item) => {
        confirmation('Do you want to Remove the appraisal?', () => {
          this.removeItem && this.removeItem(item);
        });
      };
    };
  }
});