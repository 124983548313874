import module from 'module';
import $ from 'jquery';
const templateUrl = require('./inline-panel.template.html');


module.component('inlinePanel', {
  templateUrl,
  bindings: {
    onHide: '&'
  },
  transclude: true,
  controller: function($scope, $filter, $element,) {
    const self = this;

    const deselectTransaction = () => {
      $scope.$apply(() =>
        self.onHide()
      );
    };

    const $document = $(document);
    $document.click(deselectTransaction);

    self.hideAction = $event => {
      $event.stopPropagation();
      self.onHide();
    };

    self.$postLink = () => {
      $element.click(false);
    };

    self.$onDestroy = () => {
      $document.off('click', deselectTransaction);
    };
  }
});