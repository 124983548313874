import module from 'module';
import ParameterizedLocalCache from 'shared/util/parameterized-local-cache';

module.factory('branchCurrencyCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (branchId) => http.get(`/management/branches/${branchId}/currencies`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.currency,
    cacheName: 'branchCurrencyCache'
  })
);