import module from 'module';
import _ from 'lodash';

const templateUrl = require('./stop-order.template.html');
module.component('customerAccountsStopOrder', {
  templateUrl: templateUrl,
  controller: function ($location, $timeout, $route, confirmation, customerCache,
                        productDefinitionService, command, depositoryAccountCache) {
    let that = this;
    const customerId = $route.current.params['customerId'];
    const productId = $route.current.params['accountId'];

    that.account = {};

    that.depositoryAccountSelectConfig = {
      placeholder: 'Select depository account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };

    const s1 = depositoryAccountCache.toObservable().subscribe(data => {
      that.depositoryAccounts = [];
      if (data && data.length > 0) {
        that.depositoryAccounts = _.filter(data, d => d.clearing)
      }
    });

    const s2 = customerCache.depositAccounts(customerId).toObservable()
      .combineLatest(productDefinitionService.toObservable(), (accounts, types) => {
        return accounts.map(account => {
          const productType = _.find(types, {id: account.definitionId});
          return Object.assign(account, {productName: productType ? productType.productName : '-'});
        })
      }).subscribe(accounts => that.account = _.find(accounts, (a) => Number(a.id) === Number(productId)));

    // On init set form as submitted to highlight invalid fields
    $timeout(() => {
      that.form.$setSubmitted();
    });

    that.onDepositoryAccountChange = () => {
      that.depositoryAccount = _.find(that.depositoryAccounts, (a) => Number(a.id) === Number(that.depositoryAccountId));
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${productId}`);

    that.createStopOrder = () => {
      confirmation(`Do you want to crate stop order?`, () => {
        const req = {
          productId: productId,
          checkNumber: that.checkNumber,
          depositoryAccountId: that.depositoryAccountId,
          remarks: that.remarks,
        };
        command.execute('CreateCheckStopOrder', req).success(() => {
          that.redirectBack();
        });
      });
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
      s2.unsubscribe();
    }
  }
});
