import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('stockedItemMetalRateCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/products/pawns/metal-rates?type=STOCKED'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.pawnMetalRates,
    cacheName: 'stockedItemMetalRates'
  })
);