import module from 'module';
import jwtDecode from 'jwt-decode';


class CmcService {

  getCmcToken() {
    return localStorage.getItem('cmc');
  }

  getCmc() {
    const cmcToken = this.getCmcToken();
    return jwtDecode(cmcToken).cmc;
  }

  setCmcToken(cmc) {
    localStorage.setItem('cmc', cmc);
  }
}

module.service('cmcService', CmcService);