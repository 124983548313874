import module from 'module';
import _ from 'lodash';

const templateUrl = require('./new-pawn-list.template.html');
module.component('customerPawnCreateList', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, customerCache, pawnProductsCache, nav, productService) {
    let that = this;
    that.profile = {};
    that.pawnProducts = [];

    let customerId = $route.current.params['customerId'];

    that.redirectBack = () => $location.path(`/customer/${customerId}/pawns`);

    that.open = (pawnProduct) => {
      $location.path(`/customer/${customerId}/pawns/create/${pawnProduct.id}`);
    };

    customerCache.profile(customerId).toObservable().subscribe(profile => that.profile = profile);
    const s = pawnProductsCache.toObservable().subscribe(pawnProducts => {
      that.availablePawnProducts = pawnProducts.filter(pp => productService.availableForSale(pp));
      // only one product
      if (that.pawnProducts && that.pawnProducts.length === 1) {

        // TODO: create abstract generic awesome component
        if (nav.getSource() === 'customer-pawns') {
          // the user comes from the customer pawns page, just choose the product
          nav.clearSource();
          that.open(that.pawnProducts[0]);
        } else {
          // else go back to the customer pawns page customer pawns page
          nav.back();
        }

      }
    });

    that.$onDestroy = () => {
      s.unsubscribe();
    };

  }
});