import module from 'module';

const templateUrl = require('./group-schedule.template.html');
module.component('groupSchedule', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '=',
    'form': '='
  },
  controller: function () {
    let that = this;

  }
});
