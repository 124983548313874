const templateUrl = require('./loan-pre-terminate-by-transfer.template.html');
import BigNumber from 'bignumber.js';
import module from 'module';
import _ from 'lodash';

module.component('loanPreTerminateByTransfer', {
  templateUrl: templateUrl,
  controller: function ($location, $route, $filter, customerCache, notification, http, command, confirmation, depositAccountService, loanPreTerminateService) {
    let that = this;

    that.accounts = null;
    that.selectedAccount = null;

    that.customerId = $route.current.params['customerId'];
    that.loanId = $route.current.params['loanId'];

    that.paymentRequest = {};
    that.waiveRequest = {};
    that.amortizationIds = [];
    that.maxValues;
    that.loan;
    that.title = $filter('uppercase')($filter('translate')('pre-terminate-by-transfer', 'LOAN'));

    let subscribe = customerCache.loans(that.customerId).toObservable().subscribe(loans => {
      that.loan = _.find(loans, (l) => l.id == that.loanId);
      that.amortizationIds = _.map(that.loan.amortizationSchedule.list, a => a.id);
      that.maxValues = {...that.loan.amortizationSchedule.totalBalance};
    });

    const depositAccountsSubscribe = customerCache.depositAccounts(that.customerId).toObservable()
      .combineLatest(depositAccountService.toObservable(), (accounts, accountTypes) => {
        for (let a of accounts) {
          const type = _.find(accountTypes, (type) => type.id === a.typeId);
          if (type) {
            a.name = `${type.productDefinition.productName} [${a.productNumber}]`;
          }
        }
        return accounts;
      }).subscribe(accounts => that.accounts = accounts);

    that.getRequestBody = () => {
      that.paymentRequest.accountId = that.selectedAccount.id;
      return {
        waiveRequest: that.waiveRequest,
        loanPaymentRequest: that.paymentRequest
      };
    };

    that.preTerminate = () => {
      var info = {
        total: that.paymentRequest.amount,
        totalWaive: that.waiveRequest.waiveParts.reduce((sum, item) => sum.add(item['waiveAmount'] || 0), new BigNumber(0)).toNumber()
      };

      var message = loanPreTerminateService.getMessage(info);
      confirmation(message, () => {
        command.execute('PreterminateLoanByTransfer', that.getRequestBody(), {
            nxLoaderText: 'Performing operation...'
          }).success(() => {
          customerCache.loans(that.customerId).refetch();
          customerCache.depositAccounts(that.customerId).refetch();
          that.redirectBack();
        }).offline(() => {
          customerCache.loans(that.customerId).refetch();
          customerCache.depositAccounts(that.customerId).refetch();
          that.redirectBack();
        })
      });
    };

    that.redirectBack = () => $location.path(`/customer/${that.customerId}/loans/${that.loanId}`);

    that.$onDestroy = () => {
      subscribe.unsubscribe();
      depositAccountsSubscribe.unsubscribe();
    }
  }
});