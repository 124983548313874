import module from 'module';
import _ from 'lodash';

const templateUrl = require('./predicate.template.html');
class Predicate {
  constructor($scope) {
    this.$scope = $scope;
  }

  $onInit() {
    this.$scope.$watchCollection('$ctrl.predicates', () => {
      this.predicateDefined = _.has(this.predicates, this.type);
    });
  }

  close() {
    delete this.predicates[this.type]
  }
};
module.component('predicate', {
  templateUrl,
  transclude: true,
  bindings: {
    predicates: '=',
    type: '<'
  },
  controller: Predicate
});
