import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('propertyConfigCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get(`/management/properties-config`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.propertyConfig,
    cacheName: `propertyConfig`
  })
);
