import module from 'module';
import _ from 'lodash';

const templateUrl = require('./new-account-list.template.html');
module.component('customerAccountCreateList', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, customerCache, depositAccountService, confirmation, notification) {
    let that = this;
    that.depositAccounts = [];

    let customerId = $route.current.params['customerId'];

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts`);

    depositAccountService.toObservable().subscribe(da => that.depositAccounts = da);


    that.openAccount = (accountType) => {
      $location.path(`/customer/${customerId}/accounts/create/${accountType.id}`);
    };

  }
});
