import module from 'module';

const templateUrl = require('./cash-to-depository-bank.template.html');
module.component('cashToDepositoryBank', {
  templateUrl: templateUrl,
  controller: function (actionCommand, depositoryAccountCache) {
    const that = this;
    that.depositoryAccounts = [];
    that.transaction = {};
    that.selectConfig = {
      placeholder: 'Select account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };

    const sub = depositoryAccountCache.toObservable().subscribe(depositoryAccounts => that.depositoryAccounts = depositoryAccounts);

    that.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    that.save = () => {
      actionCommand.executeAndShowValidation('CASH_DEPOSIT_TO_BANK', that.transaction);
    };

    that.$onDestroy = () => sub.unsubscribe();
  }
});
