import module from 'module';
import _ from 'lodash';

import './users-change-branch.less'

import templateUrl from './users-change-branch.template.html';

class UsersChangeBranch {
  constructor($location, command, confirmation, notification, branchService, userCache) {
    this.commandRequest = {};
    this.branches = [];
    this.description = 'Move user between branches';

    this.$location = $location;
    this.command = command;
    this.confirmation = confirmation;
    this.notification = notification;
    this.branchService = branchService;
    this.userCache = userCache;

    this.loadBranches();
  }

  async loadBranches() {
    this.branches = await this.branchService.toPromise();
  };

  async changeBranch() {
    this.commandRequest.userId = this.user.id;

    if (this.commandRequest.targetBranchId === null || this.commandRequest.targetBranchId === undefined) {
      this.notification.show("Error", "Branch not selected!");
    } else if (this.commandRequest.targetBranchId == this.user.branchId) {
      this.notification.show("Error", "Target branch same as current branch!");
    } else {
      const branch = _.find(this.branches, b => b.id === this.commandRequest.targetBranchId);
      const proceed = await this.confirmation(`Do you want to move user <strong> ${this.user.effectiveName}</strong> to <strong>${branch.name}</strong> branch ?`, true);
      if (proceed) {
        const commandResponse = await this.command.execute('MoveUserBetweenBranches', this.commandRequest).toPromise();
        if (commandResponse) {
          this.userCache.refetch();
          this.$location.path(this.links.success);
        }
      }
    }
  };

  cancelChanges() {
    this.$location.path(this.links.cancel);
  };
}

module.component('usersChangeBranch', {
  templateUrl,
  bindings: {
    user: '<',
    links: '<'
  },
  controller: UsersChangeBranch
});
