import module from 'module';
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import moment from "moment";

const templateUrl = require('./partial-withdrawal-check.template.html');
module.component('customerTermDepositPartialWithdrawalCheck', {
  templateUrl: templateUrl,
  controller: function ($route, $filter, $location, command, http, authentication, customerCache, productDefinitionService,
                        termDepositsService, confirmationTemplate, depositoryAccountCache, branchService) {
    const that = this;
    const customerId = $route.current.params['customerId'];
    const depositId = $route.current.params['depositId'];

    // Deposit data & interest forecast
    that.deposit = {};
    that.interestForecast = {};
    that.maxWithdrawalAmount = null;

    // Depository accounts
    that.depositoryAccounts = undefined;
    that.accountSelectConfig = {
      placeholder: 'Select depository account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };

    // Command input
    that.request = {
      entryType: 'DEBIT',
      productId: depositId,
      customerId: customerId,
      override: null,
      amount: null,
      depositoryAccountId: null,
      checkNumber: null,
      micrNumber: null,
      validFrom: null,
      payee: null,
      remarks: null
    };

    // Fetch term deposit interest forecast
    const readInterestForecast = (deposit) => {
      http.post(`/products/deposits/${deposit.id}/interest`, {mode: 'TERMINATION'}).success((forecast) => {
        that.interestForecast = forecast;
        that.creditedBalance = new BigNumber(0)
          .plus(deposit.balance)
          .plus(forecast.netInterest)
          .round(2)
          .toNumber();
        that.maxWithdrawalAmount = new BigNumber(0)
          .plus(deposit.balance)
          .plus(forecast.netInterest)
          .minus(deposit.depositType.minimalBalance)
          .round(2)
          .toNumber();
      });
    };

    const s1 = customerCache.termDeposits(customerId)
      .toObservable()
      .map(deposits => _.find(deposits, d => Number(d.id) === Number(depositId)))
      .combineLatest(termDepositsService.toObservable(), (deposit, types) => {
        return Object.assign(deposit, {'depositType': _.find(types, {id: deposit.typeId})});
      })
      .combineLatest(productDefinitionService.toObservable(), (deposit, definitions) => {
        return Object.assign(deposit, {'productDefinition': _.find(definitions, {id: deposit.definitionId})});
      }).subscribe(deposit => {
        that.deposit = deposit;
        that.request.remarks = `Partial withdrawal from ${deposit.productNumber}`;
        that.interestForecast = readInterestForecast(deposit);
      });

    const s2 = depositoryAccountCache.toObservable().subscribe(data => {
      that.depositoryAccounts = _.filter(data, a => a.brstn && a.accountNumber);
    });

    const s3 = customerCache.profile(customerId).toObservable().subscribe(profile => {
      that.request.payee = profile.effectiveName;
    });

    const s4 = branchService.toObservable().subscribe(branches => {
      const authBranch = _.find(branches, {id: authentication.context.branchId});
      that.request.validFrom = moment(authBranch.postingDate).toDate();
    });

    that.onMicrDataChange = () => {
      const req = that.request;
      that.request.micrNumber = null;
      if (req.checkNumber && req.brstn && req.accountNumber) {
        that.request.micrNumber = `${req.checkNumber}${req.brstn}${req.accountNumber}`;
      }
    };

    that.onDepositoryAccountChange = () => {
      // Reset account-related input properties
      that.request.brstn = null;
      that.request.accountNumber = null;
      // Find depository account & setup properties
      const account = _.find(that.depositoryAccounts, a => Number(a.id) === Number(that.request.depositoryAccountId));
      if (account) {
        that.request.brstn = account.brstn;
        that.request.accountNumber = account.accountNumber;
      }
      that.onMicrDataChange();
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${depositId}`);

    that.partialWithdrawalEnabled = () => {
      return that.maxWithdrawalAmount
        && that.maxWithdrawalAmount >= 0
        && that.request
        && that.request.amount
        && that.request.amount >= 0
        && that.request.amount <= that.maxWithdrawalAmount
    };

    that.partialWithdrawal = () => {
      confirmationTemplate({
        question: `Do you want to withdraw ${$filter('php') (that.request.amount)} and rollover the deposit?`,
        details: [
          {label: 'Product number', description: that.deposit.productNumber},
          {label: 'Rollover amount', description: $filter('php')(that.creditedBalance - that.request.amount)},
          {label: 'Check number', description: that.request.checkNumber},
          {label: 'Payee', description: that.request.payee},
          {label: 'Valid from', description: $filter('prettyDate')(that.request.validFrom)}
        ],
        warning: 'This operation cannot be reverted safely.<br>Please make sure that the data is correct.',
        yesCallback: () => {
          command.execute(
            'DepositPartialWithdrawalCheck',
            that.request,
            {nxLoaderText: 'Performing partial withdrawal'}
          ).success(() => {
            customerCache.termDeposits(customerId).refetch();
            that.redirectBack();
          });
        }
      });
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
      s2.unsubscribe();
      s3.unsubscribe();
      s4.unsubscribe();
    };
  }
});
