import module from 'module';
import _ from "lodash";
import templateUrl from './users-branches.template.html';

module.component('usersBranches', {
  templateUrl,
  bindings: {
    name: '<',
    filterName: '<',
    username: '<',
    filterUsername: '<'
  },
  controller: function ($scope, systemPropertyCache) {
    systemPropertyCache.toObservable().first().subscribe(properties => {
      const organizationType = _.find(properties, {code: 'ORGANIZATION_TYPE'});
      this.organizationType = !organizationType ? 'BANK' : organizationType.value;
    });

    this.name = '';
    this.filterName = '';
    this.username = '';
    this.filterUsername = '';

    this.updateFilterValues = () => {
      this.filterName = this.name;
      this.filterUsername = this.username;
    };

    const resetFiltersIfEmpty = () => {
      if (!this.name && !this.username) {
        this.filterName = '';
        this.filterUsername = '';
      }
    };
    $scope.$watch("$ctrl.name", resetFiltersIfEmpty);
    $scope.$watch("$ctrl.username", resetFiltersIfEmpty);
  }
});
