import module from "module";

import {ssNumberPattern} from '../sss-offline-payment/sss-offline-payment.utils';
import templateUrl from './sss-online-individual-payment.template.html';
import {confirmationMessage, payorTypeForPRN} from '../sss/sss.utils'

class SssOnlineIndividualPayment {
  constructor(http, command, popup, $httpParamSerializer, confirmation, $filter, postingDateService) {
    this.http = http;
    this.command = command;
    this.popup = popup;
    this.pnrFormMode = null;
    this.$httpParamSerializer = $httpParamSerializer;
    this.confirmation = confirmation;
    this.$filter = $filter;

    postingDateService.getCurrentUserBranchPostingDate().then(date => this.postingDate = date);
  }

  ssNumberPattern() {
    return ssNumberPattern('CONTRIBUTION');
  }

  onPrnChanged() {
    this.birthDate = null;
    this.ssNumber = null;
    this.payment = null;
    this.prnFormMode = true;

    if (!this.commandForm.paymentReferenceNumber.$viewValue) {
      this.prnFormMode = null;
    }
  }

  onBirthDateChanged() {
    this.payment = null;
    this.paymentReferenceNumber = null;
    this.prnFormMode = false;

    if (!this.commandForm.birthDate.$viewValue && !this.commandForm.ssNumber.$viewValue) {
      this.prnFormMode = null;
    }
  }

  onSSNumberChanged() {
    this.payment = null;
    this.paymentReferenceNumber = null;
    this.prnFormMode = false;

    if (!this.commandForm.birthDate.$viewValue && !this.commandForm.ssNumber.$viewValue) {
      this.prnFormMode = null;
    }
  }

  fetchByPrn() {
    this.http.get(`/sss/online-payment/payor-kind/INDIVIDUAL/prn/${this.paymentReferenceNumber}`)
      .success(payment => {
        this.payment = payment;
      })
      .error(err => {
        this.popup({text: err.errorMessage, header: 'Error'});
      });
  }

  fetchBySsn() {
    const ssn = this.ssNumber;
    const birthDate = this.birthDate.format("YYYY-MM-DD");
    const params = this.$httpParamSerializer({
      ssn,
      birthDate,
    });

    this.http.get(`/sss/online-payment/payor-kind/INDIVIDUAL/ssn?${params}`)
      .success(payment => {
        this.payment = payment;
      })
      .error(err => {
        this.popup({text: err.errorMessage, header: 'Error'});
      });
  }

  async save() {
    const message = confirmationMessage({
      amount: this.payment.amount,
      startingPeriod: this.payment.startingPeriod,
      endingPeriod: this.payment.endingPeriod,
      payorType: payorTypeForPRN(this.payment.paymentReferenceNumber),
      moneyFormatter: this.$filter('php'),
      calculateFlexiFund:  payorTypeForPRN(this.payment.paymentReferenceNumber) === 'OVERSEAS_WORKER'
    });

    const proceed = await this.confirmation(message, true);
    if (proceed) {
      const response = await this.command.execute('PayOnlineSSS', {
        "ssNumber": this.payment.ssNumber,
        "paymentReferenceNumber": this.payment.paymentReferenceNumber,
        "amount": this.payment.amount,
        "startingPeriod": this.payment.startingPeriod,
        "endingPeriod": this.payment.endingPeriod,
        "paymentDate": this.postingDate.format('YYYY-MM-DDTHH:mm:ss')
      }).toPromise();

      this.onSaveAction({
        response,
      });
    }
  }
}


module.component('sssOnlineIndividualPayment', {
  templateUrl,
  bindings: {
    'payorKind': '=',
    'onSaveAction': '<',
    'cancelAction': '<',
  },
  controller: SssOnlineIndividualPayment
});
