import module from "module";
import _ from "lodash";
import moment from 'moment';

const templateUrl = require('./start-the-day.template.html');
module.component('startTheDay', {
  templateUrl: templateUrl,
  controller: function ($filter, http, authentication, notification, systemPropertyCache, currencyCache,
                        workingDaysCache, branchService, actionCommand, confirmationTemplate) {

    // JS 'magic'
    let that = this;
    // Current branch
    that.userBranch = authentication.context.branchId;
    // Organization type
    that.organizationType = null;
    // Current working day
    that.workingDay = null;
    // Start the day transaction data
    that.transaction = {
      startCash: null,
      startCoci: null,
      clearingDay: true,
      bspApproval: false,
      branchId: that.userBranch
    };
    that.organizationCurrencyIsoCode = 'PHP';

    that.denominations = {};

    // clear branch cache
    workingDaysCache.evict();
    branchService.evict();

    const systemPropertiesSub = systemPropertyCache.toObservable()
      .combineLatest(currencyCache.toObservable(), (properties, currencies) => {
        that.currencies = currencies;
        that.organizationCurrencyIsoCode = _.find(properties, {code: 'MAIN_CURRENCY_ISO_CODE'}).value;
        const mainCurrencyIdx = _.findIndex(this.currencies, it => it.isoCode === this.organizationCurrencyIsoCode);
        if (mainCurrencyIdx > -1) {
          that.mainCurrency = that.currencies[mainCurrencyIdx];
          that.transaction.currencyId = that.mainCurrency.id;
        }
        return properties;
      }).subscribe(properties => {
        // Rear DENOMINATION_MODE property
        that.denominationLevel = _.find(properties, {code: 'DENOMINATION_LEVEL'}).value;
        // Read ORGANIZATION_TYPE property
        let organizationType = _.find(properties, {code: 'ORGANIZATION_TYPE'});
        that.organizationType = organizationType === null ? 'BANK' : organizationType.value;
        // If organization type is <> BANK -> set default values for bank properties
        if (that.organizationType !== 'BANK') {
          that.transaction.startCoci = 0;
          that.transaction.clearingDay = false;
          that.bspApproval = true;
        }
      });


    /**
     * Read working day data for current branch.
     * Current branch - branch of currently logged user.
     */
    const workingDaysSub = workingDaysCache.toObservable().subscribe(workingDays => {
      that.workingDay = _.find(workingDays, {branchId: that.userBranch});
    });

    const branchSub = branchService.toObservable().subscribe(branches => {
      const authBranch = _.find(branches, {id: that.userBranch});
      if (authBranch) {
        that.postingDate = moment(authBranch.postingDate).toDate();
        that.branchName = authBranch.name;
      }
    });

    const fetchAutoDenomination = () => {
      // If currency is not given or denomination is disabled -> return null as data provider result
      if (!that.denominationLevel || that.denominationLevel === 'NONE') return null;
      // Otherwise fetch current branch denomination
      // TODO = fetch currency value by ISO code
      const currencyId = that.mainCurrency.id;
      http.get(`/currencies/denomination/branches/${that.userBranch}?currencyId=${currencyId}`, {}, {nxLoaderText: 'Loading auto denomination'})
        .success((branchDenomination) => {
          // Transform branch denomination into denomination bundle
          that.autoDenomination = {
            incoming: {
              denomination: {
                currencyId: branchDenomination.currencyId,
                units: branchDenomination.summary
              }
            }
          };
        })
        .error(() => notification.show("Failed to load auto denomination"));
    };

    /**
     * Read begging vault cash and beginning COCI.
     * Fetched values must be passed as transaction data.
     */
    http.get(`/management/branches/${that.userBranch}/counter`, {}, {nxLoaderText: 'Loading branch counter'})
      .success((counter) => {
        that.transaction.startCash = counter.totalCash;
        that.transaction.startCoci = counter.totalCheck;
        fetchAutoDenomination();
      })
      .error(() => notification.show("Failed to load branch counter"));

    that.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    //todo: extract service
    that.flattenDenominations = () => {
      const flatDenominations = [];

      for (let key in that.denominations) {
        const denomination = that.denominations[key];
        if(denomination) {
          const denominationBundle = denomination.denominationBundle;
          flatDenominations.push(denominationBundle);
        }
      }
      return flatDenominations;
    };

    that.save = () => {
      confirmationTemplate({
        question: 'Do you want to start the day?',
        details: [
          {label: 'Branch', description: that.branchName},
          {label: 'Date', description: $filter('prettyDate')(that.postingDate)}
        ],
        warning: 'This operation cannot be reverted safely.<br>Please make sure that the day can be started.',
        yesCallback: () => {
          that.transaction.foreginDenominationBundles = that.flattenDenominations();
          actionCommand.execute('START_DAY', that.transaction)
        }
      });

    };

    // Release subscriptions
    that.$onDestroy = () => {
      systemPropertiesSub.unsubscribe();
      workingDaysSub.unsubscribe();
      branchSub.unsubscribe();
    };
  }
});
