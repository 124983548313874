import module from 'module';
import _ from 'lodash';

class PropertyConfigService {

  constructor(propertyConfigCache) {
    this.propertyConfigCache = propertyConfigCache;
    propertyConfigCache.toObservable().subscribe(config => this.propertyConfig = config);
  }

  findBySection(module, section) {
    // If property config is not yer initialized -> return null
    if (!this.propertyConfig) return null;
    // If module, section or name is null -> return null
    if (!module || !section ) return null;
    // Otherwise search for config
    return _.find(this.propertyConfig, {module: module, section: section});
  }

  find(module, section, name) {
    // If property config is not yer initialized -> return null
    if (!this.propertyConfig) return null;
    // If module, section or name is null -> return null
    if (!module || !section || !name) return null;
    // Otherwise search for config
    return _.find(this.propertyConfig, {module: module, section: section, name: name});
  }

  sectionVisible(module, section) {
    let fields = this.findBySection(module, section);
    let isVisible = _.some(fields, {visible: true});
    return isVisible;
  }

  visible(module, section, name) {
    let field = this.find(module, section, name);
    return field ? field.visible : true;
  }

  required(module, section, name) {
    let field = this.find(module, section, name);
    return field ? field.required : false;
  }

  /**
   * @deprecated prefer to use defaultValueAsync()
   * because this method may return null if propertyConfig
   * wasn't yet initialized, unlike defaultValueAsync() which
   * ensures propertyConfig is ready
   */
  defaultValue(module, section, name) {
    let field = this.find(module, section, name);
    return field ? field.defaultValue : null;
  }

  async defaultValueAsync(module, section, name) {
    if (!this.propertyConfig) {
      this.propertyConfig = await this.propertyConfigCache.toPromise();
    }
    const field = this.find(module, section, name);
    return field? field.defaultValue : null;
  }
}

module.service('propertyConfigService', PropertyConfigService);
