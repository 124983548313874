import module from 'module';
import templateUrl from './issue-passbook.template.html';

class IssuePassbook {

  constructor($route, $location, customerCache, productDefinitionService, confirmationTemplate, command, passbookCache,
              modalPrintPreviewService) {
    this.$route = $route;
    this.$location = $location;
    this.customerCache = customerCache;
    this.productDefinitionService = productDefinitionService;
    this.confirmationTemplate = confirmationTemplate;
    this.command = command;
    this.passbookCache = passbookCache;
    this.modalPrintPreviewService = modalPrintPreviewService;

    this.initProductInfo();
    this.fillProductData();
  }

  initProductInfo() {
    this.customerId = this.$route.current.params['customerId'];

    const productTypes = {
      ACCOUNT: 'accounts',
      TERM_DEPOSIT: 'term-deposits'
    };

    if (this.$route.current.params.hasOwnProperty('accountId')) {
      this.productType = productTypes.ACCOUNT;
      this.productId = this.$route.current.params.accountId;
      this.productsCache = this.customerCache.depositAccounts(this.customerId);
      this.passbookCoverPrintCode = 'DEPOSIT_ACCOUNT_PASSBOOK_COVER';
      this.commandName = 'CreateDepositAccountPassbook';
    } else {
      this.productType = productTypes.TERM_DEPOSIT;
      this.productId = this.$route.current.params.depositId;
      this.productsCache = this.customerCache.termDeposits(this.customerId);
      this.passbookCoverPrintCode = 'TERM_DEPOSIT_PASSBOOK_COVER';
      this.commandName = 'CreateTermDepositPassbook';
    }

    this.passbook = {productId: this.productId};
  }

  async fillProductData() {
    const [products, productDefinitions] = await Promise.all([this.productsCache.toPromise(), this.productDefinitionService.toPromise()]);

    const product = products.find(product => Number(product.id) === Number(this.productId));
    const productType = productDefinitions.find(definition => Number(definition.id) === Number(product.definitionId));

    this.product = {...product, productName: productType ? productType.productName : '-', productDefinition: productType};
    this.activePassbook = await this.passbookCache.withParam(this.productId).toPromise();
  }

  issuePassbook() {
    this.confirmationTemplate({
      question: `Do you want to issue a new passbook?`,
      warning: this.activePassbook ? 'Active passbook will be replaced' : null,
      details: this.getDetails(),
      yesCallback: () => {
        this.command.execute(this.commandName, this.passbook)
          .success((res) => {
            const print = {
              code: this.passbookCoverPrintCode,
              parameters: {
                'PRODUCT_DEFINITION': this.product.productDefinition.id
              }
            };

            this.modalPrintPreviewService.show(print,  {passbookId: res.output.id}, () => { this.refreshCacheAndRedirectBack(); });
          });
      }
    });
  }

  refreshCacheAndRedirectBack() {
    this.productsCache.refetch();
    this.passbookCache.withParam(this.productId).evict();
    this.redirectBack();
  }

  getDetails() {
    const details = [];
    if (this.activePassbook) {
      details.push({label: 'Active passbook number', description: this.activePassbook.number});
    }
    details.push({label: 'New passbook number', description: this.passbook.number});
    return details;
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/${this.productType}/${this.productId}`);
  }
}

module.component('issuePassbook', {
  templateUrl,
  controller: IssuePassbook
});
