import module from 'module';
import templateUrl from './passbook.template.html';

class Passbook {
  constructor(modalPrintPreviewService, operationTableBuilder, http, passbookCache) {
    this.printService = modalPrintPreviewService;
    this.tableBuilder = operationTableBuilder;
    this.http = http;
    this.passbookCache = passbookCache;
  }

  $onInit() {
    this.printService.canReprint(this.printCode, (active) => {
      this.isPrintActive = active;
    });

    this.passbookHistoryConfig = this.tableBuilder.buildNgTable({
      productId: this.accountId,
      status: ['PROCESSED'],
      isPassbook: true,
      postData: (data) => {
        data.forEach(d => {
          d.printed = this.passbookObj.printedOperationIds ? this.passbookObj.printedOperationIds.includes(d.id) : false;
          d.printInPassbook = d.printed === false;
        });
        return data;
      }
    });
  }

  getOperationCode(operation) {
    return operation.target.id === this.accountId ? operation.targetCode : operation.sourceCode;
  }

  transactionClicked(transaction, $event) {
    $event.stopPropagation();
    this.selectedTransactionId = transaction.id;
  };

  canPrint(operations) {
    return (operations || []).some(o => o.printInPassbook)
  }

  printPassbook(data) {
    let operations = data.filter(o => o.printInPassbook);
    let operationIds = operations.map(o => o.id);
    this.printService.show(this.printCode, {
      productId: this.accountId,
      operationIds: operationIds,
      startLine: this.passbookObj.nextLine
    }, () => {
      let req = operationIds.map((id, idx) => ({operationId: id, line: this.passbookObj.nextLine + idx + 1}));
      this.passbookObj.nextLine = req.map(o => o.line).reduce((a, b) => Math.max(a, b));
      this.http.put(`/products/${this.accountId}/passbook`, req).success(() => {
        operations.forEach(o => {
          o.printed = true;
          o.printInPassbook = false;
        });
        this.passbookCache.withParam(this.accountId).evict();
      });
    });
  }
}

module.component('passbook', {
  templateUrl,
  bindings: {
    printCode: '@',
    accountId: '<',
    passbookObj: '='
  },
  controller: Passbook
});