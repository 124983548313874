import module from 'module';
import templateUrl from './cash.template.html';

class CashController {
  constructor($element, $attrs) {
    this.$element = $element;
    this.$attrs = $attrs;
    // 9000000000000 - Backend max cash variable. Due to JS floating point precision max cash is divided by 10.
    this.maxCashAmount = 900000000000;
  }
  
  $onInit() {
    this.min = this.min || 0;
    this.max = this.max || this.maxCashAmount;
    this.fraction = this.fraction || 2;
    // Due to JS floating point precision safeDigitsNumber is reduced by 1.
    this.safeDigitsNumber = Number.MAX_SAFE_INTEGER.toString().length - this.fraction - 1;

    const onKeyPress = e => {
      const evt = e.originalEvent;
      const inputElement = evt.target;
      const value = inputElement.value;
  
      const floatDigitsWithDotLength = this.cutOutDigitsWithDot(value);
  
      const isValidLength = this.validLengthCheck(value, floatDigitsWithDotLength);
      const isSelected = this.selectionCheck(inputElement);
      const cursorAtFloat = this.allowFloatChange(inputElement, value, floatDigitsWithDotLength);
      const isKeyADot = evt.key === '.';
      
      if (!isValidLength && !isSelected && !cursorAtFloat && !isKeyADot) {
        e.preventDefault();
        return false;
      }
    };
    
    if (!this.currency && typeof this.currency !== 'string' && !this.hideCurrency) {
      this.currency = 'PHP';
    }

    if (this.ngModel) {
      this.ngModel.$validators.min = (modelValue) => {
        // Check only existing value.
        if (!modelValue && typeof modelValue !== 'number') return true;
        return this.min <= modelValue;
      };

      this.ngModel.$validators.max = (modelValue) => {
        if (!modelValue && typeof modelValue !== 'number') return true;
        return this.max >= modelValue;
      };

      this.ngModel.$render = () => {
        this.cashValue = this.ngModel.$viewValue;
      }
    }

    this.$element.on('keypress', onKeyPress);
  }

  cutOutDigitsWithDot(value) {
    const indexOfDot = value.indexOf('.');
    let floatDigits = 0;
    let floatDigitsWithDot = 0;

    // is Number is float
    if (indexOfDot >= 0) {
      floatDigits = value.length - (indexOfDot + 1);
      floatDigitsWithDot = floatDigits + 1;
    } else {
      floatDigits = 0;
    }

    return floatDigitsWithDot;
  }

  validLengthCheck(value, floatDigitsWithDot) {
    return value.length - floatDigitsWithDot < this.safeDigitsNumber;
  }

  selectionCheck(input) {
    return (input.selectionEnd - input.selectionStart) >= 1;
  }

  allowFloatChange(input, value, floatDigitsWithDot) {
    return (value.length - floatDigitsWithDot) < input.selectionEnd;
  }

  valueChanged() {
    if (this.ngModel) {
      this.ngModel.$setViewValue(this.cashValue);
    }
  }

  $onChanges(changes) {
    // invoke validation in case of input changes
    if(this.ngModel) {
      this.ngModel.$validate();
    }

    if (changes.value && changes.value.currentValue && !this.ngModel) {
      this.cashValue = changes.value.currentValue;
    }
  }
}

module.component('cash', {
  controller: CashController,
  templateUrl,
  require: {
    ngModel: '?^ngModel'
  },
  bindings: {
    blur: '&',
    currency: '<',
    fraction: '@',
    hideCurrency: '<',
    max: '<',
    min: '<',
    prepend: '<',
    value: '<'
  }
});
