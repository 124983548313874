import module from 'module';
import _ from 'lodash';
import $ from 'jquery';
import './customer-picker.less';

const templateUrl = require('./customer-picker.template.html');

/**
 * Deprecated, use customer-picker-paginated instead.
 * Allows to search for 50 customers based on given name.
 */
module.component('customerPicker', {
  templateUrl,
  bindings: {
    'onChoose': '&',
    'customerType': '<'
  },
  controller: function ($scope, $element, http, branchService) {
    const that = this;

    const showPopup = event => {
      const isCurrentElChild = $element.find(event.target).length > 0;

      if(!isCurrentElChild) {
        $scope.$apply(function () {
          that.visible = false;
        });
      }
    };

    $(document).click(showPopup);

    that.visible = false;
    that.searchResults = [];

    branchService.toObservable().subscribe(branches => that.branches = branches);

    that.search = () => {
      if (that.customerName) {
        http.get('/customers/full-text-search', {
          nxLoaderText: 'Searching for customer...',
          params: {
            customerName: that.customerName,
            pageSize: 50,
            pageNo: 1
          }
        })
          .success((data) => {
            that.visible = true;
            that.customerName = null;
            const results = _.filter(data, (c) => c.customerStatus === 'ACTIVE');
            _.forEach(results, (r) => Object.assign(r, {branch: _.find(that.branches, {id: r.branchId})}));
            that.searchResults = results;
          });
      }
    };

    that.choose = (customer, $event) => {
      $event.stopPropagation();

      that.onChoose({customer: customer});
      that.visible = false;
      that.searchResults = [];
    };

    that.$onDestroy = () => {
      $(document).off('click', showPopup);
    }
  }
});