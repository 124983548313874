import module from 'module';
import templateUrl from './batch-create-holiday.template.html';
import './batch-create-holiday.style.less';

class BatchCreateHoliday {

  constructor(command, confirmation, $route, branchService, $filter) {
    this.command = command;
    this.confirmation = confirmation;
    this.$route = $route;
    this.branchService = branchService;
    this.$filter = $filter;
  }

  async $onInit() {
    this.branches = await this.branchService.toPromise();

    this.selectedBranchIds = [];
    this.date = null;
    this.name = null;
  }

  redirectBack() {
    this.$route.reload();
  }

  async save() {

    const holidayDate = this.$filter('nxDate')(this.date);
    const proceed = await this.confirmation(`Do you want to create the holiday [${holidayDate}] for all the selected branches [count=${this.selectedBranchIds.length}]?`);

    if (!proceed) return;

    const request = {
      branchIds: this.selectedBranchIds,
      date: holidayDate,
      name: this.name
    };

    await this.command.execute('BatchCreateHoliday', request).toPromise();
    this.redirectBack();
  }

}


module.component('batchCreateHoliday', {
  templateUrl,
  controller: BatchCreateHoliday
});
