import module from 'module';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

const templateUrl = require('./partial-withdrawal-cash.template.html');
module.component('customerTermDepositPartialWithdrawalCash', {
  templateUrl: templateUrl,
  controller: function ($route, $filter, $location, command, http, customerCache, productDefinitionService,
                        termDepositsService, confirmationTemplate) {
    const that = this;
    const customerId = $route.current.params['customerId'];
    const depositId = $route.current.params['depositId'];

    // Deposit data & interest forecast
    that.deposit = {};
    that.interestForecast = {};
    that.maxWithdrawalAmount = null;

    // Command input
    that.request = {
      entryType: 'DEBIT',
      productId: depositId,
      customerId: customerId,
      override: null,
      amount: null
    };

    // Fetch term deposit interest forecast
    const readInterestForecast = (deposit) => {
      http.post(`/products/deposits/${deposit.id}/interest`, {mode: 'TERMINATION'}).success((forecast) => {
        that.interestForecast = forecast;
        that.creditedBalance = new BigNumber(0)
          .plus(deposit.balance)
          .plus(forecast.netInterest)
          .round(2)
          .toNumber();
        that.maxWithdrawalAmount = new BigNumber(0)
          .plus(deposit.balance)
          .plus(forecast.netInterest)
          .minus(deposit.depositType.minimalBalance)
          .round(2)
          .toNumber();
      });
    };

    const s1 = customerCache.termDeposits(customerId)
      .toObservable()
      .map(deposits => _.find(deposits, d => Number(d.id) === Number(depositId)))
      .combineLatest(termDepositsService.toObservable(), (deposit, types) => {
        return Object.assign(deposit, {'depositType': _.find(types, {id: deposit.typeId})});
      })
      .combineLatest(productDefinitionService.toObservable(), (deposit, definitions) => {
        return Object.assign(deposit, {'productDefinition': _.find(definitions, {id: deposit.definitionId})});
      }).subscribe(deposit => {
        that.deposit = deposit;
        that.interestForecast = readInterestForecast(deposit);
      });

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${depositId}`);

    that.partialWithdrawalEnabled = () => {
      return that.maxWithdrawalAmount
        && that.maxWithdrawalAmount >= 0
        && that.request
        && that.request.amount
        && that.request.amount >= 0
        && that.request.amount <= that.maxWithdrawalAmount
    };

    that.partialWithdrawal = () => {
      confirmationTemplate({
        question: `Do you want to withdraw ${$filter('php') (that.request.amount)} and rollover the deposit?`,
        details: [
          {label: 'Product number', description: that.deposit.productNumber},
          {label: 'Rollover amount', description: $filter('php')(that.creditedBalance - that.request.amount)}
        ],
        warning: 'This operation cannot be reverted safely.<br>Please make sure that the data is correct.',
        yesCallback: () => {
          command.execute(
            'DepositPartialWithdrawalCash',
            that.request,
            {nxLoaderText: 'Performing partial withdrawal'}
          ).success(() => {
            customerCache.termDeposits(customerId).refetch();
            that.redirectBack();
          });
        }
      });
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
    };
  }
});
