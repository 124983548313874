import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('pawnItemAttributeTypeCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get(`/products/pawns/items/attribute-types`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.pawnItemAttributeTypeConfig,
    cacheName: `pawnItemAttributeTypeConfig`
  })
);
