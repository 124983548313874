import module from "module";

const templateUrl = require('./batch-sss-pension-result.template.html');
module.component('batchSssPensionResult', {
  templateUrl: templateUrl,
  bindings: {
    'fileId': '<',
    'onSubmit': '<'
  },
  controller: function (sssPensionService) {
    const that = this;

    const s1 = sssPensionService.getSummaryByFileId(that.fileId).subscribe(s => {
      that.summary = s;
    });

    that.submit = () => {
      that.onSubmit(that.fileId);
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
    };
  }
});
