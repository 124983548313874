import module from 'module';
import _ from 'lodash';

const templateUrl = require('./role-read-access.template.html');

module.component('roleReadAccess', {
  templateUrl: templateUrl,
  bindings: {
    // INPUT: role
    'role': '=',

    // "OUTPUT" param - list of permissions for the provided role
    'rolePermissions': '='
  },
  controller: function ($scope, permissionCache) {
    const that = this;

    that.permissions = null;

    if (!that.rolePermissions) that.rolePermissions = [];

    permissionCache.toObservable().first().subscribe(permissions => {
      that.permissions = permissions;
      mapPermissions();
    });

    $scope.$watch('$ctrl.role', () => {
      that.rolePermissions = [];
      mapPermissions();
    });

    function mapPermissions() {
      if (!that.role || !that.permissions) return;

      that.permissions = that.permissions.map(p => {
        const rolePermission = _.find(that.role.permissions, {id: p.id});
        p.predicates = rolePermission && rolePermission.predicates ? rolePermission.predicates : {};

        p.roleHasPermission = !!rolePermission;
        if (p.roleHasPermission) {

          that.rolePermissions.push({
            permissionId: p.id,
            predicates: p.predicates
          });
        }
        return p;
      });
    }

    that.updatePermission = (p) => {
      if (p.roleHasPermission) {
        // add permission
        if (!that.rolePermissions.includes(p.permissionId)) that.rolePermissions.push({
          permissionId: p.id,
          predicates: p.predicates
        })

      } else {
        // remove permission
        that.rolePermissions = that.rolePermissions.filter(rp => rp.permissionId !== p.id);
      }
    };

  }

});
