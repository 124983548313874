import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('termDepositsService', (http, CacheFactory, config) => {
  let cache = new LocalCache({
    provider: () => http.get('/products/deposits/types'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.termDepositTypes,
    cacheName: 'termDepositTypes',
  });

  //TODO : move cache to termDepositsCache

  const maturityStrategyMapping = {
    'NONE': 'Wait for customer arrival',
    'TRANSFER_TO_ACCOUNT': 'Auto transfer to savings account',
    'RENEW_WITH_INTEREST': 'Auto renewal with interests',
    'RENEW_WITHOUT_INTEREST': 'Auto renewal w/o interests',
  };

  const creditingStrategyMapping = {
    'NOT_ALLOWED': 'Not allowed',
    'CASH_WITHDRAWAL': 'Cash on arrival',
    'CHECK_WITHDRAWAL': 'Check on arrival',
    'ADHOC_WITHDRAWAL': 'Cash or check on arrival',
    'PDC_WITHDRAWAL': 'Post-dated checks on opening',
    'PRODUCT_TRANSFER': 'Product transfer',
  };

  cache.getMaturityStrategyLabel = (value) => {
    return maturityStrategyMapping[value];
  };

  cache.getCreditingStrategyLabel = (value) => {
    return creditingStrategyMapping[value];
  };

  cache.enrichWithStrategiesDescription = (termDepositProduct) => {
    return Object.assign({}, termDepositProduct,
      {
        maturityStrategies: termDepositProduct.maturityStrategies.map(strategy => ({
          label: cache.getMaturityStrategyLabel(strategy),
          value: strategy,
        })),
        creditingStrategies: termDepositProduct.creditingStrategies.map(strategy => ({
          label: cache.getCreditingStrategyLabel(strategy),
          value: strategy,
        })),
      });
  };

  return cache;
});
