import module from 'module';

import templateUrl from './customer-layaway-payments-report-view.template.html';
import './customer-layaway-payments-report-view.style.less';

class CustomerLayawayPaymentsReportViewComponent {
  constructor($location, http, confirmation, branchService, pawnCache, pawnItemTypeCache,
              command, pawnService, reportService, $route, systemPropertyCache) {
    this.$location = $location;
    this.http = http;
    this.confirmation = confirmation;
    this.branchService = branchService;
    this.pawnCache = pawnCache;
    this.pawnItemTypeCache = pawnItemTypeCache;
    this.command = command;
    this.reportService = reportService;
    this.$route = $route;
    this.systemPropertyCache = systemPropertyCache;
  }

  async $onInit() {
    const layawayId = this.$route.current.params['layawayId'];
    this.fileName = `Layaway_${layawayId}_Customer_Payments_Report.xlsx`;
    this.reportCode = 'CustomerLayawayPaymentsReport';

    this.params = {
      productId: layawayId
    };

    this.request = {
      reportCode: this.reportCode,
      params: this.params,
      reportName: this.fileName
    };

    this.filterConfig = {
      reportCode: this.reportCode,
      buttons: {
        filter: {
          isVisible: true,
          isDisabled: false,
          action: () => this.filter(),
          text: 'Filter'
        }, download: {
          isVisible: true,
          isDisabled: false,
          action: () => this.downloadXls(),
          text: 'Download xls'
        }, print: {
          isVisible: false,
          isDisabled: false,
          action: null,
          text: 'Print'
        }
      }
    };
  }

  convertToNumber(numberStr) {
    return Number(numberStr.replace(',',''));
  }

  getReportRowValue(row, columnNumber) {
    return row['cells'][columnNumber].value;
  }

  async filter() {
    this.rows = [];
    const reportModels = await this.reportService.downloadJson(this.request);
    this.rows = reportModels[0].rows;
  };

  downloadXls() {
    this.reportService.downloadXls({
      reportCode: this.reportCode,
      params: this.params,
      reportName: this.fileName
    });
  }
}

module.component('customerLayawayPaymentsReportView', {
  templateUrl: templateUrl,
  bindings: {
    customerId: '<',
    showAddItem: '<',
    checkedMode: '=',
    checkedItems: '='
  },
  controller: CustomerLayawayPaymentsReportViewComponent
});
