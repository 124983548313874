import module from 'module';
import { healthCheckPhase } from 'constants/healthCheckPhase';
import './health-check-list.component.less';
import templateUrl from './health-check-list.template.html';

class HealthCheckList {
  constructor() {
    this.statuses = [];
    this.healthCheckPhase = healthCheckPhase;
  }

  isFailed(checkList) {
    return checkList.some(val => val.status === 'FAILED');
  }

  checkStatus(checkList) {
    return {
      isFailed: this.isFailed(checkList)
    }
  }

  $onChanges(changes) {
    if (changes.checks && changes.checks.currentValue) {
      const checks = changes.checks.currentValue;
      this.statuses = Object.entries(checks).map(value => this.checkStatus(value[1]));
    }
  }
}

module.component('healthCheckList', {
  templateUrl,
  bindings: {
    checks: '<',
    hideOk: '<',
    phaseType: '<'
  },
  controller: HealthCheckList
});