import module from 'module';
import _ from 'lodash';
import angular from 'angular';

const templateUrl = require('./edit-account-ata.template.html');
module.component('customerAccountEditAta', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, http, customerCache, commandMetadataCache, ataCache,
                        command, confirmation, notification, productDefinitionService) {
    const that = this;
    const customerId = $route.current.params['customerId'];
    const accountId = $route.current.params['accountId'];

    that.command = {updateAta: false};

    const copyOrInitArray = (input) => input && input.length > 0 ? angular.copy(input) : [];

    // Fetch account details
    const s1 = customerCache.depositAccounts(customerId).toObservable()
      .subscribe(accounts => {
        that.account = _.find(accounts, {id: Number(accountId)});
        that.command.productId = that.account.id;
        that.command.ataRelationsIds = copyOrInitArray(that.account.ataRelationsIds);
      });

    // Fetch ATA associated with account
    const s2 = ataCache.withParam(accountId).toObservable()
      .subscribe(ata => {
        that.ata = ata;
        that.command.enabled = ata.enabled;
        that.command.maxRelations = ata.maxRelations;
        that.command.allowExternalRelations = ata.allowExternalRelations;
        that.command.relationDefinitionsIds = copyOrInitArray(ata.relationDefinitionsIds);
        that.command.triggerCommands = copyOrInitArray(ata.triggerCommands);
        that.command.creditCommands = copyOrInitArray(ata.creditCommands);
      });

    // Fetch product definitions
    const s3 = productDefinitionService.toObservable()
      .subscribe(productTypes => {
        that.productTypes = _.filter(productTypes, {productGroup: 'ACCOUNT'});
        that.relationDefinitionsIds = _.map(that.productTypes, t => {
          return {label: t.productName, value: t.id}
        })
      });

    // Fetch ATA trigger & credit commands
    const s4 =commandMetadataCache.toObservable()
      .subscribe(commands => {
        that.triggerCommands = {};
        that.creditCommands = {};
        if (commands && commands.length > 0) {
          // Function to filter commands by tag and convert results to label/value form
          // label -> human readable command name, value -> command alias
          const extract = (tag) => {
            return _.map(_.filter(commands, c => c.alias && _.includes(c.tags, tag)), c => {
              return {label: $filter('startCase')(c.alias), value: c.alias}
            })
          };
          that.triggerCommands = extract('ATA_TRIGGER');
          that.creditCommands = extract('ATA_CREDIT');
        }
      });

    that.validateRelations = () => {
      that.relationsValid = true;
      // If selected relations are null or empty -> leave method
      const relationsIds = that.command.ataRelationsIds;
      if (!relationsIds || relationsIds.length === 0) return true;
      // If at least one selected relation is not present in [validRelations] map -> return false
      const validRelationsIds = _.map(that.validRelations, 'id');
      const diff = _.difference(relationsIds, validRelationsIds);
      that.relationsValid = !diff || diff && diff.length === 0;
      return that.relationsValid;
    };

    that.reloadRelations = () => {

      that.validRelations = [];
      if (that.allRelations && that.allRelations.length > 0) {
        // Exclude relations not matching allowed definitions
        const definitions = that.command.relationDefinitionsIds;
        let relations = _.filter(that.allRelations, r => _.includes(definitions, r.definitionId));
        // Exclude accounts of related people if they are not allowed
        const allowExternal = that.command.allowExternalRelations;
        if (!allowExternal) relations = _.filter(relations, {customerId: Number(customerId)});
        // Keep valid relations for further use
        that.validRelations = relations;
      }

      // Reload multiselect options to contain current ATA relations & all missing (deprecated) relations
      const multiselectIds = _.union(that.command.ataRelationsIds, _.map(that.validRelations, 'id'));
      that.multiselectRelations = _.map(multiselectIds, id => {
        return {
          value: id,
          label: function () {
            const account = _.find(that.allRelations, {id: id});
            return account ? account.productNumber : 'Invalid product: ' + id;
          }()
        };
      });

      // Validate existing relations
      that.validateRelations();
    };

    // Fetch all accounts belonging to customer (along with relatives)
    // All accounts are fetched to avoid refetching of accounts on each
    // change of [allowExternalRelations] change
    const s5 = customerCache.profile(customerId).toObservable()
      .subscribe(profile => {
        that.allRelations = [];
        that.validRelations = [];
        // Create array containing id of current customer
        // and ids of all of relatives (if exists)
        let customerIds = [Number(customerId)];
        if (profile && profile.relatives && profile.relatives.length > 0) {
          customerIds = customerIds.concat(_.map(profile.relatives, 'relativeCustomerId'));
        }
        // Fetch active & dormant accounts for [customerIds]
        const criteria = {customerId: customerIds, productStatus: ['ACTIVE', 'INACTIVE']};
        http.post('/products/accounts/search', criteria, {nxLoaderSkip: true}).success(data => {
          if (data && data.result && data.result.length > 0) {
            // Exclude current account from options
            that.allRelations = _.filter(data.result, a => Number(a.id) !== Number(accountId));
          }
          that.reloadRelations();
        });
      });

    that.productTypeLabel = (id) => {
      const type = _.find(that.productTypes, {id: id});
      return type ? type.productName : 'Unknown product';
    };

    that.accountLabel = (id) => {
      const account = _.find(that.allRelations, {id: id});
      return account ? account.productNumber : 'Invalid account';
    };

    that.redirect = () => {
      $location.path(`/customer/${customerId}/accounts/${accountId}`);
    };

    that.redirectBack = () => {
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => that.redirect());
    };

    that.update = () => {
      confirmation(`Do you want to update the "${that.account.productNumber}" account?`, () => {
        command.execute('UpdateAccountAutomaticTransferAgreement', that.command).success(() => {
          customerCache.depositAccounts(customerId).refetch();
          ataCache.withParam(accountId).refetch();
          that.redirect();
        });
      });
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
      s2.unsubscribe();
      s3.unsubscribe();
      s4.unsubscribe();
      s5.unsubscribe();
    };
  }
});
