import module from 'module';
import _ from 'lodash';

const templateUrl = require('./role-write-access.template.html');
module.component('roleWriteAccess', {
  templateUrl,
  bindings: {
    // INPUT: role
    'role': '=',

    // "OUTPUT" params:
    // list of access rules for the provided role
    'roleAccessRules': '=',
    // list of access rules ids to be deleted from the provided role
    'roleAccessRulesToDelete': '='
  },
  controller: function ($scope, $filter, accessRuleDefinitionCache, accessRuleCache) {
    const that = this;
    that.roleAccessRules = [];
    that.roleAccessRulesToDelete = [];
    that.availablePredicates = {};

    that.executeOptions = [{
      label: 'Cannot execute',
      value: {
        executeWithApproval: false,
        execute: false
      }
    },{
      label: 'Execute',
      value: {
        executeWithApproval: false,
        execute: true
      }
    },{
      label: 'Execute with approval',
      value: {
        executeWithApproval: true,
        execute: false
      }
    }];

    accessRuleCache.toObservable().first().subscribe(rules => {
      that.rules = rules;
      mapRules();
    });

    function mapRules() {
      if (!that.role || !that.rules) return;

      that.roleAccessRules = (_.filter(that.rules, {roleId: that.role.id}) || []).map(r => {
        r.displayName = $filter('translateEnum')(r.command, 'COMMAND');
        r.executePermission = _.find(that.executeOptions, {
          value: {
            executeWithApproval: r.executeWithApproval,
            execute: r.execute
          }
        });
        return r;
      });
    }

    accessRuleDefinitionCache.toObservable().first().subscribe(data => {
      _.forEach(data, d => {
        d.displayName = $filter('translateEnum')(d.command, 'COMMAND');
      });
      that.availableCommands = data;
      that.availablePredicates = {};
      _.forEach(data, d => {
        that.availablePredicates[d.command] = _.keyBy(d.predicates, 'name');
      });
    });

    that.hasPredicates = (command) => {
      return that.availablePredicates[command] && Object.keys(that.availablePredicates[command]).length > 0;
    };

    that.displayCommandSystemName = (command) => {
      //by default if translation do not exist for command display `name | startCase`
      //show system name only for missing transalation
      return $filter('startCase')(command.command) != command.displayName;
    };

    $scope.$watch('$ctrl.role', () => {
      that.roleAccessRules = [];
      mapRules();
    });

    that.addRule = (command) => {
      let rule = {
        command: command.command,
        roleId: that.role.id,
        predicates: {},
        approve: false,
        executePermission: that.executeOptions[0]
      };
      that.executeWithApprovalUpdated(rule);
      that.roleAccessRules.push(rule);
    };

    that.executeWithApprovalUpdated = (rule) => {
      rule.executeWithApproval = rule.executePermission.value.executeWithApproval;
      rule.execute = rule.executePermission.value.execute;
    };

    that.removeRule = (rule) => {
      const idx = that.roleAccessRules.indexOf(rule);
      if (idx > -1) {
        that.roleAccessRules.splice(idx, 1);
      }
      if(rule.id) {
        that.roleAccessRulesToDelete.push(rule.id);
      }
    };

    that.addPredicate = (rule, predicateName) => {
      if(rule.predicates[predicateName]) return; //predicate already added

      rule.predicates[predicateName] = null;
    };
  }
});
