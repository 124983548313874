import module from 'module';
import {BigNumber} from 'bignumber.js/bignumber';
import templateUrl from './forex-calculator.template.html'

class ForexCalculator {
  constructor() {
    this.entities = [];
    this.addEntry();
  }

  addEntry() {
    this.entities.push({
      amount: 0,
      exchangeRate: 0
    });
    this.recalculate();
  }

  removeEntity(index) {
    this.entities.splice(index, 1);
    this.recalculate();
  }

  recalculate() {
    const totalAmount = this.entities
      .map(entry => new BigNumber(entry.amount))
      .reduce((a, b) => a.plus(b), new BigNumber(0));

    const productsOfAmountsAndExchangeRates = this.entities
      .map(entry => (new BigNumber(entry.amount)).times(new BigNumber(entry.exchangeRate)))
      .reduce((a, b) => a.plus(b), new BigNumber(0));

    this.weightedAverageExchangeRate = productsOfAmountsAndExchangeRates.div(totalAmount).toString();
    this.totalAmount = totalAmount.toString();
  }
}

module.component('forexCalculator', {
  templateUrl,
  controller: ForexCalculator
});
