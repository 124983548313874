import module from 'module';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import 'rxjs/add/operator/distinctUntilChanged';
import _ from 'lodash';
import AccessType from 'constants/customerAccessType';

/**
 * Oracle returning currently active customer.
 * Exposes a stream of active customer changes.
 */
class ActiveCustomerTypeService {
  constructor() {
    this.customerIdSubject = new ReplaySubject(1);
  }

  updateCustomerId(customerId) {
    this.customerIdSubject.next({customerId: String(customerId)});
  }

  customerIdChanged() {
    return this.customerIdSubject.distinctUntilChanged(_.isEqual);
  }
}

module.factory('activeCustomerService', () => {
  const activeCustomerTypeService = {};
  for(let type of Object.values(AccessType)) {
    activeCustomerTypeService[type] = new ActiveCustomerTypeService()
  }

  return {
    ofType: (accessType) => {
      const service = activeCustomerTypeService[accessType];
      if(service) {
        return service;
      }

      console.error("Access type not supported:", accessType)
    }
  }
});