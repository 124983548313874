import module from 'module';

const templateUrl = require('./customer-profile-header.template.html');
module.component('customerProfileHeader', {
  templateUrl: templateUrl,
  transclude: true,
  bindings: {
    /**
     * Either 'CUSTOMER' or 'GROUP'
     * If null, the default of CUSTOMER is chosen.
     */
    mode: '<'
  },
  controller: function ($scope, $route, branchService, fileService, notification, customerCache, groupCustomerCache) {
    let that = this;

    let branchId = null;
    let branchNames = {};

    let customerId = $route.current.params['customerId'];

    const cache = that.mode === 'GROUP' ? groupCustomerCache : customerCache;

    cache.profile(customerId).toObservable().subscribe(profile => {
      that.customerProfile = profile;

      if (profile && profile.id) {
        that.customerName = profile.effectiveName;
        that.customerNumber = profile.customerNumber;
        branchId = profile.branchId;
        refreshBranchName();
      }

      branchService.toObservable().subscribe(branches => {
        branches.forEach(b => branchNames[b.id] = b.name);
        refreshBranchName();
      });
    });

    that.showAvatar = () => {
      if (!that.profilePicture) {
        fileService.downloadFile(that.customerProfile.pictureFileId, true, true)
          .success(file => {
            that.profilePicture = window.URL.createObjectURL(file);
            that.showProfilePopup = true;
          })
          .error(error => {
            notification.show("Error", "Could not loan profile picture");
            console.error(error);
          });
      } else {
        that.showProfilePopup = true;
      }
    }

    that.showSignature = () => {
      const signatureFileId = that.customerProfile.individualData.signatureFileId;

      if (signatureFileId && !that.signature) {
        fileService.downloadFile(signatureFileId, false)
          .success(file => {
            that.signature = window.URL.createObjectURL(file);
          })
          .error(error => {
            notification.show("Error", "Could not show signature");
            that.showPopup = false;
            console.error(error);
          });
      }
      that.showPopup = true;
    };

    function refreshBranchName() {
      if (branchId) {
        that.branchName = branchNames[branchId];
      }
    }

    that.$onDestroy = () => {
      if (that.signature) {
        window.URL.revokeObjectURL(that.signature);
      }
    }

  }
});
