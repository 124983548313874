import module from 'module';
import _ from 'lodash';

import templateUrl from './amount-condition.template.html';
import './amount-condition.style.less';

class AmountConditionComponent {
  constructor($scope) {
    this.$scope = $scope;
  }

  $onInit() {
    this.type = 'AMOUNT';

    this.$scope.$watchCollection('$ctrl.conditions', () => {
      if (!this.propertyDefinition) {
        return;
      }

      if (!this.isConditionAlreadyDefined()) {
        this.setDefaultValues();
      } else {
        this.fetchExistingValues();
      }
    });

    this.valueChanged = () => {
      this.saveValues();
    };
  }

  setDefaultValues() {
    this.model = {
      param1: null,
      param2: null
    };
    this.predicate = null;
  }

  fetchExistingValues() {
    this.model = this.getCondition().model;
    this.predicate = this.getCondition().predicate;
  }

  saveValues() {
    this.conditions[this.propertyDefinition.property] = {
      ...this.getCondition(),
      type: this.propertyDefinition.type,
      model: this.model,
      predicate: this.predicate
    };
  }

  isConditionAlreadyDefined() {
    return _.has(this.conditions, this.propertyDefinition.property);
  }

  getSupportedPropertyDefinitions() {
    return this.propertyDefinitions
      .filter(pd => pd.type === this.type)
      .filter(pd => !this.getCondition().properties.includes(pd.property));
  }

  getPropertyLabel(property) {
    return this.propertyDefinitions
      .find(pd => pd.property === property).label;
  }

  getCondition() {
    return this.conditions[this.propertyDefinition.property];
  }

  addProperty(propertyDefinition) {
    this.getCondition()
      .properties.push(propertyDefinition.property);
  }
}

module.component('amountCondition', {
  templateUrl,
  bindings: {
    conditions: '=',
    propertyDefinition: '<',
    propertyDefinitions: '<'
  },
  controller: AmountConditionComponent
});
