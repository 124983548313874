import module from 'module';
import _ from 'lodash';

import templateUrl from './risk-categories.template.html';
import './risk-categories.style.less';

class RiskCategoriesView {
  constructor(http, systemPropertyCache) {
    this.http = http;
    this.systemPropertyCache = systemPropertyCache;
  }

  async $onInit() {
    [this.properties, this.riskCategories] = await Promise.all([this.systemPropertyCache.toPromise(), this.http.get('/management/risk/categories').toPromise()]);
    const corpoProp = _.find(this.properties, {code: 'CIF_CORPORATE_SUPPORT'});
    this.corporateCustomerEnabled = corpoProp && corpoProp.value === 'TRUE';

    this.riskCategories = this.riskCategories.map(
      r => ({
        ...r,
        individualPercentage: r.customerTypes.includes('INDIVIDUAL') ? r.weightPercentage : null,
        corporatePercentage: r.customerTypes.includes('CORPORATE') ? r.weightPercentage : null
      })
    );

    this.totalIndividualPercentage = _.sumBy(this.riskCategories, it => it.individualPercentage);
    this.totalCorporatePercentage = _.sumBy(this.riskCategories, it => it.corporatePercentage);
  }
}

module.component('riskCategoriesView', {
  templateUrl,
  controller: RiskCategoriesView
});
