import module from 'module';
import {NgTableParams} from 'ng-table/ng-table.js';
import _ from 'lodash';

const templateUrl = require('./checkbook-details.template.html');
module.component('checkbookDetails', {
  templateUrl: templateUrl,
  transclude: true,
  bindings: {
    productId: '<',
    customerId: '<'
  },
  controller: function (http, $filter, $location, checkbookCache, customerCache, confirmationTemplate, popup, command) {
    let that = this;

    that.checkbooks = [];
    that.checkbookId = null;
    that.checkbook = undefined;

    that.checkbookDetailsConfig = new NgTableParams({
      count: 15,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: params => {
        //Since backend do not support the pagination it is required to do it on frontend.
        //Without array slicing, ng-table will display all data, despite the count parameters
        if (that.entries) {
          return Promise.resolve(that.entries.slice((params.page() - 1) * params.count(), params.page() * params.count()));
        } else {
          return Promise.resolve([]);
        }
      }
    });

    const reloadEntries = () => {

      // By default fetch entries for all checkbooks
      // If certain checkbook is selected -> fetch only checkbook related entries
      let dataEndpoint = `/products/accounts/${that.productId}/checkbooks/entries`;
      if (that.checkbookId && that.checkbookId > 0) {
        dataEndpoint = `/products/accounts/${that.productId}/checkbooks/${that.checkbookId}/entries`;
      }

      // Create data promise
      http.get(dataEndpoint, {}, {nxLoaderText: 'Loading checkbook entries'}).success((entries) => {

        that.entries = entries;
        that.checkbookDetailsConfig.total(that.entries.length);
        that.checkbookDetailsConfig.reload();

        // If checkbook is selected -> verify entries status
        // If all entries are untouched (status === 'NOT_ISSUED') checkbook can be removed
        if (that.checkbook) that.checkbook.removable = !entries || entries.every((e) => e.status === 'NOT_ISSUED');
      })
    };

    that.onCheckbookChange = () => {
      that.checkbook = undefined;
      if (that.checkbookId) that.checkbook = _.find(that.checkbooks, {id: that.checkbookId});
      reloadEntries();
    };

    const checkbooksSub = checkbookCache.withParam(that.productId).toObservable().subscribe(page => {
      // Extend result checkbook with label
      // If checkbook has non-default status add it to label
      that.checkbooks = _.map(page.result, c => {
        c.label = c.number;
        if (c.status !== 'ISSUED') c.label = `${c.number} (${$filter('prettyEnum')(c.status)})`;
        return c;
      });
      // If there is only 1 checkbook -> set it as selected
      if (that.checkbooks && that.checkbooks.length === 1) that.checkbookId = that.checkbooks[0].id;
      // Load checkbook(s) entries
      that.onCheckbookChange();
    });

    const createCheckbookDetails = (checkbook) => {
      return [
        {label: 'Number', description: checkbook.number},
        {label: 'Issued on', description: $filter('prettyDate')(checkbook.issuedOn)},
        {label: 'First check', description: checkbook.startNumber},
        {label: 'Last check', description: checkbook.endNumber}
      ];
    };

    const refreshCheckbooks = () => {
      checkbookCache.withParam(that.productId).refetch();
      customerCache.depositAccounts(that.customerId).refetch();
      $location.path(`/customer/${that.customerId}/accounts/${that.accountId}`)
    };

    that.remove = (checkbookId) => {
      confirmationTemplate({
        question: `Do you want to remove checkbook?`,
        warning: 'This operation cannot be reverted.<br>Please make sure that the checkbook data is correct.',
        details: createCheckbookDetails(that.checkbook),
        yesCallback: () => command.execute('RemoveCheckbook', {id: checkbookId}).success(() => {
          popup({text: `Checkbook removed`, callback: refreshCheckbooks});
        }, true)
      });
    };

    that.cancel = (checkbookId) => {
      confirmationTemplate({
        question: `Do you want to cancel checkbook?`,
        details: createCheckbookDetails(that.checkbook),
        yesCallback: () => command.execute('CancelCheckbook', {id: checkbookId}).success(() => {
          popup({text: `Checkbook status changed to: Canceled`, callback: refreshCheckbooks});
        }, true)
      });
    };

    that.$onDestroy = () => checkbooksSub.unsubscribe();
  }
});
