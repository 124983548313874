import module from 'module';

import templateUrl from './transaction-categories.template.html'

class TransactionCategories {

  constructor(transactionCategoryService, $location) {
    this.transactionCategoryService = transactionCategoryService;
    this.$location = $location;
  }

  onRemove(category) {
    this.transactionCategoryService.remove(category, this.actionType);
  }

  translate(actionType) {
    let translations = {
      OFFICIAL_RECEIPT_IN_CASH: 'Official receipt in cash',
      OFFICIAL_RECEIPT_IN_CHECK: 'Official receipt in check'
    };

    return translations[actionType];
  };

  onAdd() {
    this.$location.path(`/admin/general-ledger/transactions/${this.actionType}`);
  };

  onEdit(category) {
    this.$location.path(`/admin/general-ledger/transactions/${this.actionType}/${category.id}`);
  };

}

module.component('transactionCategories', {
  templateUrl,
  bindings: {
    categories: '<',
    actionType: '<'
  },
  controller: TransactionCategories
});