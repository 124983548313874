import module from 'module';

const templateUrl = require('./update-user-asset-counter.template.html');

module.component('updateUserAssetCounter', {
  templateUrl,
  controller: function (confirmationTemplate, http, popup, $filter, $route, command, branchService, userCache) {
    const that = this;

    that.branches = [];
    that.branch = null;
    that.users = [];
    that.branchUsers = [];
    that.user = null;
    that.userCash = null;
    that.userCheck = null;
    that.command = {userId: null, cashDiff: null, checkDiff: null};

    const s1 = branchService.toObservable().subscribe(branches => that.branches = branches);
    const s2 = userCache.toObservable().subscribe(users => that.users = users.filter(u => !u.phantom && !u.technical));

    that.onBranchChange = (branchId) => {
      that.onUserChange(null);
      that.branch = _.find(that.branches, (b) => Number(b.id) === Number(branchId));
      if (that.branch) {
        that.branchUsers = that.users.filter(u => Number(u.branchId) === Number(that.branch.id));
      }
    };

    that.onUserChange = (userId) => {

      that.userCash = null;
      that.userCheck = null;
      that.command.cashDiff = null;
      that.command.checkDiff = null;

      that.user = _.find(that.branchUsers, (u) => Number(u.id) === Number(userId));
      if (that.user) {
        http.get(`/management/users/${that.user.id}/counter`).success((counter) => {
          that.userCash = counter.totalCash;
          that.userCheck = counter.totalCheck;
        });
      }
    };

    that.execute = () => {
      confirmationTemplate({
        question: 'Do you want to update the user asset counter?',
        details: [
          {label: 'Branch name', description: $filter('prettyEnum')(that.branch.name)},
          {label: 'Username', description: that.user.username},
          {label: 'Increase cash by:', description: $filter('php')(that.command.cashDiff)},
          {label: 'Increase check by', description: $filter('php')(that.command.checkDiff)}
        ],
        yesCallback: () => command.execute('UpdateUserAssetCounter', that.command).success(() => $route.reload())
      })
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
      s2.unsubscribe();
    };
  }
});
