import module from 'module';

import templateUrl from './prints-page.template.html';

module.component('printsPage', {
  templateUrl,
  bindings: {
    headerLabel: '@',
    headerTitle: '@',
    backLink: '<'
  },
  transclude: true
});