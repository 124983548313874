import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('feeDefinitionsCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/products/fees'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.productFees,
    cacheName: 'productFees'
  })
);
