import _ from 'lodash';
import module from 'module';

class DenominationSerialNumbersService {
  constructor(popup, systemPropertyCache) {
    this.supportedCurrencies = ['USD'];
    this.popup = popup;

    systemPropertyCache.toPromise()
      .then(systemProperties => {
        const property = _.find(systemProperties, {code: 'FOREIGN_DENOMINATION_SERIAL_NUMBERS_ENABLED'});
        this.canSave = property && property.value === 'TRUE';
    });
  }

  appendSerialNumbers(currency, transaction) {
    if (!this.canSave || !this.supportedCurrencies.includes(currency.isoCode) || !transaction || !transaction.denominationBundle) {
      return;
    }

    if (transaction.denominationBundle.incoming && transaction.denominationBundle.incoming.units) {
      const list = currency.units.map(unit => ({id: unit.id, unitCashValue: unit.unitCashValue, serialNumbers: unit.incomingSerialNumbers}));
      this.append(list, transaction.denominationBundle.incoming.units, currency.isoCode);
    }

    if (transaction.denominationBundle.outgoing && transaction.denominationBundle.outgoing.units) {
      const list = currency.units.map(unit => ({id: unit.id, unitCashValue: unit.unitCashValue, serialNumbers: unit.outgoingSerialNumbers}));
      this.append(list, transaction.denominationBundle.outgoing.units, currency.isoCode);
    }
  }

  append(currencyNoteList, denominationBundleUnits, currencyIsoCode) {
    const processErrorMessage = (message, serialNumbers) => {
      for (let i = 0; i < serialNumbers.length; i++) {
        message += serialNumbers[i];
        if (i != serialNumbers.length - 1) {
          message += ', ';
        }
      }

      return message;
    }

    try {
      let duplicateSerialNumbersSet = new Set();
      let processedSerialNumbersSet = new Set();

      denominationBundleUnits.forEach(denominationBundleUnit => {
        const note = _.find(currencyNoteList, {id: denominationBundleUnit.currencyUnitId});

        // Check for equality between the count and serial numbers.
        const serialNumbersLength = (note.serialNumbers && note.serialNumbers.length) || 0;
        const difference = Math.abs(denominationBundleUnit.count - serialNumbersLength);
        if (difference != 0) {
          const numbers = difference > 1 ? 'numbers' : 'number';
          const missingOrExtra = serialNumbersLength < denominationBundleUnit.count ? 'Missing' : 'Extra';
          throw new Error(`${missingOrExtra} ${difference} serial ${numbers} for ${note.unitCashValue} ${currencyIsoCode}.`);
        }

        // Check for duplicate serial numbers from all denominations.
        if (serialNumbersLength) {
          note.serialNumbers.forEach(sn => {
            if (processedSerialNumbersSet.has(sn)) {
              duplicateSerialNumbersSet.add(sn);
            }

            processedSerialNumbersSet.add(sn);
          });

          denominationBundleUnit.serialNumbers = note.serialNumbers;
        }
      });

      if (duplicateSerialNumbersSet.size > 0) {
        throw new Error(processErrorMessage('Please remove the following duplicate serial numbers: ', Array.from(duplicateSerialNumbersSet)));
      }

      // Unprocessed serial numbers are extra inputs. Check for them here.
      let unprocessedSerialNumbers = []
      currencyNoteList.forEach(currencyNote => {
        (currencyNote.serialNumbers || []).forEach(serialNumber => {
          if (!processedSerialNumbersSet.has(serialNumber)) {
            unprocessedSerialNumbers.push(serialNumber);
          }
        });
      });

      if (unprocessedSerialNumbers.length) {
        throw new Error(processErrorMessage('Please remove the following extra serial numbers: ', unprocessedSerialNumbers));
      }
    } catch (error) {
      this.popup({
        header: 'Error',
        text: error.message,
        renderHtml: true
      });

      throw error;
    }
  };

  resetSerialNumbers(foreignCurrency) {
    if (this.canSave) {
      foreignCurrency.units
        .filter(unit => unit.type === 'NOTE')
        .forEach(note => {
          note.incomingSerialNumbers = [];
          note.outgoingSerialNumbers = [];
        });
    }
  }
}

module.factory('denominationSerialNumbersService', DenominationSerialNumbersService);