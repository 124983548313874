import _ from 'lodash';
import module from 'module';

class ActionService {
  constructor(http, notification, confirmation, popup) {
    this.http = http;
    this.notification = notification;
    this.confirmation = confirmation;
    this.popup = popup;
  }

  cancel(task, $event, success = _.noop) {
    if ($event) {
      $event.stopPropagation();
    }

    this.confirmation(`Do you want to cancel the action ?`, () => {
      this.http.put(`/tasks/${task.id}/status`, '"REJECTED"', {nxLoaderText: 'Canceling action...'}).success(() => {
        this.notification.show("The selected action was rejected.");
        success(task);
      }).error(data => this.errorHandler(data))
    });
  };

  pinEntered(task, pin, user, success = _.noop) {
    this.confirmation(`Do you want to approve the action?`, () => {
      this.http.put(`/tasks/${task.id}/status?approvalUserId=${user.id}&pin=${pin}`, '"APPROVED"', {
        nxLoaderText: 'Approving action...'
      }).success(() => {
        this.notification.show("The selected action was approved.");
        success(task);
      }).error(data => this.errorHandler(data))
    });
  };

  reject(task, $event, success = _.noop) {
    if ($event) {
      $event.stopPropagation();
    }

    this.confirmation(`Do you want to reject the action ?`, () => {
      this.http.put(`/tasks/${task.id}/status`, '"REJECTED"', {nxLoaderText: 'Rejecting action...'}).success(() => {
        this.notification.show("The selected action was rejected.");
        success(task);
      }).error(data => this.errorHandler(data))
    });
  };

  approve(task, $event, success = _.noop) {
    if ($event) {
      $event.stopPropagation();
    }

    this.confirmation(`Do you want to approve the action?`, () => {
      this.http.put(`/tasks/${task.id}/status`, '"APPROVED"', {nxLoaderText: 'Approving action...'}).success(() => {
        this.notification.show("The selected action was approved.");
        success(task);
      }).error(data => this.errorHandler(data))
    });
  };

  errorHandler(data) {
    let details = data.errorMessage ? data.errorMessage : "An unknown error occurred.";
    let message = `${details}`;
    let finePrint;
    if (data.commandId || data.requestUuid) {
      finePrint = 'Error code: ';
      if (data.commandId) finePrint += data.commandId;
      if (data.commandId && data.requestUuid) finePrint += '/';
      if (data.requestUuid) finePrint += `${data.requestUuid.substring(0,8)}`;
    }
    this.popup({header: 'Error', text: message, renderHtml: true, finePrint: finePrint});
  }
}

module.service('dashboardActionService', ActionService);
