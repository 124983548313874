import module from 'module';
import _ from 'lodash';
import moment from 'moment';

const templateUrl = require('./individual-data.template.html');

class IndividualDataComponent {
  constructor($scope, $timeout, dict, propertyConfigService) {
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.dictionary = dict;
    this.cfg = propertyConfigService;
  }

  $onInit() {
    this.uploadFile;

    this.dictionary.onLoadingComplete(() => {
      this.dict = this.dictionary;
    });

    this.$scope.$watch('$ctrl.profile.individualData.signatureFileId', () => {
      if (this.profile.individualData) {
        if (this.profile.individualData.signatureFileId) {
          this.profile.individualData.signatureFile = [{
            id: this.profile.individualData.signatureFileId
          }];
        }
      }
    });

    this.$scope.$watch('$ctrl.profile.pictureFileId', () => {
      if (this.profile.individualData) {
        if (this.profile.pictureFileId) {
          this.profile.pictureFile = [{
            id: this.profile.pictureFileId
          }];
        }
      }
    });

    this.$scope.$watch('$ctrl.profile.extractedPhones', async () => {
      // initialized if null
      if (!this.profile.extractedPhones.mobile) {
        this.profile.extractedPhones.mobile = {number: ''};
      }
      await this.prepareMobileNumberField();
      this.validateNumberPattern(this.mobileNumber);
    });
  }

  /**
   * Splits and stores mobile number into country prefix and actual individual's phone number
   */
  async prepareMobileNumberField() {
    this.defaultPrefix = await this.cfg.defaultValueAsync('customer', 'contact_data', 'mobile_number');
    const regex = new RegExp('^\\' + this.defaultPrefix);
    if (_.isEmpty(this.profile.extractedPhones.mobile)) {
      this.mobileNumber = '';
    } else {
      this.mobileNumber = this.profile.extractedPhones.mobile.number.replace(regex, '');
    }
  }

  validateNumberPattern(number) {
    // check if form is ready
    if (!this.form.mobileNumber) {
      return;
    }

    // validate if empty is allowed.
    // allow if mobile number is not required
    if (!this.cfg.required('customer', 'contact_data', 'mobile_number') && _.isEmpty(number)) {
      this.profile.extractedPhones.mobile.number = number;
      this.form.mobileNumber.$setValidity('pattern', true);
      return;
    }
    // otherwise check format
    const regex = new RegExp('^\\' + this.defaultPrefix + '\\d{10}$');
    const completeMobileNumber = this.defaultPrefix + number;
    const isValid = regex.test(completeMobileNumber);
    if (isValid) {
      this.profile.extractedPhones.mobile.number = completeMobileNumber;
    }
    this.form.mobileNumber.$setValidity('pattern', isValid);
  }

  calculateAge() {
    // Check if form is ready
    if (!this.form.birthDate) {
      return;
    }

    // Age = date_today - birth_date
    const birthDate = moment(new Date(this.profile.individualData.birthDate));
    const dateToday = moment();
    const age = dateToday.diff(birthDate, 'years');
    const isAgeValid = age > 17;

    this.profile.individualData.age = age;

    this.form.birthDate.$setValidity('validAge', isAgeValid);
    this.form.age.$setValidity('validAge', isAgeValid);
  }

  nameChanged() {
    this.onNameChange();
  }

  watchlistVerify() {
    this.onWatchlistVerify({
      input: {
        customerId: this.profile.id,
        customerType: this.profile.customerType,
        name1: this.profile.individualData.firstName,
        name2: this.profile.individualData.lastName,
        name3: this.profile.individualData.middleName
      }
    });
  }
}

module.component('customerProfileIndividualData', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'onNameChange': '&',
    'onWatchlistVerify': '&',
    'editMode': '='
  },
  controller: IndividualDataComponent
});
