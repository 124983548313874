import angular from 'angular'
import $ from 'jquery';

import module from 'module';
import CompositeRequest from 'shared/common/compositeRequest';
import contentDisposition from "content-disposition";

class FileService {
  constructor(http, fileCache) {
    this.http = http;
    this.fileCache = fileCache;
  }

  getMetadata(id) {
    return this.http.get(`/files/metadata/${id}`);
  }

  uploadFile(file, params = {}, config={}) {
    const fd = new FormData();
    fd.append('file', file);

    const {additionalData={}, } = config;
    Object.keys(additionalData).forEach(key => {
      fd.append(key, additionalData[key]);
    });

    return this.http.post(`/files?${$.param(params)}`, fd, {
      transformRequest: angular.identity,
      nxLoaderText: 'Uploading file...',
      headers: {'Content-Type': undefined},
      ...config,
    });
  }

  uploadFiles(files, success) {
    if (!files || files.length === 0) {
      return success([]);
    }

    const compositeRequest = CompositeRequest.create();
    const fileIds = [];
    let resolved = 0;
    files.forEach((file, index) => {
      compositeRequest.add(this.uploadFile(file)
        .success(fileId => {
          fileIds[index] = fileId.id;
          resolved += 1;
          if (resolved === files.length) {
            compositeRequest.add(success(fileIds))
          }
        }))
    });

    return compositeRequest;
  }

  updateRemarks(fileId, remarks, config = {}) {
    return this.http.put(`/files/metadata/${fileId}/remarks`, remarks, config);
  }

  deleteFile(fileId) {
    return this.http.doDelete(`/files/${fileId}`);
  }

  downloadFile(fileId, skipLoader, useCache) {
    if (skipLoader === undefined) skipLoader = false;

    if (!useCache) {
      return this.http.get(`/files/${fileId}`, {
        responseType: 'blob',
        nxLoaderSkip: skipLoader
      })
    } else {
      // use cache
      let successCallback = null;
      let errorCallback = null;

      this.fileCache.withParam(fileId).toObservable().first().subscribe(data => {
        if (successCallback) successCallback(data);
      }, err => {
        console.log('Error when fetching file: ', err);
        if (errorCallback) errorCallback(err);
      });

      const callbackWrapper = {
        success: callback => {
          successCallback = callback;
          return callbackWrapper;
        },
        error: callback => {
          errorCallback = callback;
          return callbackWrapper;
        }
      };
      return callbackWrapper
    }
  }

  parseHeadersForContentFilename(headers) {
    const contentDispositionHeader = headers('content-disposition');
    if (!contentDispositionHeader) {
      return null;
    }
    const disposition = contentDisposition.parse(contentDispositionHeader);
    const {filename,} = disposition.parameters;
    return filename;
  }
}

module.service('fileService', FileService);
