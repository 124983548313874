import module from "module";
import _ from "lodash";
import moment from 'moment';
import BigNumber from "bignumber.js";

const templateUrl = require('./close-account-check.template.html');
module.component('customerAccountsCloseAccountCheck', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, authentication, customerCache, branchService, depositoryAccountCache,
                        productDefinitionService, notification, accountOperationService) {

    let customerId = $route.current.params['customerId'];
    let productId = $route.current.params['accountId'];

    let that = this;

    that.checkingAccounts = [];
    that.depositAccount = {};
    that.interestForecast = {};
    that.terminationAmount = 0;

    that.command = {
      productId: productId,
      amount: null,
      validFrom: null,
      depositoryAccountId: null,
      micrNumber : '',
      checkNumber: null,
      payee: null,
      remarks: null
    };

    // Checking account config
    that.selectConfig = {
      placeholder: 'Select account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };

    that.onMicrNumberChange = (micrNumber) => {
      if (micrNumber && micrNumber.length === 31) {
        that.command.checkNumber = micrNumber.substr(0, 10);
      } else {
        that.command.checkNumber = null;
      }
    };

    const branchSub = branchService.toObservable().subscribe(branches => {
      const branchId = authentication.context.branchId;
      const authBranch = _.find(branches, {id: branchId});
      if (authBranch) {
        const systemDate = moment(authBranch.postingDate).toDate();
        that.systemDate = systemDate;
        that.command.validFrom = systemDate;
      }
    });

    const depositoryAccountSub = depositoryAccountCache.toObservable().subscribe(depositoryAccounts => {
      that.checkingAccounts = _.filter(depositoryAccounts, {accountType: 'CHECKING'});
    });

    customerCache.depositAccounts(customerId).toObservable()
      .combineLatest(productDefinitionService.toObservable(), (depositAccounts, products) =>
        depositAccounts.map(da => {
          const p = _.find(products, {id: da.definitionId});
          return Object.assign(da, {productName: p ? p.productName : '<Unknown product>'});
        })
      )
      .combineLatest(customerCache.profile(customerId).toObservable(), (depositAccounts, customer) => {
        that.command.payee = customer.effectiveName;
        return depositAccounts;
      })
      .first()
      .subscribe(accounts => {
        // productId is a string
        that.depositAccount = _.find(accounts, (a) => String(a.id) === productId);
        that.command.remarks = 'Closing of account no. ' + that.depositAccount.productNumber;
        that.readInterestForecast(that.depositAccount.id);
      });

    // Fetch termination interest forecast
    that.readInterestForecast = (accountId) => {
      http.get(`/products/accounts/${accountId}/interest?closing=true`, {nxLoaderText: 'Calculating interest'})
        .success((forecast) => {
          that.interestForecast = forecast;
          that.terminationAmount = new BigNumber(0)
            .plus(that.depositAccount.balance)
            .plus(forecast.netInterest)
            .round(2)
            .toNumber();
          // Fill close account command properties
          that.command.amount = that.terminationAmount;
        })
        .error(() => {
          notification.show("Error", "Failed to load interest forecast.")
        });
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${productId}`);

    that.close = () => {
      accountOperationService.closeWithCheck({
        customerId: customerId,
        ...that.command
      }, that.redirectBack);
    };

    that.$onDestroy = () => {
      depositoryAccountSub.unsubscribe();
      branchSub.unsubscribe();
    }
  }
});
