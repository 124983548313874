import module from 'module';
import ParameterizedLocalCache from 'shared/util/parameterized-local-cache';

module.factory('productLockCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (productId) => http.get(`/products/${productId}/locks`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.productLock,
    cacheName: 'productLockCache'
  })
);
