import module from 'module';
import 'components/customer/profile/profile-update.service';

const templateUrl = require('./preloader.template.html');
module.component('preloader', {
  templateUrl: templateUrl,
  controller: function () {
    // TODO: add spinner
  }
});
