import module from 'module';

const templateUrl = require('./new-loan-list.template.html');
module.component('customerLoanCreateList', {
  templateUrl: templateUrl,
  bindings: {
    loanType: '=',
  },
  controller: function ($scope, $route, $location) {
    let that = this;
    this.$onInit = () => {
      const loanUrl = that.loanType === 'group' ? 'group-loans' : 'loans';

      that.customerId = $route.current.params['customerId'];

      that.open = ({product, }) => {
        $location.path(`/customer/${that.customerId}/${loanUrl}/create/${product.id}`);
      };
    }
  }
});