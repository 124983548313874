import $ from 'jquery';
import _ from 'lodash';
import module from 'module';
import {NgTableParams} from 'ng-table/ng-table.js';
import '../../common/modal/modal-info.component'

const templateUrl = require('./pawned-items-list.template.html');
module.component('pawnedItemsList', {
  templateUrl: templateUrl,
  controller: function ($scope, $routeParams, $location, $filter, $route, http,
                        authentication, notification, confirmation, branchService, pawnCache, popup,
                        pawnItemTypeCache, pawnItemDefectTypeCache, pawnMetalRateCache, pawnAuctionCache,
                        pawnItemAttributeTypeCache, pawnMetalTypesCache, command, pawnService, pawnMetalDescriptionService,
                        modalInfo, pawnStatusMapper, pawnItemCache) {
    let that = this;

    that.pawnStatusMapper = pawnStatusMapper;

    const reportCode = 'PawnedItemsReport';

    that.params = {};

    that.metals = [];

    that.pawnItemTypes = [];
    that.pawnItemDefectTypes = [];
    that.pawnMetalRates = [];
    that.pawnItemAttributeTypes = [];


    that.checkedForWithdraw = [];
    that.chkSellAll = false;
    that.chkWithdrawAll = false;

    that.checkedForMoveToStock = [];
    that.chkMoveToStockAll = false;

    that.pawnItems = [];
    that.criteria = {
      category: null,
      type: null,
      defect: null,
      branch: null
    };

    that.filterConfig = {
      reportCode: reportCode,
      buttons: {
        filter: {
          isVisible: true,
          isDisabled: false,
          action: () => that.filter(),
          text: 'Filter'
        }, download: {
          isVisible: true,
          isDisabled: false,
          action: () => that.downloadXls(),
          text: 'Download xls'
        }, print: {
          isVisible: false,
          isDisabled: false,
          action: null,
          text: 'Print'
        }
      }
    };

    branchService.toObservable().subscribe(branches => {
      that.branches = branches;
    });

    pawnAuctionCache.systemAuctions().toObservable().subscribe(auctions => {
      that.auctions = auctions;
    });

    // Read pawn meta types
    pawnMetalTypesCache.toObservable().subscribe(types => that.metals = types);

    // Read pawn item types
    pawnItemTypeCache.toObservable().subscribe(itemTypes => {
      that.pawnItemTypes = itemTypes;
    });

    // Read pawn item defect types
    pawnItemDefectTypeCache.toObservable().subscribe(defectTypes => {
      that.pawnItemDefectTypes = defectTypes;
    });

    // Read pawn metal rates
    pawnMetalRateCache.toObservable().subscribe(rates => {
      that.pawnMetalRates = rates;
    });

    // Read pawn item attributes types
    pawnItemAttributeTypeCache.toObservable().subscribe(attributeTypes => {
      that.pawnItemAttributeTypes = attributeTypes;
    });


    that.getTypeLabel = (typeId) => {
      let type = _.find(that.pawnItemTypes, {id: typeId});
      return type && type.name ? type.name : '-';
    };

    that.filter = () => {
      that.pawnItems = [];
      that.checkedForWithdraw = [];
      that.checkedForMoveToStock = [];
      // go back to first page when data is reloaded
      that.tableConfig.page(1);
      that.tableConfig.reload();
    };

    that.downloadXls = () => {
      http.http({
        url: `/reports/${reportCode}/xls`,
        method: 'POST',
        responseType: 'blob',
        data: $.param(that.params),
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        nxLoaderText: 'Downloading report XLS'
      }).success(response => {
        const xlsxFileUrl = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = xlsxFileUrl;
        a.download = `Pawned_Items_Report.xlsx`;
        a.click();
        window.URL.revokeObjectURL(xlsxFileUrl);
      });
    };


    // Call service for default filter config
    that.tableConfig = new NgTableParams({
      count: 15,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: params => {
        that.currentBranchId = that.params.branchId || null;
        that.selectedAuctionIds = that.getSelectedAuctionIds();

        let typeIds = that.params.pawnItemTypeId;
        // selecting all types should only include those with parentId
        // that is equal to the selected categoryId
        if (that.params.pawnItemCategoryId && !_.first(typeIds)) {
          typeIds = that.pawnItemTypes
            .filter((type) => type.parentId === that.params.pawnItemCategoryId)
            .map((type) => type.id);
        }

        const queryParams = {
          pageNo: params.page() - 1,
          pageSize: params.count(),
          inStock: false,
          categoryId: that.params.pawnItemCategoryId || null,
          typeIds: typeIds,
          productStatus: that.params['productStatus[]'],
          branchIds: that.currentBranchId,
          auctionIds: that.selectedAuctionIds,
          ticketNumber: that.params.pawnTicketNumber || null,
          tagIds: that.params['pawnTagId[]'],
          productGroup: 'PAWN'
        };

        // Create pawn items search promise
        return http.get(`/products/pawns/items?${$.param(queryParams, true)}`, {nxLoaderText: 'Loading pawn items'})
          .success((items) => {
            that.chkWithdrawAll = false;
            that.chkSellAll = false;
            that.chkMoveToStockAll = false;
            that.pawnItems = items.result;
            that.tableConfig.data = that.pawnItems;
            that.tableConfig.total(items.totalCount);

            // For each pawn item prepare item details
            if (that.pawnItems && that.pawnItems.length > 0) {
              _.forEach(that.pawnItems, (pawnItem) => {
                //Set withdraw for pawn item if was selected
                pawnItem.withdraw = _.findIndex(that.checkedForWithdraw, {id: pawnItem.id}) > -1;

                // calculate box number
                pawnItem.boxNumber = pawnItem.withdrawBox ? pawnItem.withdrawBox.branchId + '-'
                  + pawnItem.withdrawBox.boxNumber : null;

                // Extend item with category/type/subtype labels
                Object.assign(pawnItem, {categoryLabel: that.getTypeLabel(pawnItem.categoryId)});
                Object.assign(pawnItem, {typeLabel: that.getTypeLabel(pawnItem.typeId)});
                Object.assign(pawnItem, {subtypeLabel: that.getTypeLabel(pawnItem.subtypeId)});
                // Extend item with details pane data
                Object.assign(pawnItem, {details: that.readItemDetails(pawnItem)});
                if (pawnItem.productId) {
                  pawnCache.withParam(pawnItem.productId).toObservable().first().subscribe(pawn => {
                    Object.assign(pawnItem, {pawn: pawn});
                  });
                } else {
                  let pawn = {
                    auctionDate: '-',
                    expirationDate: '-',
                    productNumber: '-'
                  };
                  Object.assign(pawnItem, {pawn: pawn});
                }

              })
            }
          });
      }
    });

    that.getSelectedAuctionIds = () => {
      if (that.hasAllAuctionsSelected()) {
        return that.auctions
          .filter(auction => auction.branchId === that.params.branchId)
          .map(auction => auction.id);
      }
      return that.params.pawnAuctionId;
    }

    that.hasAllAuctionsSelected = () => that.params.branchId && !_.first(that.params.pawnAuctionId);

    that.itemClicked = (item, $event) => {
      $event.stopPropagation();
      that.selectedItemId = item.id;
    };

    that.hideInlinePanel = () => {
      that.selectedItemId = null;
    };

    that.readItemDetails = (item) => {
      return pawnService.buildDescription(item, that.pawnItemDefectTypes, that.pawnItemTypes, that.metals, that.pawnItemAttributeTypes, that.pawnMetalRates);
    };

    that.checkWithdraw = (item) => {
      if (item.withdraw) {
        that.checkedForWithdraw.push(item);
      } else {
        const idx = _.findIndex(that.checkedForWithdraw, {id: item.id});
        if (idx > -1) {
          that.checkedForWithdraw.splice(idx, 1);
        }
      }
    };

    that.withdrawAll = () => {
      _.forEach(that.tableConfig.data, line => {
        if (!(line.status === 'PAWNED_EXPIRED' || line.productStatus === 'AUCTIONED')) {
          return;
        }

        const idx = _.findIndex(that.checkedForWithdraw, {id: line.id});
        if (that.chkWithdrawAll) {
          if (pawnService.isPawned(line) && idx === -1) {
            that.checkedForWithdraw.push(line);
            line.withdraw = true;
          }
        } else {
          line.withdraw = false;
          if (idx > -1) {
            that.checkedForWithdraw.splice(idx, 1);
          }
        }
      });
    };

    that.checkMoveToStock = (item) => {
      if (item.moveToStock) {
        that.checkedForMoveToStock.push(item);
      } else {
        const idx = _.findIndex(that.checkedForMoveToStock, {id: item.id});
        if (idx !== -1) {
          that.checkedForMoveToStock.splice(idx, 1);
        }
      }
    }

    that.checkMoveToStockAll = () => {
      _.forEach(that.tableConfig.data, line => {
        if (line.productStatus !== 'AUCTIONED') {
          return;
        }

        const idx = _.findIndex(that.checkedForMoveToStock, {id: line.id});
        if (that.chkMoveToStockAll) {
          if (idx === -1 && line.productStatus === 'AUCTIONED') {
            line.moveToStock = true;
            that.checkedForMoveToStock.push(line);
          }
        } else {
          line.moveToStock = false;

          if (idx !== -1) {
            that.checkedForMoveToStock.splice(idx, 1);
          }
        }
      });
    }

    that.moveToStockedItemAction = () => {
      let content = [];

      that.checkedForMoveToStock.forEach(pawnItem => {
        content.push({label: 'PT Number', value: pawnItem.productNumber});
      });

      const buttons = [{
        text: 'Move to Stock',
        action: async () => {
          modalInfo.closeModalWithBackdrop();
          const res = await command.execute('MoveAuctionedPawnItemsToStock', {itemIds: that.checkedForMoveToStock.map(item => item.id) }).toPromise();
          if (!res || res.approvalRequired) {
            return;
          }

          pawnItemCache.evict();
          pawnCache.evict();

          popup({
            text: 'Pawn items have been moved to stock.',
            callback: () => {
              $route.reload();
            }
          });
        },
        bclass: 'btn btn-primary'
      }];

      modalInfo.display(
        content,
        "Pawned items selected to move to stock",
        `Do you want to move ${that.checkedForMoveToStock.length} items to stock?`,
        buttons);
    }

    that.getAuctionNamesForCurrentBranch = () => {
      const auctions = _.filter(that.auctions, a => that.selectedAuctionIds.includes(a.id))
      return auctions != null ? auctions.map(a => a.name).join(", ") : null;
    }

    that.withdrawAction = () => {
      const branch = _.find(that.branches, {id: that.currentBranchId});
      const branchName = branch != null ? branch.name : null;
      const auctionsName = that.getAuctionNamesForCurrentBranch();

      const content = pawnMetalDescriptionService.buildSelectedItemsInfo(
        branchName,
        auctionsName,
        that.checkedForWithdraw
      );

      const buttons = [{
        text: 'Withdraw',
        action: async () => {
          modalInfo.closeModalWithBackdrop();

          const res = await command.execute('WithdrawPawnItem', {itemIds: that.checkedForWithdraw.map(el => el.id)}).toPromise();
          if (!res || res.approvalRequired) {
            return;
          }

          pawnItemCache.evict();
          pawnCache.evict();

          popup({
            text: `Created box number: ${branch.id + '-' + res.output.id}`,
            callback: () => {
              $route.reload();
            }
          });
        },
        bclass: 'btn btn-primary'
      }];

      modalInfo.display(
        content,
        "Pawned items selected for withdraw",
        `Do you want to withdraw ${that.checkedForWithdraw.length} items to head office?`,
        buttons);
    };

    that.viewSelected = () => {
      const branch = _.find(that.branches, {id: that.currentBranchId});
      const branchName = branch != null ? branch.name : null;
      const auctionsName = that.getAuctionNamesForCurrentBranch();

      const content = pawnMetalDescriptionService.buildSelectedItemsInfo(
        branchName,
        auctionsName,
        [...that.checkedForWithdraw]);

      modalInfo.display(content, "Selected pawned items");
    };

    that.remove = (item) => {
      confirmation('Do you want to removed the item?', () => {
        http.put(`/products/pawns/items/${item.id}/status`, '"CANCELLED"')
          .success(() => {
            notification.show("Success", "Item successfully removed");
            that.filter();
          })
          .error(() => {
            notification.show("Error", "Failed to remove item");
          });
      })
    };
  }
});
