import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('dictionaryCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/dictionaries'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.dict,
    cacheName: 'dictionaries'
  })
);