import module from 'module';
import _ from 'lodash';

import '../common/date-granted.component'
import {Subscription} from "rxjs/Subscription";

const templateUrl = require('./customer-loans-edit-information.template.html');
module.component('customerLoansEditInformation', {
  templateUrl: templateUrl,
  controller: function ($route, $location, dict, customerCache, command,
                        customerService, fileService, loanService, confirmation,
                        notification, http, loanProductsCache, depositAccountService) {
    let that = this;

    this.dict = dict;
    that.collateralFiles = [];
    that.validateFiles = false;

    let loanId = Number($route.current.params['loanId']);
    let customerId = Number($route.current.params['customerId']);

    const subscription = new Subscription();

    let loanSubscr = undefined;
    dict.onLoadingComplete(() => {
      loanSubscr = customerCache.loans(customerId)
        .toObservable().combineLatest(loanProductsCache.toObservable(), (loans, loanTypes) =>
          loans.map(loan => {
            // add loanProduct to loan object
            const type = _.find(loanTypes, {id: loan.typeId});
            return Object.assign(loan, {
              loanType: type
            });
          })
        ).combineLatest(customerCache.depositAccounts(customerId).toObservable(), (loans, accounts) => {

          that.loan = _.find(loans, el => el.id === loanId);

          // save accounts
          that.accounts = _.cloneDeep(accounts);
          that.cbuAccounts = [];
          that.pfAccounts = [];
          that.tpAccounts = [];

          for (let a of that.accounts) {
            if (a.status === 'CLOSED') continue;

            if (a.typeId === that.loan.loanType.cbuAccountTypeId) that.cbuAccounts.push(a);
            if (a.typeId === that.loan.loanType.pfAccountTypeId) that.pfAccounts.push(a);
            if (a.typeId === that.loan.loanType.tpAccountTypeId) that.tpAccounts.push(a);
          }

          // update contractual savings accounts
          return loans.map(loan => {
            let clonedLoan = _.cloneDeep(loan);
            clonedLoan.cbuAccount = _.find(that.accounts, {id: loan.cbuSavingsAccountId});
            clonedLoan.pfAccount = _.find(that.accounts, {id: loan.pfSavingsAccountId});
            clonedLoan.tpAccount = _.find(that.accounts, {id: loan.tpSavingsAccountId});
            return clonedLoan;
          });
        }).combineLatest(depositAccountService.toObservable(), (loans, accountTypes) => {
          for (let a of that.accounts) {
            let productNumber = a.productNumber;
            let productName = _.find(accountTypes, {id: a.typeId}).productDefinition.productName;
            a.label = `${productName} (${productNumber})`;
          }
          return loans;
        }).subscribe(loans => {
          if (loanId) {
            that.clonedLoan = angular.copy(that.loan);
            let cleanUnsecuredLoanSecurityId = _.find(that.dict['LOAN_SECURITY'], {code: 'CLN'});
            if (that.loan.loanInformation.loanSecurityId === cleanUnsecuredLoanSecurityId) {
              that.validateFiles = true;
            }

            that.collateralFiles = that.loan.collateralFileIds.map(fileId => ({
              id: fileId,
            }));
          }
        });

      subscription.add(loanSubscr);
    });


    subscription.add(customerCache.profile(customerId).toObservable()
      .subscribe(profile => {
        that.profile = profile;
      })
    );

    that.redirectBack = () => {
      $location.path(`/customer/${customerId}/loans/${loanId}`)
    };

    that.save = () => {
      let loanCopy = _.cloneDeep(that.clonedLoan);
      loanCopy.collateralFileIds = that.collateralFiles.map(file => file.id);

      confirmation('Do you want to update loan information?', () => {
        command.execute('EditLoanInformation', {
          productId: loanCopy.id,
          loanInformation: loanCopy.loanInformation,
          creationType: loanCopy.creationType,
          collateralFileIds: loanCopy.collateralFileIds,
          cbuSavingsAccountId: loanCopy.cbuSavingsAccountId,
          pfSavingsAccountId: loanCopy.pfSavingsAccountId,
          tpSavingsAccountId: loanCopy.tpSavingsAccountId,
          allowAccrual: loanCopy.allowAccrual
        }).success(() => {
          customerCache.loans(customerId).refetch();
          that.redirectBack();
        });
      });
    };

    that.$onDestroy = () => {
      subscription.unsubscribe();
    }
  }
});