import module from 'module';
import BigNumber from "bignumber.js";

import './layaway-cancel-view.style.less';

import templateUrl from './layaway-cancel-view.template.html';

class LayawayCancelView {
  constructor ($routeParams, $location, pawnItemCache, pawnItemTypeCache, authentication, notification, command,
               confirmationTemplate, actionCategoryCache, systemPropertyCache, glMappingsService, branchService,
               $filter, customerCache, confirmation, http) {
    this.$routeParams = $routeParams;
    this.$location = $location;
    this.pawnItemCache = pawnItemCache;
    this.pawnItemTypeCache = pawnItemTypeCache;
    this.authentication = authentication;
    this.notification = notification;
    this.command = command;
    this.confirmationTemplate = confirmationTemplate;
    this.actionCategoryCache = actionCategoryCache;
    this.systemPropertyCache = systemPropertyCache;
    this.glMappingsService = glMappingsService;
    this.branchService = branchService;
    this.$filter = $filter;
    this.customerCache = customerCache;
    this.confirmation = confirmation;
    this.http = http;
  }

  async $onInit() {
    const layawayId = parseInt(this.$routeParams.layawayId);
    this.request = {
      denominationBundle: null,
      refund: 0
    };

    this.layaway = await this.http.get(`/products/layaways/${layawayId}`).toPromise();
    this.layaway.accumulatedPayments = this.layaway.sellingPrice - this.layaway.balance;

    if (this.layaway.accumulatedPayments >= this.layaway.minimalPaymentsForRefund) {
      this.request.refund = new BigNumber(this.layaway.accumulatedPayments)
        .div(2)
        .toNumber();
    }
  }

  goBack() {
    this.$location.path(`/customer/${this.customer.customerId}/layaways/${this.layaway.id}`);
  }

  async cancel() {
    const confirmed = await this.confirmationTemplate({
      question: `Do you want to cancel this layaway?`,
      details: [
        {label: 'Refund', description: this.$filter('php')(this.request.refund)}
      ]
    });

    if (!confirmed) {
      return;
    }

    const request = {
      ...this.request,
      productId: this.layaway.id,
      customerId: this.layaway.customerId,
      branchId: this.layaway.branchId
    };

    const response = await this.command.execute('CancelLayaway', request).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.pawnItemCache.evict();
    await this.customerCache.layaways(this.layaway.customerId).refetch();
    this.$location.path(`/customer/${this.layaway.customerId}/layaways/`);
  }
}

module.component('layawayCancelView', {
  templateUrl: templateUrl,
  controller: LayawayCancelView
});
