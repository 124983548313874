import module from 'module';

class OperationService {
  constructor(http) {
    this.http = http;
  }

  fetchOperationDetails(operationId, options) {
    return this.http.get(`/products/operations/${operationId}`, options);
  }
}

module.service('operationService', OperationService);