import module from 'module';
import _ from "lodash";
import templateUrl from './users-filtered-list.template.html' ;

module.component('usersFilteredList', {
  templateUrl,
  bindings: {
    name: '=',
    username: '='
  },
  controller: function (authentication, $scope, $filter, $location,
                        command, userCache, branchService, confirmation) {

    this.editUser = (branchId, id) => {
      $location.path(`/admin/organization/users/${branchId}/user/${id}`);
    };

    let branchUsers = [];
    this.filteredUsers = [];
    this.filter = () => {
      if (!this.name && !this.username) {
        this.filteredUsers = branchUsers;
        return;
      }

      this.filteredUsers = branchUsers.filter(user => {
        let nameMatched = true;
        let usernameMatched = true;

        if (this.name) {
          const name = _.toLower(this.name);
          const fullName = _.toLower(user.fullName);
          nameMatched = fullName.includes(name);
        }

        if (this.username) {
          const username = _.toLower(this.username);
          usernameMatched = _.toLower(user.username).includes(username);
        }

        return nameMatched && usernameMatched;
      });

      // if there's only 1 user, open it
      if (this.filteredUsers.length === 1) {
        const user = this.filteredUsers[0];
        this.editUser(user.branchId, user.id);
      }
    };

    $scope.$watch("$ctrl.name", () => {
      this.filter();
    });
    $scope.$watch("$ctrl.username", () => {
      this.filter();
    });

    const userSub = userCache.toObservable().subscribe(async (users) => {
      const branchesArr = await branchService.toPromise();
      const branches = _.keyBy(branchesArr, b => b.id);

      branchUsers = users.filter((user) => {
        const branchUser =  authentication.context.branchIds.includes(user.branchId);
        return !user.phantom && !user.technical && branchUser;
      }).map((user) => {
        user.allRoles = user.roles.map(r => $filter('prettyEnum')(r.name)).join(', ');
        user.branchName = branches[user.branchId].name;
        return user;
      });
      this.filter();
    });

    this.deleteUser = (id) => {
      confirmation('Are you sure you want to delete this user?' +
        ' Please note that this action cannot be reversed and the username cannot be used again ' +
        'as it must stay in the system for audit purposes.', () => {
        command.execute('DeleteUser', {id: id}).success(() => {
          userCache.refetch();
        });
      });
    };

    this.$onDestroy = () => {
      userSub.unsubscribe();
    };
  }
});
