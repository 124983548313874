import _ from "lodash";
import moment from "moment";
import module from "module";
const templateUrl = require('./report-details.template.html');

module.component('reportDetails', {
  templateUrl,
  controller: function ($scope, $routeParams, $location, http, breadcrumbs, reportService) {
    const that = this;
    const reportType = _.get($routeParams, 'reportType');
    const reportCode = _.get($routeParams, 'reportCode');
    const reportTypeName = reportType.replace(/-/g, ' ');

    that.descriptor = {};
    that.reportGenerated = false;
    that.reportContent = null;
    that.reportColumnsNum = null;
    that.filterConfig = {reportCode: reportCode};

    that.params = {};

    const isPrintDisabled = () => {
      return !that.reportContent || that.reportContent.every(content => content.rows.length === 0);
    };

    const outputSupported = (type) => {
      return that.descriptor
        && that.descriptor.outputTypes
        && that.descriptor.outputTypes.includes(type);
    };

    const setupButtons = () => {
      that.filterConfig.buttons = {
        filter: {
          isVisible: outputSupported('UI_MODEL'),
          isDisabled: false,
          action: () => that.loadReportContent(),
          text: 'View'
        }, download: {
          isVisible: outputSupported('XLS'),
          isDisabled: false,
          action: () => reportService.downloadXls({
            reportCode: reportCode,
            params: that.params,
            reportName: that.descriptor.name
          }),
          text: 'Download xls'
        }, print: {
          isVisible: outputSupported('UI_MODEL'),
          isDisabled: isPrintDisabled(),
          action: () => window.print(),
          text: 'Print'
        }, downloadBSRFile: {
          isVisible: reportCode === 'SSSPaymentFileReport',
          isDisabled: false,
          action: () => reportService.downloadBsrFile(that.params),
          text: 'Download e-payment file',
        }, downloadCustomFile: {
          isVisible: outputSupported('CUSTOM_FILE'),
          isDisabled: false,
          action: () => reportService.downloadCustomFile({reportCode: reportCode, params: that.params}),
          text: 'Download file'
        }
      };
    };

    http.get(`/reports/descriptors/${reportCode}`)
      .success(d => {
        that.descriptor = d;
        that.descriptor.params = _.union([], d.mandatoryParams, d.optionalParams);
        setupButtons();
        _.set(breadcrumbs, 'options', {
          'report-list': reportTypeName,
          'report-details': that.descriptor.name
        });
      });

    that.loadReportContent = async () => {
      that.reportContent = await reportService.downloadJson({reportCode: reportCode, params: that.params});
      that.filterConfig.buttons.print.isDisabled = isPrintDisabled();
    };

    that.reportRightPadding = (sheet) => {
      return that.reportColumnsNum - sheet.xoffset - sheet.headers.length;
    };

    that.displaySummary = (report) => {
      if (report && report.summaryRow) {
        return _.some(report.summaryRow, (cell) => cell !== null);
      }
      return false;
    };

    that.months = [{
      month: 1, label: 'January'
    }, {
      month: 2, label: 'February'
    }, {
      month: 3, label: 'March'
    }, {
      month: 4, label: 'April'
    }, {
      month: 5, label: 'May'
    }, {
      month: 6, label: 'June'
    }, {
      month: 7, label: 'July'
    }, {
      month: 8, label: 'August'
    }, {
      month: 9, label: 'September'
    }, {
      month: 10, label: 'October'
    }, {
      month: 11, label: 'November'
    }, {
      month: 12, label: 'December'
    }];

    // init years
    that.years = [];
    const year = moment().year();
    for (let i = 0; i < 5; i++) {
      that.years.push(year - i);
    }

    that.$onDestroy = () => {
      $location.search({});
    }
  }
});
