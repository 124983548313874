import module from 'module';
import templateUrl from './pawn-tag-predicate.template.html';

class PawnTagPredicate {

  constructor($scope, pawnTagCache) {
    this.$scope = $scope;
    this.type = 'AVAILABLE_TAGS';
    this.pawnTagCache = pawnTagCache;
    this.value = [];
    this.tags = [];
    this.predicates = {};
  }

  async $onInit() {
    this.tags = await this.pawnTagCache.toPromise();
    if (!this.predicates || Object.keys(this.predicates).length === 0) {
      this.value = this.tags.flatMap(t => t.id);
      this.valueChanged();
    } else {
      this.value = this.predicates[this.type];
    }

    this.$scope.$watch('$ctrl.value', () => this.valueChanged());
  };

  valueChanged() {
    this.predicates[this.type] = this.value;
  }

}

module.component('pawnTagPredicate', {
  templateUrl,
  bindings: {
    predicates: '='
  },
  controller: PawnTagPredicate
});
