import module from 'module';
import $ from 'jquery';
import _ from 'lodash';
import {NgTableParams} from 'ng-table/ng-table.js';

const templateUrl = require('./outgoing-checks-list.template.html');
module.component('outgoingChecksList', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $location, $filter, $timeout, http, authentication, confirmation,
                        notification, branchService, confirmationTemplate, command, popup) {
    const that = this;

    that.checkStatuses = [
      'ISSUED',
      'CREDITED',
      'PENDING_CLEARING',
      'CLEARING_ERROR',
      'REVOKED'
    ];

    // Check search criteria
    that.filter = {
      status: null,
      branchId: null,
      dateRange: {from: null, to: null}
    };

    const convertFilterToQueryString = (params) => {
      return $.param({
        pageNo: params.page() - 1,
        pageSize: params.count(),
        branchId: that.filter.branchId,
        status: that.filter.status,
        registeredOnFrom: $filter('nxDate')(that.filter.dateRange.from),
        registeredOnTo: $filter('nxDate')(that.filter.dateRange.to),
        clearingGroup: 'INWARD'
      });
    };

    const createCheckDetails = (check) => {

      const details = [];
      const pushDetail = (label, value, include = true, nullLabel = '-') => {
        if (include) details.push({label: label, value: value ? value : nullLabel});
      };

      // Prepare check details data
      pushDetail('Check id.', check.id);
      pushDetail('Number', check.number);
      pushDetail('MICR', check.micrNumber);
      pushDetail('Clearing group', $filter('prettyEnum')(check.checkClearingGroup));
      pushDetail('Status', $filter('prettyEnum')(check.status));
      pushDetail('Clearing performed', $filter('prettyBoolean')(check.clearingPerformed));
      pushDetail('Post dated', $filter('prettyEnum')(check.postDated.toString()));
      pushDetail('Valid from', $filter('prettyDate')(check.validFrom));
      pushDetail('Amount', $filter('php')(check.amount));
      pushDetail('Registered on', $filter('prettyDate')(check.registeredOn));
      pushDetail('Remarks', check.remarks);

      // If inward check clearing failed -> display errors
      const clearingErrors = check.clearingErrors;
      if (clearingErrors && clearingErrors.length > 0) {
        pushDetail('Clearing errors', _.map(clearingErrors, e => $filter('prettyEnum')(e)).join(', '));
      }

      return details;
    };

    const createSearchQueryPromise = (params) => {
      const queryParams = convertFilterToQueryString(params);
      return http.get(`/checks/outgoing?${queryParams}`, {nxLoaderText: 'Loading checks'}).success(page => {
        that.tableConfig.data = page.result;
        that.tableConfig.total(page.totalCount);
        that.checks = page.result;
        if (that.checks && that.checks.length > 0) {
          _.forEach(that.checks, (check) => {
            // Prepare inline panel data (check details)
            check.details = createCheckDetails(check);
            // Set available check actions
            check.canBeValidated = !check.clearingPerformed;
            check.canBeCleared = !check.clearingPerformed;
          });
        }
      });
    };

    that.itemClicked = (check, $event) => {
      $event.stopPropagation();
      that.selectedCheck = check;
      that.selectedCheckId = check.id;
    };

    that.hideInlinePanel = () => {
      that.selectedCheckId = null;
    };

    that.tableConfig = new NgTableParams({
      count: 20,
    }, {
      counts: [],
      paginationMaxBlocks: 5,
      paginationMinBlocks: 5,
      getData: params => createSearchQueryPromise(params)
    });

    that.filterChecks = () => {
      that.tableConfig.reload();
    };

    that.returnCheck = (check) => {
      confirmation(`Do you want to revoke check ${check.number}?`, () => {
        http.doDelete(`/checks/outgoing/${check.id}`, {nxLoaderText: 'Revoking check'})
          .success(() => {
            notification.show('Success', `Check ${check.number} revoked`);
            that.filterChecks();
          })
          .error(() => {
            notification.show("Error", "Failed to revoke check.");
          });
      });
    };

    // Read branches available for user
    const branchesSub = branchService.toObservable().subscribe(branches => {

      // Initialize list of branches available to user
      const branchIds = authentication.context.branchIds;
      that.availableBranches = _.filter(branches, (b) => _.includes(branchIds, b.id));

      // Prepare default filter settings
      const defaultBranch = _.find(that.availableBranches, {id: authentication.context.branchId});
      const postingDate = Date.parse(defaultBranch.postingDate);
      that.filter.branchId = defaultBranch.id;
      that.filter.dateRange = {from: postingDate, to: postingDate};

      // Load initial checks page
      that.tableConfig.page(1);
      that.tableConfig.reload();
    });

    that.validate = (check) => {
      confirmationTemplate({
        question: 'Do you want to perform check clearing validation?',
        details: [
          {label: 'Check number', description: check.number},
          {label: 'MICR', description:  check.micrNumber},
          {label: 'Amount', description:  $filter('php')(check.amount)}
        ],
        warning: 'This operation will not incur any clearing charges.',
        yesCallback: () => command.execute('ValidateInwardCheck', {checkId: check.id, failFast: false}).success((res) => {
          // Even if validation failed command will result in a success.
          // Only unexpected system exceptions will cause command to fail
          if (!res.output.valid) {
            popup({text: `Check ${check.number} is invalid for clearing`, callback: that.filterChecks()});
          } else {
            popup({text: `Check ${check.number} is valid for clearing`, callback: that.filterChecks()});
          }
        }, true)
      });
    };

    that.clear = (check) => {
      confirmationTemplate({
        question: 'Do you want to perform check clearing?',
        details: [
          {label: 'Check number', description: check.number},
          {label: 'MICR', description:  check.micrNumber},
          {label: 'Amount', description:  $filter('php')(check.amount)}
        ],
        warning: 'This operation might incur clearing charges.<br>Please make sure that the check data is valid.',
        yesCallback: () => command.execute('ClearInwardCheck', {id: check.id}).success((res) => {
          // Even if clearing charges were applied command will result in a success.
          // Only unexpected system exceptions will cause command to fail
          if (!res.output.cleared) {
            const charges = $filter('php')(res.output.clearingCharges);

            // Convert errors to HTML form
            let popupText = `<p><span class="red"><strong>WARNING!</strong> Failed to clear check: ${check.number} due to:</span></p>`;
            const errors = res.output.clearingErrors.map(err => $filter('prettyEnum')(err)).join(', ');
            popupText += `<p>${errors}</p>`;
            if (charges && charges > 0) popupText += `<p>Total amount of ${charges} was charged.</p>`;

            popup({
              header: 'Clearing failed',
              text: popupText,
              callback: that.filterChecks(),
              renderHtml: true
            });

          } else {
            popup({text: `Check ${check.number} cleared successfully`, callback: that.filterChecks()});
          }
        }, true)
      });
    };

    that.postManualCheck = () => $location.path('/inventory/checks/outgoing/post-manual-check');

    that.batchRegisterChecks = () => $location.path('/inventory/checks/outgoing/batch-register-inward-check');

    that.$onDestroy = () => {
      branchesSub.unsubscribe();
    };
  }
});
