import module from 'module';
import moment from "moment";

class RevertCommandService {

  constructor(commandMetadataCache) {
    commandMetadataCache.toObservable()
      .subscribe(commandMetaData => this.commandMetaData = commandMetaData);

  }

  /**
   * Operation must contain additional field with branch working day before validating with revertAllowed.
   * This filed is not there initially and must be added before calling the service. See transaction-history component.
   * */
  revertAllowed(operation, commandName) {

    if (!operation || !operation.commandId) {
      return false;
    }

    if (!operation.branchWorkingDay) {
      throw "No branchWorkingDay was passed in operation"
    }

    if (operation.status === 'REVERTED') {
      return false;
    }

    const workingDay = operation.branchWorkingDay;
    let branchDate = moment(workingDay.postingDate).format('YYYY-MM-DD');
    let operationDate = moment(operation.registrationTime).format('YYYY-MM-DD');

    let skipSameDayValidation = false;

    // commandName can be undefined
    if(commandName) {
      skipSameDayValidation = this.commandMetaData.find(command => command.alias === commandName);
    }

    if (branchDate !== operationDate && !skipSameDayValidation) {
      return false;
    }

    return workingDay && workingDay.status !== 'COUNTER_CLOSED';
  };

}

module.service('revertCommandService', RevertCommandService);
