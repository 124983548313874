export const feeTypes = Object.freeze([
  'FIXED',
  'PERCENTAGE'
]);

export const feeOverdraftStrategies = Object.freeze([
  'ERROR',
  'SKIP',
  'DRAIN',
  'OVERDRAFT'
]);

export const roundingModes = Object.freeze([
  'ONE_PESO',
  'HALF_PESO',
  'CENTAVO'
]);
