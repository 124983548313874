import module from 'module';
import $ from 'jquery';

const templateUrl = require('./modal-print-preview.template.html');
module.component('modalPrintPreview', {
  templateUrl: templateUrl,
  bindings: {
    doc: '<',
    onClose: '&'
  },
  controller: function($timeout, printService) {
    let that = this;
    $('#printPreview').modal({
      keyboard:false,
      backdrop: 'static'
    });
    $timeout(() => {
      printService.printToContainer(that.doc, {
        autoPrint: false,
        backgroundEnabled: true,
        container: $('#prev')
      });
    });

    that.close = () => {
      $('#printPreview').modal('hide');
      if (that.onClose) that.onClose();
    };

    that.print = () => {
      printService.printToContainer(that.doc, {
        autoPrint: true,
        backgroundEnabled: false,
        container: $('#autoprint')
      });
    };

  },
});

