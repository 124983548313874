import module from 'module';
import $ from 'jquery';

module.factory('notification', function () {

  var notificationService = {
    show: function (title, text, time) {
      time = (time !== undefined ? time : 2000);

      if (text == null) {
        text = title;
        title = "Success";
      }

      $.gritter.add({
        // (string | mandatory) the heading of the notification
        title: title,
        // (string | mandatory) the text inside the notification
        text: text,
        // (int | optional) the time you want it to be alive for before fading out (milliseconds)
        time: time,
        // (bool | optional) if you want it to fade out on its own or just sit there
        sticky: false
      });
    }
  };

  return notificationService;
});