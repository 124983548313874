import module from 'module';
import _ from 'lodash';

import {addAccountLabels} from 'components/general-ledger/common/gl.utils';

const templateUrl = require('./depository-account-details.template.html');

module.component('depositoryAccountDetails', {
  templateUrl,
  controller: function ($route, $location, $filter, dict, glMappingsService, command, depositoryAccountCache) {

    const that = this;
    const accountId = $route.current.params['accountId'];

    that.account = {id: accountId};
    that.banks = undefined;
    that.ledgerAccounts = undefined;

    const accountTypesEnum = ['SAVINGS', 'CHECKING', 'CLEARING', 'TIME_DEPOSIT', 'POS'];

    that.accountTypes = _.map(accountTypesEnum, (e) => {
      return {id: e, description: $filter('prettyEnum')(e)}
    });

    that.bankSelectConfig = {
      placeholder: 'Select bank',
      searchField: ['description'],
      valueField: 'id',
      labelField: 'description',
      maxItems: 1
    };

    that.accountTypeSelectConfig = {
      placeholder: 'Select account type',
      searchField: ['description'],
      valueField: 'id',
      labelField: 'description',
      maxItems: 1
    };

    that.ledgerAccountSelectConfig = {
      placeholder: 'Select ledger account',
      searchField: 'label',
      valueField: 'fullCode',
      labelField: 'label',
      maxItems: 1
    };

    dict.onLoadingComplete(() => that.banks = dict['BANK']);

    const s1 = glMappingsService.accounts.toObservable().subscribe(glAccounts => {
      that.ledgerAccounts = addAccountLabels(
        _.filter(glAccounts, function (glAccount) {
          return ['ASSET'].includes(glAccount.accountGroup);
        })
      );
    });

    const s2 = depositoryAccountCache.toObservable().subscribe((accounts) => {
      if (accountId && accounts) {
        that.account = _.find(accounts, a => Number(a.id) === Number(accountId));
        that.account.bankId = String(that.account.bankId);
      }
    });

    that.redirectBack = () => $location.path('/admin/organization/depository-accounts');

    that.createOrUpdate = () => {
      const commandName = that.account.id ? 'UpdateDepositoryAccount' : 'CreateDepositoryAccount';
      command.execute(commandName, that.account).success(() => {
        depositoryAccountCache.refetch();
        that.redirectBack();
      })
    };

    // On init set form as submitted to highlight invalid fields
    that.$onInit = () => {
      if (that.form) that.form.$setSubmitted();
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
      s2.unsubscribe();
    };
  }
});
