import module from 'module';
import BigNumber from "bignumber.js";
import _ from 'lodash';

const templateUrl = require('./term-deposit-section.template.html');

module.component('termDepositSection', {
  templateUrl,
  bindings: {
    sectionType: '<',
    generalForm: '=',
    interestsForm: '=',
    chargesForm: '=',
    deposit: '<'
  },
  controller: function ($scope, casaTypesCache) {

    const that = this;

    that.isNewDeposit = !that.deposit || !that.deposit.id;

    that.daysInYear = [100, 357, 360, 364, 365];
    that.casaTypes = [];
    that.pdicCasaTypes = [];

    that.depositGroups = [{
      label: 'Time deposit',
      value: 'TD'
    }, {
      label: 'Special savings account',
      value: 'SSA'
    }];

    that.cycleTypes = [{
      label: 'Daily',
      value: 'DAY'
    }, {
      label: 'Weekly',
      value: 'WEEK'
    }, {
      label: 'Monthly',
      value: 'MONTH_END'
    }, {
      label: 'Quarterly',
      value: 'QUARTER'
    }, {
      label: 'Yearly',
      value: 'YEAR'
    }];

    that.maturityStrategyOptions = [{
      label: 'Wait for customer arrival',
      value: 'NONE'
    }, {
      label: 'Auto renewal with interests',
      value: 'RENEW_WITH_INTEREST'
    }, {
      label: 'Auto renewal w/o interests',
      value: 'RENEW_WITHOUT_INTEREST'
    }];

    that.creditingStrategyOptions = [{
      label: 'Cash on arrival',
      value: 'CASH_WITHDRAWAL'
    }, {
      label: 'Check on arrival',
      value: 'CHECK_WITHDRAWAL'
    }, {
      label: 'Post-dated checks on opening',
      value: 'PDC_WITHDRAWAL'
    }, {
      label: 'Product transfer',
      value: 'PRODUCT_TRANSFER'
    }];

    that.creditingStrategies = [{
      label: 'Not allowed',
      model: false
    }, {
      label: 'Allowed',
      model: true
    }];

    that.placementStrategies = [{
      label: 'Only on activation',
      value: 'NONE'
    }, {
      label: 'Anytime',
      value: 'ADHOC'
    }, {
      label: 'Only after interest crediting',
      value: 'AFTER_CREDITING'
    }];

    that.passbookConfigOptions = [{
      label: 'Yes',
      value: 'PASSBOOK_NOT_REQUIRED'
    }, {
      label: 'No',
      value: 'PASSBOOK_REQUIRED'
    }, {
      label: 'With override',
      value: 'WITH_OVERRIDE'
    }];

    that.passbookSupportOptions = [{
      label: 'Passbook',
      value: true
    }, {
      label: 'Certificate',
      value: false
    }];

    const casaTypesSub = casaTypesCache.toObservable().subscribe(casaTypes => {
      that.casaTypes = casaTypes;
      that.pdicCasaTypes = _.filter(that.casaTypes, {'regulatorType': 'PDIC'});
    });

    $scope.$watch('$ctrl.deposit', () => {
      if (that.deposit) {
        if (!that.deposit.maturityStrategies) that.deposit.maturityStrategies = [];
        if (that.deposit.creditingStrategies) {

          if (that.deposit.creditingStrategies.length === 1 &&
            that.deposit.creditingStrategies[0] === 'NOT_ALLOWED') {
            that.creditingStrategiesValue = false;
          } else {
            that.creditingStrategiesValue = true;
          }
        }
        that.initialPretermInterestRate = that.deposit.pretermInterestRate;
        that.initialPretermSecondInterestRate = that.deposit.pretermSecondInterestRate;

        if (that.deposit.passbookConfig && that.deposit.passbookConfig !== 'PASSBOOK_NOT_SUPPORTED') {
          that.passbookSupported = true;
          that.passbookConfig = that.deposit.passbookConfig;
        } else {
          that.passbookSupported = false;
        }
      }
    });

    $scope.$watch('$ctrl.deposit.interestRate', () => {
      if (that.deposit)
        that.reevaluatePretermInterests();
    });

    that.reevaluatePretermInterests = () => {
      const interestRate = that.deposit.interestRate;
      if (!that.overridePretermInterestRate) {
        if (that.initialPretermInterestRate) {
          that.deposit.pretermInterestRate = that.initialPretermInterestRate;
        } else if (interestRate) {
          that.deposit.pretermInterestRate = new BigNumber(interestRate).dividedBy(4).round(0).toNumber();
        }
      }
      if (!that.overridePretermSecondInterestRate) {
        if (that.initialPretermSecondInterestRate) {
          that.deposit.pretermSecondInterestRate = that.initialPretermSecondInterestRate;
        } else if (interestRate) {
          that.deposit.pretermSecondInterestRate = new BigNumber(interestRate).dividedBy(2).round(0).toNumber();
        }
      }
    };

    that.onCreditingInterestsChange = () => {
      if (that.creditingStrategiesValue === false) {
        that.deposit.creditingStrategies = ['NOT_ALLOWED'];
      } else {
        that.deposit.creditingStrategies = [];
      }
    };

    that.transferCreditingStrategyEnabled = () => {
      return that.deposit
        && that.deposit.creditingStrategies
        && that.deposit.creditingStrategies.length > 0
        && _.includes(that.deposit.creditingStrategies, 'PRODUCT_TRANSFER');
    };

    that.passbookConfigChange = () => {
      if (!that.passbookSupported) {
        that.deposit.passbookConfig = 'PASSBOOK_NOT_SUPPORTED';
      } else {
        that.deposit.passbookConfig = that.passbookConfig;
      }
    };

    // Release subscriptions
    that.$onDestroy = () => {
      casaTypesSub.unsubscribe();
    }
  },
});
