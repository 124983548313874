import module from 'module';
import _ from 'lodash';

import templateUrl from './roles-config.template.html';

class RolesConfigComponent {
  constructor($scope, $timeout, $route, http, rolesCache, breadcrumbs, accessRuleCache, commandRoleMapCache,
              notification, confirmation, command, userCache, authentication) {
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$route = $route;
    this.http = http;
    this.rolesCache = rolesCache;
    this.breadcrumbs = breadcrumbs;
    this.accessRuleCache = accessRuleCache;
    this.commandRoleMapCache = commandRoleMapCache;
    this.notification = notification;
    this.confirmation = confirmation;
    this.command = command;
    this.userCache = userCache;
    this.authentication = authentication;
  }

  async $onInit() {
    this.roleId = parseInt(this.$route.current.params['roleId']);
    this.rolePermissions = [];
    this.roleAccessRules = [];
    this.roleAccessRulesToDelete = [];

    const [users, roles] = await Promise.all([this.userCache.toPromise(), this.rolesCache.toPromise()]);

    const currentRealUser = users.find(u => u.id === this.authentication.context.realUserId);
    this.role = _.find(roles, r => (currentRealUser.technical || !r.technical) && r.id === this.roleId);

    if (this.roleId && this.role) {
      _.set(this.breadcrumbs, 'options.role', this.role.name);
    }
  };

  async save() {
    const confirmed = this.confirmation('Do you want to save new configuration?');

    if (!confirmed) {
      return;
    }

    const response = await this.command.execute('UpdateRolePermissions', {
      roleId: this.role.id,
      rolePermissions: this.rolePermissions
    }).toPromise();

    if (response.approvalRequired) {
      return;
    }

    this.rolesCache.evict();
  };

  async saveAccessRules() {
    const confirmed = await this.confirmation('Do you want to save new configuration?');

    if (!confirmed) {
      return;
    }

    const response = await this.http.post('/access', {
      accessRules: this.roleAccessRules,
      deleteRuleIds: this.roleAccessRulesToDelete
    }).toPromise();

    if (response.approvalRequired) {
      return;
    }

    this.accessRuleCache.evict();
    this.commandRoleMapCache.refetch();
    this.notification.show('Actions updated successfully');
    this.$route.reload();
  }
}

module.component('rolesConfig', {
  templateUrl,
  controller: RolesConfigComponent
});
