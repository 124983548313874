import module from 'module';

const templateUrl = require('./loan-industry-purpose.template.html');
module.component('loanIndustryPurpose', {
  templateUrl,
  bindings: {
    industryPurposeId: '=',
    ngRequired: '<'
  },
  controller: function ($scope, $timeout, dict, loanIndustryPurposeCache, hierarchyBuilder) {

    let that = this;

    that.onIndustryPurposeChange = () => {
      that.industryPurposeId = that.industryPurpose.id;
    };

    that.$doCheck = () => {
      const industryPurpose = that.industryPurpose || {};
      if(that.industryPurposeId && industryPurpose.id !== that.industryPurposeId && that.loanIndustryPurposeIdMap) {
        that.industryPurpose = that.loanIndustryPurposeIdMap[that.industryPurposeId];
      }
    };

    let loanPurposeSubscr = loanIndustryPurposeCache.toObservable().subscribe((groups) => {
        // build mis groups hierarchy
        let root = [];
        let idMap = {};
        hierarchyBuilder.buildHierarchy(groups, idMap, root);

        that.availableLoanIndustryPurposeGroups = root;
        that.loanIndustryPurposeIdMap = idMap;

        if(that.industryPurposeId) {
          that.industryPurpose = that.loanIndustryPurposeIdMap[that.industryPurposeId];
        }
      }
    );

    that.$onDestroy = () => {
      loanPurposeSubscr.unsubscribe();
    };

    that.label = node => node.name;
  }
});