import module from 'module';

import templateUrl from './layaway-product.template.html';

class LayawayProducts {}

module.component('layawayProducts', {
  templateUrl,
  controller: LayawayProducts
});
