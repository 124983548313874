import module from "module";
import _ from 'lodash';

const templateUrl = require('./depository-account-list.template.html');

module.component('depositoryAccountList', {
  templateUrl,
  controller: function ($location, $filter, depositoryAccountCache, confirmationTemplate, command) {

    const that = this;
    that.accounts = [];

    const s1 = depositoryAccountCache.toObservable().subscribe(data => that.accounts = data ? data : []);

    that.update = (id) => $location.path(`/admin/organization/depository-accounts/${id}/update`);

    that.delete = (id) => {
      const account = _.find(that.accounts, {id: id});
      confirmationTemplate({
        question: `Do you want to remove depository account?`,
        warning: 'This operation cannot be reverted.<br>Please make sure that the account data is correct.',
        details: [
          {label: 'Account name', description: account.accountName},
          {label: 'Account type', description: $filter('prettyEnum')(account.accountType)}
        ],
        yesCallback: () => command.execute('DeleteDepositoryAccount', {id: id}).success(() => depositoryAccountCache.refetch())
      });
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
    }
  }
});
