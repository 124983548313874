import module from 'module';
import moment from 'moment';

module.factory('postingDateService', function (branchService, authentication) {
  let service = {};

  service.getPostingDate = (branch) => {
    if (branch && branch.postingDate) {
      const mom = moment(branch.postingDate);
      return mom;
    } else {
      throw new Error('Posting date for user branch not found. Provided branch object: ' + JSON.stringify(branch));
    }
  };

  service.getPostingDateByBranchIds = async (branchIds) => {
    const branches = await branchService.toPromise();
    return branches.filter(b => branchIds.includes(b.id))
      .map(b => moment(b.postingDate));
  };

  service.getPostingDateByBranchId = async (branchId) => {
    const branches = await branchService.toPromise();
    const branch = branches.find(b => b.id === branchId);
    return moment(branch.postingDate);
  };

  service.getCurrentUserBranchPostingDate = () => {
    return service.getPostingDateByBranchId(authentication.context.branchId);
  };

  return service;
});