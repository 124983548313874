import module from 'module';
import templateUrl from './check-deposit-to-bank.template.html';
import BigNumber from 'bignumber.js';

// Deprecated - Marked for removal
class CheckDepositToBank {
  constructor (actionCommand, authentication, depositoryAccountCache) {
    this.actionCommand = actionCommand;
    this.depositoryAccountCache = depositoryAccountCache;
    this.userId = authentication.context.realUserId;
    this.branchId = authentication.context.branchId;
    
    this.depositoryAccounts = [];

    this.transaction = {
      amount: 0,
      userId: this.userId,
      branchId: this.branchId,
      depositoryAccountId: null,
      checkIds: []
    };

    this.selectConfig = {
      placeholder: 'Select account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };
  }
  
  $onInit() {
    this.depositoryAccountCache.toPromise()
      .then(depositoryAccounts => this.depositoryAccounts = depositoryAccounts);
  }

  onCheckChange(checks) {
    this.updateTransaction(checks);
  }

  updateTransaction(checks) {
    let amount = new BigNumber(0);
    const checkIds = [];

    checks.forEach(check => {
      amount = amount.plus(check.amount);
      checkIds.push(check.id);
    });
    
    this.transaction.amount = amount.toNumber();
    this.transaction.checkIds = checkIds;
  }

  cancelChanges() {
    this.actionCommand.cancelChanges();
  }
  
  save() {
    this.actionCommand.execute('BATCH_CHECK_DEPOSIT_TO_BANK', this.transaction);
  }
};

module.component('checkDepositToBank', {
  templateUrl,
  controller: CheckDepositToBank
});
