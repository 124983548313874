import module from 'module';
import _ from 'lodash';


const templateUrl = require('./transactions-list.template.html');

module.component('transactionsList', {
  templateUrl: templateUrl,
  controller: function ($location, $route, $filter, actionCategoryCache) {
    this.transactions = Object.freeze({
      CASH_IN: 'CASH_IN',
      CASH_OUT: 'CASH_OUT',
      ADJUSTMENT_IN: 'ADJUSTMENT_IN',
      ADJUSTMENT_OUT: 'ADJUSTMENT_OUT',
      OFFICIAL_RECEIPT: 'OFFICIAL_RECEIPT',
      OFFICIAL_RECEIPT_IN_CASH: 'OFFICIAL_RECEIPT_IN_CASH',
      OFFICIAL_RECEIPT_IN_CHECK: 'OFFICIAL_RECEIPT_IN_CHECK',
      FOREX_CASH_IN: 'FOREX_CASH_IN',
      FOREX_CASH_OUT: 'FOREX_CASH_OUT'
    });

    this.categoriesCashIn = [];
    this.categoriesCashOut = [];
    this.categoriesAdjustmentIn = [];
    this.categoriesAdjustmentOut = [];
    this.ledgerAccounts = [];
    this.displayMode = this.transactions.CASH_IN;

    const categoriesSub = actionCategoryCache.toObservable()
      .subscribe(categories => {
        _.forEach(categories, category => {
          //ledgerAccountFullCode is not required, we're showing '-' in table if is not set
          category.fullCode = category.ledgerAccountFullCode ? category.ledgerAccountFullCode : '-';
        });

        this.categoriesCashIn = categories.filter(category => category.actionType === this.transactions.CASH_IN);
        this.categoriesCashOut = categories.filter(category => category.actionType === this.transactions.CASH_OUT);
        this.categoriesAdjustmentIn = categories.filter(category => category.actionType === this.transactions.ADJUSTMENT_IN);
        this.categoriesAdjustmentOut = categories.filter(category => category.actionType === this.transactions.ADJUSTMENT_OUT);
        this.categoriesOfficialReceipt = categories.filter((category) =>
          [this.transactions.OFFICIAL_RECEIPT_IN_CHECK, this.transactions.OFFICIAL_RECEIPT_IN_CASH].includes(category.actionType));
        this.categoriesForexCashIn = categories.filter(category => category.actionType === this.transactions.FOREX_CASH_IN);
        this.categoriesForexCashOut = categories.filter(category => category.actionType === this.transactions.FOREX_CASH_OUT);
      });

    this.setDisplayMode = (displayMode) => {
      this.displayMode = displayMode;
    };

    this.getDisplayMode = () => this.displayMode;

    this.$onDestroy = () => {
      categoriesSub.unsubscribe();
    }
  }
});
