import module from 'module';

import templateUrl from './meta-data.template.html';

class MetaDataComponent {
  // Empty
}

module.component('customerMetaData', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '<'
  },
  controller: MetaDataComponent
});
