import module from 'module';

const templateUrl = require('./process-backdated-transactions.template.html');

class BackdatedTransactionsProcessor {

  constructor(confirmationTemplate, $route, branchService, command) {

    this.confirmationTemplate = confirmationTemplate;
    this.$route = $route;
    this.branchService = branchService;
    this.command = command;

    this.branch = {};
    this.branches = [];
  }

  execute() {
    this.confirmationTemplate({
      question: `Do you want to process backdated GL transactions for branch ${this.branch.name}?`,
      yesCallback: () => this.command.execute('ProcessBackdatedTransactions', {id: this.branch.id}).success(() => this.$route.reload())
    })
  }

  $onInit() {
    this.branchService.toObservable()
      .first()
      .subscribe(branches => this.branches = branches);
  }
}

module.component('processBackdatedTransactions', {
  templateUrl,
  controller: BackdatedTransactionsProcessor
});

