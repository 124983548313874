import module from 'module';
import moment from 'moment';

import templateUrl from './date.template.html';
import './date.component.less';

class DateWrapper {
  constructor($attrs, postingDateService) {
    this.$attrs = $attrs;
    this.postingDateService = postingDateService;
    this.dateFormat = 'YYYY-MM-DD';
    this.postingDate = null;
  }

  static normalizeDate(date) {
    if (!date) return null;
    const inputMoment = moment(date);
    return new Date(inputMoment.year(), inputMoment.month(), inputMoment.date());
  }

  $onInit() {
    this.ngModel.$validators.min = (modelValue) => {
      if (!modelValue || !this.min) return true;
      const normalizedMin = DateWrapper.normalizeDate(this.min);
      const normalizedModel = DateWrapper.normalizeDate(modelValue);
      return moment(normalizedModel).isSameOrAfter(normalizedMin);
    };

    this.ngModel.$validators.max = (modelValue) => {
      if (!modelValue || !this.max) return true;
      const normalizedMax = DateWrapper.normalizeDate(this.max);
      const normalizedModel = DateWrapper.normalizeDate(modelValue);
      return moment(normalizedModel).isSameOrBefore(normalizedMax);
    };

    this.ngModel.$parsers.push(viewValue => {
      return this.convertDateFormat(DateWrapper.normalizeDate(viewValue));
    });

    this.ngModel.$formatters.push(modelValue => {
      return DateWrapper.normalizeDate(modelValue);
    });

    this.ngModel.$render = async () => {
      if (this.ngModel.$modelValue || this.initWithNull) {
        this.syncDateModel();

      } else {
        this.postingDate = await this.postingDateService.getCurrentUserBranchPostingDate();
        this.dateModel = DateWrapper.normalizeDate(this.postingDate);
        // Propagate value to ngModel
        this.ngModel.$setViewValue(this.dateModel);
      }
    };
  }

  convertDateFormat(date) {
    if (!date) return null;

    let formattedDate = null;
    switch (this.outputFormat) {
      // Previously directive called format-date
      case 'date':
        formattedDate = new Date(date);
        break;
      case 'local-date':
      default:
        formattedDate = date ? moment(date).format(this.dateFormat) : null;
        break;
    }

    return formattedDate;
  }

  $onChanges() {
    this.refreshMinMaxBoundaries();
    this.syncNgModel();
    this.validateNgModel();
  }

  refreshMinMaxBoundaries() {
    if (typeof this.min === 'object') {
      // when min is of type Date normalize it first, then convert to string date
      this.min = DateWrapper.normalizeDate(this.min);
      this.minBoundary = moment(this.min).format(this.dateFormat);
    } else {
      this.minBoundary = this.min;
    }

    if (typeof this.max === 'object') {
      // when max is of type Date normalize it first, then convert to string date
      this.max = DateWrapper.normalizeDate(this.max);
      this.maxBoundary = moment(this.max).format(this.dateFormat);
    } else {
      this.maxBoundary = this.max;
    }
  }

  valueChanged() {
    if (this.ngModel) {
      this.dateModel = DateWrapper.normalizeDate(this.dateModel);
      this.ngModel.$setViewValue(this.dateModel);
    }
  }

  validateNgModel() {
    if(this.ngModel) {

      this.ngModel.$setValidity('min',false);
      this.ngModel.$setValidity('max',false);
      this.ngModel.$validate();
    }
  }

  syncNgModel() {
    if (this.ngModel) {
      this.ngModel.$setViewValue(this.dateModel);
    }
  }

  syncDateModel() {
    this.dateModel = DateWrapper.normalizeDate(this.ngModel.$modelValue);
    this.ngModel.$setViewValue(this.dateModel);
    this.validateNgModel();
  }
}

/**
 * Wraps input type="date". Provides formatting output and fallback if ngModel is undefined/null.
 *
 * This component supports two types of date formats (look at outputFormat param):
 * - local-date - default (and strongly RECOMMENDED) format, example: '2018-09-11'
 * - date       - example: '2017-12-08T00:00:00.000Z', standard js Date type, be careful with that format,
 *                because it is returned in UTC timezone (therefore you may end up with different date
 *                after conversion to your timezone i.e. '2017-12-07T22:00:00.000-02:00' in case given above)
 *
 *
 * @param min - optional - string date format. Triggers native html and ngModelController validation.
 *              May be timestamp or string (String must be passed in single quotes i.e. min="'2018-07-07'")
 *
 * @param max - optional - string date format. Triggers native html and ngModelController validation.
 *              May be timestamp or string (String must be passed in single quotes i.e. min="'2018-07-07'")
 *
 * @param initWithNull - optional - if true and ng-model is undefined then date component is initialized with null,
 *                       otherwise posting date of current branch is fetched and set as an initial value.
 *
 * @param outputFormat - optional - string which determine output format. Default output is string-date format.
 */
module.component('date', {
  require: {
    ngModel: '^ngModel',
  },
  bindings: {
    min: '<',
    max: '<',
    initWithNull: '<',
    outputFormat: '@'
  },
  templateUrl,
  controller: DateWrapper
});
