import {Observable} from "rxjs/Observable";

import module from 'module';
import moment from 'moment';

import './product-owners.style.less'
const templateUrl = require('./product-owners.template.html');

module.component('productOwners', {
  templateUrl: templateUrl,
  bindings: {
    'ownership': '=',
    'editMode': '<'
  },
  controller: function ($scope, $route, notification) {

    const that = this;

    that.$onInit = () => {

      that.addOwner = (customer, type) => {
        if(!that.ownership.find(o => o.customerId === customer.customerId)) {
          that.ownership.push({
            birthDate: customer.birthDate ? moment(customer.birthDate).format('YYYY-MM-DD') : null,
            branchName: customer.branch.name,
            customerId: customer.customerId,
            customerNumber: customer.customerNumber,
            effectiveName: customer.name,
            ownershipType: type,
            productAccess: true
          });
        } else {
          notification.show("Error", "Customer already added!");
        };
      };

      that.onRemoveOwner = (idx) => {
        that.ownership.splice(idx, 1);
      };

    }
  }
});
