import module from 'module';

const templateUrl = require('./mother-branch-predicate.template.html');

class MotherBranchPredicate {

  constructor($scope) {
    this.$scope = $scope;
    this.type = 'BRANCH_ACCESS_MODE';
  }

  $onInit() {
    this.$scope.$watchCollection('$ctrl.predicates', () => {
      if (!this.predicates) {
        this.value = null;
      } else {
        this.value = this.predicates[this.type];
      }
    });
  };

  valueChanged() {
    this.predicates[this.type] = this.value;
  };
}

module.component('motherBranchPredicate', {
  templateUrl,
  bindings: {
    predicates: '='
  },
  controller: MotherBranchPredicate
});
