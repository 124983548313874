import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('singleUserCache', (http, CacheFactory, config) => {
  let that = {};

  let singleUser = {};

  let singleUserCache = (id) => {
    const cache = new LocalCache({
      provider: () => http.get(`/management/users/${id}`, {nxLoaderSkip: true}),
      cacheFactory: CacheFactory,
      cacheTime: config.cache.singleUser,
      cacheName: `user_${id}`
    });
    singleUser[id] = cache;
    return cache;
  };

  that.user = (id) => {
    if (!singleUser[id]) {
      singleUserCache(id);
    }
    return singleUser[id];
  };

  return that;
});
