import module from 'module';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

const templateUrl = require('./cashiers-check-creation.template.html');
module.component('cashiersCheckCreation', {
  templateUrl: templateUrl,
  controller: function (config, actionCommand, glMappingsService, modalPrintPreviewService) {
    this.transaction = {
      units: [{}]
    };
    this.cashReceiptThreshold = config.cashReceiptThreshold;

    this.selectConfig = {
      placeholder: 'Select GL account',
      searchField: ['name', 'fullCode'],
      valueField: 'fullCode',
      labelField: 'name',
      maxItems: 1
    };

    glMappingsService.accounts.toObservable().subscribe(accounts => {
        this.accounts = accounts;
      }
    );

    const bigSumBy = (collection, itemProperty) => {
      return collection.reduce((sum, item) => sum.add(item[itemProperty] || 0), new BigNumber(0));
    };

    this.calculateDebitSum = () => {
      return bigSumBy(this.transaction.units, 'amount');
    };

    this.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    this.save = () => {
      this.transaction.units.forEach(u => u.entryType = 'DEBIT');

      let clonedTransaction = angular.copy(this.transaction);
      clonedTransaction.purpose = 'REGULAR_WITHDRAWAL';
      clonedTransaction.amount = Number(this.calculateDebitSum());
      clonedTransaction.units.push({
        accountCode: 'LIAB/TCMC',
        amount: Number(this.calculateDebitSum()),
        entryType: 'CREDIT'
      });
      actionCommand.execute('CASHIER_CHECK_CREATION', clonedTransaction)
        .success((res) => {
          modalPrintPreviewService.show('CASHIER_CHECK_CREATION', {actionDataId: res.output});
        });
    }
  }
});
