import { Observable } from 'rxjs/Observable'
import { Observer } from 'rxjs/Observer';
import 'rxjs/add/operator/combineLatest';

export default service =>
  Observable.create(observer => {
    const listener = (err, val) => {
      err ? observer.error(err) : observer.next(val);
    };

    service.subscribe(listener);

    return () => service.unsubscribe(listener);
  });
