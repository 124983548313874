import './translation.cache'
import _ from 'lodash';
import module from 'module';


/**
 * This filter is stateful and it's invoked for every $digest cycle.
 * Because translation data is fetch form cache, filter will show 'tmpTranslation' variable value,
 * until the translation data has been fetched.
 */

module.filter('translate', (translationCache) => {
  const tmpTranslation = '...';
  let allCodes = {};

  translationCache.toObservable().first().subscribe(translationCodes => {
    allCodes = translationCodes;
  });

  const doTranslate = (type, code, placeholder) => {

    const placeholderValue = (value) => {
      if (!placeholder) {
        return `<${value}>`
      }
      if (typeof placeholder === 'string' || placeholder instanceof String) {
        return placeholder;
      } else {
        return placeholder(value);
      }
    };

    let translations = allCodes[type];
    let results = _.filter(translations, it => it.code == code);

    let value = null;

    if (results.length > 1) {
      value = `TRANSLATION MISMATCH <${code}>`;
    } else if (results.length == 0) {
      value = placeholderValue(code);
    } else {
      let resultValue = results[0].value;
      if (!resultValue) {
        value = placeholderValue(code);
      } else {
        value = resultValue;
      }
    }
    return value;
  };

  let filterFun = (code, type, placeholder) => {
    if(!code || !type) {
      return tmpTranslation;
    }
    code = code.toUpperCase();
    return doTranslate(type, code, placeholder);
  };

  filterFun.$stateful = true;

  return filterFun;
});

module.filter('translateEnum', ($filter) => {
  return (code, type) => {
    return $filter('translate')(code, type, () => {
      return $filter('startCase')(code);
    });
  };
});
