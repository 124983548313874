import module from 'module';

const templateUrl = require('./loan-mis-group.template.html');
module.component('loanMisGroup', {
  templateUrl,
  bindings: {
    misGroupId: '=',
    ngRequired: '<'
  },
  controller: function ($scope, $timeout, dict, misGroupsCache, hierarchyBuilder) {

    let that = this;

    // mis groups
    that.misSelected = null;

    that.onMisGroupChange = () => {
      that.misGroupId = that.misGroup.id;
    };

    that.$doCheck = () => {
      const misGroup = that.misGroup || {};
      if(that.misGroupId && misGroup.id !== that.misGroupId && that.misGroupIdMap) {
        that.misGroup = that.misGroupIdMap[that.misGroupId];
      }
    };

    let misSubscr = misGroupsCache.toObservable().subscribe((groups) => {
        // build mis groups hierarchy
        let root = [];
        let idMap = {};
        hierarchyBuilder.buildHierarchy(groups, idMap, root);
        that.availableMisGroups = root;
        that.misGroupIdMap = idMap;

        if(that.misGroupId) {
          that.misGroup = that.misGroupIdMap[that.misGroupId];
        }
      }
    );

    that.$onDestroy = () => {
      misSubscr.unsubscribe();
    };

    that.label = node => node.name;
  }
});