import $ from 'jquery';
import _ from 'lodash';
import module from 'module';
import {bufferTime} from 'rxjs/operators/bufferTime';
import {filter} from 'rxjs/operators/filter';
import InspireTree from 'inspire-tree';
import InspireTreeDOM from 'inspire-tree-dom';
import {ReplaySubject} from 'rxjs/ReplaySubject';

import './ent-horde.style.less';
import templateUrl from './ent-horde.template.html';

/**
 * Credits to Bartosz Polnik for designing this component
 */
class EntHorde {
  constructor($scope, $element) {
    this.$scope = $scope;
    this.$element = $element;
  }

  $onInit() {
    this.itemAdditions = new ReplaySubject();
    this.itemRemovals = new ReplaySubject();
    this.showDropdown = false;

    this.tree = new InspireTree({
      selection: {
        mode: 'checkbox',
      },
      pagination: {
        limit: 40
      }
    });

    if (!this.ngModel) {
      throw new Error('Missing ng model');
    }

    this.ngModel.$render = () => {
      const externalValues = this.modelValue() || [];
      this.tree.select(externalValues);
      this.tree.invoke('check', externalValues);
    };

    this.hideDropdownListener = (e) => {
      const $clickedItem = $(e.target);

      if ($clickedItem.closest(this.$element).length > 0) {
        return;
      }

      this.$scope.$apply(() => {
        this.showDropdown = false;
      });
    };

    $(document.body).on('click', this.hideDropdownListener);

    this.itemAdditionsSubscription = this.subscribeAndBuffer(this.itemAdditions)
      .subscribe(() => {
          if (!this.tree) {
            return;
          }
          const newViewValue = this.tree.selected(false).map(node => node.id);
          this.triggerNgModelUpdate(newViewValue);
        }
      );

    this.itemRemovalsSubscription = this.subscribeAndBuffer(this.itemRemovals)
      .subscribe(() => {
          if (!this.tree) {
            return;
          }
          const newViewValue = this.tree.selected(false).map(node => node.id);
          this.triggerNgModelUpdate(newViewValue);
        }
      );
  }

  subscribeAndBuffer(subject) {
    return subject.asObservable()
      .pipe(
        bufferTime(100),
        filter(items => items.length > 0)
      );
  }

  collectIndeterminateNodes(sourceNode, indeterminateCheckedNodes) {
    let foundSelectedNode = false;
    for (const node of (sourceNode.children || [])) {
      if (this.collectIndeterminateNodes(node, indeterminateCheckedNodes)) {
        indeterminateCheckedNodes.add(sourceNode.id);
        foundSelectedNode = true;
      }
    }

    return foundSelectedNode || this.modelValue().includes(sourceNode.id);
  }

  prepareNodeConfig(sourceNode, indeterminateCheckedNodes) {
    return {
      id: sourceNode.id,
      text: this.nodeLabel(sourceNode),
      children: (sourceNode.children || []).map(node => this.prepareNodeConfig(node, indeterminateCheckedNodes)),
      itree: {
        state: {
          indeterminate: indeterminateCheckedNodes.has(sourceNode.id),
          selectable: true,
          selected: this.modelValue().includes(sourceNode.id),
          checked: this.modelValue().includes(sourceNode.id)
        },
      }
    };
  }

  normalizedTreeModel() {
    const indeterminateCheckedNodes = new Set();
    for (const node of this.treeData) {
      this.collectIndeterminateNodes(node, indeterminateCheckedNodes);
    }

    return Object.values(this.treeData || [])
      .map(node => this.prepareNodeConfig(node, indeterminateCheckedNodes));
  }

  modelValue() {
    return this.ngModel.$modelValue || [];
  }

  selectModelValue() {
    if (!this.tree) {
      return;
    }

    const externalIds = this.modelValue();
    if (externalIds && externalIds.length > 0) {
      const nodes = this.tree.nodes(externalIds);

      nodes.expandParents();
      nodes.select();
      nodes.invoke('check');
    }
  }

  triggerNgModelUpdate(ids) {
    this.$scope.$apply(() => {
      if (!this.ngModel) {
        return;
      }

      this.ngModel.$setTouched();
      this.ngModel.$setViewValue(ids);
    });
  }

  triggerNodeAdded(addedNode) {
    this.itemAdditions.next(addedNode);
  }

  triggerNodeRemoved(removedNode) {
    this.itemRemovals.next(removedNode);
  }

  $onChanges(changes) {
    const previousTreeData = _.get(changes, 'treeData.previousValue', {});
    const currentTreeData = _.get(changes, 'treeData.currentValue', {});
    // here I'm trying to defer initialization of  tree as it's rendering engine is asynchronous
    if (changes.treeData && changes.treeData.isFirstChange() && !_.isEmpty(currentTreeData)) {
      return;
    }

    // I'm explicitly checking for emptiness as two instances of empty objects
    // using different constructors may not be equal
    if (_.isEmpty(previousTreeData) && _.isEmpty(currentTreeData)) {
      return true;
    }

    if (!_.isEqual(previousTreeData, currentTreeData) && this.tree) {
      // It's easier to rebuild the element upon change of the tree
      this.$onDestroy();
      this.$onInit();
      this.$postLink();
    }
  }

  $postLink() {
    this.$element.click(_evt => this.ngModel.$setTouched());

    const root = this.$element.find('.ent-horde__tree-element');
    new InspireTreeDOM(this.tree, {
      target: root.get(0),
      deferredRendering: true
    });

    if (!this.tree) {
      return;
    }

    this.tree.on('node.selected', (node, _isLoadEvent) => {
      this.triggerNodeAdded(node);
    });

    this.tree.on('node.deselected', (node, _isLoadEvent) => {
      this.triggerNodeRemoved(node);
    });

    this.tree.on('model.loaded', _nodes => {
      this.selectModelValue();
    });

    if (this.treeData) {
      this.tree.load(this.normalizedTreeModel());
    }
  }

  $onDestroy() {
    $(document).off('click', this.hideDropdownListener);
    this.itemAdditionsSubscription.unsubscribe();
    this.itemRemovalsSubscription.unsubscribe();
  }
}

module.component('entHorde', {
  templateUrl,
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    treeData: '<',
    nodeLabel: '<',
    labelRenderer: '<'
  },
  controller: EntHorde
});
