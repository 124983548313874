import module from 'module';
import _ from 'lodash';

const templateUrl = require('./group-loan-information.template.html');
module.component('groupLoanInformation', {
  templateUrl: templateUrl,
  bindings: {
    'loans': '<'
  },
  controller: function ($scope) {

    let that = this;

    $scope.$watchCollection('$ctrl.loans', () => {
      if (that.loans) {
        that.totalPrincipal = _.sumBy(that.loans, it => it.principalBalance);
        that.totalInterest = _.sumBy(that.loans, it => it.principalBalance);

        that.totalNotarialFee = _.sumBy(that.loans, it => it.notarialFee);
        that.totalApplicationFee = _.sumBy(that.loans, it => it.applicationFee);
        that.totalCreditInvestigationFee = _.sumBy(that.loans, it => it.creditInvestigationFee);
        that.totalExtraBankFee = _.sumBy(that.loans, it => it.extraBankFee);
        that.totalServiceCharge = _.sumBy(that.loans, it => it.serviceCharge);
        that.totalPreterminationFee = _.sumBy(that.loans, it => it.preterminationFee);
        that.totalMembershipFee = _.sumBy(that.loans, it => it.membershipFee);
        that.totalIdFee = _.sumBy(that.loans, it => it.idFee);
        that.totalInsuranceFee = _.sumBy(that.loans, it => it.insuranceFee);
        that.totalInsuranceServiceFee = _.sumBy(that.loans, it => it.insuranceServiceFee);
        that.totalInsuranceProcessingFee = _.sumBy(that.loans, it => it.insuranceProcessingFee);
      }
    });

  }
});