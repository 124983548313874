import module from 'module';
import _ from 'lodash';

// Deprecated - Marked for removal
const templateUrl = require('./encash-interbranch-cashier-check.template.html');
module.component('encashInterbranchCashierCheck', {
  templateUrl: templateUrl,
  controller: function ($filter, http, authentication, branchService, userCache, actionCommand, breadcrumbs, notification) {

    const that = this;

    // Current branch ID
    that.branchId = authentication.context.branchId;
    // List of pending cashier checks for branch
    that.pendingChecks = [];
    // Selected check number (from list)
    that.selectedNumber = null;
    // Selected pending check
    that.selectedCheck = null;
    // Ledger action data
    that.transaction = {};
    // Pending check select config
    this.selectConfig = {
      placeholder: 'Select check number',
      searchField: ['number'],
      valueField: 'number',
      labelField: 'number',
      maxItems: 1
    };

    that.transactionName = $filter('translate')('ENCASH INTERBRANCH CASHIER CHECK', 'MISC_TRANSACTION');

    _.set(breadcrumbs, 'options', {
      'Encash interbranch cashier check': that.transactionName,
    });

    // Read issued cashier's checks for current branch
    const checkQueryUrl = `/checks/cashiers?targetBranchId=${that.branchId}&interbranchChecks=TRUE&status=ISSUED&pageSize=50`;
    http.get(checkQueryUrl, {}, {nxLoaderText: 'Loading cashier checks'})
      .success((page) => {
        if (page && page.resultCount > 0) {
          that.pendingInterbranchChecks = page.result;
        }
      })
      .error(() => notification.show("Failed to load registered tasks"));

    that.refreshSelectedCheck = () => {
      if (that.selectedNumber) {
        that.selectedCheck = _.find(that.pendingInterbranchChecks, {number: that.selectedNumber});
        that.transaction.amount = that.selectedCheck.amount;
        that.transaction.number = that.selectedCheck.number;
        that.transaction.payee = that.selectedCheck.payee;
        that.transaction.purpose = that.selectedCheck.purpose;
        that.transaction.hookType = that.selectedCheck.hookType;
        that.transaction.hookId = that.selectedCheck.hookId;
        that.transaction.targetBranchId = that.selectedCheck.targetBranchId;
        that.transaction.sourceBranchId = that.selectedCheck.branchId;
        that.transaction.issuedById = that.selectedCheck.issuedBy;
      }
    };

    that.readCheckPurposeLabel = () => {
      if (!that.selectedCheck) return '';
      return {
        'REGULAR_WITHDRAWAL': 'Withdrawal',
        'INTEREST_WITHDRAWAL': 'Interest withdrawal',
        'PRINCIPAL_WITHDRAWAL': 'Principal withdrawal',
        'LOAN_RELEASE': 'Loan release'
      }[that.selectedCheck.purpose]
    };

    this.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    this.save = () => {
      actionCommand.execute('ENCASH_INTERBRANCH_CASHIER_CHECK', that.transaction);
    }
  }
});
