import module from 'module';

import './security-machine-specific.style.less';
import templateUrl from './security-machine-specific.template.html';

class SecurityMachineSpecific {
  constructor(cmcService, notification, command) {
    this.cmcService = cmcService;
    this.notification = notification;
    this.command = command;

    this.cmc = this.cmcService.getCmc();
  }

  save() {
    this.command.execute('SetCmc', { cmc: this.cmc }).success(response => {
      this.cmcService.setCmcToken(response.output);
    });
  }
}

module.component('securityMachineSpecific', {
  templateUrl,
  controller: SecurityMachineSpecific
});