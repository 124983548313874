import module from 'module';

const templateUrl = require('./amortization-preview.template.html');
module.component('amortizationPreview', {
  templateUrl: templateUrl,
  bindings: {
    amortizationPreview: '<'
  },
  controller: function () {
  }
});