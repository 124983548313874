import "rxjs/add/observable/fromPromise";
import "rxjs/add/operator/toPromise";
import "rxjs/add/observable/of";
import "rxjs/add/operator/combineAll";
import {enrichOwnersWithRelatives, isCorporate} from '../common/casa-product-util';
import {Subscription} from "rxjs/Subscription";
import _ from "lodash";
import {NgTableParams} from "ng-table/ng-table.js";

import module from "module";
import moment from "moment";

const templateUrl = require('./customer-term-deposits.template.html');
module.component('customerTermDeposits', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $scope, http, command, customerCache,
                        productDefinitionService, branchService, userCache, confirmation, notification,
                        $routeParams, modalPrintPreviewService, termDepositsService, transactionDetailsBuilder,
                        authentication, customerDepositService, userCounterService, casaTypesCache, fileService,
                        productLockCache, productLockService, interestBoardService, revertCommandService, operationTableBuilder,
                        passbookCache, certificateCache, queryParamsRemover, printCertificateService, customerService) {
    let that = this;
    that.termDeposits = [];
    that.visibleDeposits = [];

    that.selectedDeposit = null;
    that.item = null;
    that.selectedTransactionId = undefined;
    that.permissions = authentication.permissions;
    that.isValidationSlipActive = false;
    that.showClosed = $routeParams.showClosed === "true";

    that.isPrintCertificateActive = false;

    let customerId = $route.current.params['customerId'];
    that.depositId = $route.current.params['depositId'];
    
    queryParamsRemover.removeQueryOnRedirect($scope, ['showClosed']);

    if (that.depositId) {
      passbookCache.withParam(that.depositId).toPromise().then(passbook => {
        that.activePassbook = passbook;
        that.activePassbook.nextLine = passbook.lastUsedLine + 1;
      });

      certificateCache.withParam(that.depositId).toPromise()
        .then(certificate => that.activeCertificate = certificate);
    }

    modalPrintPreviewService.canReprint('TERM_DEPOSIT_VALIDATION_SLIP', (active) => {
      that.isValidationSlipActive = active;
    });
    modalPrintPreviewService.canReprint('CERTIFICATE_OF_TERM_DEPOSIT', (active) => {
      that.isPrintCertificateActive = active;
    });
    modalPrintPreviewService.canReprint('TERM_DEPOSIT_PASSBOOK', (active) => {
      that.isPassbookPrintActive = active;
    });

    that.showPrint = (transaction) => {
      modalPrintPreviewService.show('TERM_DEPOSIT_VALIDATION_SLIP', {operationId: transaction.id});
    };

    that.isValidationSlipSupported = (transaction) => {
      return ['WITHDRAW_CASH',
        'WITHDRAW_CHECK',
        'DEPOSIT_CASH',
        'DEPOSIT_CUTOFF_CHECK',
        'DEPOSIT_CHECK',
        'CREDIT_MEMO',
        'DEBIT_MEMO'].includes(transaction.operationGroup);
    };

    that.toggleClosedDeposits = () => {
      if (that.showClosed) {
        that.visibleDeposits = that.termDeposits;
      } else {
        that.visibleDeposits = that.termDeposits.filter(deposit => deposit.status !== 'CLOSED');
      }

      if (that.selectedDeposit) {
        $location
          .path(`/customer/${customerId}/term-deposits/${that.selectedDeposit.id}`)
          .search('showClosed', that.showClosed.toString());
      }
    };

    that.keyValueDetails = _.memoize(currentTransaction => {
      let baseValueDetails = transactionDetailsBuilder.build(currentTransaction);
      baseValueDetails.push(transactionDetailsBuilder.buildPhpIfNotZero('Available Balance after', (currentTransaction.balanceAfter)));

      baseValueDetails = transactionDetailsBuilder.clean(baseValueDetails);

      return baseValueDetails;
    });

    that.keyValueDetails.cache = new WeakMap();

    that.filterInterestRate = () => {
      that.selectedDeposit.postingRate = interestBoardService.filterInterestRate(
        that.selectedDeposit.type.interestBoards,
        that.selectedDeposit.depositTerm,
        that.selectedDeposit.balance
      );
    };

    const confirmationIfPassbookRequired = (action) => {
      if (that.selectedDeposit.passbookConfig === 'PASSBOOK_REQUIRED') {
        confirmation('Is passbook available?', action);
      } else {
        action();
      }
    };

    that.onlyClosedDeposits = () => {
      return that.depositAccounts && that.depositAccounts.filter(deposit => deposit.status !== 'CLOSED').length === 0;
    };

    const s = customerCache.profile(customerId).toObservable()
      .combineLatest(customerCache.termDeposits(customerId, false).toObservable(), (profile, deposits) => {

        customerService.redirectWhenProfileIsInvalid(profile);

        that.profile = profile;
        return deposits;
      })
    // enrich with productName from product definitions
      .combineLatest(productDefinitionService.toObservable(), (deposits, products) =>
        deposits.map(da => {
          const p = _.find(products, {
            id: da.definitionId
          });
          return Object.assign(da, {
            depositType: p,
            productName: p ? p.productName : '<Unknown>'
          });
        })
      )
      // enrich with passbook configuration
      .combineLatest(termDepositsService.toObservable(), (deposits, termDepositTypes) =>
        deposits.map(deposit => {
          const depositType = termDepositTypes.find(dt => dt.id === deposit.typeId);
          return {...deposit,
                  passbookConfig: depositType ? depositType.passbookConfig : null,
                  printPassbookAfterValidationSlip: depositType ? depositType.printPassbookAfterValidationSlip : false
          };
        })
      )
      // enrich with branch name from branch service
      .combineLatest(branchService.toObservable(), (deposits, branches) =>
        deposits.map(da => {
          that.depositBranch = _.find(branches, {id: da.branchId});
          return Object.assign(da, {branchName: that.depositBranch.name});
        })
      )
      // enrich with user name from user service
      .combineLatest(userCache.toObservable(), (deposits, users) =>
        deposits.map(da => {
          const user = _.find(users, {
            id: da.userId
          });
          return Object.assign(da, {
            createdBy: user ? user.effectiveName : '<Unknown>'
          });
        })
      )
      // enrich with productName from product definitions
      .combineLatest(termDepositsService.toObservable(), (deposits, types) =>
        deposits.map(da => {
          const type = _.find(types, (d) => Number(d.id) === Number(da.typeId));
          return Object.assign(da, {
            type: type,
            depositGroup: type ? type.depositGroup : '<Unknown>'
          });
        })
      )
      // enrich with pdic casa types
      .combineLatest(casaTypesCache.toObservable(), (deposits, casaTypes) => {
        that.pdicCasaTypes = _.filter(casaTypes, {'regulatorType': 'PDIC'});
        return deposits.map(da => {
          const name = _.find(that.pdicCasaTypes, { id: da.pdicTypeId }).name;
          return Object.assign(da, {
            pdicTypeName: name.substring(name.indexOf('/') + 1)
          });
        })
      })
      .subscribe(deposits => {

        // Due to read-only purpose, read account in all statuses
        // and always fetch accounts of related people
        customerDepositService.searchDeposits([customerId], [], true, accounts => {
          _.forEach(deposits, d => {
            if (d.relatedAccountId) {
              const account = _.find(accounts, {id: d.relatedAccountId});
              d.relatedAccountName = account ? account.label : '<Unknown>';
            }
          })
        });

        // select first account when none selected
        if (!that.depositId && deposits.length > 0) $location.path(`/customer/${customerId}/term-deposits/${deposits[0].id}`);

        if (that.depositId) {
          const deposit = _.find(deposits, (d) => String(d.id) === that.depositId);
          if (deposit) {
            that.selectedDeposit = deposit;
            that.cashPlacement = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/cash-placement`);
            that.checkPlacement = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/check-placement`);
            that.interestsCashWithdrawal = () => confirmationIfPassbookRequired(() =>
              $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/interests-cash-withdrawal`));
            that.interestsCheckWithdrawal = () => confirmationIfPassbookRequired(() =>
              $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/interests-check-withdrawal`));
            that.creditMemo = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/credit-memo`);
            that.debitMemo = () => confirmationIfPassbookRequired(() =>
              $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/debit-memo`));
            that.createHold = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/create-hold`);
            that.createLock = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/create-lock`);
            that.terminateWithCash = () => confirmationIfPassbookRequired(() =>
              $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/cash-termination`));
            that.terminateWithCheck = () => confirmationIfPassbookRequired(() =>
              $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/check-termination`));
            that.rollover = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/manual-rollover`);
            that.edit = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/edit`);
            that.partialWithdrawalCash = () => confirmationIfPassbookRequired(() =>
              $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/partial-withdrawal-cash`));
            that.partialWithdrawalCheck = () => confirmationIfPassbookRequired(() =>
              $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/partial-withdrawal-check`));
            that.issuePassbook = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/issue-passbook`);
            that.issueCertificate = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/issue-certificate`);
            that.filterInterestRate();
            that.isCorporate = isCorporate(that.profile, that.selectedDeposit, that.pdicCasaTypes);
            if (that.isCorporate) {
              enrichOwnersWithRelatives(that.selectedDeposit, that.profile.relatives);
            }
          } else {
            $location.path(`/customer/${customerId}/term-deposits`);
          }

        }

        that.depositAccounts = deposits;

        if (that.showClosed) {
          that.visibleDeposits = deposits;
        } else {
          that.visibleDeposits = deposits.filter(deposit => deposit.status !== 'CLOSED');

          if (that.selectedDeposit && that.selectedDeposit.status === 'CLOSED' && that.selectDeposit && that.visibleDeposits[0]) {
            that.selectDeposit(that.visibleDeposits[0]);
          } else if (that.selectedDeposit && that.selectedDeposit.status === 'CLOSED' && that.onlyClosedDeposits()) {
            that.showClosed = true;
            that.toggleClosedDeposits();
          }
        }

        return deposits;
      });

    const subscription = new Subscription();
    subscription.add(s);

    that.switchContext = (owner) => {
      let redirectionUrl = `/customer/${owner.customerId}/accounts`;
      $location.path(redirectionUrl);
    };

    that.showSignature = (owner) => {
      const signatureFileId = owner.signatureFileId;
      that.signature = null;
      if (signatureFileId && !that.signature) {
        fileService.downloadFile(signatureFileId, false)
          .success(file => {
            that.signature = window.URL.createObjectURL(file);
          })
          .error(error => {
            notification.show("Error", "Could not show signature");
            that.showPopup = false;
            console.error(error);
          });
      }
      that.showPopup = true;
    };

    that.hideSignature = () => {
      that.showPopup = false;
      that.signature = null;
    };

    if (that.depositId) {
      // Add product locks subscription
      subscription.add(productLockCache.withParam(that.depositId).toObservable().subscribe(locks => {
        that.locks = locks;
        that.nonReleasedLocks = _.filter(locks || [], {released: false});
      }));
    }

    that.depositIsLocked = () => {
      return !_.isEmpty(that.nonReleasedLocks);
    };

    that.transactionClicked = (transaction, $event) => {
      $event.stopPropagation();
      that.selectedTransactionId = transaction.id;
    };

    that.hideInlinePanel = () => {
      that.selectedTransactionId = null;
    };

    that.selectDeposit = (deposit) => {
      that.selectedDeposit = deposit;
      $location.path(`/customer/${customerId}/term-deposits/${deposit.id}`)
    };

    that.createNew = () => $location.path(`/customer/${customerId}/term-deposits/create`);

    that.balanceIsPositive = () => {
      return that.selectedDeposit && that.selectedDeposit.balance > 0;
    };

    that.creditMemoAllowed = () => {
      return ['PENDING', 'ACTIVE', 'MATURE', 'INACTIVE'].includes(that.selectedDeposit.status)
        && !that.depositIsLocked();
    };

    that.placementAllowed = () => {
      let deposit = that.selectedDeposit;
      if (that.depositIsLocked()) return false;
      if ('PENDING' === deposit.status) return true;
      if (['ACTIVE', 'MATURE', 'INACTIVE'].includes(deposit.status)) {
        switch (deposit.type.placementStrategy) {
          case 'ADHOC':
            return true;
          case 'AFTER_CREDITING':
            const scheduler = deposit.creditingScheduler;
            if (scheduler && scheduler.enabled && scheduler.lastExecution) {
              const systemDate = moment(that.depositBranch.postingDate);
              const lastCrediting = moment(scheduler.lastExecution);
              return systemDate >= lastCrediting;
            }
            return false;
          default:
            return false;
        }
      }
      return false;
    };

    const schedulerExecutionAllowed = (deposit) => {

      const scheduler = deposit.creditingScheduler;
      if (!scheduler || !scheduler.enabled || !scheduler.cycleType || !scheduler.interval) return false;

      const referenceDate = scheduler.lastExecution || deposit.activatedOn;
      if (!referenceDate) return false;

      const interval = scheduler.interval;
      const start = moment(referenceDate);
      const systemDate = moment(that.depositBranch.postingDate);

      switch (scheduler.cycleType) {
        case 'DAY':
          return systemDate >= start.add(interval, 'days');
        case 'WEEK':
          return systemDate >= start.add(interval * 7, 'days');
        case 'MONTH':
          return systemDate >= start.add(interval, 'months');
        case 'QUARTER':
          return systemDate >= start.add(interval * 3, 'months');
        case 'YEAR':
          return systemDate >= start.add(interval, 'years');
        default:
          return false;
      }
    };

    const interestWithdrawalAllowed = (deposit) => {
      return ['ACTIVE', 'MATURE', 'INACTIVE'].includes(deposit.status)
        && deposit.activatedOn
        && schedulerExecutionAllowed(deposit);
    };

    that.cashWithdrawalAllowed = () => {
      const deposit = that.selectedDeposit;
      return ['MATURE', 'INACTIVE'].includes(deposit.status)
        || ['CASH_WITHDRAWAL', 'ADHOC_WITHDRAWAL'].includes(deposit.creditingStrategy)
        && interestWithdrawalAllowed(deposit);
    };

    that.checkWithdrawalAllowed = () => {
      const deposit = that.selectedDeposit;
      return ['MATURE', 'INACTIVE'].includes(deposit.status)
        || ['CHECK_WITHDRAWAL', 'ADHOC_WITHDRAWAL'].includes(deposit.creditingStrategy)
        && interestWithdrawalAllowed(deposit);
    };

    /**
     * Debit memo operation can be performed when:
     * 1. Product balance is > 0
     * 2. Product is: PENDING/ACTIVE/MATURE/INACTIVE
     * 3. Product is not locked
     */
    that.debitMemoAllowed = () => that.balanceIsPositive()
      && that.creditMemoAllowed();

    /**
     * Create hold operation can be performed when:
     * 1. Product balance is > 0
     * 2. Product is: ACTIVE/MATURE/INACTIVE
     */
    that.createHoldAvailable = () => that.balanceIsPositive()
      && ['ACTIVE', 'MATURE', 'INACTIVE'].includes(that.selectedDeposit.status)
      && !that.depositIsLocked();

    /**
     * Deposit termination can be performed when:
     * 1. Product available balance is > 0
     * 2. Product is: PENDING/ACTIVE/MATURE/INACTIVE
     */
    that.terminationAllowed = () => that.balanceIsPositive()
      && ['PENDING', 'ACTIVE', 'MATURE', 'INACTIVE'].includes(that.selectedDeposit.status)
      && !that.depositIsLocked();

    /**
     * Deposit rollover can be performed when:
     * 1. Maturity strategy = NONE
     * 2. Product is MATURE/INACTIVE
     */
    that.rolloverAllowed = () => {
      return that.selectedDeposit.maturityStrategy === 'NONE' && ['MATURE', 'INACTIVE'].includes(that.selectedDeposit.status);
    };

    /**
     * Deposit closing can be performed when:
     * 1. Product total balance is 0
     * 2. Product is PENDING
     */
    that.closingAllowed = () => {
      let deposit = that.selectedDeposit;
      return deposit.status === 'PENDING' &&
        deposit.balance === 0
        && deposit.holdsBalance === 0
        && !that.depositIsLocked();
    };

    that.editAvailable = () => that.selectedDeposit.status !== 'CLOSED'
      && !that.depositIsLocked();

    that.createLockAvailable = () => {
      return !that.depositIsLocked() && that.selectedDeposit.status !== 'CLOSED';
    };

    that.releaseLockAvailable = () => {
      return that.depositIsLocked() && that.selectedDeposit.status !== 'CLOSED';
    };

    that.releaseLock = () => {
      productLockService.releaseProductLocks(that.selectedDeposit, null, () => {
        customerCache.depositAccounts(customerId).refetch();
        $route.reload();
      })
    };

    that.partialWithdrawalAllowed = () => {
      return that.selectedDeposit
        && that.selectedDeposit.balance > 0
        && that.selectedDeposit.type.partialWithdrawalAllowed
        && _.includes(['ACTIVE', 'MATURE', 'INACTIVE'], that.selectedDeposit.status)
        && !that.depositIsLocked();
    };

    that.close = () => {
      confirmation(`Do you want to close deposit?`, () => {
        command.execute('CloseTermDeposit', {id: that.depositId}, {nxLoaderText: 'Closing term deposit'})
          .success(() => {
            customerCache.termDeposits(customerId).refetch();
          });
      })
    };

    that.revertAllowed = (operation) => {
      return revertCommandService.revertAllowed(operation);
    };

    const revert = (operation, input, question, revertCommandName) => {
      confirmation(question, () =>
        command.execute(revertCommandName, input).success(() => {
          customerCache.termDeposits(customerId).refetch();
          userCounterService.refresh();
        }));
    };

    that.revertCashPlacement = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the cash placement of ${operation.amount} PHP?`,
      'DepositCashPlacementRevert'
    );

    that.revertCheckPlacement = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the check placement of ${operation.amount} PHP?`,
      'DepositCheckPlacementRevert'
    );

    that.revertCreditMemo = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the credit memo for amount of ${operation.amount} PHP?`,
      'TermDepositCreditMemoRevert'
    );

    that.revertDebitMemo = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the debit memo for amount of ${operation.amount} PHP?`,
      'TermDepositDebitMemoRevert'
    );

    that.returnCheck = (operation) => {
      const input = {'id': operation.checkId};
      command.execute('ReturnDepositCheck', input).success(() => {
        customerCache.termDeposits(customerId).refetch();
      });
    };

    that.passbookIssueAvailable = () => passbookSupported(that.selectedDeposit)
      && ['ACTIVE'].includes(that.selectedDeposit.status)
      && !that.depositIsLocked();

    that.certificateIssueAvailable = () => !passbookSupported(that.selectedDeposit)
      && that.isPrintCertificateActive
      && ['ACTIVE'].includes(that.selectedDeposit.status)
      && !that.depositIsLocked();

    const passbookSupported = (depositType) => depositType ? depositType.passbookConfig !== 'PASSBOOK_NOT_SUPPORTED' : false;

    that.hasActivePassbook = () => passbookSupported(that.selectedDeposit) && that.activePassbook;

    that.hasActiveCertificate = () => !passbookSupported(that.selectedDeposit) && that.activeCertificate;

    that.reprintCertificate = () => printCertificateService.printFor(that.depositId);

    that.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }
});
