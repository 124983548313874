import module from 'module';
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import {addAccountLabels} from '../../../general-ledger/common/gl.utils';

import './stock-item-sell-view.style.less';

import templateUrl from './stock-item-sell-view.template.html';

class StockItemSellView {
  constructor ($scope, $routeParams, $location, $filter, $route, pawnItemCache, pawnItemTypeCache,
                        authentication, notification, confirmationTemplate, command, actionCategoryCache, systemPropertyCache,
                        glMappingsService, propertyConfigService) {
    this.$scope = $scope;
    this.$routeParams = $routeParams;
    this.$location = $location;
    this.$filter = $filter;
    this.$route = $route;
    this.pawnItemCache = pawnItemCache;
    this.pawnItemTypeCache = pawnItemTypeCache;
    this.authentication = authentication;
    this.notification = notification;
    this.confirmationTemplate = confirmationTemplate;
    this.command = command;
    this.actionCategoryCache = actionCategoryCache;
    this.systemPropertyCache = systemPropertyCache;
    this.glMappingsService = glMappingsService;
    this.cfg = propertyConfigService;
  }

  async $onInit() {
    this.item = {};

    this.discountRate = 0;
    this.discount = 0;

    this.discountRequired = true;
    this.discountRateRequired = true;

    const itemId = parseInt(this.$routeParams.pawnItemId);
    this.request = {
      itemId: itemId,
      finalPrice: null,
      denominationBundle: null,
      remarks: null,
      customerId: null,
      officialReceiptNumber: null
    };

    const [properties, ledgerAccounts, categories] = await Promise.all([
      this.systemPropertyCache.toPromise(),
      this.glMappingsService.accounts.toPromise(),
      this.actionCategoryCache.toPromise()
    ]);

    const ledgerSupportProperty = _.find(properties, {code: 'LEDGER_SUPPORT'});
    this.supportsLedger = ledgerSupportProperty && ledgerSupportProperty.value === 'TRUE';

    // Apply labels to accounts applicable for
    this.ledgerAccounts = addAccountLabels(ledgerAccounts);

    this.categories = categories.filter(cat => cat.actionType === 'CASH_IN' && cat.code === 'SELL_PAWN_ITEM');
    // Select the first and only category
    this.transaction = {
      categoryId: this.categories[0].id
    }

    if (itemId) {
      this.pawnItemTypes = await this.pawnItemTypeCache.toPromise();
      const pawnItem = await this.pawnItemCache.withParam(itemId).toPromise();

      this.item = {
        ...pawnItem,
        categoryLabel: this.getTypeLabel(pawnItem.categoryId),
        typeLabel: this.getTypeLabel(pawnItem.typeId),
        subtypeLabel: this.getTypeLabel(pawnItem.subtypeId)
      };

      this.onDiscountChange(); // initializes final price
    }
  }

  setCustomer(customer) {
    this.customer = customer;
  }

  getTypeLabel(typeId) {
    const type = _.find(this.pawnItemTypes, {id: typeId});
    return type && type.name ? type.name : '-';
  }

  onCategoryChange() {
    this.remarksRequired = false;
    if (this.transaction.categoryId) {
      const category = _.find(this.categories, {id: this.transaction.categoryId});
      this.remarksRequired = category && category.remarksRequired;

      if (!this.supportsLedger) {
        // no need to fill units
        return;
      }

      if (category.ledgerAccountFullCode) {
        const fixedAccount = _.find(this.ledgerAccounts, {fullCode: category.ledgerAccountFullCode});

        this.blockedUnits = [
          {
            accountCode: fixedAccount.fullCode,
            amount: this.request.finalPrice,
            entryType: this.entryType === 'CREDIT' ? 'DEBIT' : 'CREDIT'
          }
        ];
      } else {
        this.blockedUnits = [];
      }
    }
  };

  onDiscountRateChange() {
    this.discountDisabled = !!this.discountRate;
    this.discountRequired = !this.discountRate;
    this.discountRateRequired = !!this.discountRate;

    if (this.discountRate < 0) {
      this.discountRate = 0;
    }

    const currentValue = new BigNumber(this.item.sellingPrice);

    const discountPercentage = new BigNumber(parseFloat(this.discountRate)).div(100);
    const discountVal = currentValue.mul(discountPercentage);
    this.discount = discountVal.toNumber();

    this.request.finalPrice = currentValue.minus(discountVal).toNumber();

    this.onCategoryChange();
  }

  onDiscountChange() {
    this.discountRateDisabled = !!this.discount;
    this.discountRateRequired = !this.discount;
    this.discountRequired = !!this.discount;
    const currentValue = new BigNumber(this.item.sellingPrice);
    const discountVal = new BigNumber(this.discount);

    const discountPercentage = discountVal.div(currentValue).mul(100).toNumber();
    // most suitable way to round to 2 decimal places
    this.discountRate = Math.round(discountPercentage * 100) / 100;

    this.request.finalPrice = currentValue.minus(discountVal).toNumber();

    this.onCategoryChange();
  }

  cancel() {
    this.$location.path('/dashboard/stocked-items');
  }

  async sell() {
    const confirmed = await this.confirmationTemplate({
      question: 'Do you want to sell the following item?',
      details: [
        {label: 'Customer', description: `${this.customer.name} (${this.customer.customerNumber})`},
        {label: 'Transaction category', description: this.categories.find(c => c.id === this.transaction.categoryId).name},
        {label: 'Category', description: this.item.categoryLabel},
        {label: 'Type', description: this.item.typeLabel},
        {label: 'Subtype', description: this.item.subtypeLabel},
        {label: 'Selling price', description: this.$filter('php')(this.item.sellingPrice)},
        {label: 'Discount rate', description: this.discountRate},
        {label: 'Discount', description: this.$filter('php')(this.discount)},
        {label: 'Final payment', description: this.$filter('php')(this.request.finalPrice)},
        {label: 'Remarks', description: this.request.remarks || ''},
        {label: 'Official receipt #', description: this.request.officialReceiptNumber || ''}
      ]
    });
    if (!confirmed) {
      return;
    }

    const request = {
      ...this.request,
      customerId: this.customer.customerId,
      branchId: this.item.branchId,
      units: this.supportsLedger ? this.transaction.units : null
    };

    const response = await this.command.execute('SellStockItem', request);
    if (response.approvalRequired) {
      return;
    }

    this.$location.path(`/customer/${this.customer.customerId}/stocked-items`);
  }
}

module.component('stockItemSellView', {
  templateUrl: templateUrl,
  controller: StockItemSellView
});
