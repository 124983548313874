import module from 'module';
import templateUrl from './customer-merge.template.html';
import './customer-merge.style.less';

class MergeCustomers {
  constructor(notification, customerCache, branchService, http, $location, confirmation, command, popup, permissionCache, $route, customerMergeStorageService) {
    this.notification = notification;
    this.customerCache = customerCache;
    this.branchService = branchService;
    this.http = http;
    this.$location = $location;
    this.confirmation = confirmation;
    this.command = command;
    this.popup = popup;
    this.permissionCache = permissionCache;
    this.$route = $route;
    this.selectedCustomers = [];
    this.customerMergeStorageService = customerMergeStorageService;
  }

  async $onInit() {
    await this.initCustomerData();
  }

  async initCustomerData() {
    this.customerId = this.$route.current.params['customerId'];
    [this.customerProfile, this.duplicatedCustomerIds] = await Promise.all([this.fetchCustomerProfile(this.customerId), this.fetchDuplicatedCustomerIds(this.customerId)]);
    this.customerType = this.customerProfile.customerType;

    const suggestedToMerge = this.duplicatedCustomerIds ?
      await Promise.all(this.duplicatedCustomerIds.map(cstId => this.fetchFormattedCustomerProfile(cstId))) : [];

    const customers = this.customerMergeStorageService.get(this.customerId);
    if (customers.length > 0) {
      this.selectedCustomers = customers;
    } else {
      this.selectedCustomers = suggestedToMerge;
      this.customerMergeStorageService.add(this.customerId, this.selectedCustomers);
    }
  }

  chooseAndFormatAddress(addresses) {
    if (addresses && addresses.length > 0) {
      // choose primary address or the first one
      const chosenAddress = addresses.find(address => address.primary === true) || addresses.find(() => true);

      return ''
        .concat(chosenAddress.street ? chosenAddress.street.toString() + ', ' : '')
        .concat(chosenAddress.barangay ? chosenAddress.barangay.toString() + ', ' : '')
        .concat(chosenAddress.city ? chosenAddress.city.toString() + ', ' : '')
        .concat(chosenAddress.province ? chosenAddress.province.toString() + ', ' : '')
        .concat(chosenAddress.postalCode ? chosenAddress.postalCode.toString() + ', ' : '')
    }
  }

  async fetchCustomerProfile(id) {
    return this.http.get(`/customers/${id}`).toPromise();
  }

  async fetchDuplicatedCustomerIds(id) {
    return this.http.get(`/customers/${id}/duplicates`).toPromise();
  }

  async fetchFormattedCustomerProfile(id) {
    const cstProfile = await this.fetchCustomerProfile(id);
    const branches = await this.branchService.toPromise();

    return {
      id: cstProfile.id,
      customerNumber: cstProfile.customerNumber,
      branchId: cstProfile.branchId,
      branchName: branches.find(branch => branch.id === cstProfile.branchId).name,
      effectiveName: cstProfile.effectiveName,
      registrationOrBirthDate: cstProfile.individualData ? cstProfile.individualData.birthDate
        : cstProfile.corporateData.registrationDate,
      incorporationOrBirthPlace: cstProfile.individualData ? cstProfile.individualData.birthPlace
        : cstProfile.corporateData.placeOfIncorporation,
      address: this.chooseAndFormatAddress(cstProfile.addresses)
    };
  }

  async addCustomerToMergingContext(customer) {
    if (Number(customer.customerId) === Number(this.customerId)) {
      this.notification.show('Warning', 'Cannot merge with the same customer.');
      return;
    }

    if (this.selectedCustomers.find(cst => cst.id === customer.customerId)) {
      this.notification.show('Warning', 'Customer has already been added.');
      return;
    }

    if (customer.customerType === 'GROUP') {
      this.notification.show('Warning', 'Merging of GROUP customers is not allowed.');
      return;
    }

    const customerToMerge = await this.fetchFormattedCustomerProfile(customer.customerId);
    customerToMerge.merge = true;
    this.selectedCustomers.push(customerToMerge);
    this.customerMergeStorageService.add(this.customerId, customerToMerge);
  }

  async action(mode) {
    if (await this.confirmation(this.getConfirmationMessage(mode), true)) {
      const request = {
        sourceCustomerIds: this.getCustomersToMerge().map(cst => cst.id),
        targetCustomerId: this.customerId,
        mode: mode
      };

      await this.command.execute('MergeCustomers', request).toPromise();

      this.customerMergeStorageService.clearAll();
      this.customerCache.refetch();
      this.permissionCache.evict();

      this.redirectBack();
    }
  }

  getConfirmationMessage(mode) {
    if (mode === 'VERIFY_ONLY') {
      return `Do you want to mark ${this.customerProfile.effectiveName} ${this.customerProfile.customerNumber} as verified?`;
    }

    let message = 'Do you want to ';
    if (mode === 'MERGE_AND_VERIFY') {
      message += 'mark as verified and ';
    }
    message += `merge the following duplicate CIFs to ${this.customerProfile.effectiveName} ${this.customerProfile.customerNumber}?`;

    const customerDetails = this.getCustomersToMerge().map(cst => `<br/><br/>- ${cst.effectiveName} ${cst.customerNumber}`);
    return message.concat(customerDetails);
  }

  viewProfile(customerId) {
    this.$location.path(`/customer/${customerId}/profile`);
  }

  onMergeChange() {
    this.customerMergeStorageService.add(this.customerId, this.selectedCustomers);
  }

  redirectBack() {
    this.customerMergeStorageService.clear(this.customerId);
    this.$location.path(`/customer/${this.customerId}/profile`);
  }

  getCustomersToMerge() {
    return this.selectedCustomers ? this.selectedCustomers.filter(c => c.merge) : [];
  }

  isMergeAllowed() {
    return this.getCustomersToMerge().length > 0
  }

  isVerifyAllowed() {
    return this.customerProfile ? !this.customerProfile.verified : false;
  }
}

module.component('customerMerge', {
  templateUrl,
  bindings: {
    customerId: '<'
  },
  controller: MergeCustomers
});
