import module from 'module';

module.factory('layawayStatusMapper', () => {
    return (status) => {
      switch (status) {
        case 'ACTIVE':
          return 'Active';
        case 'PAST_DUE_PERFORMING':
          return 'Expired';
        case 'PAST_DUE_NON_PERFORMING':
          return 'Forfeited';
        case 'CANCELED':
          return 'Cancelled';
        case 'CLOSED':
          return 'Claimed';
        default:
          console.warn('WARNING: Unknown layaway status: ' + status);
          return status;
      }
    }
  }
);
