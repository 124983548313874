import module from 'module';
import _ from 'lodash';
import moment from 'moment';
import BigNumber from "bignumber.js";


const templateUrl = require('./interests-check-withdrawal.template.html');
module.component('customerTermDepositInterestsCheckWithdrawal', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, http, customerCache, productDefinitionService, confirmation, notification,
                        branchService, authentication, depositoryAccountCache, command, modalPrintPreviewService,
                        casaProductPrintService) {
    let that = this;
    that.termDeposit = {};
    that.banks = [];
    that.interestForecast = {};


    that.overrideCheckbox = false;
    that.taxRecalculated = false;

    that.override = {
      interest : 0,
      netInterest : 0,
      withholdingTax: 0
    };

    // Checking account config
    that.selectConfig = {
      placeholder: 'Select account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };

    // form parameters
    that.micrNumber = null;
    that.depositoryAccountId = null;
    that.validFrom = null;
    that.payee = null;
    that.remarks = null;

    let customerId = $route.current.params['customerId'];
    let depositId = $route.current.params['depositId'];

    const branchSub = branchService.toObservable().subscribe(branches => {
      const branchId = authentication.context.branchId;
      const authBranch = _.find(branches, {id: branchId});
      if (authBranch) {
        const systemDate = moment(authBranch.postingDate).toDate();
        that.systemDate = systemDate;
        that.validFrom = systemDate;
      }
    });

    const depositoryAccountSub = depositoryAccountCache.toObservable().subscribe(depositoryAccounts => {
      that.checkingAccounts = _.filter(depositoryAccounts, {accountType: 'CHECKING'});
    });

    // Fetch term deposit interest forecast
    that.readInterestForecast = (depositId) => {
      http.post(`/products/deposits/${depositId}/interest`, {mode:'STANDARD'})
        .success((forecast) => {
          that.interestForecast = forecast;
          that.override.interest = that.interestForecast.interest;
          that.override.netInterest = that.interestForecast.netInterest;
          that.override.withholdingTax = that.interestForecast.withholdingTax;
        })
        .error(() => {
          notification.show("Error", "Failed to load interest forecast.")
        });
    };

    customerCache.termDeposits(customerId).toObservable()
      .map(deposits => _.find(deposits, (d) => d.id == depositId))
      .combineLatest(productDefinitionService.toObservable(), (deposit, products) => {
          const p = _.find(products, {id: deposit.definitionId});
          return Object.assign(deposit, {
            productName: p ? p.productName : '<Unknown product>'
          });
        }
      )
      .combineLatest(customerCache.profile(customerId).toObservable(), (deposit, customer) => {
        that.payee = customer.effectiveName;
        return deposit;
      })
      .subscribe(deposit => {
        that.termDeposit = deposit;
        that.interestForecast = that.readInterestForecast(deposit.id);
        that.remarks = 'Interest withdrawal from deposit no. ' + deposit.productNumber;
      });

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${depositId}`);

    that.updateOverrides = () => {
      that.taxRecalculated = false;
    };

    that.recalculateTax = () => {
      http.get(`/products/deposits/${depositId}/interest/calculate-withholding-tax?interest=${that.override.interest}`)
        .success((newTax) => {
          that.override.netInterest = new BigNumber(that.override.interest).sub(newTax).toNumber();
          that.override.withholdingTax = newTax;
          that.taxRecalculated = true;
        });
    };

    that.withdrawalEnabled = () => {

      // If firm is invalid -> leave immediately
      if (that.transactionForm.invalid) return false;

      // If override is enabled base validation on override values
      if (that.overrideCheckbox) {
        return that.taxRecalculated
          && that.override
          && that.override.interest
          && that.override.netInterest > 0;
      } else {
        return that.interestForecast.interest
          && that.interestForecast.netInterest > 0;
      }
    };

    that.withdraw = () => {
      let netInterest = that.overrideCheckbox ? that.override.netInterest : that.interestForecast.netInterest;
      confirmation(`Do you want to deposit ${$filter('php') (netInterest)}?`, () => {
        command.execute('WithdrawDepositInterestByCheck', {
          productId: depositId,
          customerId: customerId,
          entryType: "DEBIT",
          amount: that.overrideCheckbox ? that.override.netInterest : that.interestForecast.netInterest,
          override: that.overrideCheckbox ? that.override.netInterest : null,
          validFrom: that.validFrom,
          depositoryAccountId: that.depositoryAccountId,
          micrNumber: that.micrNumber,
          checkNumber: that.micrNumber.substr(0, 10),
          payee: that.payee,
          remarks: that.remarks
        }).success((res) => {
          casaProductPrintService.printFor('TERM_DEPOSIT', customerId, depositId, res.output.id).finally(() => {
            customerCache.termDeposits(customerId).refetch();
            that.redirectBack();
          });
        });
      });
    };

    that.$onDestroy = () => {
      depositoryAccountSub.unsubscribe();
      branchSub.unsubscribe();
    };
  }
});
