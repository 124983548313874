import module from 'module';
import moment from 'moment';
import _ from "lodash";
import { isCorporate, multipleAccountOwnershipsEnabled, mapSignatoryToOwnership } from '../../common/casa-product-util';

const templateUrl = require('./edit-account.template.html');
module.component('customerAccountEdit', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, customerCache, casaTypesCache, confirmation,
                        branchService, command, dict) {
    const that = this;

    const customerId = $route.current.params['customerId'];
    const accountId = $route.current.params['accountId'];

    that.account = {};
    that.pdicCasaTypes = [];
    that.accounts = [];
    that.command = {};
    that.accountSelectConfig = {
      placeholder: 'Select account',
      allowEmptyOption: true
    };

    that.$onInit = () => {
      dict.onLoadingComplete(() => {
        that.loadAccount()
      });
    };

    that.loadAccount = () => {
      customerCache.depositAccounts(customerId).toObservable()
        .combineLatest(casaTypesCache.toObservable(), (accounts, casaTypes) => {
          that.pdicCasaTypes = _.filter(casaTypes, {'regulatorType': 'PDIC'});
          let account = _.find(accounts, {id: Number(accountId)});
          if (account) {
            account.pdicTypeName = account.pdicTypeId ? _.find(that.pdicCasaTypes, {id: account.pdicTypeId}).name : null;
          }
          //remove edited accountID from the list of related accounts
          that.accounts = _.filter(accounts, a => a.id != accountId);
          that.accounts = _.filter(that.accounts, a => a.status === 'ACTIVE');
          return account;
        })
        .combineLatest(branchService.toObservable(), (account, branches) => {
          const branch = _.find(branches, {id: account.branchId});
          if (branch.postingDate) {
            that.branchPostingDate = moment(branch.postingDate).toDate();
          } else {
            that.branchPostingDate = Date();
          }
          return account;
        })
        .combineLatest(customerCache.profile(customerId).toObservable(), (account, profile) => {
          let signatoryTypeId = _.find(dict.RELATIVE_TYPE, {code: 'SIGNATORY'}).id;
          that.profile = profile;
          that.signatoryRelatives = _.filter(profile.relatives, {typeId: signatoryTypeId});
          that.signatoryNames = account.ownership
            .filter(o => o.ownershipType === 'SIGNATORY')
            .map(o => o.effectiveName);
          return account;
        })
        .first()
        .subscribe(account => {
          that.account = account;
          that.command = {
            productId: accountId,
            pdicTypeId: account.pdicTypeId,
            taxExempted: account.taxExempted,
            accruedInterest: account.accruedInterest,
            lastAccrualDate: account.lastAccrualDate,
            lastInterestApplication: account.lastInterestApplication,
            lastDormancyCharge: account.lastDormancyCharge,
            lastMaintainingCharge: account.lastMaintainingCharge,
            relatedAccountId: account.relatedAccountId,
            ownership: account.ownership,
            signatoryIds: account.ownership.filter(o => o.ownershipType === 'SIGNATORY').map(o => o.relativeId)
          };
          that.pdicCasaTypesChanged();
        });
    }


    that.pdicCasaTypesChanged = () => {
      that.multipleAccountOwnershipEnabled = multipleAccountOwnershipsEnabled(that.profile, that.command, that.pdicCasaTypes);
      that.isCorporate = isCorporate(that.profile, that.command, that.pdicCasaTypes);
      if (!that.isCorporate) {
        that.command.signatoryIds = [];
      }
    };

    that.redirect = () => {
      $location.path(`/customer/${customerId}/accounts/${accountId}`);
    };

    that.redirectBack = () => {
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => {
        that.redirect();
      });
    };

    that.update = () => {
      confirmation(`Do you want to update the "${that.account.productNumber}" account?`, () => {
        removeTimeFromDate(that.command);

        const commandInput = angular.copy(that.command);

        let signatories = that.signatoryRelatives
          .filter(s => commandInput.signatoryIds.includes(s.id))
          .map(sig => mapSignatoryToOwnership(sig));

        commandInput.ownership = that.account.ownership.filter(o => o.ownershipType !== 'SIGNATORY').concat(signatories);

        if (!(that.multipleAccountOwnershipEnabled || that.isCorporate)) {
          //remove everyone but owner
          that.command.ownership = that.command.ownership.filter(o => o.ownershipType === 'OWNER');
        }
        command.execute('UpdateAccount', commandInput, {nxLoaderText: 'Processing operation...'}).success(() => {
          customerCache.depositAccounts(customerId).refetch();
          that.redirect();
        });
      });
    };

    //private methods

    const removeTimeFromDate = (object) => {
      for(let key in object) {
        if (object.hasOwnProperty(key)) {
          let value = object[key];
          if (value instanceof Date) {
            object[key] = $filter('nxDate')(value);
          }
        }
      }
    };
  }
});
