import module from 'module'

import templateUrl from  './print-certificate.template.html';

class PrintCertificate {
  constructor(printsCache, modalPrintPreviewService) {
    this.printsCache = printsCache;
    this.modalPrintPreviewService = modalPrintPreviewService;

    this.onModalApiReady = ({api,}) => {
      this.modalApi = api;
      this.reprintCertificate();
    };
  }

  async reprintCertificate() {
    this.certificateRemarks = null;
    const remarksActive = await this.certificateRemarksActive();

    if (remarksActive) {
      const {accepted} = await this.modalApi.show();
      if (!accepted) {
        this.onClose();
        return;
      }
    }

    this.modalPrintPreviewService.show('CERTIFICATE_OF_TERM_DEPOSIT', {
      depositId: this.productId,
      remarks: this.certificateRemarks
    }, this.onClose);
  }

  async certificateRemarksActive() {
    const prints = await this.printsCache.toPromise();
    return prints
      .find(print => print.code === 'CERTIFICATE_OF_TERM_DEPOSIT')
      .lines
      .find(line => line.field === 'remarks')
      .active;
  }
}

module.component('printCertificate', {
  templateUrl,
  bindings: {
    productId: '<',
    onClose: '&'
  },
  controller: PrintCertificate
});