import module from 'module';
import _ from 'lodash';

class PawnTagService {
  constructor(authentication, rolesCache, pawnTagCache) {
    this.authentication = authentication;
    this.rolesCache = rolesCache;
    this.pawnTagCache = pawnTagCache;
  }

  async getPermittedTagIds() {
    const roles = await this.rolesCache.toPromise();
    const permissions = _.filter(roles, r => this.authentication.context.roleIds.includes(r.id))
      .map(r => r.permissions)
      .reduce((_, val) => val);

    const pawnTagReadPermission = _.find(permissions, {name: 'PWN_TAG_READ'})
    if (!pawnTagReadPermission) {
      return [];
    }

    return pawnTagReadPermission.predicates['AVAILABLE_TAGS'];
  }

  async hasCompletePawnTagReadPermission() {
    const [tags, permittedTagIds] = await Promise.all([this.pawnTagCache.toPromise(), this.getPermittedTagIds()]);
    return tags.length - permittedTagIds.length === 0;
  }
}

module.service('pawnTagService', PawnTagService);
