import module from 'module';
import ParameterizedLocalCache from "../../shared/util/parameterized-local-cache";

module.factory('forexCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (branchId) => http.get(`/forex/exchange-rates?branchId=${branchId}`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.forex,
    cacheName: 'forexCache'
  })
);
