import module from 'module';

import BigNumber from "bignumber.js";

const templateUrl = require('./add-group-members.template.html');
module.component('addGroupMembers', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '=',
    'form': '='
  },
  controller: function ($scope, $timeout, $route, http, dict, notification) {
    let that = this;

    that.dict = dict;

    that.customerId = $route.current.params['customerId'];
    that.loansCount = {};
    that.summary = {
      active: 0,
      total: 0,
      principalBalance: new BigNumber(0),
      savingsBalance: new BigNumber(0),
    };


    that.addMember = (customer) => {
      if (!that.profile.customerIds.includes(customer.customerId)) {
        that.profile.customers.push(customer);
        that.profile.customerIds.push(customer.customerId);
      } else {
        notification.show("Error", "Customer already added!");
      }
    };

    if (that.customerId) {
      http.get(`/products/loans/group-loan/${that.customerId}/customers-individual-loans-info`).success(infos => {
          for (let info of infos) {
            that.loansCount[info.customerId] = info;
            const summary = that.summary;

            summary.active += info.active;
            summary.total += info.total;
            summary.principalBalance = summary.principalBalance.plus(info.principalBalance);
            summary.savingsBalance = summary.savingsBalance.plus(info.savingsBalance);
          }
        }
      );
    }

    that.onRemoveMember = (idx) => {
      let customer = that.profile.customers[idx];
      that.profile.customers.splice(idx, 1);
      let index = that.profile.customerIds.findIndex((el) => customer.customerId === el);
      that.profile.customerIds.splice(index, 1);
    };


  }
});
