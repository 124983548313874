import module from 'module';

import templateUrl from './stocked-item-rates.template.html';
import './stocked-item-rates.style.less';

class StockedItemRates {
  constructor(http, command, authentication, systemPropertyCache) {
    this.http = http;
    this.command = command;
    this.permission = authentication.permissions;
    this.systemPropertyCache = systemPropertyCache;
  }

  async $onInit() {
    const systemProperties = await this.systemPropertyCache.toPromise();
    this.stockedItemMarkupPercentageProperty = _.find(systemProperties, {code: 'STOCKED_ITEM_REPRICING_MARKUP_PERCENTAGE'});
    this.stockedItemMarkupRate = Number(this.stockedItemMarkupPercentageProperty.value);
  }

  saveMarkupRate() {
    const input = {
      codeToValue: {'STOCKED_ITEM_REPRICING_MARKUP_PERCENTAGE': this.stockedItemMarkupRate}
    };

    this.command.execute('ChangeSecurityProperties', input, {nxLoaderText: 'Updating markup rate.'})
      .success(() => {
        this.systemPropertyCache.refetch();
      });
  }
}

module.component('stockedItemRates', {
  templateUrl,
  controller: StockedItemRates
});