import module from 'module';

class TransactionCategoryService {

  constructor(confirmation, command, actionCategoryCache, $filter, $location) {
    this.confirmation = confirmation;
    this.command = command;
    this.actionCategoryCache = actionCategoryCache;
    this.$filter = $filter;
    this.$location = $location;
  }

  async remove(category, actionType) {
    let id = category.id;
    const removeApproved = await this.confirmation(`Do you want to delete '${this.$filter('prettyEnum')(actionType)}' - '${category.name}' action category?`);
    if (!removeApproved) {
      return;
    }

    await this.command.execute('RemoveActionCategory', {id: id}).toPromise();
    this.actionCategoryCache.refetch();
  }

  async save(actionCategory) {
    await this.command.execute('CreateActionCategory', actionCategory, {nxLoaderText: 'Creating category'}).toPromise();
    this.actionCategoryCache.refetch();
    this.redirectBack();
  }

  async update(request) {
    await this.command.execute('UpdateActionCategory', request, {nxLoaderText: 'Updating category'}).toPromise();
    this.actionCategoryCache.refetch();
    this.redirectBack();
  }

  redirectBack() {
    this.$location.path(`/admin/general-ledger/`);
  }
}

module.service('transactionCategoryService', TransactionCategoryService)