import module from 'module';
import _ from 'lodash';

class UserIdleCheck {
  constructor(authentication, $timeout, systemPropertyCache, notification) {
    this.authentication = authentication;
    this.$timeout = $timeout;
    this.systemPropertyCache = systemPropertyCache;
    this.notification = notification;
    this.loggedOut = false;
    this.events = ['mousemove', 'mousedown', 'keypress', 'touchmove', 'mousewheel'];
    this.listeningOnEvents = false;

    // we subscribe only after we receive first USER_IDLE_TIME_MINUTES
    // otherwise we could logout user due to idle time, because he moved mouse before we received the first IDLE_TIME value
    // (undefined !== 0)
    this.subscription = systemPropertyCache.toObservable()
      .subscribe(props => {
        if(!this.listeningOnEvents) {
          for (let event of this.events) {
            document.addEventListener(event, () => this.resetTimer(), {passive: true});
          }

          this.listeningOnEvents = true;
        }

        this.userIdleTime = parseInt(_.get(props.find(prop => prop.code === 'USER_IDLE_TIME_MINUTES'), 'value', '0'));
        this.resetTimer();
      });
  }

  startTimer() {
    if (this.userIdleTime !== 0 && !this.loggedOut) {
      this.timeoutP = this.$timeout(() => {
        this.notification.show('Logged out due to inactivity');
        console.info('Logged out due to inactivity');
        this.loggedOut = true;
        this.authentication.logout()
      }, this.userIdleTime * 60 * 1000);  // property value in minutes
    }

  }

  resetTimer() {
    this.stopTimer();
    this.startTimer();
  }

  stopTimer() {
    if (this.timeoutP) {
      this.$timeout.cancel(this.timeoutP);
      this.timeoutP = null;
    }
  }

  $onDestroy() {
    this.subscription.unsubscribe();
  }
}

module.component('userIdleCheck', {
  controller: UserIdleCheck,
});
