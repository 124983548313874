import module from 'module';
import _ from 'lodash';
import angular from 'angular';

const templateUrl = require('./users-list.template.html');

module.component('usersList', {
  templateUrl,
  controller: function ($route, $filter, $location, command, userCache, branchService, confirmation, breadcrumbs) {
    this.users = [];
    this.branchId = $route.current.params.branchId;
    this.selectedStatus = null;
    this.detailLink = `/admin/organization/users/${this.branchId}/user`;

    let branchSub = branchService.toObservable().subscribe(data => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == this.branchId) {
          this.branch = data[i];
        }
      }

      _.set(breadcrumbs, 'options', {
        'users-list-label': 'Users > ' + this.branch.name
      });
    });
    let userSub = userCache.toObservable().subscribe(data => {
      this.users = [];
      for (let i = 0; i < data.length; i++) {
        let user = data[i];
        // Exclude phantom & technical users
        if (!user.phantom && !user.technical && user.branchId == this.branchId) {
          user.allRoles = [];

          for (let z = 0; z < user.roles.length; z++) {
            user.allRoles.push($filter('prettyEnum')(user.roles[z].name));
          }
          user.allRoles = user.allRoles.join(', ');
          this.users.push(user);
        }
      }

      this.allUsers = angular.copy(this.users);
    });

    this.updateList = () => {
      this.users = _.filter(this.allUsers, (user) => {
        return (user.enabled === this.selectedStatus || this.selectedStatus === null)
      });
    };

    this.addNew = () => {
      $location.path(`${this.detailLink}/create`);
    };

    this.editUser = (userId) => {
      $location.path(`${this.detailLink}/${userId}`);
    };

    this.deleteUser = (id) => {
      confirmation('Are you sure you want to delete this user?' +
        ' Please note that this action cannot be reversed and the username cannot be used again ' +
        'as it must stay in the system for audit purposes.', () => {
        command.execute('DeleteUser', {id: id})
          .success(() => {
            userCache.refetch();
          });
      });
    };

    this.$onDestroy = () => {
      userSub.unsubscribe();
      branchSub.unsubscribe();
    };
  }
});
