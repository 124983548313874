import module from 'module';
import _ from 'lodash';
const templateUrl = require('./pawn-batch-change-auction-date.template.html');

module.component('pawnBatchChangeAuctionDate', {
  templateUrl,
  controller: function (branchService, confirmation, $filter, popup, $route, command) {
    const that = this;

    that.command = {};

    const branchSub = branchService.toObservable().subscribe(branches => {
      that.branches = branches;
    });

    that.execute = () => {
      const branch = _.find(that.branches, b => b.id === that.command.branchId);
      const minCreationDate = $filter('apiDate')(that.command.minCreationDate);
      const maxCreationDate = $filter('apiDate')(that.command.maxCreationDate);
      const auctionDate = $filter('apiDate')(that.command.auctionDate);
      confirmation(`Do you want to set the auction date <strong>${auctionDate}</strong><br>
      for all pawns created in branch <strong>${branch.name}</strong><br>
      between <strong>${minCreationDate}</strong> and <strong>${maxCreationDate}</strong> (inclusive)?
      <br><br>
      <strong class="red">WARNING: This action cannot be reverted.</strong>
      `, () => {
        command.execute('BulkChangePawnAuctionDate', {
          branchId: that.command.branchId,
          minCreationDate: minCreationDate,
          maxCreationDate: maxCreationDate,
          auctionDate: auctionDate
        }).success(ret => popup({
          text: `Bulk updated successful. Updated <strong>${ret.output}</strong> pawns.`,
          callback: () => $route.reload(),
          renderHtml: true}), true);
      }, () => {}, true);
    };

    that.$onDestroy = () => {
      branchSub.unsubscribe();
    };

  }
});
