import module from 'module';

const templateUrl = require('./update-branch-asset-counter.template.html');

module.component('updateBranchAssetCounter', {
  templateUrl,
  controller: function (confirmationTemplate, http, popup, $filter, $route, command, branchService) {
    const that = this;

    that.command = {
      branchId: null,
      cashDiff: 0,
      checkDiff: 0
    };

    const s1 = branchService.toObservable().subscribe(branches => that.branches = branches);

    that.onBranchChange = (branchId) => {
      that.branch = _.find(that.branches, (b) => Number(b.id) === Number(branchId));
      if (that.branch) {
        http.get(`/management/branches/${that.branch.id}/counter`).success((counter) => {
          that.branchCash = counter.totalCash;
          that.branchCheck = counter.totalCheck;
        });
      }
    };

    that.execute = () => {
      confirmationTemplate({
        question: 'Do you want to update the branch asset counter?',
        details: [
          {label: 'Branch name', description: $filter('prettyEnum')(that.branch.name)},
          {label: 'Increase cash by:', description: $filter('php')(that.command.cashDiff)},
          {label: 'Increase check by', description: $filter('php')(that.command.checkDiff)}
        ],
        yesCallback: () => command.execute('UpdateBranchAssetCounter', that.command).success(() => $route.reload())
      })
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
    };
  }
});
