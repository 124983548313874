import module from 'module';

import templateUrl from './layaway-product-general.template.html';

class LayawayProductGeneralComponent {
  $onInit() {
    this.installmentIntervals = [{ label: 'Monthly', value: 'MONTHLY' }];
  }
}

module.component('layawayProductGeneralForm', {
  templateUrl,
  bindings: {
    layawayProduct: '=',
    form: '=',
    createMode: '<',
    branches: '<'
  },
  controller: LayawayProductGeneralComponent
});
