import _ from 'lodash';
import moment from 'moment';
import BigNumber from "bignumber.js";

const MAX_OFW_MONTHLY_CONTRIBUTION = 1760;

export const confirmationMessage = ({
                                      amount,
                                      startingPeriod,
                                      endingPeriod,
                                      payorType,
                                      moneyFormatter,
                                      calculateFlexiFund
                                    }) => {

  const startingMoment = moment(startingPeriod, 'YYYY-MM');
  const endingMoment = moment(endingPeriod, 'YYYY-MM');
  const monthDiff = endingMoment.diff(startingMoment, 'months') + 1;

  let monthlyAmount = null;
  let flexiFund = null;

  // everything above 1760 per month for ofw is flexi fund
  const nonFlexiFundMaxAmount = new BigNumber(monthDiff).mul(MAX_OFW_MONTHLY_CONTRIBUTION);
  if(!calculateFlexiFund || nonFlexiFundMaxAmount.greaterThan(amount)) {
    monthlyAmount = new BigNumber(amount)
      .dividedBy(monthDiff)
      .round(2)
      .toNumber();
  } else {
    flexiFund = new BigNumber(amount)
      .minus(nonFlexiFundMaxAmount)
      .round(2)
      .toNumber();
    monthlyAmount = MAX_OFW_MONTHLY_CONTRIBUTION;
  }

  const text = [{
    label: 'MONTHLY',
    value: monthlyAmount,
    formatter: moneyFormatter
  }, {
    label: 'TOTAL',
    value: amount,
    formatter: moneyFormatter
  }, {
    label: 'FLEXI',
    value: flexiFund,
    formatter: moneyFormatter
  }, {
    label: 'APP PERIOD',
    value: `${startingPeriod} - ${endingPeriod}`,
    formatter: text => text
  }];

  return text
    .filter(item => item.value)
    .map(({label, value, formatter}) => `${label}: ${formatter(value)}`)
    .join('<br/>');
};


export const payorTypeForPRN = prn => {
  const bsrValue = prn[0];
  switch (bsrValue) {
    case '5':
      return 'EMPLOYER';
    case 'H':
      return 'HOUSEHOLD_EMPLOYER';
    case 'S':
      return 'SELF_EMPLOYED_MEMBER';
    case 'V':
      return 'VOLUNTARY_MEMBER';
    case 'O':
      return 'OVERSEAS_WORKER';
    case 'N':
      return 'NON_WORKING_SPOUSE';
    case 'F':
      return 'FARMER_FISHERMAN';
    default:
      console.warn("Unknown value of prn", prn);
      return null;
  }
};
