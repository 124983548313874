import BigNumber from 'bignumber.js';

const ZERO = new BigNumber(0);

/**
 * Manually transpiled from PayHelper.java as of commit 3c478e8
 */
export default class PayHelper {

  constructor(amount) {
    this.remainingAmount = amount;
    this.modifiedAmortizations = [];
  };

  payPart(partField, amount, amortizations) {
    if (this.remainingAmount.isZero()) return ZERO;

    let paid = ZERO;

    for (let amortization of amortizations) {
      if (this.remainingAmount.isZero()) return paid;

      // retrieves the part balance
      let partBalance = amortization[partField];
      if (partBalance.isZero()) continue;

      // pays the part fully (if we have sufficient funds) or partially (if amount > partBalance)
      let toPay = BigNumber.min(partBalance, amount, this.remainingAmount);
      let paidAmount = this._pay(toPay);
      if (!paidAmount.isZero()) amortization[`${partField}_touched`] = true;

      // sets the remaining part balance
      amortization[partField] = partBalance.minus(paidAmount);
      amortization[`${partField}_paid`] = amortization[`${partField}_paid`].plus(paidAmount);
      if (!this.modifiedAmortizations.includes(amortization)) this.modifiedAmortizations.push(amortization);
      amortization.paid = amortization.paid ? amortization.paid.plus(paidAmount) : paidAmount;

      paid = paid.add(paidAmount);
    }

    return paid;
  }

  // poor man's private method
  _pay(toPay) {
    if (toPay.isZero()) return ZERO;

    if (!this.remainingAmount.isZero()) {
      if (toPay.lessThan(this.remainingAmount)) {
        this.remainingAmount = this.remainingAmount.minus(toPay);
        return toPay;
      } else {
        let paidAmount = this.remainingAmount;
        this.remainingAmount = ZERO;
        return paidAmount;
      }
  }
  // remaining amount is zero
  return ZERO;
}

}