import module from "module";
import {confirmationMessage, payorTypeForPRN} from "../sss/sss.utils";

const templateUrl = require('./sss-online-employer-payment.template.html');
module.component('sssOnlineEmployerPayment', {
  templateUrl,
  bindings: {
    'payorKind': '=',
    'onSaveAction': '<',
    'cancelAction': '<',
  },
  controller: function (http, command, popup, confirmation, $filter, postingDateService) {
    this.$onInit = () => {
      postingDateService.getCurrentUserBranchPostingDate().then(date => this.postingDate = date);

      this.fetchPayment = () => http.get(`/sss/online-payment/payor-kind/EMPLOYER/prn/${this.paymentReferenceNumber}`)
        .success(payment => {
          this.payment = payment;
        })
        .error(err => {
          popup({text: err.errorMessage, header: 'Error'});
        });

      this.save = async () => {
        const message = confirmationMessage({
          amount: this.payment.amount,
          startingPeriod: this.payment.applicableMonth,
          endingPeriod: this.payment.applicableMonth,
          payorType: payorTypeForPRN(this.payment.paymentReferenceNumber),
          moneyFormatter: $filter('php'),
          calculateFlexiFund:  payorTypeForPRN(this.payment.paymentReferenceNumber) === 'OVERSEAS_WORKER'
        });

        const proceed = await confirmation(message, true);
        if (proceed) {
          command.execute('PayOnlineSSS', {
            "ssNumber": this.payment.ssNumber,
            "paymentReferenceNumber": this.payment.paymentReferenceNumber,
            "amount": this.payment.amount,
            "startingPeriod": this.payment.applicableMonth,
            "endingPeriod": this.payment.applicableMonth,
            "paymentDate": this.postingDate.format('YYYY-MM-DDTHH:mm:ss')
          }).success(response => {
            this.onSaveAction({
              response,
            });
          });
        }
      }
    }
  }
});
