import _ from 'lodash';
import $ from 'jquery';

import module from 'module';

import templateUrl from './customer-layaways-view.template.html';
import './customer-layaways-view.style.less';

class CustomerLayawaysViewComponent {
  constructor($route, $routeParams, $location, $scope, http, customerCache, confirmation, notification,
              userCache, nav, layawayStatusMapper, $filter, branchService, authentication, branchWorkingDayCache,
              confirmationTemplate, command, queryParamsRemover, customerService, pawnItemCache, popup, layawayProductsCache) {
    this.$route = $route;
    this.$routeParams = $routeParams;
    this.$location = $location;
    this.$scope = $scope;
    this.http = http;
    this.customerCache = customerCache;
    this.confirmation = confirmation;
    this.notification = notification;
    this.userCache = userCache;
    this.nav = nav;
    this.layawayStatusMapper = layawayStatusMapper;
    this.$filter = $filter;
    this.branchService = branchService;
    this.authentication = authentication;
    this.branchWorkingDayCache = branchWorkingDayCache;
    this.confirmationTemplate = confirmationTemplate;
    this.command = command;
    this.queryParamsRemover = queryParamsRemover;
    this.customerService = customerService;
    this.pawnItemCache = pawnItemCache;
    this.popup = popup;
    this.layawayProductsCache = layawayProductsCache;
  }

  async $onInit() {
    this.$scope.permission = this.authentication.permissions;

    this.terminationStatuses = ['CLOSED', 'PAST_DUE_NON_PERFORMING', 'CANCELED'];
    this.hideClosed = this.$routeParams.hideClosed === "true";
    this.visibleLayaways = [];

    this.workingDay = null;
    this.contextBranchId = this.authentication.context.branchId;
    this.layaways = [];

    this.customerId = this.$route.current.params['customerId'];
    const layawayId = this.$route.current.params['layawayId'];

    this.queryParamsRemover.removeQueryOnRedirect(this.$scope, ['hideClosed']);

    this.toggleLayaways = () => {
      if (this.hideClosed) {
        this.visibleLayaways = this.layaways.filter(l => l.status !== 'CLOSED');
      } else {
        this.visibleLayaways = this.layaways;
      }

      if (this.selectedLayaway) {
        this.$location
          .path(`/customer/${this.customerId}/layaways/${this.selectedLayaway.id}`)
          .search('hideClosed', this.hideClosed.toString());
      }
    };

    const [customerLayaways, layawayTypes, branches, users, workingDay, profile] = await Promise.all([
      this.customerCache.layaways(this.customerId).toPromise(),
      this.layawayProductsCache.toPromise(),
      this.branchService.toPromise(),
      this.userCache.toPromise(),
      this.branchWorkingDayCache.withParam(this.contextBranchId).toPromise(),
      this.customerCache.profile(this.customerId).toPromise()
    ]);

    await this.customerService.redirectWhenProfileIsInvalid(profile);

    this.workingDay = workingDay;
    const allLayaways = customerLayaways.map(l =>
      ({
        ...l,
        layawayType: _.find(layawayTypes, {id: l.typeId}),
        branch: _.find(branches, {id: l.branchId}),
        user: _.find(users, {id: l.userId}),
        appraisalUser: _.find(users, {id: l.appraisalUserId})
      })
    );

    const availableLayaways = this.getLayawaysAvailableToTheUser(allLayaways);

    if (!layawayId && availableLayaways.length > 0) {
      this.gotoFirstCustomerLayaway(availableLayaways);
    }

    if (layawayId) {
      const selectedLayaway = _.find(availableLayaways, (a) => String(a.id) === layawayId);
      if (selectedLayaway) {
        this.selectedLayaway = selectedLayaway;
      } else {
        this.gotoCustomerLayaways();
      }
    }

    this.layaways = availableLayaways.map(l => {
      if (l.parentPawnId) {
        const parentPawn = _.find(availableLayaways, {id: l.parentPawnId});
        l.parentPawnNumber = parentPawn ? parentPawn.productNumber : 'N/A';
      }
      // Find root pawn (only if root pawn is owned by the same user)
      if (l.rootPawnId) {
        const rootPawn = _.find(availableLayaways, {id: l.rootPawnId});
        l.rootPawnNumber = rootPawn ? rootPawn.productNumber : 'N/A';
      }

      return l;
    });

    if (this.hideClosed) {
      this.visibleLayaways = this.layaways.filter(l => l.status !== 'CLOSED');

      if (this.selectedLayaway && this.selectedLayaway.status === 'CLOSED' && this.selectLayaway && this.visibleLayaways[0]) {
        this.selectLayaway(this.visibleLayaways[0]);
      }
    } else {
      this.visibleLayaways = this.layaways;
    }

    this.workingDay = workingDay;
  }

  gotoCustomerLayaways() {
    this.$location.path(`/customer/${this.customerId}/layaways`);
  }

  gotoFirstCustomerLayaway(layaways) {
    this.$location.path(`/customer/${this.customerId}/layaways/${layaways[0].id}`);
  }

  getLayawaysAvailableToTheUser(allLayaways) {
    const branchIds = this.authentication.context.branchIds;
    return _.filter(allLayaways, p => _.includes(branchIds, p.branchId));
  }

  isLayawayTerminated(layaway) {
    return layaway && _.includes(this.terminationStatuses, layaway.status);
  };

  mapDefectLevel(defectLevel) {
    if (defectLevel === 'NONE') return 'Good';
    return $filter('prettyEnum')(defectLevel);
  }

  transactionClicked(transaction, $event) {
    $event.stopPropagation();
    this.selectedTransaction = transaction.id;
  }

  hideInlinePanel() {
    this.selectedTransaction = null;
  }

  selectLayaway(l) {
    this.selectedLayaway = l;
    this.$location.path(`/customer/${this.customerId}/layaways/${l.id}`);
  }

  gotoPayment() {
    this.$location.path(`/customer/${this.customerId}/layaways/${this.selectedLayaway.id}/payment`);
  }

  gotoPrintAgreementForm() {
    this.$location.path(`/customer/${this.customerId}/layaways/${this.selectedLayaway.id}/agreement-form`);
  }

  gotoLayawayPaymentsReport() {
    this.$location.path(`/customer/${this.customerId}/layaways/${this.selectedLayaway.id}/payments-report`)
  }

  gotoLayawayReceipt() {
    this.$location.path(`/customer/${this.customerId}/layaways/${this.selectedLayaway.id}/receipt`)
  }

  gotoCancel() {
    this.$location.path(`/customer/${this.customerId}/layaways/${this.selectedLayaway.id}/cancel`);
  }

  gotoLayaway(layawayId) {
    // Check if pawn is hidden
    if (this.isLayawayTerminated(layawayId)) {
      this.$location.path(`/customer/${this.customerId}/layaways/${layawayId}`).search({displayClosed: 1});
      return;
    }

    // scroll to pawn
    const layawayRow = $(`#pawn_${layawayId}`);
    if (!layawayRow) {
      console.error('No pawn tag defined for layawayId: ', layawayId);
    } else {
      if (!this.isScrolledIntoView(layawayRow)) {
        const pawnYCoordinate = layawayRow.offset().top;
        $("html, body").animate({scrollTop: pawnYCoordinate - 20}, "fast");
      }
    }

    // change layaway context
    this.$location.path(`/customer/${this.customerId}/layaways/${layawayId}`);
  };

  // https://stackoverflow.com/questions/487073/check-if-element-is-visible-after-scrolling
  isScrolledIntoView(el) {
    const element = el[0];
    const elemTop = element.getBoundingClientRect().top;
    const elemBottom = element.getBoundingClientRect().bottom;

    return (elemTop >= 0) && (elemBottom <= window.innerHeight);
  }

  isSelectedLayawayRegisteredBranch(l) {
    return l && this.contextBranchId === l.branchId;
  }
}

module.component('customerLayawaysView', {
  templateUrl: templateUrl,
  controller: CustomerLayawaysViewComponent
});
