import module from 'module';
import _ from 'lodash';

const templateUrl = require('./issue-checkbook.template.html');
module.component('issueCheckbook', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, customerCache, systemPropertyCache, productDefinitionService,
                        requestFormatter, confirmation, command, depositoryAccountCache) {
    const that = this;

    const customerId = $route.current.params['customerId'];
    const accountId = $route.current.params['accountId'];

    that.account = {};
    that.depositoryAccount = undefined;
    that.checkbook = {productId: accountId};

    const defaultCheckbookSizes = {
      'INDIVIDUAL': 0,
      'COMMERCIAL': 0
    };

    that.depositoryAccountSelectConfig = {
      placeholder: 'Select depository account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };

    that.fillBeginningWithZerosUpTo = (noOfChars, word) => {
      if (word.length >= noOfChars) {
        return word.slice(word.length - noOfChars);
      } else {
        return '0'.repeat(noOfChars - word.length) + word;
      }
    };

    that.onFirstCheckNumberChange = () => {
      that.checkbook.startNumber = that.fillBeginningWithZerosUpTo(10, that.checkbook.startNumber);
    };

    const s1 = systemPropertyCache.toObservable().subscribe(properties => {
      // Set default individual checkbook size
      const ind = _.find(properties, {code: 'CHECK_INDIVIDUAL_CHECKBOOK_SIZE'});
      defaultCheckbookSizes['INDIVIDUAL'] = ind && ind.value > 0 ? Number(ind.value) : 0;
      // Set default commercial checkbook size
      const com = _.find(properties, {code: 'CHECK_COMMERCIAL_CHECKBOOK_SIZE'});
      defaultCheckbookSizes['COMMERCIAL'] = com && com.value > 0 ? Number(com.value) : 0;
    });

    const s2 = depositoryAccountCache.toObservable().subscribe(data => {
      that.depositoryAccounts = [];
      if (data && data.length > 0) {
        that.depositoryAccounts = _.filter(data, d => d.clearing)
      }
    });

    const s3 = customerCache.depositAccounts(customerId).toObservable()
      .combineLatest(productDefinitionService.toObservable(), (accounts, types) => {
        return accounts.map(account => {
          const productType = _.find(types, {id: account.definitionId});
          return Object.assign(account, {productName: productType ? productType.productName : '-'});
        })
      }).subscribe(accounts => that.account = _.find(accounts, (a) => Number(a.id) === Number(accountId)));

    that.onDepositoryAccountChange = () => {
      // Reset start check number
      that.checkbook.startNumber = null;
      // If depository account is selected -> fetch valid start number
      const depositoryAccountId = that.checkbook.depositoryAccountId;
      if (depositoryAccountId && depositoryAccountId > 0) {
        const acc = _.find(that.depositoryAccounts, a => Number(a.id) === Number(depositoryAccountId));
        if (acc && acc.brstn && acc.clearing) {
          that.depositoryAccount = acc;
          const criteria = requestFormatter.nullifyParams({brstn: acc.brstn});
          const queryParams = requestFormatter.convertToQueryParams(criteria);
          http.get(`/products/accounts/checkbooks/highest-check-sequence${queryParams}`, {nxLoaderSkip: true}).success((result) => {
            if (result !== null && Number(result) >= 0) {
              that.checkbook.startNumber = String(Number(result) + 1);
              that.onFirstCheckNumberChange();
            }
          })
        }
      }
    };

    that.issue = () => {
      confirmation(`Do you want to issue new checkbook ${that.checkbook.number}?`, () => {
        command.execute('CreateCheckbook', that.checkbook, {nxLoaderText: 'Issuing checkbook'})
          .success(() => {
            customerCache.depositAccounts(customerId).refetch();
            that.redirectBack();
          })
      });
    };

    that.onTypeChange = (type) => {
      switch (type) {
        case 'INDIVIDUAL':
        case 'COMMERCIAL':
          that.checkbook.checksCount = defaultCheckbookSizes[type];
          break;
        default:
          that.checkbook.checksCount = 0;
          break;
      }
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${accountId}`);

    that.$onDestroy = () => {
      s1.unsubscribe();
      s2.unsubscribe();
      s3.unsubscribe();
    }
  }
});
