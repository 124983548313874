import module from 'module';
import './close-counters-health-check.component.less';
import templateUrl from './close-counters-health-check.template.html';

class CloseCountersHealthCheck {}

module.component('closeCountersHealthCheck', {
  templateUrl,
  bindings: {
    checks: '<',
    hideOk: '<'
  },
  controller: CloseCountersHealthCheck
});