import module from 'module';

const currentAppVersion = __APP_VERSION;
const currentGitCommit = __GIT_COMMIT;

class AppVersionService {
  constructor($http, http) {
    this.$http = $http;
    this.http = http;
  }

  currentFrontend() {
    return {
      appVersion: currentAppVersion,
      gitCommit: currentGitCommit
    };
  }

  frontend() {
    const timestamp = new Date().getTime();
    return this.$http.get(`version.json?_ts=${timestamp}`)
        .then(response => response.data);
  }

  backend() {
    return this.http.get('/system/status').toPromise();
  }

  isAppOutdated() {
    return this.frontend().then((versionObject) => {
      const latestAppVersion = versionObject.appVersion;
      const latestGitCommit = versionObject.gitCommit;

      const isOutdated = (currentAppVersion !== latestAppVersion || currentGitCommit !== latestGitCommit)
      if (isOutdated)
        console.log(`UI version is outdated.\nCurrent version: ${currentAppVersion}/${currentGitCommit}\nLatest version:  ${latestAppVersion}/${latestGitCommit}`)
      return isOutdated;
    });
  }
}

module.service('appVersionService', AppVersionService);