import module from 'module';
import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery';

import templateUrl from './users-details.template.html';

module.component('usersDetails', {
  templateUrl,
  controller: function (userCache, http, branchService, $route, $timeout, breadcrumbs,
                        rolesCache, confirmation, $location, $filter, authentication, notification, $routeParams,
                        config, postingDateService, popup) {

    let that = this;

    that.user = {};
    that.roles = null;
    that.branches = null;
    that.branchId = $route.current.params.branchId;
    that.userId = parseInt($route.current.params.userId);
    that.selectedStatus = 'all';
    const parent = `/admin/organization/users/${that.branchId}`;
    that.permission = authentication.permissions;
    that.canEditUsers = that.permission['MNG_USER_WRITE'];
    that.editingOwnData = that.userId === authentication.context.id;
    that.transactionLimitRequired = false;

    that.showExpirationDateError = false;
    that.currentDate;
    that.minDate;


    that.$onInit = async () => {
      const [branches, shifts] = await Promise.all([branchService.toPromise(), http.get("/management/shifts").toPromise()]);
      that.branches = branches;
      that.shifts = shifts;
      const userBranch = _.find(branches, {id: authentication.context.branchId});
      that.currentDate = postingDateService.getPostingDate(userBranch);
      that.branch = _.find(branches, {id: parseInt(that.branchId)});
      breadcrumbs.options = {...breadcrumbs.options, 'users-list-label': `Users > ${that.branch.name}`};
      that.initializeForm();
    };

    let subscriptions = [];

    if (!that.canEditUsers && !that.editingOwnData) {
      $location.path('/lookup-customer');
    }

    /**
     * Removes technical roles from given list
     */
    const filterRoles = (roles) => {
      return _.filter(roles, {technical: false})
    };

    that.initExistingUser = () => {
      // For existing users roles should be composed
      let s = userCache.toObservable().combineLatest(rolesCache.toObservable(),  (users, roles) => {
        that.roles = filterRoles(roles);
        let user = _.find(users, d => d.id == that.userId);
        user.fullName = `${user.firstName} ${user.middleName} ${user.lastName}`;
        if (user.roles && user.roles.length > 0) {
          user.roleId = _.head(user.roles).id;
        }
        that.user = user;

        if (that.user.expirationDate) {
          that.showExpirationDateError = that.currentDate.isAfter(moment(that.user.expirationDate));
        }

        that.minDate = that.currentDate.toDate();
      }).subscribe(users => {
        that.onRoleUpdate();
        _.set(breadcrumbs, 'options["users-details-label"]', that.user.fullName);
      });
      subscriptions.push(s);
    };

    that.initNewUser = () => {
      _.set(breadcrumbs, 'options["users-details-label"]', 'CREATE');
      that.user = {
        branchId: that.branchId,
        branchIds: [parseInt(that.branchId)],
        enabled: true,
        middleName: '',
        roles: []
      };

      if (!that.user.expirationDate) {
        that.user.expirationDate = $filter('nxDate')(that.currentDate.add(config.expirationTime, 'days').toDate());
      }

      that.minDate = that.currentDate.toDate();

      let rolesCacheSub = rolesCache.toObservable().subscribe(roles => {
        that.roles = filterRoles(roles);
        that.onRoleUpdate();
      });
      subscriptions.push(rolesCacheSub);
    };

    that.initializeForm = () => {
      if (that.userId) {
        that.initExistingUser();
      } else {
        that.initNewUser();
      }

      // On init set form as submitted to highlight invalid fields
      that.userForm.$setSubmitted();
      that.passwordForm.$setSubmitted();
      if ($routeParams.showPasswordTab === 'true') {
        $("a[data-target='#tab-password']").click();
        notification.show("Information", "You have to change your password");
      }
    };

    that.onRoleUpdate = () => {
      if (that.user.roleId) {
        let role = _.find(that.roles, {id: that.user.roleId});
        that.transactionLimitRequired = role.transactionLimitRequired;
      } else {
        that.transactionLimitRequired = false;
        that.user.transactionLimit = null;
      }
    };

    that.changeExpirationDate = (value) => {
      if (value) { //Ng-model with min is doing validation for us
        that.showExpirationDateError = false;
      }
    };

    that.updatePassword = () => {
      http.put(`/management/users/${that.userId}/password`, {
        'newPassword': that.user.password,
        'oldPassword': that.oldPassword
      }).success(() => {
        notification.show("Success", "Password updated successfully");
        if (that.userId === authentication.context.id) {
          authentication.logout();
        }
        that.updateUser();
      }).error(err => {
        if(err && err.errorCode === 'REUSED_OLD_PASSWORD_ERROR') {
          notification.show("Error", "Cannot use old password.");
          return;
        }

        notification.show("Error", "Error while updating password. Check old password");
      });
    };

    that.cancelChanges = () => {
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => $location.path(parent));
    };

    that.save = () => {
      that.user.roles = [];

      if (that.user.roleId) {
        that.user.roles.push({id: that.user.roleId});
      }
      if (that.userId) {
        if (that.retypedPassword) {
          that.updatePassword();
        } else {
          that.updateUser();
        }
      } else {
        that.createUser();
      }
    };

    that.createUser = () => {
      http.post(`/management/users`, that.user).success(() => {
        userCache.refetch();
        notification.show("Success", "User created successfully");
        $location.path(parent);
      }).error(error =>
        popup({
          header: 'Error',
          text: error.errorMessage
        })
      );
    }

    that.updateUser = () => {
      that.user.password = undefined;
      http.put(`/management/users/${that.userId}`, that.user).success(() => {
        userCache.refetch();
        notification.show("Success", "User updated successfully");
        $location.path(parent);
      }).error(error =>
        popup({
          header: 'Error',
          text: error.errorMessage
        })
      );
    };

    that.changeBranch = () => {
      $location.path(`${parent}/user/${that.userId}/move-to-branch`);
    };

    that.$onDestroy = () => {
      _(subscriptions).forEach(s => s.unsubscribe());
    }
  }
});
