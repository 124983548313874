import module from 'module';
import templateUrl from './update-new-branch-posting-date.template.html';

class UpdateNewBranchPostingDateComponent {
  constructor(command, confirmation, $route, workingDaysCache, branchService, http, $filter) {
    this.command = command;
    this.$route = $route;
    this.http = http;
    this.$filter = $filter;
    this.branchService = branchService;
    this.workingDaysCache = workingDaysCache;
  }
  async $onInit() {
    this.input = {};
    this.branches = await this.http.get(`/management/branches?noTransactions`).toPromise();
  }

  setCurrentPostingDate(branchId) {
    this.currentPostingDate = this.branches.find(b => b.id === branchId).postingDate;
    this.input.newPostingDate = this.currentPostingDate;
  }

  async execute() {
    this.input.newPostingDate = this.$filter('apiDate')(this.input.newPostingDate);
    const response = await this.command.execute('UpdateNewBranchPostingDate', this.input).toPromise();

    if (!response.approvalRequired) {
      this.branchService.refetch();
      this.workingDaysCache.refetch();
      this.$route.reload()
    }
  }
}

module.component('updateNewBranchPostingDate', {
  templateUrl,
  controller: UpdateNewBranchPostingDateComponent
});
