import module from 'module';

const templateUrl = require('./cash-placement.template.html');
module.component('customerTermDepositCashPlacement', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, customerCache, productDefinitionService, confirmation, loader,
                        modalPrintPreviewService, customerDepositService, command, casaProductPrintService) {
    let that = this;
    that.deposit = {};

    let customerId = $route.current.params['customerId'];
    let depositId = ~~$route.current.params['depositId'];

    customerDepositService.getDeposit(customerId, depositId, deposit => that.termDeposit = deposit);

    that.amountSelected = (amount) => {
      that.selectedAmount = amount;
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${depositId}`);

    that.deposit = () => {
      confirmation(`Do you want to place ${$filter('php')(that.selectedAmount)}?`, () => {
        command.execute('DepositCashPlacement', {
            "productId": depositId,
            "amount": that.selectedAmount,
            "entryType": "CREDIT"
          }, {nxLoaderText: 'Performing cash placement'}
        ).success((res) => {
          casaProductPrintService.printFor('TERM_DEPOSIT', customerId, depositId, res.output.id).finally(() => {
            customerCache.termDeposits(customerId).refetch();
            customerDepositService.checkIfCertificated(that.termDeposit, (activated) => {
              if (activated) {
                modalPrintPreviewService.show('CERTIFICATE_OF_TERM_DEPOSIT', {depositId: depositId}, () => that.redirectBack());
              } else {
                that.redirectBack();
              }
            })
          });
        });
      });
    }
  }
});
