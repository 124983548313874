import module from 'module';
import {NgTableParams} from 'ng-table/ng-table';
import operationStatusToLabel from '../../../common/transaction-history/operationStatusToLabel';

import templateUrl from './customer-forex-transaction-history.template.html';
import './customer-forex-transaction-history.less';

class ForexTransactionHistory {

  constructor(postingDateService, http, reportService) {
    this.postingDateService = postingDateService;
    this.http = http;
    this.reportService = reportService;
  }

  async $onInit() {
    this.reportCode = 'CustomerForexTransactionHistoryReport';
    this.statusToLabel = operationStatusToLabel;
    this.filterParams = {
      customerId: this.customerId
    };
    this.filterConfig = {
      reportCode: this.reportCode,
      buttons: {
        filter: {
          isVisible: true,
          isDisabled: false,
          action: () => this.resetTransactions(),
          text: 'Filter'
        },
        download: {
          isVisible: true,
          isDisabled: false,
          action: () => this.downloadXls(),
          text: 'Download xls'
        }
      }
    };
  }

  downloadXls() {
    this.reportService.downloadXls({
      reportCode: this.reportCode,
      params: {
        ...this.filterParams
      }
    });
  }

  resetTransactions() {
    this.transactionHistoryConfig = new NgTableParams({
      count: 15,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: async params => {
        if (this.customerId && this.filterParams.date_range.from && this.filterParams.date_range.to) {
          const response = await this.http.post('/forex/transaction-history', {
            pageNo: params.page() - 1,
            pageSize: params.count(),
            dateFrom: this.filterParams.date_range.from,
            dateTo: this.filterParams.date_range.to,
            customerId: this.customerId,
            type: this.filterParams.actionTemplateType
          }).toPromise();

          this.transactionHistoryConfig.total(response.totalCount);
          return response.result;
        }
        return Promise.resolve([]);
      }
    });
  }
}

module.component('customerForexTransactionHistory', {
  templateUrl: templateUrl,
  transclude: true,
  bindings: {
    customerId: '<'
  },
  controller: ForexTransactionHistory
});
