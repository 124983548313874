import module from 'module';
import _ from 'lodash';

import templateUrl from './general-ledger.template.html';

class GeneralLedger {
  constructor(authentication, systemPropertyCache) {
    this.authentication = authentication;
    this.systemPropertyCache = systemPropertyCache;
  }

  async $onInit() {
    this.titles = {
      categories: 'Transaction Categories',
      mapping: 'Transaction Mapping'
    }

    this.title = this.titles.categories;

    this.changeTitle = title => {
      this.title = title;
    };

    this.permission = this.authentication.permissions;

    const properties = await this.systemPropertyCache.toPromise();
    const isLedgerSupported = _.find(properties, {code: 'LEDGER_SUPPORT'});
    this.isLedgerSupported = !!(isLedgerSupported && isLedgerSupported.value);
  }
}

module.component('generalLedger', {
  templateUrl,
  controller : GeneralLedger
});
