import module from 'module';
import _ from 'lodash';

const templateUrl = require('./deposit-account-section.template.html');

module.component('depositAccountSection', {
  templateUrl,
  bindings: {
    sectionType: '<',
    generalForm: '=',
    interestsForm: '=',
    chargesForm: '=',
    account: '<'
  },
  controller: function ($scope, $filter, casaTypesCache) {

    const that = this;

    that.isNewAccount = !that.account || !that.account.id;

    that.dormancyChargeMax = 50;
    that.daysInYear = [100, 357, 360, 364, 365];
    that.casaTypes = [];
    that.pdicCasaTypes = [];

    $scope.$watch('$ctrl.account', () => {
      if (that.account) {
        //set initial value for this flag
        if (that.account.passbookConfig && that.account.passbookConfig !== 'PASSBOOK_NOT_SUPPORTED') {
          that.passbookSupported = true;
          that.passbookConfig = that.account.passbookConfig;
        } else {
          that.passbookSupported = false;
        }
      }
    });

    that.cycleTypes = [{
      label: 'Monthly',
      value: 'MONTH'
    }, {
      label: 'Quarterly',
      value: 'QUARTER'
    }];

    that.purposes = [{
      label: 'General',
      value: 'GENERAL'
    }, {
      label: 'Contractual savings / CBU',
      value: 'CONTRACTUAL_SAVINGS_CBU'
    }, {
      label: 'Contractual savings / PF',
      value: 'CONTRACTUAL_SAVINGS_PF'
    }, {
      label: 'Contractual savings / TP',
      value: 'CONTRACTUAL_SAVINGS_TP'
    }, {
      label: 'Insurance',
      value: 'INSURANCE'
    }];

    that.subtypes = [{
      label: 'Savings',
      value: 'SAVINGS'
    }, {
      label: 'Checking',
      value: 'CHECKING'
    }];

    // remove PASSBOOK_NOT_SUPPORTED since we have separate flag for this (that.passbookSupported)
    that.passbookConfigOptions = [{
      label: 'Yes',
      value: 'PASSBOOK_NOT_REQUIRED'
    }, {
      label: 'No',
      value: 'PASSBOOK_REQUIRED'
    }, {
      label: 'With override',
      value: 'WITH_OVERRIDE'
    }];

    that.spoAndDaudChargeOptions = [{
      label: 'Fixed amount',
      value: false
    }, {
      label: 'Unfunded service charge and penalty per check fee',
      value: true
    }];

    that.typeConflictStrategyOptions = [{
      label: 'Show warning',
      value: 'SHOW_WARNING'
    }, {
      label: 'No action',
      value: "NO_ACTION"
    }];

    casaTypesCache.toObservable()
      .first()
      .subscribe(casaTypes => {
        that.casaTypes = casaTypes;
        that.pdicCasaTypes = _.filter(that.casaTypes, {'regulatorType': 'PDIC'});
      });

    that.passbookConfigChange = () => {
      if (!that.passbookSupported) {
        that.account.passbookConfig = 'PASSBOOK_NOT_SUPPORTED';
      } else {
        that.account.passbookConfig = that.passbookConfig;
      }
    }

    that.changeAccrued = () => {
      if (that.account.accrued) {
        if (!that.account.accrualScheduler) {
          // interest is accrued, but scheduler is not set
          that.account.accrualScheduler = {
            cycleType: 'MONTH',
            enabled: true,
            executionInterval: 1
          };
        }
      } else {
        that.account.accrualScheduler = null;
      }
    };
  }
});
