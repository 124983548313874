import module from 'module';

const templateUrl = require('./new-loan-list-table.template.html');
module.component('customerLoanCreateListTable', {
  templateUrl: templateUrl,
  bindings: {
    loanType: '=',
    openAction: '<',
    customerId: '<',
  },
  controller: function ($scope, $route, $location, http, customerCache, groupCustomerCache, loanProductsCache) {
    let that = this;
    that.profile = {};
    that.loanProducts = [];

    that.$onInit = () => {
      const showGroupLoans = this.loanType === 'group';
      if (showGroupLoans) {
        groupCustomerCache.profile(that.customerId).toObservable().subscribe(profile => that.profile = profile);
      } else {
        customerCache.profile(that.customerId).toObservable().subscribe(profile => that.profile = profile);
      }

      loanProductsCache.toObservable().subscribe(loanProducts => that.loanProducts = loanProducts);
    };

    that.open = (loanProduct) => {
      that.openAction({
        product: loanProduct
      });
    };
  }
});