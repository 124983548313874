import module from 'module';

const templateUrl = require('./new-pawn-header.template.html');

class CustomerPawnCreateItemsHeader {
  constructor(pawnTagCache, $scope, pawnTagService) {
    this.pawnTagCache = pawnTagCache;
    this.$scope = $scope;
    this.pawnTagService = pawnTagService;
  }

  async $onInit() {
    this.tags = await this.pawnTagCache.toPromise();
    const permittedTagIds = await this.pawnTagService.getPermittedTagIds();

    this.$scope.$watch('$ctrl.branch', () => {
      if (!this.branch || !this.pawnProduct) {
        return;
      }

      this.taggedInterestRatesView = (this.pawnProduct.taggedInterestRates || [])
        .filter(t => permittedTagIds.includes(t.tagId));
    });

    this.tagNameById = this.tags.reduce((map, tag) => {
      map[tag.id] = tag.name;
      return map;
    }, {});
  }
}

module.component('customerPawnCreateItemsHeader', {
  templateUrl: templateUrl,
  bindings: {
    pawnProduct: '<',
    branch: '<'
  },
  controller: CustomerPawnCreateItemsHeader
});
