import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('glTagsDescriptionCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/ledger/tags/params/descriptions'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.ledgerTag,
    cacheName: 'glTagsDescription'
  })
);