import {pluck} from 'rxjs/operators';

/**
 * Instantiate this class to make use of StatusService.
 *
 * Example:
 * new StatusBasedService({
 *   property: 'informationMessage',
 *   statusService: statusService,
 *   optional: true
 * })
 */
export default class StatusBasedService {
  /**
   * @param property - property of status bundle, which contains object that we want to fetch with instance of this service.
   * @param statusService - reference to StatusService instance
   */
  constructor({property, statusService}) {
    this.property = property;
    this.statusService = statusService;
  }

  /**
   * @returns Observable with given part of status bundle or default value if defined
   */
  toObservable() {
    return this.statusService.getStatusObs().pipe(
      pluck(this.property)
    );
  }

  toPromise() {
    return this.toObservable()
      .first()
      .toPromise();
  }

  /**
   * Refetch status bundle from server
   */
  refresh() {
    this.statusService.refresh();
  }
}