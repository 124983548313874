import module from 'module';
import ParameterizedLocalCache from 'shared/util/parameterized-local-cache';

module.factory('productItemsLastSnapshotCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (params) => http.get(`/products/pawns/${params}/items/last-snapshot`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.productItemsLastSnapshot,
    cacheName: 'productItemsLastSnapshotCache'
  })
);
