import module from 'module';
import templateUrl from './sql-editor.template.html';
import './sql-editor.component.less';

class SqlEditorController {
  constructor($element) {
    this.$element = $element;
    this.isInitialized = null;
  }

  async initializeCodeMirror() {
    const mime = this.mime || 'text/x-pgsql';
    
    try {
      const codeMirror = (await import(/* webpackChunkName: "code-mirror" */ './code-mirror')).default;
      
      this.editor = codeMirror.fromTextArea(this.$element.find('textarea')[0], {
        mode: mime,
        indentWithTabs: true,
        smartIndent: true,
        lineNumbers: true,
        matchBrackets : true,
        autofocus: true,
        extraKeys: {"Ctrl-Space": "autocomplete"},
        // Hints for tables
        hintOptions: {
          tables: this.hints
        }
      });
      
      this.editor.on('change', change => {
        this.onQueryUpdate({ sql: change.getValue() });
      });
      
      this.onQueryUpdate({ sql: this.editor.getValue() });
      this.isInitialized = true;
    } catch(ex) {
      this.isInitialized = false;
    }
  }

  $onChanges(changes) {
    if (changes.hints && changes.hints.currentValue) {
      this.hints = changes.hints.currentValue;

      if (!this.isInitialized) {
        this.initializeCodeMirror();
      }
    }
    
    if (changes.query && changes.query.currentValue && this.isInitialized) {
      this.query = changes.query.currentValue;
      this.editor.setValue(this.query);
    }
  }
}

module.component('sqlEditor', {
  bindings: {
    mime: '@',
    hints: '<',
    query: '<',
    onQueryUpdate: '<'
  },
  controller: SqlEditorController,
  templateUrl
});
