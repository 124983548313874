import module from 'module';
import _ from 'lodash';

const templateUrl = require('./deposit-account-details.template.html');

module.component('depositAccountDetails', {
  templateUrl,
  controller: function (depositAccountService, $route, $location, breadcrumbs, notification, confirmation, command) {

    const that = this;

    that.sectionType = 'general';
    that.currentProductId = $route.current.params.productId;
    that.depositAccount = null;

    depositAccountService.toObservable().subscribe(data => {
      if (!data) data = [];
      for (let i = 0; i < data.length; i++) {
        if (Number(data[i].id) === Number(that.currentProductId)) {
          that.depositAccount = data[i];
          break;
        }
      }

      _.set(breadcrumbs, 'options', {
        'deposit-account-label': that.depositAccount ? that.depositAccount.productDefinition.productName : 'Create New',
      });

      if (!that.depositAccount) that.depositAccount = {
        purpose: 'GENERAL',
        dormant: true,
        withholdingTax: 2000,
        applyInterestOnClose: true,
        accrued: true,
        unfundedServiceCharge: 1000.00,
        unfundedServiceChargeAlias: 'SCDAIF',
        fixedPenaltyPerCheckCharge: 200.00,
        fixedPenaltyPerCheckChargeAlias: 'PENLTY',
        variablePenaltyPerCheckCharge: 40000.00,
        variablePenaltyPerCheckChargeAlias: 'PENLTY',
        presentingBankCharge: 1000.00,
        presentingBankChargeAlias: 'RETFEE',
        passbookConfig: 'PASSBOOK_NOT_SUPPORTED',
        spoChargeDelegate: true,
        spoCharge: 0.0,
        spoChargeAlias: 'SCDAIF',
        daudChargeDelegate: true,
        daudCharge: 0.0,
        daudChargeAlias: 'SCDAUD',
        autoClose: false,
        productDefinition: {
          productGroup: 'ACCOUNT',
          expired: false,
          daysInYear: 365,
          availableForSale: false
        },
        accrualScheduler: {
          cycleType: 'MONTH',
          enabled: true,
          executionInterval: 1
        },
        dormantConfig: {
          chargeAmount: 50,
          activationFee: 0,
          productStatus: 'INACTIVE',
          term: 1825,
          balanceThreshold: 0
        },
        interestScheduler: {
          cycleType: 'MONTH',
          enabled: true,
          executionInterval: 1
        },
        maintainingScheduler: {
          cycleType: 'MONTH',
          enabled: true,
          executionInterval: 1
        },
        dormancyScheduler: {
          cycleType: 'MONTH',
          enabled: true,
          executionInterval: 1
        },
        automaticTransferAgreement: {
          enabled: false,
          maxRelations: 1,
          allowExternalRelations: false
        },
        interestBoards: [{
          productDefinitionId: null,
          rateType: 'BASIC',
          defaultRate: 0,
          cells: [{
            rate: 0,
            minBalance: 0,
            maxBalance: null,
            minTerm: 0,
            maxTerm: null
          }]
        }]
      };
    });

    const createCommandInput = (type) => {
      // Copy properties with 1:1 mapping
      const input = _.pick(type, [
        'id',
        'defaultPdicTypeId',
        'purpose',
        'accountSubtype',
        'autoClose',
        'passbookConfig',
        'printPassbookAfterValidationSlip',
        'taxExempted',
        'accrued',
        'applyInterestOnClose',
        'interestMinimalBalance',
        'withholdingTax',
        'maintainingBalance',
        'maintainingCharge',
        'unfundedServiceCharge',
        'unfundedServiceChargeAlias',
        'fixedPenaltyPerCheckCharge',
        'fixedPenaltyPerCheckChargeAlias',
        'variablePenaltyPerCheckCharge',
        'variablePenaltyPerCheckChargeAlias',
        'presentingBankCharge',
        'presentingBankChargeAlias',
        'spoChargeDelegate',
        'spoCharge',
        'spoChargeAlias',
        'daudChargeDelegate',
        'daudCharge',
        'daudChargeAlias',
        'typeConflictStrategy',
        'subtypeConflictStrategy',
        'automaticTransferAgreement',
        'interestBoards'
      ]);

      // Copy remaining properties
      return Object.assign(input, {
        expired: type.productDefinition.expired,
        productName: type.productDefinition.productName,
        productTypeCode: type.productDefinition.productTypeCode,
        daysInYear: type.productDefinition.daysInYear,
        description: type.productDefinition.description,
        availableForSale: type.productDefinition.availableForSale,
        dormancyChargeAmount: type.dormantConfig.chargeAmount,
        interestCycleType: type.interestScheduler.cycleType,
        interestExecutionInterval: type.interestScheduler.executionInterval,
        accrualCycleType: input.accrued ? type.accrualScheduler.cycleType : null,
        accrualExecutionInterval: input.accrued ? type.accrualScheduler.executionInterval : null
      });
    };

    that.saveDeposit = () => {
      const input = createCommandInput(that.depositAccount);
      if (that.depositAccount.id) {
        command.execute('UpdateAccountType', input)
          .success(() => {
            depositAccountService.refetch();
            that.glMappingComponent.save()
              .success(() => {
                notification.show("Success", "Deposit Account updated successfully");
                $location.path("/admin/deposit-accounts");
              });
          }, true)
          .approval(() => {
            that.glMappingComponent.save();
          });
      } else {
        command.execute('CreateAccountType', input)
          .success(function () {
            depositAccountService.refetch();
            $location.path("/admin/deposit-accounts");
          });
      }
    };

    that.cancelChanges = () => {
      const parent = '/admin/deposit-accounts';
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => $location.path(parent));
    };

    that.setGlMappingComponent = (instance) => {
      that.glMappingComponent = instance;
    };
  }
});
