import module from 'module';

const templateUrl = require('./pawn-item-origin-predicate.template.html');
module.component('pawnItemOriginPredicate', {
  templateUrl,
  bindings: {
    predicates: '=',
    type: '<'
  },

  controller: function ($scope) {
    const that = this;
    $scope.$watchCollection('$ctrl.predicates', () => {
      if (!that.predicates) {
        that.value = null;
      } else {
        that.value = that.predicates[that.type];
      }
    });
    that.valueChanged = () => {
      that.predicates[that.type] = that.value;
    };

    that.pawnItemOrigins = [
      {
        label: 'Pawnshop',
        value: 'PAWNSHOP'
      },
      {
        label: 'Others',
        value: 'OTHERS'
      },
    ];
  }
});
