import module from 'module';
import _ from 'lodash';
import $ from 'jquery';
import {NgTableParams} from 'ng-table/ng-table.js';

import './lookup-customer-modal.style.less';
import templateUrl from './lookup-customer-modal.template.html';

class LookupCustomerModalComponent {
  constructor(http, branchService, requestFormatter) {
    this.customer = null;
    this.resetPaging = false;

    this.searchParams = {
      customerNumber: null,
      customerName: null,
      birthDate: null
    };

    this.onModalApiReady = ({api,}) => {
      this.modalApi = api;
      this.openModal();
    };

    this.tableConfig = new NgTableParams({
      page: 1,
      count: 20
    }, {
      counts: [],
      paginationMinBlocks: 3,
      paginationMaxBlocks: 8,
      getData: async params => {
        this.nullifyParams(this.searchParams);
        if (this.isSearchDisabled()) {
          return;
        }

        if (this.tableConfig.data && this.resetPaging) {
          params.page(1);
        }
        this.resetPaging = false;
        this.customer = null;

        const queryParams = requestFormatter.convertToQueryParams({
          ...this.searchParams,
          pageNo: params.page() - 1,
          pageSize: params.count()
        });

        const [response, branches] = await Promise.all([
          http.get(`/customers/full-text-search/paged${queryParams}`, {
            nxLoaderText: 'Searching for customer...'
          }).toPromise(),
          branchService.toPromise()
        ]);

        for(const customer of response.result) {
          customer.branch = branches.find(b => b.id === customer.branchId);
        }

        params.total(response.totalCount);
        return response.result;
      }
    });
  }

  onParamChange() {
    this.resetPaging = true;
  }

  nullifyParams(searchParams) {
    _.each(searchParams, function (value, key) {
      if (value != null && $.trim(value) === '') {
        searchParams[key] = null;
      }
    });
  };

  search() {
    this.tableConfig.reload();
  }

  selectCustomer(customer) {
    this.customer = customer;
  }

  async openModal() {
    const {accepted} = await this.modalApi.show();
    if (accepted) {
      this.onSelected(this.customer);
    } else {
      this.onClosed();
    }
  }

  isSearchDisabled() {
    return !this.dataForm
      || this.dataForm.$invalid
      || (!this.searchParams.customerNumber && (!this.searchParams.customerName || !this.searchParams.birthDate));
  }
}

module.component('lookupCustomerModal', {
  templateUrl,
  bindings: {
    'onSelected': '<',
    'onClosed': '<',
  },
  controller: LookupCustomerModalComponent,
});
