import module from 'module';
import _ from "lodash";

const templateUrl = require('./official-receipt.template.html');
module.component('officialReceipt', {
  templateUrl: templateUrl,
  controller: function (config, actionCommand, glMappingsService, actionCategoryCache, $routeParams, dict, $location, userCounterService) {

    const that = this;
    const actionType = $routeParams.actionType;
    const actionCode = actionType.toUpperCase().replace(new RegExp('-', 'g'), '_');

    const titles = {
      OFFICIAL_RECEIPT_IN_CASH: 'Official receipt in cash:',
      OFFICIAL_RECEIPT_IN_CHECK: 'Official receipt in check:'
    };

    const descriptions = {
      OFFICIAL_RECEIPT_IN_CASH: 'Official receipt in cash',
      OFFICIAL_RECEIPT_IN_CHECK: 'Official receipt in check'
    };

    that.title = titles[actionCode];
    that.description = descriptions[actionCode];
    that.transaction = { units: [] };
    that.blockedUnits = [];
    that.cashReceiptThreshold = config.cashReceiptThreshold;
    that.remarksRequired = true;
    that.entryType = 'DEBIT';
    that.fixedAccountLabel = 'TELLER CASH ON HAND';

    that.bankSelectConfig = {
      placeholder: 'Select bank',
      searchField: ['description'],
      valueField: 'id',
      labelField: 'description',
      maxItems: 1
    };

    dict.onLoadingComplete(() => {
      that.banks = dict['BANK'];
    });

    const ledgerAccountsSub = glMappingsService.accounts.toObservable().subscribe(accounts => {
      that.accounts = accounts;
    });

    const categoriesSub = actionCategoryCache.toObservable().subscribe(categories => {
      that.categories = categories.filter((category) => category.actionType === actionCode);
    });

    that.isOfficialReceiptInCheck = () => {
      return actionCode === 'OFFICIAL_RECEIPT_IN_CHECK';
    };

    that.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    that.onCategoryChange = () => {
      const category = that.categories.find((category) => category.id === that.transaction.categoryId);

      let blockedUnit = that.accounts
        .filter((account) => account.fullCode === category.ledgerAccountFullCode)
        .map((account) => { return {
          accountCode: account.fullCode,
          amount: that.transaction.amount,
          entryType: 'CREDIT'
        }})[0];

      that.blockedUnits = blockedUnit ? [blockedUnit] : [];
    };

    that.isCategoryChosen = () => this.transaction.categoryId !== undefined;

    that.isLedgerAccountSpecified = () => that.blockedUnits.length > 0;

    that.save = () => {
      actionCommand.execute(actionCode, that.transaction);
      userCounterService.refresh();
      $location.path("/dashboard/miscellaneous-transactions");
    };

    that.$onDestroy = () => {
      ledgerAccountsSub.unsubscribe();
      categoriesSub.unsubscribe();
    };
  }
});
