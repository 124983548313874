import module from 'module';
import moment from 'moment';
import {distinctUntilChanged, pluck} from 'rxjs/operators';
import templateUrl from './exchange-course-list.template.html';
import './exchange-course-list.component.less';

class ExchangeCourseList {
  constructor(forexCache, authentication, statusService, notification) {
    this.branchId = authentication.context.branchId;
    this.notification = notification;
    this.statusService = statusService;
    this.lastSavedUpdate = null;
    
    this.getExchangeRate = (err, rates) => {
      this.exchangeRates = rates.exchangeRatesInBranches[this.branchId];
    };

    this.cache = forexCache.withParam(this.branchId);
    this.cache.subscribe(this.getExchangeRate);
  }

  checkExchangeDate(forexLastUpdateTime) {
    const statusLastUpdate = moment(forexLastUpdateTime);

    if (this.lastSavedUpdate) {
      this.notification.show('Update', 'Exchange rate has changed');
      this.cache.refetch();
    }

    this.lastSavedUpdate = statusLastUpdate;
  }

  $onInit() {
    this.subscription = this.statusService.getStatusObs()
      .pipe(
        pluck('forexLastUpdateTime'),
        distinctUntilChanged()
      )
      .subscribe(status => this.checkExchangeDate(status));
  }

  $onDestroy() {
    this.cache.unsubscribe(this.getExchangeRate);
    this.subscription.unsubscribe();
  }
}

module.component('exchangeCourseList', {
  templateUrl: templateUrl,
  controller: ExchangeCourseList
});
