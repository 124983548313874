import module from "module";
import angular from "angular";

const templateUrl = require('./batch-sss-pension-result-list.template.html');
module.component('batchSssPensionResultList', {
  templateUrl: templateUrl,
  bindings: {
    'fileId': '=',
    'onSubmit': '<'
  },
  controller: function (sssPensionService) {
    const that = this;

    const s1 = sssPensionService.getSummaries().subscribe(s => {
      that.summaries = s;
    });

    that.correct = (summary) => {
      that.fileId = angular.copy(summary.fileId);
      that.onSubmit(that.fileId);
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
    };
  }
});
