import module from 'module';

import templateUrl from './update-cif-branch.template.html';

class UpdateCifBranch {
  get file() {
    return this.innerFile;
  }

  set file(text) {
    this.innerFile = text;

    this.customersToBeUpdated = (text || '')
      .trim()
      .split('\n')
      .map(line => line.trim())
      .map(customerNumber => ({
        customerNumber,
      }));
  }

  constructor(http, $scope, $route, branchService, confirmationTemplate, command, popup, requestFormatter) {
    this.http = http;
    this.$scope = $scope;
    this.$route = $route;
    this.branchService = branchService;
    this.confirmationTemplate = confirmationTemplate;
    this.command = command;
    this.popup = popup;
    this.requestFormatter = requestFormatter;
  }

  async $onInit() {
    this.branches = await this.branchService.toPromise();

    this.cifSelectionModes = [
      {
        label: 'File',
        value: 'file'
      }, {
        label: 'Manual Input',
        value: 'manual-input'
      }
    ];

    this.sourceBranch = null;
    this.targetBranch = null;
    this.cifSelectionMode = null;
    this.customersToBeUpdated = [];
    this.searchCifNumber = "";
    this.innerFile = '';
  }

  onCifSelectionChanged() {
    this.customersToBeUpdated = [];
  }

  async onSourceBranchChanged(oldValue) {
    if (this.customersToBeUpdated.length > 0) {
      const confirmed = await this.confirmationTemplate({
        question: `Changing the source branch from ${oldValue.name} to ${this.sourceBranch.name} will clear the following selections. Do you wish to proceed?`,
        details: [{
          label: 'Cif numbers',
          description: this.customersToBeUpdated.map(c => c.customerNumber).toString()
        }]
      });

      if (confirmed) {
        this.customersToBeUpdated = [];
      } else {
        this.sourceBranch = oldValue;
      }
    }
  }

  async addCustomer() {
    if (!this.sourceBranch) {
      return;
    }

    if (this.isAlreadyAdded(this.searchCifNumber)) {
      this.popup({
        text: `Cif number ${this.searchCifNumber} is already included in the list.`
      });
      return;
    }

    const searchCriteria = {
      customerNumber: this.searchCifNumber,
      customerId: null,
      documentNumber: null,
      name: null,
      birthDate: null,
      productNumber: null,
      checkNumber: null,
      statuses: ['ACTIVE', 'PROSPECT'],
      customerTypes: ['INDIVIDUAL', 'CORPORATE'],
      registrationDate: null
    };

    const data = await this.http.get(
      `/customers${this.requestFormatter.convertToQueryParams(searchCriteria)}`,
      {nxLoaderText: 'Searching for customer...'}
    ).toPromise();

    if (data && data.length === 1 && data[0].branchId === this.sourceBranch.id) {
      this.completeDataAndAddToPool(data[0]);
    } else {
      this.popup({
          text: `No customer found with cif number [${this.searchCifNumber}] and source branch [${this.sourceBranch.name}].`
        }
      );
    }
  }

  completeDataAndAddToPool(customerSearchResult) {
    const customerDetails = {
      sourceBranch: this.sourceBranch ? this.sourceBranch.name : undefined,
      ...customerSearchResult
    };
    this.customersToBeUpdated.push(customerDetails);
    this.searchCifNumber = "";
  }

  isAlreadyAdded(customerNumber) {
    return this.customersToBeUpdated.find(c => c.customerNumber === customerNumber) !== undefined;
  }

  removeCustomer(index) {
    this.customersToBeUpdated.splice(index, 1);
  }

  async execute() {
    if (!this.sourceBranch || !this.targetBranch) {
      this.popup({text: `Source branch and target branch are required.`});
      return;
    }

    if (this.sourceBranch.id === this.targetBranch.id) {
      this.popup({text: `Source branch ${this.sourceBranch.name} and target branch ${this.targetBranch.name} are the same.`});
      return;
    }

    const customerNumbers = this.customersToBeUpdated.map(p => p.customerNumber);
    const confirmed = await this.confirmationTemplate({
      question: 'These cif numbers will be moved to another branch. Do you wish to proceed?',
      details: [
        {label: 'Cif numbers', description: customerNumbers.toString()},
        {label: 'Branch name', description: this.targetBranch.name}
      ]
    });

    if (confirmed) {
      await this.command.execute(
        'UpdateCifBranch',
        {
          targetBranchId: this.targetBranch.id,
          sourceBranchId: this.sourceBranch.id,
          customerNumbers: customerNumbers
        }
      ).toPromise();

      await this.popup({
        text: `Mother branch of customer(s) successfully changed to ${this.targetBranch.name}`,
      });

      this.$route.reload();
    }
  }

  downloadSample() {
    const sampleFileUrl = window.URL.createObjectURL(new Blob(
        ["122148\n122150"],
        {type: 'text/plain'}
      )
    );

    const a = document.createElement('a');
    a.href = sampleFileUrl;
    a.download = "sample-update-cif-branch.txt";
    a.click();
  }
}

module.component('updateCifBranch', {
  templateUrl,
  controller: UpdateCifBranch
});
