import module from 'module';
import _ from 'lodash';

import templateUrl from './update-product-branch.template.html';


class UpdateProductBranch {
  constructor(http, $scope, $route, branchService, confirmationTemplate,
              command, productDefinitionService, popup) {
    this.http = http;
    this.$scope = $scope;
    this.$route = $route;
    this.branchService = branchService;
    this.confirmationTemplate = confirmationTemplate;
    this.command = command;
    this.productDefinitionService = productDefinitionService;
    this.popup = popup;

    this.branches = [];
    this.productsToBeUpdated = [];
    this.movingProducts = false;
    this.addProductButtonDisabled = true;
    this.searchProductNumber = null;
    this.targetBranch = undefined;
    this.sourceBranch = undefined;

    this.initData();
  }

  async initData() {
    const branchP = this.branchService.toPromise();
    const prodDefP = this.productDefinitionService.toPromise();
    const [branches, productDefinitions] = await Promise.all([branchP, prodDefP]);

    this.branches = _.cloneDeep(branches);
    this.productDefinitions = _.cloneDeep(productDefinitions);
  };

  addProduct() {
    if (this.checkIfExisting(this.searchProductNumber)) {
      this.popup({
        text: `Product number ${productNumber} is already included in the list.`
      });
      return;
    }

    const seachCriteriaRequest = {
      productNumber: [this.searchProductNumber],
      exactProductNumber: true,
      branchId: this.sourceBranch.id
    };

    this.http.post(`/products/search`, seachCriteriaRequest, {nxLoaderText: 'Searching for product...'})
      .success((data) => {
        if (data && data.result && data.result.length === 1) {
          this.completeDataAndAddToPool(data.result[0]);
        } else {
          this.popup({
              text: `No product found with product number [${seachCriteriaRequest.productNumber}] and source branch [${this.sourceBranch.name}]. 
                \n To change the source branch, please reload the page.`
            }
          );
        }
      });
  };

  completeDataAndAddToPool(searchResult) {
    const product = _.cloneDeep(searchResult);
    const def = _.find(this.productDefinitions, {
      id: product.definitionId
    });

    product.productType = def.productGroup;
    product.productName = def.productName;
    product.sourceBranch = this.sourceBranch.name;
    this.productsToBeUpdated.push(product);
  };

  checkIfExisting(productNumber) {
    return _.find(this.productsToBeUpdated, {
      productNumber: productNumber
    });
  };

  removeProduct(index) {
    this.productsToBeUpdated.splice(index, 1);
  };

  enableForm() {
    return this.targetBranch && this.sourceBranch && this.productsToBeUpdated.length > 0;
  };

  async execute() {
    const productIds = _.map(this.productsToBeUpdated, prod => {
      return prod.id;
    });
    const productNumbers = _.map(this.productsToBeUpdated, prod => {
      return prod.productNumber;
    });
    const targetBranchId = this.targetBranch.id;
    const sourceBranchId = this.sourceBranch.id;

    const payload = {
      targetBranchId: targetBranchId,
      sourceBranchId: sourceBranchId,
      productIds: productIds
    };

    await this.confirmationTemplate({
      question: 'These products will be moved to another branch. Do you wish to proceed?',
      details: [
        {label: 'Product numbers', description: productNumbers.toString()},
        {label: 'Branch name', description: this.targetBranch.name}
      ],
      yesCallback: async () => {
        await this.command.execute('UpdateProductBranch', payload).toPromise();
        this.popup({
            text: `Mother branch of product(s) successfully changed to ${this.targetBranch.name}`,
            callback: () => this.$route.reload()
          }
        );
      }
    });
  };

  moveProducts() {
    if (this.validatePostingDates()) {
      this.movingProducts = true;
    } else {
      this.popup({text: `Source branch [${this.sourceBranch.name} : ${this.sourceBranch.postingDate}] and target branch [${this.targetBranch.name} | ${this.targetBranch.postingDate}] should have the same posting date.`});
    }
  };

  validatePostingDates() {
    return this.sourceBranch.postingDate === this.targetBranch.postingDate;
  };
}

module.component('updateProductBranch', {
  templateUrl,
  controller: UpdateProductBranch
});