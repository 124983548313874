import module from 'module';

import templateUrl from './pawned-item-rates.template.html';

class PawnedItemRates {
  constructor(authentication) {
    this.permission = authentication.permissions;
  }
}

module.component('pawnedItemRates', {
  templateUrl,
  controller: PawnedItemRates
});
