import './sub-components/group-data.component'
import './sub-components/add-group-members.component'
import './sub-components/group-officers.component'
import './sub-components/group-schedule.component'
import {Subscription} from 'rxjs/Subscription';


import module from 'module';
import moment from 'moment';

const templateUrl = require('./group-profile.template.html');
module.component('groupProfile', {
  templateUrl: templateUrl,
  controller: function ($route, $location, confirmation, command, groupCustomerCache, branchService, singleUserCache, customerService) {
    const that = this;
    that.editMode = false;

    that.profile = null;
    that.officers = [];

    that.customerId = $route.current.params['customerId'];

    const subscription = new Subscription();


    const formatTime = (time) => time ? moment(time).format('HH:mm:ss') : null;
    const parseTime = (time) => time ? moment(time, 'HH:mm:ss').toDate() : null;

    that.initOfficers = () => {
      that.officers = [];

      for (let loanOfficer of that.profile.loanOfficers) {
        that.officers.push({
          dbId: loanOfficer.id,
          id: loanOfficer.userId,
          role: loanOfficer.role,
          name: loanOfficer.name,
          isUserRole: true
        });
      }

      for (let groupLeader of that.profile.groupLeaders) {
        that.officers.push({
          dbId: groupLeader.id,
          id: groupLeader.customerId,
          role: groupLeader.role,
          name: groupLeader.name,
          isUserRole: false
        });
      }
    };

    that.setOfficersNames = () => {
      for (let user of that.profile.loanOfficers) {
        let singleUserCacheSub = singleUserCache.user(user.userId).toObservable().subscribe((u) => {
          const middle = (u.middleName ? u.middleName + ' ' : '');
          user.name = `${u.firstName} ${middle}${u.lastName}`;
        });
        subscription.add(singleUserCacheSub);
      }

      for (let member of that.profile.groupLeaders) {
        let customer = that.profile.customers.find(el => el.customerId == member.customerId);
        member.name = customer.name;
      }
    };

    if (that.customerId) {
      that.editMode = true;
      let branchServiceSub = branchService.toObservable()
        .combineLatest(groupCustomerCache.profile(that.customerId).toObservable(), (branches, profileResp) => {
          let profile = angular.copy(profileResp);
          for (let customer of profile.customerGroup.customers) {
            let branch = branches.find((el) => el.id == customer.branchId);
            customer.branch = branch;
            customer.birthDate = customer.individualData.birthDate;
            customer.customerId = customer.id;
            customer.name = customer.effectiveName;
          }
          return profile;
        })
        .subscribe((groupCustomer) => {

          groupCustomer.customerGroup.collectionTimeFrom = parseTime(groupCustomer.customerGroup.collectionTimeFrom);
          groupCustomer.customerGroup.collectionTimeTo = parseTime(groupCustomer.customerGroup.collectionTimeTo);

          that.profile = groupCustomer.customerGroup;
          that.profile.addresses = groupCustomer.addresses;
          that.profile.customerId = that.customerId;
          that.profile.branchId = groupCustomer.branchId;


          if (typeof(groupCustomer.customerGroup.collectionDay) == 'string') {
            const weekdays = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
            that.profile.collectionDay = weekdays.findIndex(el => el.toUpperCase() === groupCustomer.customerGroup.collectionDay.toUpperCase());
          }

          groupCustomer = customerService.extractPhones(groupCustomer);
          that.profile.phones = [
            groupCustomer.extractedPhones.home || {
              primary: false,
              type: 'HOME',
            }, groupCustomer.extractedPhones.mobile || {
              primary: false,
              type: 'MOBILE',
            }
          ];


          that.setOfficersNames();
          that.initOfficers();
        });
      subscription.add(branchServiceSub);
    } else {
      that.profile = {
        customerIds: [],
        customers: [],
        loanOfficers: [],
        groupLeaders: [],
        addresses: [],
        phones: [
          {
            primary: false,
            type: 'HOME'
          }, {
            primary: false,
            type: 'MOBILE'
          }
        ]
      }
    }


    that.update = () => {
      confirmation('Do you want to update group profile?', () => {
        const clone = angular.copy(that.profile);
        clone.collectionTimeTo = formatTime(clone.collectionTimeTo);
        clone.collectionTimeFrom = formatTime(clone.collectionTimeFrom);
        clone.customers = [];
        clone.phones = clone.phones.filter(phone => phone.number);
        command.execute('UpdateGroupCustomer', clone).success(() => {
          groupCustomerCache.refetch();
          $location.path(`/customer/${that.profile.customerId}/group-loans`);
        });
      });
    };

    that.save = () => {
      confirmation('Do you want to save group profile?', () => {
        const clone = angular.copy(that.profile);
        clone.collectionTimeTo = formatTime(clone.collectionTimeTo);
        clone.collectionTimeFrom = formatTime(clone.collectionTimeFrom);
        clone.customers = [];
        clone.phones = clone.phones.filter(phone => phone.number);
        command.execute('CreateGroupCustomer', clone).success((response) => {
          groupCustomerCache.refetch();
          $location.path(`/customer/${response.output.id}/group-loans`);
        });
      })
    };

    that.cancel = () => {
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => {
        if (that.editMode) {
          $location.path(`/customer/${that.customerId}/group-loans`);
        } else {
          $location.path('/dashboard/lookup-group');
        }
      })
    };

    that.$onDestroy = () => subscription.unsubscribe();

  }
});
