import module from 'module';
import _ from 'lodash';

const templateUrl = require('./encash-on-us-check.template.html');
module.component('encashOnUsCheck', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, confirmation, customerCache, productDefinitionService, command,
                        accountOperationService) {
    const that = this;

    const customerId = $route.current.params['customerId'];
    const productId = $route.current.params['accountId'];

    const s1 = customerCache.depositAccounts(customerId).toObservable()
      .combineLatest(productDefinitionService.toObservable(), (depositAccounts, products) => {
        return depositAccounts.map(da => {
          const p = _.find(products, {id: da.definitionId});
          return Object.assign(da, {
            productName: p ? p.productName : '<Unknown product>'
          });
        })
      })
      .combineLatest(customerCache.profile(customerId).toObservable(), (depositAccounts, customer) => {
        that.payee = customer.effectiveName;
        return depositAccounts;
      })
      .subscribe(accounts => {
        that.depositAccount = _.find(accounts, (a) => String(a.id) === productId);
        that.remarks = 'Withdrawal from account no. ' + that.depositAccount.productNumber;
      });

    that.encash = () => {
      accountOperationService.encashOnUsCheck({
        customerId: customerId,
        productId: productId,
        amount: that.check.amount,
        validFrom: $filter('nxDate')(that.check.validFrom),
        micrNumber: that.check.micrNumber,
        payee: that.check.payee,
        remarks: that.check.remarks
      }, that.redirectBack)
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${productId}`);

    that.$onDestroy = () => {
      s1.unsubscribe();
    };
  }
});
