import module from 'module';

import templateUrl from './customer-risk-profile.template.html';
import './customer-risk-profile.style.less';

class CustomerRiskProfileView {
  constructor(http, $route, dict, nav, command, popup, confirmation, riskAssessmentEntryCache) {
    this.http = http;
    this.$route = $route;
    this.dict = dict;
    this.nav = nav;
    this.command = command;
    this.popup = popup;
    this.confirmation = confirmation;
    this.riskAssessmentEntryCache = riskAssessmentEntryCache;

    this.onModalApiReady = ({api,}) => {
      this.modalApi = api;
    };
  }

  async $onInit() {
    this.dict.onLoadingComplete(() => this.customerRiskLevels = this.dict['CUSTOMER_RISK_LEVEL']);

    this.customerId = this.$route.current.params['customerId'];
    [this.riskCategories, this.riskProfileContainer, this.riskAssessments] = await Promise.all([
      this.http.get('/management/risk/categories').toPromise(),
      this.fetchCustomerRiskProfilePromise(),
      this.riskAssessmentEntryCache.toPromise()
    ]);
    this.loadRiskProfile();
  }

  loadRiskProfile() {
    this.riskProfile = this.riskProfileContainer.riskProfile;
    this.riskProfileEntries = this.riskProfileContainer.entries.map(e => ({
      ...e,
      category: this.getCategory(e),
      categoryEntry: this.getCategoryEntry(e)
    }));
  }

  fetchCustomerRiskProfilePromise() {
    return this.http.get(`/customers/${this.customerId}/risk-profile`).toPromise();
  }

  async calculate() {
    this.disableCalculate = true;

    this.riskProfile = null;
    this.riskProfileEntries = null;
    try {
      const response = await this.command.execute('CreateRiskProfile', {customerId: this.customerId}).toPromise();
      if (response.approvalRequired) {
        return;
      }

      this.riskProfileContainer = await this.fetchCustomerRiskProfilePromise();
      this.loadRiskProfile();

      this.popup({
        header: 'Result',
        text: 'New risk profile was generated.',
        renderHtml: true
      });
    } finally {
      this.disableCalculate = false;
    }
  }

  getCategory(profileEntry) {
    return this.riskCategories.find(c => c.id === profileEntry.riskCategoryId);
  }

  getCategoryEntry(profileEntry) {
    return this.riskCategories.flatMap(c => c.entries).find(e => e.id === profileEntry.riskCategoryEntryId);
  }

  goBack() {
    this.nav.back();
  }

  async override() {
    const { accepted } = await this.modalApi.show();
    if (!accepted) {
      this.overrideRemarks = null;
      this.overrideRiskDictionaryEntryId = null;
      return;
    }

    const response = await this.command.execute('OverrideRiskProfile', {
      customerId: this.customerId,
      overrideRemarks: this.overrideRemarks,
      riskDictionaryEntryId: this.overrideRiskDictionaryEntryId
    }).toPromise();

    if (response.approvalRequired) {
      return;
    }

    this.$route.reload();
  }

  async save() {
    const confirmation = await this.confirmation(`Do you want update this risk profile?`);
    if (!confirmation) {
      return;
    }

    const response = await this.command.execute('UpdateRiskProfile', {riskProfile: this.riskProfile}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.$route.reload();
  }

  getRiskLevelName(riskAssessment) {
    return this.dict.getDescription('CUSTOMER_RISK_LEVEL', riskAssessment.riskDictionaryEntryId);
  }
}

module.component('customerRiskProfileView', {
  templateUrl,
  controller: CustomerRiskProfileView
});
