import module from 'module';
import csv from "jquery-csv";
import _ from 'lodash';
import BigNumber from "bignumber.js";
import {addAccountLabels} from 'components/general-ledger/common/gl.utils';
import '../common/account-picker.component'


const templateUrl = require('./batch-memo.template.html');
module.component('batchMemo', {
  templateUrl: templateUrl,
  controller: function ($scope, $location, $filter, glMappingsService, confirmation, http, productDefinitionService, command, popup) {
    const that = this;

    // By default set display mode to 'CREDIT'
    that.displayMode = 'CREDIT';

    // GL accounts select config
    that.selectConfig = {
      placeholder: 'Select Account',
      searchField: 'label',
      valueField: 'fullCode',
      labelField: 'label',
      maxItems: 1
    };

    const defaultAccountSearchPageSize = 50;
    // GL account to be debited
    that.glAccount = null;
    // List of accounts to be credited/debited
    that.accounts = [];
    // Remarks that will be applied to all of the memo operations
    that.remarks = null;

    that.missingAccounts = {};

    that.setDisplayMode = (mode) => {
      that.displayMode = mode;
    };

    //true for csv accounts not found on server
    that.hasCSVInvalidData = () => {
      return !_.isEmpty(that.missingAccounts);
    };

    const glAccountsSub = glMappingsService.accounts.toObservable().subscribe(glAccounts => {
      that.glAccounts = addAccountLabels(
        _.filter(glAccounts, function (glAccount) {
          return ['ASSET', 'LIABILITY', 'INCOME', 'EXPENSE'].includes(glAccount.accountGroup);
        })
      );
    });

    that.calculateTotal = () => {
      return that.accounts.reduce((sum, item) => sum.add(item['amount'] || 0), new BigNumber(0)).toFixed(2);
    };

    that.selectAccount = (account) => that.accounts.push(account);

    that.removeAccount = (index) => that.accounts.splice(index, 1);

    productDefinitionService.toObservable().first().subscribe(data => that.productTypes = data);

    that.fetchAccounts = (request, onData) => {
      http.post('/products/accounts/search', request)
        .success((data) => {
          onData(data);
          const readData = (data.pageNo * defaultAccountSearchPageSize) + data.result.length;
          if (readData < data.totalCount) {
            request.pageNo = request.pageNo + 1;
            that.fetchAccounts(request, onData);
          }
        });
    };

    that.updateTotal = () => {
      if (_.size(that.accounts) > 0 ) {
        that.total = that.calculateTotal();
      } else {
        that.total = 0;
      }
    };
    $scope.$watchCollection('$ctrl.accounts', () => {
      that.updateTotal();
    });

    $scope.$watch('$ctrl.fileContent', () => {
      if (that.fileContent) {
        //reset all data
        that.reset();

        let accountList = csv.toArrays(that.fileContent, {'separator': ';'});
        //remove header entry
        if (accountList && accountList.length > 0) {
          accountList.shift();
        }
        accountList = accountList.map((item, idx) => {
          return {
            productNumber: item[0],
            amount: item[1],
            //+1 to append index of removed header
            //+1 to start line count from 1
            lineNo: idx + 1 + 1
          };
        })
        //remove tag with empty product number
          .filter(item => item.productNumber);

        that.missingAccounts = accountList.reduce((map, item) => {
          map[item.productNumber] = item;
          return map;
        }, {});

        let csvAccounts = accountList.map(item => item.productNumber);
        that.fetchAccounts({
          productNumber: csvAccounts,
          exactProductNumber: true,
          pageSize: defaultAccountSearchPageSize,
          pageNo: 0
        }, (data) => {
          const searchResult = data.result;
          // Assign product type name to each search result
          for (let r of searchResult) {
            const productType = _.find(that.productTypes, {id: r.definitionId});
            r.amount = Number(that.missingAccounts[r.productNumber].amount);
            r.productName = productType.productName;
            //remove from map accounts with valid product number
            delete that.missingAccounts[r.productNumber];
          }
          that.accounts = that.accounts.concat(searchResult);
        });
      }
    });

    that.reset = () => {
      that.glAccount = null;
      that.accounts = [];
      that.remarks = null;
      that.missingAccounts = {};
    };

    that.doOperation = () => {
      confirmation(`Do you want to perform batch ${that.displayMode} memo for total sum of ${$filter('php')(Number(that.total))}?`, () => {

        const req = {
          "type": that.displayMode,
          "ledgerAccountFullCode": that.glAccount.accountCode,
          "remarks": that.remarks,
          "accounts": _.map(that.accounts, (a) => {
            return {"id": a.id, "amount": a.amount}
          })
        };

        command.execute('AccountBatchMemo', req).success((res) => {
          that.reset();
          const errors = res.output.executionErrors;
          if (errors && Object.keys(errors).length > 0) {

            // Convert errors to HTML form
            let popupText = '<p><span class="red"><strong>WARNING!</strong> Accounts listed below were omitted.</span></p>';
            _.forOwn(errors, (errMessage, accNumber) => {
              popupText += `<strong>${accNumber}</strong>: ${errMessage}<br>`
            });

            // Display popup with error details
            popup({
              header: 'Memo errors',
              text: popupText,
              renderHtml: true
            });
          }
        });
      });
    };

    that.$onDestroy = () => {
      glAccountsSub.unsubscribe();
    }
  }
});
