import BigNumber from 'bignumber.js';
import module from 'module';
import {allPercentageChargeTypes, pastDueInterestChargeTypes} from "constants/loan";

const templateUrl = require('./new-loan-charges-override.template.html');

module.component('customerLoanChargesOverride', {
  templateUrl: templateUrl,
  bindings: {
    loan: '=',
    formName: '=',
    loanProduct: '<'
  },
  controller: function ($scope, $route, http, notification, command, popup, $filter, feeDefinitionsCache) {
    let that = this;
    that.overrideChkbox = false;

    that.fees = [
      {name: 'Doc stamp', key: 'docStamp'},
      {name: 'Notarial Fee', key: 'notarialFee'},
      {name: 'Application Fee', key: 'applicationFee'},
      {name: 'Credit Investigation Fee', key: 'creditInvestigationFee'},
      {name: 'Extra Bank Fee', key: 'extraBankFee'},
      {name: 'Service Charge', key: 'serviceCharge'},
      {name: 'Membership Fee', key: 'membershipFee'},
      {name: 'Id Fee', key: 'idFee'},
      {name: 'Insurance Fee', key: 'insuranceFee'},
      {name: 'Insurance Service Fee', key: 'insuranceServiceFee'},
      {name: 'Insurance Processing Fee', key: 'insuranceProcessingFee'}
    ];

    that.contractualFees = [
      {name: 'CBU Charge', key: 'cbuCharge'},
      {name: 'PF Charge', key: 'pfCharge'},
      {name: 'TP Charge', key: 'tpCharge'}
    ];

    that.allFees = [
      ...that.fees,
      ...that.contractualFees
    ];

    that.pastDueInterestChargeTypes = pastDueInterestChargeTypes.map(value => ({
      label: $filter('prettyEnum')(value),
      value,
    }));

    that.penaltyChargeTypes = [...allPercentageChargeTypes, 'FIXED_AMOUNT'].map(type => ({
      label: $filter('prettyEnum')(type),
      value: type
    }));


    that.currentFees = {
    };

    that.allFees.map(el => el.key).forEach(el => that.currentFees[el] = 0);

    that.diff = angular.copy(that.currentFees);

    that.diffCalculate = (key) => {
      var currentFee = that.currentFees[key];
      var loanFee = that.loan.feeOverride[key];
      if (currentFee && loanFee) {
        var current = new BigNumber(currentFee);
        var override = new BigNumber(loanFee);
        that.diff[key] = override.minus(current).toNumber();
      } else {
        that.diff[key] = 0
      }

    };

    that.postForFees = async () => {
      if (that.loan.principalAmount) {
        const clonedLoan = angular.copy(that.loan);
        clonedLoan.collateralFiles = undefined;
        clonedLoan.creationType = clonedLoan.creationType || 'NEW_LOAN';
        clonedLoan.automaticTransfer = false;

        const [loan, feeDefinitions] = await Promise.all([
          http.post('/products/loans/simulate', clonedLoan).toPromise(),
          feeDefinitionsCache.toPromise()
        ]);

        for (let el of that.fees) {
          that.currentFees[el.key] = loan[el.key];
        }
        let amortization = loan.amortizationSchedule.list[0];
        let totalAmortizationNumber = loan.totalAmortizationNumber;
        for (let el of that.contractualFees) {
          that.currentFees[el.key] = new BigNumber(parseFloat(amortization[el.key + 'Amount'])).mul(totalAmortizationNumber).toNumber();
        }
        that.includeCustomFees(loan.simulatedFees, feeDefinitions);
        that.loan.feeOverride = angular.copy(that.currentFees);
      }
    };

    that.includeCustomFees = (fees, feeDefinitions) => {
      that.loan.customFeesOverride = fees.filter(fee => !fee.applied)
          .map(fee => {
            const feeDefinition = feeDefinitions.find(feeDefinition => feeDefinition.id === fee.feeDefinitionId);
            fee.name = feeDefinition.feeName;
            fee.defaultAmount = fee.amount;
            return fee;
          });
    };

    that.clearFeesOverride = () => {
      that.loan.feeOverride = {};
      that.loan.customFeesOverride = [];
    };

    $scope.$watch('$ctrl.overrideChkbox', () => {
      if (that.overrideChkbox) {
        that.postForFees();
      } else {
        that.clearFeesOverride();
      }
    });

    $scope.$watch('$ctrl.loan.creationType', () => {
      that.clearFeesOverrideWithMessage("Loan type changed. Please fill charges override form again.");
    });

    $scope.$watch('$ctrl.loan.principalAmount', () => {
      that.clearFeesOverrideWithMessage("Principal changed, please fill charges override form again.");
    });

    that.clearFeesOverrideWithMessage = (message) => {
      if (that.overrideChkbox) {
        that.clearFeesOverride();
        that.overrideChkbox = false;
        popup({text: message});
      }
    };
  }
});
