import module from 'module';
import './areas.style.less';
import templateUrl from './areas.template.html';
import {flatten} from "../tree.utils";

class Areas {
  constructor($location, $route, http, branchService, command, confirmation) {
    this.$location = $location;
    this.$route = $route;
    this.http = http;
    this.branchService = branchService;
    this.command = command;
    this.confirmation = confirmation;
  }

  async $onInit() {
    [this.areas, this.branches] = await Promise.all([this.http.get('/management/areas').toPromise(), this.branchService.toPromise()]);

    const flatAreas = flatten(this.areas);
    flatAreas.forEach(area => this.assignProperties(area));

    const assignedBranches = flatAreas
      .flatMap(area => area.branchIds || [])
      .filter((id, index, self) => self.indexOf(id) === index);
    this.unassignedBranches = this.branches
      .filter(branch => !assignedBranches.includes(branch.id));
  }

  assignProperties(area) {
    // flag to distinguish areas from branches in tree structure
    area.isArea = true;

    if (area.branchIds) {
      area.branches = area.branchIds.map(branchId => {
        return {
          id: branchId,
          name: this.branches.find(b => Number(branchId) === Number(b.id)).name
        }
      });
    }
  }

  isArea(item) {
    return item.branches || item.children;
  }

  create() {
    this.$location.path('/admin/organization/areas/create');
  }

  edit(areaId) {
    this.$location.path(`/admin/organization/areas/${areaId}`);
  }

  async delete({id, name}) {
    const proceed = await this.confirmation(`Are you sure you want to delete '${name}'?`);
    if (proceed && id) {
      await this.command.execute('DeleteArea', { id: id }).toPromise();
      this.$route.reload();
    }
  }
}

module.component('areas', {
  templateUrl,
  controller: Areas
});