import module from 'module';
import _ from 'lodash';

const templateUrl = require('./batch-register-inward-check.template.html');

module.component('batchRegisterInwardCheck', {
  templateUrl: templateUrl,
  controller: function ($filter, $location, command, confirmationTemplate, popup) {

    const that = this;
    that.clearingFile = [];

    that.input = {
      format: 'BDO',
      performClearing: false,
      stopOnError: true
    };

    that.redirectBack = () => $location.path('/inventory/checks');

    that.postChecks = () => {
      confirmationTemplate({
        question: `Do you want to upload inward clearing file?`,
        details: [
          {label: 'File format', description: that.input.format},
          {label: 'Perform clearing', description: $filter('prettyBoolean')(that.input.performClearing)},
          {label: 'Stop registration on error', description: $filter('prettyBoolean')(that.input.stopOnError)}
        ],
        yesCallback: () => {
          that.input.fileId = that.clearingFile[0].id;
          command.execute('BatchRegisterInwardCheck', that.input).success((res) => {
            if (res.output.registrationErrors) {

              // Convert registration errors to HTML form
              let popupText = '<p><span class="red"><strong>WARNING!</strong> Checks listed below were not uploaded.</span></p>';
              _.forOwn(res.output.registrationErrors, (errMessage, checkNumber) => {
                popupText += `<strong>${checkNumber}</strong>: ${errMessage}<br>`
              });

              // Display popup with error details
              popup({
                header: 'Upload errors',
                text: popupText,
                callback: () => that.redirectBack(),
                renderHtml: true
              });
            }
          })
        }
      });
    };
  }
});
