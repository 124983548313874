import module from "module";
import templateUrl from "./resend-failed-notifications.template.html";
import moment from "moment";

class ResendFailedNotifications {
  constructor(confirmation, http, $route, command, $filter) {
    this.confirmation = confirmation;
    this.http = http;
    this.$route = $route;
    this.command = command;
    this.$filter = $filter;

    this.maxSendOnDate = moment().toDate();
    this.sentOn = this.maxSendOnDate;
  }

  async execute() {
    const confirmation = await this.confirmation(`Do you want to resend all failed notifications sent on ${this.$filter('nxDate')(this.sentOn)}?`);
    if (!confirmation) {
      return;
    }

    await this.command.execute('ResendFailedNotification',{
      sentOn: this.$filter('nxDate')(this.sentOn)
    }).toPromise();
    this.$route.reload();
  }
}

module.component('resendFailedNotifications', {
  templateUrl,
  controller: ResendFailedNotifications
});
