import module from 'module';

// based on: https://stackoverflow.com/questions/15242592/how-to-autocapitalize-the-first-character-in-an-input-field-in-angularjs
module.directive('capitalizeInput', function ($parse) {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, modelCtrl) {
      const capitalize = function (inputValue) {
        if (inputValue === null || inputValue === undefined) return '';
        let capitalized = inputValue.charAt(0).toUpperCase() + inputValue.substring(1);
        if (capitalized !== inputValue) {
          modelCtrl.$setViewValue(capitalized);
          modelCtrl.$render();
        }
        return capitalized;
      };
      modelCtrl.$parsers.push(capitalize);
      capitalize($parse(attrs.ngModel)(scope)); // capitalize initial value
    }
  };
});
