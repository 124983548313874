import module from 'module';

//based on http://stackoverflow.com/questions/14012239/password-check-directive-in-angularjs
module.directive('validatePassword', function(){
  return {
    restrict: 'A',
    require: '?ngModel',
    link: function(scope, elem, attr, ngModel) {
      if(!ngModel) return;

      scope.$watch(attr.ngModel, function() {
        validate();
      });

      attr.$observe('validatePassword', function (val) {
        validate();
      });

      let validate = function() {
        let val1 = ngModel.$viewValue;
        let val2 = attr.validatePassword;

        ngModel.$setValidity('validatePassword', !val1 && !val2 || val1 === val2);
      };
    }
  }
});
