import module from 'module';
import $ from 'jquery';
import _ from 'lodash';
import {NgTableParams} from 'ng-table/ng-table.js';

const templateUrl = require('./on-us-checks-list.template.html');
module.component('onUsChecksList', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $location, $filter, $timeout, http, authentication, confirmation, notification, branchService) {
    const that = this;

    // Check search criteria
    that.filter = {
      branchId: null,
      dateRange: {from: null, to: null}
    };

    const convertFilterToQueryString = (params) => {
      return $.param({
        pageNo: params.page() - 1,
        pageSize: params.count(),
        branchId: that.filter.branchId,
        status: that.filter.status,
        registeredOnFrom: $filter('nxDate')(that.filter.dateRange.from),
        registeredOnTo: $filter('nxDate')(that.filter.dateRange.to),
        clearingGroup: 'ON_US'
      });
    };

    const createCheckDetails = (check) => {

      const details = [];
      const pushDetail = (label, value, include = true, nullLabel = '-') => {
        if (include) details.push({label: label, value: value !== '' && value !== null ? value : nullLabel});
      };

      // Prepare check details data
      pushDetail('Check id.', check.id);
      pushDetail('Number', check.number);
      pushDetail('MICR', check.micrNumber);
      pushDetail('Clearing group', $filter('prettyEnum')(check.checkClearingGroup));
      pushDetail('Status', $filter('prettyEnum')(check.status));
      pushDetail('Post dated', $filter('prettyEnum')(check.postDated.toString()));
      pushDetail('Valid from', $filter('prettyDate')(check.validFrom));
      pushDetail('Amount', $filter('php')(check.amount));
      pushDetail('Registered on', $filter('prettyDate')(check.registeredOn));
      pushDetail('Credited on', $filter('prettyDate')(check.creditedOn));
      pushDetail('Remarks', check.remarks);

      return details;
    };

    const createSearchQueryPromise = (params) => {
      const queryParams = convertFilterToQueryString(params);
      return http.get(`/checks/outgoing?${queryParams}`, {nxLoaderText: 'Loading checks'}).success(page => {
        that.tableConfig.data = page.result;
        that.tableConfig.total(page.totalCount);
        that.checks = page.result;
        if (that.checks && that.checks.length > 0) {
          _.forEach(that.checks, (check) => {
            check.details = createCheckDetails(check);
          });
        }
      });
    };

    that.itemClicked = (check, $event) => {
      $event.stopPropagation();
      that.selectedCheck = check;
      that.selectedCheckId = check.id;
    };

    that.hideInlinePanel = () => {
      that.selectedCheckId = null;
    };

    that.tableConfig = new NgTableParams({
      count: 20,
    }, {
      counts: [],
      paginationMaxBlocks: 5,
      paginationMinBlocks: 5,
      getData: params => createSearchQueryPromise(params)
    });

    that.filterChecks = () => {
      that.tableConfig.reload();
    };

    // Read branches available for user
    const branchesSub = branchService.toObservable().subscribe(branches => {

      // Initialize list of branches available to user
      const branchIds = authentication.context.branchIds;
      that.availableBranches = _.filter(branches, (b) => _.includes(branchIds, b.id));

      // Prepare default filter settings
      const defaultBranch = _.find(that.availableBranches, {id: authentication.context.branchId});
      const postingDate = Date.parse(defaultBranch.postingDate);
      that.filter.branchId = defaultBranch.id;
      that.filter.dateRange = {from: postingDate, to: postingDate};

      // Load initial checks page
      that.tableConfig.page(1);
      that.tableConfig.reload();
    });

    that.$onDestroy = () => {
      branchesSub.unsubscribe();
    };
  }
});
