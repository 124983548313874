import module from 'module';
import _ from 'lodash';

import templateUrl from './administration-branches.template.html';

class AdministrationBranches {
  constructor($location, authentication, branchService, organizationCache, workingDaysCache) {
    this.$location = $location;
    this.authentication = authentication;
    this.branchService = branchService;
    this.organizationCache = organizationCache;
    this.workingDaysCache = workingDaysCache;
  }

  async $onInit() {
    this.statusCodeToLabelMapping = {
      'STARTED': 'Active',
      'PENDING': 'Pending start of day',
      'COUNTER_CLOSED': 'Counter closed',
      'CLOSED': 'Closed',
      'BATCH_PROCESSING': 'Batch processing'
    };

    this.canManageBranches = this.authentication.permissions['MNG_BRANCH_WRITE'];

    const [branches, organizations, workingDays]  = await Promise.all([this.branchService.toPromise(), this.organizationCache.toPromise(), this.workingDaysCache.toPromise()]);
    this.rootOrganization = _.find(organizations, {'root': true});

    for (const branch of branches) {
      branch.organization = _.find(organizations, {id: branch.organizationId});
      branch.status = {};
      for (const workingDay of workingDays) {
        if (branch.id === workingDay.branchId) {
          branch.status.code = workingDay.status;
          branch.postingDate = workingDay.postingDate;
          branch.clearingDay = workingDay.clearingDay ? 'Yes' : 'No';
        }
      }

      branch.status.label = this.resolveBranchStatus(branch)
    }
    this.branches = branches.sort((a, b) => a.name.localeCompare(b.name));
  }

  resolveBranchStatus(branch) {
    if (branch.closed){
      return 'Permanently closed';
    }

    return this.statusCodeToLabelMapping[branch.status.code];
  }

  addNew() {
    this.$location.path('/admin/organization/branches/create');
  }
}

module.component('administrationBranches', {
  templateUrl,
  bindings: {
    detailLink: '@'
  },
  controller: AdministrationBranches
});
