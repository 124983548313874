import LocalCache from 'shared/util/localCache';
import angular from 'angular';

import {storagePrefix} from "shared/util/localCache";

/**
 * A wrapper around LocalCache that allows you to specify a param.
 * The response for each param is kept separately under different cache keys.
 */
export default class ParameterizedLocalCache {

  constructor(options) {
    this.args = options;
  }

  withParam(param) {
    let args = angular.copy(this.args);
    const stringParam = JSON.stringify(param);

    // wrap provider
    let originalProvider = args.provider;
    args.provider = () => originalProvider(param);

    let localCache = new LocalCache(args);
    localCache.param = param;
    localCache.cacheKey = 'key-' + stringParam;

    return localCache;
  }

  evict() {
    const cacheName = this.args.cacheName;
    const cacheKeyPrefix = `${storagePrefix}${cacheName}`;
    console.log('Evicting cache for', cacheName);

    for(let key of Object.keys(localStorage)) {
      if(key.startsWith(cacheKeyPrefix)) {
        localStorage.removeItem(key);
      }
    }
  }

}
