import module from 'module';
import {Observable} from "rxjs/Observable";

class SSSPensionService {

  constructor(http) {
    this.http = http;
  }

  createSimpleGetObservable(url) {
    return Observable.create(observer => {
      this.http.get(url)
        .success(summary => {
          observer.next(summary);
          observer.complete();
        })
        .error(err => observer.error(err));
    })
  };

  getSummaries() {
    return this.createSimpleGetObservable(`/sss/pensions/summaries`);
  }

  getSummaryByFileId(fileId) {
    if (!fileId || fileId < 1) return Observable.of(null);
    return this.createSimpleGetObservable(`/sss/pensions/summaries?fileId=${fileId}`);
  }

  getPensionsByFileId(fileId) {
    if (!fileId || fileId < 1) return Observable.of([]);
    return this.createSimpleGetObservable(`/sss/pensions?fileId=${fileId}`);
  }
}

module.service('sssPensionService', SSSPensionService);
