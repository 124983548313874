import module from 'module';
import _ from "lodash";

const templateUrl = require('./pawn-product-interests.template.html');
module.component('pawnProductInterestsForm', {
  templateUrl,
  bindings: {
    pawnProduct: '=',
    form: '='
  },
  controller: function ($scope, pawnTagCache, pawnTagService) {

    const that = this;

    that.$onInit = async () => {
      [that.tags, that.permittedTagIds] = await Promise.all([pawnTagCache.toPromise(), pawnTagService.getPermittedTagIds()]);
      that.tagNames = that.tags.reduce((map, tag) => {
        map[tag.id] = tag.name;
        return map;
      }, {});

      that.collectionTypes = [
        {label: 'Full', value: 'FULL'},
        {label: 'Proportional to days delayed', value: 'PROPORTIONAL_TO_DAYS_DELAYED'}
      ]
    };

    $scope.$watch('$ctrl.pawnProduct', () => {
      if (!that.pawnProduct || !that.pawnProduct.taggedInterestRates || !that.tags) {
        return;
      }


      const detailedTaggedInterestRates = that.pawnProduct.taggedInterestRates.map(ir => {
        const tag = that.tags.find(t => t.id === ir.tagId) || {};
        return {
          tagId: tag.id,
          level: tag.level,
          interestRate: ir.interestRate,
          eirRate: ir.eirRate
        }}
      );
      that.pawnProduct.taggedInterestRates = _.sortBy(detailedTaggedInterestRates, 'level', 'name');
      that.taggedInterestRatesView = that.pawnProduct.taggedInterestRates
        .filter(ir => that.permittedTagIds.includes(ir.tagId));
    });

    that.isInterestRateUniqueWithinSameLevel = ($index) => {
      const {level, interestRate} = that.pawnProduct.taggedInterestRates[$index];
      const differentTagsWithinSameLevel = that.pawnProduct.taggedInterestRates
        .filter((ir, index) => index !== $index && ir.level === level)
      return differentTagsWithinSameLevel.every(ir => ir.interestRate !== interestRate);
    }

    that.isEirRateEqualOtherSameInterestRates = ($index) => {
      const {interestRate, eirRate} = that.pawnProduct.taggedInterestRates[$index];
      const differentTagsWithSameInterestRate = that.pawnProduct.taggedInterestRates
        .filter((ir, index) => index !== $index && ir.interestRate === interestRate)
      return differentTagsWithSameInterestRate.every(ir => ir.eirRate === eirRate);
    }

    // charge types that available on pawn opening fees
    that.pawnChargeTypes = [
      {label: 'Fixed Amount', value: 'FIXED_AMOUNT'},
      {label: 'Percentage of Original Interests', value: 'PERCENTAGE_OF_ORIGINAL_INTERESTS'},
      {label: 'Percentage of Original Principal', value: 'PERCENTAGE_OF_ORIGINAL_PRINCIPAL'},
      {label: 'Percentage of Original Interests and Principal', value: 'PERCENTAGE_OF_ORIGINAL_INTERESTS_AND_PRINCIPAL'}
    ];

    that.interbranchRenewalChargeTypes = [
      {label: 'Fixed Amount', value: 'FIXED_AMOUNT'},
      {label: 'Percentage of Original Principal', value: 'PERCENTAGE_OF_ORIGINAL_PRINCIPAL'}
    ];
  }
});
