import module from 'module';
import moment from 'moment';

module.factory('systemDateService', function (branchService, authentication) {
  let service = {};

  service.getSystemDate = (branch) => {
    if (branch && branch.postingDate) {
      return moment(branch.postingDate);
    }
    throw new Error('System date for user branch not found. Provided branch object: ' + JSON.stringify(branch));
  };

  service.getSystemDateByBranchId = (branchId) => {
    return branchService.toPromise()
    // first we need to find the right branch
      .then(branches => branches.find(branch => branch.id === branchId))
      // then from branch we need to grab systemDate
      .then(branch => moment(branch.postingDate));
  };

  service.getCurrentUserBranchSystemDate = () => {
    return service.getSystemDateByBranchId(authentication.context.branchId);
  };

  return service;
});
