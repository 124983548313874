import module from 'module';
import $ from "jquery";
import contentDisposition from "content-disposition";
import retry from 'p-retry';


class ApiError extends Error {
  constructor(errorResponse) {
    super(errorResponse.errorMessage);
    this.errorResponse = errorResponse;
  }
}


module.factory('reportService', function (popup, http, loader, fileService) {

  const retryRequest = async (request) => {
    const loaderId = loader.show('Downloading report');

    try {
      return await retry(async () => {
        try {
          return await request();
        } catch(err) {
          if(!err) {
            throw new retry.AbortError("Error reading data");
          }

          const responseError = err;
          const {errorCode} = responseError;
          if(errorCode !== 'TOO_MANY_REQUESTS') {
            throw new retry.AbortError(new ApiError(responseError));
          }

          console.trace('Too many requests', responseError);
          throw new ApiError(responseError);
        }
      }, {
        maxTimeout: 120000, // retry at least every 2 min
        retries: 20,  // retry with backoff up to 2min, and then try every 2 min
        randomize: true // after 30min we will deny request for report
      });
    } finally {
      loader.dismiss(loaderId);
    }
  }

  const clickOnLink = (url, download) => {
    const a = document.createElement('a');
    a.download = download;
    a.href = url;
    a.click();
  }

  const downloadReportFile = async (reportName, httpObject) => {
    return new Promise((resolve, reject) => {
      httpObject.success((response, status, headers) => {
        try {
          const xlsxFileUrl = window.URL.createObjectURL(response);
          const filename = fileService.parseHeadersForContentFilename(headers) || reportName;

          clickOnLink(xlsxFileUrl, filename);

          window.URL.revokeObjectURL(xlsxFileUrl);
          resolve();
        } catch(err) {
          console.error(err);
          reject({
            errorMessage: err.message
          });
        }
      }).error(async data => {
        if(!data) {
          reject(null);
        }

        if(data instanceof Blob) {
          reject(await new Response(data).json());
        }

        reject(data);
      });
    });
  }

  const handleResponseError = (data) => {
    const unknownError = "An unknown error occurred.";

    let msg = (data.errorResponse && data.errorResponse.errorMessage) ? data.errorResponse.errorMessage : unknownError;
    if(data.errorResponse && data.errorResponse.errorCode === 'TOO_MANY_REQUESTS') {
      msg = 'Error generating report. Please try again later';
    }
    const processedMessage = msg.replace(/\n/g, '<br>');
    popup({header: 'Error', text: processedMessage , renderHtml: true});
  };

  const downloadXls = async ({reportCode, params, reportName}) => {
    try {
      await retryRequest(() =>
        downloadReportFile(reportName, http.http({
            url: `/reports/${reportCode}/xls`,
            method: 'POST',
            nxLoaderSkip: true,
            responseType: 'blob',
            data: prepareFlatJson(params),
            headers: {'Content-Type': 'application/json'},
            nxLoaderText: 'Downloading report XLS'
          })
        )
      );
    } catch(err) {
      handleResponseError(err);
      throw err;
    }
  };

  const downloadCustomFile = async ({reportCode, params}) => {
    try {
      await retryRequest(() =>
        downloadReportFile(reportCode, http.http({
          url: `/reports/${reportCode}/custom-file`,
          method: 'POST',
          nxLoaderSkip: true,
          responseType: 'blob',
          data: prepareFlatJson(params),
          headers: {'Content-Type': 'application/json'},
          nxLoaderText: 'Downloading report custom file'
        }))
      );
    } catch(err) {
      handleResponseError(err);
      throw err;
    }
  }

  const downloadBsrFile = async ({params}) => {
    return http.http({
      url: `/sss/bsr`,
      method: 'POST',
      responseType: 'blob',
      data: $.param(params),
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      nxLoaderText: 'Downloading e-payment file'
    }).success((response, status, headers) => {
      const bsrFileUrl = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = bsrFileUrl;
      const contentDispositionHeader = headers('content-disposition');
      const disposition = contentDisposition.parse(contentDispositionHeader);
      const {filename,} = disposition.parameters;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(bsrFileUrl);
    }).error(data => handleResponseError(data))
      .toPromise();
  }

  const downloadJson = async ({reportCode, params}) => {
    let response = null;
    try {
      response = await retryRequest(() => http
        .http({
          url: `/reports/${reportCode}/json`,
          method: 'POST',
          nxLoaderSkip: true,
          responseType: 'json',
          data: prepareFlatJson(params),
          headers: {'Content-Type': 'application/json'}
        })
        .toPromise()
      );
    } catch (err) {
      handleResponseError(err);
      throw err;
    }

    if (response === null) {
      // response cannot be null - it always should contain a value
      // empty value happens if there is no connection or we got a streaming error
      // and data conversion failed
      const error = {
        errorMessage: 'Error reading data'
      };

      handleResponseError(new ApiError(error));
      throw error;
    }

    return response;
  };

  /*
     * Recursively flattens non-array objects.
     * Implementation follows <code>content-type: application/x-www-form-urlencoded</code> convention for param's name
     * generation which is tightly used by our reports backend.
     *
     * <pre>
     * Source:
     *   {
     *     branchId: [1],
     *     typeId: [null],
     *     roleId: 1,
     *     date_range: {
     *       from: "2017-12-16",
     *       to: "2017-12-16"
     *     }
     *     LEDGER_ACCOUNT_CODE[]: [ASSET/ACC, ASSET/ACC2]
     *   }
     * </pre>
     *
     * <pre>
     * Result:
     *   {
     *     branchId[]: [1],
     *     typeId[]: [null],
     *     roleId: 1,
     *     date_range[from]: "2017-12-16",
     *     date_range[to]: "2017-12-16",
     *     LEDGER_ACCOUNT_CODE[]: [ASSET/ACC, ASSET/ACC2]
     *   }
     * </pre>
     */
  const prepareFlatJson = (source) => {
    const result = {};
    for (let key in source) {
      if (!source.hasOwnProperty(key)) {
        continue;
      }

      const value = source[key];
      if (typeof value == 'object' && !Array.isArray(value) && source[key] !== null) {
        const flatten = prepareFlatJson(source[key]);
        for (const k in flatten) {
          if (!flatten.hasOwnProperty(k)) {
            continue;
          }
          result[key + `[${k}]`] = flatten[k];
        }
      } else {
        if (!key.endsWith('[]') && Array.isArray(value)) {
          key = key + '[]';
        }
        result[key] = value;
      }
    }
    return result;
  }

  return {
    handleResponseError: handleResponseError,
    downloadXls: downloadXls,
    downloadJson: downloadJson,
    downloadCustomFile: downloadCustomFile,
    downloadBsrFile: downloadBsrFile
  }
});
