import module from 'module';
import {loanCalculationMethods, loanCreationTypes} from 'constants/loan';
import {feeTypes, roundingModes} from 'constants/fee';

import './custom-fee.style.less';

const templateUrl = require('./custom-fee.template.html');

class CustomFee {
  constructor($scope) {
    this.$scope = $scope;
    //constants
    this.loanCreationTypes = loanCreationTypes;
    this.loanCalculationMethods = loanCalculationMethods;
    this.feeTypes = feeTypes;
    this.roundingModes = roundingModes;
    //narrow to values supported by loan
  }

  $onInit() {
    const allPredicates = this.feeDefinition.predicates || [];

    this.$scope.$watch('$ctrl.branches', () => {
      if (this.branches) {
        this.branchesIds = this.predicatesToValues(allPredicates, 'BranchIdFeePredicate', () => this.branches.map(b => b.id));
      }
    });

    if (this.feeDefinition.feeTypes) {
      this.feeTypes = this.feeDefinition.feeTypes;
    }

    if (!this.feeDefinition.predicates) {
      this.feeDefinition.predicates = [];
    }

    // we need to force correct value of loan apply on
    this.updateExtraOptions();

    //Loan specific properties
    if (this.productGroup === 'LOAN') {
      this.applyOnProductHooks = ['LOAN_RELEASE', 'LOAN_PAYMENT'];
      this.calculationProductHooks = ['CREATE'];
      //extract selected values from predicates
      if (this.feeDefinition.feeClass !== 'CUSTOM' && this.feeDefinition.validCalculationMethods) {
        this.loanCalculationMethods = this.feeDefinition.validCalculationMethods;
      }

      this.selectedLoanCreationTypes = this.predicatesToValues(allPredicates, 'LoanCreationTypeIdFeePredicate',
        () => this.loanCreationTypes.map(t => t.value));

      if (this.feeDefinition.extraOptions && this.feeDefinition.extraOptions.LOAN_BOARD) {
        this.useFeeBoard = true;
      }
      if (this.feeDefinition.extraOptions && this.feeDefinition.extraOptions.LOAN_APPLY_YEARLY) {
        this.loanApplyYearly = true;
      }
    }

    //Deposit specific properties
    if (this.productGroup === 'DEPOSIT') {
      this.calculationProductHooks = ['DEPOSIT_PRETERMINATION'];
      this.applyOnProductHooks = ['DEPOSIT_PRETERMINATION'];

      this.depositAge = this.predicatesToValues(allPredicates, 'DepositAgeFeePredicate',
        () => null);

      if (this.feeDefinition.feeClass === 'CUSTOM') {
        // Deposits customs fees supports only FIXED type for now on
        this.feeTypes = ['FIXED'];
      } else {
        // Currently Deposits doc-stamp and WITHHOLDING_TAX fees supports only percentage value
        this.feeTypes = ['PERCENTAGE'];
      }
    }
  };

  predicatesToValues(allPredicates, predicateType, defaultValues) {
    let predicates = allPredicates.filter(p => p.type === predicateType);
    if (predicates.length === 1) {
      return predicates[0].value;
    } else {
      return defaultValues();
    }
  };

  feeTypeChange() {
    if (this.feeDefinition.feeType === 'FIXED') {
      this.feeDefinition.calculationMethod = 'FIXED_AMOUNT';
    } else if(this.feeDefinition.feeType === 'PERCENTAGE') {
      this.feeDefinition.calculationMethod = null;
    }
  };

  useFeeBoardChange() {
    if (!this.feeDefinition.extraOptions) {
      this.feeDefinition.extraOptions = {};
    }
    if (this.useFeeBoard) {
      this.feeDefinition.extraOptions.LOAN_BOARD = {cells: []};
      //ignore fee-definition values and use the boards definition
      this.feeDefinition.percentageAmount = 0;
      this.feeDefinition.fixedAmount = 0;
    } else {
      this.feeDefinition.extraOptions.LOAN_BOARD = null;
    }
  }

  selectedValuesToPredicate(predicateType, allValues, selectedValues) {
    //if nothing selected
    if (!selectedValues || selectedValues.length === 0) {
      return {
        type: predicateType,
        value: allValues,
        compareMode: 'NOT_CONTAINS'
      };
      //for all selected
    } else if (allValues.length === selectedValues.length) {
      return [];
    } else if (allValues.length !== selectedValues.length) {
      return {
        type: predicateType,
        value: selectedValues,
        compareMode: 'CONTAINS'
      };
    }
    this.feeDefinition.predicates = this.feeDefinition.predicates
      .filter(p => p.type !== 'BranchIdFeePredicate')
      .concat(branchIdPredicate);
  };

  branchesIdsChange() {
    let allBranchesId = this.branches.map(b => b.id);
    let predicate = this.selectedValuesToPredicate('BranchIdFeePredicate', allBranchesId, this.branchesIds);
    this.feeDefinition.predicates = this.feeDefinition.predicates
      .filter(p => p.type !== 'BranchIdFeePredicate')
      .concat(predicate);
  }

  loanCreationTypesChange() {
    let predicate = this.selectedValuesToPredicate('LoanCreationTypeIdFeePredicate', this.loanCreationTypes, this.selectedLoanCreationTypes);
    this.feeDefinition.predicates = this.feeDefinition.predicates
      .filter(p => p.type !== 'LoanCreationTypeIdFeePredicate')
      .concat(predicate);
  }

  calculationApplyOnChange() {
    if (this.feeDefinition.applyOn === 'LOAN_PAYMENT') {
      if (!this.feeDefinition.extraOptions) {
        this.feeDefinition.extraOptions = {};
      }

      this.feeDefinition.extraOptions.LOAN_APPLY_YEARLY = true;
    } else {
      if (this.feeDefinition.extraOptions) {
        delete this.feeDefinition.extraOptions.LOAN_APPLY_YEARLY;
      }
    }
  }

  updateExtraOptions() {
    if(!this.feeDefinition.extraOptions) {
      this.feeDefinition.extraOptions = {};
    }

    if (this.feeDefinition.applyOn === 'LOAN_PAYMENT') {
      this.feeDefinition.extraOptions.LOAN_APPLY_YEARLY = true;
    } else {
      delete this.feeDefinition.extraOptions.LOAN_APPLY_YEARLY;
    }

    if (this.feeDefinition.calculationMethod !== 'PERCENTAGE_OF_ORIGINAL_PRINCIPAL_WITH_CUSTOM_DIVISOR') {
      //cleanup extra options to make sure it is only applicable for selected calculation methods
      delete this.feeDefinition.extraOptions.DIVISOR;
    }
  }

  depositAgeChange() {
    const predicate = {
      type: 'DepositAgeFeePredicate',
      value: this.depositAge,
      compareMode: 'LESS_THAN_OR_EQUAL'
    };
    this.feeDefinition.predicates = this.feeDefinition.predicates
      .filter(p => p.type !== 'DepositAgeFeePredicate')
      .concat(predicate);
  }
}


module.component('customFee', {
  templateUrl,
  transclude: true,
  bindings: {
    feeDefinition: '=',
    index: '<',
    branches: '<',
    loanInsuranceAccounts: '<',
    //LOAN, DEPOSIT
    productGroup: '<'
  },
  controller: CustomFee
});
