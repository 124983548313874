import module from 'module';
import './app-version-update.less';

const templateUrl = require('./app-version-update.template.html');


module.component('appVersionUpdate', {
  templateUrl: templateUrl,
  controller: function (appVersionService, $interval, config, refreshService) {

    const self = this;

    let checkForUpdates = () => {
      appVersionService.isAppOutdated().then((isOutdated) => {
        self.isAppOutdated = isOutdated;
      });
    };

    self.updateApp = () => {
      refreshService.refreshPage({});
    };

    const updateInterval = $interval(checkForUpdates, config.appVersionCheckInterval);

    self.$onDestroy = () => {
      $interval.cancel(updateInterval);
    }
  }
});