import module from 'module';

module.factory('internetCheckService', function (config) {
  let service = {
    internetConnection: true,

    checkInterval: config.internetCheckInterval/1000,  // in seconds
    retryInterval: 5,  // in seconds

    retrySecondsLeft: this.checkInterval,

    retry: function () {
      this.retrySecondsLeft = 0;
    },

    reportSuccess: function () {
      this.retrySecondsLeft = service.checkInterval;
      service.internetConnection = true;
    }
  };

  return service;
});
