import module from 'module';
import _ from 'lodash';
import BigNumber from "bignumber.js";
import moment from "moment";

const templateUrl = require('./pawn-partial-renewal.template.html');
module.component('customerPawnPartialRenewal', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, customerCache, confirmation, notification, authentication,
                        workingDaysCache, pawnTagCache, popup, newPawnService, branchService, command) {
    const that = this;
    that.banks = [];

    // form parameters
    that.remainingAmount = null;
    // new pawn owner
    that.newOwner = null;
    // partial renewal command input object
    that.commandInput = {
      changeOwner: false,
      newOwnerId: null,
      productId: null,
      principalPayment: null,
      totalPayment: null,
      denominationBundle: null
    };

    const customerId = $route.current.params['customerId'];
    const pawnId = $route.current.params['pawnId'];

    that.currentBranch = authentication.context.branchId;

    that.$onInit = async () => {
      const [pawns, tags, workingDays, branches] = await Promise.all([
        customerCache.pawns(customerId).toPromise(),
        pawnTagCache.toPromise(),
        workingDaysCache.toPromise(),
        branchService.toPromise()
      ]);

      that.amount = 0;
      that.tags = tags;
      await that.calculate();

      that.pawn = _.find(pawns, (l) => l.id == pawnId);
      that.commandInput.productId = that.pawn.id;
      that.interbranch = that.currentBranch !== that.pawn.branchId;

      that.currentBranchWorkingDay = _.find(workingDays, {branchId: that.currentBranch});
      that.pawnBranchWorkingDay = _.find(workingDays, {branchId: that.pawn.branchId});
      that.currentBranchName = _.find(branches, {id: that.currentBranchWorkingDay.branchId}).name;
      that.pawnBranchName = _.find(branches, {id: that.pawnBranchWorkingDay.branchId}).name;
      that.hasDifferentPostingDates = !moment(that.currentBranchWorkingDay.postingDate).isSame(moment(that.pawnBranchWorkingDay.postingDate));
    };

    that.setNewOwner = (customer) => {
      that.newOwner = customer;
      if (customer) that.commandInput.newOwnerId = customer.customerId;
    };

    that.removeNewOwner = () => {
      that.newOwner = null;
      that.commandInput.newOwnerId = null;
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/pawns/${pawnId}`);

    that.calculate = async () => {
      that.changed = false;

      try {
        that.calculation = await http.post(`/products/pawns/${pawnId}/partial-renewal/calculate`, {
          productId: pawnId,
          amount: that.amount
        }).toPromise();

        that.supportedPawnTagIds = newPawnService.getSupportedTagIds(that.calculation.tagId, that.tags);
        that.commandInput.principalPayment = that.amount;
        that.commandInput.totalPayment = that.calculation.total;
        that.principalBalance = that.calculation.principalBalance;
      } catch (error) {
        popup({
          header: 'Error',
          text: error.errorMessage
        });
      }
    };

    that.recalculateFieldsValues = (principalChanged) => {
      if (principalChanged) {
        that.amount = new BigNumber(that.pawn.principalBalance)
          .minus(that.principalBalance)
          .round(2)
          .toNumber();
      } else {
        that.principalBalance = new BigNumber(that.pawn.principalBalance)
          .minus(that.amount)
          .round(2)
          .toNumber();
      }
      that.changed = true;
    };

    that.renewPartially = async () => {
      const proceed = await confirmation(`Do you want to renew the pawn by cash? Total cash that needs to be paid: ${that.calculation.total} PHP`);
      if (!proceed) {
        return;
      }

      const response = await command.execute('PartialRenewPawn', that.commandInput).toPromise();
      if (response.approvalRequired) {
        return;
      }

      if (that.commandInput.newOwnerId) {
        customerCache.pawns(that.commandInput.newOwnerId).refetch();
      }
      customerCache.pawns(customerId).refetch();
      newPawnService.removeAll();

      $location.path(`/customer/${that.commandInput.newOwnerId || customerId}/pawns/${response.output.id}`);
    };
  }
});
