import module from 'module';

const templateUrl = require('./stop-order-list.template.html');
module.component('stopOrderList', {
  templateUrl: templateUrl,
  controller: function () {
    const that = this;

    // By default set display mode to 'ACTIVE'
    that.displayMode = 'ACTIVE';

    that.setDisplayMode = (mode) => {
      that.displayMode = mode;
    }
  }
});
