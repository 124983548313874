import module from 'module';

import templateUrl from './approval-branch-predicate.template.html';

class ApprovalBranchPredicate {

  constructor($scope) {
    this.$scope = $scope;
    this.type = 'APPROVAL_BRANCH'
  }

  $onChanges() {
    if (!this.predicates) {
      this.value = null;
    } else {
      this.value = this.predicates[this.type];
    }
  }

  valueChanged() {
    this.predicates[this.type] = this.value;
  };
}


module.component('approvalBranchPredicate', {
  templateUrl,
  bindings: {
    predicates: '='
  },
  controller: ApprovalBranchPredicate
});