export const storage = () => {
  return window[storageName()] || sessionStorage;
};

let cachedStorageName = null;
export const storageName = () => {
  if (cachedStorageName) {
    return cachedStorageName;
  }

  const storageElement = document.querySelector('[data-nx-token-storage]');
  if (storageElement) {
    cachedStorageName = storageElement.dataset.nxTokenStorage;
  } else {
    cachedStorageName = 'sessionStorage';
  }

  return cachedStorageName;
};