import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('commandMetadataCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/command/metadata'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.commandMetadata,
    cacheName: 'commandMetadata'
  })
);
