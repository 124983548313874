import module from 'module';
import templateUrl from './exchange-rates.template.html';
import '../forex-panel.style.less';

class ExchangeRates {
  constructor($route, forexCache, notification, authentication, branchService, command, http) {
    this.$route = $route;
    this.forexCache = forexCache;
    this.notification = notification;
    this.authentication = authentication;
    this.branchService = branchService;
    this.command = command;
    this.http = http;
  }

  async $onInit() {
    await Promise.all([this.initExchangeRates(), this.initBranches()]);
    this.updateNotExcludedBranches();
  }

  updateNotExcludedBranches() {
    const excludedBranches = this.groupRates.flatMap(group => group.branchIds);
    this.notExcludedBranches = this.branches.filter(br => !excludedBranches.includes(br.id));
  }

  async initExchangeRates() {
    const exchangeRateGroupsPromise = this.http.get('/forex/exchange-rate-groups').toPromise();
    const exchangeRatesResponsePromise = this.forexCache.withParam(this.authentication.context.branchId).toPromise();
    const [exchangeRateGroups, exchangeRatesResponse] =
      await Promise.all([exchangeRateGroupsPromise, exchangeRatesResponsePromise]);
    this.branchExchangeRates = exchangeRatesResponse.exchangeRatesInBranches[this.authentication.context.branchId];

    this.notExcluded = exchangeRateGroups.find(group => !group.branchIds || group.branchIds.length === 0);
    this.groupRates = exchangeRateGroups.filter(group => group.branchIds && group.branchIds.length > 0);
  }

  async initBranches() {
    this.branches = await this.branchService.toPromise();
    this.branchIdToName = this.branches.reduce((a, b) => {
      a[b.id] = b.name;
      return a;
    }, {});
  }

  edit() {
    this.editing = true;
  }

  addGroup() {
    const blankExchangeRates = angular.copy(this.notExcluded.exchangeRates);
    blankExchangeRates.forEach(er => {
      er.id = null;
      er.branchId = null;
      er.exclusion = true;
      er.buyRate = 0;
      er.sellRate = 0;
    });

    this.groupRates.push({
      branchIds: [],
      exchangeRates: blankExchangeRates
    });

    this.updateNotExcludedBranches();
  }

  removeGroup(group) {
    this.groupRates = this.groupRates.filter(g => g !== group);
    this.updateNotExcludedBranches();
  }

  appendBranchToGroup(group) {
    group.branchIds.push(group.branchToAppend.id);
    group.branchToAppend = null;
    this.updateNotExcludedBranches();
  }

  removeBranchFromGroup(group, branchId) {
    group.branchIds = group.branchIds.filter(id => id !== branchId);
    this.updateNotExcludedBranches();
  }

  rateGroupsValid() {
    const emptyGroupsCount = this.groupRates.filter(group => group.branchIds.length === 0).length;

    if (emptyGroupsCount > 0) {
      return false;
    }

    return true;
  }

  cancel() {
    this.initExchangeRates();
    this.editing = false;
  }

  async save() {
    this.request = this.createRequestObject();

    await this.command.execute('UpdateAllExchangeRates', {exchangeRateGroups: this.request}).toPromise();

    this.forexCache.withParam(this.authentication.context.branchId).refetch();
    this.editing = false;
    this.$route.reload();
  }

  createRequestObject() {
    const nonUSDRates = this.notExcluded.exchangeRates.filter(er => er.currency.isoCode !== 'USD');
    const allBranchesRates = _.cloneDeep(this.notExcluded);
    const clonedGroupRates = _.cloneDeep(this.groupRates);

    // copy non USD exchange rates
    clonedGroupRates.flatMap(group => group.exchangeRates)
      .forEach(rate => {
      if (rate.currency.isoCode === 'USD') return;

      const rateToCopy = nonUSDRates.find(er => er.currency.id === rate.currency.id);
      rate.buyRate = rateToCopy.buyRate;
      rate.sellRate = rateToCopy.sellRate;
    });

    return [allBranchesRates, ...clonedGroupRates];
  }
}

module.component('exchangeRates', {
  templateUrl,
  controller: ExchangeRates
});
