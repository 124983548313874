import module from 'module';
import $ from 'jquery';

const templateUrl = require('./file-upload-new.template.html');
module.component('fileUploadNew', {
  templateUrl,
  bindings: {
    'onFilesAdded': '&',
    'validate': '<',
    'ngDisabled': '<',
    'enableWebcam': '<',
    'enableDropFile': '<',
    'enableSelectFile': '<',
    'showWebCamHandler': '&'
  },
  controller: function () {
    const that = this;
    that.webcamVisible = false;

    const modalElement = () =>
      $('.file-upload-new__modal').find('.modal');

    that.$onInit = () => {
      // calls showWebcam function from parent component
      // use handler when you want to use webcam functionality only.
      if (that.showWebCamHandler) {
        that.showWebCamHandler({
          handler: that.showWebcam
        });
      }
    };

    that.showWebcam = e => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }

      that.webcamVisible = true;

      requestAnimationFrame(() => {
        modalElement().modal('show');
        modalElement().on('hidden', () => {
          that.webcamVisible = false;
        });
      });
    };

    that.hideWebcam = () => {
      modalElement().modal('hide');
      that.webcamVisible = false;
      that.uploadForm.$setPristine();
      that.uploadForm.$setUntouched();
    };

    that.onPhotoTaken = blob => {
      that.hideWebcam();

      blob.name = 'cameraPicture';
      that.addFiles([blob]);
    };

    that.addFiles = files => {
      that.onFilesAdded({files,});
    };
  }
});
