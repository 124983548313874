import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('pawnMetalRateCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/products/pawns/metal-rates?type=PAWNED'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.pawnMetalRates,
    cacheName: 'pawnMetalRates'
  }));
