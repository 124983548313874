import 'rxjs/add/operator/combineLatest';

import module from 'module';
import './security-general.style.less';


import templateUrl from './security-general.template.html';

class SecurityGeneral {
  constructor(systemPropertyCache, commandAccessChecker, $route, $filter, command, rolesCache) {
    this.command = command;
    this.systemPropertyCache = systemPropertyCache;
    this.$route = $route;

    this.properties = [];
    this.changeAllowed = false;

    Promise.resolve()
      .then(async () => {
      const systemProperties = await systemPropertyCache.toPromise();
      const roles = await rolesCache.toPromise();
      this.properties = systemProperties.map(({code, value,}) => {
        if (code === 'USER_IDLE_TIME_MINUTES') {
          return {
            code,
            type: 'number',
            min: 0,
            max: 1440,
            unit: 'min',
            value: parseInt(value),
          }
        } else if (code === 'USER_ALLOWED_FAILED_LOGIN_ATTEMPTS') {
          return {
            code,
            type: 'number',
            min: 1,
            value: parseInt(value)
          }
        } else if (code === 'REQUIRED_CASH_ROLES') {
          return {
            code,
            type: 'multiselect',
            multiValue: value.split(',')
              .filter(val => val) // skip non existent roles
              .map(val => parseInt(val)), // don't pass parseInt directly, as its second argument is radix...
            possibleValues: roles.map(role => ({
              value: role.id,
              label: role.description ? role.description : $filter('prettyEnum')(role.name)
            }))
          }
        } else if (code === 'PASSWORD_MIN_LENGTH') {
          return {
            code,
            type: 'number',
            min: 1,
            value: parseInt(value)
          }
        } else if (code === 'PASSWORD_COMPLEXITY') {
          return {
            code,
            type: 'multiselect',
            multiValue: value.split(',')
              .filter(val => val), // skip non existent values
            possibleValues: ['ONE_NUMBER', 'ONE_LOWERCASE_LETTER', 'ONE_UPPERCASE_LETTER', 'ONE_SPECIAL_CHARACTER', 'NEVER_USED_BEFORE']
              .map(value => ({
                value,
                label: $filter('translate')(value, 'PASSWORD_COMPLEXITY')
              }))
          }
        } else if (code === 'EXPIRE_PASSWORD_AFTER_NR_OF_DAYS') {
          return {
            code,
            type: 'number',
            value: parseInt(value),
            tooltip: 'Value of zero disables password expiration',
            min: 0
          }
        } else if (code === 'ENABLE_CUSTOMER_VIEWING_RESTRICTIONS') {
          return {
            code,
            type: 'select',
            value: value,
            possibleValues: [{
              value: 'TRUE',
              label: 'Yes',
            }, {
              value: 'FALSE',
              label: 'No'
            }]
          }
        }

        return null;
      }).filter(val => val); // remove nulls
    });

    this.checkerSubscription = commandAccessChecker.canExecuteCommandObservable()
      .subscribe(canExecuteCommand => this.changeAllowed = canExecuteCommand('ChangeSecurityProperties'));
  }

  serializePropertyValue(property) {
    if('value' in property) {
      return property.value;
    }

    if('multiValue' in property) {
      return  property.multiValue.join(",");
    }

    throw Error("Unsupported serializable property: " + property);
  }

  save() {
    const codeToValue = this.properties.reduce((acc, property) => ({
      [property.code]: this.serializePropertyValue(property),
      ...acc
    }), {});

    this.command.execute('ChangeSecurityProperties', {codeToValue,})
      .success(done => {
        this.systemPropertyCache.refetch();
        this.$route.reload();
      });
  }

  $onDestroy() {
    if (this.checkerSubscription) {
      this.checkerSubscription.unsubscribe();
    }
  }
}

module.component('securityGeneral', {
  templateUrl,
  controller: SecurityGeneral
});
