import module from 'module';
import _ from 'lodash';

import templateUrl from './security.template.html';

class SecurityComponent {
  constructor(userCache, rolesCache, authentication) {
    this.userCache = userCache;
    this.rolesCache = rolesCache;
    this.authentication = authentication;
  }

  async $onInit() {
    const [users, roles] = await Promise.all([this.userCache.toPromise(), this.rolesCache.toPromise()]);

    const currentRealUser = users.find(u => u.id === this.authentication.context.realUserId);
    console.log(this.authentication.context, currentRealUser)
    this.roles = _.filter(roles, r => currentRealUser.technical || !r.technical);
  }
}

module.component('security', {
  templateUrl,
  controller: SecurityComponent
});
