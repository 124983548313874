import module from 'module';
import LocalCache from 'shared/util/localCache';
import MemoryFactory from 'shared/util/localCacheMemoryFactory'


module.factory('loanCache', function (http) {
  let that = this;
  let service = {};

  that.cacheMap = {};

  function retrieveCacheObject(loanId, cacheName, cacheOptions) {
    // adds default params
    cacheOptions['cacheFactory'] = MemoryFactory;
    cacheOptions['cacheName'] = cacheName;

    if (!that.cacheMap[cacheName]) {
      that.cacheMap[cacheName] = new LocalCache(cacheOptions);
    }
    return that.cacheMap[cacheName];
  }

  service.loan = (loanId) =>
    retrieveCacheObject(loanId, 'loan' + loanId, {
      provider: () => http.get(`/products/loans/${loanId}?includeAmortization=true`)
    });

  service.refetch = () => {
    let keys = Object.keys(that.cacheMap);
    for (let key of keys) {
      that.cacheMap[key].refetch();
    }
  };

  return service;
});