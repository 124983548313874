import module from 'module';

import templateUrl from './query-param-guard.template.html';

/**
 * Angularjs preserves query param between nagivations.
 * If you would like to intercept outbound routing query params, pass queryParamFilter function to this component.
 */
class QueryParamGuard {
  constructor($scope, $location) {
    this.eventSubscription = $scope.$on('$routeChangeStart', (evt, next, current) => {
      if (current && next && current.originalPath !== next.originalPath) {
        const newParams = this.queryParamFilter({newPath: next.originalPath, oldParams: $location.search()});
        $location.search(newParams).replace();
      }
    });
  }

  $onDestroy() {
    this.eventSubscription();
  }
}

module.component('queryParamGuard', {
  templateUrl,
  transclude: true,
  bindings: {
    // function called with parameters ({newPath, params={string:string)}
    // expected to return object of type {string:string{ of new parameters to set
    queryParamFilter: '<'
  },
  controller: QueryParamGuard
});
