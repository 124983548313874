import '../common/edit-loan-amortization.component'
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/combineAll';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import {Subscription} from 'rxjs/Subscription';
import CompositeRequest from 'shared/common/compositeRequest';
import BigNumber from 'bignumber.js';

import _ from 'lodash';

import module from 'module';

const templateUrl = require('./customer-loans-edit-amortization.template.html');
module.component('customerLoansEditAmortization', {
  templateUrl: templateUrl,
  controller: function ($route, $location, dict, customerCache,
                        customerService, fileService, loanService, confirmation, command) {
    let that = this;

    let loanId = Number($route.current.params['loanId']);
    let customerId = Number($route.current.params['customerId']);

    const subscription = new Subscription();

    subscription.add(customerCache.loans(customerId).toObservable()
      .subscribe(loans => {
        if (loanId) {
          that.loan = _.find(loans, el => el.id === loanId);
          that.clonedLoan = angular.copy(that.loan);
        }
      }));

    subscription.add(customerCache.profile(customerId).toObservable()
      .subscribe(profile => that.profile = profile)
    );


    that.redirectBack = () => {
      $location.path(`/customer/${customerId}/loans/${loanId}`)
    };

    that.save = () => {
      confirmation('Do you want to update the amortization schedule?', () => {
        let loanCopy = angular.copy(that.clonedLoan);
        updateInterest(loanCopy);

        loanCopy.amortizationSchedule = that.clonedLoan.amortizationSchedule;

        command.execute('EditLoanAmortization', {
          productId: loanCopy.id,
          interestAmount: loanCopy.interestAmount,
          interestBalance: loanCopy.interestBalance,
          amortizationSchedule: loanCopy.amortizationSchedule.list
        }).success(() => {
          customerCache.loans(customerId).refetch();
          that.redirectBack();
        });
      });
    };

    function updateInterest(loan) {
      let amount = new BigNumber(0);
      let balance = new BigNumber(0);
      for (let a of loan.amortizationSchedule.list) {
        amount = amount.plus(a.interestAmount);
        balance = balance.plus(a.interestBalance);
      }

      loan.interestAmount = amount.toNumber();
      loan.interestBalance = balance.toNumber();
    }

    that.$onDestroy = () => {
      subscription.unsubscribe();
    }
  }
});