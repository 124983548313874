import module from 'module';

import templateUrl from './working-day-cache-refresher.template.html';

class WorkingDayCacheRefresher {
  constructor(workingDayService, refreshService) {
    const workingDayKey = "nx__nonvolatile__working_day_id";
    this.workingDayId = localStorage.getItem(workingDayKey);
    this.sub = workingDayService.toObservable()
      .subscribe(workingDay => {
        const workingDayId = String(workingDay.id);
        const oldWorkingDay = this.workingDayId;
        if(oldWorkingDay && workingDayId !== oldWorkingDay) {
          console.warn("Refreshing caches due to working day change.");
          refreshService.clearCache();
          this.workingDayId = workingDayId;
        }

        localStorage.setItem(workingDayKey, workingDayId);
      });
  }

  $onDestroy() {
    this.sub.unsubscribe();
  }
}

module.component('workingDayCacheRefresher', {
  templateUrl,
  controller: WorkingDayCacheRefresher
});
