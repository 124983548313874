import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('pawnItemDefectTypeCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get(`/products/pawns/items/defect-types`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.pawnItemDefectTypeConfig,
    cacheName: `pawnItemDefectTypeConfig`
  })
);
