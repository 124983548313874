import module from 'module';
import _ from 'lodash';
import moment from 'moment';

import { Observable } from 'rxjs/Observable';
import "rxjs/add/observable/zip";

const templateUrl = require('./create-lock.template.html');

class CreateProductLock {
  constructor($route, $location, $filter, confirmationTemplate, customerCache, branchService, authentication, productLockService, postingDateService) {

    this.$location = $location;
    this.$filter = $filter;
    this.confirmationTemplate = confirmationTemplate;
    this.customerCache = customerCache;
    this.productLockService = productLockService;

    this.lock = { autoRelease: false };
    // TODO: Remove comment signs when freeze functionality will be implemented.
    this.lockTypes = [
      { label: 'Blockade', type: 'BLOCKADE' },
      // {label: 'Freeze', type: 'FREEZE'}
    ];

    this.accountId = $route.current.params['accountId'];
    this.depositId = $route.current.params['depositId'];
    this.customerId = $route.current.params['customerId'];
    this.productId = this.accountId || this.depositId;

    
    const fetchProducts = this.accountId ?
      customerCache.depositAccounts(this.customerId).toPromise() : 
      customerCache.termDeposits(this.customerId).toPromise();
    
    postingDateService.getCurrentUserBranchPostingDate().then(date => this.postingDate = date);
    fetchProducts.then(products => this.product = _.find(products, (a) => Number(a.id) === Number(this.productId)));
  }

  create() {
    let data = {
      productId: this.product.id, 
      type: this.lock.type, 
      creationRemarks: this.lock.creationRemarks
    };

    if (this.lock.autoRelease) {
      data = { autoReleaseOn: moment(this.lock.autoReleaseOn).format('YYYY-MM-DD'), ...data };
    }

    this.productLockService.createLock(this.product, data, () => {
      this.customerCache.depositAccounts(this.customerId).refetch();
      this.redirectBack();
    });
  }

  redirectBack() {
    const back = this.accountId ? 'accounts' : 'term-deposits';
    this.$location.path(`/customer/${this.customerId}/${back}/${this.productId}`);
  }
}

module.component('createLock', {
  templateUrl,
  controller: CreateProductLock
});
