import module from 'module';
import {BigNumber} from 'bignumber.js';
import _ from 'lodash';
import {MODE} from '../stock-items-inventory-view.component';

import './stock-items-sell-summary.style.less';
import templateUrl from './stock-items-sell-summary.template.html';
import {addAccountLabels} from "../../../general-ledger/common/gl.utils";

class StockItemsSellSummary {

  constructor($scope, $routeParams, $location, $filter, $route, command, http, authentication, notification,
              confirmation, branchService, popup, systemPropertyCache, glMappingsService, actionCategoryCache,
              propertyConfigService) {
    this.$scope = $scope;
    this.$routeParams = $routeParams;
    this.$location = $location;
    this.$filter = $filter;
    this.$route = $route;
    this.command = command;
    this.http = http;
    this.authentication = authentication;
    this.notification = notification;
    this.confirmation = confirmation;
    this.branchService = branchService;
    this.popup = popup;
    this.systemPropertyCache = systemPropertyCache;
    this.glMappingsService = glMappingsService;
    this.actionCategoryCache = actionCategoryCache;
    this.cfg = propertyConfigService;
  }

  async $onInit() {
    this.MODE = MODE;
    this.request = {
      discount: 0,
      finalPrice: 0,
      officialReceiptNumber: null
    };

    const [properties, ledgerAccounts, categories] = await Promise.all([
      this.systemPropertyCache.toPromise(),
      this.glMappingsService.accounts.toPromise(),
      this.actionCategoryCache.toPromise()
    ]);

    this.supportsLedger = _.find(properties, {code: 'LEDGER_SUPPORT'});
    this.categories = categories.filter(cat => cat.actionType === 'CASH_IN' && cat.code === 'SELL_PAWN_ITEM');
    // Select the first and only category
    this.transaction = {
      categoryId: this.categories[0].id
    }
    this.ledgerAccounts = addAccountLabels(ledgerAccounts);

    this.setUpDefaultDiscountAndFinalPriceToEveryItem();
    this.recalculateRequestTotalFinalPrice();
    this.onCategoryChange();
  }

  setUpDefaultDiscountAndFinalPriceToEveryItem() {
    this.items.forEach(i => {
      i.itemId = i.id;
      i.discount = 0;
      i.extraDiscount = 0;
    })
  }

  onDiscountChange(item) {
    this.recalculateRequestTotalFinalPrice();
  }

  recalculateRequestTotalFinalPrice() {
    const totalSellingPrice = this.sumTotalPrice(this.items, 'sellingPrice');
    const discount = this.sumTotalPrice(this.items, 'discount');
    const extraDiscount = this.sumTotalPrice(this.items, 'extraDiscount');
    this.request.discount = extraDiscount.toNumber();
    this.request.finalPrice = totalSellingPrice
      .minus(discount)
      .minus(extraDiscount)
      .toNumber();
    this.onCategoryChange(); // refresh units
  }

  sumTotalPrice(items, property) {
    return items.map(entry => new BigNumber(entry[property]))
      .reduce((a, b) => a.plus(b), new BigNumber(0));
  }

  onCategoryChange() {
    this.remarksRequired = false;
    if (this.transaction.categoryId) {
      const category = _.find(this.categories, {id: this.transaction.categoryId});
      this.remarksRequired = category && category.remarksRequired;

      if (!this.supportsLedger) {
        // no need to fill units
        return;
      }

      if (category.ledgerAccountFullCode) {
        const fixedAccount = _.find(this.ledgerAccounts, {fullCode: category.ledgerAccountFullCode});

        this.blockedUnits = [
          {
            accountCode: fixedAccount.fullCode,
            amount: this.request.finalPrice,
            entryType: this.entryType === 'CREDIT' ? 'DEBIT' : 'CREDIT'
          }
        ];
      } else {
        this.blockedUnits = [];
      }
    }
  };

  setCustomer(customer) {
    this.customer = customer;
  }

  cancel() {
    this.mode = null;
  }

  async send() {
    const confirm = await this.confirmation(`Do you want to sell ${this.items.length} items for total of ${this.$filter('php')(this.request.finalPrice)}?`);
    if (!confirm) {
      return;
    }

    const response = await this.command.execute(
      this.mode.command,
      {
        ...this.request,
        items: this.items,
        customerId: this.customer.customerId,
        branchId: this.items[0].branchId,
        units: this.supportsLedger ? this.transaction.units : null
      }
    ).toPromise();

    if (response.approvalRequired) {
      return;
    }

    this.$route.reload();
  }
}

module.component('stockItemsSellSummary', {
  templateUrl: templateUrl,
  bindings: {
    mode: '=',
    items: '<'
  },
  controller: StockItemsSellSummary
});
