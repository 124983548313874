import module from 'module';

import './customer-access.style.less';
import templateUrl from './customer-access.template.html';

/**
 * Displays information on customer access. Allows for requesting one and checking current status.
 * When access was granted, hides itself.
 */
class CustomerAccess {
  constructor(customerAccessService) {
    this.customerAccessService = customerAccessService;
  }

  $onInit() {
    this.hasFullAccess = () => this.customerAccessService.ofType(this.accessType).fullAccess();
    this.requestedAccess = () => this.customerAccessService.ofType(this.accessType).requestedAccess();
    this.accessRejected = () => this.customerAccessService.ofType(this.accessType).accessRejected();
    this.requestAccess = () => this.customerAccessService.ofType(this.accessType).requestAccess();
    this.checkAccess = () => this.customerAccessService.ofType(this.accessType).checkAccess();

    this.customerAccessService.ofType(this.accessType).checkAccess();
  }
}

module.component('customerAccess', {
  templateUrl,
  bindings: {
    accessType: '<'
  },
  controller: CustomerAccess
});
