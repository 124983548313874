import module from 'module';
import templateUrl from './issue-certificate.template.html';

class IssueCertificate {
  constructor($location, customerCache, productDefinitionService, confirmationTemplate, command,
              modalPrintPreviewService, certificateCache, printCertificateService) {
    this.$location = $location;
    this.customerCache = customerCache;
    this.productDefinitionService = productDefinitionService;
    this.confirmationTemplate = confirmationTemplate;
    this.command = command;
    this.modalPrintPreviewService = modalPrintPreviewService;
    this.certificateCache = certificateCache;
    this.printCertificateService = printCertificateService;
  }

  $onInit() {
    this.fillProductData();
  }

  async fillProductData() {
    this.productsCache = this.customerCache.termDeposits(this.customerId);

    const [products, productDefinitions] = await Promise.all([this.productsCache.toPromise(), this.productDefinitionService.toPromise()]);

    const product = products.find(product => Number(product.id) === Number(this.productId));
    const productDefinition = productDefinitions.find(definition => Number(definition.id) === Number(product.definitionId));

    this.product = {...product, productName: productDefinition ? productDefinition.productName : '-'};

    this.certificate = { productId: this.productId };
    this.activeCertificate = await this.certificateCache.withParam(this.productId).toPromise();
  }

  issueCertificate() {
    this.confirmationTemplate({
      question: `Do you want to issue a new certificate?`,
      warning: this.activeCertificate ? 'Active certificate will be replaced' : null,
      details: this.getDetails(),
      yesCallback: () => {
        this.command.execute('CreateTermDepositCertificate', this.certificate)
          .success(() => {
            this.printCertificateService.printFor(this.productId).then(() => this.refreshCacheAndRedirectBack());
          });
      }
    });
  }

  refreshCacheAndRedirectBack() {
    this.productsCache.refetch();
    this.certificateCache.withParam(this.productId).evict();
    this.redirectBack();
  }

  getDetails() {
    const details = [];
    if (this.activeCertificate) {
      details.push({label: 'Active passbook number', description: this.activeCertificate.number});
    }
    details.push({label: 'New passbook number', description: this.certificate.number});
    return details;
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/term-deposits/${this.productId}`);
  }
}

module.component('issueCertificate', {
  templateUrl,
  bindings: {
    customerId: '<',
    productId: '<'
  },
  controller: IssueCertificate
});