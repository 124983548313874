import module from 'module';
import _ from 'lodash';

const templateUrl = require('./reset-ledger-balance.template.html');

module.component('resetLedgerBalance', {
  templateUrl,
  controller: function (branchService, confirmationTemplate, command, $filter, $route) {
    const that = this;

    that.command = {};

    const branchSub = branchService.toObservable().subscribe(branches => {
      that.branches = branches;
    });

    that.separators = [
      {value: ';', label: 'Semicolon (;)'},
      {value: ',', label: 'Comma (,)'}
    ];

    that.execute = () => {
      const branchId = that.command.branchId;
      const branch = _.find(that.branches, b => b.id === branchId);
      confirmationTemplate({
        question: 'Do you want to reset the GL balances?',
        details: [
          {label: 'Branch', description: branch.name}
        ],
        warning: 'All values for the given branch will be overwritten. <br>This action cannot be reverted.',
        yesCallback: () => {
          command.execute('ResetLedgerBalanceFromCSV', that.command)
            .success(() => $route.reload());
        }
      });
    };

    that.$onDestroy = () => {
      branchSub.unsubscribe();
    };

  }
});
