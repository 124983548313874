import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('productStatusMigrationCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get(`/products/status-migrations`, {nxLoaderSkip: true}),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.userCounter,
    cacheName: 'productStatusMigration'
  }));
