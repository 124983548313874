import module from 'module';

import templateUrl from './executing-branch-predicate.template.html';

class ExecutingBranchPredicate {

  constructor($scope) {
    this.$scope = $scope;
    this.type = 'EXECUTING_BRANCH';
  }

  $onChanges() {
    if (!this.predicates) {
      this.value = null;
    } else {
      this.value = this.predicates[this.type];
    }
  }

  valueChanged() {
    this.predicates[this.type] = this.value;
  };
}

module.component('executingBranchPredicate', {
  templateUrl,
  bindings: {
    predicates: '='
  },
  controller: ExecutingBranchPredicate
});
