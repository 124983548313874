import module from 'module';

import _ from 'lodash';
import {Observable} from 'rxjs/Observable'
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/first';
import { NgTableParams } from 'ng-table/ng-table.js';
import {addAccountLabels} from "../../../general-ledger/common/gl.utils";

const templateUrl = require('./gl-transaction-mapping.template.html');
module.component('glTransactionsMapping', {
  templateUrl,
  controller : function ($filter, $q, glTagsDescriptionCache, http, glMappingsService,
                         ledgerTagCache, feeDefinitionsCache, notification) {
    const that = this;

    that.tagsDescription = [];
    that.tdKeys = [];
    that.ledgerAccounts = [];
    that.ledgerTags = [];
    that.ledgerTagTypes = [];

    that.emptyAccountConfig = {
      placeholder: 'Select account'
    };

    that.glFilter = {
      filterName: undefined
    };

    that.selectConfig = {
      placeholder: 'Select tag type',
      searchField: ['label'],
      valueField: 'type',
      labelField: 'label',
      maxItems: 1
    };

    const extractTagParam = (tag, paramName) => {
      if (!tag || !tag.params) return null;
      return tag.params[paramName];
    };

    const concatMappingName = (name, description, key) => {
      const separator = " | ";
      if (that.tagsDescription[description][key]) {
        return name.length > 0 ? separator.concat(that.tagsDescription[description][key]) : that.tagsDescription[description][key];
      }
      return  "";
    };

    const createMappingName = tag => {
      let name = "";

      if (that.tagsDescription) {
        if (tag.params.REFERENCE_BRANCH_ID && that.tagsDescription.REFERENCE_BRANCH_ID) {
          name = name.concat(concatMappingName(name, "REFERENCE_BRANCH_ID", tag.params.REFERENCE_BRANCH_ID));
        }

        if (tag.params.DEPOSITORY_ACCOUNT_ID && that.tagsDescription.DEPOSITORY_ACCOUNT_ID) {
          name = name.concat(concatMappingName(name, "DEPOSITORY_ACCOUNT_ID", tag.params.DEPOSITORY_ACCOUNT_ID));
        }

        if (tag.params.ASSET_COUNTER_SUBTYPE_ID && that.tagsDescription.ASSET_COUNTER_SUBTYPE_ID) {
          name = name.concat(concatMappingName(name, "ASSET_COUNTER_SUBTYPE_ID", tag.params.ASSET_COUNTER_SUBTYPE_ID));
        }

        if (tag.params.PRODUCT_TYPE_ID && that.tagsDescription.PRODUCT_TYPE_ID) {
          name = name.concat(concatMappingName(name, "PRODUCT_TYPE_ID", tag.params.PRODUCT_TYPE_ID));
        }

        if (tag.params.PRODUCT_STATUS && that.tagsDescription.PRODUCT_STATUS) {
          name = name.concat(concatMappingName(name, "PRODUCT_STATUS", tag.params.PRODUCT_STATUS));
        }

        if (tag.params.MIS_CODE && that.tagsDescription.MIS_CODE) {
          name = name.concat(concatMappingName(name, "MIS_CODE", tag.params.MIS_CODE));
        }

        if (tag.params.FEE_DEFINITION_ID && that.tagsDescription.FEE_DEFINITION_ID) {
          name = name.concat(concatMappingName(name, "FEE_DEFINITION_ID", tag.params.FEE_DEFINITION_ID));
        }
      }

      return name;
    };

    const tagsPromise = http.get('/ledger/tags').toPromise();

    const tagsObservable = Observable.fromPromise(tagsPromise);

    const getDataPromise = tagsObservable.combineLatest(feeDefinitionsCache.toObservable(), glTagsDescriptionCache.toObservable(),
      (tags, feeDefinitions, tagDesc) => {

        that.tagsDescription = tagDesc;
        return _.map(_.sortBy(tags, "tagType"), (tag) => {
          const feeDefinitionId = extractTagParam(tag, 'FEE_DEFINITION_ID');
          if (feeDefinitionId && Number(feeDefinitionId) > 0) {
            const feeDefinition = _.find(feeDefinitions, {id: Number(feeDefinitionId)});
            if (feeDefinition) tag['feeDefinition'] = feeDefinition;
          }
          tag.mappingName = createMappingName(tag);
          tag.filterName = tag.tagType + " | " + tag.mappingName;

          that.ledgerTags = tags;
          that.ledgerTagTypes = _.map(_.uniq(_.map(that.ledgerTags, 'tagType')), (tagType) => {
            return {type: tagType, label: $filter('prettyEnum')(tagType)}
          });

          return tag;
        });
      }).first().toPromise();

    const getData = params => getDataPromise.then(tags => {

      tags = $filter('filter')(tags, params.filter());

      params.total(tags.length);

      return tags.slice((params.page() - 1) * params.count(),
        params.page() * params.count());
    });

    that.tableConfig = new NgTableParams({
      page: 1,
      count: 10,
      filter: that.glFilter,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      total: that.ledgerTags.length,
      getData,
    });

    const accountSub = glMappingsService.accounts.toObservable().subscribe((accounts) => {
      that.ledgerAccounts = addAccountLabels(accounts);
    });

    that.saveTag = (tag) => {
      const command = {
        tagId: tag.id,
        delegate: tag.delegate,
        accountCode: tag.accountCode,
        comment: tag.comment,
        delegateTagType: tag.delegateTagType
      };
      const commands = [command];

      // Return GL modification promise
      return http.put('/ledger/tags?batch=true', commands)
        .success(() => {
          notification.show("Success", "GL mappings updated");
          ledgerTagCache.refetch();
        })
        .error(() => {
          notification.show("Error", "Failed to update GL mappings")
        });
    };

    that.onDelegateChange = (tag) => {
      tag.accountCode = null;
      tag.delegateTagType = tag.delegate ? 'UNMAPPED' : null;
    };

    that.$onDestroy = () => {
      accountSub.unsubscribe();
    };
  }
});
