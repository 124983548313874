import module from 'module';
import ParameterizedLocalCache from 'shared/util/parameterized-local-cache';

module.factory('pawnCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (pawnId) => http.get(`/products/pawns/${pawnId}`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.pawn,
    cacheName: 'pawnCache'
  })
);
