import module from 'module';
import _ from 'lodash';

const templateUrl = require('./loan-transfer-payment.template.html');
module.component('customerLoansTransferPayment', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, command, customerCache, loanProductsCache, depositAccountService, confirmation, notification,
                        modalPrintPreviewService) {
    let that = this;

    that.loan = null;
    that.paymentMap = {};

    let customerId = $route.current.params['customerId'];
    let loanId = $route.current.params['loanId'];

    const s1 = customerCache.loans(customerId).toObservable()
      .combineLatest(loanProductsCache.toObservable(), (loans, loanTypes) => {
        if (!loans) that.redirectBack();
        return loans.map(loan => {
          // add loanProduct to loan object
          const type = _.find(loanTypes, {id: loan.typeId});
          return Object.assign(loan, {
            loanType: type
          });
        });
      })
      .subscribe(loans => {
        that.loan = _.find(loans, (l) => l.id === Number(loanId));
      });

    const s2 = customerCache.depositAccounts(customerId).toObservable()
      .combineLatest(depositAccountService.toObservable(), (accounts, accountTypes) => {
        for (let a of accounts) {
          const type = _.find(accountTypes, (type) => type.id === a.typeId);
          if (type) {
            a.name = `${type.productDefinition.productName} [${a.productNumber}]`;
          }
        }
        return accounts;
      }).subscribe(accounts => that.accounts = accounts);

    that.redirectBack = () => $location.path(`/customer/${customerId}/loans/${loanId}`);

    that.pay = () => {
      confirmation(`Do you want to pay ${that.paymentRequest.amount} PHP?`, () => {
        command.execute('PayLoanByTransfer', {
          productId: loanId,
          accountId: that.selectedAccount.id,
          commandPurpose: 'LOAN_PAYMENT',
          ...that.paymentRequest
        }, {nxLoaderText: 'Performing operation...'})
          .success((validationResponse) => {
            let operationId = validationResponse.output.operationId;
            customerCache.loans(customerId).refetch();
            customerCache.depositAccounts(customerId).refetch();
            modalPrintPreviewService.show('LOAN_PAYMENT_RECEIPT', {operationId: operationId},
              () => that.redirectBack());
          }).offline(() => {
            customerCache.loans(customerId).refetch();
            customerCache.depositAccounts(customerId).refetch();
            that.redirectBack();
          })
      });
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
      s2.unsubscribe();
    };

    that.print = () => {
      window.print();
    };

  }
});
