import module from 'module';

import templateUrl from './user-login-record.template.html';
import './user-login-record.style.less';

class UserLoginRecord{
  constructor(authentication) {
    this.authentication = authentication;
  }

  $onInit(){
    this.permission = this.authentication.permissions;
  }
}

module.component('userLoginRecord', {
  templateUrl,
  controller: UserLoginRecord
});