import module from 'module';

const templateUrl = require('./update-product-ownership.template.html');

module.component('updateProductOwnership', {
  templateUrl,
  controller: function (confirmationTemplate, popup, $filter, $route, command) {
    const that = this;
    that.customer = {};
    that.command = {};

    that.onProductChange = (productNumber) => {
      that.command.productNumbers = [productNumber];
    };

    that.onCustomerSelect = (customer) => {
      if (!customer) {
        return;
      }
      that.customer = customer;
      that.command.customerId = customer.customerId;
    };

    that.onCustomerRemove = () => {
      that.customer = null;
      that.command.customerId = null;
    }

    that.execute = async () => {
      const confirm = await confirmationTemplate({
        question: 'Do you want to update product owner?',
        details: [
          {label: 'Product number', description: that.productNumber},
          {label: 'Customer name', description: that.customer.name}
        ]});

      if (!confirm) {
        return;
      }

      const response = await command.execute('UpdateProductOwner', that.command);
      if (response.approvalRequired) {
        return;
      }

      await popup({text: `Product owner successfully changed to ${that.customer.name}`});
      $route.reload();
    }
  }
});
