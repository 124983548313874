import module from 'module';
import moment from 'moment';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

module.filter('max', function () {
  return function (input, max) {
    var out = [];
    if (input !== null && input !== undefined) {
      for (var i = 0; i < input.length && i < max; i++) {
        out.push(input[i]);
      }
    }
    return out;
  };
});

//@deprecated
module.filter('percent', function () {
  return function (input, args) {
    if (input !== null && input !== undefined) {
      if (typeof input !== 'number') {
        throw "Percent filter error: '" + input + "' is not a number!";
      }
      return (input / 100) + '%';
    }
    if (_.isArray(args) && args.includes('dashOnFalsy') || args === 'dashOnFalsy') {
      return "-";
    }
    return "";
  };
});

module.filter('php', function (formattingService) {
  return function (input, args /* string | string[] | { [k: string]: boolean | string } */) {
    let negativeBrackets = null;
    let dashOnZero = null;
    let noSuffix = null;
    let currency = null;

    if (args && (_.isArray(args) || typeof args === 'string')) {
      negativeBrackets = args.includes('negativeBrackets');
      dashOnZero = args.includes('dashOnZero');
      noSuffix = args.includes('noSuffix');
    } else if (args /* map */) {
      negativeBrackets = args.negativeBrackets;
      dashOnZero = args.dashOnZero;
      noSuffix = args.noSuffix;
      currency = args.currency;
    }

    const suffix = noSuffix ? '' : ' PHP';

    if (input !== null && input !== undefined) {
      let inputNumber = input;
      if (input instanceof BigNumber) {
        inputNumber = input.toNumber();
      }

      if (typeof inputNumber !== 'number') {
        throw "PHP filter error: '" + inputNumber + "' is not a number!";
      }
      let number = Number(inputNumber);

      if (dashOnZero && number === 0) {
        return '-';
      }

      if (currency) {
        if(negativeBrackets){
          return `(${formattingService.formatNumber((-number).toFixed(2))} ${currency})`;
        }
        return `${formattingService.formatNumber(number.toFixed(2))} ${currency}`;
      }

      if (negativeBrackets && number < 0) {
        return `(${formattingService.formatNumber((-number).toFixed(2))}${suffix})`;
      }
      else {
        return formattingService.formatNumber(number.toFixed(2)) + suffix;
      }
    }
    return "-";
  };
});

module.filter('nxDate', function () {
  return function (input) {
    if (input !== null && input !== undefined) {
      return moment(input).format("YYYY-MM-DD");
    }
    return "";
  };
});

module.filter('prettyDate', function () {
  return function (input) {
    if (input !== null && input !== undefined) {
      return moment(input).format("MMM Do YYYY");
    }
    return "";
  };
});

module.filter('prettyDateTime', function () {
  return function (input) {
    if (input !== null && input !== undefined) {
      return moment(input).format("MMM Do YYYY, h:mm:ss a");
    }
    return "";
  };
});

module.filter('prettyTime', function () {
  return function (input) {
    if (input !== null && input !== undefined) {
      return moment(input).format("h:mm:ss a");
    }
    return "";
  };
});


module.filter('apiDate', function () {
  return function (input) {
    if (input !== null && input !== undefined) {
      return moment(input).format("YYYY-MM-DD");
    }
    return "";
  };
});

/**
 * Can be used in ng-repeat to simulate a loop. Example:
 * ng-repeat="i in 10 | foreach"
 */
module.filter('foreach', function () {
  return function (input) {
    var array = []
    var max = parseInt(input);
    for (var i = 0; i < max; i++)
      array.push(i);
    return array;
  };
});

/**
 * Converts from enum ONE_UGLY_ENUM to: One ugly enum.
 */
module.filter('prettyEnum', function () {
  return function (input) {
    if (!input || input.length < 2) return input;

    const s = input.replace(/_/g, ' ').toLowerCase();
    return s[0].toUpperCase() + s.substring(1);
  };
});

/**
 * Converts from enum PrettyCamelCase to: Pretty camel case.
 */
module.filter('startCase', function () {
  return function (input) {
    let result = _.startCase(input);
    return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
  };
});

/**
 * Converts boolean value to Yes or No
 */
module.filter('prettyBoolean', function () {
  return function (input) {
    return input ? 'Yes' : 'No';
  };
});
