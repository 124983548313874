import module from 'module';
import _ from 'lodash';

const templateUrl = require('./pawn-cash-release.template.html');
module.component('customerPawnCashRelease', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, dict, customerCache, productDefinitionService, confirmation,
                        notification, command) {
    let that = this;
    that.banks = [];

    // form parameters
    that.remainingAmount = null;
    // release command input object
    that.commandInput = {
      productId: null,
      amount: null,
      denominationBundle: null
    };

    let customerId = $route.current.params['customerId'];
    let pawnId = $route.current.params['pawnId'];

    customerCache.pawns(customerId).toObservable().subscribe(pawns => {
      that.pawn = _.find(pawns, (l) => l.id == pawnId);
      that.remainingAmount = that.pawn.releaseAmount;
      that.commandInput.productId = that.pawn.id;
      that.commandInput.amount = that.remainingAmount;
    });

    that.redirectBack = () => $location.path(`/customer/${customerId}/pawns/${pawnId}`);

    that.release = async () => {
      const confirmed = await confirmation(`Did the customer sign all the documents?`);
      if (!confirmed) {
        return;
      }

      const response = await command.execute('PawnReleaseByCash', that.commandInput).toPromise();
      if (response.approvalRequired) {
        return;
      }

      customerCache.pawns(customerId).refetch();
      that.redirectBack();
    };

  }
});
