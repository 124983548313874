import module from 'module';
import moment from 'moment';
import $ from 'jquery';
import _ from 'lodash';
import {NgTableParams} from 'ng-table/ng-table.js';

import './history-miscellaneous-transactions.style.less';

const templateUrl = require('./history-miscellaneous-transactions.template.html');

module.component('historyMiscellaneousTransactions', {
  templateUrl: templateUrl,
  controller: function ($filter, $route, http, modalPrintPreviewService, commandAccessChecker, command, userCounterService, systemDateService) {
    const that = this;
    const reportCode = 'ActionDataReport';
    that.reprintActive = {
      "SSS_MISC_TRANSACTION_VALIDATION_SLIP" : false,
      "MISCELLANEOUS_VALIDATION_SLIP" : false,
    };

    commandAccessChecker.canExecuteCommandPromise()
      .then(canExecuteCommand => that.canExecuteCommand = canExecuteCommand);

    // action to revert command name
    that.revertibleActions = {
      'ADJUSTMENT_IN': 'AdjustmentInRevert',
      'ADJUSTMENT_OUT': 'AdjustmentOutRevert',
      'BRANCH_CASH_TRANSFER_IN': 'BranchCashTransferRevert',
      'BRANCH_CASH_TRANSFER_OUT': 'BranchCashTransferRevert',
      'CASHIER_CASH_OVERAGE': 'CashierCashOverageRevert',
      'CASHIER_CASH_SHORTAGE': 'CashierCashShortageRevert',
      'CHECK_DEPOSIT_TO_BANK': 'CheckDepositToBankRevert',
      'CHECK_TRANSFER_TO_CASHIER': 'CheckTransferToCashierRevert',
      'CASH_WITHDRAWAL_FROM_BANK': 'CashWithdrawalFromBankRevert',
      'CASH_DEPOSIT_TO_BANK': 'CashDepositToBankRevert',
      'CASH_IN': 'CashInRevert',
      'CASH_OUT': 'CashOutRevert',
      'CHECK_IN': 'CheckInRevert',
      'CHECK_OUT': 'CheckOutRevert',
      'FOREX_CASH_IN': 'ForexCashInRevert',
      'FOREX_CASH_OUT': 'ForexCashOutRevert',
      'FOREX_INTERBRANCH_CASH_TRANSFER': 'ForexInterbranchCashTransferRevert',
      'FOREX_PURCHASE': 'PurchaseForeignCurrencyRevert',
      'FOREX_SALE': 'SellForeignCurrencyRevert',
      'REMITTANCE_EXCHANGE': 'RemittanceExchangeRevert',
    };

    that.isRevertibleActionSelected = item => {
      const revertCommandName = that.revertibleActions[item.actionType];
      if(!revertCommandName || moment(item.registrationDate).isBefore(that.branchSystemDate)) {
        return false;
      }

      if(item.status === 'REJECTED') {
        return false;
      }

      if(!item.extraInfo.commandId) {
        return false;
      }

      return that.canExecuteCommand && that.canExecuteCommand(revertCommandName);
    };

    that.revertAction = async item => {
      const { accepted } = await that.modalApi.show();
      if(!accepted) {
        this.revertRemarks = null;
        return;
      }

      await command.execute(that.revertibleActions[item.actionType], {
          commandId: item.extraInfo.commandId,
          actionDataId: item.id,
          remarks: this.revertRemarks
        }).toPromise();

      userCounterService.refresh();
      that.tableConfig.reload();
      this.revertRemarks = null;
    };

    const isSSSPaymentAction = (actionType) => {
      switch(actionType) {
        case "PAY_ONLINE_SSS":
        case "PAY_OFFLINE_SSS":
          return true;
        default:
          return false;
      }
    }

    const actionPrintType = (actionType) => {
      if (isSSSPaymentAction(actionType)) {
        return "SSS_MISC_TRANSACTION_VALIDATION_SLIP";
      } else {
        return "MISCELLANEOUS_VALIDATION_SLIP";
      }
    }

    that.isPrintActive = (action) => {
      const printType = actionPrintType(action.actionType);
      return that.reprintActive[printType];
    }

    modalPrintPreviewService.canReprint('MISCELLANEOUS_VALIDATION_SLIP', (active) => {
      that.reprintActive['MISCELLANEOUS_VALIDATION_SLIP'] = active;
    });
    modalPrintPreviewService.canReprint('SSS_MISC_TRANSACTION_VALIDATION_SLIP', (active) => {
      that.reprintActive['SSS_MISC_TRANSACTION_VALIDATION_SLIP'] = active;
    });

    that.printValidation = (action) => {
      const printType = actionPrintType(action.actionType);
      modalPrintPreviewService.show(printType, {actionDataId: action.id});
    };

    that.filterConfig = {
      reportCode : reportCode,
      buttons: {
        filter : {
          isVisible: true,
          isDisabled: false,
          action: () => that.filter(),
          text: 'View'
        }
      }
    };

    const createActionDetails = (action) => {
      const details = [];
      const pushDetail = (label, value, nullLabel = '-') => {
        details.push({label: label, value: value !== '' && value !== null ? value : nullLabel});
      };

      if (isSSSPaymentAction(action.actionType)) {
        pushDetail('Transaction no', _.get(action.extraInfo, 'sssTransactionNumber'));
      } else {
        pushDetail('Transaction no', action.id);
      }
      pushDetail('Date and time', $filter('prettyDateTime')(action.registrationDate));
      pushDetail('User initiating', action.initiatingUserName);
      pushDetail('Target user', action.targetUserName);
      pushDetail('Transaction type', $filter('prettyEnum')(action.actionType));
      pushDetail('Remarks', action.remarks || action.rejectionRemarks);
      pushDetail('Status', $filter('prettyEnum')(action.status));
      pushDetail('Debit (Amount Received)', $filter('php')(action.debitAmount));
      pushDetail('Credit (Amount Paid)', $filter('php')(action.creditAmount));
      pushDetail('Total currency amount', $filter('php')(action.totalCurrencyAmount, {currency: action.currencyCode, negativeBrackets: action.isTotalCurrencyAmountNegative}));
      return details;
    }

    const today = moment();
    that.filters = {
      date_range: {
        from: today.clone().subtract(2, 'days').format('YYYY-MM-DD'),
        to: today.format('YYYY-MM-DD')
      }
    };

    // Call service for default filter config
    that.tableConfig = new NgTableParams({
      count: 50
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: async params => {
        that.selectedBranchId = that.filters.branchId ? that.filters.branchId : null;
        let queryParams = $.param({
          pageNo: params.page() - 1,
          pageSize: params.count(),
          branchId: that.selectedBranchId,
          userId: that.filters.userId ? that.filters.userId : null,
          registrationDateFrom: _.get(that.filters, 'date_range.from'),
          registrationDateTo: _.get(that.filters, 'date_range.to')
        }, true);
        // Create pawn items search promise

        const items = await http.get(`/ledger/actions/history?${queryParams}`).toPromise();
        _.forEach(items.result, i => {
          i.isTotalCurrencyAmountNegative = ['FOREX_CASH_OUT', 'FOREX_INTERBRANCH_CASH_TRANSFER'].includes(i.actionType);
          i.totalCurrencyAmount = i.isTotalCurrencyAmountNegative ? -i.totalCurrencyAmount : i.totalCurrencyAmount;
          i.details = createActionDetails(i);
        });
        that.branchSystemDate = await systemDateService.getSystemDateByBranchId(that.selectedBranchId);
        params.total(items.totalCount);
        return items.result;
      }
    });

    that.filter = () => {
        that.tableConfig.reload();
    };

    that.itemClicked = (item, $event) => {
      $event.stopPropagation();
      that.selectedActionId = item.idx;
    };

    that.hideInlinePanel = () => {
      that.selectedActionId = null;
    };


    that.onModalApiReady = ({api,}) => {
      that.modalApi = api;
    };
  }
});

