import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('rolesCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get(`/management/roles`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.rolesConfig,
    cacheName: `rolesConfig`
  })
);
