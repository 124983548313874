import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import _ from 'lodash';
import module from 'module';
import moment from "moment/moment";


const templateUrl = require('./customer-loans-statement.template.html');
module.component('customerLoansStatement', {
  templateUrl: templateUrl,
  controller: function ($route, postingDateService, reportModal) {
    let that = this;

    that.loanId = $route.current.params['loanId'];
    that.customerId = $route.current.params['customerId'];
    that.users = undefined;
    that.allUsers = undefined;

    postingDateService.getCurrentUserBranchPostingDate().then(date => that.date = date.clone().format('YYYY-MM-DD'));

    that.displayReport = () => {
      let params = {
        productId: that.loanId,
        date: that.date,
        checkedBy: that.checkedByName,
        approvedBy: that.approvedByName,
        customerId: that.customerId
      };
      let reportName = 'LoanStatementOfAccountReport';
      reportModal.display({
        params: params,
        reportCode: reportName
      });
    };
  }
});
