import module from 'module';
import _ from 'lodash';

const templateUrl = require('./branch-cash-transfer.template.html');
module.component('branchCashTransfer', {
  templateUrl: templateUrl,
  controller: function (authentication, branchServiceHelper, userCache, actionCommand) {

    const that = this;

    that.transaction = {};

    // Complete list of system users
    that.systemUsers = [];
    // List of branches excluding branch of logged user
    that.branches = [];
    // List of users from target branch
    that.users = [];

    that.denominationIsValid = () => {
      return this.transaction.denominationBundle != null && this.transaction.denominationValid;
    };

    that.onTargetBranchChange = (targetBranchId) => {
      that.transaction.approvalUserId = null;
      that.users = _.filter(that.systemUsers, {branchId: targetBranchId, phantom: false});
      // If there is only 1 user -> select it
      if (that.users && that.users.length === 1) {
        that.transaction.approvalUserId = _.head(that.users).id;
      }
    };

    const sub = Promise.all([
      branchServiceHelper.getOperatingBranches(),
      userCache.toPromise()
    ]).then(([branches, users]) => {
      that.systemUsers = users;

      const authBranchId = authentication.context.branchId;
      that.transaction.sourceBranchId = authBranchId;
      that.sourceBranch = _.find(branches, {id: authBranchId});
      that.branches = _.filter(branches, (b) => Number(b.id) !== authBranchId);
      // If there is only 1 branch -> select it
      if (that.branches && that.branches.length === 1) {
        const targetBranchId = _.head(that.branches).id;
        that.transaction.targetBranchId = targetBranchId;
        that.onTargetBranchChange(targetBranchId);
      }
    });

    that.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    that.save = () => {
      actionCommand.execute('BRANCH_CASH_TRANSFER', that.transaction);
    };
  }
});
