import {loanCalculationMethods} from 'constants/loan';

import module from 'module';
import angular from 'angular';
import {allPercentageChargeTypes} from "constants/loan";

const templateUrl = require('./loan-product-charges.template.html');

class LoanProductChargesForm {
  constructor($scope, feeDefinitionsCache) {
    this.$scope = $scope;
    this.feeDefinitionsCache = feeDefinitionsCache;

    this.loanCalculationMethods = loanCalculationMethods;
    // not all charge types are available for contractual savings, as they are calculated before some figures are
    // available (like total interest rate)
    this.contractualSavingsChargeTypes = [
      'PERCENTAGE_OF_ORIGINAL_PRINCIPAL',
    ];

    // charge types that available on loan opening fees
    this.openingChargeTypes = [
      'PERCENTAGE_OF_ORIGINAL_INTERESTS',
      'PERCENTAGE_OF_ORIGINAL_PRINCIPAL',
      'PERCENTAGE_OF_ORIGINAL_INTERESTS_AND_PRINCIPAL',
      'PERCENTAGE_OF_OUTSTANDING_INTERESTS',
      'PERCENTAGE_OF_OUTSTANDING_PRINCIPAL',
      'PERCENTAGE_OF_OUTSTANDING_INTERESTS_AND_PRINCIPAL',
    ];

    this.insuranceChargeTypes = [
      'PERCENTAGE_OF_ORIGINAL_INTERESTS',
      'PERCENTAGE_OF_ORIGINAL_PRINCIPAL',
      'PERCENTAGE_OF_ORIGINAL_INTERESTS_AND_PRINCIPAL',
      'PERCENTAGE_OF_OUTSTANDING_INTERESTS',
      'PERCENTAGE_OF_OUTSTANDING_PRINCIPAL',
      'PERCENTAGE_OF_OUTSTANDING_INTERESTS_AND_PRINCIPAL',
      'PERCENTAGE_OF_INSURANCE_FEE',
    ];

    // all charge types are available only for charges that are calculated after the loan is created (e.g. penalty)
    this.allChargeTypes = allPercentageChargeTypes;

    this.insuranceFeeDeductionStrategies = [
       'DEDUCT_FROM_RELEASE_AMOUNT',
       'TRANSFER_TO_ACCOUNT',
    ];

  }

  extractFeeValuesFromPropertyBaseFee(loanProduct, feeClass) {
    let charge = this.getFeeBasedOnFeeClass(loanProduct, feeClass);
    if (charge.type === 'FIXED_AMOUNT') {
      return {
        fixedAmount: charge.rate,
        feeType: 'FIXED',
      };
    } else {
      return {
        percentageAmount: charge.rate,
        feeType: 'PERCENTAGE',
        calculationMethod: charge.type,
      };
    }
  };

  getFeeBasedOnFeeClass(loanProduct, feeClass) {
    switch(feeClass) {
      case 'DOC_STAMP':
        return loanProduct.docStamp;
      case 'CBU':
        return loanProduct.cbuCharge;
      case 'PF':
        return loanProduct.pfCharge;
      case 'TP':
        return loanProduct.tpCharge;
      case 'INSURANCE_FEE':
        return loanProduct.insuranceFee;
      case 'INSURANCE_SERVICE_FEE':
        return loanProduct.insuranceServiceFee;
      case 'INSURANCE_PROCESSING_FEE':
        return loanProduct.insuranceProcessingFee;
      case 'NOTARIAL_FEE':
        return loanProduct.notarialFee;
      case 'APPLICATION_FEE':
        return loanProduct.applicationFee;
      case 'CREDIT_INVESTIGATION_FEE':
        return loanProduct.creditInvestigationFee;
      case 'SERVICE_CHARGE':
        return loanProduct.serviceCharge;
      case 'ID_FEE':
        return loanProduct.idFee;
      case 'PRETERMINATION_FEE':
        return loanProduct.preterminationFee;
      case 'EXTRA_BANK_FEE':
        return loanProduct.extraBankFee;
      case 'MEMBERSHIP_FEE':
        return loanProduct.membershipFee;
      case 'PENALTY':
        return loanProduct.penalty;
    }
  };

  $onInit() {
    this.$scope.$watch('$ctrl.loanProduct', () => {
      this.propertyBasedFees = [{
        feeName: "Documentary stamp",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: 'DOC_STAMP',
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'DOC_STAMP'),
      },{
        feeName: 'CBU',
        validCalculationMethods: this.contractualSavingsChargeTypes,
        feeClass: 'CBU',
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'CBU'),
      },{
        feeName: 'PF',
        validCalculationMethods: this.contractualSavingsChargeTypes,
        feeClass: 'PF',
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'PF'),
      },{
        feeName: 'TP',
        validCalculationMethods: this.contractualSavingsChargeTypes,
        feeClass: 'TP',
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'TP'),
      },{
        feeName: "Insurance fee",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: 'INSURANCE_FEE',
        insuranceFeeOptions: {
          insuranceFeeDeductionStrategies: this.insuranceFeeDeductionStrategies,
          insuranceFeeDeductionStrategy: this.loanProduct.insuranceFeeDeductionStrategy,
          insuranceSavingsAccountId: this.loanProduct.insuranceSavingsAccountId,
        },
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'INSURANCE_FEE'),
      },{
        feeName: "Insurance service fee",
        validCalculationMethods: this.insuranceChargeTypes,
        feeClass: "INSURANCE_SERVICE_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'INSURANCE_SERVICE_FEE'),
      },{
        feeName: "Insurance processing fee",
        validCalculationMethods: this.insuranceChargeTypes,
        feeClass: "INSURANCE_PROCESSING_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'INSURANCE_PROCESSING_FEE'),
      },{
        feeName: "Notarial Fee",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: "NOTARIAL_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'NOTARIAL_FEE'),
      },{
        feeName: "Application Fee",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: "APPLICATION_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'APPLICATION_FEE'),
      },{
        feeName: "Credit investigation Fee",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: "CREDIT_INVESTIGATION_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'CREDIT_INVESTIGATION_FEE'),
      },{
        feeName: "Service Charge",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: "SERVICE_CHARGE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'SERVICE_CHARGE'),
      },{
        feeName: "ID Fee",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: "ID_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'ID_FEE'),
      },{
        feeName: "Pretermination Fee",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: "PRETERMINATION_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'PRETERMINATION_FEE'),
      },{
        feeName: "Extra Bank Fee",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: "EXTRA_BANK_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'EXTRA_BANK_FEE'),
      },{
        feeName: "Membership Fee",
        validCalculationMethods: this.openingChargeTypes,
        feeClass: "MEMBERSHIP_FEE",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'MEMBERSHIP_FEE'),
      },{
        feeName: "Penalty",
        validCalculationMethods: this.allPercentageChargeTypes,
        feeClass: "PENALTY",
        ...this.extractFeeValuesFromPropertyBaseFee(this.loanProduct, 'PENALTY'),
      }];

      this.feeDefinitionsCache.toPromise().then(feeDefs => {
        let loanTypeFeeDefs = feeDefs
          .filter(fd => fd.feeClass === 'CUSTOM')
          .filter(fd => fd.enabled)
          .filter(fd => fd.productDefinitionId === this.loanProduct.productDefinition.id);
        this.feeDefinitions = [
          ...this.propertyBasedFees,
          ...loanTypeFeeDefs
        ];

        this.loanProduct.feeDefinitions = this.feeDefinitions.filter(fd => fd.feeClass === 'CUSTOM');
      });
    });

  }

  onSave() {
    this.loanProduct.feeDefinitions = this.feeDefinitions.filter(fd => fd.feeClass === 'CUSTOM');

    //for property based fees updates values
    this.feeDefinitions.filter(fd => fd.feeClass !== 'CUSTOM').forEach((fd) => {
      let loanProductCharge = this.getFeeBasedOnFeeClass(this.loanProduct, fd.feeClass);
      if (fd.feeType === 'FIXED') {
        loanProductCharge.rate = fd.fixedAmount;
        loanProductCharge.type = 'FIXED_AMOUNT';
      } else {
        loanProductCharge.rate = fd.percentageAmount;
        loanProductCharge.type = fd.calculationMethod;
      }
    });
  }
}

module.component('loanProductChargesForm', {
  templateUrl,
  bindings: {
    loanProduct: '=',
    form: '='
  },
  controller: LoanProductChargesForm
});
