import module from 'module';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

const templateUrl = require('./close-account-cash.template.html');
module.component('customerAccountsCloseAccountCash', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, customerCache, productDefinitionService, notification, accountOperationService) {

    let customerId = $route.current.params['customerId'];
    let productId = $route.current.params['accountId'];

    let that = this;

    that.depositAccount = {};
    that.interestForecast = {};
    that.terminationAmount = 0;

    customerCache.depositAccounts(customerId).toObservable()
      .combineLatest(productDefinitionService.toObservable(), (depositAccounts, products) =>
          depositAccounts.map(da => {
            const p = _.find(products, {id: da.definitionId});
            return Object.assign(da, {
              productName: p ? p.productName : '<Unknown product>'
            });
          })
      ).first()
      .subscribe(accounts => {
        that.depositAccount = _.find(accounts, (a) => String(a.id) === productId);
        that.readInterestForecast(that.depositAccount.id);
      });

    // Fetch termination interest forecast
    that.readInterestForecast = (accountId) => {
      http.get(`/products/accounts/${accountId}/interest?closing=true`, {nxLoaderText: 'Calculating interest'})
        .success((forecast) => {
          that.interestForecast = forecast;
          that.terminationAmount = new BigNumber(0)
            .plus(that.depositAccount.balance)
            .plus(forecast.netInterest)
            .round(2)
            .toNumber();
        })
        .error(() => {
          notification.show("Error", "Failed to load interest forecast.")
        });
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${productId}`);

    that.close = () => {
      accountOperationService.closeWithCash({
        customerId: customerId,
        productId,
        amount: that.terminationAmount
      }, that.redirectBack);
    }
  }
});
