import module from 'module';
import moment from 'moment';
import _ from 'lodash';

const templateUrl = require('./date-granted.template.html');
module.component('dateGranted', {
  templateUrl: templateUrl,
  bindings: {
    dateGranted: '=',
    setDateGranted: '<',
    validateDate: '<',
    disabled: '<'
  },
  controller: function ($scope, branchService, authentication, postingDateService) {
    const that = this;

    if (that.validateDate === undefined) {
      that.validateDate = true;
    }

    if (that.disabled === undefined) {
      that.disabled = false;
    }
    that.isBackdated = false;

    $scope.$watch('$ctrl.dateGranted', () => {
      that.isBackdated = moment(that.dateGranted).isBefore(that.minDateGrantedSave);
    });

    $scope.$watch('$ctrl.validateDate', () => {
      if (that.validateDate) {
        that.minDateGranted = that.minDateGrantedSave;
      } else {
        that.minDateGranted = new Date(0);
      }
    });

    const branchSubscription = branchService.toObservable().subscribe(branches => {
      // Select branches associated with user
      // Find user branch
      let userBranch = _.find(branches, {id: authentication.context.branchId});
      that.minDateGrantedSave = postingDateService.getPostingDate(userBranch);

      if (that.setDateGranted) {
        that.dateGranted = that.minDateGrantedSave.format('YYYY-MM-DD');
      }
      if (that.validateDate) {
        that.minDateGranted = that.minDateGrantedSave.toDate();
      } else {
        that.minDateGranted = new Date(0);
      }
    });

    that.$onDestroy = () => {
      branchSubscription.unsubscribe();
    };

  }
});