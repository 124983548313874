import angular from 'angular';
import module from 'module';
import _ from 'lodash';

const templateUrl = require('./new-loan-details.template.html');
module.component('customerLoanCreateDetails', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $timeout, customerCache, loanProductsCache, misGroupsCache,
                        confirmation, notification, customerService, command) {
    let that = this;

    that.profile = {};
    that.loanProduct = {};

    that.loan = {
      loanInformation: {},
      automaticTransferAgreement: {
        transferStrategy: 'NONE'
      }
    };

    let customerId = Number($route.current.params['customerId']);
    let loanTypeId = Number($route.current.params['loanTypeId']);

    that.loan.customerId = customerId;
    that.loan.loanTypeId = loanTypeId;

    let customerSubscr = customerCache.profile(customerId).toObservable().subscribe(profile => {
      that.profile = profile;
      Object.assign(that.loan, {
        customerId: profile.id,
      });
    });

    loanProductsCache.toPromise().then(loanProducts => {
      const product = _.find(loanProducts, p => p.id == loanTypeId);
      return that.loanProduct = product;
    });

    that.enableGroupLoan = () => {
      that.groupLoanMode = true;
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/loans/create`);

    function postLoan(clonedLoan) {
      command.execute('CreateLoan', clonedLoan).success((loanResponse) => {
        const loanId = loanResponse.output.id;
        customerCache.loans(customerId).refetch();
        $location.path(`/customer/${customerId}/loans/${loanId}`);
      });
    }

    that.open = () => {
      confirmation('Do you want to open the loan?', () => {
        const files = that.loan.collateralFiles;
        let clonedLoan = angular.copy(that.loan);
        clonedLoan.collateralFiles = undefined;
        clonedLoan.feeOverride = clonedLoan.feeOverride || {};
        clonedLoan.collateralFileIds = _.map(files, file => file.id);
        postLoan(clonedLoan);
      });
    };

    that.$onDestroy = () => {
      customerSubscr.unsubscribe();
    };


  }
});
