import module from 'module';
import _ from 'lodash';
import moment from 'moment';
import BigNumber from "bignumber.js";

const templateUrl = require('./manual-rollover.template.html');
module.component('depositManualRollover', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $location, $filter, http, command, notification, confirmationTemplate, customerCache, termDepositsService, branchService) {
    const that = this;

    const customerId = $route.current.params['customerId'];
    const depositId = $route.current.params['depositId'];
    const preterminationFirstHalfMultipler = new BigNumber(0.25).toNumber();
    const preterminationSecondHalfMultipler = new BigNumber(0.50).toNumber();

    that.request = {
      productId: depositId,
      depositInterest: {
        interestRate: 0,
        preterminationFirstHalfInterestRate: null,
        preterminationSecondHalfInterestRate: null,
        overmatureInterestRate: null,
        specialInterestRate: true
      },
      term: 0
    };
    that.forecast = {};
    that.deposit = undefined;
    that.maturityStrategies = undefined;

    const setupStrategy = (propertyName, strategies) => {
      if (strategies && strategies.length === 1) {
        that.request[propertyName] = strategies[0];
      }
    };

    const calculateForecast = (deposit) => {
      http.post(`/products/deposits/${deposit.id}/interest`, {mode: 'TERMINATION'})
        .success((forecast) => {
          that.forecast.interest = forecast.interest;
          that.forecast.withholdingTax = forecast.withholdingTax;
          that.forecast.netInterest = forecast.netInterest;
          that.forecast.rolloverAmount = new BigNumber(0)
            .plus(that.deposit.balance)
            .plus(forecast.netInterest)
            .round(2)
            .toNumber();
        })
        .error(() => {notification.show("Error", "Failed to load interest forecast.")});
    };

    // Read deposit instance and extend it with term deposit
    // type, product definition details & deposit mother branch
    const s1 = customerCache
      .termDeposits(customerId).toObservable()
      .map(deposits => _.find(deposits, d => Number(d.id) === Number(depositId)))
      .combineLatest(termDepositsService.toObservable(), (deposit, types) => {
        that.type = _.find(types, {id: deposit.typeId});
        setupStrategy('maturityStrategy', that.type.maturityStrategies);
        setupStrategy('creditingStrategy', that.type.creditingStrategies);
        that.maturityStrategies = _.map(that.type.maturityStrategies, s => {
          return {value: s, label: termDepositsService.getMaturityStrategyLabel(s)};
        });
        return Object.assign(deposit, {type: that.type});
      })
      .combineLatest(branchService.toObservable(), (deposit, branches) => {
        that.branch = _.find(branches, {id: deposit.branchId});
        return Object.assign(deposit, {branch: that.branch});
      })
      .subscribe(deposit => {
        that.deposit = deposit;
        calculateForecast(that.deposit);
        return that.deposit;
      });

    that.onTermChange = () => {
      const term = that.request.term;
      if (term && term > 0) {
        that.forecast.maturityDate = moment(that.branch.postingDate).add(term, 'days');
      }
    };

    that.rollover = () => {
      confirmationTemplate({
        question: 'Are you sure you want to proceed with manual rollover?',
        details: [
          {label: 'Product number', description: that.deposit.productNumber},
          {label: 'Interest rate', description:  that.request.depositInterest.interestRate + '%'} ,
          {label: 'Deposit term', description:  that.request.term + ' days'},
          {label: 'Maturity date', description:  $filter('prettyDate')(that.forecast.maturityDate)},
          {label: 'Rollover amount', description:  $filter('php')(that.forecast.rolloverAmount)}
        ],
        warning: 'This operation cannot be reverted safely.<br>Please make sure that the data is correct.',
        yesCallback: () => command.execute('DepositManualRollover', that.request).success(() => {
          customerCache.termDeposits(customerId).refetch();
          that.redirectBack();
        })
      });
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${depositId}`);

    that.$onDestroy = () => {
      s1.unsubscribe();
    };

    $scope.$watch('$ctrl.request.depositInterest.interestRate', () => {
      if (that.request.depositInterest.interestRate && that.request.depositInterest.interestRate > 0) {
        const interestRate = new BigNumber(that.request.depositInterest.interestRate);
        that.request.depositInterest.preterminationFirstHalfInterestRate = interestRate.mul(preterminationFirstHalfMultipler).toNumber();
        that.request.depositInterest.preterminationSecondHalfInterestRate = interestRate.mul(preterminationSecondHalfMultipler).toNumber();
      }
    });
  }
});
