import _ from 'lodash';
import moment from 'moment';

import module from 'module';

import './collections.style.less'
const templateUrl = require('./collections.template.html');

module.component('collections', {
  templateUrl,
  controller: function ($scope, http, userCache, loanProductsCache, customerCache, confirmation, command) {
    const that = this;

    that.collections = null;

    const subscription = userCache.toObservable().subscribe(users => {
      that.officers = _.filter(users, u => _.find(u.roles, {name: 'LOAN_OFFICER'})).map(u => Object.assign(u, {
        fullName: `${u.firstName} ${u.lastName}`
      }));
    });

    subscription.add(loanProductsCache.toObservable()
      .subscribe(products => {
        that.products = products.map(p => Object.assign(p, {
          description: p.productDefinition.description,
          name: p.productDefinition.productName
        }));
      })
    );

    http.get(`/products/loans/active-batch-customers`).success((customers) => {
      that.groupCustomers = customers;
      that.groupCustomerNames = {};
      for (let c of customers) {
        that.groupCustomerNames[c.id] = c.effectiveName;
      }

    });

    that.productConfig = {
      placeholder: 'Select product',
      searchField: ['name', 'description'],
      valueField: 'id',
      labelField: 'name',
      maxItems: null,
    };

    that.groupConfig = {
      placeholder: 'Select group',
      searchField: ['effectiveName'],
      valueField: 'id',
      labelField: 'effectiveName',
      maxItems: null
    };

    that.filter = {
      dateFrom: moment().format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD')
    };

    that.refresh = () => {
      that.requirementsForm.$setSubmitted();

      if (that.requirementsForm.$valid) {

        http.post(`/products/loans/collections/query`, that.filter).success(response => {
          that.collections = response;
        })

      }
    };

    that.calculateTotalAmount = (collections) => _.sumBy(collections, 'dueAmount');
    that.calculatePaidAmount = (collections) => _.sumBy(collections, 'paidAmount');

    that.groupStatuses = {};
    that.temporaryReceipts = {};

    that.changeGroupStatus = (groupLoanId, collection) => {
      if (that.groupStatuses[groupLoanId]) {
        // uncheck
        for (let col of collection) {
          col.status = false;
          col.paidAmount = null;
          if (!col.trOverride) {
            // temporary receipt was not inputted manually
            col.tr = null;
          }
        }
        that.groupStatuses[groupLoanId] = false;
      } else {
        // check
        for (let col of collection) {
          col.paidAmount = col.dueAmount;
          col.status = true;
          if (!col.trOverride) {
            // temporary receipt was not inputted manually
            const temporaryReceipt = that.temporaryReceipts[groupLoanId];
            if (temporaryReceipt) col.tr = temporaryReceipt.tr;
          }
        }
        that.groupStatuses[groupLoanId] = true;
      }
    };

    that.changeLoanInGroupStatus = (collection, collectionGroup) => {
      that.changeNonGroupStatus(collection);

      if (!collection.status) {
        // status was unchecked

        // clear tr
        collection.tr = null;
        collection.trOverride = false;
        // uncheck the group status as well
        that.groupStatuses[collection.groupLoanId] = false;
        return;
      } else {
        // status was checked -> update tr
        if (!collection.trOverride) {
          // temporary receipt was not inputted manually
          const temporaryReceipt = that.temporaryReceipts[collection.groupLoanId];
          if (temporaryReceipt) collection.tr = temporaryReceipt.tr;
        }
      }

      // check if all collections in the group are checked and fully paid (paid amounts = amortization amounts)
      for (let col of collectionGroup) {
        if (!col.status || col.paidAmount !== col.dueAmount) return;
      }

      // all paid. check the group status
      that.groupStatuses[collection.groupLoanId] = true;

    };

    that.changeNonGroupStatus = (collection) => {
      if (!collection.status) {

        if (collection.paidAmount == null) {
          collection.paidAmount = collection.dueAmount;
        }
        collection.status = true;

      } else {
        // uncheck
        collection.status = false;
        if (collection.paidAmount === collection.dueAmount) collection.paidAmount = null;
      }
    };

    that.updateTemporaryReceiptForCollection = (collections, temporaryReceipt) => {
      for (let c of collections) {
        if (!c.trOverride && c.status) {
          // temporary receipt was not inputted manually
          c.tr = temporaryReceipt;
        }
      }
    };

    that.registerPayment = () => {
      let paymentRequest = [];

      for (let l of that.collections.nonGroupLoans) {
        if (!l.status) continue;

        paymentRequest.push({
          productId: l.loanId,
          priorityAmortizationIds: [l.amortizationId],
          amount: l.paidAmount,
          temporaryReceipt: l.tr,
        });
      }

      for (let groupLoanId of Object.keys(that.collections.groupLoans)) {
        for (let l of that.collections.groupLoans[groupLoanId]) {
          if (!l.status) continue;

          paymentRequest.push({
            productId: l.loanId,
            priorityAmortizationIds: [l.amortizationId],
            amount: l.paidAmount,
            temporaryReceipt: l.tr,
            commandPurpose: 'LOAN_PAYMENT'
          });
        }
      }

      if (paymentRequest.length > 0) {
        confirmation('Do you want to register the payments?', () =>
          command.execute('PayLoanByCashBatch', {
            loanPaymentRequests: paymentRequest
          }).success(() => {
            that.collections = null;
            if (customerCache.loadedCustomerId) customerCache.loans(customerCache.loadedCustomerId).evict();
          }));
      }
    };

    that.$onDestroy = () => {
      subscription.unsubscribe();
    };

  }
});