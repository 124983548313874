import module from 'module';
import _ from 'lodash';

import templateUrl from './automatic-transfer-arrangement.template.html';

class AutomaticTransferArrangement {
  constructor(commandMetadataCache, productDefinitionService, $filter) {
    this.triggerCommands = [];
    this.creditCommands = [];
    this.commandMetadataCache = commandMetadataCache;
    this.productDefinitionService = productDefinitionService;
    this.$filter = $filter;

    this.fetchProducts();
  }

  async fetchProducts() {
    const allProducts = await this.productDefinitionService.toPromise();
    const products = _.filter(allProducts, {productGroup: 'ACCOUNT'});
    this.productOptions = _.map(products, t => {
      return {label: t.productName, value: t.id}
    })
  }

  async populateCommands() {
    const commands = await this.commandMetadataCache.toPromise();

    // Function to filter commands by tag and convert results to label/value form
    // label -> human readable command name, value -> command alias
    const extract = (tag) => {
      return _.map(_.filter(commands, c => c.alias && _.includes(c.tags, tag)), c => {
        return {label: this.$filter('startCase')(c.alias), value: c.alias}
      })
    };

    this.triggerCommands = extract(this.triggerCommandTags);
    this.creditCommands = extract(this.creditCommandTags);
  }

  $onChanges(changes) {
    if(changes.products && changes.products.currentValue)
    this.productOptions = _.map(changes.products.currentValue, t => {
      return {label: t.productName, value: t.id}
    });

    if(changes.triggerCommandTags || changes.creditCommandTags) {
      this.populateCommands();
    }
  }
}

module.component('automaticTransferArrangement', {
  templateUrl,
  bindings: {
    model: '=',
    triggerCommandTags: '<',
    creditCommandTags: '<'
  },
  controller: AutomaticTransferArrangement
});