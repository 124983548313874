import module from 'module';

import './custom-fee-loan-board.style.less';
import templateUrl from './custom-fee-loan-board.template.html';

class CustomFeeLoanBoard {
  constructor($scope, dict) {
    this.dict = dict;
    this.$scope = $scope;
  };

  $onChanges(changes) {
    if (changes.hasOwnProperty('feeType')) {
      if (this.feeType === 'PERCENTAGE') {
        this.board.defaultFixedAmount = null;
        this.board.cells.forEach(c => {
          c.fixedAmount = null;
        });
      } else {
        this.board.defaultPercentageAmount = null;
        this.board.cells.forEach(c => {
          c.percentageAmount = null;
        });
      }
    }
  }

  addNewBoardCell() {
    const newCell = {};
    this.board.cells.push(newCell);
  }

  removeBoardCell(index) {
    this.board.cells.splice(index, 1);
  }

}

module.component('customFeeLoanBoard', {
  templateUrl,
  bindings: {
    /**
     * board: {
     *   defaultRate: 300.00,
     *   cells: [{
     *     rateType         : [PRIME, AVERAGE, NON_PRIME],
     *     minTerm          : 123,    // days
     *     maxTerm          : 365,    // days
     *     minPrincipal     : 13.34,  // PHP
     *     maxPrincipal     : 34.00,  // PHP
     *     rate             : 300.00, // PHP
     *     minAmount        : 300.00, // PHP
     *    }, ...]
     */
    board: '=',
    /**
     * PERCENTAGE - board.cells.rate represents percentage value
     * FIXED -  board.cells.rate represents cash value
     */
    feeType: '<',
  },
  controller: CustomFeeLoanBoard
});
