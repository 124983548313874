import module from "module";
import CompositeRequest from 'shared/common/compositeRequest';
import _ from 'lodash';

const templateUrl = require('./loan-product-details.template.html');
module.component('loanProductDetails', {
  templateUrl,
  controller: function (loanProductsCache, $route, $location, http, notification, confirmation, breadcrumbs, ledgerTagCache, feeDefinitionsCache) {
    const that = this;

    that.$onInit = () => {
      // clear all cache realted data to make sure user see latest avaiable data
      // for edit
      that.clearCache();

      let productId = $route.current.params['productId'];
      that.createMode = !productId;
      if (!that.createMode) {
        loanProductsCache.toObservable().subscribe(data => {
          that.loanProduct = _.find(data, (d) => d.id == productId);
          _.set(breadcrumbs, 'options.loan-product-label', this.loanProduct.productDefinition.productName);
        });
      }
    };

    that.loanProduct = {
      minTermAdjustment: 0,
      maxTermAdjustment: 0,
      productDefinition: {
        availableForSale: true,
        expired: false,
        daysInYear: 360,
        productGroup: 'LOAN'
      },
      paymentDirection: 'HORIZONTAL',
      paymentIntervalOptions: [],
      calculateDefaultFirstPaymentDate: false,
      validateMinFirstPaymentDate: true,
      interestCalculationMethod: 'SIMPLE',
      amortizationType: 'STRAIGHT',
      maxFirstPaymentPostponement: 0,
      backdatingAllowed: false,
      cycleCounting: false,
      cycles: [],

      docStamp: {rate: 500, type: 'FIXED_AMOUNT'},
      notarialFee: {rate: 0, type: 'FIXED_AMOUNT'},
      applicationFee: {rate: 0, type: 'FIXED_AMOUNT'},
      creditInvestigationFee: {rate: 0, type: 'FIXED_AMOUNT'},
      serviceCharge: {rate: 0, type: 'FIXED_AMOUNT'},
      preterminationFee: {rate: 0, type: 'FIXED_AMOUNT'},
      extraBankFee: {rate: 0, type: 'FIXED_AMOUNT'},
      membershipFee: {rate: 0, type: 'FIXED_AMOUNT'},
      idFee: {rate: 0, type: 'FIXED_AMOUNT'},
      cbuCharge: {rate: 0, type: 'FIXED_AMOUNT'},
      pfCharge: {rate: 0, type: 'FIXED_AMOUNT'},
      tpCharge: {rate: 0, type: 'FIXED_AMOUNT'},
      penalty: {rate: 0, type: 'FIXED_AMOUNT'},
      insuranceFee: {rate: 0, type: 'FIXED_AMOUNT'},
      insuranceServiceFee: {rate: 0, type: 'FIXED_AMOUNT'},
      insuranceProcessingFee: {rate: 0, type: 'FIXED_AMOUNT'},

      insuranceFeeDeductionStrategy: 'DEDUCT_FROM_RELEASE_AMOUNT',
      insuranceSavingsAccountId: null,  // TODO: add combo box with saving account selections
      duePaymentHierarchy: ['CUSTOM', 'INTEREST', 'PAST_DUE_INTEREST', 'PENALTY', 'CBU', 'PF', 'TP', 'PRINCIPAL'],
      advancePaymentHierarchy: ['CUSTOM', 'PRINCIPAL', 'PAST_DUE_INTEREST', 'PENALTY', 'CBU', 'PF', 'TP', 'INTEREST'],

      manualMetadata: false,
      curePeriod: 0,
      microfinance: false,
      termCalculationMethod: 'EXACT_DAYS',
      performanceThresholdDays: 0,
      feeDefinitions: [],

      asEarnedInterestCalculation: false,
      withCollateral: false
    };



    this.cancel = () => {
      const parent = '/admin/loan-products';
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => $location.path(parent));
    };

    this.confirmChanges = () => {
      if (that.createMode) {
        http.post(`/products/loans/types`, this.loanProduct).success(() => {
          notification.show('Loan product created successfully');
          loanProductsCache.refetch();
          $location.path('/admin/loan-products');
        }).error((err) => {
          notification.show('Error', 'Could not create loan product');
        });
      } else {
        const typeChanges = !(this.generalForm.$pristine && this.interestsForm.$pristine && this.chargesForm.$pristine && this.advancedForm.$pristine);
        const loanTypeRequest = !typeChanges ? null :
          http.put(`/products/loans/types/${this.loanProduct.id}`, this.loanProduct).success(() => loanProductsCache.refetch());
        const saveRequest = this.glMappingComponent.save();

        const rq = CompositeRequest.create()
          .add(loanTypeRequest)
          .add(saveRequest);

        if (loanTypeRequest || saveRequest) {
          rq.success(function () {
            notification.show("Success", "Loan Product updated successfully");
            that.clearCache();
            $location.path('/admin/loan-products');
          })
        } else {
          notification.show("Information", "No changes have been made");
          $location.path('/admin/loan-products');
        }
      }
    };

    this.clearCache = () => {
      loanProductsCache.refetch();
      ledgerTagCache.evict();
      feeDefinitionsCache.evict();
    };

    this.setGlMappingComponent = (instance) => {
      this.glMappingComponent = instance;
    };

  }
});
