import module from 'module';
import templateUrl from './upload-chart-of-accounts.template.html';

class UploadChartOfAccounts {
  constructor(confirmationTemplate, $route, command) {
    this.confirmationTemplate = confirmationTemplate;
    this.$route = $route;
    this.command = command;

    // initial model data
    this.name = 'operational';
    this.contentFile = [];
  }

  async execute() {
    const confirmed = await this.confirmationTemplate({
      question: 'Do you want to process uploaded chart of accounts?',
    });

    if(confirmed) {
      await this.command.execute('CreateLedgerTemplate', {
        name: this.name,
        fileId: this.contentFile[0].id,
        deleteOnConflict: true,
        primary: true
      }).toPromise();

      this.$route.reload();
    }
  }
}

module.component('uploadChartOfAccounts', {
  templateUrl,
  controller: UploadChartOfAccounts
});

