import module from 'module';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

const templateUrl = require('./inter-branch-cash-disbursement.template.html');
module.component('interBranchCashDisbursement', {
  templateUrl: templateUrl,
  controller: function (actionCommand, authentication, branchService, glMappingsService) {
    const that = this;
    that.selectConfig = {
      placeholder: 'Select expense account',
      searchField: ['name', 'fullCode'],
      valueField: 'fullCode',
      labelField: 'name',
      maxItems: 1
    };

    that.otherBranches = [];
    that.transaction = {units: []};

    const branchesSub = branchService.toObservable().subscribe(branches => {
      // Find branches other than current one
      that.otherBranches = _.filter(branches, b => Number(b.id) !== Number(authentication.context.branchId));
    });

    const glAccountsSub = glMappingsService.accounts.toObservable().subscribe(accounts => {
        // Filter only EXPENSE accounts
        that.accounts = _.filter(accounts, {accountGroup: 'EXPENSE'});
      }
    );

    const calculateDebitSum = () => {
      const units = that.transaction.units;
      if (!units || units.length === 0) return 0;
      return that.transaction.units.reduce((sum, item) => sum.add(item['amount'] || 0), new BigNumber(0)).toNumber();
    };

    that.onUnitChange = () => {
      that.transaction.amount = calculateDebitSum();
    };

    that.addNewAccount = () => {
      if (!that.transaction.units) that.transaction.units = [];
      that.transaction.units.push({entryType: 'DEBIT', amount: 0});
      that.onUnitChange();
    };

    that.removeAccount = (accountCode) => {
      that.transaction.units.splice(_.findIndex(that.transaction.units, accountCode), 1);
      that.onUnitChange();
    };

    that.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    that.save = () => {
      actionCommand.execute('DISTRIBUTED_MISC_CASH_DISBURSEMENT', this.transaction);
    };

    that.$onInit = () => {
      that.addNewAccount();
    };

    that.$onDestroy = () => {
      branchesSub.unsubscribe();
      glAccountsSub.unsubscribe();
    }
  }
});
