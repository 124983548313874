import jwtDecode from 'jwt-decode';

import module from 'module';
import 'config'
import {storage, storageName} from './tokenStorage';

const TOKEN_STORAGE_KEY = 'token';

module.factory('authentication', ($http, config, $location) => {

  let service = {};

  function setupAuthentication(tokenObject) {
    $http.defaults.headers.common.Authorization = 'Bearer ' + tokenObject.token;
    service.context = jwtDecode(tokenObject.token);
    service.permissions = extractPermissions(service.context.permissions);
    service.context.permissions = service.permissions;

    service.context.realUserId = service.context.phantom ? service.context.imposedUserId : service.context.id;

    console.log('Authentication context: ', service.context);

    if(Object.keys(service.permissions).length === 1
       && service.permissions['MNG_PASSWORD_WRITE'] === true) {
      console.log('First login - forcing to change password');
      let target = `/admin/organization/users/${service.context['branchId']}/user/${service.context['id']}`;
      $location.path(target).search({showPasswordTab : 'true'});
    }
  }

  function extractPermissions(permissionString) {
    if (!permissionString) return {};

    let permissions = {};
    for (let p of permissionString.split(';')) {
      if (p) permissions[p] = true;
    }

    return permissions;
  }

  function isTokenValid(token) {
    try {
      let decodedToken = jwtDecode(JSON.parse(token).token);
      return decodedToken && decodedToken.exp > new Date().getTime() / 1000;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  /*
    Data stored in sessionStorage on the login page cannot be accessed due to navigation happening
    after successful token generation. As a result, login page uses localStorage and we need to handle two cases:
    - when token is stored in localStorage - grab token and clear localStorage
    - when token is stored in sessionStorage - just grab token
    Race condition between setting token on the loginPage and reading from localStorage doesn't occur due to the fact that
    removing token also creates an event;
   */
  function retrieveAndCleanLoginToken() {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    if (token) {
      storage().setItem(TOKEN_STORAGE_KEY, token);
      localStorage.removeItem(TOKEN_STORAGE_KEY);
      return token;
    }

    return storage().getItem(TOKEN_STORAGE_KEY);
  }

  const refreshOnTokenChange = e => {
    const {storageArea, key,} = e;
    if (storageArea === localStorage && key === TOKEN_STORAGE_KEY && storageName() === 'sessionStorage') {
      console.debug('Someone else changed token! Signing out!');
      service.logout();
    }
  };

  const removeStorageListener = () => {
    window.removeEventListener('storage', refreshOnTokenChange);
  };

  function listenOnDifferentTabLoggingIn() {
    window.addEventListener('storage', refreshOnTokenChange);
  }

  function init() {
    const cachedTokenString = storageName() === 'sessionStorage' ? retrieveAndCleanLoginToken() : storage().getItem(TOKEN_STORAGE_KEY);
    if (cachedTokenString && isTokenValid(cachedTokenString)) {
      setupAuthentication(JSON.parse(cachedTokenString));
      listenOnDifferentTabLoggingIn();
    } else {
      console.log('Token invalid or not present.');
    }
  }

  service.logout = (target) => {
    $http.post(config.apiPrefix + '/auth-tokens/logout')
      .finally(() => {
        storage().removeItem(TOKEN_STORAGE_KEY);
        const targetParam = target ? '?target=' + target : '';
        window.location.replace(`/login` + targetParam);
      });
  };

  service.refresh = (successCallback = null, errorCallback = null) => {
    $http.put(config.apiPrefix + '/auth-tokens').then(
      response => {
        const nxToken = response.data;
        removeStorageListener();
        storage().setItem(TOKEN_STORAGE_KEY, JSON.stringify(nxToken));
        setupAuthentication(nxToken);
        listenOnDifferentTabLoggingIn();
        if (successCallback) successCallback();
      }
    ).catch(() => {
      if (errorCallback) errorCallback();
    })
  };

  service.override = (branchId, successCallback = null) => {
    $http.put(config.apiPrefix + `/auth-tokens?branchId=${branchId}`).then(
      response => {
        const nxToken = response.data;
        removeStorageListener();
        storage().setItem(TOKEN_STORAGE_KEY, JSON.stringify(nxToken));
        setupAuthentication(nxToken);
        listenOnDifferentTabLoggingIn();
        if (successCallback) successCallback();
      }
    );
  };

  service.isSessionExpired = () => {
    return !service.context || !service.context.exp || service.context.exp < new Date().getTime() / 1000;
  };

  init();
  return service;
});
