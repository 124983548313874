import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('depositAccountService', (http, CacheFactory, config) => {
  return new LocalCache({
    provider: () => http.get('/products/accounts/types'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.depositAccountTypes,
    cacheName: 'depositAccountTypes',
  });
});