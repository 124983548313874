import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('schemaCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/system/schema/tables'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.schemaTables,
    cacheName: 'schemaTables'
  })
);