import module from 'module';
import './logged-in-users.style.less';

import templateUrl from './logged-in-users.template.html';

class LoggedInUsers {
  constructor(http, authentication, branchService, rolesCache, NgTableParams) {
    this.http = http;
    this.authentication = authentication;
    this.branchService = branchService;
    this.rolesCache = rolesCache;
    this.NgTableParams = NgTableParams;
  }

  async $onInit(){
    const [roles, branches] = await Promise.all([this.rolesCache.toPromise(), this.fetchAvailableBranches()]);

    this.roles = roles;
    this.branches = branches;
    this.hasLoggedInUsers = false;

    this.selectedBranchIds = this.branches.flatMap(branch => branch.id || []);
    this.selectedRoleIds = this.roles.flatMap(role => role.id || []);
  }

  async fetchAvailableBranches(){
    const allBranches = await this.branchService.toPromise();
    const availableBranches = this.authentication.context.branchIds;
    return allBranches.filter(branch => availableBranches.includes(branch.id));
  }

  async viewLoggedInUsers(){
    this.tableConfig = new this.NgTableParams({
        count: 20
      }, {
        counts: [],
        paginationMinBlocks: 3,
        paginationMaxBlocks: 8,
        getData: params => this.fetchData(params)
      }
    )
  }

  async fetchData(params){
    let branchIds = this.selectedBranchIds;
    let roleIds = this.selectedRoleIds;

    if(branchIds.length === this.branches.length){
      branchIds = null;
    }

    if(roleIds.length === this.roles.length){
      roleIds = null;
    }

    this.request = {
      branchIds: branchIds,
      roleIds: roleIds,
      pageNo: params.page() - 1,
      pageSize: params.count()
    }
    // used http.post because branchIds is too large for http.get if almost all are selected
    const response = await this.http.post(`/management/users/logged-in`, this.request).toPromise();
    params.total(response.totalCount);
    this.hasLoggedInUsers = response.result && response.result.length > 0;
    return response.result;
  }
}

module.component("loggedInUsers", {
  templateUrl,
  controller: LoggedInUsers
})