import module from 'module';
import _ from 'lodash';

import templateUrl from './tree-filter.template.html';
import './tree-filter.style.less';

class TreeFilter {
  constructor($scope) {
    $scope.$watch('$ctrl.options', () => {
      this.initializeModel()
    })
  }

  $onInit() {
    this.ngModel.$render = () => {
      this.initializeModel()
    };

    this.ngModel.$isEmpty = values => {
      return !values || values.length === 0;
    };

    this.nodeLabel = (node) => node.name;

    this.renderLabel = () => {
      const {isMissingOptions, isAllOptionSelected, uniqueSelectedIds} = this.currentState();

      if (isMissingOptions) {
        return '0 items selected';
      }

      if (isAllOptionSelected) {
        return 'All items selected';
      }

      const selectedItems = Array.from(uniqueSelectedIds);
      const length = selectedItems.length;
      if (length === 1) {
        const id = selectedItems[0];
        const option = _.find(this.options, option => option.id === id);
        return option.code;
      } else {
        return `${length} items selected`;
      }
    };
  }

  initializeModel() {
    const uniqueModelIds = new Set(this.ngModel.$modelValue);
    if (_.isEqual(this.uniqueSelectedIds(), uniqueModelIds)) {
      return;
    }

    if (this.isAllOptionsModelValue()) {
      const uniqueOptionIds = this.uniqueOptionIds();
      const uniqueSelectedIds = this.uniqueSelectedIds();
      const isAllOptionSelected = _.isEqual(uniqueOptionIds, uniqueSelectedIds);

      if (isAllOptionSelected) {
        return;
      }
    }

    if (this.isAllOptionsModelValue()) {
      this.values = Array.from(this.uniqueOptionIds());
    } else if (this.ngModel.$modelValue) {
      this.values = this.ngModel.$modelValue;
    } else {
      this.values = [];
    }
  }

  uniqueOptionIds() {
    const allIds = (this.options || []).map(option => option.id);
    return new Set(allIds);
  }

  isAllOptionsModelValue() {
    return this.ngModel.$modelValue
      && this.ngModel.$modelValue.length === 1
      && this.ngModel.$modelValue[0] === null;
  }

  updateModel() {
    this.ngModel.$setTouched();
    const {uniqueSelectedIds, isAllOptionSelected} = this.currentState();
    if (isAllOptionSelected) {
      this.ngModel.$setViewValue([null]);
    } else {
      this.ngModel.$setViewValue(Array.from(uniqueSelectedIds));
    }
  }

  currentState() {
    const uniqueOptionIds = this.uniqueOptionIds();
    const uniqueSelectedIds = this.uniqueSelectedIds();
    return {
      isMissingOptions: uniqueOptionIds.size === 0,
      isAllOptionSelected: uniqueOptionIds.size > 0 && uniqueOptionIds.size === uniqueSelectedIds.size,
      uniqueSelectedIds
    };
  }
}

module.component('treeFilter', {
  templateUrl,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    ngRequired: '<',
    treeData: '<',
    options: '<',
    uniqueSelectedIds: '<'
  },
  controller: TreeFilter
});
