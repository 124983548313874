import module from 'module';
import _ from 'lodash';

const templateUrl = require('./waive-amortization.template.html');
module.component('customerAmortizationWaive', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $location, $filter, http, command, dict, customerCache, productDefinitionService, confirmation, notification) {
    let that = this;
    let customerId = $route.current.params['customerId'];
    that.loanId = $route.current.params['loanId'];
    that.amortizationId = $route.current.params['amortizationId'];
    that.amortizationIds = [];
    that.amortization = null;
    that.loan = null;

    that.waiveRequest = {};
    that.empty = {};
    that.maxValues = {};

    customerCache.loans(customerId).toObservable().subscribe(loans => {
      if (!loans) that.redirectBack();

      that.loan = _.find(loans, (l) => l.id == that.loanId);
      if (that.amortizationId) {
        that.amortization = _.find(that.loan.amortizationSchedule.list, (a) => a.id == that.amortizationId);
        that.amortizationIds = [that.amortizationId];
        that.maxValues = {
          principal: that.amortization.principalBalance,
          interest: that.amortization.interestBalance,
          cbuCharge: that.amortization.cbuChargeBalance,
          pfCharge: that.amortization.pfChargeBalance,
          tpCharge: that.amortization.tpChargeBalance,
          pastDueInterest: that.amortization.pastDueInterestBalance,
          penalty: that.amortization.penaltyBalance,
        };
      } else {
        that.maxValues = {...that.loan.amortizationSchedule.totalBalance};
      }
    });

    that.getMessage = () => {
      let total = _.sumBy(that.waiveRequest.waiveParts, 'waiveAmount');
      let msg = `Do you want to waive ${$filter('php')(total)} ?`;
      return `${msg}`;
    };


    that.redirectBack = () => $location.path(`/customer/${customerId}/loans/${that.loanId}`);

    that.pay = () => {
      confirmation(that.getMessage(), () => {
        command.execute('WaiveLoan', that.waiveRequest, {
          nxLoaderText: 'Performing operation...'
        }).success(() => {
          customerCache.loans(customerId).refetch();
          that.redirectBack();
        }).offline(() => {
          customerCache.loans(customerId).refetch();
          that.redirectBack();
        })
      });

    }
  }
});
