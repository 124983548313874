import 'rxjs/add/observable/fromPromise';
import module from 'module';
import './new-pawn-appraisals.style.less'
import _ from 'lodash';

const templateUrl = require('./new-pawn-appraisals.template.html');

class CustomerPawnCreateItems {
  constructor($location, $route, authentication, confirmation, branchService,
              pawnItemTypeCache, pawnMetalRateCache, newPawnService, pawnItemDefectTypeCache) {
    this.$location = $location;
    this.$route = $route;
    this.authentication = authentication;
    this.confirmation = confirmation;
    this.branchService = branchService;
    this.pawnItemTypeCache = pawnItemTypeCache;
    this.pawnMetalRateCache = pawnMetalRateCache;
    this.newPawnService = newPawnService;
    this.pawnItemDefectTypeCache = pawnItemDefectTypeCache;
  }

  async $onInit() {
    this.currentBranchId = this.authentication.context.branchId;

    this.branchNamesMap = {};
    const branches = await this.branchService.toPromise();
    branches.forEach(b => this.branchNamesMap[b.id] = b.name);

    this.branch = branches.find(br => br.id === this.currentBranchId);

    this.typeMap = {};
    const types = await this.pawnItemTypeCache.toPromise();
    types.forEach(t => this.typeMap[t.id] = t.name);

    this.metalMap = {};
    const metals = await this.pawnMetalRateCache.toPromise();
    metals.forEach(m => this.metalMap[m.id] = m.fineness);

    this.defectMap = {};
    const defectTypes = await this.pawnItemDefectTypeCache.toPromise();
    defectTypes.forEach(dt => this.defectMap[dt.id] = dt.name);
  }

  getItemDefects(item) {
    if (!item || !item.defectIds || !this.defectMap) return '-';
    const defectsList = _.map(item.defectIds, id => this.defectMap[id]);
    return defectsList.join(', ');
  }

  editItem(item) {
    const customerId = this.$route.current.params['customerId'];
    const pawnTypeId = this.pawnProduct.id;
    this.$location.path(`/customer/${customerId}/pawns/create/${pawnTypeId}/edit-appraisal/${item.id}`);
  }

  async removeItem(item) {
    const confirmed = await this.confirmation('Do you want to Remove the appraisal?');
    if (confirmed) {
      this.items = this.newPawnService.removeAppraisal(item);
    }
  }

  isItemCreatedInDifferentBranch(item) {
    return item.branchId !== this.currentBranchId;
  }

  getBranchName(branchId) {
    return this.branchNamesMap[branchId];
  }

  isSameCategoryOnly() {
    if (!this.items || this.items.length === 0) {
      return true;
    }
    return new Set(this.items.map(i => i.categoryId)).size === 1;
  }
}

module.component('customerPawnCreateItems', {
  templateUrl: templateUrl,
  bindings: {
    form: '=',
    pawnProduct: '<',
    items: '='
  },
  controller: CustomerPawnCreateItems
});
