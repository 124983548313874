import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('glMappingsService', (http, CacheFactory, config) => {
  let that = {};

  let productMappings = {};

  let createProductMappingsCache = (id) => {
    const cache = new LocalCache({
      provider: () => http.get(`/ledger/mappings/product-mappings?productDefinitionId=${id}`),
      cacheFactory: CacheFactory,
      cacheTime: config.cache.productMappings,
      cacheName: `productMappings_${id}`
    });
    productMappings[id] = cache;
    return cache;
  };

  that.productMappings = (id) => {
    if (!productMappings[id]) {
      createProductMappingsCache(id);
    }
    return productMappings[id];
  };

  that.accounts = new LocalCache({
    provider: () => http.get('/ledger/mappings/product-mappings/accounts'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.productMappingsAccounts,
    cacheName: 'productMappingsAccounts'
  });

  that.feesDefinitions = new LocalCache({
    provider: () => http.get('/products/fees'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.productFees,
    cacheName: 'productFees'
  });

  return that;
});
