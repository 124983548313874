import module from 'module';
import _ from 'lodash';
import {determineAllowedGoldFinenessValues} from './metals.utils';

const templateUrl = require('./pawned-item-metal-rates.template.html');

class Metals {
  constructor (http, branchService, pawnMetalRateCache, notification, confirmation, authentication, systemPropertyCache) {
    this.http = http;
    this.branchService = branchService;
    this.pawnMetalRateCache = pawnMetalRateCache;
    this.notification = notification;
    this.confirmation = confirmation;
    this.authentication = authentication;
    this.systemPropertyCache = systemPropertyCache;
  }

  async $onInit() {
    this.readOnly = !this.authentication.permissions['PWN_METAL_RATE_WRITE'];

    const [
      branches,
      metalRates,
      systemProperties
    ] = await Promise.all([this.branchService.toPromise(),
        this.pawnMetalRateCache.toPromise(),
        this.systemPropertyCache.toPromise()]);

    const allowedGoldFinenessValuesProperty = _.find(systemProperties, {code: "ALLOWED_GOLD_FINENESS_VALUES"});

    metalRates = determineAllowedGoldFinenessValues(metalRates, allowedGoldFinenessValuesProperty);
    
    this.metalTypeMap = _.groupBy(metalRates, 'name');
    this.userBranch = _.find(branches, {id: this.authentication.context.branchId});
  }

  save() {
    this.confirmation('Do you want to update metal prices?', () => {
      // Convert pawn metal rate map into flat array
      let flatRates = [];
      for (let group of Object.keys(this.metalTypeMap)) {
        if (this.metalTypeMap.hasOwnProperty(group)) {
          flatRates = _.union(flatRates, this.metalTypeMap[group]);
        }
      }
      // Set metal rate [systemDate] to current branch date
      _.forEach(flatRates, (rate) => {
        rate.systemDate = this.userBranch.postingDate;
      });
      // Update pawn metal rates
      this.http.put(`/products/pawns/metal-rates`, flatRates, {nxLoaderText: 'Updating metal prices'})
        .success(() => {
          this.notification.show("Success", "Metal prices updated successfully");
          this.pawnMetalRateCache.refetch();
        })
        .error(() => {
          this.notification.show("Error", "Failed to update metal prices");
          this.pawnMetalRateCache.refetch();
        });
    });
  };
}

module.component('pawnedItemMetalRates', {
  templateUrl,
  controller: Metals
});
