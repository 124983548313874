import _ from 'lodash';
import module from 'module';
import templateUrl from './forex-document-type-details.template.html';
import './forex-document-type.style.less';

class ForexDocumentTypeDetails {
  constructor(currencyCache,
              $route,
              confirmation,
              command,
              $location,
              forexDocumentTypeCache,
              forexDocumentTypeRatesCache) {
    this.currencyCache = currencyCache;
    this.$route = $route;
    this.confirmation = confirmation;
    this.command = command;
    this.$location = $location;
    this.forexDocumentTypeCache = forexDocumentTypeCache;
    this.forexDocumentTypeRatesCache = forexDocumentTypeRatesCache;
  }

  async $onInit() {
    this.currencies = await this.fetchCurrencies();
    this.documentTypeId = this.$route.current.params.documentTypeId;
    this.documentType = await this.fetchDocumentType();
  }

  async fetchCurrencies() {
    const allCurrencies = await this.currencyCache.toPromise();
    return _.filter(allCurrencies, curr => curr.isoCode !== 'PHP');
  }

  async fetchDocumentType() {
    if (this.documentTypeId) {
      const [documentTypes, rates] = await Promise.all([
        this.forexDocumentTypeCache.toPromise(),
        this.forexDocumentTypeRatesCache.withParam(this.documentTypeId).toPromise()
      ]);
      const documentType = _.find(documentTypes, item => item.id === Number(this.documentTypeId));
      documentType.rates = [];
      _.forEach(this.currencies, curr => {
        const rate = _.find(rates, r => r.currencyId === curr.id);
        if (rate) {
          rate.currency = curr;
          rate.originalBuyRate  = rate.buyRate;
          rate.originalSellRate = rate.sellRate;
          documentType.rates.push(rate);
        } else {
          documentType.rates.push(this.createNewRate(curr));
        }
      });
      return documentType;
    } else {
      const documentType = {};
      documentType.rates = [];
      _.forEach(this.currencies, curr => {
        documentType.rates.push(this.createNewRate(curr));
      });
      return documentType;
    }
  }

  async save() {
    const commandName = this.documentTypeId ? 'UpdateForexDocumentType' : 'CreateForexDocumentType';
    this.confirmation('Do you want to save this forex document?', async () => {
      await this.command.execute(commandName, this.prepareRequest()).toPromise();
      this.forexDocumentTypeCache.refetch();
      this.forexDocumentTypeRatesCache.evict();
      this.goBack();
    });
  }

  prepareRequest() {
    const docType = this.documentType;
    const request = {
      id: docType.id,
      name: docType.name,
      description: docType.description
    };

    request.rates = [];
    _.forEach(docType.rates, rate => {
      if(this.isChanged(rate)){
        request.rates.push({
          id: rate.id,
          currencyId: rate.currency.id,
          buyRate: rate.buyRate,
          sellRate: rate.sellRate
        });
      }
    });
    return request;
  }

  createNewRate(currency) {
    return {
      currency: currency,
      buyRate: 0,
      sellRate: 0
    };
  }

  isChanged(rate){
    if(this.documentTypeId){
      return rate.buyRate !== rate.originalBuyRate || rate.sellRate !== rate.originalSellRate;
    }
    // return true because this is a new rate
    return true;
  }

  cancel() {
    this.confirmation('Do you want to cancel? Canceling will discard all changes.', () => this.goBack());
  };

  goBack() {
    this.$location.path('/admin/forex/document-types');
  }
}

module.component('forexDocumentTypeDetails', {
  templateUrl,
  controller: ForexDocumentTypeDetails
});

