import module from 'module';

class BranchServiceHelper {
  constructor(branchService) {
    this.branchService = branchService;
  }

  async getOperatingBranches() {
    const branches = await this.branchService.toPromise();
    return branches.filter(b => !b.closed);
  };
}

module.service('branchServiceHelper', BranchServiceHelper);
