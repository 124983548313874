import module from 'module';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

import PayHelper from './pay-helper'

const templateUrl = require('./amortization-payment.template.html');
module.component('amortizationPayment', {
  templateUrl: templateUrl,
  bindings: {
    /**
     * Loan to be paid. The loan must include a loanType parameter initialized with its loan type.
     * **/
    'loan': '<',

    /**
     * Object that is returned by this component. Consists of:
     * amount - The filled in amount to be paid
     * custom payment amounts (principalAmount, interestAmount, ...) - returned if custom payment
     * priorityAmortizationIds - Amortizations to be prioritized when paying loan
     * temporaryReceipt: Temporary Receipt (if applicable)
     * officialReceipt: Official Receipt (if applicable)
     */
    'paymentRequest': '=',

    /**
     * Describes if the receipt fields should be collected
     */
    collectTemporaryReceipt: '<',
    collectOfficialReceipt: '<'
  },
  controller: function ($scope) {
    let that = this;

    that.previewAmortizations = [];
    that.priorityAmortizationIds = [];
    that.error = null;

    if (!that.paymentRequest) that.paymentRequest = {};

    that.customPaymentPartArray = [
      {label: 'Principal', property: 'principalAmount'},
      {label: 'Interest', property: 'interestAmount'},
      {label: 'Cbu', property: 'cbuChargeAmount'},
      {label: 'Pf', property: 'pfChargeAmount'},
      {label: 'Tp', property: 'tpChargeAmount'},
      {label: 'Past Due Interest', property: 'pastDueInterestAmount'},
      {label: 'Penalty', property: 'penaltyAmount'},
      {label: 'Custom Fees', property: 'customFeesAmount'}
    ];

    that.toggleCustomPayment = () => {
      that.customPayment = !!that.customPayment;

      let value = undefined;

      that.paymentRequest.principalAmount = value;
      that.paymentRequest.interestAmount = value;
      that.paymentRequest.cbuChargeAmount = value;
      that.paymentRequest.pfChargeAmount = value;
      that.paymentRequest.tpChargeAmount = value;
      that.paymentRequest.pastDueInterestAmount = value;
      that.paymentRequest.penaltyAmount = value;
      that.paymentRequest.customFeesAmount = value;

      that.paymentRequest.amount = 0;
      that.paymentRequest.priorityAmortizationIds = undefined;
      that.modifiedAmortizations = undefined;
      that.loan.amortizationSchedule.list.forEach(a => a.priority = undefined);
    };

    /**
     * Payment methods manually transpiled from LoanPaymentService.java
     */

    that.calculatePreview = () => {
      if (that.paymentRequest.amount == null || !that.loan) return;

      const loan = _.cloneDeep(that.loan);
      setInitialValues(loan);
      that.error = null;

      convertToBigNumber(loan);

      let payHelper;
      if (that.loan.loanType.paymentDirection === 'VERTICAL') {
        payHelper = calculateVerticalPayment(loan);
      } else if (that.loan.loanType.paymentDirection === 'HORIZONTAL') {
        payHelper = calculateHorizontalPayment(loan);
      } else {
        console.log("Unknown payment direction: " + that.loan.loanType.paymentDirection);
      }

      if (!payHelper.remainingAmount.isZero()) {
        // all amortizations paid and still some money left -> paid amount is bigger than expected
        that.error = `Payment amount greater than expected. Excessive amount=${payHelper.remainingAmount}`;
      }

      postProcess(loan);
      convertFromBigNumber(loan);
      that.modifiedAmortizations = payHelper.modifiedAmortizations;
      that.totalPaidAmount = _.reduce(that.modifiedAmortizations, (sum, a) =>
        sum.plus(a.paid), new BigNumber(0)
      ).toNumber();

    };

    function calculateHorizontalPayment(loan) {
      const loanType = loan.loanType;
      const bnAmount = bn(that.paymentRequest.amount);

      const payHelper = new PayHelper(bnAmount);
      const payments = {};
      const duePaymentHierarchy = convertHierarchyToFields(loanType.duePaymentHierarchy);
      const advancePaymentHierarchy = convertHierarchyToFields(loanType.advancePaymentHierarchy);

      // 1. Pay priority amortizations first
      let priorityAmortizations = _.filter(loan.amortizationSchedule.list, a => that.priorityAmortizationIds.includes(a.id));
      markAmortizationsAsPaidHorizontally(payHelper.remainingAmount, payments, priorityAmortizations, duePaymentHierarchy, payHelper);

      // 2. Pay due and overdue amortizations
      if (!payHelper.remainingAmount.isZero()) {
        let dueAmortizations = _.filter(loan.amortizationSchedule.list, a => a.status === 'DUE' || a.status === 'OVERDUE');
        markAmortizationsAsPaidHorizontally(payHelper.remainingAmount, payments, dueAmortizations, duePaymentHierarchy, payHelper);
      }
      // 3. Handle advance payment
      if (!payHelper.remainingAmount.isZero()) {
        let unpaidAmortizations = _.filter(loan.amortizationSchedule.list, a => a.status === 'UNPAID');
        markAmortizationsAsPaidHorizontally(payHelper.remainingAmount, payments, unpaidAmortizations, advancePaymentHierarchy, payHelper);
      }
      return payHelper;
    }

    function calculateVerticalPayment(loan) {
      const loanType = loan.loanType;
      // here comes the sun
      const bnAmount = bn(that.paymentRequest.amount);

      const payHelper = new PayHelper(bnAmount);
      const payments = {};
      const duePaymentHierarchy = convertHierarchyToFields(loanType.duePaymentHierarchy);
      const advancePaymentHierarchy = convertHierarchyToFields(loanType.advancePaymentHierarchy);

      // 1. Pay priority amortizations first
      let priorityAmortizations = _.filter(loan.amortizationSchedule.list, a => that.priorityAmortizationIds.includes(a.id));
      markAmortizationsAsPaidVertically(payHelper.remainingAmount, payments, priorityAmortizations, duePaymentHierarchy, payHelper);

      // 2. Pay due and overdue amortizations
      if (!payHelper.remainingAmount.isZero()) {
        let dueAmortizations = _.filter(loan.amortizationSchedule.list, a => a.status === 'DUE' || a.status === 'OVERDUE');
        markAmortizationsAsPaidVertically(payHelper.remainingAmount, payments, dueAmortizations, duePaymentHierarchy, payHelper);
      }
      // 3. Handle advance payment
      if (!payHelper.remainingAmount.isZero()) {
        let unpaidAmortizations = _.filter(loan.amortizationSchedule.list, a => a.status === 'UNPAID');
        markAmortizationsAsPaidVertically(payHelper.remainingAmount, payments, unpaidAmortizations, advancePaymentHierarchy, payHelper);
      }
      return payHelper;
    }


    // initialization
    $scope.$watch('$ctrl.loan', () => {
      if (!that.loan) return;
      // initialize amount with first unpaid amortization

      const due = _.filter(that.loan.amortizationSchedule.list, a => a.status === 'DUE' || a.status === 'OVERDUE');
      that.paymentRequest.amount = _.reduce(due, (sum, a) => sum.plus(a.amortizationBalance), new BigNumber(0)).toNumber();

      if (!that.paymentRequest.amount) {
        // no due / overdue amortizations. Calculate total amount of first unpaid amortization
        const loanType = that.loan.loanType;
        const advancePaymentHierarchy = convertHierarchyToFields(loanType.advancePaymentHierarchy);
        let unpaid = _.filter(that.loan.amortizationSchedule.list, a => a.status === 'UNPAID');

        if (!unpaid) return;  // no unpaid amortizations for some reason... returning..

        const a = unpaid[0];
        let sum = new BigNumber(0);
        for (let h of advancePaymentHierarchy) {
          sum = sum.plus(a[h]);
        }
        that.paymentRequest.amount = sum.toNumber();
      }

      if (!that.customPayment) {
        that.calculatePreview();
      }
    });

    function setInitialValues(loan) {
      const ZERO = new BigNumber(0);

      for(let amortization of loan.amortizationSchedule.list) {
        amortization.interestBalance_initial = amortization.interestBalance;
        amortization.pastDueInterestBalance_initial = amortization.pastDueInterestBalance;
        amortization.penaltyBalance_initial = amortization.penaltyBalance;
        amortization.cbuChargeBalance_initial = amortization.cbuChargeBalance;
        amortization.pfChargeBalance_initial = amortization.pfChargeBalance;
        amortization.tpChargeBalance_initial = amortization.tpChargeBalance;
        amortization.principalBalance_initial = amortization.principalBalance;
        amortization.amortizationBalance_initial = amortization.amortizationBalance;
        amortization.customFeesBalance_initial = amortization.customFeesBalance;

        amortization.interestBalance_paid = ZERO;
        amortization.pastDueInterestBalance_paid = ZERO;
        amortization.penaltyBalance_paid = ZERO;
        amortization.cbuChargeBalance_paid = ZERO;
        amortization.pfChargeBalance_paid = ZERO;
        amortization.tpChargeBalance_paid = ZERO;
        amortization.principalBalance_paid = ZERO;
        amortization.amortizationBalance_paid = ZERO;
        amortization.customFeesBalance_paid = ZERO;

        amortization.status_initial = amortization.status;
      }
    }

    function markAmortizationsAsPaidHorizontally(amount, payments, amortizations, paymentHierarchy, payHelper) {
      if (amortizations.length === 0 || amount.isZero()) return;

      for (let a of amortizations) {
        for (let partField of paymentHierarchy) {
          if (payHelper.remainingAmount.isZero()) return;

          const paidAmount = payHelper.payPart(partField, amount, [a]);
          if (!paidAmount.isZero()) {
            const alreadyPaid = payments[partField];
            payments[partField] = !alreadyPaid ? paidAmount : paidAmount.plus(alreadyPaid);
          }
        }
      }
    }

    function markAmortizationsAsPaidVertically(amount, payments, amortizations, paymentHierarchy, payHelper) {
      if (amortizations.length === 0 || amount.isZero()) return;

      for (let partField of paymentHierarchy) {
        if (payHelper.remainingAmount.isZero()) return;

        const paidAmount = payHelper.payPart(partField, amount, amortizations);
        if (!paidAmount.isZero()) {
          const alreadyPaid = payments[partField];
          payments[partField] = !alreadyPaid ? paidAmount : paidAmount.plus(alreadyPaid);
        }
      }
    }


    function convertHierarchyToFields(hierarchy) {
      const map = {
        'INTEREST': 'interestBalance',
        'PAST_DUE_INTEREST': 'pastDueInterestBalance',
        'PENALTY': 'penaltyBalance',
        'CBU': 'cbuChargeBalance',
        'PF': 'pfChargeBalance',
        'TP': 'tpChargeBalance',
        'PRINCIPAL': 'principalBalance',
        'CUSTOM': 'customFeesBalance'
      };

      let fieldHierarchy = [];
      for (let h of hierarchy) {
        fieldHierarchy.push(map[h]);
      }
      return fieldHierarchy;
    }

    function convertToBigNumber(loan) {
      convert(loan, num => num !== undefined ? new BigNumber(num) : new BigNumber(0));
    }

    function convertFromBigNumber(loan) {
      convert(loan, bn => bn !== undefined ? bn.toFixed(2) : new BigNumber(0));
    }

    function convert(loan, f) {
      for (let a of loan.amortizationSchedule.list) {
        a.principalBalance = f(a.principalBalance);
        a.interestBalance = f(a.interestBalance);
        a.uidInterestBalance = f(a.uidInterestBalance);
        a.penaltyBalance = f(a.penaltyBalance);
        a.pastDueInterestBalance = f(a.pastDueInterestBalance);
        a.cbuChargeBalance = f(a.cbuChargeBalance);
        a.pfChargeBalance = f(a.pfChargeBalance);
        a.tpChargeBalance = f(a.tpChargeBalance);
        a.paid = f(a.paid);
        a.amortizationBalance = f(a.amortizationBalance);
        a.customFeesBalance = f(a.customFeesBalance);
      }
    }


    function postProcess(loan) {
      for (let a of loan.amortizationSchedule.list) {
        // update amortization balance
        if (a.paid) {
          a.amortizationBalance = a.amortizationBalance.minus(a.paid);
        }
        // set new status
        if (a.amortizationBalance.isZero()) {
          a.status = 'PAID';
        } else if (!a.amortizationBalance.equals(a.amortizationBalance_initial)) {
          a.status = 'PART. PAID';
        }

        // assign classes
        a.class = {
          principalBalance: calculateClass(a.principalBalance, a['principalBalance_touched']),
          interestBalance: calculateClass(a.interestBalance, a['interestBalance_touched']),
          cbuChargeBalance: calculateClass(a.cbuChargeBalance, a['cbuChargeBalance_touched']),
          pfChargeBalance: calculateClass(a.pfChargeBalance, a['pfChargeBalance_touched']),
          tpChargeBalance: calculateClass(a.tpChargeBalance, a['tpChargeBalance_touched']),
          pastDueInterestBalance: calculateClass(a.pastDueInterestBalance, a['pastDueInterestBalance_touched']),
          penaltyBalance: calculateClass(a.penaltyBalance, a['penaltyBalance_touched']),
          customFeesBalance: calculateClass(a.customFeesBalance, a['customFeesBalance_touched']),
          amortizationBalance: calculateClass(a.amortizationBalance, true)
        };
      }
    }

    function calculateClass(balance, touched) {
      if (!touched) return '';
      return balance.isZero() ? 'paid' : 'partially-paid';
    }

    that.changePriority = (amortization) => {
      if (!amortization.priority && that.priorityAmortizationIds.includes(amortization.id)) {
        // remove
        const idx = that.priorityAmortizationIds.indexOf(amortization.id);
        that.priorityAmortizationIds.splice(idx, 1);
      }

      if (amortization.priority && !that.priorityAmortizationIds.includes(amortization.id)) {
        // add
        that.priorityAmortizationIds.push(amortization.id);
      }

      // set in original amortization
      _.find(that.loan.amortizationSchedule.list, a => a.id === amortization.id).priority = amortization.priority;

      // convert to priorityAmortizationIds
      const priorityAmortizations = _.filter(that.loan.amortizationSchedule.list, a => a.priority);
      that.paymentRequest.priorityAmortizationIds = _.map(priorityAmortizations, a => a.id);

      // recalculate
      that.calculatePreview();
    };

    that.calculatePartTotal = (part) => {
      // check for balance
      let partBalance = part.replace('Amount', 'Balance');

      const sum = _.reduce(
        _.map(that.loan.amortizationSchedule.list, a => a[partBalance]),
        (a, b) => a.plus(b), new BigNumber(0)
      );

      return sum.toNumber();
    };

    that.calculateTotalCustomPayment = () => {
      that.paymentRequest.amount = bn(that.paymentRequest.principalAmount)
        .add(bn(that.paymentRequest.interestAmount))
        .add(bn(that.paymentRequest.cbuChargeAmount))
        .add(bn(that.paymentRequest.pfChargeAmount))
        .add(bn(that.paymentRequest.tpChargeAmount))
        .add(bn(that.paymentRequest.pastDueInterestAmount))
        .add(bn(that.paymentRequest.penaltyAmount))
        .add(bn(that.paymentRequest.customFeesAmount)).toNumber();
    };

    function bn(amount) {
      return amount ? new BigNumber(amount) : new BigNumber(0);
    }
  }
});