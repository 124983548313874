import _ from 'lodash';
import module from 'module';

const templateUrl = require('./new-stock-item.template.html');
module.component('newStockItem', {
  templateUrl: templateUrl,
  controller: function ($route, $location, notification, nav, command, pawnItemCache, http) {
    this.stockItemId = Number($route.current.params['itemId']);

    this.$onInit = async () => {
      if (this.stockItemId) {
        this.stockItem = await pawnItemCache.withParam(this.stockItemId).toPromise();

        if (!this.stockItem.inStock) {
          notification.show("Error", "Cannot edit this pawn stock item");
          $location.path(`/dashboard/stocked-items`);
        }
      }
    };

    const getKarats = (metal) => {
      if (!metal.components || metal.components.length === 0) {
        return metal.metalRateId ? [this.getFinenessByRateId(metal.metalRateId)] : []
      }
      return metal.components.reduce((prev, curr) => prev.concat(getKarats(curr)), []);
    };

    this.saveItem = async (item) => {
      // Create command instance
      const commandInput = {
        productId: item.productId,
        type: 'STOCKED',
        migrated: false,
        requestAmount: item.requestAmount,
        categoryId: item.category.id,
        classClassificationId: (item.classClassification && item.classClassification.id) || null,
        typeId: item.type.id,
        subtypeId: item.subtype ? item.subtype.id : null,
        customType: item.customType,
        quantity: item.quantity,
        defectIds: [],
        valuation: item.valuation || item.sellingPrice,
        sellingPrice: item.sellingPrice,
        overloadedValuation: item.overloadedValuation,
        deprecatedProductNumber: item.deprecatedProductNumber,
        remarks: item.remarks,
        attributes: item.attributes,
        plain: !item.withStones,
        weight: item.weight,
        stones: item.stones ? _.union(item.stones.valuable, item.stones.nonValuable) : null,
        origin: item.origin,
        metal: item.metal ? item.metal : null,
        autoDescription: item.autoDescription,
        karatsDescription: item.karatsDescription,
        inStock: true,
        status: item.status ? item.status : 'NEW'
      };

      await getOrCreateOverriddenMetalRate(commandInput.metal);

      // Add pawn item attributes
      if (commandInput.attributes && commandInput.attributes.length > 0) {
        _.forEach(commandInput.attributes, (attribute) => {
          Object.assign(attribute, {attributeTypeId: attribute.type.id});
        })
      }

      // Add pawn item stones
      if (commandInput.stones && commandInput.stones.length > 0) {
        _.forEach(commandInput.stones, (stone) => {
          Object.assign(stone, {stoneTypeId: stone.type.id});
          Object.assign(stone, {stoneColourId: stone.stoneColour ? stone.stoneColour.id : null});
          Object.assign(stone, {stoneRateId: stone.stoneRate ? stone.stoneRate.id : null});
          Object.assign(stone, {stoneCutGradeId: stone.stoneCutGrade ? stone.stoneCutGrade.id : null});
        })
      }

      const onSuccess = async () => {
        notification.show("Success", "Stock pawn item persisted");

        // If [id] is given item is edited and we have to go back by 2 steps
        // Otherwise item is created and only 1 step is required
        let step = 2;

        if (this.stockItemId) {
          step = 1;
          await pawnItemCache.withParam(this.stockItemId).refetch();
        }

        nav.back(step);
      };

      // Set uploaded file ids
      commandInput.fileIds = _.map(item.files, f => f.id);
      // If item id is given update it, otherwise create it

      if (this.stockItemId) {
        commandInput.itemId = this.stockItemId;
        command.execute('EditStockItem',
          commandInput, {nxLoaderText: 'Updating stock pawn item'})
          .success(onSuccess);
      } else {
        command.execute('AddNewItemToStock',
          commandInput, {nxLoaderText: 'Persisting stock pawn item'})
          .success(onSuccess);
      }
    };

    const getOrCreateOverriddenMetalRate = async (metal) => {
      // Create or fetch an existing pawn metal rate with the same fineness and price per gram.
      if (metal && metal.rate && metal.rate.overridden) {
        const metalRate = metal.rate;
        const pawnMetalRateRequest = {
          parentId: metalRate.id,
          systemDate: metalRate.systemDate,
          name: metalRate.name,
          metalTypeIds: metalRate.metalTypeIds,
          fineness: metalRate.fineness,
          pricePerGram: metalRate.pricePerGram,
          orderNo: 0,
          type: 'STOCKED'
        };
    
        const overriddenMetalRate = await http.post(`/products/pawns/get-or-override-metal-rate`, pawnMetalRateRequest, {nxLoaderText: 'Overriding metal price'}).toPromise();
        metal.metalRateId = overriddenMetalRate.id;
      }
    }
  }
});
