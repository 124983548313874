import module from 'module';
import ParameterizedLocalCache from 'shared/util/parameterized-local-cache';

module.factory('fileCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (fileId) => http.get(`/files/${fileId}`, {responseType: 'blob'}),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.fileCache,
    cacheName: 'fileCache'
  })
);
