import _ from "lodash";

import module from "module";

const templateUrl = require('./report-view.template.html');

import './report-view.style.less';

module.component('reportView', {
  templateUrl,
  bindings: {
    reportContent: '<',
    container: '<'
  },
  controller: function ($scope) {
    const that = this;

    that.container = that.container || null;
    that.divideDescription = false;
    that.maxRowsInDescription = 8;
    that.firstHalfDescription = [];
    that.secondHalfDescription = [];

    $scope.$watch('$ctrl.reportContent', () => {
      if (!that.reportContent) return;

      that.reportGenerated = true;
      that.reportColumnsNum = findColumnNumber(that.reportContent);
      that.hasData = checkIfHasData(that.reportContent);

      that.reportContent.forEach(sheet => sheet.limit = 100);

      that.splitDescriptionToHalfs();
    });

    that.splitDescriptionToHalfs = () => {
      const desc = Object.entries(that.reportContent[0].reportDescription);

      if (desc.length > that.maxRowsInDescription) {
        const firstHalfLength = Math.ceil(desc.length / 2);
        that.firstHalfDescription = that.objectFromEntries([...desc].splice(0, firstHalfLength));
        that.secondHalfDescription = that.objectFromEntries([...desc].splice(firstHalfLength, desc.length));
        that.divideDescription = true;
      } else {
        that.divideDescription = false;
      }
    }

    that.objectFromEntries = (arr) => {
      return arr.reduce((obj, [k, v]) => Object.assign(obj, {[k]: v}), {});
    }

    that.increaseLimit = () => {
      if (!that.reportContent) {
        return;
      }

      const notFullyDisplayedSheet = that.reportContent.find(sheet => sheet.limit < sheet.rows.length);
      if (notFullyDisplayedSheet) {
        notFullyDisplayedSheet.limit += 100;
      }
    };

    function checkIfHasData(reports) {
      return _(reports).map((r) => r.rows).flatten().value().length > 0
    }

    function findColumnNumber(reports) {
      return _(reports).map((report) => {
        return _(report.rows).map((a) => a.cells.length + report.xoffset).max();
      }).max();
    }

  }
});
