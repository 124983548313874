import module from 'module';
import _ from 'lodash';
import $ from "jquery";

const templateUrl = require('./account-picker.template.html');
module.component('accountPicker', {
  templateUrl,
  bindings: {
    'includeCustomer': '@includeCustomer',
    'onChoose': '&'
  },
  controller: function ($scope, $element, http, authentication, customerCache, productDefinitionService) {

    const that = this;

    const hidePopup = function () {
      $scope.$apply(function () {
        that.visible = false;
      });
    };

    $(document).click(hidePopup);

    that.visible = false;
    that.searchResults = [];
    that.customerName = '';
    that.productNumber = '';

    const productDefinitionSub = productDefinitionService.toObservable().subscribe(data => that.prductTypes = data);

    that.search = () => {
      if (that.customerName || that.productNumber) {

        // Prepare query parameters input
        const request = {pageSize: 10};
        if (that.customerName) request.customerName = that.customerName;
        if (that.productNumber) request.productNumber = [that.productNumber];

        http.post('/products/accounts/search', request).success((data) => {

          const searchResult = data.result;

          // Assign product type name to each search result
          for (let r of searchResult) {
            const productType = _.find(that.prductTypes, {id: r.definitionId});
            r.productName = productType.productName;
          }

          that.searchResults = searchResult;
          that.visible = true;
          that.customerName = '';
          that.productNumber = '';
        });
      }
    };

    that.choose = (account, $event) => {
      $event.stopPropagation();

      that.onChoose({account: account});
      that.visible = false;
      that.searchResults = [];
    };

    that.$postLink = () => {
      $element.click(false);
    };

    that.$onDestroy = () => {
      productDefinitionSub.unsubscribe();
      $(document).off('click', hidePopup);
    }
  }
});
