import module from 'module';
import _ from 'lodash';
import {addAccountLabels} from 'components/general-ledger/common/gl.utils';

const templateUrl = require('./transactions-details.template.html');

module.component('transactionsDetails', {
  templateUrl : templateUrl,
  controller: function ($scope, $routeParams, $filter, glMappingsService, systemPropertyCache, actionCategoryCache, command, $q, transactionCategoryService) {
    const that = this;

    that.selectConfigAccounts = {
      placeholder: 'Select account',
      searchField: 'label',
      valueField: 'fullCode',
      labelField: 'label',
      maxItems: 1
    };

    that.reportGroupSelection = [
      {
        value: null,
        label: ''
      },
      {
        value: 'BILLS_PAYMENT',
        label: 'Bills Payment'
      },
      {
        value: 'DRAGONPAY',
        label: 'Dragonpay'
      },
      {
        value: 'FOREX',
        label: 'Forex'
      },
      {
        value: 'GCASH',
        label: 'G-Cash'
      },
      {
        value: 'OTHERS',
        label: 'Others'
      },
      {
        value: 'PAWNSHOP',
        label: 'Pawnshop'
      },
      {
        value: 'REMITTANCE',
        label: 'Remittance'
      }
    ];

    const actionType = _.get($routeParams, 'actionType');
    that.categoryId = Number(_.get($routeParams, 'id'));

    that.actionCode = actionType.toUpperCase().replace('-', '_');
    that.actionCodeLabel = `\'${$filter('prettyEnum')(that.actionCode)}\'`;

    that.entryType = _.includes(['CASH_IN'], that.actionCode) ? 'DEBIT' : 'CREDIT';

    that.ledgerAccounts = [];
    that.categories = [];
    that.name = undefined;
    that.code = undefined;
    that.accountCode = undefined;
    that.parent = undefined;
    that.reportGroup = null;

    const OFFICIAL_RECEIPT = 'OFFICIAL_RECEIPT';
    const OFFICIAL_RECEIPT_IN_CASH = 'OFFICIAL_RECEIPT_IN_CASH';
    const OFFICIAL_RECEIPT_IN_CHECK = 'OFFICIAL_RECEIPT_IN_CHECK';

    that.availableActionTypes = (actionType === OFFICIAL_RECEIPT) ? [OFFICIAL_RECEIPT_IN_CASH, OFFICIAL_RECEIPT_IN_CHECK] : [];
    that.chosenActionTypes = that.availableActionTypes.slice();

    that.supportsLedger = false;

    const actionCategorySub = actionCategoryCache.toObservable().subscribe(actionCategories => {
      if (that.categoryId) {
        const category = actionCategories.filter(category => category.actionType === actionType);
        const actionCategory = _.find(category, {id: that.categoryId});
        that.name = actionCategory.name;
        that.reportGroup = actionCategory.reportGroup;
        that.accountCode = actionCategory.ledgerAccountFullCode;
      }
    });

    const systemPropertiesSub = systemPropertyCache.toObservable().subscribe(properties => {
      const ledgerSupportProperty = _.find(properties, {code: 'LEDGER_SUPPORT'});
      that.supportsLedger = ledgerSupportProperty && ledgerSupportProperty.value === 'TRUE';
    });

    const ledgerAccountSub = glMappingsService.accounts.toObservable().subscribe(ledgerAccounts => {
      // Apply labels to accounts applicable for
      that.ledgerAccounts = addAccountLabels(ledgerAccounts);
    });

    that.save = async () => {
      if (that.categoryId) {
        await that.updateCategory();
      } else {
        (that.actionCode === OFFICIAL_RECEIPT) ? await that.saveOfficialReceiptCategory() : await that.saveCategory();
      }
    }

    that.saveOfficialReceiptCategory = async () => {
      $q.all(
        that.chosenActionTypes.map((actionType) => command.execute('CreateActionCategory', {
          name: that.name,
          actionType: actionType,
          ledgerAccountFullCode: that.accountCode
        }, {nxLoaderText: 'Creating category'}).toPromise())
      ).then(() => {
        actionCategoryCache.refetch();
        transactionCategoryService.redirectBack();
      });
    };

    that.saveCategory = async () => {
      const request = {
        name : that.name,
        actionType : that.actionCode,
        ledgerAccountFullCode : that.accountCode,
        reportGroup: that.reportGroup
      };
      transactionCategoryService.save(request);
    };

    that.updateCategory = async () => {
      const request = {
        id: that.categoryId,
        name: that.name,
        reportGroup: that.reportGroup,
        ledgerAccountFullCode: that.accountCode
      };
      
      await transactionCategoryService.update(request);
    }

    that.cancel = () => {
      transactionCategoryService.redirectBack();
    };

    that.noActionTypeChosen = () => actionType === OFFICIAL_RECEIPT && that.chosenActionTypes.length === 0;

    that.$onDestroy = () => {
      systemPropertiesSub.unsubscribe();
      ledgerAccountSub.unsubscribe();
      actionCategorySub.unsubscribe();
    }

  }
});


