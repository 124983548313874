import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import {Subscription} from 'rxjs/Subscription';
import _ from 'lodash';
import moment from 'moment';
import module from 'module';

const templateUrl = require('./group-loans-batch.template.html');
module.component('groupLoansBatch', {
  templateUrl: templateUrl,
  bindings: {
    batches: '<',
    batch: '<',
    batchChangeAction: '<',
  },
  controller: function ($route, $location, $scope, $element, http, breadcrumbs, groupCustomerCache, notification, confirmation,
                        productDefinitionService, userCache, glMappingsService, reportModal, loanProductsCache, loanService, command,
                        postingDateService) {
    let that = this;
    let customerId = $route.current.params['customerId'];

    this.$onChanges = (changes) => {
      if (!changes.batch || !that.batch) return;

      postingDateService.getPostingDateByBranchId(that.batch.loans[0].branchId)
        .then(date => that.branchPostingDate = date);
    };

    that.selectBatch = (batch) => {
      // NOTE: we don't update the url with the chosen batchId because it would cause a full page reload :(
      this.batchChangeAction({
        batch,
      });
    };

    that.disbursmentForm = () => {
      const params = {groupLoanId: that.batch.id};
      let reportCode = "group-loan-disbursment";
      reportModal.display({
        params: params,
        reportCode: reportCode
      });
    };

    that.disbursmentWithSignatures = () => {
      $location.path(`/customer/${customerId}/group-loans/${that.batch.id}/disbursment`);
    };

    that.assignLoanBatchToGroup = () => {
      $location.path(`/customer/${customerId}/group-loans/${that.batch.id}/assign`);
    };

    that.paymentReport = () => {
      let params = {groupLoanId: that.batch.id};
      let reportName = 'LoanPaymentsPerLoanReport';
      reportModal.display({
        params: params,
        reportCode: reportName
      });
    };

    that.deleteBatch = () => {
      confirmation('Do you want to delete the selected group loan?', () => {
        command.execute('DeleteGroupLoan', {id: that.batch.id})
          .success(() => {
          groupCustomerCache.evict();
          $route.reload();
        })
      });
    };


    that.releaseGroupByCC = () => {
      if (that.isGrantDateValid()) {
        $location.path(`/customer/${customerId}/group-loans/${that.batch.id}/release/check`);
      } else {
        that.showGrantDateErrorNotification();
      }
    };

    that.isGrantDateValid = () => {
      return moment(that.branchPostingDate)
        .isSameOrAfter(that.batch.grantDate);
    };

    that.showGrantDateErrorNotification = () => {
      notification.show("Error", "Unable to perform release because date granted is in the future.");
    };

    that.editBatch = () => $location.path(`/customer/${customerId}/loans/${that.batch.id}/edit`);

    that.$postLink = () => {
      $element.on('show.bs.collapse', () => {
        $element.find('.collapse.in').collapse('hide');
      });
    }
  }
});