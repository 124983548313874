import module from 'module';
import {ReplaySubject} from 'rxjs/ReplaySubject'

/**
 * DO NOT inject this class directly to components. Use classes that inherits StatusBasedService.
 *
 * This service is responsible for making periodical call to /management/status endpoint.
 * It contains bundle with vulnerable data that must be synchronized with frontend app.
 *
 * Status endpoint contains:
 * - workingDay - workingDay data of current branch (depending on authentication context)
 * - userCounter - user counter of current user (depending on authentication context)
 * - waitForCashTransfer
 */
class StatusService {
  constructor(http, $interval, config) {
    this.http = http;
    this.$interval = $interval;
    this.config = config;
    this.lastStatusSub = new ReplaySubject(1); // cache size 1

    this.initInterval();
  }

  initInterval() {
    // initial call
    this.refresh();
    this.interval = this.$interval(() => this.refresh(), this.config.statusRefreshInterval);
  }

  /**
    Status is refreshed periodically based on 'statusRefreshInterval' config property.
    This method is also exposed so it can be refreshed manually (i.e. when userCounter is changed)
   */
  refresh() {
    this.http.get(`/management/status`, {nxLoaderSkip: true}).success(status => {
      this.lastStatusSub.next(status);
    });
  }

  /**
   * @returns Observable that contains status bundle
   */
  getStatusObs() {
    return this.lastStatusSub;
  }
}

module.service('statusService', StatusService);
