import module from "module";
import StateMachine from "javascript-state-machine";

import './batch-sss-pension.service'
import './batch-sss-pension-upload.component'
import './batch-sss-pension-result.component'
import './batch-sss-pension-result-list.component'
import './batch-sss-pension-correction.component'

const templateUrl = require('./batch-sss-pension.template.html');
module.component('batchSssPension', {
  templateUrl: templateUrl,
  controller: function () {
    const that = this;
    const initDisplayMode = 'UPLOAD';

    that.displayMode = initDisplayMode;
    that.fileId = null;
    that.sm = null;

    const createStateMachine = (displayMode) => new StateMachine({
      init: displayMode === 'RESULTS' ? 'resultList' : 'upload',
      transitions: [
        {name: 'gotoUploadResult', from: 'upload', to: 'result'},
        {name: 'gotoCorrection', from: 'resultList', to: 'correction'},
        {name: 'gotoCorrectionResult', from: 'correction', to: 'result'},
        {name: 'gotoResultDetails', from: 'result', to: 'correction'}
      ]
    });

    that.setDisplayMode = (mode) => {
      const nnMode = mode || initDisplayMode;
      that.displayMode = ['UPLOAD', 'RESULTS'].includes(nnMode) ? nnMode : initDisplayMode;
      that.sm = createStateMachine(that.displayMode);
      that.fileId = null;
    };

    that.gotoUploadResult = (fileId) => {
      that.fileId = fileId;
      that.sm.gotoUploadResult();
    };

    that.gotoCorrection = (fileId) => {
      that.fileId = fileId;
      that.sm.gotoCorrection();
    };

    that.gotoCorrectionResult = (fileId) => {
      that.fileId = fileId;
      that.sm.gotoCorrectionResult();
    };

    that.gotoResultDetails = (fileId) => {
      that.fileId = fileId;
      that.sm.gotoResultDetails();
    };

    that.$onInit = () => {
      that.setDisplayMode(initDisplayMode);
    }
  }
});
