import module from 'module';
import _ from 'lodash';

const templateUrl = require('./pawn-withdrawal.template.html');
module.component('customerPawnWithdrawal', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, dict, customerCache, productDefinitionService,
                        confirmation, notification, pawnItemTypeCache, nav) {
    let that = this;

    let customerId = $route.current.params['customerId'];
    let pawnId = $route.current.params['pawnId'];

    const s = pawnItemTypeCache.toObservable().subscribe(types => {
      that.typeMap = {};
      for (let t of types) {
        that.typeMap[t.id] = t.name;
      }
    });

    const s2 = customerCache.pawns(customerId).toObservable().subscribe(pawns => {
      that.pawn = _.find(pawns, (l) => l.id == pawnId);
      that.items = _.filter(that.pawn.items, it => (it.status == 'PAWNED_ACTIVE' || it.status == 'PAWNED_EXPIRED'));
    });

    that.redirectBack = () => $location.path(`/customer/${customerId}/pawns/${pawnId}`);

    that.withdraw = () => {
      confirmation('Do you want to withdraw the pawn for auction?', () => {
        http.post(`/products/pawns/${pawnId}/withdrawal`).success(() => {
          notification.show("Pawn successfully withdrawn for auction.");
          customerCache.pawns(customerId).refetch();
          nav.back();
        })
      })
    };

    that.$onDestroy = () => {
      s.unsubscribe();
      s2.unsubscribe();
    };
  }
});