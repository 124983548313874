import module from 'module';
import './stock-item-batch-upload.style.less';

const templateUrl = require('./stock-item-batch-upload.template.html');
class StockItemBatchUpload {
  constructor(command) {
    this.command = command;
  }

  async upload() {
    this.successMessage = "";
    this.warningMessage = "";
    
    let fileId = this.file[0].id;
    await this.command.execute('StockItemBatchUpload', {fileId: fileId}).toPromise()
      .then(response => {
        if (response && response.output) {
          let ignoredItemCodes = response.output.ignoredItemCodes;

          if (ignoredItemCodes && ignoredItemCodes.length) {
            let message = "The following items have not been uploaded:";

            for (let i = 0; i < ignoredItemCodes.length; i++) {
              message += `\n${i + 1}. ${ignoredItemCodes[i]}`;
            }

            this.warningMessage = message;
          } else {
            this.successMessage = "All items have been uploaded."
          }
        }
      });
  }
}

module.component('stockItemBatchUpload', {
  templateUrl,
  controller: StockItemBatchUpload
});