import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('organizationCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/management/organizations'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.organizationsConfig,
    cacheName: 'organizationsConfig'
  }));
