import module from 'module';
import angular from 'angular';

import './custom-fee-list.style.less';
import templateUrl from './custom-fee-list.template.html';

class CustomFeeList {
  constructor(branchService, http) {
    this.branchService = branchService;
    this.http = http;
  }

  $onInit() {
    this.branchesSubs = this.branchService.toObservable().subscribe(branches => {
      this.branches = branches;
    });

    if (this.productGroup === 'LOAN') {
      //load loan isurance accounts only for loan context
      const fetchInsuranceAccounts = async () => {
        const accounts = await this.http.get('/products/accounts?purpose=INSURANCE').toPromise();
        const customerEffectiveNamesById = {};
        for(let account of accounts) {
          const { customerId } = account;
          if(!customerEffectiveNamesById[customerId]) {
            customerEffectiveNamesById[customerId] = this.http.get(`/customers/${customerId}`).toPromise().then(this.customerService.effectiveName);
          }
        }

        for(let account of accounts) {
          account.customer = await customerEffectiveNamesById[account.customerId];
        }
        return accounts;
      }

      fetchInsuranceAccounts().then(insuranceAccounts => {
        this.insuranceAccounts = insuranceAccounts;
      });
    }
  }


  $onDestroy() {
    this.branchesSubs.unsubscribe();
  }

  addNewFeeDefinition() {
    let defaultValues = {};
    if (this.productGroup === 'LOAN') {
      defaultValues.productStatuses = ['INACTIVE'];
      defaultValues.calculateOn = 'CREATE';
      defaultValues.applyOn = 'LOAN_RELEASE';
    } else if (this.productGroup === 'DEPOSIT') {
      defaultValues.productStatuses = ['ACTIVE'];
      defaultValues.calculateOn = 'DEPOSIT_PRETERMINATION';
      defaultValues.applyOn = 'DEPOSIT_PRETERMINATION';
    }

    let newFeeDefinition = {
      feeClass: 'CUSTOM',
      feeType: 'FIXED',
      calculationMethod: 'FIXED_AMOUNT',
      operationGroup: 'APPLY_FEE',
      //defaultValues
      feeName: '',
      enabled: true,
      fixedAmount: 0,
      calculateOn: 'CREATE',
      applyOn: 'LOAN_RELEASE',
      overdraftStrategy: 'OVERDRAFT',
      roundingMode: 'CENTAVO',
      ...defaultValues
    };
    this.feeDefinitions.push(newFeeDefinition);
    this.editFeeDefinition(newFeeDefinition, false);
  }

  editFeeDefinition(feeDefinition, storeOldValue = true) {
    this.selectedFeeForEdit = feeDefinition;
    if(storeOldValue) {
      this.feeDefinitionOldValues = angular.copy(feeDefinition);
    }
    //settings this value will hide the cancel/save buttons from parent controller
    this.form.wizardMode = true;
  }

  removeFeeDefinition(index) {
    this.feeDefinitions.splice(index, 1);
    this.form.$setDirty();
    if (this.onChange) this.onChange();
  }

  cancelFeeDefinitionEdition() {
    if(this.feeDefinitionOldValues) {
      Object.assign(this.selectedFeeForEdit, this.feeDefinitionOldValues);
    } else {
      // remove newly added fee definition
      this.feeDefinitions.pop();
    }
    this.closeCustomFeeController();
    if (this.onCancel) this.onCancel();
  }

  save() {
    this.closeCustomFeeController();
    if (this.onChange) this.onChange();
  }

  closeCustomFeeController() {
    // we use LOAN_PAYMENT applyOn to denote that fee should be applied yearly
    // in flags adds custom option
    if(this.selectedFeeForEdit) {
      const fee = this.selectedFeeForEdit;
      if(fee.applyOn === 'LOAN_PAYMENT') {
        fee.applyOn = 'LOAN_RELEASE';
      }
    }

    this.selectedFeeForEdit = null;
    this.feeDefinitionOldValues = null;
    //show the parent controller cancel/save buttons
    this.form.wizardMode = false;
  }

}

module.component('customFeeList', {
  templateUrl,
  bindings: {
    form           : '=',
    feeDefinitions : '=',
    //DEPOSIT, LOAN
    productGroup   : '<',
    onCancel       : '&',
    onChange       : '&'
  },
  controller: CustomFeeList
});
