import module from 'module';
import _ from 'lodash';
import moment from 'moment';
import {Subject} from 'rxjs/Subject'
import 'rxjs/add/operator/combineLatest';

import CashIcon from './cash_icon.png';
import CheckIcon from './check_icon.jpeg';
import DateIcon from './date_icon.png';
import PostingDateIcon from './posting_date_icon.png';
import refreshService from 'shared/util/refresh.service'

const templateUrl = require('./breadcrumb.template.html');
module.component('breadcrumb', {
  templateUrl,
  bindings: {
    organizationType: '<',
    backLink: '<'
  },
  controller: function ($element, $location, $filter, breadcrumbs, config, loader, confirmation, authentication,
                        singleUserCache, branchService, userCache, userCounterService, formattingService, systemPropertyCache,
                        cashTransferCheckService, refreshService) {
    const that = this;
    that.breadcrumbs = breadcrumbs;
    that.isRootPath =
      breadcrumbs.get().length <= 1
      || (Object.keys(authentication.permissions).length === 1 // Can only change password
      && authentication.permissions['MNG_PASSWORD_WRITE'] === true);

    that.contextBranches = [];
    that.branchContextId = null;
    that.phantomUser = authentication.context.phantom;
    that.template = null;

    that.selectizeConfig = {
      maxItems: 1,
      labelField: ['name'],
      valueField: 'id',
      sortField: 'name',
      searchField: ['name'],
      onChange: that.onBranchContextUpdate
    };

    const cashTransferSub = cashTransferCheckService.toObservable()
      .subscribe(shouldBlock => this.blockActions = shouldBlock);

    that.navigateBack = () => {
      if(that.backLink) {
        $location.path(that.backLink);
      } else if (!that.isRootPath) {
        const parentPath = _.nth(breadcrumbs.get(), -2).path;
        $location.path(parentPath);
      }
    };

    that.logout = () => {
      authentication.logout();
    };

    function eraseCache(removeToken) {
      userCounterService.refresh();
      refreshService.refreshPage({clearToken: removeToken});
    }


    that.refresh = () => {
      confirmation('Do you want to perform a full page reload?', () => {
        loader.show('Reloading page...');
        authentication.refresh(() => {
          // success
          systemPropertyCache.toObservable().first().subscribe(properties => {
            let uiCommaSeparator = _.find(properties, {code: 'UI_CASH_COMMA_SEPARATOR'});
            formattingService.setUiCommaSeparator(uiCommaSeparator ? uiCommaSeparator.value === 'TRUE' : false);
          });
          eraseCache(false);
        }, () => {
          // error
          eraseCache(true);
        });
      });
    };

    that.onBranchContextUpdate = () => {
      if (!that.branchContextId) {
        return;
      }
      confirmation('Do you want to switch branch context (it will reload page)?', () => {
        loader.show('Reloading page...');
        authentication.override(that.branchContextId, () => {
          userCounterService.refresh();
          console.log('Context branch switched to: ' + that.branchContextId);
          window.location.reload();
        });
      });
    };

    const postLinkSubject = new Subject();
    that.$postLink = () => {
      postLinkSubject.next();
      postLinkSubject.complete();
    };

    const obs = singleUserCache.user(authentication.context.id).toObservable()
      .combineLatest(userCounterService.toObservable(), (user, userCounter) => {
        return Object.assign(user, {userCounter: userCounter});
      })
      .combineLatest(branchService.toObservable(), (user, branches) => {
        // Read list of context branches based on [branchIds]
        const branchIds = authentication.context.branchIds;
        that.contextBranches = _.filter(branches, (b) => _.includes(branchIds, b.id) && !b.closed);
        // Extend user data with branch details
        const userBranch = _.find(branches, {id: user.branchId});
        if (!that.branchContextId) that.branchContextId = userBranch.id;
        return Object.assign(user, {branch: userBranch});
      })
      .combineLatest(userCache.toObservable(), (user, users) => {
        // If authentication token contains imposed user id -> fetch details
        const imposedUserId = authentication.context.imposedUserId;
        if (imposedUserId) {
          const imposedUser = _.find(users, {id: imposedUserId});
          user = Object.assign(user, {imposedUser: imposedUser});
        }
        return user;
      })
      //wait for $postLink cycle (this controller's element and its children have been linked)
      .combineLatest(postLinkSubject, (user) => user);

    const subscription = obs
      .subscribe(user => {
      that.user = user;
      // If imposed user is defined -> override full name
      let fullName = (u) => `${u.firstName} ${u.middleName || ''} ${u.lastName}`;
      that.user.fullName = user.imposedUser ? fullName(user.imposedUser) : fullName(user);
      that.template = that.createInfoPopup(user);

      const element = $element.find('.breadcrumb__info-details--more');
      element.popover('destroy');
      element.popover({
        html: true,
        content: that.template,
        container: 'breadcrumb',
        trigger: 'click'
      });
    });

    that.getLabel = (value) => {
      if (typeof value === 'object') {
        return $filter('translate')(value.value, value.type);
      } else {
        return value;
      }
    };

    that.createInfoPopup = (user) => {
      const templateAttrs = [];

      // Add common attributes
      templateAttrs.push(...[{
        icon: CashIcon,
        label: 'Total cash:',
        value: $filter('php')(user.userCounter.totalCash),
      }, {
        icon: CheckIcon,
        label: 'Total check:',
        value: $filter('php')(user.userCounter.totalCheck),
      }, {
        icon: DateIcon,
        label: 'System date:',
        value: moment(user.branch.postingDate).format('MM/DD/YYYY'),
      }, {
        icon: PostingDateIcon,
        label: 'Posting date:',
        value: moment(user.branch.effectivePostingDate).format('MM/DD/YYYY'),
      }]);

      const templateBody = templateAttrs.map(item =>
          `<div class="breadcrumb__more-popup-item">
          <img class="breadcrumb__more-popup-icon" src="${item.icon}">
          <div class="breadcrumb__more-popup-label">${item.label}</div>
          <div class="breadcrumb__more-popup-value">${item.value}</div>
          </div>`).join('');

      const template = `
        <div class="breadcrumb__more-popup-container">
        ${templateBody}
      </div>
        `;
      return template;
    };

    that.$destroy = () => {
      subscription.unsubscribe();
      cashTransferSub.unsubscribe();
    };
  }
})
;
