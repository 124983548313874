import module from 'module';

const templateUrl = require('./related-people.template.html');
module.component('customerProfileRelatedPeople', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '=',
    'minRelatedPeople' : '<'
  },
  controller: function ($scope, $location, notification, dict, branchService, confirmation) {
    const defaultUrl = '/customer/{}/profile';
    var that = this;
    that.dict = dict;
    that.isRelativeSignatory = false;

    if (that.minRelatedPeople == null) {
      that.minRelatedPeople = 0;
    }

    that.addRelative = (customer) => {
      if(!customer) return;
      if (customer.customerId == that.profile.id) {
        notification.show('Warning', 'Customer cannot be related to himself.');
        return;
      }
      if (_.find(that.profile.relatives, {relativeCustomerId: customer.customerId})) {
        notification.show('Warning', 'Customer has already been added.');
        return;
      }
      const r = customerSearchToRelative(customer);
      that.profile.relatives.push(r);
    };

    that.removeRelative = (rel) => {
      var array = that.profile.relatives;
      var index = array.indexOf(rel);
      if (that.checkRelationship(rel.typeId, that.dict.RELATIVE_TYPE) && that.profile.customerType === 'CORPORATE') {
        confirmation(`Deleting this relationship will remove this customer as signatory from ${that.profile.effectiveName} products.
        Do you want to proceed?`, () => {array.splice(index, 1);});
      } else {
        array.splice(index, 1);
      }
    };

    that.isAnyRelativeSignatory = (relatives, relativeTypes) => {
      const signatoryType = relativeTypes.find(rel => 'SIGNATORY' === rel.code);
      const relative = relatives.find(rel => signatoryType.id === rel.typeId);
      that.isRelativeSignatory = relative ? true : false;
    };

    that.signatoryWarning = (relative) => {
      let existingRelative = that.startingRelatives.find(r => r.id === relative.id);
      if (existingRelative
        && that.profile.customerType === 'CORPORATE'
        && that.checkRelationship(existingRelative.typeId, that.dict.RELATIVE_TYPE)
        && !that.checkRelationship(relative.typeId, that.dict.RELATIVE_TYPE)) {
          notification.show('Warning', 'Changing type to non-Signatory. Relative will be removed from products.')
      }
    };

    that.checkRelationship = (id, relativeTypes) => {
      const relative = relativeTypes.find(value => id === value.id);
      return relative && relative.code === 'SIGNATORY';
    };

    //wait for profile to be resolved
    $scope.$watch('$ctrl.profile.relatives', () => {
      if (!that.startingRelatives) {
        that.startingRelatives = angular.copy(that.profile.relatives);
      }
      if (_.isArray(that.profile.relatives) && !_.isEmpty(that.profile.relatives)) {
        branchService.toObservable().first()
          .subscribe(bs =>  {
            that.profile.relatives.forEach(r => {
              r.branchName = (_(bs).find({id: r.branchId})|| {}).name
            });
          });
      }
    }, true);

    that.openCustomer = (customerId) => {
      let redirectionUrl = defaultUrl.replace('{}', customerId);
      $location.path(redirectionUrl);
    };

    const customerSearchToRelative = (customer) => {
      return {
        effectiveName: customer.name,
        birthDate : customer.birthDate,
        customerNumber : customer.customerNumber,
        branchId : customer.branchId,
        branchName : customer.branch.name,
        relativeCustomerId: customer.customerId,
        withdrawalLimit: customer.withdrawalLimit,
        typeId : null,
        beneficiary : false,
        dependent : false,
      }
    }
  }
});
