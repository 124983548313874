import module from 'module';
import _ from 'lodash';
const templateUrl = require('./misc-clear-system-cache.template.html');

module.component('miscClearSystemCache', {
  templateUrl,
  controller: function (branchService, confirmation, http, $filter, notification) {
    const that = this;

    that.execute = () => {
      confirmation(`Do you want to clear the system cache?`, () => {
        http.doDelete(`/system/cache`)
          .success(() => notification.show('Cache cleared successfully'));
      });
    };

  }
});
