import module from 'module';
import {Subscription} from 'rxjs/Subscription';
import _ from 'lodash';

import './group-loan-information.component'

const templateUrl = require('./group-loan-check-release.template.html');
module.component('customerGroupLoanCheckRelease', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $location, http, confirmation, notification, customerCache, loanCache,
    modalPrintPreviewService) {
    const that = this;

    let customerId = $route.current.params['customerId'];
    let groupLoanId = $route.current.params['groupLoanId'];

    const subscription = new Subscription();

    http.get(`/products/loans/group-loan/${groupLoanId}`).success((groupLoan) => {
      that.groupLoan = groupLoan;

      if (that.groupLoan) {
        that.groupLoan.individualLoans = [];
        that.groupLoan.loanIds.map(loanId => {
          let loanSub = loanCache.loan(loanId).toObservable()
            .subscribe((loanFromServer) => {
              let customerSub = customerCache.profile(loanFromServer.customerId).toObservable().subscribe((customer) => {
                loanFromServer.customer = customer.effectiveName;
              });
              subscription.add(customerSub);
              that.groupLoan.individualLoans.push(loanFromServer);
            });
          subscription.add(loanSub);
        });
      }
    });

    that.$onDestroy = () => {
      subscription.unsubscribe();
    };

    that.redirectBack = () => {
      $location.path(`/customer/${customerId}/group-loans/${groupLoanId}`);
    }

    that.cancelChanges = () => {
      that.redirectBack();
    };

    that.save = () => {
      http.post(`/products/loans/group-loan/${groupLoanId}/release/cashiers-check`, {
        cashiersCheckNumber: that.checkNumber,
        remarks: that.remarks,
      }).success((res) => {
        //since on the backend the root group lease operation is missing, pick any from response
        //(using head as random function :) )
        const operationId = _.head(res).id;
        notification.show('Cashier\'s check successfully created.');
        customerCache.loans(customerId).refetch();
        that.groupLoan.loanIds.map(loanId => {
          loanCache.loan(loanId).refetch();
        });
        modalPrintPreviewService.show('CREATE_CASHIER_CHECK_FOR_LOAN_RELEASE', {operationId: operationId},
          () => that.redirectBack());
      })
    }
  }
});
