import module from "module";
import _ from "lodash";

import templateUrl from './currencies-denomination.template.html';


class CurrenciesDenomination {
  constructor($filter, http, authentication, notification, systemPropertyCache, currencyCache) {
    this.$filter = $filter;
    this.http = http;
    this.authentication = authentication;
    this.notification = notification;
    this.systemPropertyCache = systemPropertyCache;
    this.currencyCache = currencyCache;

  }

  $onInit() {
    this.userBranch = this.authentication.context.branchId;
    this.denominationValues = {};
    this.organizationCurrencyIsoCode = 'PHP';
    this.denominations = {};

    this.disableSolver = this.disableSolver || false;
    this.autoDenominations = {};

    Promise.all([this.systemPropertyCache.toPromise(),
      this.currencyCache.toPromise()])
      .then(([properties, currencies]) => {
        this.currencies = currencies;
        const prop = _.find(properties, {code: 'MAIN_CURRENCY_ISO_CODE'});
        this.organizationCurrencyIsoCode = prop ? prop.value : this.organizationCurrencyIsoCode;

        const mainCurrencyIdx = _.findIndex(this.currencies, it => it.isoCode === this.organizationCurrencyIsoCode);
        if (mainCurrencyIdx > -1) {
          this.currencies.splice(mainCurrencyIdx, 1);
        }
        this.denominationLevel = _.find(properties, {code: 'DENOMINATION_LEVEL'}).value;
        this.initDenominations();
      });

  }

  fetchAutoDenomination(currencyId) {
    if (!this.denominationLevel || this.denominationLevel === 'NONE') return null;

    this.http.get(`/currencies/denomination/branches/${this.userBranch}?currencyId=${currencyId}`, {}, {nxLoaderText: 'Loading auto denomination'})
      .toPromise()
      .then((branchDenomination) => {
        this.denominationValues[currencyId] = branchDenomination.sum;
        this.autoDenominations[currencyId] = {
          incoming: {
            denomination: {
              currencyId: branchDenomination.currencyId,
              units: branchDenomination.summary
            }
          }
        };
      })
      .catch(() => this.notification.show("Failed to load auto denomination"));
  }

  initDenominations() {
    for (let currency of this.currencies) {
      this.autoDenominations[currency.id] = {};
      this.denominationValues[currency.id] = 0;
      this.denominations[currency.id] = {
        denominationBundle: {},
        currencyId: currency.id
      };

      this.fetchAutoDenomination(currency.id);
    }
  };
}

module.component('currenciesDenomination', {
  bindings: {
    denominations: '=',
    readOnly: '<'
  },
  templateUrl,
  controller: CurrenciesDenomination
});
