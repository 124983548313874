import module from 'module';
import LocalCache from 'shared/util/localCache';

module.factory('riskAssessmentEntryCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/management/risk/assessment'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.riskAssessmentEntries,
    cacheName: 'riskAssessmentEntries'
  })
);