import module from 'module';

const templateUrl = require('./term-deposit-fees.template.html');

class TermDepositFees {
  constructor($scope, feeDefinitionsCache) {
    this.$scope = $scope;
    this.feeDefinitionsCache = feeDefinitionsCache;
  }

  $onInit() {
    this.$scope.$watch('$ctrl.deposit', () => {
      this.propertyBasedFees = [{
        feeName: "Documentary stamp",
        feeClass: 'DOC_STAMP',
        percentageAmount: this.deposit.docStamp,
        feeType: 'PERCENTAGE',
        feeTypes: ['PERCENTAGE']
      },{
        feeName: 'Withholding tax',
        feeClass: 'WITHHOLDING_TAX',
        percentageAmount: this.deposit.withholdingTax,
        feeType: 'PERCENTAGE',
        feeTypes: ['PERCENTAGE'],
        taxExemptionThreshold: this.deposit.taxExemptionThreshold
      }];

      this.feeDefinitionsCache.toPromise().then(feeDefs => {
        let depositTypeFeeDefs = feeDefs
          .filter(fd => fd.feeClass === 'CUSTOM')
          .filter(fd => fd.enabled)
          .filter(fd => fd.productDefinitionId === this.deposit.productDefinitionId);

        this.feeDefinitions = [
          ...this.propertyBasedFees,
          ...depositTypeFeeDefs,
          ...(this.deposit.feeDefinitions || []).filter(feeDef => !feeDef.id)
        ];

        this.deposit.feeDefinitions = this.feeDefinitions.filter(fd => fd.feeClass === 'CUSTOM');
      });

    });

  }

  onSave() {
    this.deposit.feeDefinitions = this.feeDefinitions.filter(fd => fd.feeClass === 'CUSTOM');

    //for property based fees updates values
    this.feeDefinitions.filter(fd => fd.feeClass !== 'CUSTOM').forEach((fd) => {
      if (fd.feeClass === 'DOC_STAMP') {
        this.deposit.docStamp = fd.percentageAmount;
      } else if (fd.feeClass === 'WITHHOLDING_TAX') {
        this.deposit.withholdingTax = fd.percentageAmount;
        this.deposit.taxExemptionThreshold = fd.taxExemptionThreshold;
      }
    });
  }
}

module.component('termDepositFees', {
  templateUrl,
  bindings: {
    deposit: '=',
    form: '='
  },
  controller: TermDepositFees
});
