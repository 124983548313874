import module from 'module';
import _ from 'lodash';


module.factory('transactionDetailsBuilder', function ($filter) {
  let service = {};

  service.buildPhpIfNotZero = (label, amount) => {
    if (!amount || amount === '' || amount === 0) {
      return {};
    }

    amount = parseFloat(amount);

    if (isNaN(amount) || !isFinite(amount)) {
      return {}
    }
    return {
      label,
      value: $filter('php')(amount)
    }
  };

  service.buildIfNotEmpty = (label, str) => {
    if (str && str !== '') {
      return {
        label,
        value: str
      }
    }
    return {};
  };


  service.build = (currentTransaction) => {
    if (!currentTransaction) {
      return [];
    }
    let baseValueDetails = service.buildBase(currentTransaction);
    baseValueDetails.push(...service.buildBankSection(currentTransaction));
    baseValueDetails.push(...service.buildCheckSection(currentTransaction));
    baseValueDetails.push(...service.buildFees(currentTransaction));
    baseValueDetails.push(...service.buildStatus(currentTransaction));
    baseValueDetails.push(...service.buildPayments(currentTransaction));
    return baseValueDetails;
  };

  service.buildBase = (currentTransaction) => {
    const formatUser = user => `${user.firstName} ${user.lastName}`;

    return [
      service.buildIfNotEmpty('Transaction no', currentTransaction.id),
      {
        label: 'User initiating',
        value: currentTransaction.registrationUser ? formatUser(currentTransaction.registrationUser) : '-',
      },
      service.buildPhpIfNotZero('Amount', currentTransaction.amount),
      {
        label: 'Status',
        value: currentTransaction.status,
      }
    ];
  };

  service.buildStatus = (currentTransaction) => {
    let values = [];
    const operationGroup = currentTransaction.operationGroup;
    if ('UPDATE_STATUS' === operationGroup) {
      values.push({
        label: 'Status changed',
        value: `${service.attrByName(currentTransaction, 'SOURCE_STATUS')} -> ${service.attrByName(currentTransaction, 'TARGET_STATUS')}`
      });
    }
    return values;
  };

  service.buildCheckSection = (transaction) => {
    let checkAttrs = [];
    const checkAttributeTypes = ['BANK_NAME', 'CHECK_NO', 'INITIAL_CLEARING_DATE', 'CHECK_AMOUNT', 'CHECK_CLEARING_GROUP'];
    _.forOwn(transaction.attributes, (value, key) => {
      if (value && _.includes(checkAttributeTypes, key)) {
        checkAttrs.push({
          label: $filter('prettyEnum')(key),
          value: $filter('prettyEnum')(value)
        });
      }
    });
    return checkAttrs;
  };

  service.buildBankSection = (currentTransaction) => {
    let values = [];
    const operationGroup = currentTransaction.operationGroup;
    if (['CREDIT_MEMO', 'DEBIT_MEMO'].includes(operationGroup)) {
      values.push(...[{
        label: 'Remarks',
        value: service.attrByName(currentTransaction, 'REMARKS'),
      }]);
    }
    return values;
  };

  service.buildFees = (currentTransaction) => {
    if (currentTransaction.feeName) {
      return [{
        label: 'Fee name',
        value: currentTransaction.feeName
      }]
    }
    return [{}];
  };


  service.buildPayments = (currentTransaction) => {
    let values = [];
    const operationGroup = currentTransaction.operationGroup;
    const subgroup = currentTransaction.operationSubgroup;
    const isTransferFunds = operationGroup === 'TRANSFER_FUNDS' && subgroup === 'LOAN_PAYMENT';
    const isCreditLoanMemo = operationGroup === 'CREDIT_MEMO' && subgroup === 'LOAN_PAYMENT';
    if (['DEPOSIT_CASH', 'DEPOSIT_CHECK'].includes(operationGroup) || isTransferFunds || isCreditLoanMemo) {
      values.push(...[
        service.buildPhpIfNotZero('Interest payment', service.attrByName(currentTransaction, 'INTEREST_PAYMENT', '')),
        service.buildPhpIfNotZero('Principal payment', service.attrByName(currentTransaction, 'PRINCIPAL_PAYMENT', '')),
        service.buildPhpIfNotZero('Penalty payment', service.attrByName(currentTransaction, 'PENALTY_PAYMENT', '')),
        service.buildPhpIfNotZero('Past due interest payment', service.attrByName(currentTransaction, 'PAST_DUE_INTEREST_PAYMENT', '')),
        service.buildPhpIfNotZero('CBU payment', service.attrByName(currentTransaction, 'CBU_PAYMENT', '')),
        service.buildPhpIfNotZero('TP payment', service.attrByName(currentTransaction, 'TP_PAYMENT', '')),
        service.buildPhpIfNotZero('PF payment', service.attrByName(currentTransaction, 'PF_PAYMENT', '')),
        service.buildIfNotEmpty('Amortizations Lines', service.attrByName(currentTransaction, 'AMORTIZATION_LINE_NUMBER', '')),
        service.buildIfNotEmpty('Temporary receipt', service.attrByName(currentTransaction, 'TEMPORARY_RECEIPT', '')),
        service.buildIfNotEmpty('Official receipt', service.attrByName(currentTransaction, 'OFFICIAL_RECEIPT', '')),
      ]);
    }

    return values;
  };

  service.clean = (arr) => {
    return arr.filter(val => Object.keys(val).length !== 0);
  };

  service.attrByName = (currentTransaction, type, defaultVal = `<MISSING ${type}>`) => {
    return _.get(currentTransaction.attributes, type, defaultVal);
  };

  return service;
});