import module from 'module';
import _ from 'lodash';
import { automaticTransferStrategies } from "constants/loan";

import templateUrl from './loan-automatic-transfer.template.html';
import './loan-automatic-transfer.style.less';

class LoanAutomaticTransfer {
  constructor(customerCache, accountProductsCache) {
    this.customerCache = customerCache;
    this.accountProductsCache = accountProductsCache;
    this.accounts = [];

    const renderer = (data, escape) => {
      const name = _.get(data, 'type.productDefinition.description');
      const number = data.productNumber;
      return `<div class="account-option"><span>${escape(number)}</span> <span>${escape(name)}</span></div>`;
    };

    this.accountSelectConfig = {
      placeholder: 'Select account',
      searchField: ['description'],
      valueField: 'id',
      render: {
        option: renderer,
        item: renderer
      },
      maxItems: 1
    };

    this.transferStrategies = automaticTransferStrategies;
  }

  async fetchAccounts(customerId) {
    const accountsP = this.customerCache.depositAccounts(customerId).toPromise();
    const accountTypesP = this.accountProductsCache.toPromise();
    const [accounts, accountTypes] = await Promise.all([accountsP, accountTypesP]);
    const accountWithTypes = accounts.map(acc => {
      const type = accountTypes.find(type => type.id === acc.typeId);
      return {
        ...acc,
        type,
        description: `${acc.productNumber} ${type.productDefinition.description}`
      };
    });

    this.accounts = accountWithTypes.filter(acc => acc.status === 'ACTIVE' && acc.type.accountSubtype === 'SAVINGS');
  }

  $onInit() {
    this.fetchAccounts(this.customerId);
  }
}

module.component('loanAutomaticTransfer', {
  templateUrl,
  bindings: {
    customerId: '<',
    automaticTransfer: '='
  },
  controller: LoanAutomaticTransfer
});