import 'rxjs/add/operator/map';
import _ from 'lodash';

import module from 'module';

const templateUrl = require('./gl-transaction-details.template.html');

module.component('glTransactionDetails', {
  templateUrl,
  bindings: {
    transaction: '<',
    onHide: '&',
    onRevoke: '&',
  },
  controller: function ($filter, $element, notification, operationService, $q) {
    const self = this;

    self.keyValueDetails = [];

    self.extractAttr = (attributes, key) => {
      let result = _.find(attributes, ['type', key]);
      return result ? result.value : '-';
    };

    self.mapTransactionType = (type) => {
      if (type === 'OPERATION') {
        return 'Automatic';
      } else if (type === 'MANUAL') {
        return 'Manual';
      } else if (type === 'LEDGER_ACTION') {
        return 'Miscellaneous';
      } else {
        return type;
      }
    };

    self.$onChanges = change => {
      const transaction = _.get(change, 'transaction.currentValue');

      // Set common ticket properties
      const keyValueDetails = [{
        label: 'Ticket no',
        value: transaction.id,
        type: 'STATIC'
      }, {
        label: 'Type',
        value: self.mapTransactionType(transaction.transactionType),
        type: 'STATIC'
      }, {
        label: 'User initiating',
        value: transaction.user,
        type: 'STATIC'
      }, {
        label: 'Backdated',
        value: $filter('prettyBoolean')(transaction.backdated),
        type: 'STATIC'
      }, {
        label: 'Contingent',
        value: $filter('prettyBoolean')(transaction.contingent),
        type: 'STATIC'
      }, {
        label: 'System date',
        value: transaction.systemDate,
        type: 'STATIC'
      }, {
        label: 'Posting date',
        value: transaction.postingDate,
        type: 'STATIC'
      }];

      if (self.requestTimeout) {
        self.requestTimeout.resolve(true);
        self.requestTimeout = null;
      }

      if (transaction.transactionType === 'OPERATION') {
        // Check if transaction has associated operations
        // FIXME = temporary solution is to read details for first operation only (later all operations should be fetched and presented)
        if (transaction.objectIds && transaction.objectIds.length > 0) {
          let primaryOperationId = transaction.objectIds[0];
          const deferred = $q.defer();
          self.requestTimeout = deferred;
          operationService.fetchOperationDetails(primaryOperationId, {timeout: deferred.promise,})
            .success(operation => {
              let attributes = self.extractOperationAttributes(operation);
              keyValueDetails.push(...[{
                label: 'Operation type',
                value: $filter('translate')(operation.targetCode, 'OPERATION_NAME'),
                type: 'ATTRIBUTE'
              }, {
                label: 'Operation amount',
                value: operation.amount,
                type: 'CASH'
              }]);
              keyValueDetails.push(...attributes);
              self.keyValueDetails = keyValueDetails;
            })
            .error(() => {
              notification.show('Failed to load ticket details');
            })
        }
      } else {
        keyValueDetails.push(...[{
          label: 'Remarks',
          value: transaction.remarks,
          type: 'STATIC'
        }]);
      }
      self.keyValueDetails = keyValueDetails;
    };

    self.extractOperationAttributes = (operation) => {
      if (operation && operation.attributes) {
        // Iterate over attributes and convert them to human-readable values
        let humanReadableAttributes = [];
        _.forOwn(operation.attributes, (value, key) => {
          humanReadableAttributes.push(...[{
            label: $filter('prettyEnum')(key),
            value: value ? value : '-',
            type: 'ATTRIBUTE'
          }]);
        });
        return humanReadableAttributes;
      } else {
        return [];
      }
    };

    const format = (transactionUnit, unit, classname) => {
      if (transactionUnit.entryType !== unit) {
        return '-';
      }

      const amount = $filter('php')(transactionUnit.amount);
      return `<span class="${classname}">${amount}</span>`;
    };

    self.formatDebit = transactionUnit => format(transactionUnit, 'DEBIT', 'red');

    self.formatCredit = transactionUnit => format(transactionUnit, 'CREDIT', 'green');

    self.$onDestroy = () => {
      if (self.requestTimeout) {
        self.requestTimeout.resolve(true);
      }
    }
  }
});