import module from 'module';
import ParameterizedLocalCache from 'shared/util/parameterized-local-cache';

module.factory('passbookCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (productId) => http.get(`/products/${productId}/passbook`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.passbook,
    cacheName: 'passbookCache'
  })
);
