import module from 'module';
import moment from 'moment';
import _ from 'lodash';
import {loanCreationTypes} from 'constants/loan';

const templateUrl = require('./new-loan-parameters.template.html');
module.component('customerLoanCreateParameters', {
  templateUrl: templateUrl,
  bindings: {
    formDetails: '=',
    loan: '=',
    loanProduct: '<',
    groupLoan: '<',
    hideSimulation: '<'
  },
  controller: function ($scope, $route, $timeout, http, dict, userCache) {
    const that = this;
    that.dict = dict;
    that.minDate = null;
    that.maxDate = null;
    that.minDateGranted = null;
    that.availableMisGroups = [];
    const customerId = $route.current.params['customerId'];

    that.setDefaultCreationType = () => {
      if (that.loanProduct.defaultCreationType && !that.loan.creationType) {
        that.loan.creationType = that.loanProduct.defaultCreationType;
      }
    };

    that.$onInit = () => {
      that.setDefaultCreationType();

      that.creationTypes = [...loanCreationTypes];
    };

    $scope.$watch('$ctrl.loan.grantDate ', () => {
      const postponement = that.loanProduct.maxFirstPaymentPostponement;
      that.minDate = that.loan.grantDate;

      if (that.minDate && postponement) {
        that.maxDate = moment(that.minDate).add(postponement, 'days').format('YYYY-MM-DD');
      }
      that.calculateFirstPaymentDate();
    });

    $scope.$watch('$ctrl.loanProduct', () => {
      const postponement = that.loanProduct.maxFirstPaymentPostponement;

      if (that.loanProduct) {
        if (that.loanProduct.paymentIntervalOptions) {
          // show only payment intervals supported by loan product
          that.paymentIntervals = _.filter(that.allPaymentIntervals, (i) => that.loanProduct.paymentIntervalOptions.indexOf(i.value) !== -1);
          that.loan.paymentInterval = that.loan.paymentInterval || that.paymentIntervals[0].value;
          that.minDate = that.loan.grantDate;

          if (that.minDate && postponement) {
            that.maxDate = moment(that.minDate).add(postponement, 'days').format('YYYY-MM-DD');
          }
          that.calculateFirstPaymentDate();
        }

        that.loan.totalAmortizationNumber = that.loan.totalAmortizationNumber || that.loanProduct.defaultAmortizationNumber;
        that.loan.interestRate = that.loan.interestRate || that.loanProduct.defaultInterestRate;
        that.loan.termAdjustment = that.loan.termAdjustment || that.loanProduct.minTermAdjustment;
        that.loan.interestCalculationParameter = that.loan.interestCalculationParameter || that.loanProduct.defaultInterestCalculationParameter;
        that.loan.pastDueInterestCharge = that.loan.pastDueInterestCharge || _.clone(that.loanProduct.pastDueInterestCharge);
        that.loan.penalty = that.loan.penalty || _.clone(that.loanProduct.penalty);
        if (that.loanProduct.feeDefinitions) {
          that.showRateTypeId = that.loanProduct.feeDefinitions.some(fd => {
            return fd.extraOptions && fd.extraOptions.LOAN_BOARD;
          });
        }
        that.setDefaultCreationType();
      }
    });

    const userSubscr = userCache.toObservable().subscribe(users => {
      // FIXME = role based approach must be changed to permission based!
      that.officers = _.filter(users, u => _.find(u.roles, {name: 'LOAN_OFFICER'})).map(u => Object.assign(u, {
        fullName: `${u.firstName} ${u.lastName}`
      }));
    });

    that.previewAmortization = () => {
      const clonedLoan = angular.copy(that.loan);
      clonedLoan.collateralFiles = undefined;
      clonedLoan.customerId = customerId;
      clonedLoan.creationType = clonedLoan.creationType || 'NEW_LOAN';
      clonedLoan.automaticTransfer = false;

      http.post('/products/loans/simulate', clonedLoan).success(loan => {
        that.loanPreview = loan;
        that.loan.term = loan.term;
      });
    };

    const calculateSemimonthly_01_16 = () => {
      const minDate = moment(that.loan.grantDate);
      const currentMonthHalf = moment(that.loan.grantDate).startOf('month').add(15, 'days');

      if (minDate.isBefore(currentMonthHalf)) {
        return currentMonthHalf;
      } else {
        return moment(that.loan.grantDate).add(1, 'months').startOf('month');
      }
    };

    const calculateSemimonthly_15_30 = () => {
      const minDate = moment(that.loan.grantDate);
      const currentMonthHalf = moment(that.loan.grantDate).startOf('month').add(14, 'days');
      const daysInCurrentMonth = moment(that.loan.grantDate).daysInMonth();

      if (minDate.isBefore(currentMonthHalf)) {
        return currentMonthHalf;
      } else {
        const startOfTheMonth = minDate.clone().startOf('month');
        const validEndDay = moment.min(startOfTheMonth.clone().endOf('month'), startOfTheMonth.clone().add('29', 'days'));
        const currentDayInMonth = minDate.clone().date();

        if (currentDayInMonth < validEndDay.clone().date()) {
          return validEndDay;
        } else {
          return currentMonthHalf.add(1, 'months');
        }
      }
    }

    that.calculateFirstPaymentDate = () => {
      const postponement = that.loanProduct.maxFirstPaymentPostponement;
      if (!that.loan.paymentInterval || !that.minDate) {
        return null;
      }

      let firstPaymentDate;
      const minDate = that.loan.grantDate;
      const days = _.find(that.allPaymentIntervals, {value: that.loan.paymentInterval}).days;
      const interval = that.loan.paymentInterval;

      switch (interval) {
        case 'MONTHLY':
          firstPaymentDate = moment(minDate).add(1, 'months');
          break;
        case 'SEMIMONTHLY_1_16':
          firstPaymentDate = calculateSemimonthly_01_16();
          break;
        case 'SEMIMONTHLY_15_30':
          firstPaymentDate = calculateSemimonthly_15_30();
          break;
        case 'QUARTERLY':
          firstPaymentDate = moment(minDate).add(3, 'months');
          break;
        case 'SEMIANNUAL':
          firstPaymentDate = moment(minDate).add(6, 'months');
          break;
        case 'YEARLY':
          firstPaymentDate = moment(minDate).add(12, 'months');
          break;
        default:
          firstPaymentDate = moment(minDate).add(days, 'days');
      }

      if (that.loanProduct.validateMinFirstPaymentDate)  {
        that.minDate = firstPaymentDate.clone().format('YYYY-MM-DD');
      }

      that.maxDate = firstPaymentDate.clone().add(postponement, 'days').format('YYYY-MM-DD');

      if (that.loanProduct.calculateDefaultFirstPaymentDate) {
        that.loan.firstAmortizationDueDate = firstPaymentDate.toDate();
      }
    };

    that.previewAmortizationDisabled = () => {
      let missingRequired = !that.loan.principalAmount || !that.loan.totalAmortizationNumber ||
          !that.loan.firstAmortizationDueDate || !that.loan.creationType;
      if (!missingRequired && that.showRateTypeId) {
        return !that.loan.loanInformation.loanRateTypeId;
      } else {
        return missingRequired;
      }
    };

    that.allPaymentIntervals = [{
      label: 'Daily',
      value: 'DAILY',
      days: 1
    }, {
      label: 'Weekly',
      value: 'WEEKLY',
      days: 7
    }, {
      label: 'Fortnightly',
      value: 'FORTNIGHTLY',
      days: 14
    }, {
      label: 'Every 30 days',
      value: 'EVERY_30_DAYS',
      days: 30
    }, {
      label: 'Monthly',
      value: 'MONTHLY'
    }, {
      label: 'Semimonthly (1.X & 16.X)',
      value: 'SEMIMONTHLY_1_16'
    }, {
      label: 'Semimonthly (15.X & 30.X)',
      value: 'SEMIMONTHLY_15_30'
    }, {
      label: 'Quarterly',
      value: 'QUARTERLY'
    }, {
      label: 'Semiannual',
      value: 'SEMIANNUAL'
    }, {
      label: 'Yearly',
      value: 'YEARLY'
    }, {
      label: 'Single Payment',
      value: 'SINGLE_PAYMENT'
    }];

    that.uidApplications = [{
      label: 'From top',
      value: 'TOP'
    }, {
      label: 'From bottom',
      value: 'BOTTOM'
    }];

    that.$onDestroy = () => {
      userSubscr.unsubscribe();
    };
  }
});
