import module from 'module';
import ParameterizedLocalCache from '../../../shared/util/parameterized-local-cache';

module.factory('forexDocumentTypeRatesCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (documentTypeId) => http.get(`/forex/document-types/${documentTypeId}/rates`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.forexDocumentTypeRates,
    cacheName: 'forexDocumentTypeRatesCache'
  })
);
