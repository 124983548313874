import module from 'module';
import _ from 'lodash';

class LoanService {
  constructor(http, fileCache, customerCache, loanProductsCache) {
    this.http = http;
    this.fileCache = fileCache;
    this.customerCache = customerCache;
    this.loanProductsCache = loanProductsCache;
  }

  async getLoansForCustomer(customerId) {
    const loans = await this.customerCache.loans(customerId).toPromise();
    const loanTypes = await this.loanProductsCache.toPromise();
    return loans.map(loan => {
      // add loanProduct to loan object
      const type = _.find(loanTypes, {id: loan.typeId});
      return {
        ...loan,
        loanType: type
      };
    });
  }

  async getLoanForCustomer(customerId, loanId) {
    const loans = await this.getLoansForCustomer(customerId);
    return loans.find(loan => String(loan.id) === loanId);
  }
}

module.service('loanService', LoanService);