import module from 'module';
import _ from 'lodash';
import 'rxjs/add/operator/combineLatest';
import BigNumber from "bignumber.js";

const templateUrl = require('./interests-cash-withdrawal.template.html');
module.component('customerTermDepositInterestsCashWithdrawal', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, http, customerCache, productDefinitionService, confirmation,
                        notification, command, modalPrintPreviewService, casaProductPrintService) {
    let that = this;

    that.overrideCheckbox = false;
    that.deposit = {};

    that.taxRecalculated = false;

    that.override = {
      interest : 0,
      netInterest : 0,
      withholdingTax: 0
    };

    let customerId = $route.current.params['customerId'];
    let depositId = ~~$route.current.params['depositId'];

    // Interest forecast
    that.interestForecast = {};

    // Fetch term deposit interest forecast
    that.readInterestForecast = (depositId) => {
      http.post(`/products/deposits/${depositId}/interest`, {mode: 'STANDARD'})
        .success((forecast) => {
          that.interestForecast = forecast;
          that.override.interest = that.interestForecast.interest;
          that.override.netInterest = that.interestForecast.netInterest;
          that.override.withholdingTax = that.interestForecast.withholdingTax;
        })
        .error(() => {
          notification.show("Error", "Failed to load interest forecast.")
        });
    };

    customerCache.termDeposits(customerId).toObservable()
      .map(deposits => _.find(deposits, {id: depositId}))
      .combineLatest(productDefinitionService.toObservable(), (deposit, products) => {
          const p = _.find(products, {id: deposit.definitionId});
          return Object.assign(deposit, {
            productName: p ? p.productName : '<Unknown product>'
          });
        }
      )
      .subscribe(deposit => {
        that.termDeposit = deposit;
        that.readInterestForecast(deposit.id);
      });

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${depositId}`);

    that.updateOverrides = () => {
      that.taxRecalculated = false;
    };

    that.recalculateTax = () => {
      http.get(`/products/deposits/${depositId}/interest/calculate-withholding-tax?interest=${that.override.interest}`)
        .success((newTax) => {
          that.override.netInterest = new BigNumber(that.override.interest).sub(newTax).toNumber();
          that.override.withholdingTax = newTax;
          that.taxRecalculated = true;
        });
    };

    that.withdrawalEnabled = () => {

      // If firm is invalid -> leave immediately
      if (that.transactionForm.invalid) return false;

      // If override is enabled base validation on override values
      if (that.overrideCheckbox) {
        return that.taxRecalculated
          && that.override
          && that.override.interest
          && that.override.netInterest > 0;
      } else {
        return that.interestForecast.interest
          && that.interestForecast.netInterest > 0;
      }
    };

    that.withdraw = () => {
      let netInterest = that.overrideCheckbox ? that.override.netInterest : that.interestForecast.netInterest;
      confirmation(`Do you want to withdraw ${$filter('php') (netInterest)} PHP?`, () => {
        command.execute('WithdrawDepositInterestByCash', {
          productId: depositId,
          customerId: customerId,
          entryType: "DEBIT",
          amount: that.overrideCheckbox ? that.override.netInterest : that.interestForecast.netInterest,
          override: that.overrideCheckbox ? that.override.interest : null
        }).success((res) => {
          casaProductPrintService.printFor('TERM_DEPOSIT', customerId, depositId, res.output.id).finally(() => {
            customerCache.termDeposits(customerId).refetch();
            that.redirectBack();
          });
        });
      });
    }
  }
});
