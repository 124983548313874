import module from 'module';

module.factory('pawnStatusMapper', () => {
    return (status) => {
      switch (status) {
        case 'INACTIVE':
          return 'Inactive';
        case 'PENDING':
          return 'Awaiting approval';
        case 'ACTIVE':
          return 'Active';
        case 'APPROVED':
          return 'Approved';
        case 'MATURE':
          return 'Mature';
        case 'PAST_DUE_PERFORMING':
          return 'Past due';
        case 'CLOSED':
          return 'Closed';
        case 'CANCELED':
          return 'Canceled';
        case 'PAST_DUE_NON_PERFORMING':
          return 'Expired';
        case 'AUCTIONED':
          return 'Auctioned';
        case 'PAST_DUE_WRITE_OFF':
          return 'Withdrawn';
        case 'STOCKED':
          return 'Stocked';

        default:
          console.log('WARNING: Unknown pawn status: ' + status);
          return status;
      }
    }
  }
);
