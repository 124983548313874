import module from 'module';
import _ from 'lodash';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/combineLatest';
import $ from 'jquery';

module.factory('modalPrintPreviewService', function ($rootScope, $compile, http, printsCache,
                                                     notification, authentication) {
    let that = this;
    that.permissions = authentication.permissions;

  /**
   * @deprecated use that.showAsync
   * @param printDescription can be simple string or object with properties 'code', 'param'
   */
    that.show = (printDescription, args, callback = () => {}, errorMsg) => {
      let printCode = null;
      let printParameters = null;
      if(typeof printDescription === 'string') {
        printCode = printDescription;
      } else {
        printCode = printDescription.code;
        printParameters = printDescription.parameters;
      }

      printsCache.toObservable().first().subscribe(data => {
        let p = _.find(data, {code: printCode});
        if(p && p.active) {
          let httpResponse = http.post(`/print/${printCode}/json`, args, {
            params: {
              ...printParameters
            }
          })
            .success((doc) => displayModal(doc, callback))
            .error(() => callback());
          if (errorMsg) {
            httpResponse.error(() => {
              notification.show("Error", errorMsg);
            });
          }
        } else {
          callback();
        }
      }, (err) => callback());
    };

    that.showAsync = (printDescription, args) => {
      return new Promise((resolve) => that.show(printDescription, args, () => resolve()));
    };

    that.canReprint = (name, onSuccess) => {
      printsCache.toObservable().first()
        .subscribe(prints => {
          let active = false;
          let p = _.find(prints, {code: name});
          if(p && p.active) {
            active = p.canReprint || that.permissions['PRT_REPRINT'] === true;
          }
          onSuccess(active);
        });
    };

    const displayModal = (print, cb) => {
      let scope = $rootScope.$new();
      scope.print = print;
      scope.closePreview = () => {
        $('#modalPrintPreview').remove();
        if (cb) cb();
      }; 
      $('body').append($compile('<modal-print-preview id="modalPrintPreview" doc="print" on-close="closePreview()" />')(scope));
    };

    return that;
});
